import React, {useContext} from 'react'
import {ControlledMenu} from '@szhsin/react-menu'
import moment from 'moment'
import {getServiceImage} from '../../../utils/style.utils'
import {KTSVG} from '../../../_metronic/helpers'
import {formatTimeStringFromMinutes} from '../../../utils/date.utils'
import {TransportsContext} from '../../../context/transports.context'

export const StopsComponent = ({
  poiList,
  showWaitingTime = true,
  inline = false,
}: {
  poiList: any[]
  showWaitingTime?: boolean
  inline?: boolean
}) => {
  return (
    <div>
      {(poiList || []).map((point: any, i: number) => {
        const hasWaitingTime = parseInt(point.estimatedWait) > 0
        return (
          <div
            style={{
              display: 'flex',
            }}
            className={inline ? 'mb-3' : 'my-3'}
            key={i}
          >
            <div
              style={{
                display: 'inline-flex',
                height: '22px',
                maxWidth: '50%',
              }}
              className='me-1'
            >
              <span
                className='badge'
                style={{
                  borderRadius: showWaitingTime && hasWaitingTime ? '4px 0 0 4px' : '4px',
                  marginRight: 6,
                  background: '#f2f2f2',
                  color: '#989898',
                  fontSize: 11,
                }}
              >
                {i + 1}
              </span>
              {showWaitingTime && hasWaitingTime ? (
                <span
                  style={{
                    borderRadius: '0px 4px 4px 0px',
                    padding: '0.5em 0.4em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '22px',
                    background: '#f2f2f2',
                    color: '#989898',
                    borderLeft: '1px solid #c3c3c350',
                    marginLeft: '-8px',
                    fontSize: 11,
                    marginRight: '6px',
                    whiteSpace: 'nowrap',
                    width: 'auto',
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotone/Home/Clock.svg'
                    className='svg-icon-3 svg-color-primary'
                  />
                  {formatTimeStringFromMinutes(point.estimatedWait)}
                </span>
              ) : null}
            </div>
            <span>
              {point.address}, {point.addressDetails}
            </span>
          </div>
        )
      })}
    </div>
  )
}

export default function CardHoverMenu({transport, ...props}: any) {
  //   if (props.cardProps.state !== 'open') {
  //     return <></>
  //   }
  const {tariffServicePhotoDict} = useContext<any>(TransportsContext)
  const tariffServiceName = transport?.tariffService?.name || transport?.tariffServiceName
  const numOfPassengers = transport?.passengers || 0

  return (
    <ControlledMenu {...props}>
      <div style={{minWidth: 300, maxWidth: 300, padding: 16}}>
        <div>
          <div className='hover-card-item hover-card-service'>
            <img
              src={
                tariffServicePhotoDict[transport?.tariffService?.name] ||
                getServiceImage(tariffServiceName)
              }
            />
            <p style={{fontSize: 11}}>{`${tariffServiceName} (${numOfPassengers})`}</p>
          </div>

          <div className='hover-card-item'>
            <label>Time:</label>
            <span className='startHour' style={{background: '#6c757d'}}>
              {moment(transport?.effectiveDate).format('HH:mm')}
            </span>
            <p></p>
          </div>

          {Boolean(transport?.requestRemark) && !Boolean(transport?.hasValidRequestRemark) ? (
            <div className='hover-card-item'>
              <label>Request remark:</label>
              <p>{transport?.requestRemark}</p>
            </div>
          ) : null}

          <div className='hover-card-item'>
            <label>Company:</label>
            <p>{transport?.company?.name}</p>
          </div>
        </div>

        <div className='hover-card-item'>
          <label>Client:</label>
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {transport?.passengerName}
            {transport?.vip && (
              <span
                style={{
                  marginLeft: '4px',
                }}
              >
                <KTSVG
                  path='/media/icons/duotone/General/Star.svg'
                  className='svg-icon-2 svg-icon-warning clickable'
                />
              </span>
            )}
          </p>
        </div>

        <div className='hover-card-item'>
          <label>From:</label>
          <span className='startHour'>
            {moment(transport?.fromDate || transport?.effectiveDate).format('HH:mm')}
          </span>
          <p>
            {transport?.origin} ({transport?.originAdditionalInfo})
          </p>
        </div>

        {transport?.intermediaryPoints?.length > 0 ? (
          <div className='hover-card-item'>
            <label>Stops:</label>
            <StopsComponent poiList={transport?.intermediaryPoints} />
          </div>
        ) : null}

        <div className='hover-card-item'>
          <label>To: </label>{' '}
          <span className='endHour'>
            {moment(transport?.toDate || transport?.completeEstimateDate).format('HH:mm')}
          </span>
          <p>
            {transport?.destination} ({transport?.destinationAdditionalInfo})
          </p>
        </div>

        {transport.comment ? (
          <div className='hover-card-item'>
            <label>Comment: </label> <p>{transport.comment}</p>
          </div>
        ) : null}

        {transport.lastCheckedBy ? (
          <div className='hover-card-item'>
            <label>Checked by: </label>{' '}
            <p>
              {transport.lastCheckedBy} at {moment(transport.lastCheckedAt).format('HH:mm')}
            </p>
          </div>
        ) : null}
        {transport.driverNotification ? (
          <div className='hover-card-item'>
            <label>Driver notification: </label> <p>{transport.driverNotification} </p>
          </div>
        ) : null}
        {transport.dispatchComment ? (
          <div className='hover-card-item'>
            <label>Dispatch Comment: </label> <p>{transport.dispatchComment} </p>
          </div>
        ) : null}

        {props.bottomContent ? props.bottomContent : null}
      </div>
    </ControlledMenu>
  )
}

import React from 'react'
import { EditableLabel } from '../../../../../app/modules/add-edit-template/components/fields-generator'

const TextareaField = ({
  field,
  defaultRowHeight = 2,
  value,
  hideLabel,
  maxLength = 0,
  additionalInfo,
  readOnly,
  onChange,
  onChangeLabel,
}: {
  field: any
  defaultRowHeight?: number
  value: string
  hideLabel?: boolean
  additionalInfo?: string
  maxLength?: number
  readOnly?: boolean
  onChange: (e: any) => void
  onChangeLabel?: (value: string) => void
}) => {
  const hasMaxLength = Boolean(maxLength) && maxLength > 0
  return (
    <>
      {!Boolean(hideLabel) ? (
        <EditableLabel label={field?.label} onChange={onChangeLabel} isRequired={field?.required} />
      ) : null}
      <textarea
        key={field?.name}
        name={field?.name}
        className={`form-control form-control-sm ${hasMaxLength && (value || '').length > maxLength ? 'text-danger' : ''}`}
        id={field?.name}
        required={field?.required}
        readOnly={readOnly}
        rows={defaultRowHeight}
        onChange={onChange}
        value={value || ''}
      />
      {Boolean(additionalInfo) ? (
        <div style={{ opacity: 0.9, padding: '5px' }}>
          <span style={{ fontWeight: '500' }}>info:</span>{' '}
          <span
            className={
              hasMaxLength && (value || '').length > maxLength ? 'text-danger' : ''
            }
          >
            {additionalInfo}
          </span>
        </div>
      ) : null}
    </>
  )
}

export const TextareaComponent = ({
  label,
  name,
  required,
  defaultRowHeight = 2,
  value,
  hideLabel,
  maxLength = 0,
  additionalInfo,
  readOnly,
  onChange = () => { },
}: {
  label: string
  name?: string
  required?: boolean
  defaultRowHeight?: number
  value: string
  hideLabel?: boolean
  maxLength?: number
  additionalInfo?: string
  readOnly?: boolean
  onChange?: (e: any) => void
}) => {
  const field = {
    label,
    name: Boolean(name) ? name : label,
    required: Boolean(required)
  }
  return <TextareaField
    field={field}
    value={value}
    hideLabel={Boolean(hideLabel)}
    defaultRowHeight={defaultRowHeight}
    maxLength={maxLength}
    readOnly={Boolean(readOnly)}
    additionalInfo={additionalInfo}
    onChange={onChange}
  />
}

export default TextareaField
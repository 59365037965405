import React, { FC } from 'react'
import {ProfileDetails} from './cards/ProfileDetails'
import {SignInMethod} from './cards/SignInMethod'
import {DeactivateAccount} from './cards/DeactivateAccount'
import { AppSettings } from './cards/AppSettings'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../../_metronic/layout/core'

export function Settings() {
  return (
    <>
      <ProfileDetails />
      <SignInMethod />
      <AppSettings />
      <DeactivateAccount />
    </>
  )
}

const AccountSettingsWrapper: FC = () => {
  const intl = useIntl()
  return (<>
    <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'AUTH.GENERAL.SETTINGS'})}</PageTitle>
    <Settings /></>
  )
}

export default AccountSettingsWrapper

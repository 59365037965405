import {FC, useEffect, useState} from 'react'
import {emptyTariffService, pickupLocationBasedTaxesType, TariffServiceType} from './PlanTypes'
import {
  getInitializedObjectListWithEmpty,
  getObjectListWithEmptyStripped,
  onChangeListHasEmpty,
} from '../../../utils/state.utils'
import {emptyGeofence, GeofenceType} from '../../../types/general.types'
import Table from './Table'
import {displayGeofence} from '../AddEditPlan'
import {usePlanContext} from '../../../context/plan.context'
import _ from 'lodash'
import {getArrayOfObjectsString} from '../../../utils/string.utils'

const pickupLocationTypeReq = ['geofenceType', 'startGeofences', 'tariffServices', 'fixedTax', 'defaultWaitTime']
const pickupLocationTypeUnique = ['geofenceType', 'startGeofences', 'tariffServices']
const emptyPickupLocation: pickupLocationBasedTaxesType = {
  id: null,
  geofenceType: '',
  startGeofences: [],
  tariffServices: [],
  fixedTax: 0,
  defaultWaitTime: 0,
}
const getPickupLocationTaxLabel = (pickupLocationTax: pickupLocationBasedTaxesType) =>
  'pickup location tax (geofenceType: ' +
  (pickupLocationTax.geofenceType || '') +
  '; startGeofences: ' +
  getArrayOfObjectsString({list: pickupLocationTax.startGeofences || [], identifier: 'name', maxLength: 25}) +
  '; tariffServices: ' +
  getArrayOfObjectsString({list: pickupLocationTax.tariffServices || [], identifier: 'name', maxLength: 25}) +
  ')'

const PickupLocationTable: FC<{}> = ({}) => {
  const {plan, setPlan, dictionaries, tariffServices, geofences} = usePlanContext()
  const [pickupLocationTaxes, setPickupLocationTaxes] = useState<pickupLocationBasedTaxesType[]>(
    plan?.pickupLocationBasedTaxes || []
  )

  useEffect(() => {
    let futurePickupLocationTaxes = getInitializedObjectListWithEmpty({
      initialObjectList: plan?.pickupLocationBasedTaxes || [],
      propsWithEmpty: [
        {property: 'startGeofences', emptyValue: emptyGeofence},
        {property: 'tariffServices', emptyValue: emptyTariffService},
      ],
    })
    setPickupLocationTaxes(futurePickupLocationTaxes)
  }, [])

  useEffect(() => {
    let futurePickupLocationTaxes = getObjectListWithEmptyStripped({
      objectList: pickupLocationTaxes || [],
      propsWithEmpty: [
        {property: 'startGeofences', emptyValue: emptyGeofence},
        {property: 'tariffServices', emptyValue: emptyTariffService},
      ],
    })
    setPlan({...plan, pickupLocationBasedTaxes: futurePickupLocationTaxes})
  }, [pickupLocationTaxes])

  const PickupLocationProps = [
    {
      name: 'geofenceType',
      getDisplayLabel: (name: string) =>
        dictionaries.PRICING_GEOFENCE_TYPES.find((item: any) => item.name === name)?.title,
      label: 'Geofence Type',
      type: 'select',
    },
    {
      name: 'startGeofences',
      getDisplayLabel: (geofences: GeofenceType[]) =>
        (geofences || [])
          .map((geofence: GeofenceType) => displayGeofence(geofence, dictionaries))
          .join(', '),
      label: 'Start Geofences',
      type: 'select',
      isMulti: true,
    },
    {
      name: 'tariffServices',
      getDisplayLabel: (tariffServices: TariffServiceType[]) =>
        (tariffServices || []).map((service: any) => service.name || 'ALL').join(', '),
      label: 'Tariff Services',
      type: 'select',
      isMulti: true,
    },
    {
      name: 'fixedTax',
      getDisplayLabel: (e: any) => e,
      label: 'Fixed Tax',
      type: 'currencyInput',
      addOns: {append: (plan?.currency || '').toLowerCase()},
      canEditInView: true,
      minWidth: '135px',
    },
    {
      name: 'defaultWaitTime',
      getDisplayLabel: (e: any) => e,
      label: 'Default Wait Time',
      type: 'input',
      inputType: 'number',
      canEditInView: true,
      addOns: {append: 'min'},
      minWidth: '135px',
    },
  ]

  const pickupExceptions = [
    {
      message: "All geofences should be of the same type as the chosen 'Geofence Type'",
      isEntryValid: (entry: pickupLocationBasedTaxesType) => {
        return entry.startGeofences.every(
          (geofence: GeofenceType) =>
            geofence.type === entry.geofenceType || !Boolean(geofence.type)
        )
      },
    },
  ]

  const pickupLocationFieldToProperty = (
    field: any,
    pickupLocationTax: pickupLocationBasedTaxesType,
    setPickupLocationTax: Function
  ) => {
    let fieldName = field.name

    const fieldsToPanelPropertiesMapping = {
      geofenceType: {
        simpleValue: pickupLocationTax.geofenceType || '',
        options: dictionaries.PRICING_GEOFENCE_TYPES || [],
        getOptionValue: (e: any) => e.name || '',
        getOptionLabel: (e: any) => e.title || '',
        onChange: (geofenceType: string) => {
          let startGeofences = (pickupLocationTax.startGeofences || []).filter(
            (geofence: GeofenceType) => geofence.type === geofenceType || !Boolean(geofence.type)
          )
          const newPickupLocationTax = {
            ...pickupLocationTax,
            geofenceType: geofenceType,
            startGeofences: startGeofences,
          }

          setPickupLocationTax(newPickupLocationTax)
        },
      },
      startGeofences: {
        simpleValue: pickupLocationTax.startGeofences || [],
        options: [emptyGeofence, ...(geofences || [])],
        filterOption: (candidate: {label: string; value: any; data: any}) => {
          // filter the geofences after the geofenceType
          if (_.isEqual(candidate.data, emptyGeofence)) return true
          return Boolean(pickupLocationTax.geofenceType)
            ? candidate.value.type === pickupLocationTax.geofenceType
            : true
        },
        getOptionValue: (geofence: GeofenceType) => geofence || '',
        getOptionLabel: (geofence: GeofenceType) => displayGeofence(geofence, dictionaries) || '',
        onChange: (startGeofences: GeofenceType[]) =>
          onChangeListHasEmpty({
            entity: pickupLocationTax,
            setEntity: setPickupLocationTax,
            newValues: startGeofences,
            emptyValue: emptyGeofence,
            property: 'startGeofences',
          }),
        sorted: true,
      },
      tariffServices: {
        simpleValue: pickupLocationTax.tariffServices || [],
        options: [emptyTariffService, ...(tariffServices || [])],
        getOptionValue: (e: any) => e || '',
        getOptionLabel: (e: any) => (Boolean(e.name) ? e.name : 'ALL'),
        onChange: (tariffServices: TariffServiceType[]) =>
          onChangeListHasEmpty({
            entity: pickupLocationTax,
            setEntity: setPickupLocationTax,
            newValues: tariffServices,
            emptyValue: emptyTariffService,
            property: 'tariffServices',
          }),
        sorted: true,
      },
      fixedTax: pickupLocationTax.fixedTax,
      defaultWaitTime: pickupLocationTax.defaultWaitTime,
    }

    return (fieldsToPanelPropertiesMapping as any)[fieldName]
  }

  return (
    <>
      <Table
        tableLabel={'Pickup Location Based Taxes Table'}
        properties={PickupLocationProps}
        fieldToProperty={pickupLocationFieldToProperty}
        entries={pickupLocationTaxes}
        setEntries={setPickupLocationTaxes}
        emptyEntry={emptyPickupLocation}
        getEntryLabel={getPickupLocationTaxLabel}
        requiredFields={pickupLocationTypeReq}
        extraExceptions={pickupExceptions}
        compoundUniqueKey={pickupLocationTypeUnique}
      />
    </>
  )
}

export default PickupLocationTable

import { Button, Modal } from 'react-bootstrap'

const ConfirmModal = ({
  confirmAction,
  subtitle, 
  title, 
  visible,
  setVisible
}: {
  confirmAction:Function
  subtitle:string
  title:string
  visible:boolean
  setVisible:Function
}) => {

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={visible}
      onHide={() => { setVisible(false) }}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{subtitle}</Modal.Body>
      <Modal.Footer>
        <Button variant='success' onClick={()=>{confirmAction()}}>
          Yes
        </Button>
        <Button variant='danger' onClick={() => { setVisible(false) }}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  )
}


export default ConfirmModal

import moment from 'moment'
import {memo, useContext, useEffect, useMemo, useState} from 'react'
import {toast} from 'react-toastify'
import {TransportsContext} from '../../../../../context/transports.context'
import Select from '../../../../../_metronic/layout/components/select/Select'
import ModalPortal from '../../../../../_metronic/layout/components/modal/modal-portal'
import {createFilter} from 'react-select'
import easytrackAxios from '../../../../../setup/easytrack.axios'

import {UserContext} from '../../../../../context/user.context'
import CustomDriverOption from '../../CustomDriverOption'
import CustomVehicleOption from '../../CustomVehicleOption'
import {CarType} from '../../../../../types/general.types'
import { EASYTRACK_API_URL } from '../../../../../constants/api.constants'

type AssignModalProps = {
  modalVisible: boolean
  setModalVisible: Function
  transport: any
}

const AssignDriverModal = memo(
  ({modalVisible, setModalVisible, transport}: AssignModalProps) => {
    const {
      drivers,
      transports,
      getTransportsPerDriver,
      assignTransportToDriver,
      getShiftsMap,
      chosenDate,
      setShiftsMap,
      getDriverAssignments,
    } = useContext<any>(TransportsContext)

    const {businessProfileId} = useContext<any>(UserContext)
    const [transportsPerDriver, setTransportsPerDriver] = useState<any>({})
    const [selectedDriver, setSelectedDriver] = useState(transport.driver)
    const [vehicles, setVehicles] = useState<any>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [selectedVehicle, setSelectedVehicle] = useState<CarType | null>(null)

    const getVehicles = async () => {
      const vehicleResults = await easytrackAxios.get(
        `${EASYTRACK_API_URL}/vehicles?businessProfileId=${businessProfileId}`
      )
      setVehicles(vehicleResults.data)
    }

    useEffect(() => {
      let result = getTransportsPerDriver(transports)
      setTransportsPerDriver(result)

      getVehicles()
    }, [])

    return (
      <ModalPortal visible={modalVisible} setVisible={setModalVisible}>
        <div style={{width: '100%', padding: 16}}>
          <div className='hover-card-item'>
            <label>Origin:</label>
            <p>{transport.origin}</p>
          </div>
          <div className='hover-card-item'>
            <label>Destination:</label>
            <p>{transport.destination}</p>
          </div>

          <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
            <div className='hover-card-item assignment-small-card'>
              <label>Start at:</label>
              <p>{moment(transport?.effectiveDate).format('HH:mm')}</p>
            </div>
            <div
              style={{
                margin: '0px 5px',
              }}
              className='hover-card-item assignment-small-card'
            >
              <label>Estimated finish:</label>
              <p>{moment(transport?.completeEstimateDate).format('HH:mm')}</p>
            </div>
            <div className='hover-card-item assignment-small-card'>
              <label>Duration:</label>
              <p>{transport.duration} min</p>
            </div>
          </div>

          <Select
            value={selectedDriver}
            onChange={async (value: any) => {
              setSelectedDriver(value)
              setSelectedVehicle(null)

              const payload: any = {
                transportId: transport.id,
                driverId: value.idUserProfile,
                businessProfileId: value.businessProfile.id,
                mode: 'verify',
                bypassDriverSchedule: true,
              }

              const result = await assignTransportToDriver(payload)

              if (result?.status !== 200) {
                // Show error
                toast.error(result?.data?.message)
              } else {
                if (!result.data.driverEnglishLevelValid) {
                  // Show warning
                  toast.warning('Driver english level is not valid')
                }

                if (!result.data.driverPOSValid) {
                  // Show warning
                  toast.warning('Driver POS is not valid')
                }

                if (!result.data.requestInValidDriverSchedule) {
                  // Show warning
                  toast.warning("The assigned transport request is outside the driver's schedule")
                }
              }

              let currentDate = new Date(chosenDate)
              let dayAfterDate = new Date()
              dayAfterDate.setDate(currentDate.getDate() + 2)

              const driverVehicleResponse = await getDriverAssignments({
                businessProfileId,
                driverIds: [value.idUserProfile],
                startDate: moment(currentDate).format('YYYY-MM-DD'),
                stopDate: moment(dayAfterDate).format('YYYY-MM-DD'),
              })

              if (driverVehicleResponse.status !== 200) {
                return toast.error('Error getting driver vehicle')
              }

              const assingEntries = driverVehicleResponse?.data?.assignEntries
              if (!Boolean(assingEntries) || !Array.isArray(assingEntries) || assingEntries?.length === 0) {
                return toast.error('Driver has no data')
              }

              const driverVehicle = vehicles.find(
                (vehicle: any) =>
                  vehicle.id == assingEntries[0]?.idVehicle
              )

              setSelectedVehicle(driverVehicle)
            }}
            components={{
              Option: (props) => (
                <CustomDriverOption
                  {...props}
                  selectedTransport={transport}
                  transportsPerDriver={transportsPerDriver}
                />
              ),
            }}
            filterOption={createFilter({
              ignoreCase: true,
              ignoreAccents: true,
              trim: true,
              stringify: (option) => {
                // @ts-ignore
                return `${option?.label} ${option?.value} ${option?.data?.remarks} ${option?.data?.employeeBadge} ${option?.data?.name} ${option?.data?.englishLevel} ${option?.data?.cnp} ${option?.data?.idUserProfile} ${option?.data?.userId}`
              },
              matchFrom: 'any',
            })}
            placeholder='Select driver'
            label='Assign request to driver'
            getOptionLabel={(option: any) => option.fullName}
            getOptionValue={(option: any) => option.idUserProfile}
            styles={{menuPortal: (base: any) => ({...base, zIndex: 9999})}}
            options={drivers}
          />

          {/* Select vehicle */}
          {selectedDriver ? (
            <div style={{marginTop: 32}}>
              <Select
                value={selectedVehicle}
                components={{
                  Option: (props) => <CustomVehicleOption {...props} />,
                }}
                onChange={(value: any) => {
                  setSelectedVehicle(value)
                }}
                filterOption={createFilter({
                  ignoreCase: true,
                  ignoreAccents: true,
                  trim: true,
                  stringify: (option) => {
                    // @ts-ignore
                    return `${option?.label} ${option?.value} ${option?.data?.brand} ${option?.data?.plateNumber} ${option?.data?.department} ${option?.data?.type}`
                  },
                  matchFrom: 'any',
                })}
                placeholder='Select vehicle'
                label='Select driver vehicle'
                getOptionLabel={(option: any) => {
                  return option.businessName
                }}
                getOptionValue={(option: any) => option.idVehicle}
                styles={{menuPortal: (base: any) => ({...base, zIndex: 9999})}}
                options={vehicles}
              />
            </div>
          ) : null}

          <div style={{width: '100%', justifyContent: 'flex-end', display: 'flex'}}>
            <button
              disabled={!selectedDriver || isLoading}
              type='button'
              className='btn btn-primary mt-4'
              onClick={async () => {
                // Alert dezactivat pentru siguranta
                setIsLoading(true)

                const payload: any = {
                  transportId: transport.id,
                  driverId: selectedDriver.idUserProfile,
                  businessProfileId: selectedDriver.businessProfile.id,
                  mode: 'assign',
                  bypassDriverSchedule: true,
                }

                // Add idVehicle to payload
                if (Boolean(selectedVehicle?.idVehicle)) {
                  payload.vehicleId = selectedVehicle.idVehicle
                }

                const result = await assignTransportToDriver(payload)

                if (result.status !== 200) {
                  setIsLoading(false)

                  toast.error(result?.data?.message || 'Something went wrong')
                  return
                }

                if (!result.data.acceptedAssign) {
                  setIsLoading(false)
                  toast.error('Assign was not accepted by the server')
                  return
                }

                if (!result.data.requestInValidDriverSchedule) {
                  // Show warning
                  toast.warning("The assigned transport request is outside the driver's schedule")
                }

                const futureShifts = await getShiftsMap()
                setIsLoading(false)
                if(!futureShifts) {
                  return
                }
                setShiftsMap(futureShifts)
              }}
              style={{marginLeft: 'auto'}}
            >
              {isLoading ? 'Loading...' : 'Confirm assignment'}
            </button>
          </div>
        </div>
      </ModalPortal>
    )
  }
)

export default AssignDriverModal

import moment from 'moment'
import { toast } from 'react-toastify'
import { statusColorStyle } from '../../../../utils/style.utils'
import Checkbox from '../../../../_metronic/layout/components/fields/checkbox/checkbox'
import CurrencyInput from '../../../../_metronic/layout/components/fields/currencyInput/CurrencyInput'
import CustomInputField from '../../../../_metronic/layout/components/fields/customInput/custom-input-field'
import DateInput from '../../../../_metronic/layout/components/fields/date-input/date-input-field'
import InputField from '../../../../_metronic/layout/components/fields/input/input-field'
import RepeatRequestField from '../../../../_metronic/layout/components/fields/repeatRequest/repeat-request-field'
import GeolocationSelect from '../../../../_metronic/layout/components/geolocation-select/GeolocationSelect'
import Select from '../../../../_metronic/layout/components/select/Select'
import SelectAsync from '../../../../_metronic/layout/components/select/SelectAsync'
import { CourseMapWrapper } from '../../add-edit-transport/components/CourseMap'
import DurationInput from '../../add-edit-transport/components/DurationInput'
import PassengersComponent from '../../add-edit-transport/components/PassengersListModal'
import StopsList from '../../add-edit-transport/components/StopsList'
import TransportDocumentWrapper from '../../add-edit-transport/components/TransportDocumentWrapper'
import PoiFormWrapper from '../../poi-component/PoiForm'
import BreakdownCardWrapper from '../../view-transport/modules/BreakdownCard'
import GreeterSignList from '../../view-transport/modules/GreeterSignList'
import SearchSubscriptionsField from '../../add-edit-transport/components/subscriptions/SearchSubscriptionsField'
import SubscriptionsDisplayField from '../../add-edit-transport/components/subscriptions/SubscriptionsDisplayField'
import OptionsSelectWrapper from '../../add-edit-transport/components/rent/OptionsSelect'

export const EditableLabel = ({
  label,
  onChange,
  isRequired,
  style = {},
  hideColon,
}: {
  label: string
  onChange: (value: string) => void
  isRequired: boolean
  style?: any
  hideColon?: boolean,
}) => {
  if (!onChange) {
    return (
      <label htmlFor={label} className='form-label' style={style}>
        {label}
        {!hideColon ? ': ' : ''}
        {isRequired && <span className='text-danger'>*</span>}
      </label>
    )
  }

  return (
    <input
      className='editable-label'
      type='text'
      name='card-title'
      id='card-title'
      value={label}
      onChange={(ev) => {
        // Edit panel name
        onChange(ev.target.value)
      }}
    />
  )
}

export function generateField(
  field: any,
  onChangeLabel?: (value: string) => void
): any {
  switch (field?.type) {
    case 'input':
      return (
        <InputField onChangeLabel={onChangeLabel} value={''} field={field} onChange={(ev) => { }} />
      )
    case 'tariffCurrencyInput':
      return (
        <CurrencyInput
          onChangeLabel={onChangeLabel}
          field={field}
          value={'0.00'}
          onChange={(e) => {
            toast.info('This is a display only field')
          }}
          additionalInfo={'RON Without VAT'}
        />
      )
    case 'customInput':
      return (
        <CustomInputField
          onChangeLabel={onChangeLabel}
          customFields={[]}
          value={''}
          field={field}
          onChangeInput={(ev) => {
            toast.info('This is a display only field')
          }}
          onChangeSelect={(ev) => {
            toast.info('This is a display only field')
          }}
        />
      )
    case 'inputCompleteFields':
      return (
        <>
          <EditableLabel
            label={field?.label}
            onChange={onChangeLabel}
            isRequired={field.required}
          />
          <Select
            disabled
            onChange={(optionsInstance: any) => {
              toast.info('This is a display only field')
            }}
            value={null}
            options={[]}
          />
        </>
      )
    case 'date':
      return (
        <DateInput
          onChangeLabel={onChangeLabel}
          label={field?.label}
          required={field?.required}
          disabled
          id={field?.name}
          field={field}
          value={null}
          onChange={(date) => {
            toast.info('This is a display only field')
          }}
        />
      )
    case 'selectWithAutocomplete':
      return (
        <>
          <EditableLabel
            label={field?.label}
            onChange={onChangeLabel}
            isRequired={field?.required}
          />
          <SelectAsync
            disabled
            name={field?.name}
            loadOptions={(code: any) => { }}
            getOptionValue={() => { }}
            getOptionLabel={() => { }}
            simpleValue={null}
            onChange={(optionsInstance: any) => {
              toast.info('This is a display only field')
            }}
            onInputChange={() => { }}
          />
        </>
      )
    case 'select':
      const selectOptions = []

      const selectValue = null

      return (
        <>
          <EditableLabel
            label={field?.label}
            onChange={onChangeLabel}
            isRequired={field?.required}
          />

          <Select
            disabled
            styles={field?.name === 'requestStatusString' ? statusColorStyle : ''}
            name={field?.name}
            className='col-12'
            id={field?.name}
            required={field?.required}
            onChange={(e: Object) => {
              toast.info('This is a display only field')
            }}
            value={selectValue}
            options={selectOptions}
          />
        </>
      )
    case 'textarea':
      return (
        <>
          <EditableLabel
            label={field?.label}
            onChange={onChangeLabel}
            isRequired={field?.required}
          />
          <textarea
            name={field?.name}
            className={`form-control form-control-sm`}
            id={field?.name}
            required={field?.required}
            rows={2}
            onChange={(e) => {
              toast.info('This is a display only field')
            }}
            value={''}
          ></textarea>
        </>
      )
    case 'geolocation':
      return (
        <>
          <span>
            <EditableLabel
              label={field?.label}
              onChange={onChangeLabel}
              isRequired={field?.required}
            />

            <i
              className='fas fa-exclamation-circle ms-2 fs-7 clickable'
              data-bs-toggle='tooltip'
              title={'This coords  will show up as a tiltop'}
            ></i>
          </span>

          <GeolocationSelect
            simpleValue=''
            onChange={async (params: any) => {
              toast.info('This is a display only field')
            }}
            onInputChange={(value: string): void => { }}
          />

          {field?.additionalInfoKey ? (
            <input
              name={field?.name}
              type='text'
              placeholder='Additional info'
              className='form-control form-control-sm mt-1'
              id={field?.name}
              onChange={(e) => {
                toast.info('This is a display only field')
              }}
              value={''}
            />
          ) : null}
        </>
      )
    case 'breakdownInfo':
      return (
        <BreakdownCardWrapper
          key={field?.name}
          label={field.label + ':'}
          breakdownIdentifier={field.name}
          tariffInfo={null}
        />
      )
    case 'courseMap':
      return <CourseMapWrapper startLocation={null} endLocation={null} waypointsArray={[]} />
    case 'customFieldForRepeat':
      return (
        <RepeatRequestField
          currentDate={{
            year: moment().year(),
            month: moment().month() + 1,
            day: moment().date(),
            hour: moment().hour(),
            minute: moment().minute(),
          }}
          onChangeLabel={onChangeLabel}
          fieldLabel={field.label || field.name}
          repeatOnDates={[]}
          setRepeatOnDates={() => {
            toast.info('This is a display only field')
          }}
          field={field}
        />
      )
    case 'checkbox':
      return (
        <Checkbox
          onChangeLabel={onChangeLabel}
          field={field}
          value={Boolean(false)}
          onChange={(e) => {
            toast.info('This is a display only field')
          }}
        />
      )
    case 'optionsSelect':
      return <OptionsSelectWrapper options={[]} selectedOptions={[]} setSelectedOptions={() => {}} currency={''} />
    case 'durationInput':
      return <DurationInput field={field} onChangeLabel={onChangeLabel} />
    case 'stopsList':
      return <StopsList field={field} onChangeLabel={onChangeLabel} />
    case 'passengersList':
      return <PassengersComponent field={field} onChangeLabel={onChangeLabel} />
    case 'greeterSignList':
      return <GreeterSignList field={field} onChangeLabel={onChangeLabel} />
    case 'header':
      return (
        <>
          <h5>{field?.text}</h5>
        </>
      )
    case 'paragraph':
      return (
        <>
          <p className={`text-${field?.paragraphType}`}>
            <strong>Lorem ipsum</strong>
          </p>
        </>
      )
    case 'searchSubscriptions':
      return (
        <SearchSubscriptionsField setSubscriptions={() => { }} field={field} onChangeLabel={onChangeLabel} />
      )
    case 'displaySubscriptions':
      return (
        <SubscriptionsDisplayField subscriptions={[]} field={field} onChangeLabel={onChangeLabel} />
      )
    case 'poiComponent':
      return (
        <PoiFormWrapper
          onChangeLabel={onChangeLabel}
          field={field}
          intermPOIList={[]}
          setIntermPOIList={(e) => {
            // if e is not array
            toast.info('This is a display only field')
          }}
          pois={[]}
        />
      )
    case 'currencyInput':
      return (
        <CurrencyInput
          onChangeLabel={onChangeLabel}
          field={field}
          value={'0.00'}
          onChange={(e) => {
            toast.info('This is a display only field')
          }}
        />
      )
    case 'composite':
      return (
        <div className='composite'>
          {field?.title && (
            <h5 className='pb-4 mb-7 border-bottom text-primary'>{field?.title}:</h5>
          )}
          {field?.fields?.map((field: any) => generateField(field))}
        </div>
      )
    case 'alert':
      return (
        <>
          <EditableLabel
            onChange={onChangeLabel}
            label={field?.label}
            isRequired={field?.required}
          />

          <div
            className={`mb-0 alert primary`}
            style={{ backgroundColor: '#F3F6F4', minHeight: '47.5px' }}
            role='alert'
          >
            {''}
          </div>
        </>
      )
    case 'transportPhotos':
      return (
        <TransportDocumentWrapper
          transportRequest={{}}
          documents={[]}
          documentTypes={[]}
          field={field}
          type='photos'
        />
      )
    case 'transportPvs':
      return (
        <TransportDocumentWrapper
          transportRequest={{}}
          documents={[]}
          documentTypes={[]}
          field={field}
          type='pvs'
        />
      )
    default:
      return <></>
  }
}

import {TransportType} from '../../../types/general.types'
import CellWrapper from './CellWrapper'
import TransportCellWrapper from './TransportCellWrapper'
import {Inclusiveness, isBetween, Schedule, ShiftCornerComponent} from './utils'

const AssignmentsCell = ({
  transports,
  driver,
  index,
  rowIndex,
  currentInterval,
  nextInterval,
  driverShiftsMap,
  shiftTypes,
  showShifts,
  showSchedules,
  currentIntervalIndex,
  driverSchedule,
  mode = 'drivers'
}: {
  transports: any[]
  driver?: any
  index: number
  rowIndex?: number
  currentInterval: any
  nextInterval: any
  driverShiftsMap?: any
  shiftTypes?: any[]
  showShifts?: boolean
  showSchedules?: boolean
  currentIntervalIndex: number
  driverSchedule?: Schedule
  mode?: any
}) => {
  // Current interval date
  let currentIntervalDate = new Date(currentInterval.date + ' ' + currentInterval.hour)
  const currentIntervalDateTime = currentIntervalDate.getTime()

  // Next interval date
  let nextIntervalDate = new Date(nextInterval.date + ' ' + nextInterval.hour)
  const nextIntervalDateTime = nextIntervalDate.getTime()

  // Create the blue cells
  let children = transports
    .sort((a, b) => {
      let aDate = new Date(a.effectiveDate)
      let aDateTime = aDate.getTime()

      let bDate = new Date(b.effectiveDate)
      let bDateTime = bDate.getTime()

      if (aDateTime < bDateTime) {
        return -1
      }
      return 1
    })
    .map((transport: TransportType) => {
      return (
        <TransportCellWrapper
          mode={mode}
          driver={driver}
          key={JSON.stringify(transport)}
          index={index}
          transport={transport}
        />
      )
    })

  // Make border bottom red if driver currentShift is in the current interval
  let wrapperStyleShift = {},
    wrapperStyleSchedule = {}
  let topLeftComponent = null
  let topRightComponent = null

  if (driver && showShifts) {
    let startShift = driverShiftsMap.find((e) => e.titleColor === 'start-shift')

    let endShift = driverShiftsMap.find(
      (e) => e.titleColor === 'end-shift' || e.titleColor === 'end-shift-estimated'
    )
    /* SHIFTS */
    if (startShift && endShift) {
      let shiftStartDate = new Date(startShift.startDate)
      let shiftStartDateTime = shiftStartDate.getTime()

      let shiftEndDate = new Date(endShift.endDate || endShift.endDateAccordingToNormAndPauseTime)
      let shiftEndDateTime = shiftEndDate.getTime()
      // Start
      if (
        shiftStartDateTime >= currentIntervalDateTime &&
        shiftStartDateTime < nextIntervalDateTime
      ) {
        topLeftComponent = (
          <ShiftCornerComponent currentShift={startShift} label='Start' shiftTypes={shiftTypes} />
        )
        wrapperStyleShift = {
          ...wrapperStyleShift,
          borderLeft: '3px solid #48a841',
          borderBottom: '3px solid #48a841',
        }
      }

      // Between
      if (
        shiftStartDateTime < currentIntervalDateTime &&
        currentIntervalDateTime < shiftEndDateTime
      ) {
        wrapperStyleShift = {...wrapperStyleShift, borderBottom: '3px solid #48a841'}
      }

      // Finish
      if (shiftEndDateTime >= currentIntervalDateTime && shiftEndDateTime < nextIntervalDateTime) {
        if (!topLeftComponent) {
          topRightComponent = (
            <ShiftCornerComponent currentShift={endShift} label='Finish' shiftTypes={shiftTypes} />
          )
        }
        wrapperStyleShift = {
          ...wrapperStyleShift,
          borderRight: endShift.endDate ? '3px solid #48a841' : '3px dashed #48a841',
          borderBottom: '3px solid #48a841',
        }
      }
    } else if (startShift) {
      let shiftStartDate = new Date(startShift.startDate)
      let shiftStartDateTime = shiftStartDate.getTime()

      if (
        shiftStartDateTime >= currentIntervalDateTime &&
        shiftStartDateTime < nextIntervalDateTime
      ) {
        topLeftComponent = (
          <ShiftCornerComponent currentShift={startShift} label='Start' shiftTypes={shiftTypes} />
        )
        wrapperStyleShift = {borderLeft: '3px solid #48a841'}
      }
    }
  }
  /* SCHEDULES */
  if (driverSchedule && showSchedules) {
    const morningStartDateTime = driverSchedule.morningStartDate
        ? driverSchedule.morningStartDate.getTime()
        : 0,
      morningEndDateTime = driverSchedule.morningStartDate
        ? driverSchedule.morningEndDate.getTime()
        : 0
    const eveningStartDateTime = driverSchedule.eveningStartDate
        ? driverSchedule.eveningStartDate.getTime()
        : 0,
      eveningEndDateTime = driverSchedule.eveningEndDate
        ? driverSchedule.eveningEndDate.getTime()
        : 0
    const nextMorningStartDateTime = driverSchedule.nextMorningStartDate
        ? driverSchedule.nextMorningStartDate.getTime()
        : 0,
      nextMorningEndDateTime = driverSchedule.nextMorningEndDate
        ? driverSchedule.nextMorningEndDate.getTime()
        : 0
    const isScheduleStart = (comparable: number) =>
      isBetween({
        left: currentIntervalDateTime,
        right: nextIntervalDateTime,
        comparable,
        inclusiveness: Inclusiveness.LeftInclusive,
      })
    let isMorningScheduleStart = isScheduleStart(morningStartDateTime)
    let isEveningScheduleStart = isScheduleStart(eveningStartDateTime)
    let isNextMorningScheduleStart = isScheduleStart(nextMorningStartDateTime)
    // Start
    if (isMorningScheduleStart || isEveningScheduleStart || isNextMorningScheduleStart) {
      wrapperStyleSchedule = {
        borderBottom: '3px solid #009EF7',
        borderLeft: '3px solid #009EF7',
      }
    }

    const isInSchedule = (left: number, right: number) =>
      isBetween({
        left,
        right,
        comparable: currentIntervalDateTime,
        inclusiveness: Inclusiveness.Exclusive,
      })
    let isMorningSchedule = isInSchedule(morningStartDateTime, morningEndDateTime)
    let isEveningSchedule = isInSchedule(eveningStartDateTime, eveningEndDateTime)
    let isNextMorningSchedule = isInSchedule(nextMorningStartDateTime, nextMorningEndDateTime)
    // Between
    if (isMorningSchedule || isEveningSchedule || isNextMorningSchedule) {
      wrapperStyleSchedule = {
        borderBottom: '3px solid #009EF7',
      }
    }

    const isScheduleEnd = (comparable: number) =>
      isBetween({
        left: currentIntervalDateTime,
        right: nextIntervalDateTime,
        comparable,
        inclusiveness: Inclusiveness.RightInclusive,
      })
    let isMorningScheduleEnd = isScheduleEnd(morningEndDateTime)
    let isEveningScheduleEnd = isScheduleEnd(eveningEndDateTime)
    let isNextMorningScheduleEnd = isScheduleEnd(nextMorningStartDateTime)
    // Finish
    if (isMorningScheduleEnd || isEveningScheduleEnd || isNextMorningScheduleEnd) {
      wrapperStyleSchedule = {
        borderBottom: '3px solid #009EF7',
        borderRight: '3px solid #009EF7',
      }
    }
  }

  return (
    <CellWrapper
      mode={mode}
      driver={driver}
      style={{ ...wrapperStyleShift, ...wrapperStyleSchedule, position: 'relative' }}
      index={index}
      rowIndex={rowIndex}
      currentIntervalIndex={currentIntervalIndex}
    >
      {topLeftComponent ? topLeftComponent : null}
      {topRightComponent ? topRightComponent : null}
      {children}
    </CellWrapper>
  )
}

export default AssignmentsCell

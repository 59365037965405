import { useContext, useState } from 'react'
import HtmlEditor from '../../../../components/html-editor/HtmlEditor.component'
import Input from '../../../../components/input/input.component'
import { CompanyPackageGeneralInfo } from '../../../../types/company.types'
import { PackageContext } from '../../../../context/packages.context'
import Loading from '../../../../_metronic/layout/components/loading/Loading'
import { API_URL } from '../../../../constants/api.constants'
import { KTSVG } from '../../../../_metronic/helpers'


const AddEditGeneralInfo = ({
  generalInfo,
  setGeneralInfo
}:{
  generalInfo:CompanyPackageGeneralInfo,
  setGeneralInfo:Function
}) => {
  const { createAndUpdateCompanyPackageDetails, isLoading } = useContext<any>(PackageContext);

  const canSave = generalInfo?.pdf_title !== '' && (generalInfo?.photo || generalInfo.photo_path) 

  const getDocName = (file) =>{
    const splitFile = file.split(/[/\\]+/);
    return splitFile[splitFile.length-1]
  }

  const deleteDoc = (type:string) => {
    if (type === 'photo') {
      setGeneralInfo(prevState => ({
        ...prevState,
        photo: '',
        photo_path: ''
      }))
      return;
    }
    setGeneralInfo(prevState => ({
      ...prevState,
      uploaded_document: '',
      uploaded_document_path: ''
    }))
  }

  const fileField = (inputValue:string, docPath:string, type:string) =>{
    return <div className='documentContainer'>
      <div style={{
        width: '70%', display: 'flex', alignItems: 'center' }}>
        <p className='textDoc'>
            {getDocName(inputValue || docPath)}
        </p>
      </div>
      <div
        style={{ marginRight: 5 }}
        onClick={()=>{deleteDoc(type)}}
        className='btn btn-active-color-primary btn-sm'
      >
        <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
      </div>
    </div>
  }

  return (
    <div
      // @ts-ignore
      className={`card draggable has-card-options`}
      style={{ marginTop: 16, marginBottom:16 }}
    >
      <h1 className='py-5 px-8 d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
      Informatii generale despre pachet
      </h1>

        <ul className='autonom-dynamic-card'>

          <Input
            style={{ width: '25%' }}
            isRequired
            label='Titlu email'
            className='textField'
            value={generalInfo.email_title ?? ""}
            onChange={(event) => {
              let value = event.target.value;
              setGeneralInfo(prevState => ({
                ...prevState,
                email_title: value
              }))
            }} />
          <div style={{width:'30%'}}>
            <label className='form-label'>
              Ataseaza un document email-ului 
            </label>
            {generalInfo?.uploaded_document?.inputValue || generalInfo?.uploaded_document_path ?
              fileField(
                generalInfo?.uploaded_document?.inputValue,
                generalInfo?.uploaded_document_path,
                'doc'
              )
              :
              <input
                className={`form-control form-control-sm`}
                value={generalInfo?.uploaded_document?.inputValue || ''}
                onChange={(event) => {
                  let value: any = event.target.files[0]
                  value.inputValue = event.target.value
                  setGeneralInfo(prevState => ({
                    ...prevState,
                    uploaded_document: value
                  }))
                }}
                type={'file'}
              />
            }
          </div>

          <div style={{width:'100%'}}>
            <label className='form-label'>
              Continut email
              <span className='text-danger'>*</span>
            </label>
            <textarea
              rows={10}
              className='form-control form-control-sm textarea'
              onChange={(e) => {
                let value = e.target.value;
                setGeneralInfo(prevState => ({
                  ...prevState,
                  email_template: value
                }))
              }}
              value={generalInfo.email_template ?? ""}
            />
          </div>

          <Input
            style={{ width: '25%' }}
            label='Titlul contractului'
            className='textField'
            isRequired
            value={generalInfo.pdf_title}
            onChange={(event) => {
              let value = event.target.value;
              setGeneralInfo(prevState => ({
                ...prevState,
                pdf_title: value
              }))
            }} />

          <div style={{width:'30%'}}>
            <label className='form-label'>
              Ataseaza LOGO-ul companiei pe contract
              <span className='text-danger'>*</span>
            </label>
            {generalInfo?.photo?.inputValue || generalInfo?.photo_path ?
                fileField(
                  generalInfo?.photo?.inputValue,
                  generalInfo?.photo_path,
                  'photo')
              : 
                <input
                  className={`form-control form-control-sm`}
                  accept="image/*"
                  value={generalInfo?.photo?.inputValue || ''}
                  onChange={(event) => {
                    let value:any = event.target.files[0]
                    value.inputValue = event.target.value
                    setGeneralInfo(prevState => ({
                      ...prevState,
                      photo: value
                    }))
                  }}
                  type={'file'}
                />
              }
          </div>
        </ul>
        <div style={{ paddingLeft: '3.3%', paddingRight: '2.1%'}}>
          <p className='form-label'>Conditiile generale ale contractului</p>
          <HtmlEditor company_id={generalInfo.company_id} value={generalInfo.general_conditions_text} setValue={(val)=>{
            setGeneralInfo(prevState => ({
              ...prevState,
              general_conditions_text: val
            }))
          }}/>
        </div>
        <div className='page__top-buttons pr-10 card-header border-0 pt-10 mb-10'>
          <button disabled={isLoading || !canSave} className='btn btn-primary item-right' onClick={() => { 
            createAndUpdateCompanyPackageDetails({payload:{...generalInfo, general_conditions_text: "<span>" + generalInfo.general_conditions_text.split(". ").join(". <span></span>") + "</span>"}})
          }}>
            <div style={{display:'flex'}}>
              {isLoading ? <span className="loader"></span> : null}
              Save
            </div>
          </button>
        </div>
    </div>
  )
}

export default AddEditGeneralInfo

import {uuid4} from '@sentry/utils'
import moment from 'moment'
import React, {useState} from 'react'
import ModalPortal from '../../../../_metronic/layout/components/modal/modal-portal'
import {EditableLabel} from '../../add-edit-template/components/fields-generator'

export type TransportStop = {
  durationInMinutes: number
  location: string
  startHour: Date | string
  stopHour: Date | string
  type: string
}

export type TransportStopTemp = {
  durationInMinutes: number
  location: string
  startHour: Date | string
  stopHour: Date | string
  type: string
  tempKey: string
}

const TableHeader = () => {
  return (
    <thead>
      <tr className='fw-bolder text-muted'>
        <th className='p-2'>No.</th>
        <th className='p-2'>Location</th>
        <th className='p-2' style={{whiteSpace: 'nowrap'}}>
          Duration [min]
        </th>
        <th className='p-2' style={{whiteSpace: 'nowrap'}}>
          Start Hour
        </th>
        <th className='p-2' style={{whiteSpace: 'nowrap'}}>
          Stop Hour
        </th>
      </tr>
    </thead>
  )
}

const TableRow = ({transportStop, index}: {transportStop: TransportStopTemp; index: number}) => {
  const formattedStartHour = Boolean(transportStop.startHour)
    ? moment(transportStop.startHour).format('YYYY-MM-DD HH:mm')
    : ''
  const formattedStopHour = Boolean(transportStop.stopHour)
    ? moment(transportStop.stopHour).format('YYYY-MM-DD HH:mm')
    : ''

  return (
    <tr>
      <td className='p-2'>{index + 1}</td>
      <td className='p-2'>{transportStop.location}</td>
      <td className='p-2'>{transportStop.durationInMinutes}</td>
      <td className='p-2'>{formattedStartHour}</td>
      <td className='p-2'>{formattedStopHour}</td>
    </tr>
  )
}

const getDisplayTextFromStopsList = ({stopsList = []}: {stopsList: TransportStop[]}) => {
  const numOfStops = stopsList.length
  const stopsDuration = stopsList.map((transportStop: TransportStop) =>
    parseInt('' + (transportStop.durationInMinutes || 0))
  )
  const totalDuration = stopsDuration.reduce((sum: number, val: number) => sum + val, 0)

  return `${numOfStops} stops - total duration ${totalDuration} min`
}

const ViewModal = ({
  stopsList,
  modalVisible,
  title,
  titleStyle,
  setModalVisible,
}: {
  stopsList: TransportStopTemp[]
  modalVisible: boolean
  title: string
  titleStyle: {[prop: string]: string}
  setModalVisible: any
}) => {
  return (
    <ModalPortal
      title={title}
      titleStyle={titleStyle}
      setVisible={setModalVisible}
      visible={modalVisible}
      dialogClassName='modal-xxl'
    >
      <div className='w-100 px-4'>
        <table className='table table-hover table-row-gray-100 align-middle w-100'>
          <TableHeader />
          <tbody>
            {stopsList.map((transportStop: TransportStopTemp, index: number) => (
              <TableRow
                key={`row-${transportStop.tempKey}`}
                transportStop={transportStop}
                index={index}
              />
            ))}
          </tbody>
        </table>
      </div>
    </ModalPortal>
  )
}

const StopsList = ({
  stopsList = [],
  field = {},
  title = '',
  titleStyle = {},
  onChangeLabel,
  hideLabel = false,
}: {
  stopsList?: TransportStop[]
  field: any
  title?: string
  titleStyle?: {[prop: string]: string}
  onChangeLabel?: any
  hideLabel?: boolean
}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const localStopsList: TransportStopTemp[] = (stopsList || []).map(
    (transportStop: TransportStop) => ({
      ...transportStop,
      tempKey: uuid4(),
    })
  )

  return (
    <div key={`stopsList-${field?.name}`}>
      {!hideLabel ? (
        <EditableLabel label={field?.label} onChange={onChangeLabel} isRequired={field?.required} />
      ) : null}
      <button
        type='button'
        disabled={localStopsList.length === 0}
        onClick={() => {
          setModalVisible(true)
        }}
        style={{border: 'none', backgroundColor: 'white', color: '#009ef7', paddingLeft: 0}}
      >
        {getDisplayTextFromStopsList({stopsList})}
      </button>
      <ViewModal
        stopsList={localStopsList}
        modalVisible={modalVisible}
        title={title}
        titleStyle={titleStyle}
        setModalVisible={setModalVisible}
      />
    </div>
  )
}

export default StopsList

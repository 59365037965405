import {GoogleMap, Marker} from '@react-google-maps/api'
import {useContext, useEffect, useMemo, useState} from 'react'
import {CarsContext} from '../../../../context/cars.context'

const mapContainerStyle = {
  width: '100%',
  height: '100%',
}

const normalImage = 'https://i.imgur.com/qjwQQFK.png'
const selectedImage = 'https://i.imgur.com/arBN5Aq.png'

export const CarsMapComponent: any = () => {
  const {carsData, setCurrentCar, setShowCreateCarModal, currentCar, isSelectedCar, selectedCars} =
    useContext<any>(CarsContext)
  const [initialCenter, setInitialCenter] = useState({
    lat: 44.48244,
    lng: 26.09223,
  })

  const zoom = useMemo(() => 17, []);

  useEffect(() => {
    if (currentCar) {
      setInitialCenter({
        lat: parseFloat(currentCar.location.lat || 0),
        lng: parseFloat(currentCar.location.long || 0),
      })
    }
  }, [currentCar])

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      key={JSON.stringify(initialCenter)}
      center={initialCenter}
      zoom={zoom}
    >
      {carsData.map((car, i) => {
        return (
          <Marker
            key={car.id + JSON.stringify(selectedCars)}
            // @ts-ignore
            icon={{
              url: isSelectedCar(car) ? selectedImage : normalImage,
              anchor: new google.maps.Point(42, 42),
              scaledSize: new google.maps.Size(42, 42),
            }}
            position={{
              lat: parseFloat(car.location.lat || 0),
              lng: parseFloat(car.location.long || 0),
            }}
            onClick={() => {
              setCurrentCar(car)
              setShowCreateCarModal(true)
            }}
            title={'Current location'}
          />
        )
      })}
    </GoogleMap>
  )
}

import { toast } from 'react-toastify'
import { EASYTRACK_API_URL } from '../../../constants/api.constants'
import easytrackAxios from '../../../setup/easytrack.axios'
import { RequestMethod, easytrackRequest } from '../../../utils/api.utils';

const isRequestSuccessful = ({ result, successMessage }: { result: any; successMessage: string }) => {
  let status = result?.status || result?.response?.status
  if (result?.response?.data?.error || (status && status != 200)) {
    // Error
    toast.error(result?.response?.data?.message || 'We have some problem with your last request!')
    return false
  }
  // Success
  toast.success(successMessage)
  return true
}

export const getDefaultBillingDate = async ({
  businessProfileId
}: {
  businessProfileId: string
}) => {
  const result = await easytrackRequest({ method: RequestMethod.GET, route: `/business-profiles/${businessProfileId}/billing-dates` })
  if (!result || result.error) {
    return { error: true, message: result?.message }
  }
  return { error: false, billingDate: result.data }
}

export const getPlans = async ({
  businessProfileId,
  companyId,
  searchToken,
  page,
  size,
  matchingDefaultBillingDate
}: {
  businessProfileId: string
  companyId?: string | number
  searchToken?: string
  page?: number
  size?: number
  matchingDefaultBillingDate?: boolean
}) => {
  let requestLink = `${EASYTRACK_API_URL}/business-profiles/${businessProfileId}`
  if (Boolean(companyId)) requestLink += `/companies/${companyId}`
  requestLink += '/pricing-plans/v2?'
  let options = []
  if (Boolean(searchToken) && searchToken.length > 2) { options.push(`search=${searchToken}`) }
  if (Boolean(page) && page > 0) { options.push(`page=${page}`) }
  if (Boolean(size) && size > 0) { options.push(`size=${size}`) }
  if ((matchingDefaultBillingDate != null && matchingDefaultBillingDate != undefined)) { options.push(`matchingDefaultBillingDate=${matchingDefaultBillingDate}`) }
  requestLink += options.join('&')
  const result = await easytrackAxios.get(requestLink).catch((err) => err)

  return result?.data || []
}

export const getPlan = async ({
  businessProfileId,
  companyId,
  planId,
}: {
  businessProfileId: string
  companyId: string | number
  planId: string | number
}) => {
  const result = await easytrackAxios
    .get(
      `${EASYTRACK_API_URL}/business-profiles/${businessProfileId}/companies/${companyId}/pricing-plans/${planId}`
    )
    .catch((err) => err)

  return result?.data || {}
}
export const getRentPlan = async ({
  companyId,
  planId,
}: {
  companyId: string | number
  planId: string | number
}) => {
  const result = await easytrackAxios
    .get(
      `${EASYTRACK_API_URL}/rent-plans/${companyId}`
    )
    .catch((err) => err)

  return result?.data || []
}

export const deletePlan = async ({
  businessProfileId,
  companyId,
  planId,
}: {
  businessProfileId: string
  companyId: string | number
  planId: string | number
}) => {
  const result = await easytrackAxios
    .delete(
      `${EASYTRACK_API_URL}/business-profiles/${businessProfileId}/companies/${companyId}/pricing-plans/${planId}`
    )
    .catch((err) => err)
  return isRequestSuccessful({ result: result, successMessage: 'Plan was deleted!' })
}

export const clonePlan = async ({
  businessProfileId,
  companyId,
  planId,
  extraInfo = {},
}: {
  businessProfileId: string
  companyId: string | number
  planId: string | number
  extraInfo?: any
}) => {
  const result = await easytrackAxios
    .post(
      `${EASYTRACK_API_URL}/business-profiles/${businessProfileId}/companies/${companyId}/pricing-plans/${planId}/clone`,
      extraInfo
    )
    .catch((err) => err)
  return isRequestSuccessful({ result: result, successMessage: 'Plan was cloned!' })
    ? result.data
    : null
}

export const updatePlan = async ({
  businessProfileId,
  companyId,
  plan,
}: {
  businessProfileId: string
  companyId: string | number
  plan: any
}) => {
  const result = await easytrackAxios
    .put(
      `${EASYTRACK_API_URL}/business-profiles/${businessProfileId}/companies/${companyId}/pricing-plans/${plan.id}`,
      plan
    )
    .catch((err) => err)
  return isRequestSuccessful({ result: result, successMessage: 'Plan was updated!' })
}

export const savePlan = async ({
  businessProfileId,
  companyId,
  plan,
}: {
  businessProfileId: string
  companyId: string | number
  plan: any
}) => {
  const result = await easytrackAxios
    .post(
      `${EASYTRACK_API_URL}/business-profiles/${businessProfileId}/companies/${companyId}/pricing-plans`,
      plan
    )
    .catch((err) => err)
  return isRequestSuccessful({ result: result, successMessage: 'Plan was saved!' })
}

export const getPricingPlanTemplate = async ({ businessProfileId }: { businessProfileId: string }) => {
  const result = await easytrackAxios
    .get(`${EASYTRACK_API_URL}/business-profiles/${businessProfileId}/pricing-plans/template`)
    .catch((err) => err)
  return result
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import '../../../app/modules/autonom.scss'
import RentPlans from './modules/RentPlans'
import {disableMaxWidth, enableMaxWidth} from '../../../utils/style.utils'
import {PageTitle} from '../../../_metronic/layout/core'

const TransportPage: FC = () => {
  const {companyId} = useParams<any>()
  return (
    <>
      {/* begin::NewPlanButton */}
      <div className='page__top-buttons'>
        <Link className='btn btn-primary item-right' to={`../add-edit-rent-plan/${companyId}`}>
          New Plan
        </Link>
      </div>
      {/* end::NewPlanButton */}
      {/* begin::Row */}
      <div className='row gy-5 g-xl-12'>
        {/* begin::Col */}
        <div className='col-xxl-12'>
          <RentPlans companyId={companyId} />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
    </>
  )
}

const RentPlansWrapper: FC = () => {
  useEffect(() => {
    disableMaxWidth()

    return () => {
      enableMaxWidth()
    }
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={[]}>Company Rent Plans</PageTitle>
      <TransportPage />
    </>
  )
}

export default RentPlansWrapper

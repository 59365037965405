import React, { useContext, useState } from 'react'
import { TransportsContext } from '../../../../../context/transports.context'
import Loading from '../../../../../_metronic/layout/components/loading/Loading'

const ExportFileModal = ({
  isXLS,
  transports,
  exportXLS,
  exportPDF,
  setModalVisible,
  allTransportsSelected,
  onCancelModal,
}: {
  isXLS: boolean
  transports: any[]
  exportXLS: (checkedClientBreakdown: boolean, checkedSupplierBreakdown: boolean) => Promise<void>
  exportPDF: () => Promise<any>
  setModalVisible: (modalVisible: boolean) => void
  allTransportsSelected: boolean
  onCancelModal: Function
}) => {
  const { pageCount } = useContext<any>(TransportsContext)
  const [checkedClientBreakdown, setCheckedClientBreakdown] = useState<boolean>(false)
  const [checkedSupplierBreakdown, setCheckedSupplierBreakdown] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <div className='d-flex flex-column'>
      {isLoading ? (
        <Loading text={`Preparing the data for export ${isXLS ? 'XLS' : 'PDF'}`} />
      ) : (
        <>
          <div className='modal-header'>
            <p className='fs-2 text-center modal-title'>
              Do you want to export
              <span>
                {allTransportsSelected ? ` ${pageCount} pages of ` : ` ${transports.length} `}
              </span>
              transports as {isXLS ? 'an XLS' : 'a PDF'} file?
            </p>
          </div>

          {isXLS && (
            <div className='d-flex modal-body flex-column justify-content-between align-items-between gap-4 fs-4 pb-0'>
              <span>Do you want to add additional Sheets?</span>
              <div className='d-flex justify-content-start align-items-center'>
                <input
                  className='clickable form-check-input w-36px h-36px'
                  type='checkbox'
                  checked={checkedClientBreakdown}
                  onChange={(e) => {
                    let value = e.target.checked
                    setCheckedClientBreakdown(value)
                  }}
                />
                <span className='ms-2'>Client Breakdown</span>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-1'>
                <input
                  className='clickable form-check-input w-36px h-36px'
                  type='checkbox'
                  checked={checkedSupplierBreakdown}
                  onChange={(e) => {
                    let value = e.target.checked
                    setCheckedSupplierBreakdown(value)
                  }}
                />
                <span className='ms-2'>Supplier Breakdown</span>
              </div>
            </div>
          )}

          <div className={`${isXLS ? 'modal-footer' : 'mb-5'} d-flex justify-content-center mt-7`}>
            <div className='btn btn-danger me-5' onClick={() => onCancelModal()}>
              Cancel
            </div>
            <div
              className='btn btn-primary'
              onClick={async () => {
                setIsLoading(true)
                if (isXLS) {
                  await exportXLS(checkedClientBreakdown, checkedSupplierBreakdown)
                } else {
                  await exportPDF()
                }

                setIsLoading(false)
                setModalVisible(false)
              }}
            >
              Export
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ExportFileModal

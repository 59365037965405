import React, { useEffect, useState } from 'react'
import { FlatSubscriptionType, SubscriptionType } from '../../../../../types/subscription.types'
import { StateSetter } from '../../../../../utils/state.utils'
import { RequestMethod, orbitRequest } from '../../../../../utils/api.utils'
import { toast } from 'react-toastify'
import { LoadingCard } from '../../../../../_metronic/layout/components/loading/Loading'
import DisplaySubscriptionModal from '../../../add-edit-transport/components/subscriptions/DisplaySubscriptionModal'

const handleFindSubscription = async ({
  subscriptionId,
  setLoadedSubscription,
  setModalVisible
}: {
  subscriptionId: number,
  setLoadedSubscription: StateSetter<SubscriptionType>
  setModalVisible: StateSetter<boolean>
}) => {
  const subscriptionResponse = await orbitRequest({
    route: `/subscription?id=${subscriptionId}`,
    method: RequestMethod.GET,
  })
  if (Boolean(subscriptionResponse) && !subscriptionResponse.error) {
    setLoadedSubscription(subscriptionResponse?.subscription)
  } else {
    toast.error(`Nu s-a putut găsi subscripția cu id ${subscriptionId}`)
    setModalVisible(false)
  }
}

const ViewSubscriptionModal = ({
  subscription,
  modalVisible,
  setModalVisible
}: {
  subscription: FlatSubscriptionType,
  modalVisible: boolean,
  setModalVisible: StateSetter<boolean>
}) => {
  const [loadedSubscription, setLoadedSubscription] = useState<SubscriptionType | null>(null)
  const subscriptionId = subscription?.id

  useEffect(() => {
    if (subscriptionId) {
      handleFindSubscription({ subscriptionId, setLoadedSubscription, setModalVisible })
    }
  }, [subscriptionId])

  if (!loadedSubscription) {
    return <LoadingCard />
  }

  return (
    <DisplaySubscriptionModal
      subscription={loadedSubscription}
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
    />
  )
}

export default ViewSubscriptionModal
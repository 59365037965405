import { Button } from 'react-bootstrap';
import Select from '../../../../_metronic/layout/components/select/Select';
import '../modules/form.scss';
import { useEffect, useState } from 'react';

import {
  ClientCampaignType,
  ClientGeneralFieldsType,
  CountyType,
  FormStatusType,
  LocationType,
  PlaceOfBusinessType,
  emptyClientCampaign,
} from '../../../../types/campaign.types';
import { toast } from 'react-toastify';
import { getSortedList } from '../../../../utils/sort.utils';
import { translation } from '../../../../utils/campaign.utils';
import { RequestMethod, orbitRequest } from '../../../../utils/api.utils';
import { APIResponseType } from '../../../../types/general.types';
import {
  getCountiesRequest,
  getLocationsByCountyRequest,
  getPlacesOfBusinessByLocationRequest,
} from '../../../../setup/axios/campaign.request';
import { LoadingCard } from '../../../../_metronic/layout/components/loading/Loading';

export const kmAndTiresUserUpdate = async (
  data: ClientCampaignType,
  userGeneralData: ClientGeneralFieldsType
): Promise<APIResponseType> =>
  await orbitRequest({
    route: `/campaign-clients/update`,
    payload: {
      id: Number(data.id),
      user_id: Number(data.user_id),
      campaign_id: Number(data.campaign_id),
      company_id: Number(data.company_id),
      car_id: Number(data.car_id),
      business_id: Number(data.business_id) === 0 ? null : Number(data.business_id),
      short_code: data.short_code,
      is_client_details_correct: Number(data.is_client_details_correct) === 1 ? true : false,
      user_feedback: data.user_feedback,
      status: FormStatusType.UPDATED,
      brand: data.car_brand,
      model: data.car_model,
      license_number: data.car_license_number,
      number_of_km: Number(data.car_number_of_km),
      preferred: data.car_preferred,
      company_name: data.company_name,
      first_name: userGeneralData.user_first_name,
      last_name: userGeneralData.user_last_name,
      email: userGeneralData.user_email,
      user_phone: data.user_phone,
      county: data.user_county,
      location: data.user_location,
      preferred_language: data.user_preferred_language,
      is_data_processing_approved:
        Number(data.user_is_data_processing_approved) === 1 ? true : false,
      is_promotion_approved: Number(data.user_is_promotion_approved) === 1 ? true : false,
      is_owner: Number(data.is_owner) === 1 ? true : false,
    },
    method: RequestMethod.POST,
  });

const KmAndTiresFields = ({
  client,
  userGeneralData,
  fetchData,
}: {
  client: ClientCampaignType;
  userGeneralData: ClientGeneralFieldsType;
  fetchData: () => Promise<void>;
}) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isCarOwner, setIsCarOwner] = useState<'not_checked' | 'yes' | 'no'>('not_checked');
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [counties, setCounties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [placesOfBusiness, setPlacesOfBusiness] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [isConditionChecked, setIsConditionChecked] = useState<boolean>(false);
  const [selectedPlaceOfBusiness, setSelectedPlaceOfBusiness] =
    useState<PlaceOfBusinessType | null>(null);
  const [formData, setFormData] = useState<ClientCampaignType>(emptyClientCampaign);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const generalFields = [
    'is_client_details_correct',
    'user_is_promotion_approved',
    'user_is_data_processing_approved',
  ];

  const handleInputChange = async (fieldName: string, value: number | string) => {
    if (generalFields.includes(fieldName)) {
      setFormData((oldData) => ({
        ...oldData,
        [fieldName]: value,
      }));
    } else {
      setFormData((oldData) => ({
        ...oldData,
        [fieldName]: value,
        is_sent: 1,
      }));
    }
  };

  const submitChanges = async () => {
    setLoading(true);

    const userResponse = await kmAndTiresUserUpdate(formData, userGeneralData);
    setLoading(false);
    if (!userResponse || userResponse.error) {
      toast.error(
        `${translation.userError || 'Something went wrong'}! ${userResponse?.message || ''}`
      );
      return;
    }
    fetchData();
    toast.success(translation.userSuccessfulUpdate);
    await toggleForm();
    return;
  };

  const toggleForm = async () => {
    setIsFormLoading(true);
    if (!isFormVisible) {
      await loadData();
    }
    setIsFormLoading(false);
    setIsFormVisible(!isFormVisible);
    setIsCarOwner('not_checked');
  };

  const checkExistingData = async () => {
    setFormData({
      ...client,
      user_first_name: userGeneralData.user_first_name,
      user_last_name: userGeneralData.user_last_name,
      user_email: userGeneralData.user_email,
      user_phone: userGeneralData.user_phone,
      user_feedback: '-',
    });
    setIsConditionChecked(client?.is_client_details_correct === 1 ? true : false);
    return;
  };

  const getAndSetCounties = async () => {
    const countiesResponse = await getCountiesRequest();
    if (!countiesResponse || countiesResponse.error) {
      toast.error(countiesResponse?.message || 'Something went wrong');
      return;
    }

    const countiesList = countiesResponse?.counties?.map((county, index) => ({
      id: index,
      county: county,
    }));
    setCounties(countiesList);

    // find the county that matches the client's county
    const defaultCounty = countiesList?.find(
      (county) => county.county?.toLocaleLowerCase() === client?.user_county.toLocaleLowerCase()
    );
    setSelectedCounty(defaultCounty);

    // if a county was found => get the locations for that county
    if (defaultCounty) {
      const locationsResponse = await getLocationsByCountyRequest(defaultCounty.county);
      if (!locationsResponse || locationsResponse.error) {
        toast.error(locationsResponse?.message || 'Something went wrong');
        return;
      }

      const locations = locationsResponse?.locations?.map((location) => ({
        id: location.id,
        location: location.location,
      }));
      setLocations(locations);
      const defaultLocation = locations.find(
        (location) => location?.location.toLowerCase() === client?.user_location.toLowerCase()
      );

      if (defaultLocation && defaultLocation.location) {
        setSelectedLocation(defaultLocation);
        await getAndSetPlacesOfBusiness(defaultLocation.location);
      }
    }
  };

  const getAndSetPlacesOfBusiness = async (location: string) => {
    const placesOfBusinessResponse = await getPlacesOfBusinessByLocationRequest(location);

    if (!placesOfBusinessResponse || placesOfBusinessResponse.error) {
      toast.error(placesOfBusinessResponse?.message || `Something went wrong`);
      return;
    }

    const placesOfBusiness = placesOfBusinessResponse?.places_of_business.map((item) => ({
      id: item.id,
      name: item.name + ' - ' + formatAddress(item.street_address),
    }));

    // find the place of business that matches the client's
    const defaultPlaceOfBusiness = placesOfBusiness?.find(
      (item) => item.id === Number(client?.business_id)
    );
    setSelectedPlaceOfBusiness(defaultPlaceOfBusiness);
    setPlacesOfBusiness(placesOfBusiness);
  };

  const formatAddress = (address: string) => {
    const sectorRegex = /(Sector [1-6])/gi;
    const sectorMatch = address.match(sectorRegex);

    if (sectorMatch) {
      return sectorMatch[0];
    } else {
      return address;
    }
  };

  const loadData = async () => {
    await checkExistingData();
    await getAndSetCounties();
  };

  useEffect(() => {
    // remove body scroll when form is visible
    if (isFormVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isFormVisible]);

  return (
    <>
      <div className='form' style={{ backgroundColor: '#fff' }}>
        <div
          style={{
            maxWidth: '100%',
            padding: '10px 20px',
            backgroundColor: '#f2f2f2',
            borderRadius: 5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: '1px solid #eee',
          }}
          onClick={async () => {
            if (client.id === undefined) {
              client.id = 0;
            }
            await toggleForm();
          }}
        >
          <div>
            <p style={{ fontWeight: 600, marginRight: 10, margin: 0 }}>
              {client?.car_license_number || `${translation.licensePlate}`}
            </p>
            <p
              style={{
                fontSize: 14,
                color: '#666',
                margin: 0,
                marginTop: -5,
              }}
            >
              {client?.car_brand || ''} {client?.car_model || ''}
            </p>

            {/* last updated at friendly */}
            <p
              style={{
                fontSize: 12,
                color: '#666',
                margin: 0,
                marginTop: 5,
              }}
            >
              {translation.lastUpdated}:{' '}
              {client?.updated_at
                ? new Date(client?.updated_at).toLocaleDateString('ro-RO', {
                  hour: '2-digit',
                  minute: '2-digit',
                })
                : ''}
            </p>
          </div>

          <div
            style={{
              color: '#eee',
              fill: '#989898',
            }}
          >
            <svg
              width='24'
              height='24'
              xmlns='http://www.w3.org/2000/svg'
              fillRule='evenodd'
              clipRule='evenodd'
            >
              <path d='M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z' />
            </svg>
          </div>
        </div>

        {/* eye icon */}
      </div>
      {isFormLoading && (
        <div className='custom-form form center mb-5'>
          <LoadingCard />
        </div>
      )}
      {isFormVisible && (
        <div className='custom-form form center mb-5' style={{ overflowY: 'auto' }}>
          <div className='close-button-wrapper'>
            <div
              className='close-button'
              onClick={async () => {
                if (client.id === undefined) {
                  client.id = 0;
                }
                await toggleForm();
              }}
              style={{ marginLeft: 'auto' }}
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                <path
                  fill='currentColor'
                  d='M6.793 6.793a1 1 0 0 1 1.414 0L12 10.586l3.793-3.793a1 1 0 1 1 1.414 1.414L13.414 12l3.793 3.793a1 1 0 0 1-1.414 1.414L12 13.414l-3.793 3.793a1 1 0 0 1-1.414-1.414L10.586 12L6.793 8.207a1 1 0 0 1 0-1.414Z'
                  clipRule='evenodd'
                />
              </svg>
            </div>
          </div>

          {/* begin::Form */}
          <div className='form-content'>
            <div className='form-content-content'>
              <div className='wpcf7 js' id='wpcf7-f7-o1' lang='ro-RO' dir='ltr'>
                <div className='screen-reader-response'></div>
                <form
                  method='post'
                  className='wpcf7-form init'
                  aria-label='Formular de contact'
                  data-status='init'
                >
                  <h4 className='form-info'>{translation.instructions}</h4>
                  {client ? (
                    <>
                      {/* begin::NrKm */}

                      <div
                        style={{
                          marginBottom: 32,
                        }}
                      >
                        <label>{translation.stillCarOwner}</label>
                        <p
                          style={{
                            fontWeight: 600,
                          }}
                        >
                          {client.car_license_number}
                        </p>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8,
                          }}
                        >
                          <div
                            style={{
                              borderRadius: 10,
                              cursor: 'pointer',
                              maxWidth: '100%',
                              margin: '0px 10px 0px 0px',
                              height: 30,
                              width: 100,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              background: isCarOwner === 'no' ? '#009EF7' : 'none',
                              color: isCarOwner === 'no' ? '#fff' : '#009EF7',
                              border: '1px solid #009EF7',
                            }}
                            onClick={() => {

                              setFormData((oldData) => ({
                                ...oldData,
                                is_owner: 0,
                                is_client_details_correct: 1,
                              }));
                              setIsConditionChecked(true); // set

                              setIsCarOwner('no');
                            }}
                          >
                            {translation.no.toLocaleUpperCase()}
                          </div>

                          <div
                            style={{
                              borderRadius: 10,
                              cursor: 'pointer',
                              maxWidth: '100%',
                              width: 100,
                              margin: '0px 10px 0px 0px',
                              height: 30,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              background: isCarOwner === 'yes' ? '#009EF7' : 'none',
                              color: isCarOwner === 'yes' ? '#fff' : '#009EF7',
                              border: '1px solid #009EF7',
                            }}
                            onClick={() => {
                              setIsConditionChecked(false); // set
                              setFormData((oldData) => ({
                                ...oldData,
                                is_owner: 1,
                                is_client_details_correct: 0,
                              }));
                              setIsCarOwner('yes');
                            }}
                          >
                            {translation.yes.toLocaleUpperCase()}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {isCarOwner === 'yes' ? (
                    <>
                      <div className='mb-5'>
                        <label>{translation.noOfKm}</label>
                        <span className='wpcf7-form-control-wrap' data-name='model'>
                          <input
                            inputMode="numeric"
                            value={formData?.car_number_of_km || ''}
                            onChange={(e) => {
                              // allow only digits
                              const regex = /^[0-9]*$/;
                              if (!regex.test(e.currentTarget.value)) {
                                return;
                              }

                              // if first character is 0, dont allow any other digits
                              if (
                                e.currentTarget.value[0] === '0' &&
                                e.currentTarget.value.length > 1
                              ) {
                                return;
                              }

                              handleInputChange('car_number_of_km', e.target.value);
                            }}
                            style={{
                              borderColor: client.car_number_of_km ? '#eee' : 'red',
                              display: 'block',
                              backgroundColor: '#fff',
                              width: '100%',
                              border: '1px solid #eee',
                              borderRadius: '8px',
                              padding: '10px 15px',
                              margin: '0 0 10px',
                              color: '#000',
                            }}
                          />
                        </span>
                      </div>
                      {/* end::NrKm */}

                      {/* begin::Judet */}
                      <div className='mb-5 input-custom-wrapper'>
                        <Select
                          label={translation.county}
                          value={selectedCounty || ''}
                          options={getSortedList({ listToSort: counties || [], args: ['county'] })}
                          getOptionLabel={(option: CountyType) => option.county}
                          getOptionValue={(option: CountyType) => option.county}
                          styles={{
                            control: (baseStyles) =>
                              (client.user_county || selectedCounty)
                                ? {
                                  ...baseStyles,
                                  borderColor: '#eee',
                                }
                                : {
                                  ...baseStyles,
                                  borderColor: 'red',
                                },
                          }}
                          onChange={async (e: CountyType) => {
                            // reset the location when a new county is selected
                            setSelectedPlaceOfBusiness(null);
                            setPlacesOfBusiness([]);
                            setSelectedCounty(e);
                            setSelectedLocation(null);
                            const countiesResponse = await getLocationsByCountyRequest(e.county);
                            if (!countiesResponse || countiesResponse.error) {
                              toast.error(countiesResponse?.message || translation.error);
                              return;
                            }

                            setLocations(
                              countiesResponse.locations?.map((location) => ({
                                id: location.id,
                                location: location.location,
                              }))
                            );
                            handleInputChange('user_county', e.county);
                          }}
                        />
                      </div>
                      {/* end::Judet */}

                      {/* begin::Localitate */}
                      <div className='mb-5 input-custom-wrapper'>
                        <Select
                          label={translation.location}
                          value={selectedLocation || ''}
                          options={getSortedList({
                            listToSort: locations || [],
                            args: ['location'],
                          })}
                          getOptionLabel={(option: LocationType) => option.location}
                          getOptionValue={(option: LocationType) => option.location}
                          styles={{
                            control: (baseStyles) =>
                              (client.user_location || selectedLocation)
                                ? {
                                  ...baseStyles,
                                  borderColor: '#eee',
                                }
                                : {
                                  ...baseStyles,
                                  borderColor: 'red',
                                },
                          }}
                          onChange={(e: LocationType) => {
                            setSelectedLocation(e);
                            setSelectedPlaceOfBusiness(null);
                            setPlacesOfBusiness([]);
                            getAndSetPlacesOfBusiness(e.location);
                            handleInputChange('user_location', e.location);
                          }}
                        />
                      </div>
                      {/* end::Localitate */}

                      {/* begin::Vulcanizare */}
                      {(selectedCounty?.county === 'ILFOV' ||
                        selectedCounty?.county === 'BUCURESTI') &&
                        placesOfBusiness &&
                        placesOfBusiness.length > 0 ? (
                        <div className='mb-5 input-custom-wrapper'>
                          <Select
                            label={translation.vulcanization}
                            value={selectedPlaceOfBusiness || ''}
                            options={getSortedList({
                              listToSort: placesOfBusiness || [],
                              args: ['name'],
                            })}
                            getOptionLabel={(option: PlaceOfBusinessType) => option.name}
                            getOptionValue={(option: PlaceOfBusinessType) => option.name}
                            onChange={(e: PlaceOfBusinessType) => {
                              setSelectedPlaceOfBusiness(e);
                              handleInputChange('business_id', e.id);
                            }}
                          />
                        </div>
                      ) : null}
                      {/* end::Vulcanizare */}
                      <p>
                        <span className='wpcf7-form-control-wrap' data-name='date-corecte'>
                          <span className='wpcf7-form-control wpcf7-checkbox wpcf7-validates-as-required'>
                            <span
                              className='wpcf7-list-item first last'
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <input
                                id='date-corect'
                                type='checkbox'
                                //checked={formData.is_client_details_correct === 1 ? true : false}
                                onChange={(e) => {
                                  handleInputChange(
                                    'is_client_details_correct',
                                    client.is_client_details_correct === 1 ? 0 : 1
                                  );
                                  setIsConditionChecked(e.target.checked);
                                }}
                              ></input>
                              <label
                                style={{
                                  fontSize: 12,
                                  marginTop: 12,
                                }}
                                htmlFor='date-corect'
                                className='wpcf7-list-item-label'
                              >
                                {translation.areClientDetailsCorrect}
                              </label>
                            </span>
                          </span>
                        </span>
                        {!isConditionChecked && (
                          <p
                            style={{
                              cursor: 'unset',
                              color: 'red',
                              fontSize: 12,
                              marginLeft: 24,
                            }}
                          >
                            *{translation.requiredField}
                          </p>
                        )}
                      </p>

                      <p>
                        <span className='wpcf7-form-control-wrap' data-name='acord-date'>
                          <span className='wpcf7-form-control wpcf7-checkbox wpcf7-validates-as-required'>
                            <span
                              className='wpcf7-list-item first last'
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <input
                                id='acord-date'
                                type='checkbox'
                                //checked={client.user_is_data_processing_approved === 1 ? true : false}
                                onChange={() =>
                                  handleInputChange(
                                    'user_is_data_processing_approved',
                                    client.user_is_data_processing_approved === 1 ? 0 : 1
                                  )
                                }
                              ></input>
                              <label
                                style={{
                                  fontSize: 12,
                                  marginTop: 12,
                                }}
                                htmlFor='acord-date'
                                className='wpcf7-list-item-label'
                              >
                                {translation.isDataProcessingApproved}
                              </label>
                            </span>
                          </span>
                        </span>
                      </p>

                      <p>
                        <span className='wpcf7-form-control-wrap' data-name='acord-mkt'>
                          <span className='wpcf7-form-control wpcf7-checkbox'>
                            <span
                              className='wpcf7-list-item first last'
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <input
                                id='acord-mkt'
                                name='user_is_promotion_approved'
                                type='checkbox'
                                //checked={formData.user_is_promotion_approved === 1 ? true : false}
                                onChange={(e) => {
                                  handleInputChange(
                                    'user_is_promotion_approved',
                                    e.target.checked === false ? 0 : 1
                                  );
                                }}
                              ></input>
                              <label
                                style={{
                                  marginLeft: 6,
                                  fontSize: 12,
                                  marginTop: 12,
                                }}
                                htmlFor='acord-mkt'
                              >
                                {translation.isPromotionApproved}
                              </label>
                            </span>
                          </span>
                        </span>
                      </p>
                    </>
                  ) : null}

                  {/* end::Checkboxes */}

                  <Button
                    className='btn btn-primary btn-lg p-5 btn-success'
                    type='submit'
                    style={{ width: '100%', marginBottom: 50 }}
                    disabled={
                      !isConditionChecked ||
                      loading ||
                      isFormLoading ||
                      isCarOwner === 'not_checked'
                    }
                    onClick={async (e) => {
                      e.preventDefault();
                      if (isConditionChecked) {
                        submitChanges();
                      } else {
                        toast.error(translation.dataConfirmationRequired);
                      }
                    }}
                  >
                    {loading ? translation.loading : translation.submit}
                  </Button>
                </form>
              </div>
            </div>
          </div>
          {/* end::Form */}
        </div>
      )}
    </>
  );
};

export default KmAndTiresFields;

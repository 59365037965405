import {CSSProperties, memo, useRef, useState} from 'react'
import {toast} from 'react-toastify'
import {KTSVG} from '../../../../helpers/components/KTSVG'

const COMPLEX_EMAIL_PATTERN_STRING: string =
  '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)])'
const COMPLEX_REGEX_EMAIL: RegExp = new RegExp(COMPLEX_EMAIL_PATTERN_STRING)

export const isValidEmail = (input: string) => {
  if(!Boolean(input)) {
    return false;
  }
  return COMPLEX_REGEX_EMAIL.test(input)
}

export const ChipsTextbox = ({
  chips,
  setChips,
  fieldId,
  fieldLabel,
  maxLength = 0,
  additionalStyle = {height: '100px'},
}: {
  chips: string[]
  setChips: Function
  fieldId: string
  fieldLabel: string
  maxLength?: number
  additionalStyle?: CSSProperties
}) => {
  const [currentChip, setCurrentChip] = useState<string>('')
  const chipInputRef = useRef(null)
  const hasExceededLimit = Boolean(maxLength) && chips.length >= maxLength
  const disabledStyle = hasExceededLimit ? {opacity: 0} : {}

  const focusChip = () => {
    chipInputRef.current && chipInputRef.current.focus()
  }

  return (
    <>
      <div
        placeholder={fieldLabel}
        className='form-control pt-9'
        style={{overflowY: 'auto', ...additionalStyle}}
        onClick={focusChip}
        id={fieldId}
      >
        {chips.map((chip) => (
          <Chip key={chip} content={chip} chips={chips} setChips={setChips} />
        ))}
        <input
          className={'form-control ' + (chips.length > 0 ? 'ms-2' : '')}
          style={{
            border: 0,
            outline: 'none',
            padding: 0,
            borderRadius: 0,
            width: '20%',
            display: 'inline-block',
            ...disabledStyle,
          }}
          ref={chipInputRef}
          disabled={hasExceededLimit}
          onChange={(e) => {
            setCurrentChip(e.target.value)
          }}
          value={currentChip}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === 'Tab') {
              e.preventDefault()
              const futureChip = currentChip.trim()
              if (!chips.some((chip: string) => chip === futureChip)) {
                setChips([...chips, futureChip])
              }
              setCurrentChip('')
            } else if (e.key === 'Backspace') {
              if (currentChip.length === 0) {
                let futureChips = [...chips]
                futureChips.pop()
                setChips(futureChips)
              }
            }
          }}
        />
      </div>
      <label htmlFor={fieldId}>{fieldLabel}</label>
    </>
  )
}

const Chip = memo(
  ({content, chips, setChips}: {content: string; chips: string[]; setChips: Function}) => {
    const isContentValidEmail = isValidEmail(content)
    return (
      <span
        key={'chip-' + content}
        className={
          'user-select-none badge mb-1 me-1 ' +
          (isContentValidEmail ? 'badge-light' : 'badge-warning')
        }
        style={{borderRadius: '50px'}}
        onContextMenu={async (e) => {
          e.preventDefault()
          // Add text to clipboard
          navigator.clipboard.writeText(content)
          toast.success('Email copied to clipboard!')
        }}
        title={content}
      >
        {content.length > 30 ? content.substring(0, 30) + '..' : content}
        <span
          onClick={() => {
            let futureChips = chips.filter((oldChip: string) => content !== oldChip)
            setChips(futureChips)
          }}
        >
          <KTSVG
            path='/media/icons/duotone/Code/Error-circle.svg'
            className={'ms-1 svg-icon svg-icon-x1 clickable svg-icon-dark'}
          />
        </span>
      </span>
    )
  }
)

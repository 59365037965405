import {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {deleteCarClass} from '../../../../../setup/axios/rent.request'

import {KTSVG} from '../../../../../_metronic/helpers'
import {CarClassModel} from '../../../auth/models/CarClassModel'

type CarCardProps = {
  setCurrentCar: any
  setShowCarModal: any
  car: CarClassModel
  carsData: CarClassModel[]
  setCarsData: any
}

const CarClassCard: React.FC<CarCardProps> = ({
  setShowCarModal,
  setCurrentCar,
  car,
  carsData,
  setCarsData,
}) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)

  // Handle car class delete
  const deleteCar = async (car: CarClassModel) => {
    const result = await deleteCarClass(car)
    if (!Boolean(result)) {
      return toast.error("The Car Class couldn't be deleted!")
    }
    const futureCarsData = carsData.filter((e) => e.id !== car.id)
    setCarsData(futureCarsData)
    toast.success('Car class was deleted!')
  }

  return (
    <>
      {deleteModalVisible && (
        <Modal
          className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
          id='kt_inbox_compose'
          role='dialog'
          data-backdrop='static'
          aria-hidden='true'
          tabIndex='-1'
          show={deleteModalVisible}
          animation={true}
        >
          <div className='modal-content'>
            {/* begin::Form */}
            {/*begin::Header*/}
            <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5'>
              <h2 className='fw-bold m-0'> </h2>
              <div className='d-flex ms-2'>
                {/*begin::Close*/}
                <div
                  className='btn btn-icon btn-sm btn-light-primary ms-2'
                  data-bs-dismiss='modal'
                  onClick={() => setDeleteModalVisible(false)}
                >
                  <KTSVG className='svg-icon-1' path='/media/icons/duotone/Navigation/Close.svg' />
                </div>
                {/*end::Close*/}
              </div>
            </div>
            {/*end::Header*/}

            {/*begin::Body*/}
            <div className='d-block py-5 ps-8 pe-5'>
              {/* end::Form group */}

              <h2>Are you sure?</h2>
              <p>
                The car class <strong>{car.description}</strong>
                with registration id: <strong>{car.id}</strong>, will be deleted forever!
              </p>
            </div>
            {/*end::Body*/}

            {/*begin::Footer*/}
            <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-top'>
              {/*begin::Actions*/}
              <div className='d-flex align-items-center me-3'></div>
              {/*end::Actions*/}

              {/*begin::Toolbar*/}
              <div className='d-flex align-items-center'>
                <button onClick={() => deleteCar(car)} className='btn btn-primary px-6'>
                  Delete
                </button>
              </div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Footer*/}
            {/*end::Form*/}
          </div>
        </Modal>
      )}

      <div className='col'>
        <div
          className='card border border-3 rounded-3 border-secondary mb-3 text-center'
          style={{maxWidth: '24rem'}}
        >
          <div
            className='bg-secondary d-flex justify-content-evenly align-items-center text-dark fw-bolder'
            style={{height: '40px'}}
          >
            <span>{`ID: ${car.id}`}</span>
          </div>
          <div className='row g-0'>
            <div className='col-12'>
              <div style={{height: '200px', position: 'relative'}}>
                <img
                  style={{objectFit: 'contain'}}
                  src={car.image_url}
                  className='card-img w-100 h-100'
                  alt='car'
                />
              </div>

              <div className='d-flex justify-content-center flex-shrink-0 mb-2'>
                <button
                  title='Edit'
                  onClick={() => {
                    setCurrentCar(car)
                    setShowCarModal(true)
                  }}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-4'
                >
                  <KTSVG
                    path='/media/icons/duotone/Communication/Write.svg'
                    className='svg-icon-3'
                  />
                </button>
                <button
                  title='Delete'
                  onClick={async () => {
                    setDeleteModalVisible(true)
                  }}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                >
                  <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
                </button>
              </div>
            </div>
            <div className='bg-secondary card-body text-dark py-6'>
              <h5 className='card-title mb-4'>{car.description}</h5>
              <p className='card-subtitle mx-3 text-light bg-dark badge'>{car.vehicle_type}</p>
              <p className='card-text text-light bg-dark badge'>{car.name}</p>
            </div>
            <div className='bg-secondary'>
              <ul className='list-group list-group-horizontal'>
                <li className='list-group-item flex-fill'>
                  <img
                    src='https://www.autonom.ro//assets/uploads/rezervare/icons/cardoor.png'
                    width='24'
                    height='24'
                    alt=''
                  />
                  {car.number_of_doors}
                </li>
                <li className='list-group-item flex-fill'>
                  <img
                    src='https://www.autonom.ro//assets/uploads/rezervare/icons/baggage.png'
                    width='24'
                    height='24'
                    alt=''
                  />
                  {car.luggage_capacity}
                </li>
                <li className='list-group-item flex-fill'>
                  <img
                    src='https://www.autonom.ro//assets/uploads/rezervare/icons/gazstation.png'
                    width='24'
                    height='24'
                    alt=''
                  />
                  {car.fuel_type?.toString().split(',').join('-')}
                </li>
                <li className='list-group-item flex-fill'>
                  <img
                    src='https://www.autonom.ro//assets/uploads/rezervare/icons/gearbox.png'
                    width='24'
                    height='24'
                    alt=''
                  />
                  {car.transmission_type?.toString().split(',').join('-')}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CarClassCard

import React, {memo, useCallback, useRef} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {areEqualPOIS, POI} from './poiConstants'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {formatTimeStringFromMinutes} from '../../../utils/date.utils'

interface PointRowProps {
  point: POI
  setPointToEdit: Function
  deleteListElement: Function
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const PointRow = ({point, setPointToEdit, deleteListElement}: PointRowProps) => {
  const iconStyle = {
    width: 22,
    height: 22,
    marginTop: 4,
    marginBottom: 4,
  }

  // @ts-ignore
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: 16,
        borderBottom: '1px solid #eee',
        backgroundColor: 'white',
      }}
    >
      <div style={{width: '100%'}}>
        <div>{point.address}</div>
        <span
          style={{
            fontSize: '12px',
          }}
        >
          {point.addressDetails}
        </span>
      </div>
      {point.estimatedWait ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minWidth: '75px',
            borderRight: '1px solid #eee',
            paddingRight: 8,
          }}
        >
          <KTSVG path='/media/icons/duotone/Home/Clock.svg' className='svg-icon-1' />
          <span
            style={{
              fontSize: 10,
              marginTop: 4,
            }}
          >
            {formatTimeStringFromMinutes(point.estimatedWait)}
          </span>
        </div>
      ) : null}
      <div style={{marginLeft: 16}}>
        <button
          style={iconStyle}
          className='btn btn-icon btn-bg-light btn-active-color-primary'
          type='button'
          onClick={() => {
            setPointToEdit(point)
          }}
        >
          <KTSVG path='/media/icons/duotone/Communication/Write.svg' className='svg-icon-3' />
        </button>
        <button
          style={iconStyle}
          className='btn btn-icon btn-bg-light btn-active-color-danger'
          type='button'
          onClick={() => {
            deleteListElement(point)
          }}
        >
          <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
        </button>
      </div>
    </div>
  )
}

const PoiList: React.FC<{
  intermPOIList: any
  setIntermPOIList: Function
  setPointToEdit: Function
}> = ({intermPOIList, setIntermPOIList, setPointToEdit}) => {
  const deleteListElement = (point: POI) => {
    let oldIntermPoiList = intermPOIList
    oldIntermPoiList = oldIntermPoiList.filter(
      (e: POI) => JSON.stringify(e) !== JSON.stringify(point)
    )
    for (let index = 0; index < oldIntermPoiList.length; index++) {
      oldIntermPoiList[index].priority = index + 1
    }
    const futureIntermPOIList = [...oldIntermPoiList]
    setIntermPOIList(futureIntermPOIList)
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const futureIntermPOIList = reorder(
      intermPOIList,
      result.source.index,
      result.destination.index
    )

    // Change priority
    for (let index = 0; index < futureIntermPOIList.length; index++) {
      // @ts-ignore
      futureIntermPOIList[index].priority = index + 1
    }

    setIntermPOIList(futureIntermPOIList)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='list'>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {intermPOIList.map((point: POI, index: number) => {
              return (
                <Draggable
                  key={JSON.stringify(point)}
                  draggableId={JSON.stringify(point)}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <PointRow
                        key={point.uniqueId}
                        point={point}
                        setPointToEdit={setPointToEdit}
                        deleteListElement={deleteListElement}
                      />
                    </div>
                  )}
                </Draggable>
              )
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default PoiList

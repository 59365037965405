import Input from "../../../../components/input/input.component";
import { PackageType, PeriodUnit } from "../../../../types/company.types";
import Select from 'react-select'
import { getDefaultOption } from "../../../../utils/state.utils";
import { useState } from "react";

type ValabilityOptionType = {
  value: number
  unit: PeriodUnit
  label: string
  selectQuantity?: boolean
  minQuantity?: string
  maxQuantity?: string
}

export const processUnitLabel = (unit: PeriodUnit) => {
  switch (unit) {
    case 'days': return 'zile';
    case 'weeks': return 'săptămâni';
    case 'months': return 'luni';
    case 'years': return 'ani';
    default: return 'unități';
  }
}

export const isPackageValidForValabbility = (companyPackage: PackageType) => {
  const valabilityOption = getValabilityOptionByInfo(companyPackage?.valability_period, companyPackage?.valability_unit)
  const value = parseInt(('' + companyPackage?.valability_period) || '0')
  if (!value) {
    return false
  }
  const minValue = parseInt(valabilityOption?.minQuantity || '-1')
  const maxValue = parseInt(valabilityOption?.maxQuantity || '-1')
  if (minValue !== -1 && value < minValue) {
    return false
  }
  if (maxValue !== -1 && value > maxValue) {
    return false
  }
  return true
}

const getLabelFromSelectedValability = (selectedValability: ValabilityOptionType) => {
  let label = 'Specificați numărul de ';
  label += processUnitLabel(selectedValability.unit);
  label += ` (${selectedValability.minQuantity || ''} - ${selectedValability.maxQuantity || ''}) `;
  return label
}

const PackageData = ({
  companyPackage,
  setCompanyPackage,
}: {
  companyPackage: PackageType,
  setCompanyPackage: Function;
}) => {
  const [selectedValability, setSelectedValability] = useState<ValabilityOptionType | null>(
    getValabilityOptionByInfo(companyPackage?.valability_period, companyPackage?.valability_unit)
  )

  return (
    <div
      // @ts-ignore
      className={`card draggable has-card-options`}
      style={{ marginTop: 16 }}
    >
      <h1 className='py-5 px-10 d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
        Informații generale
      </h1>
      <ul className='autonom-dynamic-card'>
        <Input
          style={{ width: '50%' }}
          label='Numele pachetului'
          isRequired
          value={companyPackage['name']}
          onChange={(event) => {
            let value = event.target.value;
            setCompanyPackage(prevState => ({
              ...prevState,
              name: value
            }))
          }} />
        <Input
          style={{ width: '50%' }}
          label='Descrierea pachetului'
          value={companyPackage['description']}
          onChange={(event) => {
            let value = event.target.value;
            setCompanyPackage(prevState => ({
              ...prevState,
              description: value
            }))
          }} />
      </ul>
      <ul className='autonom-dynamic-card'>
        <div style={{ width: '50%' }} key={currencySelect?.label} >
          <label className='col-lg-4 mb-1 required fw-bold fs-6'>{currencySelect.label}</label>
          <Select
            defaultValue={getDefaultOption(currencySelect, companyPackage)}
            onChange={(optionsInstance: any) => {
              let value = optionsInstance.value;
              setCompanyPackage(prevState => ({
                ...prevState,
                [currencySelect.backend_property]: value,
              }))
            }}
            options={currencySelect.options}
          />
        </div>
        <Input
          style={{ width: '50%' }}
          label='Preț (EUR/RON)'
          isRequired
          value={companyPackage['price'].toString()}
          onChange={(event) => {
            let value = event.target.value;
            setCompanyPackage(prevState => ({
              ...prevState,
              price: value
            }))
          }} />
        <div className='fv-row mb-7' style={{ width: '50%' }} key={valabilitySelect?.label} >
          <label className='col-lg-4 mb-1 required fw-bold fs-6'>{valabilitySelect.label}</label>
          <Select
            value={selectedValability}
            onChange={(optionsInstance: ValabilityOptionType) => {
              setSelectedValability(optionsInstance)
              let value = optionsInstance.value;
              let unit = optionsInstance.unit;
              setCompanyPackage(prevState => ({
                ...prevState,
                [valabilitySelect.backend_property]: value,
                [valabilitySelect.unit_backend_property]: unit
              }))
            }}
            options={valabilitySelect.options}
          />
        </div>
        {Boolean(selectedValability?.selectQuantity) ?
          <Input
            style={{ width: '50%' }}
            label={getLabelFromSelectedValability(selectedValability)}
            isRequired
            type='number'
            inputProps={{ min: selectedValability?.minQuantity, max: selectedValability?.maxQuantity, step: '1' }}
            value={companyPackage['valability_period'].toString()}
            onChange={(event) => {
              let value = parseInt(event.target.value || '');
              setCompanyPackage(prevState => ({
                ...prevState,
                [valabilitySelect.backend_property]: value
              }))
            }}
            onBlurEffect={(oldValue) => {
              let value = parseInt(oldValue || '');
              let minValue = parseInt(selectedValability?.minQuantity || '-1')
              let maxValue = parseInt(selectedValability?.maxQuantity || '-1')
              if (minValue !== -1 && minValue > value) {
                value = minValue
              }
              if (maxValue !== -1 && maxValue < value) {
                value = maxValue
              }
              return '' + value
            }}
          />
          : null}
      </ul>
      <ul className="autonom-dynamic-card">
        <div style={{ width: '100%', marginTop: -30 }}>
          <label className='form-label'>
            Sumarul pachetului
          </label>
          <textarea
            rows={6}
            className='form-control form-control-sm textarea'
            onChange={(e) => {
              let value = e.target.value;
              setCompanyPackage(prevState => ({
                ...prevState,
                package_summary: value
              }))
            }}
            value={companyPackage.package_summary}
          />
        </div>
      </ul>
    </div >
  )
}

export default PackageData

const getValabilityOptionByInfo = (quantity: number, unit: PeriodUnit): ValabilityOptionType | null => {
  if (!quantity || !unit) {
    return null
  }
  // this function is not ideal. If the system changes, this should be modified
  const options = valabilitySelect.options
  let foundOption = options.find(option => {
    if (option.value === quantity && option.unit === unit) {
      return option
    }
  })
  if (!foundOption) {
    // check for multiple
    foundOption = options.find(option => option.selectQuantity && (option.unit === unit))
  }
  return foundOption
}

interface ValabilitySelectProps {
  label: string
  backend_property: string
  unit_backend_property: string
  required: boolean
  type: string
  options: ValabilityOptionType[]
}

export const valabilitySelect: ValabilitySelectProps = {
  label: 'Valabilitate',
  backend_property: 'valability_period',
  unit_backend_property: 'valability_unit',
  required: true,
  type: 'select',
  options: [
    {
      value: 7,
      unit: PeriodUnit.Days,
      label: 'Șapte zile',
    },
    {
      value: 15,
      unit: PeriodUnit.Days,
      label: 'Cincisprezece zile',
    },
    {
      value: 1,
      unit: PeriodUnit.Months,
      label: 'O lună',
    },
    {
      value: 3,
      unit: PeriodUnit.Months,
      label: 'Trei luni',
    },
    {
      value: 6,
      unit: PeriodUnit.Months,
      label: 'Șase luni',
    },
    {
      value: 12,
      unit: PeriodUnit.Months,
      label: 'Doisprezece luni',
    },
    {
      value: 2,
      unit: PeriodUnit.Years,
      label: 'Configurare pe ani (+2)',
      selectQuantity: true,
      minQuantity: '2',
      maxQuantity: '20'
    }
  ]
};

export const currencySelect = {
  label: 'Monedă',
  backend_property: 'currency',
  required: true,
  type: 'select',
  options: [
    {
      value: 'EUR',
      label: 'EUR',
    },
    {
      value: 'RON',
      label: 'RON',
    }
  ]
};

import moment from 'moment'
import {useCallback, useContext, useEffect, useMemo, useState} from 'react'
// @ts-ignore
import {StickyTable, Row, Cell} from 'react-sticky-table'
import {TransportsContext} from '../../../context/transports.context'
import {UserContext} from '../../../context/user.context'
import {KTSVG} from '../../../_metronic/helpers'
import Loading from '../../../_metronic/layout/components/loading/Loading'
import {RefreshContext} from '../../../context/refresh.context'
import DriverRowWrapper from './DriverRowWrapper'
import {
  getDriverSchedule,
  getFilteredTransports,
  getHighlightColorOddRows,
  getHoursFromNumber,
  scrollRedLineInsideView,
} from './utils'
import AssignmentsCell from './AssignmentsCell'

const hourCellStyle = {
  width: '130px',
  minWidth: '130px',
  margin: '5px',
  backgroundColor: '#181c32',
  color: '#fff',
}

// Intervals START
const NUM_OF_HOURS = 27 // number of hours that the table has (from current day at 00:00 -24h to the next day at 03:00 -3h, thus 27h)

export type DriverType = any

function TransportTable() {
  const {
    chosenDate,
    filterInterval,
    selectedDrivers,
    setSelectedDrivers,
    dictionaries,
    drivers,
    setDrivers,
    getUserProfileInfo,
    employeeFunction,
    selectedShiftType,
    selectedStatuses,
    selectedTariffServices,
    selectedTariffTypes,
    selectedCompanies,
    showShifts,
    setShowShifts,
    showSchedules,
    setShowSchedules,
    getVehiclesRequest,
    transportToBeAssigned,
    setTransportToBeAssigned,
    shiftsMap,
    getShiftsMap,
    setShiftsMap,
  } = useContext<any>(TransportsContext)

  const {canAutoRefreshTransports, setCanAutoRefreshTransports} = useContext<any>(RefreshContext)

  const {businessProfileId} = useContext<any>(UserContext)
  const [firstLoading, setFirstLoading] = useState<boolean>(true)
  const [userLocalSearch, setUserLocalSearch] = useState('')
  const [localDrivers, setLocalDrivers] = useState<any>([])
  const [isLocalLoading, setIsLocalLoading] = useState<boolean>(false)
  const [onlyDriversWithTransports, setOnlyDriversWithTransports] = useState<boolean>(false)
  const [vehicles, setVehicles] = useState<any>([])
  const driverSchedules = useMemo(() => {
    if (!drivers || !chosenDate) {
      return {}
    }
    let futureDriverSchedules = {}
    drivers.forEach((driver: any) => {
      futureDriverSchedules[driver.idUserProfile] = getDriverSchedule({driver, chosenDate})
    })
    return futureDriverSchedules
  }, [drivers, chosenDate])

  // reset the state value on page enter
  useEffect(() => {
    setCanAutoRefreshTransports(true)
  }, [])

  useEffect(() => {
    const fetchVehicles = async () => {
      const result = await getVehiclesRequest({
        businessProfileId,
      })

      setVehicles(result.data || [])
    }

    fetchVehicles()
  }, [])

  useEffect(() => {
    return () => {
      setTransportToBeAssigned(null)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      scrollRedLineInsideView()
    }, 100)
  }, [filterInterval])

  const getFilteredDrivers = (drivers: any[]) => {
    const localSelectedDrivers =
      !selectedDrivers || selectedDrivers?.length === 0 ? drivers : selectedDrivers
    let filteredDrivers = localSelectedDrivers.filter((driver: any) => {
      let hasShiftType =
        !selectedShiftType || selectedShiftType?.length === 0
          ? true
          : !!driver.currentShift &&
            selectedShiftType.some((schedule: any) => schedule.name === driver.currentShift.type)
      let hasFunction = driver.employeeFunction
        .toLowerCase()
        .includes(employeeFunction.toLowerCase())
      return hasShiftType && hasFunction
    })
    return filteredDrivers
  }

  useEffect(() => {
    const futureFilteredDrivers = getFilteredDrivers(drivers)
    // sort the drivers after the department name
    let sortedDriversList = futureFilteredDrivers.sort((fst: any, snd: any) => {
      let a = fst.department?.name
      let b = snd.department?.name
      return a > b ? 1 : a < b ? -1 : 0
    })

    if (onlyDriversWithTransports && shiftsMap && sortedDriversList?.length > 0) {
      let formattedChosenDate = moment(chosenDate).format('YYYY-MM-DD')
      let dayAfterChosenDate = moment(chosenDate).add(24, 'hours').format('YYYY-MM-DD')
      sortedDriversList = sortedDriversList.filter((driver: any) => {
        if (shiftsMap[driver.idUserProfile] && shiftsMap[driver.idUserProfile]?.length > 0) {
          return shiftsMap[driver.idUserProfile].some((transport: any) => {
            let transportEffectiveDate = moment(transport.effectiveDate).format('YYYY-MM-DD')
            let transportEffectiveHour = moment(transport.effectiveDate).format('HH:mm')
            if (transportEffectiveDate === formattedChosenDate) return true
            if (transportEffectiveDate === dayAfterChosenDate && transportEffectiveHour < '03:00')
              return true
            return false
          })
        }

        return false
      })
    }
    setLocalDrivers(sortedDriversList)
  }, [drivers, selectedDrivers, selectedShiftType, employeeFunction, onlyDriversWithTransports])

  // this is the function that fetches the transports from the database by date
  const getAndSetShiftsMap = async () => {
    if (firstLoading) {
      setIsLocalLoading(true)
    }

    const futureShifts = await getShiftsMap()

    setShiftsMap(futureShifts)
    setIsLocalLoading(false)
  }

  useEffect(() => {
    if (firstLoading) {
      getAndSetShiftsMap()
      setFirstLoading(false)
    }

    // Get shifts with interval
    const fetchInterval = setInterval(() => {
      if (!canAutoRefreshTransports) {
        return
      }
      getAndSetShiftsMap()
    }, 20000)

    return () => {
      clearInterval(fetchInterval)
    }
  }, [chosenDate, firstLoading])

  useEffect(() => {
    getAndSetShiftsMap()
  }, [chosenDate])

  const getHourFromIndex = (index: number) => {
    let counter = filterInterval / 4
    let hour = getHoursFromNumber({hour: counter * index})
    let nextDay = counter * index >= 24
    return {
      date: moment(chosenDate)
        .add(nextDay ? 1 : 0, 'days')
        .format('YYYY-MM-DD'),
      hour: hour,
      nextDay: nextDay,
    }
  }

  /**
   *
   * @returns the index of the suitable interval that the current hour resides in
   */
  const getCurrentIntervalIndex = ({numOfIntervalsParam}: {numOfIntervalsParam: number}) => {
    let presentMoment = Date.now()
    const currentDate = moment(presentMoment).format('YYYY-MM-DD')
    const currentHour = moment(presentMoment).format('HH:mm')
    for (let i = 0; i <= numOfIntervalsParam; i++) {
      const currentInterval = getHourFromIndex(i)
      const nextInterval = getHourFromIndex(i + 1)
      if (nextInterval.hour == '00:00') {
        nextInterval.hour = '24:00'
      }

      if (
        currentInterval.hour <= currentHour &&
        currentHour < nextInterval.hour &&
        currentDate === currentInterval.date
      ) {
        return i
      }
    }

    return -2 // an index that doesn't affect our logic
  }

  // Declare intervals
  const numOfIntervals = Math.floor((4 / filterInterval) * NUM_OF_HOURS)
  const currentIntervalIndex = getCurrentIntervalIndex({
    numOfIntervalsParam: numOfIntervals,
  })
  const intervalIndexes = Array.from(Array(numOfIntervals + 1).keys())

  /**
   * Transports per interval
   * @return transports1
   */
  const getTransportsPerInterval = (transports: any[]) => {
    let transportsPerHour: any[] = []
    intervalIndexes.forEach((i) => {
      let currentInterval = intervalList[i]
      let currentIntervalDate = moment(currentInterval.date).format('YYYY-MM-DD')
      let nextInterval = intervalList[i + 1]
      let nextIntervalDate = moment(nextInterval.date).format('YYYY-MM-DD')

      transportsPerHour.push(
        transports.filter((transport: any) => {
          let transportDate = moment(transport.effectiveDate).format('YYYY-MM-DD')
          let transportHour = moment(transport.effectiveDate).format('HH:mm')
          if (transportDate != currentIntervalDate) {
            // if the dates don't match
            return false
          }
          if (currentInterval.hour <= transportHour) {
            if (transportDate != nextIntervalDate) {
              return true
            } else if (transportHour < nextInterval.hour) {
              return true
            }
          }
          return false
        })
      )
    })
    return transportsPerHour
  }

  const getOtherTransports = () => {
    if (!shiftsMap) {
      return []
    }
    let otherFromLocalTransports = Object.entries(shiftsMap).map(([key, value]) => {
      if (key === 'other' || key === 'unassigned') {
        return []
      }
      if (!localDrivers.some((driver: DriverType) => driver.idUserProfile == key)) {
        return value
      }
      return []
    })
    const otherTransports = shiftsMap?.other || []
    const flatOtherFromLocal = (otherFromLocalTransports || []).flat()
    return [...otherTransports, ...flatOtherFromLocal]
  }

  const intervalList = Array.from(Array(intervalIndexes.length + 1).keys()).map((i) =>
    getHourFromIndex(i)
  )
  //   let indexesListExtended = Array.from(Array(numOfIntervals + 2).keys())
  //   return indexesListExtended.map((i) => getHourFromIndex(i))
  // })()

  const driverTransportsPerInterval = (() => {
    let futureDriverTransports = {}
    localDrivers.forEach((driver: any) => {
      let driverId = driver.idUserProfile
      let driverTransports =
        shiftsMap && shiftsMap[driver.idUserProfile] ? shiftsMap[driver.idUserProfile] : []
      let filteredTransports = getFilteredTransports({
        transports: driverTransports,
        selectedStatuses,
        selectedTariffServices,
        selectedTariffTypes,
        selectedCompanies,
        keyword: userLocalSearch,
      })
      futureDriverTransports[driverId] = getTransportsPerInterval(filteredTransports)
    })
    return futureDriverTransports
  })()
  //   localDrivers,
  //   shiftsMap,
  //   selectedStatuses,
  //   selectedTariffServices,
  //   selectedTariffTypes,
  //   selectedCompanies,
  //   userLocalSearch,
  // ])

  const unassignedTransportsPerInterval = (() => {
    let transports = shiftsMap?.unassigned || []
    let filteredTransports = getFilteredTransports({
      transports,
      selectedStatuses,
      selectedTariffServices,
      selectedTariffTypes,
      selectedCompanies,
      keyword: userLocalSearch,
    })
    return getTransportsPerInterval(filteredTransports)
  })()
  //   shiftsMap?.unassigned,
  //   selectedStatuses,
  //   selectedTariffServices,
  //   selectedTariffTypes,
  //   selectedCompanies,
  //   userLocalSearch,
  // ])

  const otherTransportsPerInterval = (() => {
    let transports = getOtherTransports() || []
    let filteredTransports = getFilteredTransports({
      transports,
      selectedStatuses,
      selectedTariffServices,
      selectedTariffTypes,
      selectedCompanies,
      keyword: userLocalSearch,
    })
    return getTransportsPerInterval(filteredTransports)
  })()
  // }, [
  //   shiftsMap,
  //   selectedStatuses,
  //   selectedTariffServices,
  //   selectedTariffTypes,
  //   selectedCompanies,
  //   userLocalSearch,
  // ])

  const disableRightClickMenu = (e: any) => {
    e.preventDefault()
  }

  const updateInfoForDriver = useCallback(
    async ({driverInfo}: {driverInfo: DriverType}) => {
      const result = await getUserProfileInfo({idUserProfile: driverInfo.idUserProfile})
      const newDriverInfo = result.data || {}
      const futureDrivers = drivers.map((driver: DriverType) =>
        driver.idUserProfile === newDriverInfo.idUserProfile ? newDriverInfo : driver
      )
      setDrivers(futureDrivers)

      if ((selectedDrivers || []).length > 0) {
        const futureSelectedDrivers = selectedDrivers.map((driver: DriverType) =>
          driver.idUserProfile === newDriverInfo.idUserProfile ? newDriverInfo : driver
        )
        setSelectedDrivers(futureSelectedDrivers)
      }
    },
    [drivers, selectedDrivers]
  )

  if (isLocalLoading) {
    return <Loading />
  }
  /**
   * Return final UI
   */
  return (
    <div className='table-alt-scroll' key={'currentInterval' + currentIntervalIndex} onContextMenu={disableRightClickMenu}>
      {/* Unassigned request table */}
      <div style={{width: '100%', backgroundColor: 'white', position: 'relative'}}>
        <div
          style={{
            position: 'absolute',
            right: '16px',
            zIndex: 4,
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            width: '740px',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {/* Show Schedule */}
          <div
            style={{
              width: '370px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#f5f8fa',
              height: 35,
              borderRadius: 5,
              marginRight: 8,
            }}
          >
            <label htmlFor='showSchedules' style={{marginRight: 12}} className='clickable'>
              Show schedules
            </label>
            <input
              checked={showSchedules}
              onChange={(e) => {
                setShowSchedules(e.currentTarget.checked)
              }}
              name='showSchedules'
              id='showSchedules'
              className='clickable form-check-input'
              type='checkbox'
            />
          </div>

          {/* Show Shifts */}
          <div
            style={{
              width: '288px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#f5f8fa',
              height: 35,
              borderRadius: 5,
              marginRight: 8,
            }}
          >
            <label htmlFor='showShifts' style={{marginRight: 12}} className='clickable'>
              Show shifts
            </label>
            <input
              checked={showShifts}
              onChange={(e) => {
                setShowShifts(e.currentTarget.checked)
              }}
              name='showShifts'
              id='showShifts'
              className='clickable form-check-input'
              type='checkbox'
            />
          </div>

          {/* Only with transports */}
          <div
            style={{
              width: '440px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#f5f8fa',
              height: 35,
              borderRadius: 5,
              marginRight: 8,
            }}
          >
            <label
              htmlFor='onlyDriversWithTransports'
              style={{marginRight: 12}}
              className='clickable'
            >
              Only with transports
            </label>
            <input
              checked={onlyDriversWithTransports}
              onChange={(e) => {
                setOnlyDriversWithTransports(e.currentTarget.checked)
              }}
              name='onlyDriversWithTransports'
              id='onlyDriversWithTransports'
              className='clickable form-check-input'
              type='checkbox'
            />
          </div>

          <div className='input-group input-group-sm d-flex justify-content-center justify-content-xl-start py-3'>
            <span className='input-group-text'>
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-2 svg-icon-gray-500'
              />
            </span>
            <input
              placeholder='Address, phone, name, email...'
              className='form-control'
              type='text'
              value={userLocalSearch}
              onChange={(e) => {
                setUserLocalSearch(e.currentTarget.value)
              }}
            />
          </div>
        </div>

        <StickyTable
          id='unassignedTable'
          onScroll={(e: any) => {
            const scrollLeft = e.target.scrollLeft
            document.getElementById('driversTable')?.scrollTo({left: scrollLeft})
          }}
          borderWidth='0px'
          stickyHeaderCount={1}
          leftStickyColumnCount={2}
        >
          <Row>
            <Cell
              lassName='right-shadow'
              style={{
                width: '360px',
                minWidth: '360px',
                maxWidth: '360px',
                textAlign: 'center',
                fontWeight: 600,
                padding: '16px',
              }}
            >
              Unassigned Requests
            </Cell>
          </Row>

          <Row key={JSON.stringify(transportToBeAssigned)}>
            <Cell
              className='right-shadow'
              style={{
                width: '360px',
                minWidth: '360px',
                maxWidth: '360px',
                textAlign: 'center',
                fontWeight: 600,
                padding: '16px',
              }}
            ></Cell>
            {intervalIndexes.map((index) => {
              const currentInterval = intervalList[index]
              const nextInterval = intervalList[index + 1]
              const transports = unassignedTransportsPerInterval[index]
              const dateString =
                (currentInterval.nextDay ? 'nextDay' : 'selectedDay') + 'T' + currentInterval.hour
              const cellKey = 'cell-' + dateString + '-unassigned'
              return (
                <AssignmentsCell
                  transports={transports}
                  index={index}
                  currentInterval={currentInterval}
                  currentIntervalIndex={currentIntervalIndex}
                  nextInterval={nextInterval}
                  key={cellKey}
                  mode={'unassigned'}
                />
              )
            })}
          </Row>
        </StickyTable>
      </div>

      {/* Drivers table */}
      <div style={{width: '100%', height: 'calc(100vh - 120px)', backgroundColor: 'white'}}>
        <StickyTable
          onScroll={(e: any) => {
            const scrollLeft = e.target.scrollLeft
            document.getElementById('unassignedTable')?.scrollTo({left: scrollLeft})
          }}
          id='driversTable'
          borderWidth='0px'
          stickyHeaderCount={1}
          leftStickyColumnCount={1}
        >
          <Row>
            <Cell style={{...hourCellStyle, minWidth: '180px'}}></Cell>
            {intervalIndexes.map((index) => (
              <Cell key={'cell' + index} style={hourCellStyle}>
                {intervalList[index].hour}
              </Cell>
            ))}
          </Row>

          {localDrivers.map((driver: any, rowIndex: number) => {
            const driverId = driver.idUserProfile
            const currentDriverVehicle = driver.defaultVehicle
            const transportsByInterval = driverTransportsPerInterval[driverId]
            return (
              <DriverRowWrapper
                style={{ background: getHighlightColorOddRows(rowIndex) }}
                driver={driver}
                updateInfoForDriver={updateInfoForDriver}
                currentDriverVehicle={currentDriverVehicle}
                key={'driverRow-' + driverId}
              >
                {intervalIndexes.map((index) => {
                  const currentInterval = intervalList[index]
                  const nextInterval = intervalList[index + 1]
                  const transports = transportsByInterval[index]
                  const driverSchedule = driverSchedules?.[driver.idUserProfile]
                  const dateString =
                    (currentInterval.nextDay ? 'nextDay' : 'selectedDay') +
                    'T' +
                    currentInterval.hour
                  const cellKey = 'cell-' + dateString + '-' + driverId
                  return (
                    <AssignmentsCell
                      transports={transports}
                      driver={driver}
                      index={index}
                      rowIndex={rowIndex}
                      currentInterval={currentInterval}
                      currentIntervalIndex={currentIntervalIndex}
                      nextInterval={nextInterval}
                      driverShiftsMap={shiftsMap[driver.idUserProfile] || []}
                      shiftTypes={dictionaries.SHIFT_TYPES || []}
                      showShifts={showShifts}
                      showSchedules={showSchedules}
                      driverSchedule={driverSchedule}
                      key={cellKey}
                    />
                  )
                })}
              </DriverRowWrapper>
            )
          })}

          <Row>
            <Cell className='right-shadow'>Other drivers</Cell>
            {intervalIndexes.map((index) => {
              const currentInterval = intervalList[index]
              const nextInterval = intervalList[index + 1]
              const transports = otherTransportsPerInterval[index]
              const dateString =
                (currentInterval.nextDay ? 'nextDay' : 'selectedDay') + 'T' + currentInterval.hour
              const cellKey = 'cell-' + dateString + '-other'
              return (
                <AssignmentsCell
                  transports={transports}
                  index={index}
                  currentInterval={currentInterval}
                  currentIntervalIndex={currentIntervalIndex}
                  nextInterval={nextInterval}
                  key={cellKey}
                />
              )
            })}
          </Row>
        </StickyTable>
      </div>
    </div>
  )
}

export default TransportTable

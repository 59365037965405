import React, { useContext, useEffect } from 'react'
import { Card } from '../../add-edit-transport/scripts/structuralFields'
import { Field } from '../utils'
import { SubscriptionsContext } from '../../../../context/subscriptions.context'
import { VehicleType, categoryList, emptyVehicle } from '../../../../types/vehicle.types'
import { FieldType } from '../../../../utils/field.utils'

const updateVehicleFieldsByRedNumber = (isRedNumber: boolean, setVehicleFields: Function, isVinRequired: boolean) =>
  setVehicleFields((oldVehicleFields: FieldType[]) => oldVehicleFields.map(field => {
    if (field.name === 'registration_nr') {
      return {
        ...field,
        required: !isRedNumber,
      }
    }
    if (field.name === 'vin') {
      return { 
        ...field,
        required: isRedNumber || isVinRequired,
      }
    }
    return field
  }))

const VehicleCard = ({
  vehicle,
  setVehicle,
  warningMessage,
  mainCompanyName,
  isVinRequired
}: {
  vehicle: VehicleType
  setVehicle: Function
  warningMessage: string
  mainCompanyName: string
  isVinRequired: boolean
}) => {
  const { vehicleFields, setVehicleFields } = useContext<any>(SubscriptionsContext)
  const { carModels } = useContext<any>(SubscriptionsContext);
  const getFieldPropertiesFromFieldName = (fieldName: string) => {
    if (!Boolean(fieldName)) {
      return null
    }
    switch (true) {
      case fieldName === 'company_id':
        return vehicle?.company_id
      case fieldName === 'model' && mainCompanyName?.toUpperCase() === 'VOLVO':
        return {
          simpleValue: vehicle?.model || '',
          options: carModels,
          getOptionLabel: (e: any) => e?.model_name || '',
          getOptionValue: (e: any) => e?.model_name || '',
          onChange: (value: string) => setVehicle({ ...vehicle, model: value }),
        }
      case fieldName === 'category':
        return {
          simpleValue: vehicle?.category || '',
          options: categoryList,
          getOptionLabel: (e: any) => e?.name || '',
          getOptionValue: (e: any) => e?.name || '',
          onChange: (value: string) => setVehicle({ ...vehicle, category: value }),
        }
      case fieldName === 'red_number':
        return {
          value: Boolean(vehicle?.red_number),
          onChange: (value: boolean) => {
            updateVehicleFieldsByRedNumber(value, setVehicleFields, isVinRequired)
            setVehicle({ ...vehicle, red_number: value, registration_nr: '' })
          },
        }
      case fieldName === 'weight':
        return vehicle?.weight || ''
      default:
        const value = vehicle[fieldName] || emptyVehicle[fieldName]
        if (value === undefined) {
          return null
        }
        return value
    }
  }

  useEffect(() => {
    updateVehicleFieldsByRedNumber(Boolean(vehicle.red_number), setVehicleFields, isVinRequired)
    if (Boolean(vehicle?.id)) {
      // temporary fix for the required field bug and for the conversion of the value that we receive from BE
      // NOT IDEAL - NEEDS TO BE CHANGED
      setVehicle((oldVehicle: VehicleType) => ({
        ...oldVehicle,
        red_number: Boolean(vehicle.red_number),
      }))
    }
  }, [vehicle?.id])

  return (
    <div key={'vehicle-container'} className='col-12 pb-4'>
      <Card name={'Vehicle'}>
        {vehicleFields.map((field: any, index: number) => {
          const fieldName = field?.name
          const fieldProperty = getFieldPropertiesFromFieldName(fieldName)
          return (
            <Field
              key={`field-vehicle-${fieldName}`}
              field={field}
              fieldName={fieldName}
              fieldProperty={fieldProperty}
              setObject={setVehicle}
            />
          )
        })}
        {warningMessage ?
          <div className="alert warning">
            <div style={{ display: 'flex' }}>
              <i style={{ color: '#C9971C', marginTop: 3, marginRight: 10 }} className="fa fa-exclamation-triangle svg-icon-2x svg-icon-warning" />
              <p style={{ fontSize: 14, fontWeight: 600 }}>Avertizare</p>
            </div>
            <span className="alertText">{warningMessage}</span>
          </div>
          : null}
      </Card>
    </div>
  )
}

export default VehicleCard

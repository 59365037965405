import {Moment} from 'moment'
import TimePicker from 'rc-time-picker'
import '../../../../../../src/pages/add-edit-plan/styles/rent-extras-table.css'
import {useState} from 'react'
import {KTSVG} from '../../../../helpers'

export function CustomTimePicker({
  field,
  value,
  onChange,
  disableHours
}: {
  field: any
  value: Moment
  onChange: (e: any) => void,
  disableHours: Moment
}) {
  const [open, setOpen] = useState(false)

  const disabledHours = () => {
    const hours: number[] = []
    for (let i = 0; i < 24; i++) {
      if (i < disableHours.hour()) {
        hours.push(i)
      }
    }
    return hours
  }
  
  return (
    <div className='col' style={{marginTop: 10}}>
      <label
        htmlFor={field.label}
        className='form-label'
        style={{marginBottom: 15, fontWeight: '600'}}
      >
        {field.label}
      </label>
      <div style={{display: 'flex', flexDirection: 'row', maxWidth: 200}}>
        <TimePicker
          className='field-generator-time-picker'
          showSecond={field.showSecond}
          disabledHours={disableHours ? disabledHours : undefined}
          value={value}
          onChange={onChange}
          clearIcon={<></>}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          open={open}
        />
        <div
          className='btn btn-sm btn-icon btn-active-color-primary'
          data-bs-dismiss='modal'
          style={{position: 'relative', right: 32, top: 1}}
        >
          {open && (
            <KTSVG path='/media/icons/duotone/Navigation/Check.svg' className='svg-icon-1' />
          )}
        </div>
      </div>
    </div>
  )
}

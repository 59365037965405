import { FC, useEffect, useState } from 'react'
import Table from './Table'
import { createRentPlanDislocationCosts, deleteRentDislocationCosts, getRentPlanDislocationCosts, putRentDislocationCosts } from '../../../setup/axios/rent.request'
import { toast } from 'react-toastify'
import { RentPlanDislocationCostsModel, emptyRentPlanDislocationCosts } from '../../../app/modules/rent-plans/models/RentPlanDislocationCostsModel'
import { getStringOfEntity } from '../../../utils/string.utils'
import { updateField } from '../../../utils/state.utils'
import { useParams } from 'react-router-dom'
import { usePlanContext } from '../../../context/plan.context'

const rentDislocationFields = [
    'geofence_type',
    'fixed_cost',
    'cost_per_km'
]
const compoundUniqueKeyKm = ['geofence_type']

const getDislocationLabel = () => "Dislocation";

const RentExtrasFieldToProperty = (
    field: any,
    dislocationCosts: RentPlanDislocationCostsModel,
    setdislocationCosts: Function,
    currentValues: string[],
    initialGeofenceType: string
  ) => {
    let fieldName = field.name

    const updateExtrasListElemEvent = (e: any) => {
      updateField({ value: e, fieldName: fieldName, setObject: setdislocationCosts }) 
    }

      const options: {name: string, title: string}[] = [{name: "Preluare / predare aceeași Agenție Autonom", title: "Preluare / predare aceeași Agenție Autonom"}, {name: "Preluare / predare același oraș Autonom", title: "Preluare / predare același oraș Autonom"}, {name: "Preluare / predare agenții diferite Autonom", title: "Preluare / predare agenții diferite Autonom"}, {name: "Preluare / predare orașe diferite Autonom", title: "Preluare / predare orașe diferite Autonom"}, {name: "Preluare / predare în orașe fără agenții Autonom", title: "Preluare / predare în orașe fără agenții Autonom"}];
      const availableOptions = options.filter(option => (option.name === initialGeofenceType) || !currentValues.includes(option.name));

      const fieldsToPanelPropertiesMapping = {
        geofence_type: {
            simpleValue: dislocationCosts.geofence_type || '',
            options: availableOptions || [],
            getOptionValue: (e: any) => e.name || '',
            getOptionLabel: (e: any) => e.title || '',
            onChange: updateExtrasListElemEvent,
          },
      fixed_cost: { value: getStringOfEntity({ entity: dislocationCosts.fixed_cost }) },
      cost_per_km: { value: getStringOfEntity({ entity: dislocationCosts.cost_per_km }) },
    }

    return (fieldsToPanelPropertiesMapping as any)[fieldName]
  }
  
const DislocationTable: FC<{}> = ({ }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [rentPlanDislocationCosts, setRentPlanDislocationCosts] = useState<RentPlanDislocationCostsModel[]>([])
    const { id, companyId } = useParams<any>()
    const { rentCompanyPricingPlan } = usePlanContext()
    const [toBeEdited, setToBeEdited] = useState<RentPlanDislocationCostsModel>();

    const fetchDislocationCosts = async () => {
      return getRentPlanDislocationCosts(id).then((futureRentPlanDislocationCosts: RentPlanDislocationCostsModel[] | null) => {
            if (!futureRentPlanDislocationCosts) {
                return toast.error('The Rent Plan Dislocation Costs failed to load!')
            }
            setRentPlanDislocationCosts(futureRentPlanDislocationCosts)
            setIsLoading(false)
        })
    }
    useEffect(() => {
       fetchDislocationCosts();
    }, [])

    const dislocationProps = [
        {
            name: 'geofence_type',
            getDisplayLabel: (geofence_type: string) => geofence_type,
            label: 'Geofence type',
            type: 'select',
        },
        {
            name: 'fixed_cost',
            getDisplayLabel: (fixed_cost: number) => fixed_cost,
            label: 'Fixed cost',
            type: 'input',
            addOns: { append: rentCompanyPricingPlan.currency }
        },
        {
            name: 'cost_per_km',
            getDisplayLabel: (cost_per_km: number) => cost_per_km,
            label: 'Cost/KM',
            type: 'input',
            addOns: { append: rentCompanyPricingPlan.currency }
        }
    ]
    const rentPlanDislocationCostsGeofenceTypes = rentPlanDislocationCosts.map(rentPlanDislocationCost => rentPlanDislocationCost.geofence_type);

    return (
        <>
            <Table
                tableLabel={'Dislocation'}
                properties={dislocationProps}
                fieldToProperty={(field, object, setObjectFunc) => RentExtrasFieldToProperty(field, object, setObjectFunc, rentPlanDislocationCostsGeofenceTypes, toBeEdited?.geofence_type)}
                entries={rentPlanDislocationCosts}
                setEntries={setRentPlanDislocationCosts}
                emptyEntry={emptyRentPlanDislocationCosts}
                getEntryLabel={getDislocationLabel}
                requiredFields={rentDislocationFields}
                compoundUniqueKey={compoundUniqueKeyKm}
                deleteEndpoint={deleteRentDislocationCosts}
                editEndpoint={(value) => putRentDislocationCosts(value, companyId)}
                createEndpoint={async (value) => {
                    const dislocationCost = await createRentPlanDislocationCosts(id, companyId, value);
                    await fetchDislocationCosts();
                    return dislocationCost;
                }}
                isPlanCreated= {!!id}
                addEntryLabel="Add Dislocation"
                onEditCustom={setToBeEdited}
            />
        </>
    )

}

export default DislocationTable

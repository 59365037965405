import { toast } from 'react-toastify';
import Semaphore from '../../../../../providers/Semaphore/semaphore';
import { TransportType } from '../../../add-edit-transport/scripts/transportTypes';
import { FlexibleObject, StateSetter } from '../../../../../utils/state.utils';

const MAX_BULK_PAYLOAD = 50;
const MAX_TRANSPORTS_BATCH_SIZE = 150;
const MAX_PARALLEL_REQUESTS = 3;

// split the given ids list into a list of lists where each element has at most batch_size elements
// this function is used to split the transport ids into chunks that can be handled by the server
const getBatchesIds = ({ ids, batch_size }: { ids: number[]; batch_size: number }) => {
  const idBatches = [];
  let tempList = [];
  ids.forEach((id: number) => {
    tempList.push(id);
    if (tempList.length === batch_size) {
      idBatches.push(tempList);
      tempList = [];
    }
  });
  // when remaining items.length < batch_size
  if (tempList.length > 0) {
    idBatches.push(tempList);
  }
  return idBatches;
};

export type ProcessStatusObjectType = {
  bulkToastId: React.MutableRefObject<React.ReactText>;
  totalRequests: number;
  setTotalRequests: StateSetter<number>;
  completedRequests: number;
  setCompletedRequests: StateSetter<number>;
};

export const changeInvoiceBatch = async ({
  transports,
  invoiceNumber,
  processStatusObject,
  addInvoiceNumberForTransports,
}: {
  transports: TransportType[];
  invoiceNumber: string;
  processStatusObject: ProcessStatusObjectType;
  addInvoiceNumberForTransports: ({
    invoiceNumber,
    transportIds,
  }: {
    invoiceNumber: string;
    transportIds: number[];
  }) => Promise<FlexibleObject>;
}) => {
  const { bulkToastId, completedRequests, totalRequests, setTotalRequests, setCompletedRequests } =
    processStatusObject;
  if (!invoiceNumber) {
    return;
  }

  const transportIds = transports.map((transport: TransportType) => transport.id);
  const bulkPayloads = getBatchesIds({ ids: transportIds, batch_size: MAX_BULK_PAYLOAD });

  bulkToastId.current = toast.loading(
    `Setting invoice! ${completedRequests}/${totalRequests} done`,
    {
      progress: 0,
      autoClose: false,
    }
  );
  setTotalRequests(bulkPayloads.length);
  setCompletedRequests(0);

  const promisesList = [];
  bulkPayloads.forEach((payload: number[]) => {
    const promise = addInvoiceNumberForTransports({
      invoiceNumber,
      transportIds: payload,
    });
    promise.then(() => setCompletedRequests((requestsCount: number) => requestsCount + 1));
    promisesList.push(promise);
  });

  const results = await Promise.allSettled(promisesList);

  toast.dismiss(bulkToastId.current);
  const failedCount = results.filter((result) => result.status === 'rejected').length;
  toast.info(
    `Set invoiced complete! ${
      bulkPayloads.length - failedCount
    }  fulfilled, ${failedCount} rejected`
  );
};

export const getTransportsBatch = async ({
  transportFilters,
  processStatusObject,
  getTransports,
  setAreAllTransportsFulfilled,
}: {
  transportFilters: FlexibleObject;
  processStatusObject: ProcessStatusObjectType;
  getTransports: (payload: FlexibleObject) => Promise<{ data: FlexibleObject }>;
  setAreAllTransportsFulfilled: StateSetter<boolean>;
}) => {
  const { bulkToastId, setTotalRequests, setCompletedRequests } = processStatusObject;
  const batchPromiseList = [];

  bulkToastId.current = toast.loading(`Fetch transports for request!`, {
    progress: 0,
    autoClose: false,
  });

  if (transportFilters.ids) {
    const batchIdsPayloads = getBatchesIds({
      ids: transportFilters.ids,
      batch_size: MAX_TRANSPORTS_BATCH_SIZE,
    });
    setTotalRequests(batchIdsPayloads.length);
    setCompletedRequests(0);

    batchIdsPayloads.forEach((ids: number[]) => {
      const batchPromise = getTransports({ ...transportFilters, ids });
      batchPromise.then(() => setCompletedRequests((requestsCount: number) => requestsCount + 1));
      batchPromiseList.push(batchPromise);
    });
  } else {
    const batchPayload = {
      ...transportFilters,
      page: 1,
      resultsPerPage: MAX_TRANSPORTS_BATCH_SIZE,
    };
    const firstPromise = getTransports(batchPayload);
    batchPromiseList.push(firstPromise);
    // we need the first request separated because we don't know the total number of batches
    const result = await firstPromise;
    if (!result?.data?.transportRequests) {
      toast.error("Couldn't retrieve the transports for the export!");
      toast.dismiss(bulkToastId.current);
      return [];
    }
    if (result.data.pageCount === 1) {
      toast.success('Transports loaded successfully!');
      toast.dismiss(bulkToastId.current);
      return result.data.transportRequests;
    }

    const pageCount = result.data.pageCount;
    setTotalRequests(pageCount);
    setCompletedRequests(1);
    let page = 2; // we already made the first request

    const semaphore = new Semaphore(MAX_PARALLEL_REQUESTS);
    for (page; page <= pageCount; page += 1) {
      const newBatchPayload = { ...batchPayload, page };
      const batchPromise = semaphore.handleRequest(() => getTransports(newBatchPayload));
      batchPromise.then(() => setCompletedRequests((requestsCount: number) => requestsCount + 1));
      batchPromiseList.push(batchPromise);
    }
  }

  const results = await Promise.allSettled(batchPromiseList);

  toast.dismiss(bulkToastId.current);
  const successful = results.filter(
    (result) =>
      (Object.hasOwn(result, 'value') && !result['value']?.data) || result.status === 'fulfilled'
  );
  const areAllFulfilled = results.every((result) => result.status === 'fulfilled');
  setAreAllTransportsFulfilled(areAllFulfilled);
  toast.info(
    `Fetched all transports! ${successful.length} fulfilled, ${
      batchPromiseList.length - successful.length
    } rejected`
  );
  return successful.map((response) => response['value'].data?.transportRequests || []).flat();
};

const PaginationNumbering = ({
  pageCount,
  currentPage,
  setCurrentPage,
}: {
  pageCount: number
  currentPage: number
  setCurrentPage: Function
}) => {
  let pages = []
  if (pageCount <= 5) {
    pages = Array.from(Array(pageCount + 1).keys()).slice(1)
  } else {
    if (currentPage <= 3) {
      pages = [1, 2, 3, 4, -1, pageCount]
    } else if (currentPage >= pageCount - 2) {
      pages = [1, 0, pageCount - 3, pageCount - 2, pageCount - 1, pageCount]
    } else {
      pages = [1, 0, currentPage - 1, currentPage, currentPage + 1, -1, pageCount]
    }
  }

  return (
    <>
      {pages.map((page) => (
        <li
          key={'page-item-' + page}
          className={`page-item${page == currentPage ? ' active' : page <= 0 ? ' disabled' : ''}`}
        >
          <a
            href='#'
            className='page-link'
            key={page}
            onClick={() => {
              setCurrentPage(page)
            }}
          >
            {page <= 0 ? '. . .' : page}
          </a>
        </li>
      ))}
    </>
  )
}

const paginationOptions: number[] = [25, 50, 100, 500]
export const defaultPaginationSize = paginationOptions[0]

const Pagination = ({
  resultsPerPage,
  setResultsPerPage,
  pageCount,
  currentPage,
  setCurrentPage,
}: {
  resultsPerPage: number
  setResultsPerPage: Function
  pageCount: number
  currentPage: number
  setCurrentPage: Function
}) => {
  return (
    <div className='row align-items-center mb-4'>
      <div className='col-xl-4 col-xxl-3 py-3'>
        <div className='input-group input-group-sm d-flex justify-content-center justify-content-xl-start'>
          <span className='input-group-text'>Results per page:</span>
          <select
            id='resultsPerPage'
            className='form-select mw-100px'
            aria-label='Results per page'
            value={resultsPerPage}
            onChange={(ev: any) => {
              setCurrentPage(1)
              setResultsPerPage(Number(ev.target.value))
            }}
          >
            {paginationOptions.map((size: number) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='col-xl-8 col-xxl-9 py-3'>
        <ul className='pagination d-flex justify-content-xl-end'>
          <li
            key='page-item-previous'
            className={`page-item previous${currentPage === 1 ? ' disabled' : ''}`}
          >
            <div
              className='page-link'
              onClick={() => {
                const futurePageNumber = Math.max(currentPage - 1, 1)
                setCurrentPage(futurePageNumber)
              }}
            >
              <i className='previous' />
            </div>
          </li>
          <PaginationNumbering
            pageCount={pageCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          <li
            key='page-item-next'
            className={`page-item next${currentPage === pageCount ? ' disabled' : ''}`}
          >
            <div
              className='page-link'
              onClick={() => {
                const futurePageNumber = Math.min(currentPage + 1, pageCount)
                setCurrentPage(futurePageNumber)
              }}
            >
              <i className='next' />
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Pagination

import React, {createContext, useEffect, useMemo, useState} from 'react'

const RefreshContext = createContext({})

function RefreshProvider(props: any) {
  const [canAutoRefreshTransports, setCanAutoRefreshTransports] = useState<boolean>(true)

  const store = {
    canAutoRefreshTransports, setCanAutoRefreshTransports,
  }

  const storeForProvider = useMemo(() => store, [store])
  return (
    <RefreshContext.Provider value={storeForProvider}>{props.children}</RefreshContext.Provider>
  )
}

const useRefreshContext = () => {
  const context = React.useContext<any>(RefreshContext)
  if (context === undefined) {
    throw new Error('useRefreshContext must be used within a RefreshProvider')
  }
  return context
}

export {RefreshContext, useRefreshContext}
export default RefreshProvider

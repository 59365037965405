export type RentPlanDislocationCostsModel = {
    id: number
    company_id: string
    plan_id: string
    geofence_type: string
    fixed_cost: string
    cost_per_km: string
    created_at: string | null
    updated_at: string | null
}
  
export const emptyRentPlanDislocationCosts = {
  geofence_type: "",
  fixed_cost: "",
  cost_per_km: "",
  created_at: null,
  updated_at: null
}

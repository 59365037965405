import {ControlledMenu, MenuItem, useMenuState} from '@szhsin/react-menu'
import {memo, useContext, useEffect, useState} from 'react'
import {UserContext} from '../../../context/user.context'
import {deleteVehicleForDriver, updateVehicleForDriver} from '../../../setup/axios/vehicles.request'
import {Row, Cell} from 'react-sticky-table'
import SmallDriverInfo from '../cars/components/small-driver-info/SmallDriverInfo'
import SmallVehicleInfo from '../cars/components/small-vehicle-info/SmallVehicleInfo'
import {KTSVG} from '../../../_metronic/helpers'
import ModalPortal from '../../../_metronic/layout/components/modal/modal-portal'
import {SelectObject} from '../../../_metronic/layout/components/select/Select'
import easytrackAxios from '../../../setup/easytrack.axios'
import { EASYTRACK_API_URL } from '../../../constants/api.constants'

const UpdateDriverVehicleModal = memo(
  ({
    driver,
    currentDriverVehicle,
    updateInfoForDriver,
    modalVisible,
    setModalVisible,
  }: {
    driver: any
    currentDriverVehicle: any
    updateInfoForDriver: Function
    modalVisible: boolean
    setModalVisible: Function
  }) => {
    const {businessProfileId} = useContext<any>(UserContext)
    const [vehicles, setVehicles] = useState<any>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [selectedVehicleId, setSelectedVehicleId] = useState<any>(currentDriverVehicle?.idVehicle)
    const getVehicles = async () => {
      const vehicleResults = await easytrackAxios.get(
        `${EASYTRACK_API_URL}/vehicles?businessProfileId=${businessProfileId}`
      )
      setVehicles(vehicleResults.data)
    }

    useEffect(() => {
      getVehicles()
    }, [])

    return (
      <ModalPortal setVisible={setModalVisible} visible={modalVisible} hasExitButton={false}>
        <div className='py-4 px-6 w-100 h-100'>
          <span style={{fontSize: '16px', fontWeight: '500'}}>
            Update the vehicle for driver {driver.fullName}
          </span>
          <div
            style={{
              fontSize: '16px',
              display: 'flex',
              flexDirection: 'column',
            }}
            className='mt-4'
          >
            <label>Vehicle:</label>
            <SelectObject
              className='min-w-300px'
              simpleValue={selectedVehicleId}
              onChange={(value: any) => {
                setSelectedVehicleId(value)
              }}
              getOptionLabel={(option: any) => {
                return option.businessName
              }}
              getOptionValue={(option: any) => option.idVehicle}
              styles={{menuPortal: (base: any) => ({...base, zIndex: 9999})}}
              options={vehicles}
            />
          </div>
          <div className='mt-8' style={{display: 'flex', justifyContent: 'right'}}>
            <button
              disabled={isLoading}
              className='btn btn-secondary me-5'
              onClick={() => {
                setModalVisible(false)
              }}
            >
              Cancel
            </button>
            <button
              className='btn btn-info'
              disabled={isLoading}
              onClick={async () => {
                setIsLoading(true)
                await updateVehicleForDriver({
                  businessProfileId: businessProfileId,
                  driverUserId: driver.idUserProfile,
                  vehicleId: selectedVehicleId,
                })
                await updateInfoForDriver({driverInfo: driver})
                setIsLoading(false)
                setModalVisible(false)
              }}
            >
              {isLoading ? <i className='fa fa-circle-o-notch fa-spin me-2' /> : null}Update
            </button>
          </div>
        </div>
      </ModalPortal>
    )
  }
)

const DeleteDriverVehicleModal = ({
  driver,
  updateInfoForDriver,
  modalVisible,
  setModalVisible,
}: {
  driver: any
  updateInfoForDriver: Function
  modalVisible: boolean
  setModalVisible: Function
}) => {
  const {businessProfileId} = useContext<any>(UserContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <ModalPortal setVisible={setModalVisible} visible={modalVisible} hasExitButton={false}>
      <div className='py-4 px-6 w-100 h-100'>
        <span style={{fontSize: '16px', fontWeight: '500'}}>
          Are you sure you want to delete the vehicle for driver {driver.fullName}?
        </span>
        <div className='mt-8' style={{display: 'flex', justifyContent: 'center'}}>
          <button
            className='btn btn-secondary me-5'
            disabled={isLoading}
            onClick={() => {
              setModalVisible(false)
            }}
          >
            Cancel
          </button>
          <button
            className='btn btn-danger'
            disabled={isLoading}
            onClick={async () => {
              setIsLoading(true)
              await deleteVehicleForDriver({businessProfileId, driverUserId: driver.idUserProfile})
              await updateInfoForDriver({driverInfo: driver})
              setIsLoading(false)
              setModalVisible(false)
            }}
          >
            {isLoading ? <i className='fa fa-circle-o-notch fa-spin me-2' /> : null}Delete
          </button>
        </div>
      </div>
    </ModalPortal>
  )
}

const enum ModalStateEnum {
  None = 'NONE',
  Delete = 'DELETE',
  Update = 'UPDATE',
}

const DriverRowWrapper = ({
  driver,
  updateInfoForDriver,
  currentDriverVehicle,
  style,
  children
}: {
  driver: any
  updateInfoForDriver: Function
  currentDriverVehicle: any
  style?: React.CSSProperties
  children: any
}) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [modalState, setModalState] = useState<ModalStateEnum>(ModalStateEnum.None)
  const [menuProps, toggleMenu] = useMenuState({transition: true})
  const [anchorPoint, setAnchorPoint] = useState({x: 0, y: 0})

  useEffect(() => {
    if (!modalVisible) {
      setModalState(ModalStateEnum.None)
    }
  }, [modalVisible])

  const getFunctionalModal = () => {
    if (modalState === ModalStateEnum.Update) {
      return (
        <UpdateDriverVehicleModal
          driver={driver}
          updateInfoForDriver={updateInfoForDriver}
          currentDriverVehicle={currentDriverVehicle}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      )
    } else if (modalState === ModalStateEnum.Delete) {
      return (
        <DeleteDriverVehicleModal
          driver={driver}
          updateInfoForDriver={updateInfoForDriver}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      )
    }

    return null // State: None
  }

  return (
    <Row key={'row-' + driver.idUserProfile}>
      {getFunctionalModal()}
      <Cell
        className='right-shadow'
        style={{
          ...style,
          width: '360px',
          minWidth: '360px',
          maxWidth: '360px',
          whiteSpace: 'break-spaces',
          borderTop: '1px solid #eee',
          padding: '4px',
        }}
        onContextMenu={(e: any) => {
          e.preventDefault()
          setAnchorPoint({x: e.clientX, y: e.clientY})
          toggleMenu(true)
        }}
      >
        <div style={{display: 'flex'}}>
          {/* Driver */}
          <SmallDriverInfo driver={driver} />

          {/* Vehichle */}
          {currentDriverVehicle && <SmallVehicleInfo vehicle={currentDriverVehicle} />}
        </div>
      </Cell>

      {children}

      <ControlledMenu {...menuProps} anchorPoint={anchorPoint} onClose={() => toggleMenu(false)}>
        <MenuItem
          onClick={() => {
            setModalVisible(true)
            setModalState(ModalStateEnum.Update)
          }}
        >
          <KTSVG
            path='/media/icons/duotone/General/Update.svg'
            className='svg-icon-2 svg-icon-gray-500'
          />
          Update vehicle
        </MenuItem>
        <MenuItem
          onClick={() => {
            setModalVisible(true)
            setModalState(ModalStateEnum.Delete)
          }}
        >
          <KTSVG
            path='/media/icons/duotone/General/Trash.svg'
            className='svg-icon-2 svg-icon-gray-500'
          />
          Delete Vehicle
        </MenuItem>
      </ControlledMenu>
    </Row>
  )
}

export default memo(DriverRowWrapper)

import React, {createContext, useMemo, useState} from 'react'
import {CarModel} from '../app/modules/auth/models/CarModel'
import {
  createCarRequest,
  deleteCarRequest,
  getCarsRequest,
  sendCarActionRequest,
  updateCarRequest,
} from '../setup/axios/cars.request'
import {toast} from 'react-toastify'
import useThrottle from '../hooks/useThrottle'
const CarsContext = createContext({})

function CarsProvider(props: any) {
  const [carsData, setCarsData] = useState<Array<CarModel>>([])
  const [currentCar, setCurrentCar] = useState<CarModel | undefined>(undefined)
  const [selectedCars, setSelectedCars] = useState<Array<CarModel>>([])
  const [showCreateCarModal, setShowCreateCarModal] = useState<boolean>(false)
  const [showCarActionsModal, setShowCarActionsModal] = useState<boolean>(false)

  const getAndSetCars = async () => {
    const response = await getCarsRequest({})
    if (!response || response.error) {
      toast.error(`The cars could't be retrieved! ${response?.message || ''}`)
      return {message: response.message, error: true}
    }
    setCarsData(response.cars)
  }

  // Create car
  const createCar = async (car: CarModel) => {
    const response = await createCarRequest({car})

    if (!response || response.error) {
      let errorMessage = response?.message || ''
      toast.error(`The car couldn't be created! ${errorMessage}`)
      return {message: errorMessage, error: true}
    }

    const futureCarsData = [...carsData, response.car]
    setCarsData(futureCarsData)
    toast.success('The car was successfully created!')
    return {error: false}
  }

  // Update car
  const updateCar = async (car: CarModel) => {
    const response = await updateCarRequest({car})

    if (!response || response.error) {
      let errorMessage = response?.message || ''
      toast.error(`The car couldn't be updated! ${errorMessage}`)
      return {message: errorMessage, error: true}
    }

    const futureCarsData = carsData.map((e) => {
      if (e.id === car.id) {
        e = car
      }

      return e
    })
    setCarsData(futureCarsData)
    toast.success('The car was successfully updated!')
    return {error: false}
  }

  // Delete car
  const deleteCar = async (id: number) => {
    const response = await deleteCarRequest({id})

    if (!response || response.error) {
      let errorMessage = response?.message || ''
      toast.error(`The car couldn't be deleted! ${errorMessage}`)
      return {message: errorMessage, error: true}
    }

    const futureCarsData = carsData.filter((e) => e.id !== id)
    setCarsData(futureCarsData)
    toast.success('The car was successfully deleted!')
    return {error: false}
  }

  // Car actions
  const sendCarActionWithoutThrottle = async (action: string) => {
    const response = await sendCarActionRequest({phone: currentCar!.phoneNumber, action: action})

    if (!response || response.error) {
      let errorMessage = response?.message || ''
      toast.error(`The car action couldn't be sent! ${errorMessage}`)
      return {message: errorMessage, error: true}
    }

    toast.success('Sms was sent!')
    return {error: false}
  }

  const sendCarAction = useThrottle(sendCarActionWithoutThrottle, 10000)

  const isSelectedCar = (car: CarModel): boolean => {
    if (selectedCars.some((e) => e.id === car.id)) {
      return true
    }

    return false
  }
  const store = {
    carsData,
    getAndSetCars,
    createCar,
    deleteCar,
    updateCar,
    isSelectedCar,

    // Current car
    currentCar,
    setCurrentCar,

    // Selected cars
    selectedCars,
    setSelectedCars,

    // Create modal
    showCreateCarModal,
    setShowCreateCarModal,

    // Actions
    sendCarAction,
    showCarActionsModal,
    setShowCarActionsModal,
  }

  const storeForProvider = useMemo(() => store, [store])
  return <CarsContext.Provider value={storeForProvider}>{props.children}</CarsContext.Provider>
}

export {CarsContext}
export default CarsProvider

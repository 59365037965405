import {FC, useEffect, useState} from 'react'
import {TariffServiceType, TariffType} from './PlanTypes'
import {
  epsilon,
  getInitializedObjectListWithEmpty,
  getObjectListWithEmptyStripped,
  updateField,
} from '../../../utils/state.utils'
import {emptyGeofence, GeofenceType} from '../../../types/general.types'
import Table from './Table'
import {displayGeofence} from '../AddEditPlan'
import {usePlanContext} from '../../../context/plan.context'
import {getArrayOfObjectsString, getStringOfEntity} from '../../../utils/string.utils'

const tariffTypeReq = [
  'transferType',
  'startGeofence',
  'endGeofence',
  'service',
  'baseRate',
  'ratePerKm',
  'ratePerHour',
]
const tariffTypeUnique = [
  'transferType',
  'startGeofence',
  'endGeofence',
  'service',
  'extraExceptions',
]
const emptyTariff: TariffType = {
  id: null,
  transferType: '',
  startGeofence: null,
  endGeofence: null,
  service: null,
  extraExceptions: [],
  baseRate: 0,
  ratePerKm: 0,
  ratePerHour: 0,
  commission: {percentage: false, value: 0},
  commissionPercentage: false,
  commissionValue: 0,
}
const tariffExceptions = [
  {
    message:
      'commission is represented in percentages, but the value is outside of the 0-100% range',
    isEntryValid: (entry: TariffType) => {
      if (!entry.commissionPercentage) {
        return true
      } else {
        return entry.commissionValue >= 0 && entry.commissionValue <= 100
      }
    },
  },
]
const getTarifflabel = (tariff: TariffType) =>
  'tariff (transferType: ' +
  (tariff.transferType || '') +
  '; startGeofence: ' +
  getStringOfEntity({
    entity: tariff.startGeofence,
    identifier: 'name',
    emptyStringReplacement: 'ALL',
  }) +
  '; endGeofence: ' +
  getStringOfEntity({
    entity: tariff.endGeofence,
    identifier: 'name',
    emptyStringReplacement: 'ALL',
  }) +
  '; service: ' +
  getStringOfEntity({entity: tariff.service, identifier: 'name'}) +
  (tariff.extraExceptions && tariff.extraExceptions.length > 0
    ? '; extraExceptions: ' +
      getArrayOfObjectsString({
        list: tariff.extraExceptions || [],
        identifier: 'title',
        maxLength: 25,
      })
    : '') +
  ')'

// 'transferType',
// 'startGeofence',
// 'endGeofence',
// 'service',
// 'extraExceptions',

const getGeofenceValue = (geofence: GeofenceType) => {
  if (!Boolean(geofence)) return ''
  if (!Boolean(geofence?.id)) return emptyGeofence
  return {...geofence, polygon: null}
}

const TariffsTable: FC<{}> = ({}) => {
  const {plan, setPlan, dictionaries, tariffServices, geofences} = usePlanContext()

  const [tariffs, setTariffs] = useState<TariffType[]>([])

  useEffect(() => {
    let futureTariffs = getInitializedObjectListWithEmpty({
      initialObjectList: plan?.tariffs || [],
      propsWithEmpty: [
        {property: 'startGeofence', emptyValue: emptyGeofence},
        {property: 'endGeofence', emptyValue: emptyGeofence},
      ],
    })
    // deconstruct the commission composite field into simple fields
    futureTariffs = futureTariffs.map((tariff: TariffType) => {
      return {
        ...tariff,
        commissionPercentage: tariff.commission?.percentage || false,
        commissionValue: tariff.commission?.value || 0,
      }
    })
    setTariffs(futureTariffs)
  }, [])

  useEffect(() => {
    let futureTariffs = getObjectListWithEmptyStripped({
      objectList: tariffs || [],
      propsWithEmpty: [
        {property: 'startGeofence', emptyValue: emptyGeofence},
        {property: 'endGeofence', emptyValue: emptyGeofence},
      ],
    })
    // reconstruct the commission object from simple fields to composite field
    futureTariffs = futureTariffs.map((tariff: TariffType) => {
      return {
        ...tariff,
        commission: {
          percentage: tariff.commissionPercentage || false,
          value: tariff.commissionValue || 0,
        },
      }
    })
    setPlan({...plan, tariffs: futureTariffs})
  }, [tariffs])

  const tariffProps = [
    {
      name: 'transferType',
      getDisplayLabel: (transferType: string) =>
        dictionaries.PRICING_TRANSFER_TYPES.find((item: any) => item.name === transferType)?.title,
      label: 'Transfer Type',
      type: 'select',
    },
    {
      name: 'startGeofence',
      getDisplayLabel: (geofence: GeofenceType) => displayGeofence(geofence, dictionaries),
      label: 'Start Geofence',
      type: 'select',
    },
    {
      name: 'endGeofence',
      getDisplayLabel: (geofence: GeofenceType) => displayGeofence(geofence, dictionaries),
      label: 'End Geofence',
      type: 'select',
    },
    {
      name: 'service',
      getDisplayLabel: (service: TariffServiceType) => service.name,
      label: 'Service',
      type: 'select',
    },
    {
      name: 'extraExceptions',
      getDisplayLabel: (exceptions: any[]) => exceptions.map((exc: any) => exc.title).join(', '),
      label: 'Extra Exceptions',
      type: 'select',
      isMulti: true,
    },
    {
      name: 'baseRate',
      getDisplayLabel: (e: any) => e,
      label: 'Base Rate',
      type: 'currencyInput',
      canEditInView: true,
      addOns: {append: (plan?.currency || '').toLowerCase()},
      minWidth: '135px',
    },
    {
      name: 'ratePerKm',
      getDisplayLabel: (e: any) => e,
      label: 'Rate/km',
      type: 'currencyInput',
      canEditInView: true,
      addOns: {append: (plan?.currency || '').toLowerCase()},
      minWidth: '135px',
    },
    {
      name: 'ratePerHour',
      getDisplayLabel: (e: any) => e,
      label: 'Rate/h',
      type: 'currencyInput',
      canEditInView: true,
      addOns: {append: (plan?.currency || '').toLowerCase()},
      minWidth: '135px',
    },
    {
      name: 'commissionPercentage',
      getDisplayLabel: (commissionPercentage: any) =>
        commissionPercentage ? 'Percentage' : 'Currency',
      label: 'Commission Unit',
      type: 'select',
    },
    {
      name: 'commissionValue',
      getDisplayLabel: (e: any) => e,
      label: 'Commission',
      type: 'currencyInput',
      canEditInView: true,
      dependentOfEntry: true,
      getAddOns: (tariff: TariffType) =>
        tariff.commissionPercentage
          ? {prepend: '%'}
          : {append: (plan?.currency || '').toLowerCase()},
      minWidth: '135px',
    },
  ]

  const TariffFieldToProperty = (field: any, tariff: TariffType, setTariff: Function) => {
    let fieldName = field.name
    const updateTariffListElemEvent = (e: any) =>
      updateField({value: e, fieldName: fieldName, setObject: setTariff})

    const fieldsToPanelPropertiesMapping = {
      transferType: {
        simpleValue: tariff.transferType || '',
        options: dictionaries.PRICING_TRANSFER_TYPES || [],
        getOptionValue: (e: any) => e.name || '',
        getOptionLabel: (e: any) => e.title || '',
        onChange: updateTariffListElemEvent,
      },
      startGeofence: {
        simpleValue: tariff.startGeofence || '',
        options: [emptyGeofence, ...(geofences || [])],
        getOptionValue: (geofence: GeofenceType) => getGeofenceValue(geofence) || '',
        getOptionLabel: (geofence: GeofenceType) => displayGeofence(geofence, dictionaries) || '',
        onChange: updateTariffListElemEvent,
        sorted: true,
      },
      endGeofence: {
        simpleValue: tariff.endGeofence || '',
        options: [emptyGeofence, ...(geofences || [])],
        getOptionValue: (geofence: GeofenceType) => getGeofenceValue(geofence) || '',
        getOptionLabel: (geofence: GeofenceType) => displayGeofence(geofence, dictionaries) || '',
        onChange: updateTariffListElemEvent,
        sorted: true,
      },
      service: {
        simpleValue: tariff.service || '',
        options: tariffServices || [],
        getOptionValue: (e: any) => e || '',
        getOptionLabel: (e: any) => e.name || '',
        onChange: updateTariffListElemEvent,
      },
      extraExceptions: {
        simpleValue: tariff.extraExceptions || [],
        options: dictionaries.PRICING_EXTRAS || [],
        getOptionValue: (e: any) => e || '',
        getOptionLabel: (e: any) => e.title || '',
        onChange: updateTariffListElemEvent,
      },
      baseRate: tariff.baseRate,
      ratePerKm: tariff.ratePerKm,
      ratePerHour: tariff.ratePerHour,
      commissionPercentage: {
        simpleValue: tariff.commissionPercentage || false,
        options: [
          {label: 'Percentage', value: true},
          {label: 'Currency', value: false},
        ],
        getOptionValue: (e: any) => e.value,
        getOptionLabel: (e: any) => e.label,
        onChange: updateTariffListElemEvent,
      },
      commissionValue: {
        value: tariff.commissionValue,
        effectOverValueOnChange: (val: number | string) => {
          // if we don't have a percentage we return the value without modifying it
          if (!tariff.commissionPercentage) return val
          // if we have percentage, we apply the constrains
          if (val != 0 && !Boolean(val)) return '0'
          let parsedValue = parseFloat(val + '')
          const maxVal = 100 - epsilon
          return parsedValue < epsilon ? '0' : parsedValue > maxVal ? '100' : val + ''
        },
      },
    }

    return (fieldsToPanelPropertiesMapping as any)[fieldName]
  }

  return (
    <>
      <Table
        tableLabel={'Tariff Table'}
        properties={tariffProps}
        fieldToProperty={TariffFieldToProperty}
        entries={tariffs}
        setEntries={setTariffs}
        emptyEntry={emptyTariff}
        getEntryLabel={getTarifflabel}
        requiredFields={tariffTypeReq}
        compoundUniqueKey={tariffTypeUnique}
        extraExceptions={tariffExceptions}
      />
    </>
  )
}

export default TariffsTable

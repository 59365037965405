import { FC, useEffect, useState } from 'react'
import Table from './Table'
import { RentPlanInsuranceModel, emptyRentPlanInsurance } from '../../../app/modules/rent-plans/models/RentPlanInsuranceModel'
import { createRentPlanResponse, deleteRentResponse, getRentPlanInsurance, putRentPlanResponse } from '../../../setup/axios/rent.request'
import { toast } from 'react-toastify'
import { getStringOfEntity } from '../../../utils/string.utils'
import { updateField } from '../../../utils/state.utils'
import { useParams } from 'react-router-dom'
import Collapsible from 'react-collapsible';
import { InputComponentGroup } from '../../../components/field/field-generator'
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { usePlanContext } from '../../../context/plan.context'

const responseRequiredFields = [
  'name',
  'franchise',
  'cost'
]

const getResponseLabel = () => "Liability";
const compoundUniqueKeyResponse = ['name']

const RentPlanResponseFieldToProperty = (
  field: any,
  rentPlanResponse: RentPlanInsuranceModel,
  setRentPlanResponse: Function
) => {
  let fieldName = field.name
  const updateExtrasListElemEvent = (e: any) =>
    updateField({ value: e, fieldName: fieldName, setObject: setRentPlanResponse })

  const fieldsToPanelPropertiesMapping = {
    name: { value: getStringOfEntity({ entity: rentPlanResponse.name }) }
  }

  return (fieldsToPanelPropertiesMapping as any)[fieldName]
}

const ResponseTable: FC<{}> = ({ }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [rentPlanInsurance, setRentPlanInsurance] = useState<RentPlanInsuranceModel[]>([])
  const [toBeEdited, setToBeEdited] = useState<any>();
  const [isCollapsableClicked, setIsCollapsableClicked] = useState<boolean[]>([]);
  const { id, companyId } = useParams<any>()
  const { rentCompanyPricingPlan } = usePlanContext()

  useEffect(() => {
    updateInsurances();
  }, [])

  const updateInsurances = async () => {
    return getRentPlanInsurance(id).then((futureRentPlanInsurance: RentPlanInsuranceModel[] | null) => {
      if (!futureRentPlanInsurance) {
        return toast.error('The Rent Plan Insurance failed to load!')
      }
      let copyInsuranceArray = [];
      futureRentPlanInsurance.forEach(vehicleClass => {
        let secondary_array = [];
        secondary_array = vehicleClass.all_insurances.map(insurance => {
          const existingInsurance = vehicleClass.insurances.find(ins => parseInt(ins.insurance_id) === insurance.id);
          if (existingInsurance) return existingInsurance;
          else return {
            ...insurance,
            insurance_id: insurance.id,
            franchise: "",
            cost: "",
          }
        })
        copyInsuranceArray = copyInsuranceArray.concat([{ ...vehicleClass, insurances: secondary_array, totalInsurances: secondary_array.length }])
      })
      setRentPlanInsurance([...copyInsuranceArray])
      setIsCollapsableClicked(new Array(copyInsuranceArray.length).fill(false))
      setIsLoading(false)
    })
  }

  let tableInsurances = [];
  rentPlanInsurance.forEach(vehicleClass => {
    tableInsurances = vehicleClass.all_insurances.map((insurance, idx) => {
      return {
        name: insurance.name,
        getDisplayLabel: (label: string) => "",
        editOnColumn: true,
        customValue: insurance,
        label: insurance.name,
        children: [
          {
            name: 'insurances',
            getDisplayLabel: (insurances: any[]) => insurances[idx].franchise,
            label: 'Franchise fixed cost',
            addOns: { append: rentCompanyPricingPlan.currency },
            type: 'input'
          },
          {
            name: 'insurances',
            getDisplayLabel: (insurances: any[]) => insurances[idx].cost,
            label: 'Insurance cost',
            addOns: { append: "%" },
            type: 'input'
          },
        ]
      }
    })

  })
  const rentPlanInsuranceProps = [
    {
      name: 'name',
      getDisplayLabel: (name: string) => "",
      label: '',
      width: 250,
      children: [
        {
          name: "name",
          getDisplayLabel: (name: string, row: any) => {
            return row.image ? <div style={{ minWidth: 150, minHeight: 80, fontSize: 12, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 10 }}> <img src={row.image} style={{ marginRight: 14, width: 40, height: 40, marginBottom: 20 }} />{row.name}</div> : <div>{row.name}</div>
          },
          label: "Class name",
          hideInTable: true
        }
      ]
    },
    ...[...tableInsurances],
  ]

  const EditModal = (p: { setModalVisible: (modalVisible: boolean) => void, setToBeEditedTable: (toBeEditedTable: any) => void }) => {
    const isEditMode = toBeEdited.isEditMode;
    return (
      <div className='py-4 px-6 w-100 h-100'>
        <span style={{ fontSize: '16px', fontWeight: '500' }}>{isEditMode ? "Edit" : "Add"} Table Entity</span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          className='mt-4'
        >
          <div className='py-1'>
            <label htmlFor='input' className='form-label'>Insurance name</label>
            <input
              id='input'
              style={{
                borderRadius: '0.475rem 0rem 0rem 0.475rem',
              }}

              className={`form-control form-control-sm`}
              value={toBeEdited.name}
              onChange={(ev) => setToBeEdited({ ...toBeEdited, name: ev.target.value })}
            />
          </div>
          <div className="py-1">
            {rentPlanInsurance.map((r, idx) => <Collapsible key={r.id} trigger={<div className="py-1" onClick={() => {
              isCollapsableClicked[idx] = !isCollapsableClicked[idx];
              setIsCollapsableClicked([...isCollapsableClicked]);
            }} style={{ marginTop: 8, display: "flex", justifyContent: "space-between" }}>
              <span style={{ fontWeight: "bold" }}>{r.name}</span>
              {isCollapsableClicked[idx] ? <CaretUpFill color="gray" size={18} /> : <CaretDownFill color="gray" size={18} />}
            </div>}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row", marginTop: 8 }}>
                  <label htmlFor='franchise' className='form-label' style={{ width: "50%", }}>Franchise Fixed Cost</label>
                  <label htmlFor='cost' className='form-label' style={{ width: "50%" }}>Insurance Cost</label>
                </div>
                <div style={{ display: "flex", }}>
                  <InputComponentGroup addOns={{ append: rentCompanyPricingPlan.currency }}>
                    <input
                      id='input'
                      style={{
                        borderRadius: '0.475rem 0rem 0rem 0.475rem',
                        width: "70%"
                      }}
                      className={`form-control form-control-sm`}
                      value={r.insurances.find(ins => ins.insurance_id == toBeEdited.id) ? r.insurances.find(ins => ins.insurance_id == toBeEdited.id).franchise : ""}
                      onChange={(ev) => {
                        const i = r.insurances.map(ins => parseInt(ins.insurance_id)).indexOf(toBeEdited.id);
                        if (i !== -1) {
                          r.insurances[i] = {
                            ...r.insurances[i],
                            franchise: ev.target.value
                          }
                        } else {
                          r.insurances[r.totalInsurances] = {
                            ...r.insurances[r.totalInsurances],
                            plan_vehicle_class_id: r.id,
                            franchise: ev.target.value,
                            cost: (r.insurances[r.totalInsurances] && r.insurances[r.totalInsurances].cost) ? r.insurances[r.totalInsurances].cost : ""
                          }
                        }
                        setRentPlanInsurance([...rentPlanInsurance])
                      }}
                    />
                  </InputComponentGroup>
                  <InputComponentGroup addOns={{ append: "%" }}>
                    <input
                      id='input'
                      style={{
                        borderRadius: '0.475rem 0rem 0rem 0.475rem',
                        width: "70%",
                      }}
                      className={`form-control form-control-sm`}
                      value={r.insurances.find(ins => ins.insurance_id == toBeEdited.id) ? r.insurances.find(ins => ins.insurance_id == toBeEdited.id).cost : ""}
                      onChange={(ev) => {
                        const i = r.insurances.map(ins => parseInt(ins.insurance_id)).indexOf(toBeEdited.id);
                        if (i !== -1) {
                          r.insurances[i] = {
                            ...r.insurances[i],
                            cost: ev.target.value
                          }
                        } else {
                          r.insurances[r.totalInsurances] = {
                            ...r.insurances[r.totalInsurances],
                            plan_vehicle_class_id: r.id,
                            cost: ev.target.value,
                            franchise: (r.insurances[r.totalInsurances] && r.insurances[r.totalInsurances].franchise) ? r.insurances[r.totalInsurances].franchise : ""
                          }
                        }
                        setRentPlanInsurance([...rentPlanInsurance])
                      }}
                    />
                  </InputComponentGroup>
                </div>
              </div>
            </Collapsible>)}
          </div>
        </div>
        <div className='mt-8' style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            className='btn btn-secondary me-5'
            onClick={async () => {
              await updateInsurances()
              p.setModalVisible(false);
              p.setToBeEditedTable(null);
            }}
          >
            Cancel
          </div>
          <button
            className='btn btn-success'
            onClick={() => {
              setIsLoading(true)
              if (!isEditMode) {
                const classesWithInsurance = rentPlanInsurance.map(rpi => {
                  const itemToBeAdded = rpi.insurances[rpi.insurances.length - 1];
                  const isItemToBeAdded = rpi.insurances.length > rpi.totalInsurances;
                  return {
                    plan_vehicle_class_id: isItemToBeAdded ? itemToBeAdded.plan_vehicle_class_id : rpi.id,
                    franchise: isItemToBeAdded ? itemToBeAdded.franchise : "",
                    cost: isItemToBeAdded ? itemToBeAdded.cost : ""
                  }
                })
                createRentPlanResponse({ company_id: companyId, name: toBeEdited.name, classes: classesWithInsurance }, id, companyId).then(async (response) => {
                  if (!response) {
                    setIsLoading(false)
                    return;
                  }
                  else {
                    await updateInsurances();
                    p.setModalVisible(false);
                    p.setToBeEditedTable(null);
                    setIsLoading(false)
                  }
                });
              } else {
                const classesWithInsurance = rentPlanInsurance.map(rpi => {
                  const itemToBeEdited = rpi.insurances.find(ins => ins.insurance_id == toBeEdited.id);
                  return {
                    id: itemToBeEdited.id,
                    plan_vehicle_class_id: rpi.id,
                    franchise: itemToBeEdited.franchise,
                    cost: itemToBeEdited.cost
                  }
                })
                putRentPlanResponse({ company_id: companyId, name: toBeEdited.name, id: toBeEdited.id, classes: classesWithInsurance }, id).then(async (response) => {
                  if (!response) {
                    setIsLoading(false)
                    return;
                  }
                  else {
                    await updateInsurances();
                    p.setModalVisible(false);
                    p.setToBeEditedTable(null);
                    setIsLoading(false)
                  }
                })
              }
            }}
            disabled={isLoading}
          >
            Save
          </button>
        </div>
      </div>
    )
  }

  return (
    <>
      <Table
        tableLabel={'Liability'}
        properties={rentPlanInsuranceProps}
        fieldToProperty={RentPlanResponseFieldToProperty}
        entries={rentPlanInsurance}
        setEntries={setRentPlanInsurance}
        emptyEntry={emptyRentPlanInsurance}
        getEntryLabel={getResponseLabel}
        requiredFields={responseRequiredFields}
        compoundUniqueKey={compoundUniqueKeyResponse}
        deleteEndpoint={() => deleteRentResponse(toBeEdited.id).then(async () => await updateInsurances())}
        editEndpoint={putRentPlanResponse}
        createEndpoint={(value) => createRentPlanResponse(value, id, companyId)}
        isPlanCreated={!!id}
        hasChildProperties={true}
        addEditOnColumn
        onEditCustom={setToBeEdited}
        customEditModal={EditModal}
        addEntryLabel="Add Liability"
        isResponse
      />
    </>
  )

}

export default ResponseTable

import React, { PropsWithChildren, createContext, useCallback, useMemo, useState } from 'react'
import { LoadScript } from '@react-google-maps/api'
import { EASYTRACK_API_URL, GOOGLE_API_KEY } from '../constants/api.constants'
import easytrackAxios from '../setup/easytrack.axios'
import { AppContextType } from '../types/app.types'

const AppContext = createContext<AppContextType>(null)

const prepareAndGetPromise = ({ promise, setPromise, requestURL }: {
  promise: Promise<any>,
  setPromise: any,
  requestURL: string
}) => {
  if (promise) {
    return promise
  }

  const futurePromise = easytrackAxios.get(`${EASYTRACK_API_URL}${requestURL}`)
  setPromise(futurePromise)
  return futurePromise
}

function AppProvider(props: PropsWithChildren<unknown>) {
  const [siteName, setSiteName] = useState<string>('Crème Brûlée')
  const [loading, setLoading] = useState<boolean>(false)

  const [companiesRequest, setCompaniesRequest] = useState<Promise<any>>()
  const [departmentsRequest, setDepartmentsRequest] = useState<Promise<any>>()
  const [dictionariesRequest, setDictionariesRequest] = useState<Promise<any>>()
  const [driversRequest, setDriversRequest] = useState<Promise<any>>()
  const [tariffTypesRequest, setTariffTypesRequest] = useState<Promise<any>>()
  const [tariffServicesRequest, setTariffServicesRequest] = useState<Promise<any>>()
  const [vehiclesRequest, setVehiclesRequest] = useState<Promise<any>>()
  const [vehicleBrandsRequest, setVehicleBrandsRequest] = useState<Promise<any>>()
  const [vehicleTypesRequest, setVehicleTypesRequest] = useState<Promise<any>>()

  /**
   * This is a function that returns a promise with the request 
   *
   * @param  {string} key - the name of the variable we want to retrieve
   * @returns boolean or any type
  */
  const getPromiseByKey = (key: string, args?: {businessProfileId: number | string}): Promise<{ requestURL: string, promise: any, setPromise: any }> => {
    switch (key) {
      case 'companies':
        return prepareAndGetPromise({ requestURL: `/companies?businessProfileId=${args?.businessProfileId}`, promise: companiesRequest, setPromise: setCompaniesRequest })
      case 'departments':
        return prepareAndGetPromise({ requestURL: `/departments?businessProfile=${args?.businessProfileId}`, promise: departmentsRequest, setPromise: setDepartmentsRequest })
      case 'dictionaries':
        return prepareAndGetPromise({ requestURL: `/utils/dictionaries`, promise: dictionariesRequest, setPromise: setDictionariesRequest })
      case 'drivers':
        return prepareAndGetPromise({ requestURL: `/user-profiles?businessProfileId=${args?.businessProfileId}`, promise: driversRequest, setPromise: setDriversRequest })
      case 'tariffTypes':
        return prepareAndGetPromise({ requestURL: `/tariff-types?businessProfileId=${args?.businessProfileId}`, promise: tariffTypesRequest, setPromise: setTariffTypesRequest })
      case 'tariffServices':
        return prepareAndGetPromise({ requestURL: `/tariff-services?businessProfileId=${args?.businessProfileId}`, promise: tariffServicesRequest, setPromise: setTariffServicesRequest })
      case 'vehicles':
        return prepareAndGetPromise({ requestURL: `/vehicles`, promise: vehiclesRequest, setPromise: setVehiclesRequest })
      case 'vehicleBrands':
        return prepareAndGetPromise({ requestURL: `/vehicle-brands?withModels=true`, promise: vehicleBrandsRequest, setPromise: setVehicleBrandsRequest })
      case 'vehicleTypes':
        return prepareAndGetPromise({ requestURL: `/vehicle-types`, promise: vehicleTypesRequest, setPromise: setVehicleTypesRequest })
    }
  }

  const initializeData = useCallback((businessProfileId: number) => {
    getPromiseByKey('companies', { businessProfileId })
    getPromiseByKey('departments', { businessProfileId })
    getPromiseByKey('dictionaries')
    getPromiseByKey('drivers', { businessProfileId })
    getPromiseByKey('tariffTypes', { businessProfileId })
    getPromiseByKey('tariffServices', { businessProfileId })
    getPromiseByKey('vehicles')
    getPromiseByKey('vehicleBrands')
    getPromiseByKey('vehicleTypes')
  }, [])

  const store = {
    loading,
    setLoading,
    siteName,
    setSiteName,
    getPromiseByKey,
    initializeData,
  }

  const storeForProvider = useMemo(() => store, [store])
  return (
    <LoadScript googleMapsApiKey={GOOGLE_API_KEY} libraries={['places', 'drawing']}>
      <AppContext.Provider value={storeForProvider}>{props.children}</AppContext.Provider>
    </LoadScript>
  )
}

export { AppContext }
export default AppProvider

import React, { useContext, useState } from 'react'
import InputField from '../../_metronic/layout/components/fields/input/input-field'
import { PackageContext } from '../../context/packages.context'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../_metronic/helpers'
import { Link } from 'react-router-dom'
import Checkbox from '../../_metronic/layout/components/fields/checkbox/checkbox'
import Select from 'react-select'
import RadioSelect from '../radio-select/RadioSelect.componet'
import { getDefaultOption } from '../../utils/state.utils'

const AddEditModal = (
  { 
    data, 
    addEditModalVisible, 
    setAddEditModalVisible,
    fields,
    type,
    saveMethod,
    redirect,
    redirectText
  }:{ 
    data: any, 
    addEditModalVisible: boolean,
    setAddEditModalVisible: Function,
    fields:any,
    type:string
    saveMethod:Function,
    redirect?:string;
    redirectText?:string;
  }) => {

  const { isLoading } = useContext<any>(PackageContext);
  const [localData, setLocalData] = useState<any>(data);

  const getRadioSelectValue = (field) =>{
    let value ;
    field.options.forEach((option) => {
      if (option.name == localData[field.backend_property]) {
        value = option.id;
        return;
      }
    })
    return value
  }

  const onSaveMethod = async()=>{
    let payload = { ...localData };
    if (localData?.id) {
      payload.id = localData?.id
    }
    await saveMethod({ payload: payload })
    setAddEditModalVisible(false);
  }


  const renderField = (field) =>{
    if(!field){
      return
    }
    let checkCondition = true;

    if (field.isVisible) {
      checkCondition = localData[field.isVisible.field] == field.isVisible.value;
    }
    switch(true){
      case field.type === 'text' && checkCondition:
        return <InputField
          key={field?.label}
          value={localData[field.backend_property]}
          field={field}
          onChange={(ev) => {
            let value = ev.target.value;
            setLocalData(prevState => ({
              ...prevState,
              [field.backend_property]: value
            }))
          }}
        />
      case field.type === 'checkbox' && checkCondition:
        return <Checkbox
          key={field?.label}
          field={field}
          value={localData[field.backend_property]}
          onChange={(e) => {
            let value = e.target.checked
            setLocalData(prevState => ({
              ...prevState,
              [field.backend_property]: value
            }))
          }}
        />
      case field.type === 'radioSelect' && checkCondition:
        return <RadioSelect
          key={field?.label}
          isRequired={field.required}
          label={field.label}
          items={field.options}
          value={getRadioSelectValue(field)}
          onItemSelect={(e) => {
            setLocalData(prevState => ({
              ...prevState,
              [field.backend_property]: e
            }))
          }}
        />
      case field.type === 'select' && checkCondition:
        return <>
          <label className='col-lg-4 col-form-label required fw-bold fs-6'>{field.label}</label>
          <Select
            key={field?.label}
            defaultValue={getDefaultOption(field, localData)}
            onChange={(optionsInstance: any) => {
              let value = optionsInstance.value;
              setLocalData(prevState => ({
                ...prevState,
                [field.backend_property]: value
              }))
            }}
            options={field.options}
          />
        </>
      default:
        return <></>
    }
  } 

  return (
    <Modal
      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
      id='kt_inbox_compose'
      role='dialog'
      data-backdrop='false'
      aria-hidden='true'
      tabIndex='-1'
      show={addEditModalVisible}
      animation={false}
    >
      <div className='modal-content'>
        <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
          <h5 className='fw-bold m-0'>{data?.id ? `Edit ${type} ${data.id}` : `Create a new ${type}`}</h5>
          <div className='d-flex ms-2'>
            <div
              className='btn btn-icon btn-sm btn-light-primary ms-2'
              data-bs-dismiss='modal'
              onClick={() => {
                setAddEditModalVisible(false)
              }}
            >
              <KTSVG className='svg-icon-1' path='/media/icons/duotone/Navigation/Close.svg' />
            </div>
          </div>
        </div>
        <div className='d-block py-5 ps-8 pe-5'>
          {fields.map((field) => {
            return <div className='fv-row mb-7' key={field?.label} >
              {renderField(field)}
            </div>
          })}
          {localData.id && redirect ? <Link to={redirect}><p>{redirectText}</p></Link> : null}
        </div>

        <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-top'>
          <div className='d-flex align-items-center me-3'></div>
          <div className='d-flex align-items-center'>
            <button
              disabled={isLoading}
              onClick={onSaveMethod}
              className='btn btn-primary px-6'
            >
              {data?.id ? 'Update' : 'Create'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AddEditModal

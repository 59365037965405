import React from 'react'
import {toast} from 'react-toastify'
import {EASYTRACK_API_URL} from '../../../constants/api.constants'
import easytrackAxios from '../../../setup/easytrack.axios'
import {companyPOI, POI} from './poiConstants'

// this will be used as scaffolding for the axios request sanitizer function
export const addEditPOIRequest = async (poi: companyPOI) => {
  const result = await easytrackAxios
    .post(`${EASYTRACK_API_URL}/companies/${poi.companyId}/customer-pois`, {...poi})
    .then((res) => res)
    .catch((error) => {
      let type = ''
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        type = 'outside 2xx range'
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        type = 'no response received'
      } else {
        // Something happened in setting up the request that triggered an Error
        type = 'invalid request'
      }
      const message = error.toJSON()?.message || ''
      // console.warn(`Error of type "${type}" encountered with message "${message}"!`)
      return error;
    })
  return result
}

export const getPoisWithCompany = (companyId: number, pois: POI[]) => {
  let poiList = pois.map((poi: POI) => {
    let modifiedPOI: POI = {...poi, companyId: companyId}
    return modifiedPOI
  })
  return poiList
}

export const convertPOIListToTransportPOIList = (poiList: POI[]): any[] => {
  let transportPoiList = poiList.map((poi: POI) => {
    let poiObj: any = {}
    Object.entries(poi).map(([key, value]) => {
      if (key != 'companyId' && key != 'uniqueId') {
        poiObj[key] = value
      }
    })
    return poiObj
  })
  return transportPoiList
}

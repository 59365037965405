import { RentPlanDayCostsModel } from "./RentPlanDayCostsModel"
import { RentPlanDislocationCostsModel } from "./RentPlanDislocationCostsModel"
import { RentPlanExtraCostsModel } from "./RentPlanExtraCostsModel"
import { RentPlanInsuranceModel } from "./RentPlanInsuranceModel"
import { RentPlanIntervalModel } from "./RentPlanIntervalModel"
import { RentPlanTariffsModel } from "./RentPlanTariffsModel"
import { RentPlanVehicleClassModel } from "./RentPlanVehicleClassModel"

export type RentCompanyPricingPlanModel = {
    id: number
    company_id: string
    name: string
    description: string
    currency: string
    created_at: string | null
    updated_at: string | null
    rentPlanDayCosts: RentPlanDayCostsModel[] | null
    rentPlanDislocationCosts: RentPlanDislocationCostsModel[] | null
    rentPlanExtraCosts: RentPlanExtraCostsModel[] | null
    rentPlanInsurance: RentPlanInsuranceModel[] | null
    rentPlanIntervals: RentPlanIntervalModel[] | null
    rentPlanTariffs: RentPlanTariffsModel[] | null
    rentPlanVehicleClass: RentPlanVehicleClassModel[] | null
}

export const emptyRentCompanyPricingPlan = {
    id: -1,
    company_id: "-1",
    name: "",
    description: "",
    currency: "",
    created_at: null,
    updated_at: null,
    rentPlanDayCosts: [],
    rentPlanDislocationCosts: [],
    rentPlanExtraCosts: [],
    rentPlanInsurance: [],
    rentPlanIntervals: [],
    rentPlanTariffs: [],
    rentPlanVehicleClass: [],
    start_day: "",
    end_day: ""
}

import React, { useEffect, useState } from 'react';
import { useEditTemplate } from '../../../../context/edit-template-context';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { EyeIcon, EyeIconSlash } from './FieldComponent';
import { EditableLabel } from './fields-generator';
import { FlexibleObject } from '../../../../utils/state.utils';

const DragHandle = SortableHandle(() => (
  <div className='btn btn-icon btn-active-color-primary card__draggable-handle draggable-handle w-30px'>
    <i className='fas fa-arrows-alt'></i>
  </div>
));

const SortableItem = SortableElement(({ column, index, indexCount, mode, changeColumn }) => {
  const handleEditLabel = (value: string) => {
    const futureColumn = JSON.parse(JSON.stringify(column));
    futureColumn.label = value;

    changeColumn(futureColumn);
  };

  return (
    <div
      key={index}
      className='pb-7 card-field'
      style={{
        opacity: column?.visible ? 1 : 0.5,
        display: mode === 'view' && !column.visible ? 'none' : 'block',
      }}
    >
      {mode === 'edit' ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            height: '32px',
          }}
        >
          <div
            onClick={() => {
              // Toggle visibility
              const futureColumn = JSON.parse(JSON.stringify(column));
              futureColumn.visible = !futureColumn.visible;
              changeColumn(futureColumn);
            }}
          >
            {column.visible ? <EyeIcon /> : <EyeIconSlash />}
          </div>
          <DragHandle />
        </div>
      ) : null}

      <span
        style={{
          marginRight: '10px',
        }}
      >
        {indexCount + 1}
      </span>
      <EditableLabel
        label={column.label}
        onChange={mode === 'edit' ? handleEditLabel : undefined}
        isRequired={false}
      />
    </div>
  );
});

const SortableList = SortableContainer(({ columns, mode, changeColumn }) => {
  return (
    <ul className='card-body py-3 sortable-zone autonom-dynamic-card'>
      {columns.map((column, index) => {
        return (
          <SortableItem
            mode={mode}
            changeColumn={changeColumn}
            column={column}
            indexCount={index}
            index={index}
            key={`column-item-${index}`}
          />
        );
      })}
    </ul>
  );
});

// Get difference between two arrays of objects by key
const getDifference = (array1: FlexibleObject[], array2: FlexibleObject[], keyName) => {
  const result = [];

  array1.forEach((item1) => {
    const found = array2.find((item2) => item1[keyName] === item2[keyName]);

    if (!found) {
      result.push(item1);
    }
  });

  return result;
};

const TableColumns = () => {
  const { template, setTemplate } = useEditTemplate();
  const [mode, setMode] = useState<'edit' | 'view'>('view');

  const getAndSetColumns = () => {
    const templateClone = JSON.parse(JSON.stringify(template));

    let futureColumns = [];
    // If template has no columns, use default
    if (!templateClone.columns) {
      futureColumns = defaultColumns;
      setTemplate({ ...templateClone, columns: futureColumns });
      return;
    }

    // Remove columns that are not in default columns
    templateClone.columns = templateClone.columns.filter((column) => {
      return defaultColumns.find(
        (defaultColumn) => defaultColumn['backend_property'] === column['backend_property']
      );
    });

    // New default columns added
    const diff = getDifference(defaultColumns, templateClone.columns, 'backend_property');
    if (diff.length > 0) {
      futureColumns = [...templateClone.columns, ...diff];
      return setTemplate({ ...templateClone, columns: futureColumns });
    }

    // Removed default columns
    const diff2 = getDifference(templateClone.columns, defaultColumns, 'backend_property');
    if (diff2.length > 0) {
      futureColumns = templateClone.columns.filter(
        (column) => !diff2.find((item) => item.backend_property === column.backend_property)
      );
      return setTemplate({ ...templateClone, columns: futureColumns });
    }
  };

  useEffect(() => {
    getAndSetColumns();
  }, []);

  const changeColumn = (newColumn: ColumnType) => {
    const newColumns = template.columns.map((column) => {
      if (column.backend_property === newColumn.backend_property) {
        return newColumn;
      }
      return column;
    });

    setTemplate({ ...template, columns: newColumns });
  };

  return (
    <div
      className={`card draggable has-card-options`}
      style={{
        marginTop: 16,
      }}
    >
      {/* begin::Header */}
      <div className='card-header pt-5 border-0'>
        <h3 className='card-title align-items-start flex-column'>
          {/* Panel name */}
          <label className='card-label fw-bolder fs-3 mb-1'>Table columns</label>
        </h3>
        <div className='card-toolbar'>
          <div className='card-settings'>
            <div
              onClick={() => {
                setMode(mode === 'edit' ? 'view' : 'edit');
              }}
              className='btn btn-icon btn-active-color-primary w-30px'
            >
              <i className='fas fa-cog'></i>
            </div>
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <SortableList
        changeColumn={changeColumn}
        columns={template.columns || []}
        axis='xy'
        useDragHandle
        mode={mode}
        onSortEnd={({ oldIndex, newIndex }) => {
          // Change panel fields order

          const newColumns = [...template.columns];
          newColumns.splice(newIndex, 0, newColumns.splice(oldIndex, 1)[0]);
          setTemplate({ ...template, columns: newColumns });
        }}
      />

      {/* end::Body */}
    </div>
  );
};

export default TableColumns;

export type ColumnType = {
  label: string;
  backend_property: string;
  visible: boolean;
  className?: string;
};
export const defaultColumns: ColumnType[] = [
  {
    label: 'Select',
    backend_property: 'select',
    visible: true,
  },
  {
    label: 'Number',
    backend_property: 'number',
    visible: true,
  },
  {
    label: 'Status',
    backend_property: 'requestStatus',
    visible: true,
  },
  {
    label: 'Effective Date',
    backend_property: 'effectiveDate',
    visible: true,
  },
  {
    label: 'Complete Estimate Date',
    backend_property: 'completeEstimateDate',
    visible: false,
  },
  {
    label: 'Client',
    backend_property: 'company.name',
    visible: true,
  },
  {
    label: 'Requestor',
    backend_property: 'requestorName',
    visible: true,
  },
  {
    label: 'Requestor phone',
    backend_property: 'requestorPhone',
    visible: false,
  },
  {
    label: 'Pickup Address',
    backend_property: 'origin && originAdditionalInfo',
    visible: true,
  },
  {
    label: 'Destination',
    backend_property: 'destination',
    visible: true,
  },
  {
    label: 'Passenger name',
    backend_property: 'passengerName',
    visible: true,
  },
  {
    label: 'Passengers',
    backend_property: 'passengers',
    visible: true,
  },
  {
    label: 'Service',
    backend_property: 'tariffServiceName',
    visible: true,
    className: 'text-center',
  },
  {
    label: 'Vehicle',
    backend_property: 'assignedVehicle.unitNumber',
    visible: true,
  },
  {
    label: 'Vehicle plate',
    backend_property: 'assistanceVehiclePlateNumber',
    visible: false,
  },
  {
    label: 'VIN',
    backend_property: 'assistanceVehicleVin',
    visible: false,
  },
  {
    label: 'Brand',
    backend_property: 'assistanceVehicleBrand',
    visible: false,
  },
  {
    label: 'Model',
    backend_property: 'assistanceVehicleModel',
    visible: false,
  },
  {
    label: 'Department',
    backend_property: 'assistanceVehicleDepartment',
    visible: false,
  },
  {
    label: 'Location',
    backend_property: 'assistanceVehicleLocation',
    visible: false,
  },
  {
    label: 'Replacement class',
    backend_property: 'assistanceVehicleReplacementClass',
    visible: false,
  },
  {
    label: 'Replacement days',
    backend_property: 'assistanceVehicleReplacementDays',
    visible: false,
  },
  {
    label: 'Road assist description',
    backend_property: 'assistanceVehicleRoadAssistDescription',
    visible: false,
  },
  {
    label: 'Replacement plate',
    backend_property: 'replacementVehiclePlateNumber',
    visible: false,
  },
  {
    label: 'Emissions',
    backend_property: 'emissions',
    visible: false,
  },
  {
    label: 'Driver name',
    backend_property: 'assignedDriver.fullName',
    visible: true,
  },
  {
    label: 'Intermediary points',
    backend_property: 'intermediaryPoints > address * poiName || addressDetails', // display the address with poiName or addresDetails ('||') inside paranthesis ('*')
    visible: false,
  },
  {
    label: 'Payment',
    backend_property: 'paymentType',
    visible: true,
  },
  {
    label: 'Payment status',
    backend_property: 'paymentStatus',
    visible: false,
  },
  {
    label: 'Answered by',
    backend_property: 'user.username',
    visible: false,
  },
  {
    label: 'Tariff',
    backend_property: 'tariff',
    visible: false,
  },
  {
    label: 'Currency',
    backend_property: 'currency',
    visible: false,
  },
  {
    label: 'Commission',
    backend_property: 'commission',
    visible: false,
  },
  {
    label: 'Passenger phone',
    backend_property: 'passengerPhone',
    visible: false,
  },
  {
    label: 'Driver notification',
    backend_property: 'driverNotification',
    visible: false,
  },
  {
    label: 'Service type',
    backend_property: 'tariffTypeId',
    visible: false,
  },
  {
    label: 'Transfer type',
    backend_property: 'taxBreakdownTransferType',
    visible: true,
  },
  {
    label: 'Origin agency',
    backend_property: 'taxBreakdownOriginAgency',
    visible: true,
  },
  {
    label: 'Request remark',
    backend_property: 'requestRemark',
    visible: false,
  },
  {
    label: 'Requestor Reference',
    backend_property: 'companyCustomField1Value',
    visible: false,
  },
  {
    label: 'Requestor Division',
    backend_property: 'companyCustomField2Value',
    visible: false,
  },
  {
    label: 'Requestor Department',
    backend_property: 'companyCustomField3Value',
    visible: false,
  },
  {
    label: 'Requestor Service',
    backend_property: 'companyCustomField4Value',
    visible: false,
  },
  {
    label: 'Requestor Cost Center',
    backend_property: 'companyCustomField5Value',
    visible: false,
  },
  {
    label: 'GPS KM',
    backend_property: 'gpsKm',
    visible: false,
  },
  {
    label: 'Driver KM',
    backend_property: '(min0) endOdometer - startOdometer',
    visible: false,
  },
  {
    label: 'Start Time',
    backend_property: 'startTime',
    visible: false,
  },
  {
    label: 'End Time',
    backend_property: 'endTime',
    visible: false,
  },
  {
    label: 'Revenue Center',
    backend_property: 'revenueCenter',
    visible: false,
  },
  {
    label: 'Supplier',
    backend_property: 'supplier.name',
    visible: false,
  },
  {
    label: 'Dispatch comments',
    backend_property: 'dispatchComment',
    visible: false,
  },
  {
    label: 'Invoice number',
    backend_property: 'invoiceNumber',
    visible: false,
  },
  {
    label: 'Actions',
    backend_property: 'actions',
    visible: true,
    className: 'text-center',
  },
  {
    label: 'Confirmation',
    backend_property: 'custom_confirmation',
    visible: true,
  },
  // rent columns
  {
    label: 'Rental Period',
    backend_property: '(dateDiff) effectiveDate, completeEstimateDate',
    visible: false,
  },
  {
    label: 'Dislocation Type',
    backend_property: 'rentPlanData.dislocation_type',
    visible: false,
  },
  {
    label: 'Liability Type',
    backend_property: '(list) rentPlanData.liability_type',
    visible: false,
  },
  {
    label: 'Insurance',
    backend_property: 'rentPlanData.insurance_name',
    visible: false,
  },
  {
    label: 'Vehicle Class',
    backend_property: 'rentPlanData.vehicle_class_name',
    visible: false,
  },
  {
    label: 'Extra Options',
    backend_property: 'rentPlanData.extra_costs > name',
    visible: false,
  },
  {
    label: 'Discount',
    backend_property: 'rentPlanData.discount',
    visible: false,
  },
  {
    label: 'Distance',
    backend_property: 'distance',
    visible: false,
  },
  {
    label: 'Billed Customer',
    backend_property: 'billedCustomer',
    visible: false,
  },
];

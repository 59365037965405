import React from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../helpers'
import ReactPortal from '../portal/portal'

export default function ModalPortal({
  visible,
  setVisible,
  hasExitButton = true,
  dialogClassName = '',
  title = '',
  titleStyle = {},
  children,
  className
}: {
  visible: boolean
  setVisible: Function
  hasExitButton?: boolean
  dialogClassName?: string
  title?: string
  titleStyle?: { [prop: string]: string }
  children: any
  className?: string
}) {
  return (
    <ReactPortal>
      <Modal
        onHide={() => {
          setVisible(false)
        }}
        backdrop='static'
        className={`modal-sticky modal-sticky-lg modal-sticky-bottom-right ${className}`}
        id='kt_inbox_compose'
        role='dialog'
        data-backdrop='false'
        aria-hidden='true'
        tabIndex='-1'
        show={Boolean(visible)}
        animation={true}
        dialogClassName={dialogClassName}
      >
        {hasExitButton || Boolean(title) ? (
          <div style={{ display: 'flex', padding: 16 }}>
            <div id='left' style={{ flex: '1 0px' }} />
            <div
              id='center'
              style={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                flex: '3 0px',
              }}
            >
              {Boolean(title) ? (
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: '22px',
                    ...titleStyle,
                  }}
                >
                  {title}
                </span>
              ) : null}
            </div>
            <div id='right' style={{ display: 'flex', justifyContent: 'flex-end', flex: '1 0px' }}>
              {hasExitButton ? (
                <span
                  onClick={() => {
                    setVisible(null)
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotone/Interface/Close-Square.svg'
                    className='svg-icon-2tx svg-icon-danger clickable'
                  />
                </span>
              ) : null}
            </div>
          </div>
        ) : null}
        <div
          className='pt-4 pb-4'
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {children}
        </div>
      </Modal>
    </ReactPortal>
  )
}

import {useMenuState, ControlledMenu} from '@szhsin/react-menu'
import moment from 'moment'
import {useRef, useMemo} from 'react'
import { PUBLIC_EASYTRACK_URL } from '../../../constants/api.constants'
import { TransportType } from '../add-edit-transport/scripts/transportTypes'

export const getHighlightColorOddRows = (index: number): string => {
  const isOdd = index % 2 === 1
  return isOdd ? '#F2F5F9' : 'white'
}

export function scrollRedLineInsideView() {
  var parent = document.getElementById('unassignedTable')
  var child = document.querySelector('.inside-current-time')

  if (!parent || !child) {
    return null
  }
  // Where is the parent on page
  var parentRect = parent.getBoundingClientRect()
  // What can you see?
  var parentViewableArea = {
    height: parent.clientHeight,
    width: parent.clientWidth,
  }

  // Where is the child
  var childRect = child.getBoundingClientRect()

  // Is the child viewable?
  var isViewable = childRect.left >= parentRect.left && childRect.right <= parentRect.right

  if (isViewable) {
    return null
  }

  // if you can't see the child try to scroll parent;
  const scrollLeft = childRect.left - parentRect.left

  // Set scroll
  parent.scrollLeft = scrollLeft - 130 * 4
}

export const ShiftCornerComponent = ({
  currentShift,
  label,
  shiftTypes,
}: {
  currentShift: any
  label: string
  shiftTypes: any[]
}) => {
  const ref = useRef(null)
  const [cardProps, toggleCard] = useMenuState({transition: true})
  const shiftTypeLabel =
    shiftTypes.find((shiftType: any) => shiftType.name === currentShift.type)?.title || label

  const isFinished = useMemo(() => {
    return currentShift.titleColor.includes('end')
  }, [currentShift])

  const overtime = useMemo(() => {
    var duration = currentShift.extraMinutes
    var sign = duration < 0 ? '-' : ''
    duration = Math.abs(duration)
    var hours = Math.floor(duration / 60)
    var minutes = duration % 60
    return `${sign}${hours}h ${Math.abs(minutes)}m`
  }, [currentShift])

  const fLabel = useMemo(() => {
    if (label === 'Finish') {
      if (currentShift.endDate) {
        return 'Finish'
      } else {
        return 'E. Finish'
      }
    }

    return shiftTypeLabel
  }, [currentShift, label, shiftTypes])

  return (
    <div
      ref={ref}
      onMouseEnter={() => {
        toggleCard(true)
      }}
      onMouseLeave={() => {
        toggleCard(false)
      }}
      onMouseMove={() => {
        toggleCard(true)
      }}
      style={{height: 20}}
    >
      <div
        className='shift-corner'
        style={{
          position: 'absolute',
          minWidth: 80,
          maxWidth: 120,
          textAlign: 'center',
          background: '#48a841',
          color: '#fff',
          top: 0,
          borderRadius: label === 'Start' ? '0px 33px 33px 0px' : '33px 0px 0px 33px',
          ...(label === 'Start' && {left: 0}),
          ...(label === 'Finish' && {right: 0}),
        }}
      >
        <span className='ps-1 pe-3'>{fLabel}</span>
      </div>

      <ControlledMenu
        offsetX={-16}
        portal={true}
        direction={'top'}
        {...cardProps}
        // viewScroll='auto'
        align='start'
        // overflow='auto'
        arrow={true}
        anchorRef={ref}
        onMouseLeave={() => toggleCard(false)}
        onClose={() => toggleCard(false)}
      >
        <div style={{minWidth: 200, maxWidth: 200, padding: 8}}>
          <div>
            <div className='shift-wrapper'>
              <label>Start hour:</label>
              <p>{moment(currentShift.startDateTime).format('HH:mm')}</p>
            </div>

            <div className='shift-wrapper'>
              <label>Schedule ending:</label>
              <p>{moment(currentShift.endDateAccordingToNormAndPauseTime).format('HH:mm')}</p>
            </div>

            <div className='shift-wrapper'>
              <label>End Shift:</label>
              <p>
                {moment(currentShift.endDate || currentShift.endDateWithOvertime).format('HH:mm')}
              </p>
            </div>

            <div className='shift-wrapper'>
              <label>Overtime:</label>
              <p>{overtime}</p>
            </div>

            <button
              type='button'
              style={{
                width: '100%',
                background: '#e8eeff',
              }}
              className='btn btn-sm btn-icon btn-active-light-primary'
              id='kt_activities_close'
              onClick={() => {
                // Open in new tab
                window.open(
                  `${PUBLIC_EASYTRACK_URL}/shift/edit.do?id=${currentShift.id}`,
                  '_blank'
                )
              }}
            >
              Edit shift
            </button>
          </div>
        </div>
      </ControlledMenu>
    </div>
  )
}

export const AutoAssignmentIcon = () => {
  return (
    <a
      href='#'
      style={{width: '100%'}}
      className='btn btn-icon btn-active-color-primary btn-sm my-1'
    >
      <span className='svg-icon svg-icon-2x'>
        <svg
          width='24px'
          height='24px'
          viewBox='0 0 24 24'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g
            id='Stockholm-icons-/-Home-/-Bulb1'
            stroke='none'
            strokeWidth='1'
            fill='none'
            fillRule='evenodd'
          >
            <rect id='bound' x='0' y='0' width='24' height='24'></rect>
            <circle id='Combined-Shape' fill='#000000' opacity='0.3' cx='12' cy='9' r='8'></circle>
            <path
              d='M14.5297296,11 L9.46184488,11 L11.9758349,17.4645458 L14.5297296,11 Z M10.5679953,19.3624463 L6.53815512,9 L17.4702704,9 L13.3744964,19.3674279 L11.9759405,18.814912 L10.5679953,19.3624463 Z'
              id='Path-69'
              fill='#000000'
              fillRule='nonzero'
              opacity='0.3'
            ></path>
            <path
              d='M10,22 L14,22 L14,22 C14,23.1045695 13.1045695,24 12,24 L12,24 C10.8954305,24 10,23.1045695 10,22 Z'
              id='Rectangle-72-Copy-2'
              fill='#000000'
              opacity='0.3'
            ></path>
            <path
              d='M9,20 C8.44771525,20 8,19.5522847 8,19 C8,18.4477153 8.44771525,18 9,18 C8.44771525,18 8,17.5522847 8,17 C8,16.4477153 8.44771525,16 9,16 L15,16 C15.5522847,16 16,16.4477153 16,17 C16,17.5522847 15.5522847,18 15,18 C15.5522847,18 16,18.4477153 16,19 C16,19.5522847 15.5522847,20 15,20 C15.5522847,20 16,20.4477153 16,21 C16,21.5522847 15.5522847,22 15,22 L9,22 C8.44771525,22 8,21.5522847 8,21 C8,20.4477153 8.44771525,20 9,20 Z'
              id='Combined-Shape'
              fill='#000000'
            ></path>
          </g>
        </svg>
      </span>
    </a>
  )
}

export const filterTransportListByKeyword = ({
  array,
  userLocalSearch,
}: {
  array: any[]
  userLocalSearch: string
}): any[] => {
  const keyword = userLocalSearch.toLowerCase()
  if (!array) {
    return []
  }
  return array.filter((transport: TransportType) => transportIncludesKeyword({transport, keyword}))
}

export const transportIncludesKeyword = ({
  transport,
  keyword,
}: {
  transport: TransportType
  keyword: string
}) => {
  if (!keyword) {
    return true
  }
  if (transport.passengerName?.toLowerCase().includes(keyword)) {
    return true
  }
  if (transport.passengerPhone?.toLowerCase().includes(keyword)) {
    return true
  }
  if ((transport.requestorEmail || transport.requesterEmail)?.toLowerCase().includes(keyword)) {
    return true
  }
  if (transport.requestorName?.toLowerCase().includes(keyword)) {
    return true
  }
  if (transport.requestorPhone?.toLowerCase().includes(keyword)) {
    return true
  }
  if (transport.origin?.toLowerCase().includes(keyword)) {
    return true
  }
  if (transport.destination?.toLowerCase().includes(keyword)) {
    return true
  }
  return false
}

/**
 * Get a list of filtered transports
 * @param transports (list of transports to be filtered)
 * @returns a list of fitered transports
 */
export const getFilteredTransports = ({
  transports,
  selectedStatuses,
  selectedTariffServices,
  selectedTariffTypes,
  selectedCompanies,
  keyword,
}: {
  transports: any[]
  selectedStatuses: any[]
  selectedTariffServices: any[]
  selectedTariffTypes: any[]
  selectedCompanies: any[]
  keyword: string
}): any[] => {
  let filteredTransports = transports.filter((transport) => {
    if (!transport.number) {
      // if it's not a transport, but a shifts card
      return false
    }
    let hasStatusSelected =
      !selectedStatuses || selectedStatuses.length === 0
        ? true
        : selectedStatuses.some((status: any) => status?.name === transport.requestStatus)
    let hasTariffServiceSelected =
      !selectedTariffServices || selectedTariffServices.length === 0
        ? true
        : selectedTariffServices.some(
            (tariffService: any) => tariffService?.id === transport.tariffService?.id
          )
    let hasTariffTypeSelected =
      !selectedTariffTypes || selectedTariffTypes.length === 0
        ? true
        : selectedTariffTypes.some((tariffType: any) => tariffType?.id === transport.tariffTypeId)
    let hasCompanySelected =
      !selectedCompanies || selectedCompanies.length === 0
        ? true
        : selectedCompanies.some((company: any) => company?.id === transport.company?.id)
    let hasUserKeyword = transportIncludesKeyword({transport, keyword})
    return (
      hasStatusSelected &&
      hasTariffServiceSelected &&
      hasTariffTypeSelected &&
      hasCompanySelected &&
      hasUserKeyword
    )
  })
  return filteredTransports
}

export const getHoursFromNumber = ({
  hour,
  asTime = true,
}: {
  hour: number
  asTime?: boolean
}): string =>
  ('0' + (asTime ? Math.floor(hour) % 24 : Math.floor(hour))).slice(-2) +
  ':' +
  ('0' + Math.floor(60 * (hour % 1))).slice(-2)

export type Schedule = {
  morningStartDate: Date
  morningEndDate: Date
  eveningStartDate: Date
  eveningEndDate: Date
  nextMorningStartDate: Date
  nextMorningEndDate: Date
}

const getDateFromNumber = ({hour, givenDateString}: {hour: number; givenDateString: string}) => {
  let hours = getHoursFromNumber({hour, asTime: false})
  if (hour === null || hour === undefined) {
    return null
  }
  return new Date(givenDateString + ' ' + hours)
}

export const enum Inclusiveness {
  Exclusive = 'EXCLUSIVE',
  RightInclusive = 'RINCLUSIVE',
  LeftInclusive = 'LINCLUSIVE',
  Inclusive = 'INCLUSIVE',
}

export const isBetween = ({
  left,
  right,
  comparable,
  inclusiveness = Inclusiveness.Inclusive,
}: {
  left: number
  right: number
  comparable: number
  inclusiveness?: Inclusiveness
}) => {
  if ((left !== 0 && !left) || (right !== 0 && !right) || (comparable !== 0 && !comparable)) {
    return false
  }
  switch (inclusiveness) {
    case 'EXCLUSIVE':
      return left < comparable && comparable < right
    case 'RINCLUSIVE':
      return left < comparable && comparable <= right
    case 'LINCLUSIVE':
      return left <= comparable && comparable < right
    case 'INCLUSIVE':
      return left <= comparable && comparable <= right
  }
}

export const getDriverSchedule = ({
  driver,
  chosenDate,
}: {
  driver: any
  chosenDate: Date
}): Schedule => {
  if (!driver || !chosenDate) {
    return null
  }
  const givenDate = moment(chosenDate)

  const weekNumberOfChosenDate = Math.ceil(givenDate.date() / 7)
  const parity = weekNumberOfChosenDate % 2 === 0 ? 'even' : 'odd'
  const dayOfWeek = givenDate.format('dddd')

  const givenDateString = givenDate.format('YYYY-MM-DD')
  // Morning Schedule
  let morningStart = driver[parity + dayOfWeek + 'MorningScheduleStart']
  const morningStartDate = getDateFromNumber({hour: morningStart, givenDateString})
  let morningEnd = driver[parity + dayOfWeek + 'MorningScheduleEnd']
  const morningEndDate = getDateFromNumber({hour: morningEnd, givenDateString})
  // Evening Schedule
  let eveningStart = driver[parity + dayOfWeek + 'EveningScheduleStart']
  const eveningStartDate = getDateFromNumber({hour: eveningStart, givenDateString})
  let eveningEnd = driver[parity + dayOfWeek + 'EveningScheduleEnd']
  const eveningEndDate = getDateFromNumber({hour: eveningEnd, givenDateString})

  const nextDate = givenDate.add(1, 'days')

  const weekNumberOfNextDate = Math.ceil(nextDate.date() / 7)
  const nextDayParity = weekNumberOfNextDate % 2 === 0 ? 'even' : 'odd'
  const nextDayOfWeek = nextDate.format('dddd')

  const nextDateString = nextDate.format('YYYY-MM-DD')
  // Next Morning Schedule
  let nextMorningStart = driver[nextDayParity + nextDayOfWeek + 'MorningScheduleStart']
  const nextMorningStartDate = getDateFromNumber({
    hour: nextMorningStart,
    givenDateString: nextDateString,
  })
  let nextMorningEnd = driver[nextDayParity + nextDayOfWeek + 'MorningScheduleEnd']
  const nextMorningEndDate = getDateFromNumber({
    hour: nextMorningEnd,
    givenDateString: nextDateString,
  })

  return {
    morningStartDate,
    morningEndDate,
    eveningStartDate,
    eveningEndDate,
    nextMorningStartDate,
    nextMorningEndDate,
  }
}

export const WarningIcon = ({width = 18, height = 18}: {width?: number; height?: number}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width.toString()}
      height={height.toString()}
      fill='currentColor'
      className='bi bi-exclamation-circle'
      viewBox='0 0 16 16'
    >
      <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'></path>
      <path d='M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z'></path>
    </svg>
  )
}

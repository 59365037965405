import React from 'react'
import FieldComponent from './FieldComponent'
import {Draggable} from 'react-beautiful-dnd'
import { SortableListProps } from '../../../../context/edit-template-context'

import { useDroppable, } from "@dnd-kit/core";
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { getFieldFormatted } from '../utils'

const SortableItem = ({field, fieldIndex, panelIndex, panelMode}) => {
  return (
    <FieldComponent
      field={field}
      panelMode={panelMode}
      fieldIndex={fieldIndex}
      panelIndex={panelIndex}
      key={field.name}
      id={field.name}
    />
  )
}


const SortableList = ({ items, panelIndex, panelMode, id }: SortableListProps) => {
  const { setNodeRef } = useDroppable({
    id: id,
  });
  return (
    <SortableContext id={id} items={items} strategy={rectSortingStrategy}>
    <ul ref={setNodeRef} className='card-body py-3 sortable-zone autonom-dynamic-card'>
      {items.map((field, index) => {
        const fieldFormatted = getFieldFormatted(field);
        if (!fieldFormatted || fieldFormatted?.type === 'hiddenInput' || fieldFormatted?.type === 'viewModeHelper') {
          return null
        }
        return (
          <SortableItem
            panelMode={panelMode}
            field={fieldFormatted}
            fieldIndex={index}
            panelIndex={panelIndex}
            key={`sor-item-${index}`}
          />
        )
      })}
    </ul>
    </SortableContext>
  )
}

const PanelComponent = ({
  panel,
  setPanels,
  panels,
  index,
}: {
  panel: any
  panels: any
  setPanels: (panels: any) => void
  index: number
}) => {
  const [panelMode, setPanelMode] = React.useState('view')

  const panelIndex = index

  return (
    <Draggable draggableId={panel.name} index={panelIndex}>
      {(providedDraggable) => (
        <div ref={providedDraggable.innerRef} {...providedDraggable.draggableProps}>
          <div
            // @ts-ignore
            className={`card${false ? ' card-options-active' : ''} draggable has-card-options`}
            style={{
              marginTop: 16,
            }}
          >
            {/* begin::Header */}
            <div className='card-header pt-5 border-0'>
              <h3 className='card-title align-items-start flex-column'>
                {/* Panel name */}
                <input
                  className='card-label fw-bolder fs-3 mb-1'
                  type='text'
                  name='card-title'
                  id='card-title'
                  value={panel.label || panel.name}
                  onChange={(ev) => {
                    // Edit panel name
                    const newPanels = panels.map((p) => {
                      if (p.name === panel.name) {
                        p.label = ev.target.value
                      }
                      return p
                    })
                    setPanels(newPanels)
                  }}
                />
              </h3>
              <div className='card-toolbar'>
                <div className='card-settings'>
                  <div
                    onClick={() => {
                      if (panelMode === 'view') {
                        setPanelMode('edit')
                      } else {
                        setPanelMode('view')
                      }
                    }}
                    className='btn btn-icon btn-active-color-primary w-30px'
                  >
                    <i className='fas fa-cog'></i>
                  </div>
                  <div
                    {...providedDraggable.dragHandleProps}
                    className='btn btn-icon btn-active-color-primary card__draggable-handle draggable-handle w-30px'
                  >
                    <i className='fas fa-arrows-alt'></i>
                  </div>
                </div>
              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <SortableList
              panelMode={panelMode}
              panelIndex={panelIndex}
              items={panel.fields.map((field) => {return {...field, id:field.name}})}
              id={panel.name}
            />

            {/* end::Body */}
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default PanelComponent

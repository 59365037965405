import React, { Suspense, useContext, useMemo } from 'react'
import { Redirect, Route as NativeRoute, Switch } from 'react-router-dom'
import { UserContext, UserRole } from '../../context/user.context'
import AddEditPlanWrapper from '../../pages/add-edit-plan/AddEditPlan'
import ConfirmationPageWrapper from '../../pages/confirmation/ConfirmationPage'
import GeofenceListWrapper from '../../pages/geofence-list/GeofenceList'
import TariffGridWrapper from '../../pages/tariff-grid/TariffGridWrapper'
import { canAccess } from '../../utils/permissions.utils'
import { FallbackView } from '../../_metronic/partials'
import AccountSettingsWrapper from '../modules/accounts/components/settings/Settings'
import AddEditSubscriptionWrapper from '../modules/add-edit-subscription/AddEditSubscription'
import AddEditTemplateWrapper from '../modules/add-edit-template/AddEditTemplate'
import AddEditTransportWrapper from '../modules/add-edit-transport/AddEditTransport'
import CarsWrapper from '../modules/cars/CarsWrapper'
import CarClassesWrapper from '../modules/car-classes/CarClassesWrapper'
import PaymentPageWrapper from '../modules/payment/Payment'
import Welcome from '../modules/welcome-back/Welcome'
import SubscriptionsWrapper from '../modules/subscriptions/Subscriptions'
import TemplatesWrapper from '../modules/templates/Templates'
import TransportAssignmentsWrapper from '../modules/transport-assignments/TransportAssignments'
import TransportRequestsWrapper from '../modules/transport-requests/TransportRequests'
import UsersWrapper from '../modules/users/UsersWrapper'
import CompaniesWrapper from '../modules/companies/CompaniesWrapper'
import ViewTransportWrapper from '../modules/view-transport/ViewTransport'
import AddEditCompany from '../modules/add-edit-company/AddEditCompany'
import PackagesPage from '../modules/packages/PackagesPage'
import ServicesPage from '../modules/services/ServicesPage'
import RentPlansWrapper from '../modules/rent-plans/RentPlansWrapper'
import AddEditPackage from '../modules/add-edit-package/AddEditPackage'
import RentCompanyPlans from '../modules/companies/RentPlan'
import RentPricingWrapper from '../modules/rent-pricing/RentPricingWrapper'
import FormularWrapper from '../modules/campaigns/Campaigns'
import ViewCampaign from '../modules/campaigns/ViewCampaign'
import Form from '../modules/campaigns/components/Form.components'
import FinishedCampaign from '../modules/campaigns/components/FinishedCampaign.components'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import AddEditCampaign from '../modules/add-edit-campaign/AddEditCampaign'

const homepage = 'welcome-back'
const defaultFleetPage = '/transport-assignments'
const defaultRoadAssistancePage = '/subscriptions'

const Route = ({
  checkPath,
  defaultPage = defaultFleetPage,
  Layout = MasterLayout,
  isProtected = true,
  ...props
}: any) => {
  const {permissions} = useContext<any>(UserContext)

  if (isProtected && !canAccess(permissions, checkPath)) {
    return <Redirect to={defaultPage} />
  }

  if (Layout) {
    return (
      <Layout>
        <NativeRoute {...props} />
      </Layout>
    )
  }

  return <NativeRoute {...props} />
}

export function PrivateRoutes() {
  const { permissions, user } = useContext<any>(UserContext)
  const userRole = user?.role?.slug || ''

  const defaultPageForUser = useMemo(
    () =>
      userRole === UserRole.Admin || userRole === UserRole.Operator || userRole === UserRole.AdminFleet
        ? defaultFleetPage
        : userRole === UserRole.Company || userRole === UserRole.Agent || userRole === UserRole.AdminSubscriptions
          ? defaultRoadAssistancePage
          : homepage,
    [userRole]
  )

  if (!permissions) {
    // we don't know the permissions yet, so we shouldn't handle routing until then
    return null
  }

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route isProtected={false} path='/account/settings' component={AccountSettingsWrapper} />

        {/* Management */}
        <Route checkPath='/cars' path='/cars' component={CarsWrapper} />
        <Route checkPath='/users' path='/users/:id' component={UsersWrapper} />
        <Route checkPath='/users' path='/users' component={UsersWrapper} />
        <Route checkPath='/branches' path='/branches/:id' component={CompaniesWrapper} />

        <Route checkPath='/companies' path='/companies' component={CompaniesWrapper} />
        <Route checkPath='/add-edit-package' path='/add-edit-package/:companyId/:id' component={AddEditPackage} />
        <Route checkPath='/add-edit-package' path='/add-edit-package/:companyId' component={AddEditPackage} />
        <Route checkPath='/packages' path='/packages/:id' component={PackagesPage} />
        <Route checkPath='/services' path='/services/:id' component={ServicesPage} />
        <Route
          checkPath='/add-edit-branch'
          path='/add-edit-branch/:companyId/:id'
          component={AddEditCompany}
        />
        <Route
          checkPath='/add-edit-branch'
          path='/add-edit-branch/:companyId'
          component={AddEditCompany}
        />
        <Route
          checkPath='/add-edit-company'
          path='/add-edit-company/:id'
          component={AddEditCompany}
        />
        <Route
          checkPath='/add-edit-company'
          path='/add-edit-company'
          component={AddEditCompany}
        />

        {/* Rent Module Pages */}
        <Route
          checkPath='/car-classes'
          path='/car-classes'
          component={CarClassesWrapper}
        />
        <Route
          checkPath='/rent-plans'
          path='/rent-plans/:companyId'
          component={RentPlansWrapper}
        />
        <Route
          checkPath='/add-edit-rent-plan'
          path='/add-edit-rent-plan/:companyId/:id'
          component={RentCompanyPlans}
        />
        <Route
          checkPath='/add-edit-rent-plan'
          path='/add-edit-rent-plan/:companyId'
          component={RentCompanyPlans}
        />
        <Route
          checkPath='/rent-pricing'
          path='/rent-pricing/:companyId/:planId'
          component={RentPricingWrapper}
          defaultPage={homepage}
        />
        <Route
          checkPath='/rent-pricing'
          path='/rent-pricing'
          component={RentPricingWrapper}
          defaultPage={homepage}
        />

        {/* Fleet Pages */}
        <Route
          checkPath='/transport-assignments'
          path='/transport-assignments'
          component={TransportAssignmentsWrapper}
          defaultPage={homepage}
        />
        <Route
          checkPath='/transport-request'
          path='/transport-requests/:templateName'
          component={TransportRequestsWrapper}
        />
        <Route
          checkPath='/add-edit-transport'
          path='/add-edit-transport/:templateName/:id'
          component={AddEditTransportWrapper}
        />
        <Route
          checkPath='/add-edit-transport'
          path='/add-edit-transport/:templateName'
          component={AddEditTransportWrapper}
        />
        <Route
          checkPath='/view-transport'
          path='/view-transport/:templateName/:id'
          component={ViewTransportWrapper}
        />
        <Route
          checkPath='/add-edit-template'
          path='/add-edit-template/:id'
          component={AddEditTemplateWrapper}
        />
        <Route
          checkPath='/add-edit-template'
          path='/add-edit-template'
          component={AddEditTemplateWrapper}
        />
        <Route checkPath='/templates' path='/templates' component={TemplatesWrapper} />
        <Route
          checkPath='/geofence-list'
          path='/geofence-list'
          component={GeofenceListWrapper}
        />
        <Route
          checkPath='/tariff-grid'
          path='/tariff-grid'
          component={TariffGridWrapper}
        />
        <Route checkPath='/payment' path='/payment/:id' component={PaymentPageWrapper} />
        <Route
          checkPath='/add-edit-plan'
          path='/add-edit-plan/:companyId/:id'
          component={AddEditPlanWrapper}
        />
        <Route
          checkPath='/add-edit-plan'
          path='/add-edit-plan'
          component={AddEditPlanWrapper}
        />
        <Route
          checkPath='/confirmation'
          path='/confirmation'
          component={ConfirmationPageWrapper}
        />

        {/* Road Assistance Pages */}
        <Route
          checkPath='/subscriptions'
          path='/subscriptions'
          component={SubscriptionsWrapper}
          defaultPage={homepage}
        />
        <Route
          checkPath='/add-edit-subscription'
          path='/add-edit-subscription/:id'
          component={AddEditSubscriptionWrapper}
          defaultPage={defaultRoadAssistancePage}
        />
        <Route
          checkPath='/add-edit-subscription'
          path='/add-edit-subscription'
          component={AddEditSubscriptionWrapper}
          defaultPage={defaultRoadAssistancePage}
        />

        {/* Campaigns */}
        <Route checkPath='/campaigns' path='/campaigns' component={FormularWrapper} />
        <Route checkPath='/view-campaign' path='/view-campaign/:campaignId' component={ViewCampaign} />
        <Route checkPath='/view-form' path='/view-form/:campaignId/:clientCode/:type?' component={Form} Layout={null} />
        <Route checkPath='/fleet-manager-admin' path='/fleet-manager-admin/:campaignId' component={Form} Layout={null}/>
        <Route checkPath='/closed-form' path='/closed-form/finished-campaign' component={FinishedCampaign} Layout={null} />
        <Route checkPath='/add-campaign' path='/add-campaign' component={AddEditCampaign} />

        {/* General Pages */}
        <Route isProtected={false} path='/welcome-back' component={Welcome} />
        <Redirect from='/auth' to={defaultPageForUser} />
        <Redirect exact from='/' to={defaultPageForUser} />
        <Redirect from='/reset' to='/welcome-back' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import '../autonom.scss'
import Filter from './FilterContainer'
import TransportTable from './Table'
import {TransportsContext} from '../../../context/transports.context'
import {disableMaxWidth, enableMaxWidth, disablePaddingBottom} from '../../../utils/style.utils'
import RefreshProvider from '../../../context/refresh.context'
import { getPeriodicResponseFromLocalStorage } from '../../../utils/localStorage.utils'

const TransportAssignmentsPage: FC = () => {
  const {getTransportsCount, setTransportsCounter, transportsCounter} =
    useContext<any>(TransportsContext)

  const getAndSetTransportsCount = async () => {
    const transportStatusCountMap = await getPeriodicResponseFromLocalStorage({
      easytrackRequest: () => getTransportsCount({ partial: true }),
      requestKey: 'transportsCount',
      responseParam: 'data'
    })
    setTransportsCounter(transportStatusCountMap)
  }

  useEffect(() => {
    getAndSetTransportsCount()

    const interval = setInterval(() => {
      getAndSetTransportsCount()
    }, 60000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 gx-xl-12'>
        <div className='col-md-6 py-3'></div>
        <div className='col-md-6'>
          {transportsCounter ? (
            <div className='d-flex justify-content-center justify-content-xl-end align-items-center py-3'>
              <h5 className='my-0 fw-normal'>
                <span className='text-info fw-bolder'>
                  {transportsCounter['Analyze']?.value || 0}
                </span>{' '}
                transfers in <span className='fw-bolder'>Analyze</span> /{' '}
                <span className='text-info fw-bolder'>
                  {transportsCounter['To be accepted']?.value || 0}
                </span>{' '}
                transfers in <span className='fw-bolder'>To Be Accepted</span>
              </h5>
            </div>
          ) : null}
        </div>

        <Filter />

        <TransportTable />
      </div>
    </>
  )
}

const TransportAssignmentsWrapper: FC = () => {
  useEffect(() => {
    disableMaxWidth()
    disablePaddingBottom()

    return () => {
      enableMaxWidth()
    }
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={[]}>Transport Assignments</PageTitle>
      <RefreshProvider><TransportAssignmentsPage /></RefreshProvider>
    </>
  )
}

export default TransportAssignmentsWrapper

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import '../../app/modules/autonom.scss'
import { disableMaxWidth, enableMaxWidth } from '../../utils/style.utils'
import { PageTitle } from '../../_metronic/layout/core'
import TariffGrid from './modules/TariffGrid'
import { UserContext } from '../../context/user.context'
import InputField from '../../_metronic/layout/components/fields/input/input-field'
import { getAndSetAutoBillingDate, getNextValidDate } from './utils'

const TransportPage: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [autoBillingDate, setAutoBillingDate] = useState<number>(0)
  const { businessProfileId } = useContext<any>(UserContext)

  const displayBillingDate = useMemo(() => autoBillingDate ? getNextValidDate(autoBillingDate) : '', [autoBillingDate])

  useEffect(() => {
    getAndSetAutoBillingDate({ setAutoBillingDate, businessProfileId, setIsLoading })
  }, [])

  return (
    <>
      {/* begin::NewPlanButton */}
      <div className='page__top-buttons' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '5px' }}>
          <InputField
            style={{ opacity: Boolean(isLoading) ? 0.75 : 1 }}
            readOnly
            value={Boolean(isLoading) ? 'Loading...' : displayBillingDate}
            field={{ label: 'Next Billing Date', inputType: 'text', name: 'billingDate' }}
            onChange={() => { }}
          />
        </div>
        <div style={{ alignContent: 'end' }}>
          <Link
            className='btn btn-primary'
            to={`../add-edit-plan/`}
          >
            New Plan
          </Link>
        </div>
      </div>
      {/* end::NewPlanButton */}
      {/* begin::Row */}
      <div className='row gy-5 g-xl-12'>
        {/* begin::Col */}
        <div className='col-xxl-12'>
          <TariffGrid />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
    </>
  )
}

const TariffGridWrapper: FC = () => {
  const intl = useIntl()

  useEffect(() => {
    disableMaxWidth()

    return () => {
      enableMaxWidth()
    }
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={[]}>Tariff Grid</PageTitle>
      <TransportPage />
    </>
  )
}

export default TariffGridWrapper

// @ts-nocheck
import React, {FC, useMemo, useCallback, useEffect} from 'react'
import Select from '../../../_metronic/layout/components/select/Select'
import debounce from 'lodash.debounce'
import {useGeofence} from '../../../context/geofence.context'
import {defaultColor} from '../GeofenceList'
import {GeofenceType} from '../../../types/general.types'
import {isSameGeofence} from '../../../utils/geolocation.utils'

var all_shapes = []
var selectedShape

const DEFAULT_POLYGON_CONFIG = {
  clickable: true,
  draggable: false,
  fillColor: '#009EF7',
  fillOpacity: 0.4,
  strokeColor: '#009EF7',
  strokeOpacity: 1,
  strokeWeight: 1,
}

const zIndexByType = {
  'CUSTOM': 4,
  'AIRPORT': 3,
  'CITY': 2,
  'COUNTY': 1,
  'DEFAULT': 5,
}

let bounds
let coordinates = []

const GeofencesMap = () => {
  const {selectedGeofence, setSelectedGeofence, geofences, setGeofences} = useGeofence()
  const [geofenceLoaded, setGeofenceLoaded] = React.useState<boolean>(false)
  const [options, setOptions] = React.useState<any[]>([])
  const [isSelectLoading, setIsSelectLoading] = React.useState<boolean>(false)

  useEffect(() => {
    if (
      selectedGeofence &&
      all_shapes.length > 0 &&
      selectedGeofence !== selectedShape?.extraData
    ) {
      const foundSelection = all_shapes.find((shape: any) => isSameGeofence({geofenceA: shape.extraData, geofenceB: selectedGeofence}))
      bounds = new window.google.maps.LatLngBounds()
      foundSelection.extraData.polygon.forEach((coords: any) => {
        bounds.extend({lat: parseFloat(coords.x), lng: parseFloat(coords.y)})
      })
      window.customMap.fitBounds(bounds)
      setSelection(foundSelection)
    }
  }, [selectedGeofence?.id, selectedGeofence?.localUuId])

  bounds = useMemo(
    () => (Boolean(window.google) ? new window.google.maps.LatLngBounds() : null),
    [Boolean(window.google)]
  )

  useEffect(() => {
    all_shapes = all_shapes.map((e) => {
      const futureShapeFromUpdatedGeofence = geofences.find((x) =>
        isSameGeofence({geofenceA: x, geofenceB: e.extraData})
      )
      if(!futureShapeFromUpdatedGeofence) {
        e.setMap(null)
        return null;
      }

      // Return if hidden
      if ((futureShapeFromUpdatedGeofence.hidden && !e.mapCopy)) {
        e.mapCopy = e.getMap();
        e.setMap(null)
        return e
      }

      if (!e.map && e.mapCopy && !futureShapeFromUpdatedGeofence.hidden) {
        e.setMap(e.mapCopy)
        e.mapCopy = null
        if (
          isSameGeofence({geofenceA: selectedGeofence, geofenceB: futureShapeFromUpdatedGeofence})
        ) {
          setSelection(e)
        }
      } else {
        // Update shape colors;
        e.setOptions({
          fillColor: futureShapeFromUpdatedGeofence.color || '#009EF7',
          strokeColor: futureShapeFromUpdatedGeofence.color || '#009EF7',
        })

        e.extraData = futureShapeFromUpdatedGeofence
      }
      e.set('zIndex', zIndexByType[futureShapeFromUpdatedGeofence.type] || zIndexByType.DEFAULT)
      return e
    }).filter((e) => Boolean(e)) // remove the deleted geofences
  }, [geofences])

  const syncGeofencesWithShape = () => {
    const newGeofences = all_shapes.map((shape) => {
      return shape.extraData
    })
    setGeofences(newGeofences)
  }

  /**
   * Clear selection
   */
  const clearSelection = () => {
    if (selectedShape) {
      selectedShape.setEditable(false)
      selectedShape = null
      setSelectedGeofence(false)
    }
  }

  useEffect(() => {
    if (selectedGeofence === null) {
      clearSelection()
    }
  }, [selectedGeofence])

  /**
   * Select shape
   */
  function setSelection(shape) {
    if (!Boolean(shape)) return
    clearSelection()
    selectedShape = shape
    setSelectedGeofence(shape.extraData)

    shape.setEditable(true)
    // window.google.maps.event.addListener(selectedShape.getPath(), 'insert_at', getPolygonCoords(shape));
    // window.google.maps.event.addListener(selectedShape.getPath(), 'set_at', getPolygonCoords(shape));
  }

  /**
   * Polygon updated
   */
  const polygonUpdated = (shape) => {
    const coordinatesRaw = getPolygonCoords(shape)
    all_shapes.map((geofence) => {
      if (
        (geofence.extraData?.id && geofence.extraData?.id === shape.extraData?.id) ||
        (geofence.extraData?.localUuId &&
          geofence.extraData.localUuId === shape.extraData.localUuId)
      ) {
        geofence.extraData.polygon = coordinatesRaw.map((e) => {
          return {
            x: e.split(',')[0],
            y: e.split(',')[1],
          }
        })
      }
      return geofence
    })

    syncGeofencesWithShape()
  }

  /**
   * Get polygon coordinates
   */
  const getPolygonCoords = (newShape) => {
    var len = newShape.getPath().getLength()

    var polygonCoords = []
    for (var i = 0; i < len; i++) {
      polygonCoords.push(newShape.getPath().getAt(i).toUrlValue(6))
    }

    return polygonCoords
  }

  var runMaps = function () {
    window.customMap = new window.google.maps.Map(document.getElementById('map'), {
      center: {
        lat: 44.4268,
        lng: 26.1025,
      },
      zoom: 12,
    })

    var drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
      },
      markerOptions: {
        icon: 'images/beachflag.png',
      },
      polygonOptions: {
        editable: true,
        ...DEFAULT_POLYGON_CONFIG,
      },
    })

    drawingManager.setMap(window.customMap)

    window.google.maps.event.addListener(drawingManager, 'polygoncomplete', function (event) {
      event.getPath().getLength()
      window.google.maps.event.addListener(event.getPath(), 'insert_at', function () {
        const shape = event
        if (event.overlay) {
          shape = event.overlay
        }
        polygonUpdated(shape) // shape object
      })
      window.google.maps.event.addListener(event.getPath(), 'set_at', function () {
        const shape = event
        if (event.overlay) {
          shape = event.overlay
        }
        polygonUpdated(shape) // shape object
      })

      const shape = event
      if (event.overlay) {
        shape = event.overlay
      }
      polygonUpdated(shape)
    })

    window.google.maps.event.addListener(drawingManager, 'overlaycomplete', function (event) {
      // Push in the array of shapes
      all_shapes.push(event.overlay)

      // Turn off drawing mode
      drawingManager.setDrawingMode(null)
      // Create the new shape with extra info
      var newShape = event.overlay
      newShape.type = event.type
      newShape.color = defaultColor
      newShape.extraData = {
        name: 'New Geofence',
        localUuId: uuidv4(),
      }

      // Add event listeners for the new shape
      window.google.maps.event.addListener(newShape, 'click', function () {
        setSelection(newShape)
      })

      // Update geofence state
      polygonUpdated(newShape)
      clearSelection()
      setSelection(newShape)
    })
  }

  const loadGeofences = async () => {
    geofences.map((geofence: GeofenceType) => {
      // Construct the polygon.
      const formattedCoords = geofence.polygon.map((coord) => {
        return {
          lat: parseFloat(coord.x),
          lng: parseFloat(coord.y),
        }
      })

      const geofenceShape = new google.maps.Polygon({
        paths: formattedCoords,
        ...DEFAULT_POLYGON_CONFIG,
        fillColor: geofence.color || '#009EF7',
        strokeColor: geofence.color || '#009EF7',
      })

      all_shapes.push(geofenceShape)

      // Get ready geofence data without the polygon
      const cloneGeofence = {...geofence}

      geofenceShape.extraData = cloneGeofence

      window.google.maps.event.addListener(geofenceShape, 'click', function (event) {
        setSelection(geofenceShape)
      })

      window.google.maps.event.addListener(geofenceShape.getPath(), 'set_at', function (event) {
        polygonUpdated(geofenceShape)
      })

      window.google.maps.event.addListener(geofenceShape.getPath(), 'insert_at', function (event) {
        polygonUpdated(geofenceShape)
      })

      geofenceShape.set('zIndex', zIndexByType[cloneGeofence.type] || zIndexByType.DEFAULT)
      
      geofenceShape.setMap(window.customMap)
    })
  }

  useEffect(() => {
    runMaps()
  }, [])

  useEffect(() => {
    if (!geofenceLoaded) {
      if (geofences.length > 0) {
        setGeofenceLoaded(true)
      }

      loadGeofences()
    }
  }, [geofences])

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      clearSelection()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [])

  // search functions
  const renderCoordinate = (paths) => {
    coordinates = []
    let position = 0
    bounds = new window.google.maps.LatLngBounds()
    paths.map((location) => {
      if (position % 10 === 0) {
        coordinates.push({lat: parseFloat(location[1]), lng: parseFloat(location[0])})
        bounds.extend({lat: parseFloat(location[1]), lng: parseFloat(location[0])})
      }
      position++
      return true
    })
  }

  const handleSelect = (option: any) => {
    if (option.geojson.type === 'MultiPolygon') {
      renderCoordinate(option.geojson.coordinates[0][0])
    } else if (option.geojson.type === 'Polygon') {
      renderCoordinate(option.geojson.coordinates[0])
    } else {
      alert('option.geojson.type: MultiPolygon & Polygon')
    }

    if (coordinates.length > 1) {
      const sub_area = new window.google.maps.Polygon({
        paths: coordinates,
        clickable: true,
        ...DEFAULT_POLYGON_CONFIG,
      })

      const coordinatesRaw = getPolygonCoords(sub_area)
      sub_area.extraData = {
        name: option.display_name || 'New Geofence',
        color: defaultColor,
        localUuId: uuidv4(),
        polygon: coordinatesRaw.map((e) => {
          return {
            x: e.split(',')[0],
            y: e.split(',')[1],
          }
        }),
      }

      all_shapes.push(sub_area)
      setGeofences((old) => [...old, sub_area.extraData])

      window.google.maps.event.addListener(sub_area, 'click', function (event) {
        setSelection(sub_area)
      })

      window.google.maps.event.addListener(sub_area.getPath(), 'set_at', function (event) {
        polygonUpdated(sub_area)
      })

      window.google.maps.event.addListener(sub_area.getPath(), 'insert_at', function (event) {
        polygonUpdated(sub_area)
      })

      sub_area.setMap(window.customMap)
      window.customMap.setOptions({maxZoom: 15})
      window.customMap.fitBounds(bounds)
      setSelection(sub_area)

      coordinates = []
    }
  }

  const handleSearch = (query: string) => {
    fetch(`https://nominatim.openstreetmap.org/search.php?q=${query}&polygon_geojson=1&format=json`)
      .then((resp) => resp.json())
      .then((data) => {
        let filterGeoJsonType = data.filter(function (data) {
          return data.geojson.type === 'MultiPolygon' || data.geojson.type === 'Polygon'
        })
        setIsSelectLoading(false)
        setOptions(filterGeoJsonType)
      })
  }

  const debouncedChangeHandler = useMemo(() => debounce(handleSearch, 300), [])

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [])

  return (
    <div
      style={{
        flex: 1,
        minWidth: '500px',
        backgroundColor: 'white',
      }}
    >
      <Select
        className='p-4 mw-400px'
        onInputChange={(input: string) => {
          if (input === '') {
            setIsSelectLoading(false)
            setOptions([])
            return
          }
          if (!isSelectLoading) setIsSelectLoading(true)
          debouncedChangeHandler(input)
        }}
        placeholder='Caută o zonă'
        options={options}
        onChange={(optionsInstance: any) => {
          handleSelect(optionsInstance)
        }}
        isLoading={isSelectLoading}
        value={''}
        getOptionLabel={(option) => option.display_name}
      />
      <div
        // className='maps'
        className='gy-5 gx-xl-8 maps'
        id='map'
        style={{
          flex: 1,
          backgroundColor: 'white',
          width: '100%',
          height: '500px',
        }}
      ></div>
    </div>
  )
}

export default GeofencesMap

// Random uuid
export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

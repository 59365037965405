import {
  TemplateColumnType,
  TemplateFieldType,
  TemplatePanelType,
  TemplateType,
} from '../../../context/edit-template-context'
import { defaultTemplate } from '../../../data/template.default'
import { defaultColumns } from './components/TableColumns'
import { fields } from '../add-edit-transport/RequestFields'
import { createDictFromObjectArray } from '../../../utils/state.utils'

export const getFlattenFielsFromTemplate = (template: TemplateType): TemplateFieldType[] => {
  const templatePanels = template?.panels || []
  return getFlattenFieldsFromPanels(templatePanels)
}

export const getFlattenFieldsFromPanels = (templatePanels: TemplatePanelType[]): TemplateFieldType[] => {
  const flattenedFields = []
  templatePanels.forEach((panel) => {
    const panelFields = panel?.fields || []
    panelFields.forEach((field) => {
      flattenedFields.push(field)
    })
  })
  return flattenedFields
}

/**
 * This function is based around the idea of finding differences between configurations within the given 
 * template and the default configuration and completing the given template with the missing information
 * @param template - the template that we want to update
 * @returns Template - the updated template object
 */
export const getUpdatedTemplateWithDefault = (template: TemplateType): TemplateType => {
  /// CHECK IF THERE ARE COLUMNS THAT ARE NOT PRESENT IN THE DEFAULT CONFIGURATION

  const templateColumns: TemplateColumnType[] = template.columns || []
  const templateColumnsSet = new Set<string>(templateColumns.map(column => column?.backend_property || ''))

  const defaultColumnsSet = new Set<string>(defaultColumns.map(column => column?.backend_property || ''))
  
  const missingDefaultColumns = defaultColumns
    .filter(column => !templateColumnsSet.has(column?.backend_property || ''))
    .map(column => ({ ...column, visible: false }))

  const alreadyPresentColumns = templateColumns.filter(column => defaultColumnsSet.has(column?.backend_property || ''))
  const futureColumns: TemplateColumnType[] = [...alreadyPresentColumns, ...missingDefaultColumns]

  // remove panels that are not present anymore and add empty missing panels
  const remainingPanels = template.panels.filter((panel) => defaultTemplate.panels.find(defaultPanel => defaultPanel.name === panel.name))
  const missingPanels = defaultTemplate.panels.reduce((missingPanelsList, defaultPanel) => {
    const foundPanel = template.panels.find(panel => defaultPanel?.name === panel?.name)
    if (!foundPanel) {
      // the fields will be added below, in order to not have duplicates
      const newPanel = { ...defaultPanel, fields: [] }
      missingPanelsList.push(newPanel)
    }
    return missingPanelsList
  }, [])

  const newPanelConfig = [...remainingPanels, ...missingPanels]
  // the template determines the order of the panels and fields
  const templateFlatFields = getFlattenFieldsFromPanels(newPanelConfig)
  const templateFieldsDict = createDictFromObjectArray(templateFlatFields, 'name')
  const templatePanelsDict = createDictFromObjectArray(newPanelConfig, 'name')
  // the default template determines the validity of the existence of those panels and fields (if it isn't in default template, then it doesn't exist anymore)
  const defaultFlatFields = getFlattenFielsFromTemplate(defaultTemplate)
  const defaultFieldsDict = createDictFromObjectArray(defaultFlatFields, 'name')
  const defaultPanelsDict = createDictFromObjectArray(defaultTemplate?.panels || [], 'name')

  const futurePanels: TemplatePanelType[] = []

  newPanelConfig.forEach((panel) => {
    const futureFields = []
    const panelName = panel?.name

    const templatePanelFields: TemplateFieldType[] = templatePanelsDict[panelName]?.fields || []
    const defaultPanelFields: TemplateFieldType[] = defaultPanelsDict[panelName]?.fields || []
    templatePanelFields.forEach(field => {
      const fieldName = field?.name
      // if we have the field in the default config, then the field is valid and should be added
      if (defaultFieldsDict.hasOwnProperty(fieldName)) {
        futureFields.push(field)
      }
    })
    defaultPanelFields.forEach(field => {
      const fieldName = field?.name
      // if we don't have the field inside our initial config, then the field is new and should be added
      if (!templateFieldsDict.hasOwnProperty(fieldName)) {
        futureFields.push(field)
      }
    })
    const futurePanel = { ...panel, fields: futureFields }
    futurePanels.push(futurePanel)
  })

  let futureTemplate: TemplateType = { ...template }
  futureTemplate.panels = futurePanels
  futureTemplate.columns = futureColumns
  return futureTemplate
}

/**
 * This function is based around the idea of finding differences between 
 * configurations within the given template and the default configuration
 * @param template - the template that we want to compare against the default configuration
 * @returns Boolean
 */
export const isTemplateUpToDate = (template: TemplateType): Boolean => {
  //// COMPARE COLUMNS

  /// CHECK IF THERE ARE COLUMNS THAT ARE NOT PRESENT IN THE DEFAULT CONFIGURATION
  let templateColumns: TemplateColumnType[] = template.columns || []
  if (defaultColumns.length !== templateColumns.length) {
    return false
  }

  // CHECK IF THE TEMPLATE HAS MISSING COLUMNS FROM THE DEFAULT CONFIGURATION
  const templateColumnsSet = new Set<string>(templateColumns.map(column => column?.backend_property || ''))
  const hasMissingDefaultColumns = defaultColumns.some(column => !templateColumnsSet.has(column?.backend_property || ''))
  if (hasMissingDefaultColumns) {
    return false
  }

  //// COMPARE PANELS
  // default panels
  const defaultPanelNames = (defaultTemplate?.panels || []).map(panel => panel?.name || '')

  // current template panels
  const templatePanelNames = (template?.panels || []).map(panel => panel?.name || '')
  /// CHECK IF THERE ARE PANELS THAT ARE NOT PRESENT IN THE DEFAULT CONFIGURATION
  if (defaultPanelNames.length !== templatePanelNames.length) {
    return false
  }

  // If it's the same size, and the template has all the panels from the default, then it's enough
  const templatePanels: Set<string> = new Set(templatePanelNames)
  const hasMissingPanelFromDefault = defaultPanelNames.some(panelName => !templatePanels.has(panelName))
  if (hasMissingPanelFromDefault) {
    return false
  }

  // check if all the fields are present
  const defaultFlatFields = getFlattenFielsFromTemplate(defaultTemplate)

  const templateFlatFields = getFlattenFielsFromTemplate(template)
  /// CHECK IF THE TEMPLATE HAS MISSING FIELDS FROM THE DEFAULT CONFIGURATION
  if (defaultFlatFields.length !== templateFlatFields.length) {
    return false
  }

  const templateFields = new Set<string>(templateFlatFields.map(field => field?.name || ''))
  const hasMissingFieldsFromDefault = defaultFlatFields.some(field => !templateFields.has(field?.name))
  if (hasMissingFieldsFromDefault) {
    return false
  }

  return true
}

export const getFields = (fields: any) => {
  const result = {}

  fields.forEach((field) => {
    result[field.name] = {
      ...field,
    }
  })

  return result
}

export const requestFields = getFields(fields)

export const getFieldFormatted = (field) => {
  const fieldFormatted = requestFields[field.name]
  if (!fieldFormatted || fieldFormatted?.type === 'hiddenInput' || fieldFormatted?.type === 'viewModeHelper') {
    return null
  }
  fieldFormatted.hidden = field.hidden

  if (field.label === '') {
    fieldFormatted.label = ''
  } else if (!field.label) {
    fieldFormatted.label = fieldFormatted.label || field.name
  } else {
    fieldFormatted.label = field.label
  }
  return fieldFormatted
}

// @ts-nocheck
import {CAR_BRANDS} from '../data/cars.data'

export const getLogoFromBrand = (brand: string): string => {
  if(!brand) {
    return ''
  }
  const logo = CAR_BRANDS.filter((e) => {
    if (e.name.some((e) => e.toLowerCase() === brand.toLowerCase())) {
      return e.logo
    }
  })

  if (logo && logo.length > -1 && logo[0]) {
    return logo[0].logo
  }
  return ''
}

const getNumbersFromCarNumber = (carNumber: string): string => {

  const NUMERIC_REGEXP = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g
  const futrureCarNumber = carNumber.match(NUMERIC_REGEXP!)

  if(futrureCarNumber) {
    return futrureCarNumber.join('')
  } else {
    return carNumber
  }

}
const getLettersFromCarNumber = (carNumber: string): string => {
  return carNumber.slice(-3)
}

const getLocationFromCarNumber = (carNumber: string): string => {
  return carNumber.split(/\d/)[0]
}

export const formatCarNumber = (carNumber: string): string => {
  var numbers = getNumbersFromCarNumber(carNumber)
  var location = getLocationFromCarNumber(carNumber)
  var letters = getLettersFromCarNumber(carNumber)
  // Add space at last 3

  return `${location} ${numbers} ${letters}`
}

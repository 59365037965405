import moment from 'moment'
import { DateObject } from 'react-multi-date-picker'
import DurationInput from '../../app/modules/add-edit-transport/components/DurationInput'
import PassengersComponent from '../../app/modules/add-edit-transport/components/PassengersListModal'
import StopsList from '../../app/modules/add-edit-transport/components/StopsList'
import GreeterSignList from '../../app/modules/view-transport/modules/GreeterSignList'
import { isNotValid } from '../../utils/state.utils'
import { statusColorStyle } from '../../utils/style.utils'
import Checkbox from '../../_metronic/layout/components/fields/checkbox/checkbox'
import CurrencyInput from '../../_metronic/layout/components/fields/currencyInput/CurrencyInput'
import DateInput from '../../_metronic/layout/components/fields/date-input/date-input-field'
import InputField from '../../_metronic/layout/components/fields/input/input-field'
import IntegerInput from '../../_metronic/layout/components/fields/integerInput/IntegerInput'
import { SelectObject } from '../../_metronic/layout/components/select/Select'
import TextareaField from '../../_metronic/layout/components/fields/textarea/textarea'
import { CustomTimePicker } from '../../_metronic/layout/components/fields/timepicker/time-picker'

const generateLabel = (fieldLabel: string, fieldRequired: boolean) => {
  return (
    <>
      {fieldLabel}:
      {fieldRequired && (
        <span className='ps-2' style={{ color: 'red' }}>
          *
        </span>
      )}
    </>
  )
}

const updateField = (value: any, fieldName: string, setObject: Function) => {
  setObject((oldObject: any) => {
    const editedObject = { ...oldObject }
    editedObject[fieldName] = value
    return editedObject
  })
}

export const getStyleAddOn = ({ isLeftSide = false }: { isLeftSide?: boolean }) => {
  return {
    color: '#181C32',
    backgroundColor: '#F5F8FA',
    borderRadius: isLeftSide ? '10px 0px 0px 10px' : '0px 10px 10px 0px',
    border: '1px solid #E4E6EF',
    paddingLeft: '0.35rem',
    paddingRight: '0.35rem',
    fontWeight: '600',
  }
}

export const AddOn = ({ isLeftSide = false, children }: { isLeftSide?: boolean; children: any }) => {
  return (
    <span
      className='py-2'
      style={{
        ...getStyleAddOn({ isLeftSide }),
        ...(isLeftSide ? { borderRight: 0 } : { borderLeft: 0 }),
      }}
    >
      {children}
    </span>
  )
}

export const InputComponentGroup: React.FC<{ addOns: any; children: any }> = ({ addOns, children }) => (
  <div style={{ display: 'flex', flex: 1 }}>
    {Boolean(addOns?.prepend) ? <AddOn isLeftSide={true}>{addOns.prepend}</AddOn> : null}
    {children}
    {Boolean(addOns?.append) ? <AddOn>{addOns.append}</AddOn> : null}
  </div>
)

export const generateField = ({
  field,
  fieldName,
  fieldProperty,
  setObject,
  hideLabel,
}: {
  field: any
  fieldName: string
  fieldProperty: any
  setObject: Function
  hideLabel?: boolean
}): any => {
  const fieldLabel = generateLabel(field?.label, field?.required)
  let fieldValue = isNotValid(fieldProperty?.value) ? fieldProperty : fieldProperty.value
  const updateObjectField = (value: any, fieldName: string) =>
    updateField(value, fieldName, setObject)
  if (Boolean(fieldProperty?.inputAdditionalValue)) {
    fieldValue = fieldProperty.inputAdditionalValue;
  }
  switch (field?.type) {
    case 'input':
      return (
        <InputField
          key={field?.name}
          value={isNotValid(fieldValue) ? '' : fieldValue + ''}
          field={field}
          hideLabel={hideLabel}
          readOnly={field?.readOnly}
          onChange={(ev) => {
            let value = ev.target.value
            // If we want to handle the event, we use this function
            if (Boolean(fieldProperty?.handleOnChange)) {
              fieldProperty.handleOnChange(ev)
              return;
            }
            if (Boolean(fieldProperty?.effectOverValueOnChange)) {
              value = fieldProperty.effectOverValueOnChange(value)
            }
            Boolean(fieldProperty?.onChange)
              ? fieldProperty.onChange(value)
              : updateObjectField(value, fieldName)
          }}
          onBlurEffect={fieldProperty?.onBlur}
        />
      )
    case 'durationInput':
      return (
        <DurationInput
          key={field?.name}
          value={fieldValue || '0'}
          field={field}
          onChange={(value) => {
            Boolean(fieldProperty?.onChange)
              ? fieldProperty.onChange(value)
              : updateObjectField(value, fieldName)
          }}
        />
      )
    case 'stopsList':
      return (
        <StopsList key={field?.name} title={field?.label} stopsList={fieldProperty} field={field} />
      )
    case 'passengersList':
      return (
        <PassengersComponent key={field?.name} passengerNames={fieldProperty?.value} onChange={fieldProperty?.onChange} field={field} />
      )
    case 'greeterSignList':
      return (
        <GreeterSignList
          key={field?.name}
          title={field?.label}
          greeterSignActivations={fieldProperty}
          field={field}
        />
      )
    case 'inputCompleteFields':
      const value = fieldProperty?.options?.find((e: any) => e.id === fieldValue?.id || '') || null
      return (
        <>
          {!Boolean(hideLabel) ? (
            <label
              key={`label${field?.name}`}
              htmlFor={field?.name}
              className={'form-label ' + (field?.labelType === 'danger' ? 'text-danger' : '')}
            >
              {fieldLabel}
            </label>
          ) : null}

          <SelectObject
            key={field?.name}
            onChange={(optionsInstance: any) => {
              // change the value of the current field
              let newValue = fieldProperty.getOptionValue(optionsInstance)
              updateObjectField(newValue, fieldName)

              // change the values of the secondary fields
              let autocompleteFields = field?.autocomplete
              Object.entries(autocompleteFields).forEach((field: any) => {
                let optionValue = optionsInstance[field[1]]
                let fieldName = field[0]
                updateObjectField(optionValue, fieldName)
              })
            }}
            isDisabled={field?.disabled}
            value={value}
            options={fieldProperty.options}
            getOptionValue={fieldProperty.getOptionValue}
            getOptionLabel={fieldProperty.getOptionLabel}
          />
        </>
      )
    case 'date':
      return (
        <DateInput
          id={field?.name}
          key={field?.name}
          field={field}
          required={field?.required}
          readOnly={Boolean(field?.readOnly)}
          disabled={Boolean(field?.disabled)}
          label={field?.label}
          dateFormat={field?.dateFormat}
          noDatePlugins={field?.noDatePlugins}
          showOtherDays={Boolean(field?.showOtherDays)}
          minDate={field?.minDate || 0}
          value={fieldValue ? moment(fieldValue).format('YYYY-MM-DD HH:mm:00.000') : null}
          onChange={(date: DateObject) => {
            // Create YYYY-MM-DD HH:mm:00.000[Z] or as dateFormat
            const newValue = date.format(
              `${field?.dateFormat ? field?.dateFormat : 'YYYY-MM-DD HH:mm:00.000'}`
            )

            Boolean(fieldProperty?.onChange)
              ? fieldProperty.onChange(date)
              : updateObjectField(newValue, fieldName)
          }}
        />
      )
    case 'select':
      const selectOptions = fieldProperty.sorted
        ? fieldProperty.options || []
        : (fieldProperty.options || [])?.sort((fst: any, snd: any) => {
          let fstLabel = fieldProperty.getOptionLabel(fst)
          let sndLabel = fieldProperty.getOptionLabel(snd)
          return fstLabel.toLowerCase().localeCompare(sndLabel.toLowerCase())
        })
      const statusStyle = field?.name === 'requestStatusString' ? statusColorStyle : ''
      return (
        <>
          {!Boolean(hideLabel) ? (
            <label key={`label${field?.name}`} htmlFor={field?.name} className='form-label'>
              {fieldLabel}
            </label>
          ) : null}

          <SelectObject
            styles={{
              menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
              ...statusStyle,
              ...(field.styles || {}),
            }}
            key={field?.name}
            name={field?.name}
            className='col-12'
            id={field?.name}
            required={field?.required}
            onChange={(e: any) => {
              if (!Boolean(e) && Boolean(field.isClearable)) {
                return fieldProperty.onChange(null)
              }
              fieldProperty.onChange(e)
            }}
            simpleValue={fieldProperty.simpleValue}
            value={fieldProperty.value}
            onLoad={fieldProperty.onLoad}
            options={selectOptions}
            menuPortalTarget={document.body}
            filterOption={fieldProperty.filterOption}
            isMulti={Boolean(field.isMulti)}
            isClearable={Boolean(field.isClearable)}
            isDisabled={field?.disabled}
            getOptionValue={fieldProperty.getOptionValue}
            getOptionLabel={fieldProperty.getOptionLabel}
          />
        </>
      )
    case 'textarea':
      return (
        <TextareaField
          field={field}
          value={fieldValue || ''}
          hideLabel={Boolean(hideLabel)}
          maxLength={fieldProperty?.maxLength}
          additionalInfo={fieldProperty?.additionalInfo}
          onChange={(e) => {
            let value = e.target.value
            updateObjectField(value, fieldName)
          }}
        />
      )
    case 'checkbox':
      return (
        <Checkbox
          key={field?.name}
          field={field}
          value={Boolean(fieldValue)}
          isValueProp={field.isCheckboxValueProp}
          onChange={(e) => {
            let value = e.target.checked
            if (Boolean(fieldProperty?.effectOverValueOnChange)) {
              value = fieldProperty.effectOverValueOnChange(value)
            }
            Boolean(fieldProperty?.onChange)
              ? fieldProperty.onChange(value)
              : updateObjectField(value, fieldName)
          }}
        />
      )
    case 'header':
      return (
        <>
          <h5 key={field?.name}>{field?.text}</h5>
        </>
      )
    case 'paragraph':
      return (
        <>
          <p key={field?.name} className={`text-${field?.paragraphType}`}>
            <strong>
              {(() => {
                const value = fieldValue
                if (value) {
                  if (!Array.isArray(value)) return value
                  else
                    return value.map((paragraph) => (
                      <>
                        {paragraph}
                        <br />
                      </>
                    ))
                }
                return ''
              })()}
            </strong>
          </p>
        </>
      )
    case 'currencyInput':
      return (
        <CurrencyInput
          key={field?.name}
          field={field}
          value={fieldValue}
          hideLabel={hideLabel}
          onChange={(e) => {
            let value = e.target.value
            if (Boolean(fieldProperty?.effectOverValueOnChange)) {
              value = fieldProperty.effectOverValueOnChange(value)
            }
            updateObjectField(value, fieldName)
          }}
        />
      )
    case 'integerInput':
      return (
        <IntegerInput
          key={field?.name}
          field={field}
          value={fieldValue}
          hideLabel={hideLabel}
          onChange={(e) => {
            let value = e.target.value
            updateObjectField(value, fieldName)
          }}
        />
      )
    case 'alert':
      return (
        <>
          {!Boolean(hideLabel) ? (
            <label key={`label${field?.name}`} htmlFor={field?.name} className='form-label'>
              {fieldLabel}
            </label>
          ) : null}
          <div
            key={field?.name}
            className={`mb-0 alert ${fieldValue ? `alert-${field?.alertType || 'primary'}` : ''}`}
            style={{ backgroundColor: fieldValue ? '' : '#F3F6F4', minHeight: '47.5px' }}
            role='alert'
          >
            {fieldValue}
          </div>
        </>
      )
    case 'hiddenInput':
    case 'viewModeHelper':
      return (
        <input type='hidden' value={fieldValue} onChange={() => { }} required={field?.required} />
      )
    case 'timePicker':
      return <CustomTimePicker field={field} value={fieldValue} disableHours={fieldProperty.disabledHours} onChange={(ev) => {
        let value = ev

        if (Boolean(fieldProperty?.handleOnChange)) {
          fieldProperty.handleOnChange(ev)
          return;
        }
        if (Boolean(fieldProperty?.effectOverValueOnChange)) {
          value = fieldProperty.effectOverValueOnChange(value)
        }
        Boolean(fieldProperty?.onChange)
          ? fieldProperty.onChange(value)
          : updateObjectField(value, fieldName)
      }} />
    default:
      return <></>
  }
}

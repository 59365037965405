import React, {useContext, useEffect, useState} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {UserContext} from '../../../../context/user.context'
  import { toast } from 'react-toastify';
const initialValues = {
  email: 'admin@vms.ro',
}

export function ForgotPassword() {
  const {forgotPassword, resetPassword} = useContext<any>(UserContext)
  const [loading, setLoading] = useState(false)
  const [resetToken, setResetToken] = useState<string>('')
  const [mode, setMode] = useState<string>('forgotPassword')
  const [userEmail, setUserEmail] = useState<string>(initialValues.email)

  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const _resetPassword = async () => {
    setLoading(true)
    await resetPassword({token: resetToken, password})
    setLoading(false)
  }

  const _forgotPassword = async () => {
    setLoading(true)
    const res = await forgotPassword(userEmail)

    if (res.error) {
      toast.error(res.message)
    } else {
      setMode('emailSent')
    }

    setLoading(false)
  }

  useEffect(() => {
    if (window.location.href.includes('reset')) {
      setResetToken(window.location.search.split("=")[1])
      setMode('resetPassword')
    }
  }, [])

  const renderForgowPasswordMode = () => {
    return (
      <>
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div>
          {/* end::Link */}
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            className={clsx('form-control form-control-lg form-control-solid')}
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            onClick={_forgotPassword}
            className='btn btn-lg btn-primary fw-bolder me-4'
            disabled={!userEmail || loading}
          >
            <span className='indicator-label'>Submit</span>
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </>
    )
  }

  if (mode === 'emailSent') {
    return (
      <div className='text-center mb-10'>
        {/* begin::Title */}

        <div className='email-icon-wrapper'>
          <KTSVG
            path='/media/icons/duotone/Communication/Urgent-mail.svg'
            className='svg-icon-4 me-1'
          />
        </div>
        <h1 className='text-dark mb-3'>Email sent!</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>Please check spam.</div>
        {/* end::Link */}
      </div>
    )
  }
  if (mode === 'resetPassword' && resetToken) {
    return (
      <div>
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
          <input
            type='password'
            placeholder='******'
            autoComplete='off'
            className={clsx('form-control form-control-lg form-control-solid')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
          <input
            type='password'
            placeholder='******'
            autoComplete='off'
            className={clsx('form-control form-control-lg form-control-solid')}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>

        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            onClick={_resetPassword}
            className='btn btn-lg btn-primary fw-bolder me-4'
            disabled={!password || !confirmPassword || loading || password !== confirmPassword}
          >
            <span className='indicator-label'>Change password</span>
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
      </div>
    )
  }

  return renderForgowPasswordMode()
}

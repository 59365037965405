import React, { useCallback, useState } from 'react'
import SearchSubscriptionsField from '../../../add-edit-transport/components/subscriptions/SearchSubscriptionsField'
import { SubscriptionType } from '../../../../../types/subscription.types'
import DisplaySubscriptionModal from '../../../add-edit-transport/components/subscriptions/DisplaySubscriptionModal'
import { FieldType } from '../../../../../utils/field.utils'
import SubscriptionsDisplayField from '../../../add-edit-transport/components/subscriptions/SubscriptionsDisplayField'

const searchField: FieldType = {
  label: 'Search subscriptions',
  name: 'searchSubscriptions',
  type: 'searchSubscriptions'
}

const displayField: FieldType = {
  label: 'Subscriptions found',
  name: 'displaySubscriptions',
  type: 'displaySubscriptions',
}



const SearchSubscriptionsModal = () => {
  const [selectedSubscription, setSelectedSubsciption] = useState<SubscriptionType | null>(null)
  const [foundSubscriptions, setFoundSubscriptions] = useState<SubscriptionType[]>([])
  const [viewModalVisible, setViewModalVisible] = useState<boolean>(false)

  const closeViewModal = useCallback(() => {
    setViewModalVisible(false)
    setSelectedSubsciption(null)
  }, [])

  return (
    <div className='d-flex flex-column' style={{ width: '100%', marginTop: '-30px' }}>
      {viewModalVisible
        ? <DisplaySubscriptionModal setModalVisible={closeViewModal} modalVisible={viewModalVisible} subscription={selectedSubscription} />
        : null}
      <div className='d-flex flex-column modal-body'>
        <SearchSubscriptionsField setSubscriptions={setFoundSubscriptions} field={searchField} onlyVIN />
        <div className="mt-4">
          <SubscriptionsDisplayField field={displayField} subscriptions={foundSubscriptions} />
        </div>
      </div>
    </div>
  )
}

export default SearchSubscriptionsModal
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CampaignType, StatusType } from '../../../../types/campaign.types';
import InputField from '../../../../_metronic/layout/components/fields/input/input-field';
import { Button } from 'react-bootstrap';
import { StartCampaignModal } from './StartCampaignModal.component';

type FieldType = {
  label: string;
  backend_property?: string;
  visible?: boolean;
};

export const DEFAULT_FIELDS: FieldType[] = [
  {
    label: 'Campaign Name',
    backend_property: 'name',
  },
  {
    label: 'Campaign Type',
    backend_property: 'type',
  },
  {
    label: 'Content Type',
    backend_property: 'content_type',
  },
  {
    label: 'Send After',
    backend_property: 'send_after',
  },
  {
    label: 'End Date',
    backend_property: 'expiration_date',
  },
  {
    label: 'Status',
    backend_property: 'status',
  },
  {
    label: 'Clients Number',
    backend_property: 'number_of_clients',
    visible: false,
  },
];

const CampaignInfoComponent = ({
  campaign,
  setCampaign,
}: {
  campaign: CampaignType;
  setCampaign: React.Dispatch<React.SetStateAction<CampaignType>>;
}) => {
  const [showStartCampaignModal, setShowStartCampaignModal] = useState<boolean>(false);

  useEffect(() => {
    setCampaign(campaign);
  }, []);

  return (
    <div className={`card draggable has-card-options`} style={{ marginTop: 16 }}>
      <div className='card-header border-0 pt-5'>
        <h1 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Campaign Information</span>
        </h1>

        <div className='page__top-buttons'>
          <Button
            className={
              campaign.status.toLowerCase() === StatusType.ON_GOING.toLowerCase() ||
              campaign.status.toLowerCase() === StatusType.FINISHED.toLowerCase()
                ? 'btn btn-secondary item-right mt-5 me-4 disabled'
                : 'btn btn-success item-right mt-5 me-4'
            }
            onClick={() => {
              setShowStartCampaignModal(true);
            }}
          >
            Start Campaign
          </Button>

          <StartCampaignModal
            campaign={campaign}
            showStartCampaignModal={showStartCampaignModal}
            setShowStartCampaignModal={setShowStartCampaignModal}
            setCampaign={setCampaign}
          />

          <Link
            to={{
              pathname: `/view-form/0/preview/${campaign.type}`,
              query: {
                type: campaign.type,
              },
            }}
            className='btn btn-primary item-right mt-5'
          >
            View Form
          </Link>
        </div>
      </div>

      <ul className='autonom-dynamic-card'>
        {DEFAULT_FIELDS?.map((field) => {
          return (
            <div key={field?.label} style={{ width: '30%' }}>
              <InputField
                key={field?.label}
                value={campaign[field?.backend_property]}
                field={field}
                readOnly={true}
                onChange={() => {}}
                style={{ backgroundColor: 'lightgrey' }}
              />
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default CampaignInfoComponent;

import { GeofenceType } from '../../../types/general.types'

export enum CurrencySource {
  BCR = 'BCR',
  ING = 'ING',
  BNR = 'BNR'
}
export const currencySourceList = Object.values(CurrencySource).map((source: string) => ({ name: source }));

export type TariffPlanType = {
  id: number | null
  name: string
  customer: any
  from: string
  currency: string | null
  autoBillingEnabled: boolean
  autoBillingDate: string | number | null
  currencySource: CurrencySource
  tariffs: TariffType[]
  bookingManagementTaxes: BookingTaxesType[]
  pickupTimeBasedExtras: pickupTimeBasedExtrasType[]
  waitingTimeExtras: waitingTimeExtrasType[]
  pickupLocationBasedTaxes: pickupLocationBasedTaxesType[]
  intermediaryStopExtras: intermediaryStopExtrasType[]
  intercityKmCosts: intercityKmCostsType[]
  createdAt: string | null
  createdBy: string | null
  updatedAt: string | null
  updatedBy: string | null
}

export type TariffType = {
  id: number | null
  transferType: string
  startGeofence: GeofenceType
  endGeofence: GeofenceType
  service: TariffServiceType
  extraExceptions: { name: string; title: string }[]
  baseRate: number
  ratePerKm: number
  ratePerHour: number
  commission: { value: number, percentage: boolean }
  commissionValue: number
  commissionPercentage: boolean
}
export type BookingTaxesType = { name: string; title: string; value: number }
export type pickupTimeBasedExtrasType = {
  id: number | null
  name: string
  transferTypes: string[]
  tariffServices: TariffServiceType[]
  fixedTax: number
  kmTax: number
  hourTax: number
}
export type pickupLocationBasedTaxesType = {
  id: number | null
  geofenceType: string
  startGeofences: GeofenceType[]
  tariffServices: TariffServiceType[]
  fixedTax: number
  defaultWaitTime: number
}
export type waitingTimeExtrasType = {
  id: number | null
  name: string
  transferTypes: string[]
  tariffServices: TariffServiceType[]
  costPerMinute: number
  freeMinutes: number
}
export type intermediaryStopExtrasType = {
  id: number | null
  transferType: string
  tariffServices: TariffServiceType[]
  fixedTax: number
  freeStops: number
}
export type intercityKmCostsType = {
  id: number | null
  transferType: string
  tariffServices: TariffServiceType[]
  kmPricePercentage: number
}

export type TariffServiceType = {
  businessProfileId: number
  description: string
  id: number | null
  includedPassengers: number
  name: string
  photoUrl: string
  weekendLimit: number
  workdayLimit: number
  enabled: boolean
  revenueCenter: string | null
  supplier: any
  supplierId: string | number | null
}
export const emptyTariffService = {
  businessProfileId: 0,
  description: '',
  id: null,
  includedPassengers: 0,
  name: '',
  photoUrl: '',
  weekendLimit: 0,
  workdayLimit: 0,
  enabled: false,
  revenueCenter: null,
  supplier: null,
  supplierId: null
}

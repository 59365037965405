import { getTariffBreakdown } from '../../../setup/axios/transports.request'
import { emptyPOI, POI } from '../poi-component/poiConstants'
import { v4 as uuiv4 } from 'uuid'
import { getTransportForDetails } from '../../../utils/transport.utils'
import { TariffInfoType, TypeOfCompany } from '../../../utils/pricing.utils'
import moment from 'moment'
import { IdentificationData } from './scripts/requestFormOperations'
import { orbitRequest, RequestMethod } from '../../../utils/api.utils'
import { RentPlanDataType } from './scripts/tariffUtils'
import { toast } from 'react-toastify'
import { TransportForRequestType, TransportType } from './scripts/transportTypes'

export type PassengersObjectType = { mainPassenger: string; otherPassengers: string[] }

export const getIntermPointsList = (paramTransport) => {
  let intermPoints = paramTransport.intermediaryPoints || []
  if (intermPoints) {
    let futurePOIList = []
    let n = intermPoints.length
    for (let i = 0; i < n; i++) {
      futurePOIList.push({ ...emptyPOI, ...intermPoints[i], ...{ uniqueId: uuiv4() } })
    }
    return futurePOIList
  }
  return []
}

export const getVoucherFromCompany = ({
  transportCompany,
  companies,
}: {
  transportCompany: any
  companies: any[]
}) => {
  if (!transportCompany) {
    // Can't extract a voucher when the company is missing
    return ''
  }
  let companyVoucher = transportCompany?.voucher
  if (!companyVoucher) {
    if (companies.length === 0) {
      return ''
    }
    companyVoucher = companies.find((company: any) => company?.id === transportCompany?.id)?.voucher
  }
  return companyVoucher || ''
}

export const getTariffInfo = async ({
  transport,
  identificationData,
  intermPOIList,
}: {
  transport: TransportType | TransportForRequestType
  identificationData: IdentificationData
  intermPOIList: POI[]
}) => {
  const { voucher, businessProfileId } = identificationData
  const companyId = transport?.company?.id
  if (!Boolean(voucher) && !Boolean(companyId)) {
    return null
  }
  const tariffBreakdownRequest = await getTariffBreakdown({
    payload: getTransportForDetails({ transport, voucher, intermPOIList }),
    businessProfileId,
  })
  const futureTariffBreakdown = tariffBreakdownRequest?.data
  if (!futureTariffBreakdown) {
    return null
  }
  return futureTariffBreakdown
}

export const getRentTariffInfo = async ({ rentPlanData, filteredExtraCosts }: { rentPlanData: RentPlanDataType, filteredExtraCosts?: { id: number; quantity: number; }[] }) => {
  const tariffBreakdownRequest = await orbitRequest({
    route: '/rent-total-cost/get-initial-cost',
    payload: { ...rentPlanData, filtered_extra_costs: filteredExtraCosts },
    method: RequestMethod.POST,
  })
  if (!tariffBreakdownRequest || tariffBreakdownRequest.error) {
    toast.error(`There was a problem with the rent pricing! ${tariffBreakdownRequest?.message || ''}`)
    return null
  }
  const futureTariffBreakdown = tariffBreakdownRequest?.pricing
  if (!futureTariffBreakdown) {
    toast.error(`The pricing object couldn't be retrieved! Please check the provided data!`)
    return null
  }
  return futureTariffBreakdown
}

type TransportTypeObj = { name: string; title: string }
export const getTransportTypeString = ({
  transportTypeName,
  transportTypes,
}: {
  transportTypeName: string
  transportTypes: TransportTypeObj[]
}) => {
  return transportTypes.find(
    (transportType: TransportTypeObj) => transportType.name === transportTypeName
  )?.title
}

export const extractBaseCurrency = ({
  transport,
  companyType,
  tariffInfo,
}: {
  transport: TransportType
  companyType: TypeOfCompany
  tariffInfo?: TariffInfoType
}) => {
  let taxCurrency = tariffInfo?.currency || transport?.newCurrency || transport?.company?.currency || 'RON'
  let baseCurrency = taxCurrency
  if (companyType === TypeOfCompany.Supplier) {
    baseCurrency = tariffInfo?.commissionCurrency || transport?.commissionCurrency || transport?.supplier?.currency || 'RON'
  }
  return baseCurrency
}

export const convertFieldName = {
  code: 'transportCodeCode',
  idUser: ['user', 'username'],
  effectiveDateString: 'effectiveDate',
  paymentTypeString: 'paymentType',
  tariffString: 'tariff',
  requestDateString: 'requestDate',
  initialComment: 'comment',
  completeEstimateDateString: 'completeEstimateDate',
  replacementVehicleReturnEstimateDateString: 'replacementVehicleReturnEstimateDate',
  idCompanyClient: ['company', 'id'],
  idPoiOrigin: 'poiOrigin',
  idPoiDestination: 'poiDestination',
  idTariffService: 'tariffServiceId',
  currencyString: 'currency',
  paymentStatusString: 'paymentStatus',
  requestStatusString: 'requestStatus',
  idTariffType: 'tariffTypeId',
  idVehicleAssigned: ['assignedVehicle', 'idVehicle'],
  idDriverAssigned: ['assignedDriver', 'idUserProfile'],
  transferTypeString: 'transferType',
  requestRemarkString: 'requestRemark',
}

export const downloadFile = (pdf: string, createdDate: string, type: string) => {
  const linkSource = `data:${type === 'pdf' ? 'application/pdf' : 'image/jpeg'};base64,${pdf}`
  const downloadLink = document.createElement('a')
  const fileName = `${moment(createdDate).format('DD-MM-YYYY')} ${moment(createdDate).format(
    'HH:mm'
  )}`
  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
}

export const createURLForPDFFromBase64 = (pdf: string) => {
  const bytes = new Uint8Array(atob(pdf).split('').map(char => char.charCodeAt(0)));

  // Create a Blob from the Uint8Array
  const blob = new Blob([bytes], { type: 'application/pdf' });

  // Convert Blob to object URL
  const pdfURL = URL.createObjectURL(blob);
  return pdfURL
}

import { FC, useEffect, useState } from 'react'
import Table from './Table'
import { RentPlanExtraCostsModel, emptyRentPlanExtraCosts } from '../../../app/modules/rent-plans/models/RentPlanExtraCostsModel'
import { createRentPlanExtraCosts, deleteRentExtraCosts, getRentPlanExtraCosts, putRentExtraCosts } from '../../../setup/axios/rent.request'
import { toast } from 'react-toastify'
import { updateField } from '../../../utils/state.utils'
import { getStringOfEntity } from '../../../utils/string.utils'
import { useParams } from 'react-router-dom'
import { usePlanContext } from '../../../context/plan.context'
import moment, { Moment } from 'moment'

//PH = Public Holiday - Sarbatori Legale

const weekDays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN', 'PH'];

const week_days_key = 'week_days';

const rentExtrasFields = [
    'name',
    'description',
    'day_cost',
    'is_applicable',
]
const compoundUniqueKeyExtras = ['name']

const getExtrasLabel = () => "Extras";

const getFormattedWeekDays = (week_days: string[]) => {
  return week_days.join('-')
}
const RentExtrasFieldToProperty = (
    field: any,
    extras: RentPlanExtraCostsModel,
    setExtras: Function,
    setWeekDays: Function,
    setSelectedWeekDays: Function,
  ) => {
    let fieldName = field.name
    const updateExtrasListElemEvent = (e: any, customFieldName?: string) =>
      updateField({ value: e, fieldName: customFieldName ?? fieldName, setObject: setExtras })

    const fieldsToPanelPropertiesMapping = {
      name: { value: getStringOfEntity({ entity: extras.name }) },
      description: { value: getStringOfEntity({ entity: extras.description }) },
      day_cost: { value: getStringOfEntity({entity: extras.day_cost}) },
      is_applicable: {
        simpleValue: extras.is_applicable || '',
        options: [{name: "per day", title: "per day"}, {name: "per rental", title: "per rental"}, {name: "per week day", title: "per week day"}] || [],
        getOptionValue: (e: any) => e.name || '',
        getOptionLabel: (e: any) => e.title || '',
        onChange: (e) => {
          if (e === "per week day") {
            setWeekDays(weekDays)
          } else {
            setWeekDays([])
            setSelectedWeekDays([])
            setExtras({...extras, week_days: []})
            updateExtrasListElemEvent(null, 'pickup_time');
            updateExtrasListElemEvent(null, 'delivery_time');
          }
          return updateExtrasListElemEvent(e);
        },
        onLoad: () => {
          if (extras.is_applicable === "per week day") {
            setWeekDays(weekDays)
            setSelectedWeekDays(extras.week_days.map((week_day: string) => ({key: week_days_key, value: week_day})))            
          } else if (!extras.is_applicable) {
            setWeekDays([])
            setSelectedWeekDays([])
          }
        }
      },
      ...weekDays.reduce((acc, weekDay) => {
        const isExistingWeekDay = extras.week_days.includes(weekDay);
        return ({
          ...acc, [weekDay]: {
            value: isExistingWeekDay,
            onChange: (e) => {
              let temporaryWeekDays = [];
              if(!isExistingWeekDay) {
                temporaryWeekDays = [...extras.week_days, weekDay].sort((a, b) => weekDays.indexOf(a) - weekDays.indexOf(b))
                setSelectedWeekDays(temporaryWeekDays)
              } else {
                temporaryWeekDays = extras.week_days.filter(days => days !== weekDay).sort((a, b) => weekDays.indexOf(a) - weekDays.indexOf(b))
                setSelectedWeekDays(temporaryWeekDays)
              }
              if (!temporaryWeekDays.length) {
                updateExtrasListElemEvent(null, 'pickup_time');
                updateExtrasListElemEvent(null, 'delivery_time');
              } else {
                updateExtrasListElemEvent(moment(), 'pickup_time');
                updateExtrasListElemEvent(moment(), 'delivery_time');
              }
              return updateExtrasListElemEvent(temporaryWeekDays, week_days_key);
            }
          
          }
        })
      }, {}),
      
      pickup_time: {value: moment(extras.pickup_time) ?? moment()},
     
      delivery_time: {value: moment(extras.delivery_time) ?? moment()},

    }
    return (fieldsToPanelPropertiesMapping as any)[fieldName]
    }

const RentExtrasTable: FC<{}> = ({ }) => {
     const { id, companyId } = useParams<any>()
     const [rentPlanExtraCosts, setRentPlanExtraCosts] = useState<RentPlanExtraCostsModel[]>([])
     const { rentCompanyPricingPlan } = usePlanContext()
     const [weekDays, setWeekDays] = useState<string[]>([]);
     const [selectedWeekDays, setSelectedWeekDays] = useState<{key: string, value: string}[]>([]);
     
     const fetchExtras = async () => {
      return getRentPlanExtraCosts(id).then((futureRentPlanExtraCosts: RentPlanExtraCostsModel[] | null) => {
        if (!futureRentPlanExtraCosts) {
          return toast.error('The Rent Plan Extra Costs failed to load!')
        }
         setRentPlanExtraCosts(futureRentPlanExtraCosts)
      })
     }
     useEffect(() => {
       fetchExtras();
    }, [])

    const weekDaysProps = weekDays.map((weekDay) => ({
        name: weekDay,
        label: weekDay,
        getDisplayLabel: (week_day: string) => week_day,
        type: 'checkbox',
        isCheckboxValueProp: false,
        hideColon: true,
        style: {marginTopCheckbox: 10, marginTopLabel: 12, widthCheckbox: 18, heightCheckbox: 18, fontSizeLabel: 12, marginRightCheckbox: 2},
        hideInTable: true,
        hidden: false,
        required: false,
        editable: true,
        addable: true
    }))

    const timePickerProps = [
      {
        name: 'pickup_time',
        label: 'Pick up Time',
        getDisplayLabel: (pickup_time: Moment) => moment(pickup_time).isValid() ? moment(pickup_time).format('HH:mm') : "",
        type: 'timePicker',
        showSecond: false,
      },
      {
        name: 'delivery_time',
        label: 'Delivery Time',
        getDisplayLabel: (delivery_time: Moment) => moment(delivery_time).isValid() ?  moment(delivery_time).format('HH:mm') : "",
        type: 'timePicker',
        showSecond: false,
        disableHours: true
      }
    ];

    const extrasProps = [
      {
        name: 'name',
        getDisplayLabel: (name: string) => name,
        label: 'Extras type',
        type: 'input',
      },
      {
        name: 'description',
        getDisplayLabel: (description: string) => description,
        label: 'Description',
        type: 'input',
      }, 
      {
        name: 'is_applicable',
        getDisplayLabel: (is_applicable: string) => `${is_applicable}`,
        label: 'Applicable',
        type: 'select',
      },
      {
        name: 'day_cost',
        getDisplayLabel: (day_cost: number) => day_cost,
        label: 'Cost',
        type: 'input',
        addOns: {append: rentCompanyPricingPlan.currency},
      },
      {
        name: week_days_key,
        label: 'Week days',
        getDisplayLabel: (week_days: string[]) => Array.isArray(week_days) ? getFormattedWeekDays(week_days) : "",
        editModalChildren: weekDaysProps
      },
      {
        name: 'time_picker',
        label: 'Time picker',
        getDisplayLabel: (timePicker: any) => "",
        hideInTable: true,
        editable: selectedWeekDays.length > 0,
        addable: selectedWeekDays.length > 0,
        editModalChildren: timePickerProps
      },
      ...[...timePickerProps.map((timePickerProp) => ({...timePickerProp, addable: false, editable: false}))]
    ]

    return (
        <>
            <Table
                tableLabel={'Extras'}
                properties={extrasProps}
                fieldToProperty={(field, object, setObjectFunc) => RentExtrasFieldToProperty(field, object, setObjectFunc, setWeekDays, setSelectedWeekDays)}
                entries={rentPlanExtraCosts}
                setEntries={setRentPlanExtraCosts}
                emptyEntry={emptyRentPlanExtraCosts}
                getEntryLabel={getExtrasLabel}
                requiredFields={rentExtrasFields}
                compoundUniqueKey={compoundUniqueKeyExtras}
                deleteEndpoint={deleteRentExtraCosts}
                editEndpoint={(value) => putRentExtraCosts(value, companyId)}
                createEndpoint={async (value) => {
                  const extraCosts = await createRentPlanExtraCosts(value, id, companyId);
                  await fetchExtras();
                  return extraCosts;
                }}
                isPlanCreated= {!!id}
                addEntryLabel= "Add Extras"
                onCancelCustom={() => {
                  setWeekDays([])
                  setSelectedWeekDays([])
                  }}
                resetStateValue={selectedWeekDays}
            />
        </>
    )

 }

export default RentExtrasTable

export const intervalCostKey = "intervalCost";

export type RentPlanTariffsModel = {
    image_url: string
    plan_vehicle_class_id: number
    name: string
    [key: `intervalCost${number}`]: IntervalCost;
    created_at: string | null
    updated_at: string | null,
    start_day: string,
    end_day: string,
    disable_end_day: boolean,
}

export type IntervalCost = {
    id: number
    client_id: string
    cost_id: number
    interval_id: string
    plan_id: string
    cost: string
    start_day: string
    end_day: string
}
  
export const emptyRentPlanTariffs = {
    name: "",
    image_url: "",
    created_at:  null,
    updated_at:  null
}

import CryptoES from 'crypto-es'
export const PAYU_SECRET_KEY = 'sQ)Z88i5#6%L_T6#e5!d'

export const getPayUSignature = (params: any) : string => {
  var hashSource = ''

  for (var key in params) {
    if (params.hasOwnProperty(key)) {

      if (params[key].toString().includes('[') && params[key].toString().includes(']')) {
        // If is an array
        JSON.parse(params[key]).forEach((e) => {
          const value = e.replaceAll('"', '')
          hashSource += `${value.length}${value}`
        })
      } else {
        hashSource += `${params[key].length}${params[key]}`
      }
    }
  }

  var signature = CryptoES.HmacMD5(hashSource, PAYU_SECRET_KEY).toString()
  return signature
}


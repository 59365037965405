import moment from 'moment'
import React, { memo, useContext, useEffect, useState, useMemo } from 'react'
import debounce from 'lodash.debounce'
import { TransportsContext } from '../../../context/transports.context'
import { UserContext } from '../../../context/user.context'
import { statusColorStyle } from '../../../utils/style.utils'
import Select from '../../../_metronic/layout/components/select/Select'
import { useStateParams } from '../../../hooks/useStateParams'
import { AppContext } from '../../../context/app.context'

function Filter() {
  const {
    dictionaries,
    tariffServices,
    tariffTypes,
    companies,
    chosenDate,
    setChosenDate,
    filterInterval,
    setFilterInterval,
    employeeFunction,
    setEmployeeFunction,
    selectedStatuses,
    setSelectedStatuses,
    selectedTariffTypes,
    setSelectedTariffTypes,
    selectedTariffServices,
    setSelectedTariffServices,
    selectedCompanies,
    setSelectedCompanies,
    selectedDrivers,
    setSelectedDrivers,
    selectedShiftType,
    setSelectedShiftType,
    drivers,
    setDictionaries,
    setTariffServices,
    setTariffTypes,
    setCompanies,
    setDrivers,
  } = useContext<any>(TransportsContext)

  const [localEmployeeFunction, setLocalEmployeeFunction] = useState<string>(employeeFunction);
  const [localSelectedStatuses, setLocalSelectedStatuses] = useState<any[]>(selectedStatuses);
  const [localSelectedTariffTypes, setLocalSelectedTariffTypes] = useState<any[]>(selectedTariffTypes);
  const [localSelectedTariffServices, setLocalSelectedTariffServices] = useState<any[]>(selectedTariffServices);
  const [localSelectedCompanies, setLocalSelectedCompanies] = useState<any[]>(selectedCompanies);
  const [localSelectedDrivers, setLocalSelectedDrivers] = useState<any[]>(selectedDrivers);
  const [localSelectedShiftType, setLocalSelectedShiftType] = useState<any[]>(selectedShiftType);

  const { businessProfileId } = useContext<any>(UserContext)
  const { getPromiseByKey } = useContext<any>(AppContext)

  const [localDate, setLocalDate] = useStateParams<Date>(
    chosenDate, // initial value
    'date', // param name
    (value: Date) => moment.utc(value).format('MM-DD-YYYY'), // serialization func
    (value: string) => moment.utc(value).toDate() // deserialization func
  )


  const debouncedEmployeeHandler = useMemo(() => debounce(setEmployeeFunction, 500), []);
  const debouncedStatusesHandler = useMemo(() => debounce(setSelectedStatuses, 1000), []);
  const debouncedTariffTypesHandler = useMemo(() => debounce(setSelectedTariffTypes, 1000), []);
  const debouncedTariffServicesHandler = useMemo(() => debounce(setSelectedTariffServices, 1000), []);
  const debouncedCompaniesHandler = useMemo(() => debounce(setSelectedCompanies, 1000), []);
  const debouncedDriversHandler = useMemo(() => debounce(setSelectedDrivers, 1000), []);
  const debouncedShiftTypeHandler = useMemo(() => debounce(setSelectedShiftType, 1000), []);

  useEffect(() => {
    return () => {
      debouncedEmployeeHandler.cancel()
      debouncedStatusesHandler.cancel()
      debouncedTariffTypesHandler.cancel()
      debouncedTariffServicesHandler.cancel()
      debouncedCompaniesHandler.cancel()
      debouncedDriversHandler.cancel()
      debouncedShiftTypeHandler.cancel()
    }
  }, [])

  useEffect(() => {
    if (Boolean(localDate) && localDate != chosenDate) {
      setChosenDate(localDate)
    }
  }, [localDate])

  useEffect(() => {
    // Get and set drivers
    ; (async () => {
      const result = await getPromiseByKey('drivers', { businessProfileId })
      if (result.data) {
        setDrivers(
          result.data.sort((fst: any, snd: any) =>
            fst.fullName.toLowerCase().localeCompare(snd.fullName.toLowerCase())
          )
        )
      }
    })()

    // Get and set dictionaries
    ; (async () => {
      const result = await getPromiseByKey('dictionaries', { businessProfileId })
      if (result.data) {
        setDictionaries(result.data)
      }
    })()

    // Get and set tariff types
    ; (async () => {
      const result = await getPromiseByKey('tariffTypes', { businessProfileId })
      if (result.data) {
        setTariffTypes(result.data)
      }
    })()

    // Get and set tariff services
    ; (async () => {
      const result = await getPromiseByKey('tariffServices', { businessProfileId })
      if (Boolean(result?.data)) {
        setTariffServices(result.data)
      }
    })()

    // Get and set tariff companies
    ; (async () => {
      const result = await getPromiseByKey('companies', { businessProfileId })
      if (result.data) {
        const futureCompanies = result.data.filter((company: any) => {
          if (company.client && company.enabled) {
            return true
          }
          return false
        })
        setCompanies(futureCompanies)
      }
    })()
  }, [])

  return (
    <div className='col-12 pb-4'>
      <div className='card h-100'>
        <div className='card-header pt-5 border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Filter</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='row align-items-end'>
            {/* Date */}
            <div className='col-xl-4'>
              <div className='input-group input-group-sm d-flex justify-content-center justify-content-xl-start py-3'>
                <span className='input-group-text'>Date:</span>
                <input
                  className='form-control'
                  type='date'
                  value={moment.utc(localDate).format('YYYY-MM-DD')}
                  onChange={(e) => {
                    setLocalDate(new Date(e.target.value))
                  }}
                />
              </div>
            </div>

            {/* Interval */}
            <div className='col-xl-4'>
              <div className='input-group input-group-sm d-flex justify-content-center justify-content-xl-start py-3'>
                <span className='input-group-text'>Interval:</span>
                <select
                  className='form-select'
                  value={filterInterval}
                  onChange={(e) => {
                    setFilterInterval(parseInt(e.target.value))
                  }}
                >
                  {(dictionaries?.ASSIGN_INTERVALS || []).map((option: any) => {
                    return (
                      <option key={option.value} value={option.value}>
                        {option.title}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>

            {/* Employee function */}
            <div className='col-xl-4'>
              <div className='input-group input-group-sm d-flex justify-content-center justify-content-xl-start py-3'>
                <span className='input-group-text'>Employee function:</span>
                <input
                  className='form-control'
                  type='text'
                  value={localEmployeeFunction}
                  onChange={(e) => {
                    setLocalEmployeeFunction(e.target.value)
                    debouncedEmployeeHandler(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>

          <div className='row'>
            {/* Status */}
            <div className='col py-3'>
              <Select
                styles={statusColorStyle}
                label='Status'
                value={localSelectedStatuses}
                options={dictionaries?.REQUEST_STATUSES}
                onChange={(e: any[]) => {
                  setLocalSelectedStatuses(e)
                  debouncedStatusesHandler(e)
                }}
                isMulti={true}
                getOptionLabel={(option: any) => option.title}
                getOptionValue={(option: any) => option.oldId}
              />
            </div>

            {/* Tarif types */}
            <div className='col py-3'>
              <Select
                label='Tariff Type'
                value={localSelectedTariffTypes}
                options={tariffTypes}
                onChange={(e: any[]) => {
                  setLocalSelectedTariffTypes(e)
                  debouncedTariffTypesHandler(e)
                }}
                isMulti={true}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.id}
              />
            </div>

            {/* Service */}
            <div className='col py-3'>
              <Select
                key={0}
                label='Service Type'
                value={localSelectedTariffServices}
                options={tariffServices}
                onChange={(e: any[]) => {
                  setLocalSelectedTariffServices(e)
                  debouncedTariffServicesHandler(e)
                }}
                isMulti={true}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.id}
              />
            </div>
          </div>

          <div className='row'>
            {/* Companies */}
            <div className='col py-3'>
              <Select
                label='Company'
                value={localSelectedCompanies}
                options={companies}
                onChange={(e: any[]) => {
                  setLocalSelectedCompanies(e)
                  debouncedCompaniesHandler(e)
                }}
                isMulti={true}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.id}
              />
            </div>

            {/* Driver */}
            <div className='col py-3'>
              <Select
                label='Drivers'
                value={localSelectedDrivers}
                options={drivers}
                onChange={(e: any[]) => {
                  setLocalSelectedDrivers(e)
                  debouncedDriversHandler(e)
                }}
                isMulti={true}
                getOptionLabel={(option: any) => option.fullName}
                getOptionValue={(option: any) => option.idUserProfile}
              />
            </div>

            {/* Shift Types */}
            <div className='col py-3'>
              <Select
                label='Shift Types'
                value={localSelectedShiftType}
                options={dictionaries.SHIFT_TYPES}
                onChange={(e: any[]) => {
                  setLocalSelectedShiftType(e)
                  debouncedShiftTypeHandler(e)
                }}
                isMulti={true}
                getOptionLabel={(option: any) => option.title}
                getOptionValue={(option: any) => option.name}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default memo(Filter)

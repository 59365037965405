import {toast} from 'react-toastify'
import {orbitRequest, RequestMethod} from '../../../../utils/api.utils'

export const getPlans = async ({companyId}: {companyId?: string | number}) => {
  const response = await orbitRequest({
    route: `/rent-company-price-plans/company-plans?company_id=${companyId}`,
    method: RequestMethod.GET,
    // payload: {company_id: companyId},
  })
  if (!response || response.error) {
    toast.error("The plans couldn't be retrieved!")
    return []
  }

  return response.company_plans
}

export const getPlan = async ({planId}: {planId: string | number}) => {
  const response = await orbitRequest({
    route: `/rent-company-price-plans/company-plan`,
    method: RequestMethod.POST,
    payload: {plan_id: planId},
  })
  if (!response || response.error) {
    return null
  }

  return response.plan
}

export const deletePlan = async ({planId}: {planId: string | number}) => {
  const response = await orbitRequest({
    route: `/rent-company-price-plans/delete`,
    method: RequestMethod.POST,
    payload: {plan_id: planId},
  })
  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  toast.success('Plan sucessfully deleted!')
  return true;
}

export const clonePlan = async ({
  planId,
  name,
}: {
  planId: string | number
  name: string
}) => {
  const response = await orbitRequest({
    route: `/rent-company-price-plans/clone`,
    method: RequestMethod.POST,
    payload: {plan_id: planId, name},
  })
  if (!response || response.error) {
    return null
  }

  return response.plan
}

export const updatePlan = async ({plan}: {plan: any}) => {
  const response = await orbitRequest({
    route: `/rent-company-price-plans/update`,
    method: RequestMethod.POST,
    payload: {plan},
  })
  if (!response || response.error) {
    return null
  }

  return response.plan
}

export const savePlan = async ({plan}: {plan: any}) => {
  const response = await orbitRequest({
    route: `/rent-company-price-plans/create`,
    method: RequestMethod.POST,
    payload: {plan},
  })

  return response.plan
}

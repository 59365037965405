export type Invoice = {
    orderNumber: string
    date: string
    items: any[]
    itemDescription: string[]
    invoiceNumber: string
    attach: boolean
  }
  
  export type InvoiceNecessaries = {
    invoice_id: string
    invoice_number: string
  }
  
  export const EMPTY_INVOICE_NECESSARIES = {
    invoice_id: '',
    invoice_number: '',
  }
  
import {uuid4} from '@sentry/utils'
import moment from 'moment'
import React, {useState} from 'react'
import ModalPortal from '../../../../_metronic/layout/components/modal/modal-portal'
import {EditableLabel} from '../../add-edit-template/components/fields-generator'

export type GreeterSignActivation = {
  location: string
  shownAt: Date | string
  deviceType: string
  deviceName: string
}

export type GreeterSignActivationTemp = {
  location: string
  shownAt: Date | string
  deviceType: string
  deviceName: string
  tempKey: string
}

const TableHeader = () => {
  return (
    <thead>
      <tr className='fw-bolder text-muted'>
        <th className='p-2'>No.</th>
        <th className='p-2'>Location</th>
        <th className='p-2' style={{whiteSpace: 'nowrap'}}>
          Shown At
        </th>
        <th className='p-2' style={{whiteSpace: 'nowrap'}}>
          Device Type
        </th>
        <th className='p-2' style={{whiteSpace: 'nowrap'}}>
          Device Name
        </th>
      </tr>
    </thead>
  )
}

const TableRow = ({activation, index}: {activation: GreeterSignActivationTemp; index: number}) => {
  const formattedActivationDate = Boolean(activation.shownAt)
    ? moment(activation.shownAt).format('YYYY-MM-DD HH:mm')
    : ''

  return (
    <tr>
      <td className='p-2'>{index + 1}</td>
      <td className='p-2'>{activation.location}</td>
      <td className='p-2'>{formattedActivationDate}</td>
      <td className='p-2'>{activation.deviceType}</td>
      <td className='p-2'>{activation.deviceName}</td>
    </tr>
  )
}

const ViewModal = ({
  greeterSignActivations,
  modalVisible,
  title,
  setModalVisible,
}: {
  greeterSignActivations: GreeterSignActivationTemp[]
  modalVisible: boolean
  title: string
  setModalVisible: any
}) => {
  return (
    <ModalPortal
      title={title}
      setVisible={setModalVisible}
      visible={modalVisible}
      dialogClassName='modal-xxl'
    >
      <div className='w-100 px-4'>
        <table className='table table-hover table-row-gray-100 align-middle w-100'>
          <TableHeader />
          <tbody>
            {greeterSignActivations.map((activation: GreeterSignActivationTemp, index: number) => (
              <TableRow key={`row-${activation.tempKey}`} activation={activation} index={index} />
            ))}
          </tbody>
        </table>
      </div>
    </ModalPortal>
  )
}

const GreeterSignList = ({
  greeterSignActivations = [],
  field = {},
  onChangeLabel,
  hideLabel = false,
}: {
  greeterSignActivations?: GreeterSignActivation[]
  field: any
  title?: string
  titleStyle?: {[prop: string]: string}
  onChangeLabel?: any
  hideLabel?: boolean
}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const localGreeterSignActivations: GreeterSignActivationTemp[] = (
    greeterSignActivations || []
  ).map((greeterSignActivation: GreeterSignActivation) => ({
    ...greeterSignActivation,
    tempKey: uuid4(),
  }))
  const numOfActivations = localGreeterSignActivations.length

  return (
    <div key={`stopsList-${field?.name}`}>
      {!hideLabel ? (
        <EditableLabel label={field?.label} onChange={onChangeLabel} isRequired={field?.required} />
      ) : null}
      <button
        type='button'
        onClick={() => {
          setModalVisible(true)
        }}
        disabled={!numOfActivations}
        style={{border: 'none', backgroundColor: 'white', color: '#009ef7', paddingLeft: 0}}
      >
        {!numOfActivations
          ? 'No Greeter Sign Activations'
          : `${numOfActivations} Greeter Sign Activations`}
      </button>
      <ViewModal
        greeterSignActivations={localGreeterSignActivations}
        modalVisible={modalVisible}
        title={'Greeter Sign Activations'}
        setModalVisible={setModalVisible}
      />
    </div>
  )
}

export default GreeterSignList

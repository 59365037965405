import { TypeOfCompany } from "../../../utils/pricing.utils";


export const fields = [
  {
    name: 'clientSpecs',
    label: 'Client Specifications',
    type: 'alert',
    alertType: 'danger',
    style: {
      width: '100%',
    },
    hidden: false,
  },
  {
    name: 'code',
    label: 'Code',
    type: 'selectWithAutocomplete',
    inputType: 'text',
    required: true,
    autocomplete: {
      transportCodeId: 'id',
      company: 'company',
      requestorName: 'name',
      requestorPhone: 'phone',
      requestorEmail: 'email',
      companyCustomField1Value: 'companyCustomField1Value',
      companyCustomField2Value: 'companyCustomField2Value',
      companyCustomField3Value: 'companyCustomField3Value',
      companyCustomField4Value: 'companyCustomField4Value',
      companyCustomField5Value: 'companyCustomField5Value',
    },
    hidden: false,
  },
  {
    name: 'idCompanyClient',
    label: 'Company',
    type: 'select',
    required: true,
    hidden: false,
  },
  {
    name: 'supplier',
    label: 'Supplier',
    type: 'select',
    isClearable: true,
    hidden: false,
  },
  {
    name: 'revenueCenter',
    label: 'Revenue Center',
    type: 'select',
    isClearable: true,
    hidden: false,
  },
  {
    name: 'requestorName',
    label: 'Requestor',
    type: 'input',
    inputType: 'text',
    required: true,
    hidden: false,
  },
  {
    name: 'requestorPhone',
    label: 'Requestor Phone',
    type: 'input',
    inputType: 'text',
    required: true,
    hidden: false,
  },
  {
    name: 'requestorEmail',
    label: 'Requestor Email',
    type: 'input',
    inputType: 'email',
    required: true,
    hidden: false,
  },
  {
    name: 'companyCustomField1Value',
    label: 'Requestor Reference',
    type: 'customInput',
    inputType: 'text',
    hidden: false,
  },
  {
    name: 'companyCustomField2Value',
    label: 'Requestor Division',
    type: 'customInput',
    inputType: 'text',
    hidden: false,
  },
  {
    name: 'companyCustomField3Value',
    label: 'Requestor Department',
    type: 'customInput',
    inputType: 'text',
    hidden: false,
  },
  {
    name: 'companyCustomField4Value',
    label: 'Requestor Service',
    type: 'customInput',
    inputType: 'text',
    hidden: false,
  },
  {
    name: 'companyCustomField5Value',
    label: 'Requestor Cost Center',
    type: 'customInput',
    inputType: 'text',
    hidden: false,
  },
  {
    name: 'promoCode',
    label: 'Promo Code',
    type: 'input',
    inputType: 'text',
    hidden: false,
  },
  {
    name: 'repeat',
    label: 'Repeat request',
    type: 'customFieldForRepeat',
    hidden: false,
  },
  {
    name: 'effectiveDateString',
    label: 'Effective date',
    type: 'date',
    hidden: false,
    required: true,
  },
  {
    name: 'idPoiOrigin',
    label: 'Origin point of interest',
    type: 'inputCompleteFields',
    propertyField: 'additionalInfo',
    hidden: false,
    inputType: 'text',
    autocomplete: {
      originCoords: 'gps',
      origin: 'address',
      originAdditionalInfo: 'additionalInfo',
    },
  },
  {
    name: 'origin',
    label: 'Pickup address',
    type: 'geolocation',
    hidden: false,
    required: true,
    coordsKey: 'originCoords',
    zoneKey: 'originGeoFence',
    additionalInfoKey: 'originAdditionalInfo',
    style: {
      flex: 1,
      minWidth: '50%',
    },
  },
  {
    name: 'originAdditionalInfo',
    label: 'Additional info',
    type: 'viewModeHelper',
    inputType: 'text',
    required: true,
  },
  {
    name: 'originCoords',
    label: 'Provide a valid location: Origin GPS',
    type: 'hiddenInput',
    required: true,
  },
  {
    name: 'originGeoFence',
    label: 'Zone',
    type: 'input',
    hidden: false,
    inputType: 'text',
    //required: true,
  },
  // {
  //   name: 'idPoiDestination',
  //   label: 'Destination point of interest',
  //   type: 'select',
  // },
  {
    name: 'idPoiDestination',
    hidden: false,
    label: 'Destination point of interest',
    type: 'inputCompleteFields',
    propertyField: 'additionalInfo',
    inputType: 'text',
    autocomplete: {
      destinationCoords: 'gps',
      destination: 'address',
      destinationAdditionalInfo: 'additionalInfo',
    },
  },
  {
    name: 'destinationCoords',
    label: 'Provide a valid location: Destination GPS',
    type: 'hiddenInput',
    required: true,
  },
  {
    name: 'destination',
    label: 'Destination address',
    hidden: false,
    type: 'geolocation',
    coordsKey: 'destinationCoords',
    zoneKey: 'destinationGeoFence',
    additionalInfoKey: 'destinationAdditionalInfo',
    required: true,
    style: {
      flex: 1,
      minWidth: '50%',
    },
  },
  {
    name: 'destinationAdditionalInfo',
    label: 'Additional info',
    type: 'viewModeHelper',
    inputType: 'text',
    required: true,
  },
  {
    name: 'destinationGeoFence',
    hidden: false,
    label: 'Zone',
    type: 'input',
    inputType: 'text',
    //required: true,
  },
  {
    name: 'intermediaryPoints',
    label: 'Intermediary points',
    hidden: false,
    type: 'poiComponent',
    style: {
      flex: 1,
      minWidth: '50%',
    },
  },
  {
    name: 'courseMap',
    label: 'Course Map',
    type: 'courseMap',
    hidden: false,
    style: {
      flex: 1,
      minWidth: '50%',
    },
  },
  {
    name: 'type',
    hidden: false,
    type: 'select',
    label: 'Transport Type',
    required: false,
  },
  {
    name: 'idTariffService',
    hidden: false,
    label: 'Service',
    type: 'select',
    required: true,
  },
  {
    name: 'paymentTypeString',
    hidden: false,
    label: 'Payment',
    type: 'select',
    required: true,
  },
  {
    name: 'tariffString',
    hidden: false,
    label: 'Tariff',
    type: 'tariffCurrencyInput',
  },
  {
    name: 'blockTariff',
    hidden: false,
    label: 'Block tarriff',
    type: 'checkbox',
    checkboxType: 'column',
  },
  {
    name: 'currencyString',
    label: 'Currency',
    hidden: false,
    type: 'select',
    required: true,
    disabled: true,
  },
  {
    name: 'paymentStatusString',
    hidden: false,
    label: 'Payment status',
    type: 'select',
  },
  {
    name: 'idUser',
    label: 'Answered by',
    type: 'input',
    disabled: true,
    hidden: false,
    inputType: 'text',
  },
  {
    name: 'requestDateString',
    hidden: false,
    label: 'Requested on',
    type: 'date',
    required: true,
    disabled: true,
  },
  {
    name: 'taxBreakdownTransferType',
    hidden: false,
    label: 'Pricing transfer type',
    type: 'input',
    inputType: 'text',
    required: false,
    disabled: true,
  },
  {
    name: 'taxBreakdownOriginAgency',
    hidden: false,
    label: 'Origin Agency',
    type: 'input',
    inputType: 'text',
    required: false,
    disabled: true,
  },
  {
    name: 'initialComment',
    label: 'Initial comment',
    hidden: false,
    type: 'textarea',
  },
  {
    name: 'billedCustomer',
    label: 'Billed customer',
    type: 'input',
    hidden: false,
    inputType: 'text',
  },
  {
    name: 'customerAddress',
    label: 'Customer address',
    hidden: false,
    type: 'input',
    inputType: 'text',
  },
  {
    name: 'paymentNote',
    hidden: false,
    label: 'Payment note',
    type: 'input',
    inputType: 'text',
  },
  {
    name: 'invoiceNumber',
    hidden: false,
    label: 'Invoice number',
    type: 'input',
    inputType: 'text',
  },
  {
    name: 'commission',
    label: 'Commission',
    hidden: false,
    type: 'tariffCurrencyInput',
  },
  {
    name: 'blockCommission',
    label: 'Block commission',
    hidden: false,
    type: 'checkbox',
    checkboxType: 'column',
  },
  {
    name: 'taxBreakdown',
    label: 'Tariff Breakdown',
    hidden: false,
    type: 'breakdownInfo',
    companyType: TypeOfCompany.Client,
    style: {
      flex: 1,
      minWidth: '50%',
    },
  },
  {
    name: 'commissionBreakdown',
    label: 'Commission Breakdown',
    hidden: false,
    type: 'breakdownInfo',
    companyType: TypeOfCompany.Supplier,
    style: {
      flex: 1,
      minWidth: '50%',
    },
  },
  {
    name: 'freeCancellation',
    label: 'Free cancellation (hours)',
    hidden: false,
    type: 'input',
    inputType: 'number',
  },
  {
    name: 'passengerName',
    label: 'Name',
    hidden: false,
    type: 'input',
    inputType: 'text',
    required: true,
  },
  {
    name: 'language',
    label: 'Language',
    hidden: false,
    type: 'select',
  },
  {
    name: 'passengerPhone',
    label: 'Passenger phone',
    hidden: false,
    type: 'input',
    inputType: 'text',
  },
  {
    name: 'passengerEmail',
    label: 'Passenger email',
    hidden: false,
    type: 'input',
    inputType: 'email',
  },
  {
    name: 'otherPassengers',
    label: 'Other Passengers',
    hidden: false,
    type: 'passengersList',
    inputType: 'text',
    required: false,
  },
  {
    name: 'passengerReference',
    label: 'Passenger/Flight Reference',
    hidden: false,
    type: 'input',
    inputType: 'text',
  },
  {
    name: 'passengers',
    label: 'Number of passengers',
    hidden: false,
    type: 'input',
    inputType: 'number',
    required: true,
  },
  {
    name: 'vip',
    label: 'VIP',
    type: 'checkbox',
    checkboxType: 'column',
    hidden: false,
  },
  {
    name: 'assignmentBlocked',
    label: 'Assignment blocked',
    type: 'checkbox',
    hidden: false,
    checkboxType: 'column',
  },
  {
    name: 'driverNotification',
    label: 'Driver notification',
    hidden: false,
    type: 'textarea',
  },
  {
    name: 'greeter',
    title: 'Greeter Sign',
    type: 'composite',
    hidden: false,
    fields: [
      {
        name: 'greeterSignLogo',
        label: 'Logo',
        type: 'checkbox',
      },
      {
        name: 'greeterSignName',
        label: 'Name',
        type: 'checkbox',
      },
      {
        name: 'greeterSignOther',
        label: 'Other',
        type: 'input',
        inputType: 'text',
      },
    ],
  },
  {
    name: 'greeterSignActivations',
    hidden: false,
    label: 'Greeter Sign Activations',
    type: 'greeterSignList',
  },
  {
    name: 'hasUnsolvedIssues',
    title: 'Unsolved issues',
    label: 'Unsolved issues',
    hidden: false,
    type: 'composite',
    fields: [
      {
        name: 'hasUnsolvedIssues',
        label: 'Has unsolved issues',
        type: 'checkbox',
      },
    ],
  },
  {
    name: 'requestStatusString',
    hidden: false,
    label: 'Status',
    type: 'select',
  },
  {
    name: 'displaySubscriptions',
    label: 'Subscriptions found',
    hidden: false,
    type: 'displaySubscriptions',
    style: {
      flex: 1,
      minWidth: '70%'
    }
  },
  {
    name: 'searchSubscriptions',
    label: 'Search subscriptions',
    hidden: false,
    type: 'searchSubscriptions',
    style: {
      flex: 1,
      minWidth: '30%'
    }
  },
  {
    name: 'idTariffType',
    label: 'Service type',
    hidden: false,
    type: 'select',
  },
  {
    name: 'duration',
    label: 'Duration',
    hidden: false,
    type: 'durationInput',
    inputType: 'number',
  },
  {
    name: 'systemEstimatedDuration',
    hidden: false,
    label: 'System estimation duration',
    type: 'input',
    inputType: 'number',
  },
  {
    name: 'systemEstimatedDurationBreakdown',
    hidden: false,
    label: 'Breakdown',
    type: 'textarea',
  },
  {
    name: 'completeEstimateDateString',
    hidden: false,
    label: 'Complete estimate date',
    type: 'date',
  },
  {
    name: 'distance',
    label: 'Distance',
    type: 'input',
    hidden: false,
    inputType: 'number',
  },
  {
    name: 'stops',
    label: 'Stops',
    hidden: false,
    type: 'input',
    inputType: 'number',
  },
  {
    name: 'stopsCost',
    label: 'Stops Cost',
    hidden: false,
    type: 'currencyInput',
  },
  {
    name: 'wait',
    hidden: false,
    label: 'Wait',
    type: 'input',
    inputType: 'number',
  },
  {
    name: 'waitCost',
    label: 'Wait Cost',
    hidden: false,
    type: 'currencyInput',
  },
  {
    name: 'estimatedWait',
    label: 'Estimated wait',
    hidden: false,
    type: 'input',
    inputType: 'number',
    required: true,
  },
  {
    name: 'idVehicleAssigned',
    label: 'Assigned vehicle',
    hidden: false,
    type: 'select',
    isClearable: true,
  },
  {
    name: 'idDriverAssigned',
    hidden: false,
    label: 'Assigned driver',
    type: 'select',
    isClearable: true,
  },
  {
    name: 'transferTypeString',
    label: 'Transfer type',
    type: 'select',
    hidden: false,
  },
  {
    name: 'comment',
    label: 'Comment',
    hidden: false,
    type: 'textarea',
  },
  {
    name: 'requestRemarkString',
    label: 'Request remark',
    type: 'select',
    hidden: false,
  },
  {
    name: 'dispatchComment',
    type: 'composite',
    hidden: false,
    fields: [
      {
        name: 'dispatchComment',
        label: 'Dispatch comments',
        type: 'textarea',
      },
      {
        name: 'emailInputTime',
        type: 'paragraph',
        paragraphType: 'warning',
      },
      {
        name: 'analyzeTime',
        type: 'paragraph',
        paragraphType: 'success',
      },
      {
        name: 'toBeAcceptedTime',
        type: 'paragraph',
        paragraphType: 'success',
      },
    ],
  },
  {
    name: 'delayAnnounced',
    title: 'Announced delay',
    hidden: false,
    type: 'composite',
    style: {
      // width: '100%',
      // backgroundColor: 'red',
    },
    fields: [
      {
        name: 'delayAnnounced',
        label: 'Has announced delay',
        type: 'checkbox',
      },
      {
        name: 'delayAnnouncedTimestamp',
        type: 'paragraph',
      },
    ],
  },
  {
    name: 'announcedDelay',
    type: 'composite',
    label: 'Announced delay',
    hidden: false,
    fields: [
      {
        name: 'announcedDelay',
        label: 'Announced delay',
        type: 'input',
        inputType: 'number',
      },
      {
        name: 'realDelay',
        label: 'Real delay (min)',
        type: 'input',
        inputType: 'number',
      },
    ],
  },
  {
    name: 'responsible',
    type: 'composite',
    label: 'Responsible',
    hidden: false,
    fields: [
      {
        type: 'header',
        text: 'Assign responsible',
      },
      {
        name: 'assignResponsible',
        type: 'paragraph',
        paragraphType: 'info',
      },
      {
        type: 'header',
        text: 'Supervisor responsible',
      },
      {
        name: 'supervisorResponsible',
        type: 'paragraph',
        paragraphType: 'info',
      },
      {
        type: 'header',
        text: 'Checked by',
      },
      {
        name: 'checkedBy',
        type: 'paragraph',
        paragraphType: 'info',
      },
    ],
  },
  {
    name: 'assistanceVehiclePlateNumber',
    label: 'Plate number',
    hidden: false,
    type: 'selectWithAutocomplete',
    editable: true,
    propertyField: 'plateNumber',
    autocomplete: {
      assistanceVehicleVin: 'vin',
      assistanceVehicleBrand: 'brand',
      assistanceVehicleModel: 'model',
      assistanceVehicleType: 'type',
      assistanceVehicleManufactureYear: 'manufactureYear',
      assistanceVehicleDepartment: 'department',
      assistanceVehicleLocation: 'location',
      assistanceVehicleReplacementClass: 'replacementClass',
      assistanceVehicleReplacementDays: 'replacementDays',
      assistanceVehicleRoadAssistDescription: 'roadAssistDescription',
      assistanceVehicleDtdDescription: 'dtdDescription',
    },
    inputType: 'text',
  },
  {
    name: 'assistanceVehicleVin',
    label: 'VIN',
    hidden: false,
    type: 'input',
    inputType: 'text',
  },
  {
    name: 'assistanceVehicleBrand',
    label: 'Brand',
    hidden: false,
    type: 'select',
  },
  {
    name: 'assistanceVehicleModel',
    label: 'Model',
    hidden: false,
    type: 'select',
  },
  {
    name: 'assistanceVehicleType',
    hidden: false,
    label: 'Type',
    type: 'select',
  },
  {
    name: 'assistanceVehicleManufactureYear',
    label: 'Manufacture year',
    hidden: false,
    type: 'input',
    inputType: 'number',
  },
  {
    name: 'assistanceVehicleDepartment',
    label: 'Department',
    hidden: false,
    type: 'select',
  },
  {
    name: 'assistanceVehicleLocation',
    label: 'Location',
    type: 'input',
    hidden: false,
    inputType: 'text',
  },
  {
    name: 'assistanceVehicleReplacementClass',
    label: 'Replacement class',
    type: 'input',
    hidden: false,
    inputType: 'text',
  },
  {
    name: 'assistanceVehicleReplacementDays',
    label: 'Replacement days',
    type: 'input',
    hidden: false,
    inputType: 'number',
  },
  {
    name: 'assistanceVehicleRoadAssistDescription',
    label: 'Road assist description',
    type: 'input',
    hidden: false,
    inputType: 'text',
  },
  {
    name: 'assistanceVehicleDtdDescription',
    label: 'DTD Description',
    hidden: false,
    type: 'input',
    inputType: 'text',
  },
  {
    name: 'replacementVehiclePlateNumber',
    label: 'Replacement plate',
    hidden: false,
    labelType: 'danger',
    type: 'selectWithAutocomplete',
    editable: true,
    propertyField: 'plateNumber',
    inputType: 'text',
    autocomplete: {
      replacementVehicleBrand: 'brand',
      replacementVehicleModel: 'model',
    },
  },
  {
    name: 'replacementVehicleBrand',
    hidden: false,
    label: 'Brand',
    type: 'select',
  },
  {
    name: 'replacementVehicleModel',
    label: 'Model',
    hidden: false,
    type: 'select',
  },
  {
    name: 'replacementVehicleLocation',
    label: 'Location',
    hidden: false,
    type: 'input',
    inputType: 'text',
  },
  {
    name: 'replacementVehicleReturnEstimateDateString',
    label: 'Return estimate date',
    type: 'date',
    hidden: false,
    readOnly: true,
  },
  {
    name: 'applicantId',
    label: 'Applicants',
    hidden: false,
    type: 'select',
  },
  {
    name: 'denierId',
    label: 'Deniers',
    type: 'select',
    hidden: false,
  },
  {
    name: 'delayerDriver',
    hidden: false,
    label: 'Delayer driver',
    type: 'composite',
    fields: [
      {
        type: 'header',
        text: 'Delayed by Driver',
      },
    ],
  },
  {
    name: 'alert',
    hidden: false,
    label: 'Alert',
    type: 'composite',
    fields: [
      {
        type: 'header',
        text: 'Alert by driver',
      },
      {
        name: 'alertHistories',
        type: 'paragraph',
        paragraphType: 'danger',
      },
    ],
  },
  {
    name: 'startOdometer',
    hidden: false,
    label: 'Start Odometer',
    type: 'input',
    inputType: 'number',
  },
  { name: 'endOdometer', hidden: false, label: 'End Odometer', type: 'input', inputType: 'number' },
  {
    name: 'driverKm',
    hidden: false,
    label: 'Driver KM',
    type: 'input',
    inputType: 'text',
    disabled: true,
  },
  { name: 'gpsKm', hidden: false, label: 'GPS KM', type: 'input', inputType: 'number' },
  { name: 'startTime', hidden: false, label: 'Start Time', type: 'date' },
  { name: 'endTime', hidden: false, label: 'End Time', type: 'date' },
  {
    name: 'googleReadyDistance',
    hidden: false,
    label: 'Google Ready distance',
    type: 'input',
    inputType: 'number',
  },
  {
    name: 'gpsReadyDistance',
    hidden: false,
    label: 'GPS Ready distance',
    type: 'input',
    inputType: 'number',
  },
  { name: 'driverTransportStops', hidden: false, label: 'Driver Stops', type: 'stopsList' },
  { name: 'systemTransportStops', hidden: false, label: 'System Stops', type: 'stopsList' },
  {
    name: 'gpsMonitored',
    hidden: false,
    label: 'GPS Monitored',
    type: 'checkbox',
    checkboxType: 'column',
  },
  {
    name: 'emissions',
    hidden: false,
    label: 'Emissions',
    type: 'input',
    inputType: 'text',
    required: false,
    disabled: true,
  },
  {
    name: 'photos',
    hidden: false,
    label: 'Transport Photos',
    type: 'transportPhotos',
    style: {
      flex: 1,
      minWidth: '100%',
    },
  },
  {
    name: 'pvs',
    hidden: false,
    label: 'Transport Pvs',
    type: 'transportPvs',
    style: {
      flex: 1,
      minWidth: '100%',
    },
  },
  {
    name: 'rentalPeriod',
    hidden: false,
    label: 'Rental Period',
    type: 'input',
    inputType: 'number',
    disabled: true,
    addOns: { append: 'days' }
  },
  {
    name: 'vehicleClass',
    hidden: false,
    label: 'Vehicle Class',
    type: 'select',
  },
  {
    name: 'extraOptions',
    hidden: false,
    label: 'Extras',
    type: 'optionsSelect',
    isMulti: true,
    style: {
      flex: 1,
      minWidth: '70%'
    }
  },
  {
    name: 'dislocationType',
    hidden: false,
    label: 'Dislocation Type',
    readOnly: true,
    type: 'input',
    style: {
      flex: 1,
      minWidth: '100%',
    }
  },
  {
    name: 'insurance',
    hidden: false,
    label: 'Insurance',
    type: 'select',
  },
  {
    name: 'insuranceType',
    hidden: false,
    label: 'Insurance Type',
    type: 'select',
    isMulti: true
  },
  {
    name: 'discount',
    hidden: false,
    label: 'Discount',
    type: 'input',
    inputType: 'number',
    addOns: { prepend: '%' },
  },
]

import { KTSVG } from '../../../../../_metronic/helpers'
import { CarClassModel } from '../../../auth/models/CarClassModel'

type TableHeaderProps = {
  cars: CarClassModel[]
  setCurrentCar: any
  setShowCarModal: any
}

const TableHeader: React.FC<TableHeaderProps> = ({ cars, setCurrentCar, setShowCarModal}) => {

  return (
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column mb-6'>
        <span className='card-label fw-bolder fs-3 mb-1'>All car classes</span>
        <span className='text-muted mt-1 fw-bold fs-7'>A total of {cars.length} car classes</span>
      </h3>
      <div
        className='card-toolbar'
        data-bs-toggle='tooltip'
        data-bs-placement='top'
        data-bs-trigger='hover'
        title='Click to add a car class'
      >
        <button
          onClick={() => {
            setCurrentCar(undefined)
            setShowCarModal(true)
          }}
          className='btn btn-sm btn-light-primary'
          data-bs-toggle='modal'
          data-bs-target='#kt_modal_invite_friends'
        >
          <KTSVG path='media/icons/duotone/Navigation/Plus.svg' className='svg-icon-3' />
          New car class
        </button>
      </div>
    </div>
  )
}

export default TableHeader
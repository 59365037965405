import { toast } from 'react-toastify'
import { CarClassModel } from '../../app/modules/auth/models/CarClassModel'
import { RentCompanyPlanModel } from '../../app/modules/rent-plans/models/RentCompanyPlanModel'
import { RentCompanyPricingPlanModel } from '../../app/modules/rent-plans/models/RentCompanyPricingPlanModel'
import { RentPlanDayCostsModel } from '../../app/modules/rent-plans/models/RentPlanDayCostsModel'
import { RentPlanDislocationCostsModel } from '../../app/modules/rent-plans/models/RentPlanDislocationCostsModel'
import { RentPlanExtraCostsModel } from '../../app/modules/rent-plans/models/RentPlanExtraCostsModel'
import { RentPlanCompleteInsuranceModel, RentPlanInsuranceCreateModel, RentPlanInsuranceModel } from '../../app/modules/rent-plans/models/RentPlanInsuranceModel'
import { RentPlanIntervalModel } from '../../app/modules/rent-plans/models/RentPlanIntervalModel'
import { RentPlanTariffsModel } from '../../app/modules/rent-plans/models/RentPlanTariffsModel'
import { RentPlanVehicleClassModel } from '../../app/modules/rent-plans/models/RentPlanVehicleClassModel'
import { IntervalData } from '../../context/plan.context'
import { orbitRequest, RequestMethod } from '../../utils/api.utils'
import axios from 'axios'
import { API_URL } from '../../constants/api.constants'
import { RentPlanDataType } from '../../app/modules/add-edit-transport/scripts/tariffUtils'
import moment from 'moment'

export const getRentPlanByClientId = async (clientId: number | string) => {
  const response = await orbitRequest({
    route: `/rent-company-price-plans/company-latest-price-plan?client_id=${clientId}`,
    method: RequestMethod.GET
  })
  if (!response || response.error) {
    toast.error(
      `Couldn't find a plan for the given client! ${response.message}`
    )
  }
  const futurePlan = response?.plan
  return futurePlan
}

export const getTransportRentInfo = async (transportRequestId: number): Promise<RentPlanDataType | null> => {
  const response = await orbitRequest({
    route: `/rent-requests?transport_request_id=${transportRequestId}`,
    method: RequestMethod.GET
  })
  if (!response?.rent_request || response.error ) {
    toast.error(
      `Couldn't find rent info for the given transport request! ${response?.message}`
    )
    return null
  }
  const futureRentInfo = response.rent_request;
  // exchange_rate should always be numerical (float)
  futureRentInfo.exchange_rate = Boolean(futureRentInfo.exchange_rate) ? parseFloat('' + futureRentInfo.exchange_rate) : 0
  return futureRentInfo
}

export const getTransportRentInfoMultiple = async (transportRequestIds: (number | string)[]) => {
  if (transportRequestIds.length === 0) {
    return {}
  }
  const transportRequestIdsPayload = transportRequestIds.join(',');
  const response = await orbitRequest({
    route: `/rent-requests/get-multiple?reduced=1&transport_request_ids=${transportRequestIdsPayload}`,
    method: RequestMethod.GET
  })
  if (!response || response.error) {
    toast.error(`There was a problem while getting rent data for transports! ${response.message}`)
  }
  return response?.transports_rent_data || {}
}

export const checkTransportRentInfo = async (rentPlanData: RentPlanDataType) => {
  const testTransportRequestId = 0;
  const response = await orbitRequest({
    route: '/rent-requests/create-update',
    payload: { ...rentPlanData, transport_request_id: testTransportRequestId, verify: true },
    method: RequestMethod.POST
  })
  if (!response || response.error) {
    toast.error(
      `Transport rent info can't be saved! ${response.message}`
    )
    return { error: true }
  } else {
    return { error: false }
  }
}

export const saveOrEditTransportRentInfo = async ({ transportRequestId, rentPlanData }: { transportRequestId: number, rentPlanData: RentPlanDataType }) => {
  const response = await orbitRequest({
    route: '/rent-requests/create-update',
    payload: { ...rentPlanData, transport_request_id: transportRequestId },
    method: RequestMethod.POST
  })
  if (!response || response.error) {
    toast.error(
      `Transport rent info couldn't be saved! ${response.message}`
    )
    return { error: true }
  } else {
    toast.success("Transport rent info successfully saved!")
    return { error: false }
  }
}

export const getCarClasses = async (): Promise<CarClassModel[] | null> => {
  const response = await orbitRequest({ route: `/vehicle-classes`, method: RequestMethod.GET }).catch(
    (err) => err
  )
  if (!response || response.error) {
    toast.error(
      response.message
    )
  }
  return response.vehicle_classes
}

export const getRentCompanyPricePlans = async (companyId: string | number): Promise<RentCompanyPlanModel[] | null> => {
  const response = await orbitRequest({ route: `/rent-company-price-plans/company-plans?company_id=${companyId}`, method: RequestMethod.GET }).catch(
    (err) => err
  )
  if (!response || response.error) {
    toast.error(
      response.message
    )
  }
  return response.company_plans;
}

export const getRentCompanyPricePlan = async (plan_id: number): Promise<RentCompanyPlanModel | null> => {
  const response = await orbitRequest({ route: `/rent-company-price-plans/company-price-plan?plan_id=${plan_id}`, method: RequestMethod.GET }).catch(
    (err) => err
  )
  if (!response || response.error) {
    toast.error(
      response.message
    )
  }
  return response.rent_company_price_plan;
}

export const getRentPlanExtraCosts = async (planId: number): Promise<RentPlanExtraCostsModel[] | null> => {
  const response = await orbitRequest({ route: `/rent-plan-extra-costs?plan_id=${planId}`, method: RequestMethod.GET }).catch(
    (err) => err
  )
  if (!response || response.error) {
    toast.error(
      response.message
    )
  }
  return response.plan_extra_costs
}


export const getRentPlanDayCosts = async (planId: number): Promise<RentPlanDayCostsModel[] | null> => {
  const response = await orbitRequest({ route: `/rent-plan-day-cost?plan_id=${planId}`, method: RequestMethod.GET }).catch(
    (err) => err
  )
  if (!response || response.error) {
    toast.error(
      response.message
    )
  }
  return response.rent_plan_day_costs
}

export const getRentPlanInterval = async (planId: number): Promise<RentPlanIntervalModel[] | null> => {
  const response = await orbitRequest({ route: `/rent-plan-intervals?plan_id=${planId}`, method: RequestMethod.GET }).catch(
    (err) => err
  )
  if (!response || response.error) {
    toast.error(
      response.message
    )
  }
  return response.plan_intervals
}

export const getRentPlanTarrifs = async (planId: number): Promise<RentPlanTariffsModel[] | null> => {
  const rentPlanDayCosts = await getRentPlanDayCosts(planId);
  const rentPlanIntervals = await getRentPlanInterval(planId);
  const rentPlanVehiclesClass = await getRentPlanVehicleClass(planId);

  return [];
}

export const getRentPlanDislocationCosts = async (planId: number): Promise<RentPlanDislocationCostsModel[] | null> => {
  const response = await orbitRequest({ route: `/rent-plan-dislocation-costs?plan_id=${planId}`, method: RequestMethod.GET }).catch(
    (err) => err
  )
  if (!response || response.error) {
    toast.error(
      response.message
    )
  }
  return response.plan_dislocation_costs
}

export const getRentPlanInsurance = async (planId: number): Promise<RentPlanInsuranceModel[] | null> => {
  const response = await orbitRequest({ route: `/rent-plan-insurances?plan_id=${planId}`, method: RequestMethod.GET }).catch(
    (err) => err
  )
  if (!response || response.error) {
    toast.error(
      response.message
    )
  }
  return response.rent_plan_insurances
}

export const getRentPlanInsurancesComplete = async (planId: number): Promise<RentPlanCompleteInsuranceModel[] | null> => {
  const response = await orbitRequest({
    route: `/rent-plan-insurances/get-complete-insurances?plan_id=${planId}`,
    method: RequestMethod.GET,
  }).catch(
    (err) => err
  )
  if (!response || response.error) {
    toast.error(
      response.message
    )
  }
  return response.rent_plan_insurances
}

export const getRentPlanVehicleClass = async (planId: number): Promise<RentPlanVehicleClassModel[] | null> => {
  const response = await orbitRequest({ route: `/rent-plan-vehicle-classes?plan_id=${planId}`, method: RequestMethod.GET }).catch(
    (err) => err
  )
  if (!response || response.error) {
    toast.error(
      response.message
    )
  }
  return response.plan_vehicle_class
}

export const saveCarClass = async (carClass: CarClassModel) => {
  const formData = new FormData()
  carClass.id && formData.append('id', carClass.id.toString())
  formData.append('name', carClass.name)
  formData.append('description', carClass.description)
  formData.append('vehicle_type', carClass.vehicle_type)
  formData.append('image_url', carClass.image_url)
  formData.append('number_of_doors', carClass.number_of_doors.toString())
  formData.append('luggage_capacity', carClass.luggage_capacity.toString())
  formData.append('fuel_type', JSON.stringify(carClass.fuel_type))
  formData.append('transmission_type', JSON.stringify(carClass.transmission_type))

  const token = window.localStorage['jwt'] ? JSON.parse(window.localStorage['jwt']) : null;
  try {

    const res = await axios({
      method: "post",
      url: `${API_URL}/vehicle-classes/${carClass.id > 0 ? 'update' : 'create'}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    if (!res?.data || res?.data?.error) {
      toast.error(`'The carClass class could not be saved!' ${res?.data.message}`)
      return { error: true }
    }
    toast.success(`${carClass.id ? 'Informațiile generale au fost modificate!' : 'Informațiile generale au fost adăugate!'}`)
    return { error: false, payload: res?.data.vehicle_class }
  }
  catch (err) {
    toast.error(err?.response?.data?.message || 'Something went wrong')
    return { error: true }
  }
}

export const deleteCarClass = async (carClass: CarClassModel) => {
  const response = await orbitRequest({
    route: `/vehicle-classes/delete`,
    method: RequestMethod.POST,
    payload: { id: carClass.id },
  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
  }
  return true
}

export const deleteRentExtraCosts = async (id: number) => {
  const response = await orbitRequest({
    route: `/rent-plan-extra-costs/delete`,
    method: RequestMethod.POST,
    payload: { id },
  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const deleteRentDislocationCosts = async (id: number) => {
  const response = await orbitRequest({
    route: `/rent-plan-dislocation-costs/delete`,
    method: RequestMethod.POST,
    payload: { id },
  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const deleteRentIncludedKm = async (id: number) => {
  const response = await orbitRequest({
    route: `/rent-plan-vehicle-classes/delete`,
    method: RequestMethod.POST,
    payload: { id },
  })
  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const deleteRentResponse = async (id: number) => {
  const response = await orbitRequest({
    route: `/rent-plan-insurances/delete`,
    method: RequestMethod.POST,
    payload: { id },
  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const deleteRentPlanDayCost = async (id: number) => {
  const response = await orbitRequest({
    route: `/rent-plan-day-cost/delete`,
    method: RequestMethod.POST,
    payload: { id },
  })
  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const deleteRentPlanInterval = async (id: number) => {
  const response = await orbitRequest({
    route: `/rent-plan-intervals/delete`,
    method: RequestMethod.POST,
    payload: { id },
  })
  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const deleteRentVehicleClassOnly = async (id: number) => {
  const response = await orbitRequest({
    route: `/rent-plan-vehicle-classes/delete`,
    method: RequestMethod.POST,
    payload: { id },
  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const deleteRentTariffs = async (id: number) => {
  const rentPlanDayCosts = await deleteRentPlanDayCost(id)

  if (!rentPlanDayCosts) {
    return null
  }
  return true
}

export const deleteRentVehicleClass = async (id: number) => {
  const rentVehicleClass = await deleteRentVehicleClassOnly(id)

  if (!rentVehicleClass) {
    return null
  }
  return true
}

export const putRentExtraCosts = async (extra: RentPlanExtraCostsModel, company_id: string) => {
  const pickup_time = (typeof (extra.pickup_time) !== 'string' && moment(extra.pickup_time).isValid()) ? extra.pickup_time.format('YYYY-MM-DDTHH:mm:ss') : extra.pickup_time;
  const delivery_time = (typeof (extra.delivery_time) !== 'string' && moment(extra.delivery_time).isValid()) ? extra.delivery_time.format('YYYY-MM-DDTHH:mm:ss') : extra.delivery_time;
  const is_session_option_value = extra.is_applicable === 'per rental' ? 1 : 0;

  const response = await orbitRequest({
    route: `/rent-plan-extra-costs/update`,
    method: RequestMethod.POST,
    payload: { ...extra, company_id, is_session_option: is_session_option_value, pickup_time, delivery_time }
  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const putRentDislocationCosts = async (
  plan_dislocation_costs: RentPlanDislocationCostsModel,
  company_id: string
) => {
  const response = await orbitRequest({
    route: `/rent-plan-dislocation-costs/update`,
    method: RequestMethod.POST,
    payload: { ...plan_dislocation_costs, company_id },
  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const putRentIncludedKm = async (plan_vehicle_class: RentPlanVehicleClassModel) => {
  const response = await orbitRequest({
    route: `/rent-plan-vehicle-classes/update`,
    method: RequestMethod.POST,
    payload: plan_vehicle_class,
  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const putRentVehicleClass = async (plan_vehicle_class: RentPlanVehicleClassModel) => {
  const response = await orbitRequest({
    route: `/rent-plan-vehicle-classes/update`,
    method: RequestMethod.POST,
    payload: plan_vehicle_class,
  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const putRentPlanResponse = async (rent_plan_insurances: RentPlanInsuranceCreateModel, plan_id: number) => {
  const response = await orbitRequest({
    route: `/rent-plan-insurances/update?plan_id=${plan_id}`,
    method: RequestMethod.POST,
    payload: rent_plan_insurances,
  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const putRentPlanDayCosts = async (rent_plan_day_costs: RentPlanIntervalModel) => {
  const response = await orbitRequest({
    route: `/rent-plan-day-cost/update`,
    method: RequestMethod.POST,
    payload: rent_plan_day_costs,
  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const putRentPlanIntervals = async (plan_intervals: RentPlanIntervalModel) => {
  const response = await orbitRequest({
    route: `/rent-plan-intervals/update`,
    method: RequestMethod.POST,
    payload: plan_intervals,
  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const putRentTariffs = async (rent_plan_day_costs: any) => {
  const rentPlanDayCosts = await putRentPlanDayCosts(rent_plan_day_costs);

  if (!rentPlanDayCosts) {
    return null;
  }
  return true
}

export const createRentCompanyPricePlan = async (rent_company_price_plan: RentCompanyPricingPlanModel, companyId: string) => {
  const response = await orbitRequest({
    route: `/rent-company-price-plans/create`,
    method: RequestMethod.POST,
    payload: {
      ...rent_company_price_plan, company_id: companyId
    }
  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return response.rent_company_price_plan;

}

export const updateRentCompanyPricePlan = async (plan: RentCompanyPlanModel) => {
  const response = await orbitRequest({
    route: `/rent-company-price-plans/update`,
    method: RequestMethod.POST,
    payload: plan,
  })
  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }

  return response.rent_company_price_plan
}

export const getIntervalByStartDay = async (start_day: string, plan_id: number) => {
  const response = await orbitRequest({
    route: `/rent-plan-intervals/getIntervalByStartDay?start_day=${start_day}&plan_id=${plan_id}`,
    method: RequestMethod.GET
  })
  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }

  return response.interval
}

export const deleteTariffRow = async (
  plan_vehicle_class_id: number,
  intervals_array: IntervalData[],
  plan_id: number,
  company_id: string
) => {
  let costPromies = [];
  intervals_array.forEach((intervalData) => {
    costPromies = costPromies.concat([putRentTariffs({
      company_id,
      plan_id,
      interval_id: intervalData.id,
      plan_vehicle_class_id: plan_vehicle_class_id,
      cost: 0
    })]);
  });

  if (!costPromies.length) {
    return null
  }
  Promise.all(costPromies);
  return true
}

export const updatePlanAndTariffsTable = async (
  plan: RentCompanyPlanModel,
  rent_plan_intervals: RentPlanIntervalModel[],
  intervals_array: IntervalData[],
  initial_tariffs: RentPlanIntervalModel[],
  setInitialTariffs: (initial_tariffs: RentPlanIntervalModel[]) => void,
  plan_id: number,
  company_id: string
) => {
  const updatedPlan = await updateRentCompanyPricePlan(plan);

  let costPromies = [];

  if (rent_plan_intervals && intervals_array) {
    rent_plan_intervals.forEach((intervalsWithClass, classIdx) => {
      intervals_array.forEach((intervalData) => {
        const oldCost = initial_tariffs[classIdx][intervalData.intervalDays];
        const cost = intervalsWithClass[intervalData.intervalDays];
        if (oldCost === cost) return;
        const vehicleClassId = intervalsWithClass.id;
        costPromies = costPromies.concat([putRentTariffs({
          company_id,
          plan_id,
          interval_id: intervalData.id,
          plan_vehicle_class_id: vehicleClassId,
          cost: cost
        })]);
      });
    });
  }
  setInitialTariffs(rent_plan_intervals);
  if (costPromies.length) {
    toast.success("The plan tariffs costs were successfully updated");
  }
  else if (updatedPlan) {
    toast.success("The plan was successfully updated");
  }
  if (!costPromies.length) {
    return null;
  }
  Promise.all(costPromies);
  return true
}

export const createRentPlanExtraCosts = async (rent_plan_extra_cost: RentPlanExtraCostsModel, planId: number, companyId: string) => {
  const is_session_option_value = rent_plan_extra_cost.is_applicable === 'per rental' ? 1 : 0;
  const pickup_time = moment(rent_plan_extra_cost.pickup_time).isValid() ? rent_plan_extra_cost.pickup_time.format('YYYY-MM-DDTHH:mm:ss') : rent_plan_extra_cost.pickup_time;
  const delivery_time = moment(rent_plan_extra_cost.delivery_time).isValid() ? rent_plan_extra_cost.delivery_time.format('YYYY-MM-DDTHH:mm:ss') : rent_plan_extra_cost.delivery_time;

  const response = await orbitRequest({
    route: `/rent-plan-extra-costs/create?plan_id=${planId}`,
    method: RequestMethod.POST,
    payload: {
      ...rent_plan_extra_cost, company_id: companyId, is_session_option: is_session_option_value, pickup_time, delivery_time
    }
  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const createRentPlanDislocationCosts = async (planId: number, companyId: string, plan_dislocation_costs?: RentPlanDislocationCostsModel) => {
  const response = await orbitRequest({
    route: `/rent-plan-dislocation-costs/create?plan_id=${planId}`,
    method: RequestMethod.POST,
    payload: { ...plan_dislocation_costs, company_id: companyId }
  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const createRentAutoClasses = async (plan_vehicle_class: RentPlanVehicleClassModel, planId: number, companyId: string) => {
  const carClasses = await getCarClasses();
  const desiredClass = carClasses.find(carClass => carClass.name === plan_vehicle_class.name);
  desiredClass.fuel_type = desiredClass.fuel_type;
  desiredClass.transmission_type = desiredClass.transmission_type;
  const response = await orbitRequest({
    route: `/rent-plan-vehicle-classes/create?plan_id=${planId}&company_id=${companyId}`,
    method: RequestMethod.POST,
    payload: desiredClass
  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}


export const createRentIncludedKm = async (plan_vehicle_class: RentPlanVehicleClassModel, planId: number) => {
  const response = await orbitRequest({
    route: `/rent-plan-vehicle-classes/create?plan_id=${planId}`,
    method: RequestMethod.POST,
    payload: plan_vehicle_class

  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const createRentPlanResponse = async (rent_plan_insurances: RentPlanInsuranceCreateModel, planId: number, companyId: string) => {
  const response = await orbitRequest({
    route: `/rent-plan-insurances/create?plan_id=${planId}`,
    method: RequestMethod.POST,
    payload: { ...rent_plan_insurances, company_id: companyId, plan_id: planId }
  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const createRentPlanDayCosts = async (rent_plan_day_costs: RentPlanDayCostsModel, planId: number, companyId: string) => {
  const response = await orbitRequest({
    route: `/rent-plan-day-cost/create?plan_id=${planId}`,
    method: RequestMethod.POST,
    payload: { ...rent_plan_day_costs, company_id: companyId, interval_id: "1" }
  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const createRentPlanIntervals = async (plan_intervals: RentPlanIntervalModel, planId: number, companyId: string) => {
  const response = await orbitRequest({
    route: `/rent-plan-intervals/create?plan_id=${planId}`,
    method: RequestMethod.POST,
    payload: { ...plan_intervals, company_id: companyId }
  })

  if (!response || response.error) {
    toast.error(
      response.message
    )
    return false;
  }
  return true
}

export const cloneRentCompanyPricePlan = async ({
  planId,
  name,
  cloneForCompanies
}: {
  planId: number,
  name: string,
  cloneForCompanies: number[]
}) => {
  const response = await orbitRequest({
    route: `/rent-company-price-plans/clone`,
    method: RequestMethod.POST,
    payload: {
      plan_id_for_clone: planId,
      name,
      clone_for_companies: cloneForCompanies,
    }
  })

  const newPlanIds = response.new_plan_ids || []
  if (!response || response.error) {
    toast.error(response.message)
    return { error: true }
  }
  if (!Array.isArray(newPlanIds) || newPlanIds.length === 0) {
    toast.error("Cloning failed! We couldn't retrieve any cloned plan!")
    return { error: true }
  }
  return { error: false, clonedPlanIds: response.new_plan_ids || [] }
}

import {CarModel} from '../../app/modules/auth/models/CarModel'
import {orbitRequest, RequestMethod} from '../../utils/api.utils'

//  Get all cars or only one if request contains id
export const getCarsRequest = ({id}: {id?: string}) => {
  return orbitRequest({route: `/car${id ? `?id=${id}` : ''}`, method: RequestMethod.GET})
}

// Create new car
export const createCarRequest = ({car}: {car: CarModel}) => {
  const requestData = {
    carNumber: car.carNumber,
    brand: car.brand,
    model: car.model,
    vin: car.vin,
    phoneNumber: car.phoneNumber,
    trackNumber: car.trackNumber,
    location: car.location,
  }
  return orbitRequest({route: `/car/create`, payload: requestData, method: RequestMethod.POST})
}

// Update new car
export const updateCarRequest = ({car}: {car: CarModel}) => {
  const requestData = {
    id: car.id,
    carNumber: car.carNumber,
    brand: car.brand,
    model: car.model,
    vin: car.vin,
    phoneNumber: car.phoneNumber,
    trackNumber: car.trackNumber,
    location: car.location,
  }
  return orbitRequest({route: `/car/update`, payload: requestData, method: RequestMethod.POST})
}

// Delete car
export const deleteCarRequest = ({id}: {id: number}) => {
  return orbitRequest({route: `/car/delete`, payload: {id}, method: RequestMethod.POST})
}
// Send Car actions
export const sendCarActionRequest = ({phone, action}: {phone: string; action: string}) => {
  return orbitRequest({
    route: `/sms/send`,
    payload: {
      phone,
      action,
    },
    method: RequestMethod.POST,
  })
}

import { Link } from "react-router-dom";
import { KTSVG } from "../../_metronic/helpers/components/KTSVG";

type TooltipComponentBaseProps = {
  title: string,
  iconPath: string,
  iconClassName?: string
}

type CommonTooltipComponentProps = TooltipComponentBaseProps
  & { onClick?: React.MouseEventHandler<HTMLDivElement> }

type TooltipLinkProps = TooltipComponentBaseProps
  & { to: string }

const CommonTooltipComponent = ({
  title,
  iconPath,
  iconClassName = '',
  onClick = () => { }
}: CommonTooltipComponentProps) => (
  <div
    data-bs-toggle='tooltip'
    data-bs-boundary='window'
    data-bs-placement='top'
    title={title}
    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm m-1'
    onClick={onClick}
  >
    <KTSVG path={`/media/icons/duotone${iconPath}`} className={`svg-icon-1 ${iconClassName || ''}`} />
  </div>
);

export const TooltipButton = ({ title, iconPath, onClick, iconClassName }: CommonTooltipComponentProps) => (
  <CommonTooltipComponent title={title} iconPath={iconPath} onClick={onClick} iconClassName={iconClassName} />
);

export const TooltipLink = ({ title, iconPath, iconClassName, to }: TooltipLinkProps) => (
  <Link to={to}>
    <CommonTooltipComponent title={title} iconPath={iconPath} iconClassName={iconClassName} />
  </Link>
);
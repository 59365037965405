import { toast } from "react-toastify"
import { FieldType } from "./field.utils"
import { FlexibleObject } from "./state.utils"

export const downloadObjectAsJson = (exportObj, exportName) => {
  let dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj))
  let downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)
  downloadAnchorNode.setAttribute('download', exportName + '.json')
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

// clone
export const clone = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

export const changeValueForDocumentElement = ({
  elementId,
  value
}: {
  elementId: string,
  value: any
}) => {
  const fileInput = document.getElementById(elementId) as HTMLInputElement;
  fileInput.value = value;
}

export const validateFields = (fields: FieldType[], objectToCheck: FlexibleObject): boolean => {
  for (const field of fields) {
    if (field.required && !objectToCheck[field.name]) {
      toast.error(`Missing value for ${field.label}`)
      return false
    }
  }
  return true
}
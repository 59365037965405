import moment from "moment"
import { FlexibleObject } from "./state.utils"

const timeStampKeyFormat = "DD-MM-YYYYTHH:mm"

export const getLocalStorageItem = (key: string) => {
  if (!Boolean(key)) {
    return undefined
  }
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(key)
    return Boolean(item) ? JSON.parse(item) : undefined
  } catch (error) {
    console.error(error)
    return undefined
  }
}

export const setLocalStorageItem = <T>(key: string, value: T) => {
  if (!Boolean(key)) {
    return false
  }
  try {
    // Save to local storage
    window.localStorage.setItem(key, JSON.stringify(value))
    return true
  } catch (error) {
    // A more advanced implementation would handle the error case
    console.error(error)
    return false
  }
}

const cleanPeriodicRequestValues = async ({ requestKey }) => {
  const currentKeyDate = moment()

  let requestsDict: FlexibleObject = getLocalStorageItem(requestKey) || {}
  // keep only the data that we need for the requests
  const futureRequestDict: FlexibleObject = Object.entries(requestsDict).reduce((futureRequestDict, [key, value]) => {
    const keyDate = moment(key, timeStampKeyFormat)
    // at most two minutes distance between the current date and the next date
    const diffInMinuts = currentKeyDate.diff(keyDate, "minutes")
    if (diffInMinuts <= 2) {
      futureRequestDict[key] = value
    }
    return futureRequestDict
  }, {})

  setLocalStorageItem(requestKey, futureRequestDict)
}

export const getPeriodicResponseFromLocalStorage = async ({
  easytrackRequest,
  requestKey,
  responseParam,
}: {
  easytrackRequest: () => Promise<any>
  requestKey: string
  responseParam?: string
}): Promise<any> => {
  // save a timestamp for localStorage to keep the response for a given time
  const timeStampKey = moment().format(timeStampKeyFormat)
  // get the data from localStorage and check if we have what we need. If not, make a request and store the data for the given timestamp
  let requestsDict: FlexibleObject = getLocalStorageItem(requestKey) || {}
  if (!requestsDict.hasOwnProperty(timeStampKey)) {
    const futureRequestResponse = await easytrackRequest()
    const futureData = Boolean(responseParam) ? futureRequestResponse[responseParam] : futureRequestResponse
    requestsDict[timeStampKey] = futureData
    
    setLocalStorageItem(requestKey, requestsDict)
  }
  // clean the localStorage as well in order to not keep data older than 3 minutes, because we don't need it
  cleanPeriodicRequestValues({ requestKey })
  return requestsDict[timeStampKey]
}
import {UserModel} from '../../app/modules/auth/models/UserModel'
import {orbitRequest, RequestMethod} from '../../utils/api.utils'

//  Get all Users or only one if request contains id
export const getUsersRequest = ({
  id,
  currentPage = 1,
  resultsPerPage = 1,
  company_id,
}: {
  id?: string
  currentPage?: number
  resultsPerPage?: number
  company_id?: number
}) => {
  return orbitRequest({
    route: `/admin/users/getUsers?page=${currentPage}&resultsPerPage=${resultsPerPage}${
      id ? `&id=${id}` : ''
    }${company_id ? `&company_id=${company_id}` : ''}`,
    method: RequestMethod.POST,
  })
}

// Create new user
export const createUserRequest = ({user}: {user: UserModel}) => {
  const requestData = {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    role: user.role,
    status: user.status,
    password: user.password,
    password_confirmation: user.passwordConfirmation,
    company_id: user.company_id || null,
  }

  return orbitRequest({
    route: '/user/register',
    method: RequestMethod.POST,
    payload: requestData,
  })
  // customAxios.post(`${API_URL}/user/create`, requestData).catch((err) => err)
}

// Update new user
export const updateUserRequest = ({user}: {user: any}) => {
  const requestData = {
    id: user.id,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    role: user.role,
    status: user.status,
    password: user.password,
    password_confirmation: user.passwordConfirmation,
    company_id: user.company_id || null,
  }
  return orbitRequest({
    route: '/user/update',
    method: RequestMethod.POST,
    payload: requestData,
  })
  // customAxios.post(`${API_URL}/user/update`, requestData).catch((err) => err)
}

// Delete user
export const deleteUserRequest = ({id}: {id: number}) => {
  return orbitRequest({
    route: '/user/deleteUser',
    method: RequestMethod.POST,
    payload: {id},
  })
  // customAxios.delete(`${API_URL}/user/delete?id=${id}`).catch((err) => err)
}

export const getSubscriptionCompaniesRequest = () => {
  return orbitRequest({route: '/company', method: RequestMethod.GET})
  // customAxios.get(`${API_URL}/company`).catch((err) => err)
}

export const getCompanies = async ({ mainCompanyId, onlyMainCompany, businessProfileId }: { mainCompanyId?: number, onlyMainCompany?: number, businessProfileId?: number | string }) => {
  const mainCompanyIdArg = mainCompanyId ? `?main_company_id=${mainCompanyId}` : ''
  const onlyMainCompanyArg = onlyMainCompany ? `?only_main_companies=${onlyMainCompany}` : ''
  const businessProfileIdArg = businessProfileId ? `&business_profile_id=${businessProfileId}` : ''
  const response = await orbitRequest({
    route: `/company${mainCompanyIdArg}${onlyMainCompanyArg}${businessProfileIdArg}`,
    method: RequestMethod.GET
  })

  if (!response || response.error) {
    return { message: response?.message, error: true, companies: [] }
  }

  if (response?.companies) {
    return response
  }

  return { error: false, companies: response?.response?.data || [] }

}

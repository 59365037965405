/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { CompanyContext } from '../../../../context/company.context'
import { getSortedList } from '../../../../utils/sort.utils'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Link, useHistory, useParams } from 'react-router-dom'
import { CompanyType, ConglomerateCompaniesType } from '../../../../types/company.types'
import Loading from '../../../../_metronic/layout/components/loading/Loading'
import { DeleteModal } from '../../../../components/delete-modal/DeleteModal.component'
import { UserContext, UserRole } from '../../../../context/user.context'
import Pagination from '../../transport-requests/Pagination'
import InputField from '../../../../_metronic/layout/components/fields/input/input-field'
import Select from '../../../../_metronic/layout/components/select/Select'

type CompanyRowProps = {
  company: CompanyType
  deleteCompany: any,
  companyId: number;
}

type Props = {
  className: string
}

const ComapanyRow: React.FC<CompanyRowProps> = ({
  company,
  deleteCompany,
  companyId
}) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
  const history = useHistory()

  return (
    <>
      <DeleteModal
        deleteModalVisible={deleteModalVisible}
        setDeleteModalVisible={setDeleteModalVisible}
        deleteMethod={() => { deleteCompany({ id: company.id }); setDeleteModalVisible(false); }}
        textModal={`The company ${company.name}, will be deleted forever!`}
      />
      <tr key={company.id}>
        <td>
          <div className='d-flex align-items-center'>
            {/* <div className='symbol symbol-35px p-2 me-5'>
              <img src={toAbsoluteUrl('/media/avatars/blank.jpg')} alt='logo user' />
            </div> */}

            <div className='d-flex align-items-center'>
              <span className='text-dark fs-6'>{company.name}</span>
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <span className='text-dark fs-6'>{company.phone}</span>
          </div>
        </td>
        <td className='text-end'>
          <div className='d-flex align-items-center'>
            <span className='text-dark fs-6'>{company.email}</span>
          </div>
        </td>
        <td className='text-end'>
          <div className='d-flex align-items-center'>
            <span className='text-dark fs-6'>{company.address}</span>
          </div>
        </td>
        <td className='text-end'>
          <div className='d-flex align-items-center'>
            <span className='text-dark fs-6'>{company.contact}</span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <a
              onClick={() => {
                if (companyId) {
                  history.push(`/add-edit-branch/${companyId}/${company.id}`)
                } else {
                  history.push(`/add-edit-company/${company.id}`)
                }
              }}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <KTSVG path='/media/icons/duotone/Communication/Write.svg' className='svg-icon-3' />
            </a>
            <a
              onClick={async () => {
                setDeleteModalVisible(true)
              }}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            >
              <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
            </a>
          </div>
        </td>
      </tr>
    </>
  )
}

const CompaniesListComponent: React.FC<Props> = ({ className }) => {
  const { id: mainCompanyId } = useParams<any>()
  const history = useHistory()
  const { businessProfileId, user: { role: { slug: userRole } } } = useContext<any>(UserContext)
  const { companies, getCompanies, isLoading, deleteCompany, companyPagination, setCompanyPagination, conglomerateCompanies, getConglomerateCompanies} = useContext<any>(CompanyContext);
  const [searchToken, setSearchToken] = useState<string>('')
  const [companyConglomerateId, setCompanyConglomerateId] = useState<number | null>(null);

  const queryCompaniesCallback = useCallback(({
    mainCompanyId,
    searchToken,
    companyPagination,
    companyConglomerateId,
  }: {
    mainCompanyId: number | string,
    searchToken: string,
    companyPagination: { page: number, per_page: number },
    companyConglomerateId?: number | null
  }) => {
    //onlyMainCompany:1 means that it will return only the main companies
    let payload = {
      search_token: searchToken,
      page: companyPagination.page,
      per_page: companyPagination.per_page
    }
    if (Boolean(mainCompanyId)) {
      payload['mainCompanyId'] = mainCompanyId
    } else {
      payload['onlyMainCompany'] = 1
    }
    if (userRole !== UserRole.Admin) {
      payload['businessProfileId'] = businessProfileId
    }
    if(companyConglomerateId){
      payload['companyConglomerateId'] = companyConglomerateId
    }

    getCompanies(payload)
  }, [userRole, businessProfileId, companyConglomerateId])

  const queryCompaniesDebounced = useMemo(() => debounce(queryCompaniesCallback, 600), [])

  useEffect(() => {
    queryCompaniesDebounced({ mainCompanyId, searchToken, companyPagination, companyConglomerateId})
  }, [mainCompanyId, searchToken, companyPagination.page, companyPagination.per_page, companyConglomerateId])

  useEffect(() => {
    return () => {
      queryCompaniesDebounced.cancel()
    }
  }, [queryCompaniesDebounced])

  useEffect(() => {
    getConglomerateCompanies()
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{mainCompanyId ? 'All branches' : 'All companies'}</span>
          </h3>
          <InputField
            value={searchToken}
            style={{ width: '250px' }}
            placeholder='Search after name, email, contact...'
            onChange={(ev) => {
              let value = ev.target.value;
              setSearchToken(value)
            }}
            hideLabel
            field={{ required: false, name: "searchToken" }}
          />
          <Select
            name = 'conglomerateCompanies'
            field={{ label: 'Filter by conglomerate', backend_property: 'conglomerate_id' }}
            options={conglomerateCompanies}
            getOptionLabel={(company: ConglomerateCompaniesType) => company.name}
            getOptionValue={(company: ConglomerateCompaniesType) => company.id}
            simpleValue={conglomerateCompanies.find((company: ConglomerateCompaniesType) => company.id == company['conglomerate_id'])}
            isClearable={true}
            placeholder='Select conglomerate'
            onChange={(value: ConglomerateCompaniesType) => {
              setCompanyConglomerateId(value?.id)
            }}
          /> 
        </div>
        <div className='page__top-buttons'>
          <a className='btn btn-primary item-right'
            onClick={() => {
              if (mainCompanyId) {
                history.push(`/add-edit-branch/${mainCompanyId}`)
              } else {
                history.push(`/add-edit-company/`)
              }
            }}>
            {mainCompanyId ? 'New branch' : 'New company'}
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-150px'>{mainCompanyId ? 'Branch name' : 'Company name'}</th>
                <th className='min-w-140px'>Phone</th>
                <th className='min-w-120px'>Email</th>
                <th className='min-w-120px'>Adress</th>
                <th className='min-w-120px'>Contact</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody style={{
              display: isLoading ? 'table-caption' : 'table-row-group',
            }}>
              {
                isLoading ? (
                  <tr
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      flex: 1,
                    }}
                  >
                    <td>
                      <Loading />
                    </td>
                  </tr>
                ) : getSortedList({ listToSort: companies || [], args: ["firstname", "lastname"], reversed: true }).map((company: CompanyType) => (
                  <ComapanyRow
                    key={company.id}
                    company={company}
                    companyId={mainCompanyId}
                    deleteCompany={deleteCompany}
                  />
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        <div>
          <Pagination
            pageCount={companyPagination.totalPages}
            currentPage={companyPagination.page}
            setCurrentPage={(nextPage) => setCompanyPagination(oldCompanyPagination => ({ ...oldCompanyPagination, page: nextPage }))}
            resultsPerPage={companyPagination.per_page}
            setResultsPerPage={(resultsPerPage) => setCompanyPagination(oldCompanyPagination => ({ ...oldCompanyPagination, per_page: resultsPerPage }))}
          />
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

    </div>
  )
}

export { CompaniesListComponent }

import moment from 'moment'
import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { KTSVG } from '../../../../_metronic/helpers'
import Loading from '../../../../_metronic/layout/components/loading/Loading'
import ModalPortal from '../../../../_metronic/layout/components/modal/modal-portal'
import { deletePlan } from './RentPlansRequests'
import { cloneRentCompanyPricePlan, getRentCompanyPricePlans } from '../../../../setup/axios/rent.request'
import { UserContext, UserRole } from '../../../../context/user.context'
import Select from '../../../../_metronic/layout/components/select/Select'
import { CompanyType } from '../../../../types/company.types'
import { getCompanies } from '../../../../setup/axios/users.request'

const HeaderRow: FC = () => {
  return (
    <tr className='fw-bolder text-muted'>
      {/* id, name, customer id, customer name, from, currency, updatedAt, updatedBy */}
      <th>Id.</th>
      <th className='min-w-140px'>Name</th>
      <th className='min-w-100px'>Description</th>
      <th className='min-w-100px'>Currency</th>
      <th className='min-w-190px' style={{ whiteSpace: 'nowrap' }}>
        Updated At
      </th>
      <th className='min-w-100px'>Updated By</th>
      <th className='min-w-100px' style={{ paddingLeft: '44px' }}>
        Actions
      </th>
    </tr>
  )
}

const PlanRow: FC<{
  plan: any
  setPlanToBeCloned: Function
  setPlanToBeDeleted: Function
  companyId: string | number
}> = ({ plan, setPlanToBeCloned, setPlanToBeDeleted, companyId }) => {
  if (!Boolean(plan)) {
    return null
  }
  return (
    <tr>
      <td>{plan.id}</td>
      <td>{plan.name}</td>
      <td>{plan.description}</td>
      <td>{plan.currency}</td>
      <td>{moment(plan.updatedAt).format(`YYYY-MM-DD HH:mm:ss`)}</td>
      <td>{plan.updatedBy}</td>
      <td className='text-end'>
        <div style={{ display: 'flex' }}>
          <button
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={() => {
              setPlanToBeCloned(plan)
            }}
          >
            <KTSVG path='/media/icons/duotone/General/Duplicate.svg' className='svg-icon-3' />
          </button>
          <Link
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm mx-2'
            to={`../add-edit-rent-plan/${companyId}/${plan.id}`}
          >
            <KTSVG path='/media/icons/duotone/Communication/Write.svg' className='svg-icon-3' />
          </Link>
          <button
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={() => {
              setPlanToBeDeleted(plan)
            }}
          >
            <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
          </button>
        </div>
      </td>
    </tr>
  )
}

const DeleteModal = ({
  planToBeDeleted,
  setPlanToBeDeleted,
  setModalVisible,
  setPlans,
}: {
  planToBeDeleted: any
  setPlanToBeDeleted: (plan: any) => void
  setModalVisible: (modalVisible: boolean) => void
  setPlans: any
}) => {
  return (
    <div className='py-5 px-8' style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ textAlign: 'center', fontSize: '16px' }}>
        Are you sure you want to delete plan "{planToBeDeleted.name}" with id {planToBeDeleted.id}?
      </span>
      <div className='mt-8' style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          className='btn btn-secondary me-5'
          onClick={() => {
            setPlanToBeDeleted(null)
            setModalVisible(false)
          }}
        >
          Cancel
        </div>
        <div
          className='btn btn-danger'
          onClick={async () => {
            await deletePlan({
              planId: planToBeDeleted.id,
            })
            setPlanToBeDeleted(null)
            setModalVisible(false)
            setPlans((plans: any[]) => plans.filter((plan: any) => plan.id != planToBeDeleted.id))
          }}
        >
          Delete
        </div>
      </div>
    </div>
  )
}

const CloneModal = ({
  shouldTriggerReloadForCompany,
  planId,
  planName,
  setPlanToBeCloned,
  setModalVisible,
}: {
  shouldTriggerReloadForCompany: (companyId: number) => void,
  planId: number
  planName: string
  setPlanToBeCloned: (plan: boolean) => void
  setModalVisible: (modalVisible: boolean) => void
}) => {
  const history = useHistory()
  const { businessProfileId, user: { role: { slug: userRole } } } = useContext<any>(UserContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [companies, setCompanies] = useState<CompanyType[]>([])
  const [clonedPlanName, setClonedPlanName] = useState<string>(planName)
  const [cloneForCompanies, setCloneForCompanies] = useState<CompanyType[]>([])

  const getCompaniesReq = useCallback(async () => {
    //onlyMainCompany:1 means that it will return only the main companies
    let payload = {
      onlyMainCompany: 1
    }
    // only the admin can see all the companies
    if (userRole !== UserRole.Admin) {
      payload['businessProfileId'] = businessProfileId
    }

    setIsLoading(true)
    const response = await getCompanies(payload)
    if (!response || response.error) {
      return;
    }
    setCompanies(response.companies);
    setIsLoading(false)

  }, [userRole, businessProfileId])

  useEffect(() => {
    getCompaniesReq()
  }, [])

  if (isLoading) {
    return <Loading />
  }
  return (
    <div className='py-4 px-6 w-100 h-100'>
      <span style={{ fontSize: '16px', fontWeight: '500' }}>
        Clone pricing plan "{planName}" with id {planId}?
      </span>
      <div style={{ fontSize: '12px', fontWeight: '300', marginTop: 10 }}>
        The name of the cloned plane must be different from the existing one!
      </div>
      <div
        style={{
          fontSize: '16px',
          display: 'flex',
          flexDirection: 'column',
        }}
        className='mt-4'
      >
        <label>Name:</label>
        <input
          className='min-w-300px form-control'
          value={clonedPlanName}
          onChange={(e) => setClonedPlanName(e.target.value)}
        />
      </div>
      <div
        style={{
          fontSize: '16px',
          display: 'flex',
          flexDirection: 'column',
        }}
        className='mt-4'
      >
        <label>Company:</label>
        <Select
          styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
          className='min-w-300px'
          isMulti={true}
          simpleValue={cloneForCompanies}
          onChange={setCloneForCompanies}
          options={companies}
          getOptionLabel={(option: CompanyType) => option.name}
          getOptionValue={(option: CompanyType) => option.id}
        />
      </div>
      <div className='mt-8' style={{ display: 'flex', justifyContent: 'right' }}>
        <div
          className='btn btn-secondary me-5'
          onClick={() => {
            setPlanToBeCloned(null)
            setModalVisible(false)
          }}
        >
          Cancel
        </div>
        <div
          className='btn btn-info'
          onClick={async () => {
            const cloneForCompaniesIds = cloneForCompanies.map(company => company?.id)
            let cloneResponse = await cloneRentCompanyPricePlan({ planId, name: clonedPlanName, cloneForCompanies: cloneForCompaniesIds })
            if (!cloneResponse || cloneResponse.error) {
              return;
            }
            setPlanToBeCloned(null)
            setModalVisible(false)
            if (Boolean(cloneResponse.clonedPlanIds)) {
              cloneResponse.clonedPlanIds.forEach((planId: number, index: number) => {
                const { id: companyId, name: companyName } = cloneForCompanies[index]
                toast.success(`Plan with id ${planId} for company ${companyName} successfully created! Click to edit the plan!`, {
                  autoClose: 5000,
                  onClick: () => {
                    const clonedPlanRoute = `../../add-edit-rent-plan/${companyId}/${planId}`
                    history.push(clonedPlanRoute)
                  }
                })
                // if the current company receives a new plan, we reload the list to display it
                shouldTriggerReloadForCompany(companyId)
              })
            }
          }}
        >
          Clone
        </div>
      </div>
    </div>
  )
}

const RentPlans: FC<{ companyId: string | number }> = ({ companyId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [plans, setPlans] = useState<any[]>([])
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [planToBeDeleted, setPlanToBeDeleted] = useState<any>(null)
  const [planToBeCloned, setPlanToBeCloned] = useState<any>(null)
  const [triggerUpdatePlans, setTriggerUpdatePlans] = useState<boolean>(false)

  const triggerReloadForNewPlanForSameCompany = useCallback((newPlanCompanyId: number) => {
    if (Number(newPlanCompanyId) === Number(companyId)) {
      setTriggerUpdatePlans(true)
    }
  }, [])

  useEffect(() => {
    if (Boolean(planToBeDeleted)) setModalVisible(true)
  }, [planToBeDeleted])

  useEffect(() => {
    if (Boolean(planToBeCloned)) setModalVisible(true)
  }, [planToBeCloned])

  const getAndSetPlans = useCallback(async () => {
    setIsLoading(true)
    const plans = await getRentCompanyPricePlans(companyId);
    setPlans(plans)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (triggerUpdatePlans) {
      setTriggerUpdatePlans(false)
    }
    getAndSetPlans()
  }, [triggerUpdatePlans])

  return (
    <>
      {modalVisible ? (
        <ModalPortal setVisible={setModalVisible} visible={modalVisible} hasExitButton={false}>
          {planToBeDeleted ? (
            <DeleteModal
              planToBeDeleted={planToBeDeleted}
              setPlanToBeDeleted={setPlanToBeDeleted}
              setModalVisible={setModalVisible}
              setPlans={setPlans}
            />
          ) : planToBeCloned ? (
            <CloneModal
              shouldTriggerReloadForCompany={triggerReloadForNewPlanForSameCompany}
              planId={planToBeCloned?.id}
              planName={planToBeCloned?.name}
              setPlanToBeCloned={setPlanToBeCloned}
              setModalVisible={setModalVisible}
            />
          ) : null}
        </ModalPortal>
      ) : null}
      <div className={`card mb-7`}>
        {/* begin::Header */}
        <div className='card-header pt-5 border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>RENT Pricing Plans</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-300 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <HeaderRow />
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody
                style={{
                  display: isLoading ? 'table-caption' : 'table-row-group',
                }}
              >
                {isLoading ? (
                  <tr
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      flex: 1,
                    }}
                  >
                    <td>
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  (plans || []).map((plan: any) => (
                    <PlanRow
                      plan={plan}
                      companyId={companyId}
                      setPlanToBeDeleted={setPlanToBeDeleted}
                      setPlanToBeCloned={setPlanToBeCloned}
                      key={'plan-' + plan.id}
                    />
                  ))
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export default RentPlans

import moment, { Moment } from "moment"

export type RentPlanExtraCostsModel = {
    id: number
    company_id: number
    plan_id: number
    image_url: string
    name: string
    description: string
    day_cost: number
    is_applicable: string,
    week_days: string [],
    pickup_time: Moment,
    delivery_time: Moment,
    created_at: string | null
    updated_at: string | null
}
  
export const emptyRentPlanExtraCosts = {
  image_url: '',
  name: '',
  description: '',
  day_cost: 0,
  is_applicable: "",
  week_days: [],
  pickup_time: moment(),
  delivery_time: moment(),
  created_at: null,
  updated_at: null
}

import React from 'react'
import {EditableLabel} from '../../../../../app/modules/add-edit-template/components/fields-generator'

export default function Checkbox({
  field,
  onChange,
  value,
  onChangeLabel,
  isValueProp,
}: {
  field: any
  onChange: (e: any) => void
  value: boolean
  onChangeLabel?: (value: string) => void,
  isValueProp?: boolean,
}) {
  const renderRowVersion = () => (
    <div>
      <EditableLabel label={field?.label} onChange={onChangeLabel} isRequired={field.required} />
      <input
        name={field?.name}
        className='clickable form-check-input me-2'
        style={{marginTop: field?.style?.marginTopCheckbox, width: field?.style?.widthCheckbox, height: field?.style?.heightCheckbox, marginRight: field?.style?.marginRightCheckbox}}
        type='checkbox'
        required={field?.required}
        id={field?.name}
        onChange={onChange}
        defaultChecked={!isValueProp ? value : undefined}
        checked={!isValueProp ? value : undefined}
        value={isValueProp ? (value as any) : undefined}
      />

      <EditableLabel label={field?.label} onChange={onChangeLabel} isRequired={field.required} hideColon={field.hideColon} style={{marginTop: field?.style?.marginTopLabel, fontSize: field?.style?.fontSizeLabel}} />
    </div>
  )

  const renderColumnVersion = () => (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <EditableLabel label={field?.label} onChange={onChangeLabel} isRequired={field.required} />

      <input
        style={{
          height: 34,
          width: 34,
          border: '1px solid #e1e1e1',
        }}
        name={field?.name}
        className='clickable form-check-input'
        type='checkbox'
        required={field?.required}
        id={field?.name}
        onChange={onChange}
        checked={!isValueProp ? value : undefined}
        value={isValueProp ? (value as any) : undefined}
      />
    </div>
  )

  return <div>{field.checkboxType === 'column' ? renderColumnVersion() : renderRowVersion()}</div>
}

import moment from 'moment'
import {useMemo} from 'react'
import {Cell} from 'react-sticky-table'
import {formatStatusName} from '../../../utils/string.utils'
import {getStyleForStatus, statusListOfFinal} from '../../../utils/style.utils'
import {KTSVG} from '../../../_metronic/helpers'
import {WarningIcon} from './utils'

const assignmentCellStyle = {
  width: '120px',
  minWidth: '120px',
  maxWidth: '130px',
  background: '#1e1e6c',
  display: 'flex',
  fledDirection: 'column',
  margin: '5px',
  zIndex: 0,
}

const getStyleForTransport = (transport: any) => {
  const transportStatus = transport?.requestStatus
  const styleForStatus = getStyleForStatus(transportStatus, true)
  if (
    Boolean(transport?.hasUnsolvedIssue) &&
    statusListOfFinal.includes(transportStatus)
  ) {
    const styleForUnsolved = getStyleForStatus('UNSOLVED')
    return {...styleForStatus, ...styleForUnsolved}
  }
  return styleForStatus
}

const getTransferTypeTitle = ({
  transferType,
  transferTypeList,
}: {
  transferType: string
  transferTypeList: any[]
}) => {
  if (!transferType || transferType === 'AT_WORK') {
    return ''
  }
  return transferTypeList.find((entry: any) => entry.name === transferType)?.title || ''
}

const TransportCell = (props: any) => {
  const {transport, driver, transferTypeList} = props
  const id = transport.number
  const date = transport.effectiveDate
  const transferTypeString = getTransferTypeTitle({
    transferType: transport.transferType,
    transferTypeList,
  })

  const showOtherVehicleUnitNumber = useMemo(() => {
    if (!driver) {
      return false
    }

    if (
      transport.assignedVehicle &&
      driver.defaultVehicle?.unitNumber &&
      transport.assignedVehicle.unitNumber !== driver.defaultVehicle.unitNumber
    ) {
      return true
    }

    return false
  }, [transport])

  const cellStyle = useMemo(
    () => ({
      ...assignmentCellStyle,
      ...getStyleForTransport(transport),
      ...props.style,
      ...(props.mode !== 'drivers'
        ? {height: '30px'}
        : !transferTypeString
        ? {height: '45px'}
        : {height: '57px'}),
    }),
    [assignmentCellStyle, transport, transferTypeString, props.style, props.mode]
  )

  return (
    <Cell {...props} style={cellStyle} className='transport-item-content pt-1 pb-1 clickable'>
      <div
        className='transport-item-title'
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {props.mode === 'drivers' &&
        Boolean(transport?.requestRemark) &&
        !Boolean(transport?.hasValidRequestRemark) ? (
          <div
            style={{
              position: 'absolute',
              right: '2px',
              bottom: '2px',
            }}
          >
            <WarningIcon />
          </div>
        ) : null}
        <div
          style={{
            textDecoration: transport?.requestStatus === 'CANCELLED' ? 'line-through' : 'none',
            textDecorationThickness: '1.25px',
          }}
        >
          {id}
        </div>
        <div style={{marginTop: '-1px'}}>
          {props.mode === 'drivers' ? (
            <span
              style={{
                borderRadius: '10px',
                padding: '1px 3px',
                fontSize: '0.85rem',
                fontWeight: 600,
              }}
            >
              {moment(date).format('HH:mm')}
            </span>
          ) : null}
          {transport.requestStatus === 'DELAYED' ? (
            // {transport.requestStatus ? (
            <KTSVG
              path='/media/icons/duotone/Home/Clock.svg'
              className='svg-icon-3 svg-icon-white'
            />
          ) : null}
          {transport.lastCheckedBy ? (
            <KTSVG
              path='/media/icons/duotone/Navigation/Check.svg'
              className='svg-icon-3 svg-icon-white'
            />
          ) : null}
        </div>
      </div>
      {props.mode === 'drivers' ? (
        <div className='transport-item-date' style={{marginTop: -1}}>
          {transport?.tariffService?.name || transport?.tariffServiceName}
        </div>
      ) : null}
      <div className='transport-item-date' style={{fontSize: 12, marginTop: -5}}>
        {formatStatusName(transport.requestStatus)}{' '}
        {showOtherVehicleUnitNumber ? '- ' + transport?.assignedVehicle?.unitNumber : ''}
      </div>
      {transferTypeString ? (
        <span style={{marginTop: '-6.5px', fontWeight: '500'}}>{transferTypeString}</span>
      ) : null}
    </Cell>
  )
}

export default TransportCell

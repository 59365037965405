import React from 'react'

export default function Input({
  label,
  isRequired,
  value,
  onChange,
  onBlurEffect,
  disabled,
  style,
  type,
  accept,
  className,
  inputProps,
}: {
  label: string
  isRequired?: boolean
  value?: string
  onChange: (e: any) => void
  onBlurEffect?: (e: any) => void
  disabled?: boolean
  style?: any
  type?: string
  accept?: string
  className?: string
  inputProps?: any
}) {
  return (
    <div style={style}>
      <label htmlFor={label} className='form-label'>
        {label}
        {isRequired && <span className='text-danger'>*</span>}
      </label>
      <input
        required={isRequired}
        name={label}
        className={`form-control form-control-sm ${className || ''}`}
        value={value || ''}
        onChange={onChange}
        onBlur={(ev: any) => {
          var futureValue = ev?.target?.value
          if (Boolean(onBlurEffect)) {
            ev.target.value = onBlurEffect(futureValue)
          }
          onChange(ev)
        }}
        disabled={disabled}
        type={type || 'text'}
        accept={accept}
        {...inputProps}
      />
    </div>
  )
}

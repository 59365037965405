/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { Link, useParams } from 'react-router-dom'
import { CompanyContext } from '../../../context/company.context'
import Loading from '../../../_metronic/layout/components/loading/Loading'
import { getSortedList } from '../../../utils/sort.utils'
// import PackageRow from './components/PackageRow.component'
import { PackageType, emptyPackage, emptyService } from '../../../types/company.types'
import AddEditModal from '../../../components/add-edit-modal/AddEditModal.component';
import { defaultServiceFields } from '../../../utils/companies.utils'
import ServiceRow from './components/ServiceRow.component'
import { PackageContext } from '../../../context/packages.context'

const ServicesPage: FC = () => {
  const intl = useIntl()
  const { id } = useParams<any>()
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
  const { isLoading, getServices, services, createAndUpdateService, deleteService } = useContext<any>(PackageContext)

  useEffect(() => {
    getServices({ id: id });
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>Services</PageTitle>
      {addModalVisible ? <AddEditModal 
        key={JSON.stringify(emptyService)}
        data={{ ...emptyService, package_id:id }} 
        addEditModalVisible={addModalVisible} 
        setAddEditModalVisible={setAddModalVisible} 
        type='serice' 
        fields={defaultServiceFields}
        saveMethod={createAndUpdateService}
      />:null}
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>All services</span>
          </h3>
          <div className='page__top-buttons'>
            <button className='btn btn-primary item-right' onClick={() => { setAddModalVisible(true) }}>
              New service
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-300 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th>No.</th>
                  <th className='min-w-140px'>Name</th>
                  <th className='min-w-140px'>Aditional Info</th>
                  {/* <th className='min-w-100px'>Service type</th> */}
                  <th className='min-w-100px text-end'>Actions</th>
                  <th></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody
                style={{
                  display: isLoading ? 'table-caption' : 'table-row-group',
                }}
              >
                {isLoading ? (
                  <tr
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      flex: 1,
                    }}
                  >
                    <td>
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  services?.map((service: any, index: number) => (
                    <ServiceRow
                      key={index}
                      number={index}
                      service={service}
                      deleteService={deleteService}
                    />
                  ))
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>

      </div>
    </>
  )
}

export default ServicesPage

import { useState } from "react";


export default function RadioSelect({
  label,
  value,
  items,
  isRequired,
  onItemSelect
}) {

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <label htmlFor={label} className='form-label'>
        {label}
        {': '}
        {isRequired && <span className='text-danger'>*</span>}
      </label>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        
        {items.map((item)=>{
          return<label htmlFor={item.id} className="radio-label">
              <input
                className="radio-input"
                type="radio"
                id={item.id}
                name={`${label}${item.name}`}
                onChange={(e) => {const value = e.target.name.replace(label,''); onItemSelect(value) }}
                value={item.value}
                checked={item.id === value}
              />
              <span className="custom-radio" />
              {item.value}
          </label>
        })}
      </div>
    </div>
  );
}

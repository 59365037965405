const FinishedCampaign = () => {
  return (
    <div className='container-form-sms-campaign'>
      <div className='contact content-area ' id='post-8'>
        <div className='container'>
          <div className='wrapper-form'>
            <div className='logo-custom'>
              <h1 className='pt-20'>Campania a luat sfârșit</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FinishedCampaign

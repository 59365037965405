export const CAR_BRANDS = [
  {
    logo: 'https://imgur.com/pC6sI9N.png', 
    name: ['Abarth'],
  },
  {
    logo: 'https://imgur.com/VxvFZ6q.png',
    name: ['AC'],
  },
  {
    logo: 'https://imgur.com/DRlzl6s.png',
    name: ['Acura'],
  },
  {
    logo: 'https://imgur.com/dB08nav.png',
    name: ['Aixam'],
  },
  {
    logo: 'https://imgur.com/VfVXe2g.png',
    name: ['Alfa Romeo'],
  },
  {
    logo: 'https://imgur.com/8bbCoAm.png',
    name: ['Ariel'],
  },
  {
    logo: 'https://imgur.com/bSgOd30.png',
    name: ['Arrinera'],
  },
  {
    logo: 'https://imgur.com/TGjCJ2r.png',
    name: ['Aston Martin'],
  },
  {
    logo: 'https://imgur.com/p6JU4xq.png',
    name: ['Audi'],
  },
  {
    logo: 'https://imgur.com/pZLrjM9.png',
    name: ['Bentley'],
  },
  {
    logo: 'https://imgur.com/lY38v6P.png',
    name: ['BMW'],
  },
  {
    logo: 'https://imgur.com/6QhlRYy.png',
    name: ['Bugatti'],
  },
  {
    logo: 'https://imgur.com/sFKDn2X.png',
    name: ['Buick'],
  },
  {
    logo: 'https://imgur.com/E9a8Af5.png',
    name: ['Cadillac'],
  },
  {
    logo: 'https://imgur.com/bFBiCWF.png',
    name: ['Caterham'],
  },
  {
    logo: 'https://imgur.com/Ng3R6h7.png',
    name: ['Chevrolet'],
  },
  {
    logo: 'https://imgur.com/JnTk3lg.png',
    name: ['Chrysler'],
  },
  {
    logo: 'https://imgur.com/ES2uNbw.png',
    name: ['Citroën'],
  },
  {
    logo: 'https://imgur.com/4xVxgl2.png',
    name: ['Corvette'],
  },
  {
    logo: 'https://imgur.com/gUWiFPm.png',
    name: ['Dacia'],
  },
  {
    logo: 'https://imgur.com/BCSx4S3.png',
    name: ['Daewoo'],
  },
  {
    logo: 'https://imgur.com/pwMslEJ.png',
    name: ['Daf'],
  },
  {
    logo: 'https://imgur.com/kvXpjIl.png',
    name: ['Daihatsu'],
  },
  {
    logo: 'https://imgur.com/kSVjMKs.png',
    name: ['DeLorean'],
  },
  {
    logo: 'https://imgur.com/bninkX1.png',
    name: ['Dodge'],
  },
  {
    logo: 'https://imgur.com/FDk7f3Y.png',
    name: ['DR Motor'],
  },
  {
    logo: 'https://imgur.com/lqNTsIV.png',
    name: ['Elfin'],
  },
  {
    logo: 'https://imgur.com/iiWgy0H.png',
    name: ['Ferrari'],
  },
  {
    logo: 'https://imgur.com/jzINfwQ.png',
    name: ['Fiat'],
  },
  {
    logo: 'https://imgur.com/QYe7PqY.png',
    name: ['Ford'],
  },
  {
    logo: 'https://imgur.com/fB7iTKs.png',
    name: ['Gaz'],
  },
  {
    logo: 'https://imgur.com/SD4UCvw.png',
    name: ['Geely'],
  },
  {
    logo: 'https://imgur.com/ssuRSMM.png',
    name: ['Gillet'],
  },
  {
    logo: 'https://imgur.com/ssuRSMM.png',
    name: ['Ginetta'],
  },
  {
    logo: 'https://imgur.com/1MXEsLB.png',
    name: ['General Motors'],
  },
  {
    logo: 'https://imgur.com/ISwgAhh.png',
    name: ['GMC'],
  },
  {
    logo: 'https://imgur.com/XwmP1JQ.png',
    name: ['Great Wall'],
  },
  {
    logo: 'https://imgur.com/IvgVbWO.png',
    name: ['Gumpert | Apollo'],
  },
  {
    logo: 'https://imgur.com/dXk6XMU.png',
    name: ['Hennessey logo'],
  },
  {
    logo: 'https://imgur.com/pAovz2E.png',
    name: ['Holden'],
  },
  {
    logo: 'https://imgur.com/JWgXuGn.png',
    name: ['Honda'],
  },
  {
    logo: 'https://imgur.com/EVqUzky.png',
    name: ['Hummer'],
  },
  {
    logo: 'https://imgur.com/PEhk7hf.png',
    name: ['Hyundai'],
  },
  {
    logo: 'https://imgur.com/eAMZa9z.png',
    name: ['Infiniti'],
  },
  {
    logo: 'https://imgur.com/UpR1P6p.png',
    name: ['Isuzu'],
  },
  {
    logo: 'https://imgur.com/s0ikFfJ.png',
    name: ['Iveco'],
  },
  {
    logo: 'https://imgur.com/0DQkIP1.png',
    name: ['Jaguar'],
  },
  {
    logo: 'https://imgur.com/bVYgTn4.png',
    name: ['Jeep'],
  },
  {
    logo: 'https://imgur.com/4Ln6j42.png',
    name: ['Joss'],
  },
  {
    logo: 'https://imgur.com/SX6onyj.png',
    name: ['Kia'],
  },
  {
    logo: 'https://imgur.com/iyx9WkB.png',
    name: ['Koenigsegg'],
  },
  {
    logo: 'https://imgur.com/gn780YG.png',
    name: ['Lada'],
  },
  {
    logo: 'https://imgur.com/P3jieZQ.png',
    name: ['Lamborghini'],
  },
  {
    logo: 'https://imgur.com/0PIJjq1.png',
    name: ['Lancia'],
  },
  {
    logo: 'https://imgur.com/2x1onk1.png',
    name: ['Land Rover'],
  },
  {
    logo: 'https://imgur.com/O0gR1Sm.png',
    name: ['Lexus'],
  },
  {
    logo: 'https://imgur.com/7jXD03d.png',
    name: ['Lincoln'],
  },
  {
    logo: 'https://imgur.com/BS86ZIj.png',
    name: ['Lotus'],
  },
  {
    logo: 'https://imgur.com/WhNbslj.png',
    name: ['Luxgen'],
  },
  {
    logo: 'https://imgur.com/QAnGuA2.png',
    name: ['Maruti Suzuki'],
  },
  {
    logo: 'https://imgur.com/v4W0jVQ.png',
    name: ['Maserati'],
  },
  {
    logo: 'https://imgur.com/5OqPIEZ.png',
    name: ['Maybach'],
  },
  {
    logo: 'https://imgur.com/blLPOjY.png',
    name: ['Mazda'],
  },
  {
    logo: 'https://imgur.com/dgYxUJq.png',
    name: ['Mclaren'],
  },
  {
    logo: 'https://imgur.com/e8NRuVl.png',
    name: ['Mercedes'],
  },
  {
    logo: 'https://imgur.com/trgRSsg.png',
    name: ['Mercury'],
  },
  {
    logo: 'https://imgur.com/yaXP5HB.png',
    name: ['MG'],
  },
  {
    logo: 'https://imgur.com/Yos8sfY.png',
    name: ['Mini'],
  },
  {
    logo: 'https://imgur.com/P6aIf1E.png',
    name: ['Mitsubishi'],
  },
  {
    logo: 'https://imgur.com/qQYk9Ry.png',
    name: ['Mopar'],
  },
  {
    logo: 'https://imgur.com/V73HqsW.png',
    name: ['Morgan Motor'],
  },
  {
    logo: 'https://imgur.com/eHvM7ez.png',
    name: ['Mustang logo'],
  },
  {
    logo: 'https://imgur.com/hTnp78Y.png',
    name: ['Nissan'],
  },
  {
    logo: 'https://imgur.com/9boC93t.png',
    name: ['Noble'],
  },
  {
    logo: 'https://imgur.com/BIy5Sqm.png',
    name: ['Opel'],
  },
  {
    logo: 'https://imgur.com/2aQy3b7.png',
    name: ['Pagani'],
  },
  {
    logo: 'https://imgur.com/Ja9JsyW.png',
    name: ['Panoz'],
  },
  {
    logo: 'https://imgur.com/YbsFR8r.png',
    name: ['Perodua'],
  },
  {
    logo: 'https://imgur.com/bXEDyHq.png',
    name: ['Peugeot'],
  },
  {
    logo: 'https://imgur.com/3kerDX6.png',
    name: ['Pininfarina'],
  },
  {
    logo: 'https://imgur.com/3DHeh9b',
    name: ['Pontiac'],
  },
  {
    logo: 'https://imgur.com/Sxh6sdf.png',
    name: ['Porsche'],
  },
  {
    logo: 'https://imgur.com/IM8FGnw.png',
    name: ['Proton'],
  },
  {
    logo: 'https://imgur.com/8CnMJqp.png',
    name: ['Renault'],
  },
  {
    logo: 'https://imgur.com/KAy3AO4.png',
    name: ['Reva'],
  },
  {
    logo: 'https://imgur.com/8YZdMOj.png',
    name: ['Rimac'],
  },
  {
    logo: 'https://imgur.com/EAL9gvv.png',
    name: ['Rolls Royce'],
  },
  {
    logo: 'https://imgur.com/PD1HNlI.png',
    name: ['Ruf logo'],
  },
  {
    logo: 'https://imgur.com/bCMC6Og.png',
    name: ['Saab'],
  },
  {
    logo: 'https://imgur.com/HpqrLcU.png',
    name: ['Scania'],
  },
  {
    logo: 'https://imgur.com/0BK24pt.png',
    name: ['Scion'],
  },
  {
    logo: 'https://imgur.com/KS9hgg3.png',
    name: ['Seat'],
  },
  {
    logo: 'https://imgur.com/ljIlZ04.png',
    name: ['Shelby'],
  },
  {
    logo: 'https://imgur.com/b9r4xLo.png',
    name: ['Skoda'],
  },
  {
    logo: 'https://imgur.com/tURf3sw.png',
    name: ['Smart'],
  },
  {
    logo: 'https://imgur.com/XjSGCMz.png',
    name: ['Spyker'],
  },
  {
    logo: 'https://imgur.com/SyrStG4.png',
    name: ['Ssangyong'],
  },
  {
    logo: 'https://imgur.com/x81tYGj.png',
    name: ['Subaru'],
  },
  {
    logo: 'https://imgur.com/bmm11n6.png',
    name: ['Suzuki'],
  },
  {
    logo: 'https://imgur.com/408ib3H.png',
    name: ['Tata'],
  },
  {
    logo: 'https://imgur.com/8QodEFV.png',
    name: ['Tatra'],
  },
  {
    logo: 'https://imgur.com/gbS2faL.png',
    name: ['Tesla'],
  },
  {
    logo: 'https://imgur.com/wylxVLA.png',
    name: ['Toyota'],
  },
  {
    logo: 'https://imgur.com/2e3sqNt.png',
    name: ['TVR'],
  },
  {
    logo: 'https://imgur.com/pPbnApe.png',
    name: ['UAZ'],
  },
  {
    logo: 'https://imgur.com/gYtgW1q.png',
    name: ['Vauxhall'],
  },
  {
    logo: 'https://imgur.com/7j07Hnr.png',
    name: ['Viper'],
  },
  {
    logo: 'https://imgur.com/8STXzzk.png',
    name: ['Volkswagen', 'VW'],
  },
  {
    logo: 'https://imgur.com/bbX69Td.png',
    name: ['Volvo'],
  },
  {
    logo: 'https://imgur.com/DLYdtgD.png',
    name: ['Wiesmann'],
  },
  {
    logo: 'https://imgur.com/dX5OPzA.png',
    name: ['Zenvo'],
  },
]

import { FC } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import AddEditCampaignForm from "./components/AddEditCampaignForm.component";

const AddEditCampaign: FC = () => {
    return (
        <div>
            {/* begin::Header */}
            <PageTitle>
                Add Campaign
            </PageTitle>
            {/* end::Header */}

            {/* begin::form */}
            <AddEditCampaignForm />
            {/* end::Form */}
        </div>
    )
}

export default AddEditCampaign
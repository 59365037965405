export type RentPlanInsuranceModel = {
  id: number
  name: string
  image: string
  totalInsurances: number
  all_insurances: {
    id: number
    name: string
    created_at: string | null
    updated_at: string | null
  }[]
  insurances: {
    id: number
    plan_id: string
    company_id: string
    plan_vehicle_class_id: number
    franchise: string
    cost: string
    created_at: string | null
    updated_at: string | null
    insurance_id: string
  }[]
}

export type RentPlanCompleteInsuranceModel = {
id: number
name: string
plan_id: number,
company_id: number,
plan_vehicle_class_id: number,
franchise: string,
cost: string,
insurance_id: number
}

export type RentPlanInsuranceCreateModel = {
id?: number
name: string
company_id: string
classes: {
  franchise: string
  cost: string,
  plan_vehicle_class_id: number
}[]
}
export const emptyRentPlanInsurance = {
name: "",
image: "",
all_insurances: [],
insurances: [],
created_at: null,
updated_at: null
}

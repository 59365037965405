import LocalizedStrings from 'localized-strings';

export const translation = new LocalizedStrings({
  ro: {
    lastUpdated: 'Ultima actualizare',
    firstName: 'Prenume',
    stillCarOwner: 'Confirmați că sunteți proprietarul acestei mașini',
    loading: 'Se încarcă...',
    lastName: 'Nume',
    verifiedData: 'Sunt corecte datele afișate?',
    carList: 'Lista mașinilor',
    yes: 'da',
    no: 'nu',
    instructions:
      'Te rugăm să verifici dacă datele de mai jos sunt corecte sau să le actualizezi pentru a le modifica în baza noastră de date.',
    noOfKm: 'Număr de Kilometri',
    county: 'Județ',
    location: 'Localitate',
    vulcanization: 'Vulcanizare',
    areClientDetailsCorrect: 'Toate datele sunt corecte',
    requiredField: 'Acest câmp este obligatoriu',
    isDataProcessingApproved: 'Sunt de acord cu prelucrarea datelor cu caracter personal',
    isPromotionApproved:
      'Sunt de acord să primesc mesaje de informare și promovare din partea Autonom',
    submit: 'Trimite',
    userInfo: 'Informații utilizator',
    validEmail: 'Vă rugăm să introduceți un email valid.',
    validCharacters: 'Sunt acceptate doar litere, "-" și spații',
    validNumeric: 'Sunt acceptate doar caractere numerice',
    error: 'Ceva nu a mers bine',
    dataConfirmationRequired: 'Câmpul de confirmare a datelor este obligatoriu',
    licensePlate: 'Număr de înmatriculare',
    userError: 'User-ul nu a putut fi modificat',
    userSuccessfulUpdate: 'User-ul a fost modificat cu succes',
  },

  en: {
    stillCarOwner: 'Confirm that you are the owner of this car',
    lastUpdated: 'Last Updated',
    firstName: 'First Name',
    lastName: 'Last Name',
    loading: 'Loading...',
    userInfo: 'User Information',
    name: 'Name and Surname',
    carList: 'Car List',
    verifiedData: 'Is the displayed data correct?',
    yes: 'yes',
    no: 'no',
    instructions:
      'Please verify if the following data is correct or update it to modify our database.',
    noOfKm: 'Number of Kilometers',
    county: 'County',
    location: 'Location',
    vulcanization: 'Vulcanization',
    areClientDetailsCorrect: 'All registered data is correct.',
    requiredField: 'This field is required',
    isDataProcessingApproved: 'I agree to the processing of personal data.',
    isPromotionApproved: 'I agree to receive informational and promotional messages from Autonom.',
    submit: 'Submit',
    validEmail: 'Please enter a valid email address',
    validCharacters: 'Only alphabetic characters are allowed',
    validNumeric: 'Only numeric values are allowed',
    error: 'Something went wrong',
    dataConfirmationRequired: 'The data confirmation field is required',
    licensePlate: 'License Plate ',
    userError: 'Unable to save user',
    userSuccessfulUpdate: 'User has been updated',
  },
});

export const tableHeaders = [
  {
    label: "Id",
    keyName: "id"
  },
  {
    label: "Company",
    keyName: "company_name"
  },
  {
    label: "Phone Number",
    keyName: "user_phone"
  },
  {
    label: "Location",
    keyName: "location"
  },
  {
    label: "Brand",
    keyName: "brand"
  },
  {
    label: "Model",
    keyName: "model"
  },
  {
    label: "Plate Number",
    keyName: "license_number"
  },
  {
    label: "VIN",
    keyName: "vin"
  },
  {
    label: "KM",
    keyName: "number_of_km"
  },
  {
    label: "Is Owner",
    keyName: "is_owner"
  }
];

export const XLSTableHeaders = [
  'Nume și Prenume',
  'E-Mail',
  'Firmă',
  'Județ',
  'Localitate',
  'Număr de Telefon',
  'Marcă',
  'Model',
  'Număr de Înmatriculare',
  'Serie de Șasiu',
  'Vulcanizare',
  'Număr KM',
  'Mesaj',
  'Limba',
  'Statusul Formularului',
  'Sunt de acord cu prelucrarea datelor',
  'Sunt de acord să primesc mesaje de informare și promovare',
  'Sunt proprietarul mașinii',
];

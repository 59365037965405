import React from 'react'
import DatePicker, {DateObject} from 'react-multi-date-picker'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'
import {EditableLabel} from '../../../../../app/modules/add-edit-template/components/fields-generator'

type DateInputProps = {
  field: any
  value: string
  onChange: (value: DateObject) => void
  className?: string
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
  id?: string
  required?: boolean
  label?: string
  onChangeLabel?: (value: string) => void
  dateFormat?: string
  noDatePlugins?: boolean
  showOtherDays?: boolean
  minDate?: string | Date | number | DateObject
}

const DateInput = ({
  field,
  id,
  value,
  onChange,
  className,
  placeholder,
  disabled,
  readOnly,
  required,
  label,
  onChangeLabel,
  dateFormat,
  noDatePlugins,
  showOtherDays,
  minDate,
}: DateInputProps) => {
  return (
    <div id={id}>
      <EditableLabel label={label} onChange={onChangeLabel} isRequired={required} />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <span
          className='input-group-text'
          style={{
            borderRadius: '0.475rem 0px 0px 0.475rem',
            height: '38px',
            fontSize: '12px',
            fontWeight: 100,
          }}
        >
          Date:
        </span>
        <DatePicker
          currentDate={value ? new DateObject(value) : null}
          containerClassName='custom-date-picker'
          format={dateFormat || 'YYYY-MM-DD HH:mm'}
          readOnly={readOnly}
          minDate={minDate}
          showOtherDays={Boolean(showOtherDays)}
          disabled={disabled}
          plugins={
            noDatePlugins
              ? []
              : [<TimePicker hideSeconds format='DD/MM/YYYY HH:mm' position='bottom' />]
          }
          value={value ? new DateObject(new Date(value)) : null}
          onChange={(date: any) => {
            onChange(date)
          }}
        />
      </div>
    </div>
  )
}

export default DateInput

import moment from "moment";
import { TariffServiceType, emptyTariffService } from "../../../../pages/add-edit-plan/modules/PlanTypes";
import { TransportType } from "./transportTypes";

export const defaultPaymentStatus = 'NOT_PAID_YET'
export const defaultTransportType = 'TRANSFER'
export const defaultRequestStatus = 'ANALYZE'
export const defaultRentService = ''

export const extractPassengerNames = (
  passengers: string
): { mainPassenger: string; otherPassengers: string[] } => {
  const names = passengers
    .split(/[,;\|]+/g)
    .map((name: string) => name.trim())
    .filter((name: string) => name !== '')
  const mainPassenger = names[0] || ''
  const otherPassengers = names.slice(1)
  return { mainPassenger, otherPassengers }
}

export const getEditedTransportWithCompanyInfo = ({
  oldTransport,
  company,
  supplier,
  companyInfo,
  byField,
  clearCustomFields = false,
  changeCurrency,
}: {
  oldTransport: any
  company: any
  supplier?: any
  companyInfo: any
  byField: boolean
  clearCustomFields?: boolean
  changeCurrency: Function
}) => {
  let editedTransport = { ...oldTransport }
  editedTransport.company = { ...company }
  if (Boolean(supplier)) {
    editedTransport.supplier = { ...supplier }
  }

  if (byField) {
    editedTransport.greeterSignLogo = companyInfo?.greeterSignLogo
    editedTransport.greeterSignName = companyInfo?.greeterSignName
    editedTransport.greeterSignOther = companyInfo?.greeterSignOther
    editedTransport.paymentStatus = defaultPaymentStatus

    if (clearCustomFields) {
      editedTransport.companyCustomField1Value = ''
      editedTransport.companyCustomField2Value = ''
      editedTransport.companyCustomField3Value = ''
      editedTransport.companyCustomField4Value = ''
      editedTransport.companyCustomField5Value = ''
    }
    if (companyInfo?.currency) {
      const futureCurrency = companyInfo?.currency
      const { futureTariff, futureCommission } = changeCurrency(editedTransport, futureCurrency)
      editedTransport.currency = futureCurrency
      editedTransport.tariff = futureTariff
      editedTransport.commission = futureCommission
    }
  }
  return editedTransport
}

export const getReplacementEstimateDateFromTransport = (transport: TransportType) => {
  let date = transport.effectiveDate
  let days = transport.assistanceVehicleReplacementDays
  if (!date || days === null || days === undefined) {
    return ''
  }
  return moment(date).add(days, 'days').format('YYYY-MM-DD HH:mm')
}

export const getDriverKm = ({
  startOdometer,
  endOdometer,
}: {
  startOdometer: number | string
  endOdometer: number | string
}) => {
  let notStartOdometer =
    startOdometer === null || startOdometer === undefined || startOdometer === ''
  let notEndOdometer = endOdometer === null || endOdometer === undefined || endOdometer === ''

  if (notStartOdometer || notEndOdometer) {
    return 0
  }

  let startValue = parseFloat(startOdometer + '')
  let endValue = parseFloat(endOdometer + '')

  return Math.max(endValue - startValue, 0)
}

export const getServiceInfoByName = (tariffServices: TariffServiceType[], name: string) => {
  if (!tariffServices) {
    return emptyTariffService
  }
  const service = tariffServices.find((tariffService) => {
    const tariffServiceName = (tariffService?.name || '').toLocaleLowerCase()
    if (tariffServiceName === name.toLocaleLowerCase()) {
      return tariffService
    }
  })
  return service
}

export const getTransportInfoByServiceChange = ({ isEditMode, tariffServiceInfo, tariffServiceId }: { isEditMode: boolean, tariffServiceInfo: TariffServiceType, tariffServiceId: number }) => {
  if (!tariffServiceId) {
    return {}
  }
  let futureTariffInfo = {}
  futureTariffInfo["tariffServiceId"] = tariffServiceId
  if (!isEditMode && Boolean(tariffServiceInfo)) {
    // if we have a new request, and the supplier exists (the supplier autocomplete should happend only on new, not on edit)
    const futureSupplier = tariffServiceInfo.supplier
    if (futureSupplier) {
      futureTariffInfo['supplier'] = futureSupplier
    }

    const futureRevenueCenter = tariffServiceInfo.revenueCenter
    if (futureRevenueCenter) {
      futureTariffInfo['revenueCenter'] = futureRevenueCenter
    }
  }
  return futureTariffInfo
}


export const getTransportInfoByTypeChange = ({
  isEditMode,
  oldType,
  newType,
  rentACarService,
  standardService
}: {
  isEditMode: boolean,
  oldType: string,
  newType: string,
  rentACarService: TariffServiceType,
  standardService: TariffServiceType
}) => {
  if (oldType === newType) {
    return {}
  }
  let newTransportInfo = {}
  if (newType === 'RENT') {
    // from Transfer/Dispose to RENT
    newTransportInfo = getTransportInfoByServiceChange({ isEditMode, tariffServiceInfo: rentACarService, tariffServiceId: rentACarService.id })
  } else if (oldType === 'RENT') {
    // from rent to other
    newTransportInfo = getTransportInfoByServiceChange({ isEditMode, tariffServiceInfo: standardService, tariffServiceId: standardService.id })
  }
  newTransportInfo['type'] = newType
  return newTransportInfo
}

import { ReactElement } from "react"
import { OverlayTrigger, Popover } from "react-bootstrap"

const PopoverComponent = ({
  trigger,
  children,
  title = ''
}: {
  trigger: ReactElement,
  children: any,
  title?: string | null
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <OverlayTrigger
        trigger='click'
        placement='bottom'
        overlay={<Popover id='popover-contained' style={{ maxWidth: '700px' }}>
          {Boolean(title) ? (
            <Popover.Header style={{ textAlign: 'center' }}>
              {title}
            </Popover.Header>
          ) : null}
          <Popover.Body>
            {children}
          </Popover.Body>
        </Popover>}
        rootClose
      >
        {trigger}
      </OverlayTrigger>
    </div>
  )
}

export default PopoverComponent
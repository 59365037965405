export type PermissionsType = {
  allowed: string[]
  restricted: string[]
}

export const canAccess = (permissions: PermissionsType, address: string): boolean => {
  // if (address.includes('welcome-back')) {
  //   return true
  // }
  if (!permissions) {
    return false
  }
  if (permissions.allowed.length === 0 && permissions.restricted.length === 0) {
    return true
  }
  if (permissions.restricted.some((restriction: any) => address.includes(restriction))) {
    return false
  }
  if (permissions.allowed.some((allowance: any) => address.includes(allowance))) {
    return true
  }
  return false
}

const convertAddress = (address: string) => {
  if (address.includes('/admin/user')) return '/users'

  if (address.includes('/maps/tracker')) return '/cars'
  if (address.includes('/fence')) return '/geofence-list'

  if (address.includes('/transport/request/view.do')) return '/view-transport'
  if (address.includes('/transport/request')) return '/transport-requests'

  if (address.includes('/transport/assignments/list.do')) return '/transport-assignments'

  if (address.includes('/transport/plan')) return '/tariff-grid'

  if (address.includes('/index') || address === '/') return '/'
  return address
}

const onlyUnique = (value: any, index: number, array: any[]) => {
  return array.indexOf(value) === index
}

export const convertUserTypes = (userTypes: any): PermissionsType => {
  const permissions = { restricted: [], allowed: [] }
  userTypes.forEach((userType: any) => {
    permissions.allowed = permissions.allowed
      .concat(userType.allowedResources.map((address: any) => convertAddress(address.url)))
      .filter(onlyUnique)
    permissions.restricted = permissions.restricted
      .concat(userType.restrictedResources.map((address: any) => convertAddress(address.url)))
      .filter(onlyUnique)
  })
  return permissions
}

export const permissionsForSubscriptionUsers: PermissionsType = {
  restricted: [],
  allowed: ['/add-edit-subscription', '/subscriptions'],
}

export const permissionsForAdmins: PermissionsType = {
  restricted: [],
  allowed: ['/admin/user', 'users'],
}

export const permissionsForFleetAdmins: PermissionsType = {
  restricted: [],
  allowed: [
    '/companies', '/add-edit-company',
    '/branches', '/add-edit-branch',
    '/rent-plans', '/add-edit-rent-plan',
    '/rent-pricing',
    '/car-classes'
  ]
}

export const permissionsForSubscriptionAdmins: PermissionsType = {
  restricted: [],
  allowed: [
    '/companies', '/add-edit-company',
    '/packages', '/add-edit-package',
    '/branches', '/add-edit-branch',
    '/rent-plans', '/add-edit-rent-plan',
    '/rent-pricing',
    '/car-classes'
  ]
}

import React, { CSSProperties, ChangeEventHandler, MouseEventHandler, useEffect } from 'react'
import { generateField } from '../../../../../components/field/field-generator'
import { fields } from '../../RequestFields'
import { KTSVG } from '../../../../../_metronic/helpers'
import { formatNumber } from '../../../../../utils/string.utils'

export enum OptionApplicabilityEnum {
  PerDay = "per day",
  PerRental = "per rental",
  PerWeekDay = "per week day"
}

export interface ExtraType {
  id: number,
  company_id: number | string,
  plan_id: number | string,
  name: string,
  description: string,
  image_url: string,
  day_cost: number | string,
  is_session_option: boolean,
  is_multiple_option: boolean,
  created_at: string | Date,
  updated_at: string | Date,
  is_applicable: OptionApplicabilityEnum
}

export interface SelectedExtraType extends ExtraType {
  quantity: number,
}

const selectField = fields.find((field) => field.name === 'extraOptions')
const multiSelectOptionField = {
  ...selectField,
  type: 'select'
}

const OptionsSelect = ({
  options,
  setSelectedOptions
}: {
  options: any[],
  setSelectedOptions: Function
}) => {
  const fieldProperty = {
    value: '', // we don't need to display anything in the field
    options,
    onChange: (option: ExtraType[]) => {
      const hasOneValue = Array.isArray(option) && option.length === 1
      const futureOption = hasOneValue ? { ...option[0], quantity: 1 } : null
      setSelectedOptions((oldOptions: SelectedExtraType[]) => [...oldOptions, futureOption])
    },
    getOptionLabel: (option: ExtraType) => option.name,
    getOptionValue: (option: ExtraType) => option,
  }
  const selectField = generateField({
    field: multiSelectOptionField,
    fieldName: multiSelectOptionField?.name || '',
    fieldProperty,
    setObject: setSelectedOptions,
  })

  return <>{selectField}</>
}

const editOptionQuantity = (optionId: number, quantity: number, setSelectedOptions: Function) => {
  setSelectedOptions((oldSelectedOptions: SelectedExtraType[]) => {
    return oldSelectedOptions.map(option => {
      if (option.id === optionId) {
        return { ...option, quantity }
      }
      return option
    })
  })
}

const deleteOption = (optionId: number, setSelectedOptions: Function) => {
  setSelectedOptions((oldSelectedOptions: SelectedExtraType[]) => {
    return oldSelectedOptions.filter(option => option.id !== optionId)
  })
}

const OptionIdentity = ({ image_url, name }: { image_url: string, name: string }) => {
  return (
    <div className='col-4' style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ width: '40px', height: '40px', minWidth: '40px', borderRadius: '5px', backgroundColor: '#EFF2F5', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {Boolean(image_url)
          ? <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src={image_url} />
          : <KTSVG path='/media/icons/duotone/Navigation/Plus.svg' className='svg-icon-1' />
        }
      </div>
      <div className='ps-4'><span>{name}</span></div>
    </div>
  )
}

const OptionCost = ({
  cost,
  currency,
}: {
  cost: number | string,
  currency: string
}) => {
  const value = `${cost} ${currency}`
  return (
    <div className='col-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
      <span>{value}</span>
    </div>
  )
}

const getBadgeTypeByApplicableType = (applicableType: OptionApplicabilityEnum) => {
  if (applicableType === 'per day') {
    return 'primary'
  }
  if (applicableType === 'per rental') {
    return 'info'
  }
  if (applicableType === 'per week day') {
    return 'success'
  }
  return 'secondary'
}

const getDisplayTokenByApplicableType = (applicableType: OptionApplicabilityEnum) => {
  if (applicableType === 'per day') {
    return 'D'
  }
  if (applicableType === 'per rental') {
    return 'R'
  }
  if (applicableType === 'per week day') {
    return 'W'
  }
  return '-'
}

const OptionApplicabilityType = ({ applicableType }: { applicableType: OptionApplicabilityEnum }) => {
  const badgeType = getBadgeTypeByApplicableType(applicableType)
  return (
    <div className='col-2' style={{ width: '100px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
      <span className={`badge badge-${badgeType}`}>
        {applicableType}
      </span>
    </div>
  )
}

const DeleteOptionButton = ({
  optionId,
  setSelectedOptions
}: {
  optionId: number,
  setSelectedOptions: Function
}) => {
  return (
    <div className='col-1' style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
      <button
        style={{ width: '25px', height: '25px', minWidth: '25px' }}
        className='btn btn-icon btn-danger'
        type='button'
        onClick={() => deleteOption(optionId, setSelectedOptions)}
      >
        <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
      </button>
    </div>
  )
}

const minQuantity = 1, maxQuantity = 99

const IconBtnWrapper = ({
  onClick,
  hide = false,
  children
}: {
  onClick: MouseEventHandler<HTMLButtonElement>,
  hide?: boolean,
  children: any
}) => {
  if (hide) {
    return <div style={{ width: '25px', height: '25px', minWidth: '25px' }}></div>
  }
  return (
    <button
      style={{ width: '25px', height: '25px', minWidth: '25px' }}
      className='btn btn-icon btn-secondary'
      type='button'
      onClick={onClick}
    >
      {children}
    </button>
  )
}

const QuantityDisplay = ({
  quantity,
  onChange,
  isMulti
}: {
  quantity: number
  onChange: ChangeEventHandler<HTMLInputElement>
  isMulti: boolean
}) => {
  const displayStyle: CSSProperties = { width: '40px', height: '40px', minWidth: '40px', display: 'flex', textAlign: 'center' }

  if (!isMulti) {
    const iconWrapperStyle: CSSProperties = { justifyContent: 'center', alignItems: 'center' }
    return (
      <div
        className={`form-control form-control-sm mx-2`}
        style={{ ...displayStyle, ...iconWrapperStyle }}
      >
        <KTSVG path='/media/icons/duotone/Navigation/Check.svg' className='svg-icon-3' />
      </div>
    )
  }

  return (
    <input
      value={quantity}
      min={minQuantity}
      max={maxQuantity}
      onChange={onChange}
      className='form-control form-control-sm quantity-input mx-2'
      style={displayStyle}
    />
  )
}

const OptionSelectQuantity = ({
  optionId,
  quantity,
  isMulti,
  setSelectedOptions
}: {
  optionId: number,
  quantity: number,
  isMulti: boolean,
  setSelectedOptions: Function
}) => {
  return (
    <div className='col-3' style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
      <IconBtnWrapper
        hide={!Boolean(isMulti)}
        onClick={() => {
          const futureQuantity = Math.max(minQuantity, quantity - 1)
          if (futureQuantity !== quantity) {
            editOptionQuantity(optionId, futureQuantity, setSelectedOptions)
          }
        }}
      >
        <KTSVG path='/media/icons/duotone/Navigation/Angle-left.svg' className='svg-icon-3' />
      </IconBtnWrapper>
      <QuantityDisplay
        quantity={quantity}
        onChange={(ev) => {
          const futureQuantity = Number(formatNumber({ paramValue: ev.target.value, minValue: minQuantity, maxValue: maxQuantity }))
          editOptionQuantity(optionId, futureQuantity, setSelectedOptions)
        }}
        isMulti={isMulti}
      />
      <IconBtnWrapper
        hide={!Boolean(isMulti)}
        onClick={() => {
          const futureQuantity = Math.min(maxQuantity, quantity + 1)
          if (futureQuantity !== quantity) {
            editOptionQuantity(optionId, futureQuantity, setSelectedOptions)
          }
        }}
      >
        <KTSVG path='/media/icons/duotone/Navigation/Angle-right.svg' className='svg-icon-3' />
      </IconBtnWrapper>
    </div>
  )
}

const OptionRow = ({
  option,
  setSelectedOptions,
  currency,
  index
}: {
  option: SelectedExtraType
  setSelectedOptions: Function
  currency: string
  index: number
}) => {
  const isMulti = option?.is_multiple_option
  const backgroundColor = { backgroundColor: (index % 2 === 0) ? 'white' : '#F5F8FA' }
  return (
    <div className='py-2 row' style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'inherit', ...backgroundColor }}>
      <OptionIdentity image_url={option?.image_url || ''} name={option?.name} />
      <OptionCost cost={option?.day_cost || 0} currency={currency} />
      <OptionApplicabilityType applicableType={option?.is_applicable} />
      <OptionSelectQuantity isMulti={isMulti} optionId={option?.id} quantity={option?.quantity || 1} setSelectedOptions={setSelectedOptions} />
      <DeleteOptionButton optionId={option.id} setSelectedOptions={setSelectedOptions} />
    </div>
  )
}

const OptionsDisplayList = ({ children }: { children: any }) => {
  return (
    <div
      className='form-control form-control-sm mt-2'
      style={{ height: '150px', minHeight: '100px', maxHeight: '200px', overflow: 'auto' }}
    >
      {children}
    </div>
  )
}

const OptionsSelectWrapper = ({
  currency,
  selectedOptions,
  setSelectedOptions,
  options
}: {
  currency: string
  selectedOptions: SelectedExtraType[],
  setSelectedOptions: Function
  options: ExtraType[]
}) => {
  const selectedOptionsIds = selectedOptions.map(option => option.id)
  const remainingOptions = options.filter(option => !selectedOptionsIds.includes(option.id))
  return (<div>
    <OptionsSelect options={remainingOptions} setSelectedOptions={setSelectedOptions} />
    <OptionsDisplayList>
      {selectedOptions.map((option, index) =>
        <OptionRow key={option?.id} option={option} setSelectedOptions={setSelectedOptions} currency={currency} index={index} />
      )}
    </OptionsDisplayList>
  </div>)
}

const OptionViewRow = ({ option, currency }: { option: SelectedExtraType, currency: string }) => {
  if (!option) {
    return null
  }
  const badgeType = getBadgeTypeByApplicableType(option?.is_applicable)
  const badgeToken = getDisplayTokenByApplicableType(option?.is_applicable)
  return <div style={{ display: 'flex', gap: '0.5rem', marginBottom: '0.5rem', alignItems: 'center' }}>
    <span style={{ padding: '5px' }} className='badge badge-secondary'>{option.quantity} x</span>
    <span style={{ minWidth: '85px' }}>{option.name}</span>
    <span style={{ whiteSpace: 'nowrap' }}>{option.day_cost} {currency}</span>
    <span style={{ borderRadius: '15px' }} className={`badge badge-${badgeType}`}>{badgeToken}</span>
  </div>
}

export const OptionsListViewMode = ({ options, currency }: { options: SelectedExtraType[], currency: string }) => {
  return (<div>
    {options.map(option => <OptionViewRow key={option.id} option={option} currency={currency} />)}
  </div>)
}

export default OptionsSelectWrapper
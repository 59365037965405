import {useEffect, useState} from 'react'
import {Dropdown, OverlayTrigger, Popover} from 'react-bootstrap'
import {string} from 'yup'
import {EditableLabel} from '../../add-edit-template/components/fields-generator'

const timeUnits = ['min', 'h', 'd']

const getFormattedDuration = ({duration, timeUnit}: {duration: number; timeUnit: string}) => {
  if (timeUnit === 'min') {
    return `${duration} min`
  } else if (timeUnit === 'h') {
    return `${Math.floor(duration / 60)} h ${duration % 60} min`
  } else if (timeUnit === 'd') {
    return `${Math.floor(duration / (60 * 24))} d ${Math.floor(duration / 60) % 24} h ${
      duration % 60
    } min`
  }
}

const InputField = ({
  timeUnit,
  label,
  value,
  onChangeValue,
}: {
  timeUnit: string
  label: string
  value: number
  onChangeValue: Function
}) => {
  return (
    <div>
      <label key={`label-${timeUnit}`} htmlFor={`duration-${timeUnit}`} className='form-label'>
        {label}
      </label>
      <input
        key={`duration-${timeUnit}`}
        id={`duration-${timeUnit}`}
        type={'number'}
        step={1}
        className={`form-control form-control-sm mb-4`}
        value={value}
        onChange={(ev) => onChangeValue(ev.target.value)}
      />
    </div>
  )
}

const EditPopover = ({
  duration,
  setDuration,
  timeUnit,
}: {
  duration: number
  setDuration: any
  timeUnit: string
}) => {
  const minutes = duration
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  const setDurationByForm = ({modifiedUnit, value}: {modifiedUnit: string; value: number}) => {
    let minutesDiff = 0
    let hoursDiff = 0
    let daysDiff = 0

    if (modifiedUnit === 'min') {
      minutesDiff = value - (timeUnit === 'min' ? minutes : minutes % 60)
    } else if (modifiedUnit === 'h') {
      hoursDiff = value - (timeUnit === 'h' ? hours : hours % 24)
    } else if (modifiedUnit === 'd') {
      daysDiff = value - days
    }
    let durationDiff = (daysDiff * 24 + hoursDiff) * 60 + minutesDiff
    return setDuration(Math.max(duration + durationDiff, 0))
  }

  return (
    <Popover id='popover-contained'>
      <Popover.Body>
        <InputField
          value={timeUnit === 'min' ? minutes : minutes % 60}
          onChangeValue={(value: string) =>
            setDurationByForm({modifiedUnit: 'min', value: parseFloat(value || '0')})
          }
          label='Minutes'
          timeUnit='min'
        />
        {timeUnit === 'h' || timeUnit === 'd' ? (
          <InputField
            value={timeUnit === 'h' ? hours : hours % 24}
            onChangeValue={(value: string) =>
              setDurationByForm({modifiedUnit: 'h', value: parseFloat(value || '0')})
            }
            label='Hours'
            timeUnit='h'
          />
        ) : null}
        {timeUnit === 'd' ? (
          <InputField
            value={days}
            onChangeValue={(value: string) =>
              setDurationByForm({modifiedUnit: 'd', value: parseFloat(value || '0')})
            }
            label='Days'
            timeUnit='d'
          />
        ) : null}
      </Popover.Body>
    </Popover>
  )
}

type DurationInputProps = {
  field: any
  value?: string
  onChange?: (value: any) => void
  hideLabel?: boolean
  className?: string
  onChangeLabel?: (value: string) => void
  [key: string]: any
}

const DurationInput = ({
  field,
  value,
  hideLabel = false,
  onChange = () => {},
  onChangeLabel,
  ...inputProps
}: DurationInputProps) => {
  const duration = Boolean(value) ? parseInt(value) : 0
  const [timeUnit, setTimeUnit] = useState<string>(timeUnits[0])
  const [canChangeUnit, setCanChangeUnit] = useState<Boolean>(true)
  const inputComponent = (
    <input
      style={{borderRadius: '0.475rem 0rem 0rem 0.475rem', height: '38px'}}
      readOnly={true}
      name={field?.name}
      required={field?.required}
      className={`form-control form-control-sm`}
      id={field?.name}
      value={getFormattedDuration({duration, timeUnit})}
      disabled={field?.disabled}
      {...inputProps}
    />
  )
  return (
    <div>
      <EditableLabel label={field?.label} onChange={onChangeLabel} isRequired={field.required} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <OverlayTrigger
          trigger='click'
          placement='bottom'
          overlay={EditPopover({duration, timeUnit, setDuration: onChange})}
          onToggle={(visible: Boolean) => setCanChangeUnit(!visible)}
          rootClose
        >
          {inputComponent}
        </OverlayTrigger>
        <Dropdown onSelect={(unit) => setTimeUnit(unit)}>
          <Dropdown.Toggle
            disabled={!canChangeUnit || field?.disabled}
            style={{
              height: '38px',
              border: '1px solid #E4E6EF',
              paddingLeft: '0.35rem',
              paddingRight: '0.35rem',
              fontWeight: '600',
              borderRadius: '0px 10px 10px 0px',
              lineHeight: '1',
            }}
            variant='secondary'
            id='dropdown-autoclose-true'
          >
            <span
              style={{
                width: '30px',
                display: 'inline-flex',
                justifyContent: 'center',
              }}
            >
              {timeUnit}
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {timeUnits.map((unit) => (
              <Dropdown.Item eventKey={unit} active={unit === timeUnit}>
                {unit}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

export default DurationInput

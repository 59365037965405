import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {Card, formatEntryDate} from '../ViewTransport'

const TicketRow: React.FC<{ticket: any; id: number; index?: number}> = ({
  ticket,
  id,
  index = 0,
}) => {
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{ticket.dateTimeStart && formatEntryDate(ticket.dateTimeStart, 'DD-MM-yyyy')}</td>
      <td>
        Start {ticket.dateTimeStart && formatEntryDate(ticket.dateTimeStart, 'hh:mm')}
        <br />
        End {ticket.dateTimeEnd && formatEntryDate(ticket.dateTimeEnd, 'hh:mm')}
      </td>
      <td>
        Start {ticket.odometerStart} <br /> End {ticket.odometerEnd || ''}
      </td>
      <td>{ticket.client ? ticket.client.name : ''}</td>
      <td>{ticket.vehicle ? ticket.vehicle.unitNumber : ''}</td>
      <td>{ticket.driver ? ticket.driver.fullName : ''}</td>
      <td>{ticket.origin || ''}</td>
      <td>{ticket.destination || ''}</td>
      <td>{(ticket.stops || []).length}</td>
      <td>{ticket.ticket || ''}</td>
      <td>{ticket.passengers || ''}</td>
      <td>{ticket.readyTariff || 0}</td>
    </tr>
  )
}

const HeaderRow = () => {
  return (
    <tr className='fw-bolder'>
      <th
        style={{
          minWidth: '60px',
        }}
      >
        Nr. crt.
      </th>
      <th
        style={{
          minWidth: '100px',
        }}
      >
        Date
      </th>
      <th
        style={{
          minWidth: '100px',
        }}
      >
        Time
      </th>
      <th
        style={{
          minWidth: '150px',
        }}
      >
        Odometer
      </th>
      <th>Client</th>
      <th>Vehicle</th>
      <th>Driver</th>
      <th>Origin</th>
      <th>Destination</th>
      <th>Stops</th>
      <th>Ticket</th>
      <th>Pass</th>
      <th>Tariff</th>
      <th></th>
    </tr>
  )
}

const TicketTable: FC<{tickets: any; id: number}> = ({tickets, id}) => {
  return (
    <div key='container-transports' className='col-12 pt-4 pb-4'>
      <Card name={'Transport events for this request'}>
        <div className='table-responsive'>
          <table className='table table-row-gray-100 align-middle'>
            <thead>{HeaderRow()}</thead>
            <tbody>
              {tickets.map((ticket: any, index: number) => (
                <TicketRow key={ticket.id} ticket={ticket} id={id} index={index} />
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  )
}

export default TicketTable

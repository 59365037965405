import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {CampaignType} from '../../../types/campaign.types'

type CampaignsTableProps = {
  campaigns: CampaignType[]
}

const CampaignsTable: React.FC<CampaignsTableProps> = ({campaigns}) => {
  return (
    <>
      <div className={`card mb-7`}>
        <div className='card-header pt-5 border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Campaigns</span>
          </h3>

          <div className='page__top-buttons'>
            <Link
              to={{
                pathname: `/add-campaign`
              }}
              className='btn btn-info item-right mt-5 me-4'
            >
              Add Campaign
            </Link>
          </div>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive transport-table'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-4 gy-3'>
              {/* begin::Table Head */}
              <thead>
                <tr className='fw-bolder'>
                  <th>ID</th>
                  <th style={{maxWidth: '125px', minWidth: '115px'}}>Actions</th>
                  <th className='w-25px ps-5'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input name='select' className='clickable form-check-input' type='checkbox' />
                    </div>
                  </th>
                  <th>Campaign Name</th>
                  <th>Type</th>
                  <th>Activation</th>
                  <th>Expiration</th>
                  <th>Status</th>
                  <th>Number of Clients</th>
                </tr>
              </thead>
              {/* end::Table Head */}
              {/* begin::Table Body */}
              <tbody style={{display: 'table-row-group'}}>
                {campaigns?.map((campaign) => (
                  <tr key={campaign?.id}>
                    <td>{campaign?.id || ''}</td>
                    <td>
                      <div style={{display: 'flex', flexWrap: 'wrap', maxWidth: '115px'}}>
                        <Link
                          data-bs-toggle='tooltip'
                          data-bs-boundary='window'
                          data-bs-placement='top'
                          title='View'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm m-1'
                          to={`../view-campaign/${campaign.id}`}
                        >
                          <KTSVG
                            path={'/media/icons/duotone/General/Search.svg'}
                            className={'svg-icon-1'}
                          />
                        </Link>
                      </div>
                    </td>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          name='select'
                          className='clickable form-check-input'
                          type='checkbox'
                        />
                      </div>
                    </td>
                    <td>{campaign?.name || ''}</td>
                    <td>{campaign?.type || ''}</td>
                    <td>
                      {campaign?.send_after
                        ? new Date(campaign?.send_after)
                            .toLocaleDateString('en-GB')
                            .replace(/\//g, '-')
                        : '-'}
                    </td>
                    <td>
                      {campaign?.expiration_date
                        ? new Date(campaign?.expiration_date)
                            .toLocaleDateString('en-GB')
                            .replace(/\//g, '-')
                        : '-'}
                    </td>
                    <td>{campaign?.status}</td>
                    <td>{campaign?.number_of_clients}</td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table Body */}
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default CampaignsTable

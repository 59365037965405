import React, {createContext, useMemo, useState} from 'react'
import { TariffPlanType } from '../pages/add-edit-plan/modules/PlanTypes'
import { GeofenceType } from '../types/general.types'
import { RentCompanyPricingPlanModel, emptyRentCompanyPricingPlan } from '../app/modules/rent-plans/models/RentCompanyPricingPlanModel'
import { RentPlanIntervalModel } from '../app/modules/rent-plans/models/RentPlanIntervalModel'

export type IntervalData = {
  id: number;
  intervalDays: string
}

const PlanContext = createContext({})

function PlanProvider(props: any) {
  const [plan, setPlan] = useState<TariffPlanType>(null)
  const [companies, setCompanies] = useState<any>([])
  const [geofences, setGeofences] = useState<GeofenceType[]>([])
  const [dictionaries, setDictionaries] = useState<any>([])
  const [tariffServices, setTariffServices] = useState<any>([])
  const [pricingPlanTemplate, setPricingPlanTemplate] = useState<TariffPlanType>(null)
  const [rentCompanyPricingPlan, setRentCompanyPricingPlan] = useState<RentCompanyPricingPlanModel[]>([])
  const [initialTariffs, setInitialTariffs] = useState<RentPlanIntervalModel>(null);
  const [intervalsData, setIntervalsData] = useState<IntervalData[]>([]);

  const store = {
    plan, setPlan,
    companies, setCompanies,
    geofences, setGeofences,
    dictionaries, setDictionaries,
    tariffServices, setTariffServices,
    pricingPlanTemplate, setPricingPlanTemplate,
    rentCompanyPricingPlan, setRentCompanyPricingPlan,
    initialTariffs, setInitialTariffs,
    intervalsData, setIntervalsData
  }

  const storeForProvider = useMemo(() => store, [store])
  return (
    <PlanContext.Provider value={storeForProvider}>{props.children}</PlanContext.Provider>
  )
}

const usePlanContext = () => {
  const context = React.useContext<any>(PlanContext)
  if (context === undefined) {
    throw new Error('usePlan must be used within a PlanProvider')
  }
  return context
}

export {PlanContext, usePlanContext}
export default PlanProvider

import React, { useEffect, useState } from 'react'
import { EASYTRACK_API_URL } from '../../../../constants/api.constants'
import { uuidv4 } from '../../../../pages/geofence-list/modules/GeofencesMap'
import easytrackAxios from '../../../../setup/easytrack.axios'
import { DocumentVariantType, TransportPhotoTempType, TransportPhotoType, TransportPvTempType, TransportPvType } from '../../../../types/document.types'
import ModalPortal from '../../../../_metronic/layout/components/modal/modal-portal'
import { EditableLabel } from '../../add-edit-template/components/fields-generator'
import AddImageModal from './AddImageModal'
import AddPvModal from './AddPvModal'
import ImagePreview from './ImagePreview'
import TransportDocument from './TransportDocument'
import PvPreview from './PvPreview'

export const getDocumentByUrlRequest = async (photoUrl: string) => {
  return easytrackAxios
    .post(`${EASYTRACK_API_URL}/documents/view`, { url: photoUrl })
    .catch((err) => { return err })
}

const getDocumentContentByURL = async (url: string) => {
  if (!url) return ''
  let result = await getDocumentByUrlRequest(url)
  if (!result?.data) {
    return ''
  }
  let content = result?.data?.content
  return content
}

const getAndSetPhotosTempObject = async ({
  documents,
  documentsTempObjectList,
  setDocumentsTempObjectList,
  reverse = false,
}: {
  documents: any[]
  documentsTempObjectList: any[]
  setDocumentsTempObjectList: Function
  reverse?: boolean,
}) => {

  let docsObjects: any = []
  for (let i = 0; i < documents.length; i++) {
    let doc = documents[i]
    let content = await getDocumentContentByURL(doc?.url)
    let tempKey = !Boolean(doc.id) ? uuidv4() : ''
    docsObjects.push({ ...doc, content, tempKey })
  }
  const orderedPhotos = reverse ? docsObjects.reverse() : docsObjects
  let futurePhotosObjects = [...orderedPhotos, ...(documentsTempObjectList || [])]
  setDocumentsTempObjectList(futurePhotosObjects)
}

const TransportDocumentWrapper = ({
  transportRequest,
  field,
  documentTypes,
  documents,
  onChangeLabel,
  type
}: {
  transportRequest: any
  field: any
  documentTypes: DocumentVariantType[]
  documents?: TransportPhotoType[] | TransportPvType[]
  onChangeLabel?: (value: string) => void
  type: string
}) => {
  const [photosTempObjectList, setPhotosTempObjectList] = useState<TransportPhotoTempType[]>(null)
  const [pvsTempObjectList, setPvsTempObjectList] = useState<TransportPvTempType[]>(null)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [processingFiles, setProcessingFiles] = useState<boolean>(true)

  const [selectedPhoto, setSelectedPhoto] = useState<TransportPhotoTempType | null>(null);
  const handleCloseViewImgModal = () => setSelectedPhoto(null);
  const handleShowViewImgModal = setSelectedPhoto;

  const [selectedPv, setselectedPv] = useState<TransportPvTempType | null>(null);
  const handleCloseViewPVModal = () => setselectedPv(null);
  const handleShowViewPvModal = setselectedPv;

  useEffect(() => {
    getAndSetPhotosTempObject({
      documents: documents || [],
      documentsTempObjectList: type === 'pvs' ? pvsTempObjectList : photosTempObjectList,
      setDocumentsTempObjectList: type === 'pvs' ? setPvsTempObjectList : setPhotosTempObjectList
    })
  }, [documents])

  useEffect(() => {
    if (Boolean(photosTempObjectList) || Boolean(pvsTempObjectList)) {
      setProcessingFiles(false)
    }
  }, [photosTempObjectList, pvsTempObjectList])

  const renderAddDocumentModal = () => {
    if (type === 'photos') {
      return <AddImageModal
        setModalVisible={setModalVisible}
        transportRequestId={transportRequest.id}
        setProcessingFiles={setProcessingFiles}
        updateListWithNewPhotos={(newPhotos: TransportPhotoType[]) =>
          getAndSetPhotosTempObject({
            documents: newPhotos,
            documentsTempObjectList: photosTempObjectList,
            setDocumentsTempObjectList: setPhotosTempObjectList,
            reverse: true,
          })
        }
        photoTypes={documentTypes}
      />
    }

    return <AddPvModal
      setModalVisible={setModalVisible}
      transportRequest={transportRequest}
      setProcessingFiles={setProcessingFiles}
      updateListWithNewPhotos={(newPhotos: TransportPhotoType[]) =>
        getAndSetPhotosTempObject({
          documents: newPhotos,
          documentsTempObjectList: pvsTempObjectList,
          setDocumentsTempObjectList: setPvsTempObjectList,
          reverse: true,
        })
      }
      pvTypes={documentTypes}
    />
  }

  const renderPreviewDocumentComponent = () => {
    if (type === 'photos') {
      const isPhotoSelected = Boolean(selectedPhoto)
      if(!isPhotoSelected) {
        return null
      }
      return <ImagePreview
        handleCloseViewImgModal={handleCloseViewImgModal}
        viewImageModal={isPhotoSelected}
        setProcessingFiles={setProcessingFiles}
        photo={selectedPhoto}
        photosTempObjectList={photosTempObjectList}
        updateListWithNewPhotos={(unprocessedPhotos: TransportPhotoTempType[]) => setPhotosTempObjectList([...unprocessedPhotos])}
      />
    }

    const isPvSelected = Boolean(selectedPv)
    if(!isPvSelected) {
      return null
    }
    return <PvPreview
      handleCloseViewPVModal={handleCloseViewPVModal}
      viewPVModal={isPvSelected}
      setProcessingFiles={setProcessingFiles}
      pv={selectedPv}
      pvsTempObjectList={pvsTempObjectList}
      updateListWithNewPVs={(unprocessedPVs: TransportPvTempType[]) => setPvsTempObjectList([...unprocessedPVs])}
    />
  }

  return (
    <div style={{ flex: 1, width: '100%' }}>
      {modalVisible ? (
        <ModalPortal className={type === 'pvs' ? 'addDocumentModal' : ''} setVisible={setModalVisible} visible={modalVisible} hasExitButton={false}>
          {renderAddDocumentModal()}
        </ModalPortal>
      ) : null}

      {renderPreviewDocumentComponent()}

      <EditableLabel label={field?.label} onChange={onChangeLabel} isRequired={field.required} />

      <TransportDocument
        photosTempObjectList={photosTempObjectList}
        pvsTempObjectList={pvsTempObjectList}
        setModalVisible={setModalVisible}
        photoTypesLoaded={(documentTypes || []).length > 0}
        processingFiles={processingFiles}
        handleShowViewImgModal={handleShowViewImgModal}
        handleShowViewPvModal={handleShowViewPvModal}
        type={type}
      />
    </div>
  )
}

export default TransportDocumentWrapper

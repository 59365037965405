import { Button } from 'react-bootstrap';
import {
  ClientCampaignType,
  ClientGeneralFieldsType,
  LanguageType,
  emptyClientGeneralFieldsType,
} from '../../../../types/campaign.types';
import { translation } from '../../../../utils/campaign.utils';
import { useEffect, useMemo, useState } from 'react';
import KmAndTiresFields from './KmAndTiresFields.component';
import { LoadingCard } from '../../../../_metronic/layout/components/loading/Loading';

const KmAndTiresForm = ({
  clients,
  fetchData
}: {
  clients: ClientCampaignType[],
  fetchData: () => Promise<void>
}) => {
  const [language, setLanguage] = useState<LanguageType>(LanguageType.RO);
  const [userGeneralData, setUserGeneralData] = useState<ClientGeneralFieldsType>(
    emptyClientGeneralFieldsType
  );
  const [errors, setErrors] = useState<{
    email: string;
    firstName: string;
    lastName: string;
  }>({
    email: '',
    firstName: '',
    lastName: '',
  });

  const [readonly, setReadonly] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleInputChange = async (fieldName: string, value: string | number) => {
    setUserGeneralData((oldData) => ({
      ...oldData,
      [fieldName]: value,
    }));
  };

  const handleSetLanguage = () => {
    setLanguage((prevLang) => (prevLang === LanguageType.RO ? LanguageType.EN : LanguageType.RO));
    translation.setLanguage(language);
  };

  const handleDataSet = async () => {
    setUserGeneralData({
      user_first_name: clients[0]?.user_first_name,
      user_last_name: clients[0]?.user_last_name,
      user_email: clients[0]?.user_email,
      user_phone: clients[0]?.user_phone,
    });

    if (clients[0]?.user_preferred_language === LanguageType.EN) {
      translation.setLanguage(LanguageType.EN);
      setLanguage(LanguageType.EN)
    }
  };

  const LoadData = async () => {
    setIsLoading(true);
    await handleDataSet();
    setIsLoading(false);
  };

  useEffect(() => {
    translation.setLanguage(language);
  }, []);

  useEffect(() => {
    LoadData();
  }, []);

  const hasErrors = useMemo(() => {
    return Object.values(errors).some((error) => error !== '');
  }, [errors]);

  if (isLoading) {
    return <LoadingCard />;
  }

  return (
    <>
      <div className='iti-flag'
        style={{
          textAlign: 'center',
          background: '#fff'
        }}>

        <Button
          variant='text'
          style={{
            border: '1px solid #ccc',
            borderRadius: '15px',
          }}
          onClick={() => {
            handleSetLanguage();
          }}
        >
          {translation.getLanguage() === 'en' ? (
            <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Flag_of_Romania.svg/900px-Flag_of_Romania.svg.png?20160520173724' />
          ) : (
            <img src='https://media.istockphoto.com/id/486407806/vector/union-jack.jpg?s=612x612&w=0&k=20&c=KPRndA_Czak9T0w_Eq3GnhRaNxERiEiw2cjZe5GBY-E=' />
          )}
        </Button>
      </div>

      <div>
        <p
          style={{
            fontSize: 24,
            marginTop: 32,
            fontWeight: 600,
          }}>
          {translation.userInfo}
        </p>
      </div>

      <div className='form'>
        <div style={{
            display: 'flex',
            gap: "16px"
        }}>
          <div className='mb-5'>
            <label>{translation.firstName}</label>
            <input
              name='user_first_name'
              type='text'
              value={userGeneralData?.user_first_name}
              readOnly={readonly}
              style={{ borderColor: errors.firstName ? 'red' : '#eee' }}
              onChange={(e) => {
                handleInputChange('user_first_name', e.target.value);

                if (e.target.value === '') {
                  return setErrors((oldErrors) => ({ ...oldErrors, firstName: translation.requiredField }));
                }

                setErrors((oldErrors) => ({ ...oldErrors, firstName: '' }));
              }}
            ></input>
          </div>

          <div className='mb-5'>
            <label>{translation.lastName}</label>
            <input
              name='user_last_name'
              type='text'
              value={userGeneralData?.user_last_name}
              readOnly={readonly}
              style={{ borderColor: errors.lastName ? 'red' : '#eee' }}
              onChange={(e) => {
                handleInputChange('user_last_name', e.target.value);
                if (e.target.value === '') {
                  return setErrors((oldErrors) => ({ ...oldErrors, lastName: translation.requiredField }));
                }
                setErrors((oldErrors) => ({ ...oldErrors, lastName: '' }));
              }}
            ></input>
          </div>
        </div>

        {/* begin::email */}
        <div className='mb-5'>
          <label>E-mail</label>
          <input
            type='text'
            inputMode='email'
            value={userGeneralData?.user_email}
            readOnly={readonly}
            style={{ borderColor: errors.email ? 'red' : '#eee' }}
            onChange={(e) => {
              handleInputChange('user_email', e.target.value)

              if (e.target.value === '') {
                return setErrors((oldErrors) => ({ ...oldErrors, email: translation.requiredField }));
              }

              // check if email is valid
              const re = /\S+@\S+\.\S+/;
              if (!re.test(e.target.value)) {
                return setErrors((oldErrors) => ({ ...oldErrors, email: translation.validEmail }));
              }

              setErrors((oldErrors) => ({ ...oldErrors, email: '' }));
            }}
          ></input>
        </div>
        {/* end::Email */}

        {/* begin::DataValidation */}
        <>
          <div>
            <p style={{ fontSize: 15 }}>{translation.verifiedData}</p>
          </div>

          <div style={{ display: 'flex', paddingBottom: 20 }}>
            <Button
              style={{
                width: 100,
                maxWidth: '100%',
                margin: '0px 10px 0px 0px',
                height: 30,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: readonly ? 'none' : '#009EF7',
                color: readonly ? '#009EF7' : '#fff',
                border: '1px solid #009EF7',
              }}
              onClick={() => {
                setReadonly(false);
              }}
            >
              {translation.no.toLocaleUpperCase()}
            </Button>

            {!hasErrors ? (
              <Button
                style={{
                  width: 100,
                  maxWidth: '100%',
                  margin: '0px 10px 0px 0px',
                  height: 30,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: !readonly ? 'none' : '#009EF7',
                  color: !readonly ? '#009EF7' : '#fff',
                  border: '1px solid #009EF7',
                }}
                onClick={() => {
                  setReadonly(true);
                }}
              >
                {translation.yes.toLocaleUpperCase()}
              </Button>
            ) : null}
          </div>
        </>
        {/* end::DataValidation */}
        <div>
          <p
            style={{
              fontSize: 24,
              fontWeight: 600,
              marginTop: 32,
            }}>
            {translation.carList}
          </p>
        </div>

        <div
          style={{
            pointerEvents: !readonly || hasErrors ? 'none' : 'auto',
            opacity: !readonly || hasErrors ? 0.3 : 1,
            cursor: !readonly || hasErrors ? 'not-allowed' : 'pointer',
          }}
        >
          {clients?.map((item, index) => (
            <div style={{ marginBottom: 15 }} key={index}>
              <KmAndTiresFields
                client={item}
                fetchData={fetchData}
                userGeneralData={userGeneralData}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default KmAndTiresForm;

import moment from 'moment';
import {
  POI,
  convertPOItoCompanyPOI,
  getInjectedCompanyPOItoPOI,
} from '../../poi-component/poiConstants';
import {
  addEditPOIRequest,
  convertPOIListToTransportPOIList,
  getPoisWithCompany,
} from '../../poi-component/poiRequests';
import { PassengersObjectType, convertFieldName, getTariffInfo } from '../utils';
import {
  BreakdownObjectType,
  TariffInfoType,
  TypeOfCompany,
} from '../../../../utils/pricing.utils';
import { getURLFormattedTemplateName } from '../../../../utils/string.utils';
import { FlexibleObject, StateSetter, areObjectsEqual } from '../../../../utils/state.utils';
import { toast } from 'react-toastify';
import { FormValidatorResponseType } from '../../../../types/general.types';
import { scrollIntoView } from './structuralFields';
import {
  defaultPaymentStatus,
  defaultRequestStatus,
  defaultTransportType,
} from './transportDataUtils';
import { getTrimmedTransportForRepeat } from '../../../../utils/transport.utils';
import { RentPlanDataType, TransportPricingContextType, transferActionEnum } from './tariffUtils';
import {
  checkTransportRentInfo,
  saveOrEditTransportRentInfo,
} from '../../../../setup/axios/rent.request';
import { RequestMethod, easytrackRequest } from '../../../../utils/api.utils';
import { TransportForRequestType, TransportType } from './transportTypes';
import _ from 'lodash';
import { DateObject } from 'react-multi-date-picker';

const fulfilTransportRequest = async ({
  transportParam,
  transportPricingContext,
  appFlowData,
  rentPlanData,
}: {
  transportParam: TransportType | TransportForRequestType;
  transportPricingContext: TransportPricingContextType;
  appFlowData: AppFlowData;
  rentPlanData?: RentPlanDataType;
}) => {
  const { setIsLoading, history, templateName } = appFlowData;
  const { action, transferType, originAgency } = transportPricingContext;

  if (transportParam.type === 'RENT') {
    // check if the rent info is correct
    const result = await checkTransportRentInfo(rentPlanData);
    const error = result.error;
    if (error) {
      setIsLoading(false);
      // Until the rent info for most of the clients is completed we will let the save happen
      // return; // if the check failed, we should not save the transport
    }
  }

  // save transport request info
  const payload = {
    transportRequest: transportParam,
    taxBreakdownStrategy: 'DO_NOT_RECOMPUTE', // for all cases we will not recompute from backend
  };
  if (action !== null) {
    payload['taxBreakdownOriginAgency'] = originAgency;
    payload['taxBreakdownTransferType'] = transferType;
  }
  const result = await easytrackRequest({
    method: RequestMethod.PUT,
    route: '/transport-requests',
    payload,
  });
  if (!result?.data || result.error) {
    setIsLoading(false);
    // Error
    toast.error(result?.message || 'We have some problem with your last request!');
    return;
  }
  // Success
  toast.success('Transport request was saved!');

  // save rent info
  const transportId = result?.data?.id;
  if (transportId) {
    if (transportParam.type === 'RENT') {
      if (transportParam.currency === rentPlanData.currency && rentPlanData.currency === 'RON') {
        // if we have RON - RON we don't need to save any exchangeRate, because there is no conversion
        rentPlanData.exchange_rate = 0;
      }
      const { error } = await saveOrEditTransportRentInfo({
        rentPlanData,
        transportRequestId: transportId,
      });
      if (error) {
        setIsLoading(false);
        // Until the rent info for most of the clients is completed we will let the save happen
        // return;
      }
    }
    history.push(
      `../../view-transport/${getURLFormattedTemplateName(templateName)}/${transportId}`
    );
  } else {
    history.push('../../transport-requests/' + getURLFormattedTemplateName(templateName));
  }
};

export const getConvertedPOIs = async ({
  companyId,
  intermPOIList,
}: {
  companyId: number;
  intermPOIList: POI[];
}) => {
  const poisList = getPoisWithCompany(companyId, intermPOIList);
  const n = poisList.length;
  for (let i = 0; i < n; i++) {
    const poi = poisList[i];
    const companyPOI = convertPOItoCompanyPOI(poi);
    const result = await addEditPOIRequest(companyPOI);
    // there is no way to discern whether this is a legitimate error or a "duplicate" error on POIs, so we can't add an error message
    // if (result instanceof Error || !Boolean(result?.data) || Boolean(result.error)) {
    //   toast.warning('add/edit operation on intermediary point failed')
    //   return
    // }
    const postedPOI = result?.data;
    if (postedPOI) {
      poisList[i] = getInjectedCompanyPOItoPOI(postedPOI, poi);
    }
  }

  const convertedList = convertPOIListToTransportPOIList(poisList);
  return convertedList;
};

export const getTransportWithConvertedDates = (transport: TransportType) => {
  const dates = {};
  dates['effectiveDate'] = moment(transport.effectiveDate).toDate();

  if (transport.requestDate) {
    dates['requestDate'] = moment(transport.requestDate).toDate();
  }

  if (transport.completeEstimateDate) {
    dates['completeEstimateDate'] = moment(transport.completeEstimateDate).toDate();
  }

  if (transport.startTime) {
    dates['startTime'] = moment(transport.startTime).toDate();
  }

  if (transport.endTime) {
    dates['endTime'] = moment(transport.endTime).toDate();
  }

  if (transport.replacementVehicleReturnEstimateDate) {
    dates['replacementVehicleReturnEstimateDate'] = moment(
      transport.replacementVehicleReturnEstimateDate
    ).toDate();
  }

  return dates;
};

const getTransportWithPreparedData = ({
  transport,
  passengers,
}: {
  transport: TransportType;
  passengers: PassengersObjectType;
}) => {
  const preparedData = {};
  if (!transport.paymentStatus) {
    preparedData['paymentStatus'] = defaultPaymentStatus;
  }

  if (transport.driverNotification) {
    preparedData['driverNotification'] = transport.driverNotification.substring(0, 300);
  }

  preparedData['newTariff'] = null;
  preparedData['tariffDetails'] = null;
  preparedData['tariffServiceName'] = null;

  if (!transport.type) {
    preparedData['type'] = defaultTransportType;
  }

  preparedData['passengerName'] = passengers.mainPassenger;
  if (passengers.otherPassengers.length > 0) {
    preparedData['passengerName'] += '; ' + passengers.otherPassengers.join('; ');
  }

  return preparedData;
};

export const getTariffInfoModified = ({
  transport,
  hasTariffChanged,
  recomputeTaxBreakdown,
  taxBreakdown,
  initialTariff,
}: {
  transport: TransportType;
  hasTariffChanged: boolean;
  recomputeTaxBreakdown: boolean;
  taxBreakdown: BreakdownObjectType;
  initialTariff: number | string;
}): { taxBreakdown: BreakdownObjectType; tariff: number } => {
  const taxInfo = {
    taxBreakdown: { ...(transport.taxBreakdown || {}) },
    tariff: Number(transport.tariff || 0),
  };
  if (!hasTariffChanged) {
    return taxInfo;
  }

  const hasFallbackValue = Boolean(initialTariff);
  const isNewTransport = !transport?.id;
  if (
    !hasFallbackValue ||
    (_.isEmpty(taxInfo.taxBreakdown) && isNewTransport) ||
    (transport.blockTariff && recomputeTaxBreakdown) ||
    (!transport.blockTariff && !recomputeTaxBreakdown)
  ) {
    taxInfo.taxBreakdown = { ...taxBreakdown }; // we want to recompute
  } else {
    taxInfo.tariff = Number(initialTariff || 0); // we don't want to recompute
  }
  return taxInfo;
};

export const getCommissionInfoModified = ({
  transport,
  hasCommissionChanged,
  recomputeCommissionBreakdown,
  commissionBreakdown,
  initialCommission,
}: {
  transport: TransportType;
  hasCommissionChanged: boolean;
  recomputeCommissionBreakdown: boolean;
  commissionBreakdown: BreakdownObjectType;
  initialCommission: number | string;
}): { commissionBreakdown: BreakdownObjectType; commission: number } => {
  const commissionInfo = {
    commissionBreakdown: { ...(transport.commissionBreakdown || {}) },
    commission: Number(transport.commission || 0),
  };
  if (!hasCommissionChanged) {
    return commissionInfo;
  }

  const hasFallbackValue = Boolean(initialCommission);
  const isNewTransport = !transport?.id;
  if (
    !hasFallbackValue ||
    (_.isEmpty(commissionInfo.commissionBreakdown) && isNewTransport) ||
    (transport.blockCommission && recomputeCommissionBreakdown) ||
    (!transport.blockCommission && !recomputeCommissionBreakdown)
  ) {
    commissionInfo.commissionBreakdown = { ...commissionBreakdown }; // we want to recompute
  } else {
    commissionInfo.commission = Number(initialCommission || 0); // we don't want to recompute
  }
  return commissionInfo;
};

export const getTaxInfoForTransport = ({
  transport,
  recomputeStatus,
  tariffInfo,
  initialTransportData,
}: {
  transport: TransportType;
  recomputeStatus: RecomputeStatus;
  tariffInfo: TariffInfoType;
  initialTransportData: InitialTransportData;
}) => {
  const {
    hasTariffChanged,
    hasCommissionChanged,
    recomputeTaxBreakdown,
    recomputeCommissionBreakdown,
  } = recomputeStatus;
  const taxInfo = getTariffInfoModified({
    transport,
    hasTariffChanged,
    recomputeTaxBreakdown,
    taxBreakdown: tariffInfo.taxBreakdown,
    initialTariff: initialTransportData?.initialTariff,
  });

  const commissionInfo = getCommissionInfoModified({
    transport,
    hasCommissionChanged,
    recomputeCommissionBreakdown,
    commissionBreakdown: tariffInfo.commissionBreakdown,
    initialCommission: initialTransportData?.initialCommission,
  });

  return { ...taxInfo, ...commissionInfo };
};

const getRepeatOnDatesConverted = (listToConvert: DateObject[]) => {
  const convertedRepeatOnDates = [];
  listToConvert.forEach((dateElement: DateObject) => {
    const repeatDate = dateElement.format('YYYY-MM-DDTHH:mm:00.000');
    convertedRepeatOnDates.push(repeatDate);
  });
  return convertedRepeatOnDates;
};

// object with recompute flags
export type RecomputeStatus = {
  recomputeTaxBreakdown: boolean;
  recomputeCommissionBreakdown: boolean;
  hasTariffChanged?: boolean;
  hasCommissionChanged?: boolean;
};

// initial data for some transport properties
export type InitialTransportData = {
  initialTariff: number | string;
  initialCommission: number | string;
  initialCompanyId: number;
};

// data that has modified oustide the transport object
export type ExternalTransportData = {
  companyId: number;
  repeatOnDates: DateObject[];
  intermPOIList: POI[];
  passengers: PassengersObjectType;
  tariffInfo: TariffInfoType;
};

// data that is used for navigating the app and modifying the display logic
export type AppFlowData = {
  setIsLoading: StateSetter<boolean>;
  templateName: string;
  history: FlexibleObject;
};

// data that determines the identity of the transport in raport with the rest of the business logic
export type IdentificationData = {
  businessProfileId: number;
  voucher: string;
};

type getPriceByTariffInfoPopulatedType = (props: {
  transport: TransportType | TransportForRequestType;
  tariffInfo: TariffInfoType;
  companyType?: TypeOfCompany;
}) => { price: number; formattedPrice: string };

export type SubmitFormProps = {
  transport: TransportType;
  initialTransportData: InitialTransportData;
  externalTransportData: ExternalTransportData;
  appFlowData: AppFlowData;
  identificationData: IdentificationData;
  transportPricingContext: TransportPricingContextType;
  recomputeStatus?: RecomputeStatus;
  rentPlanData?: RentPlanDataType;
  getPriceByTariffInfoPopulated?: getPriceByTariffInfoPopulatedType;
};

// determine if the tax or the commision has changed and return a complete object
const getUpdatedRecomputeStatus = ({
  transport,
  tariffInfo,
  initialTransportData,
  recomputeStatus,
}: {
  transport: TransportType;
  tariffInfo: TariffInfoType;
  initialTransportData: InitialTransportData;
  recomputeStatus: RecomputeStatus;
}): RecomputeStatus => {
  const hasTariffChanged =
    transport?.tariff != initialTransportData?.initialTariff ||
    !areObjectsEqual({
      objectA: transport.taxBreakdown,
      objectB: tariffInfo.taxBreakdown,
    });
  const hasCommissionChanged =
    transport?.commission != initialTransportData?.initialCommission ||
    !areObjectsEqual({
      objectA: transport.commissionBreakdown,
      objectB: tariffInfo.commissionBreakdown,
    });
  const updatedRecomputeStatus = { ...recomputeStatus, hasTariffChanged, hasCommissionChanged };
  return updatedRecomputeStatus;
};

const getTransportDefaultOnValuesForOptional = (transport: TransportType) => ({
  ...transport,
  requestorName: Boolean(transport?.requestorName) ? transport.requestorName : '',
  requestorPhone: Boolean(transport?.requestorPhone) ? transport.requestorPhone : '',
  passengers: Boolean(transport?.passengers) ? transport.passengers : 0,
})

/**
 * Send the request to the server
 * @returns
 */
export const submitForm = async ({
  transport,
  initialTransportData,
  externalTransportData,
  appFlowData,
  identificationData,
  transportPricingContext,
  recomputeStatus = { recomputeTaxBreakdown: false, recomputeCommissionBreakdown: false },
  rentPlanData,
  getPriceByTariffInfoPopulated,
}: SubmitFormProps) => {
  const futureTransport = { ...transport };
  // remove the history from the payload because we don't need it for edit (it's readonly)
  delete futureTransport.histories;

  const { companyId, repeatOnDates, intermPOIList, passengers, tariffInfo } = externalTransportData;

  const convertedPOIList = await getConvertedPOIs({ companyId: companyId, intermPOIList });
  const dates = getTransportWithConvertedDates(futureTransport);
  const preparedData = getTransportWithPreparedData({ transport: futureTransport, passengers });
  const updatedRecomputeStatus = getUpdatedRecomputeStatus({
    transport,
    tariffInfo,
    initialTransportData,
    recomputeStatus,
  });

  const pricingInfo = getTaxInfoForTransport({
    transport: futureTransport,
    tariffInfo,
    recomputeStatus: updatedRecomputeStatus,
    initialTransportData,
  });

  const transportDefaultedValues = getTransportDefaultOnValuesForOptional(futureTransport)

  const transportForExport: TransportType = {
    ...transportDefaultedValues,
    ...dates,
    ...preparedData,
    ...pricingInfo,
    intermediaryPoints: convertedPOIList,
  };

  fulfilTransportRequest({
    transportParam: transportForExport,
    transportPricingContext,
    appFlowData,
    rentPlanData,
  });

  const taxBreakdownManual = {};
  Object.entries(tariffInfo?.taxBreakdown || {}).forEach(([key, info]) => {
    if (info.manuallyEdited) {
      taxBreakdownManual[key] = info;
    }
  });

  const commissionBreakdownManual = {};
  Object.entries(tariffInfo?.commissionBreakdown || {}).forEach(([key, info]) => {
    if (info.manuallyEdited) {
      commissionBreakdownManual[key] = info;
    }
  });
  const breakdownsManualData = {
    tax: taxBreakdownManual,
    commission: commissionBreakdownManual,
  };

  const repeatDates = getRepeatOnDatesConverted(repeatOnDates);
  const futureTransportStatus = futureTransport.requestStatus || defaultRequestStatus;
  const isEditMode = Boolean(futureTransport.id);

  const futureTransportForRepeat = getTrimmedTransportForRepeat({
    transport: transportForExport,
    keepAssignmentData: true,
    keepRequestComments: !isEditMode,
  });

  if (!isEditMode) {
    // if we make a new Request, then we keep the status for all the other requests, because we want to match them
    futureTransportForRepeat.requestStatus = futureTransportStatus;
  }
  // Make a new  request for each  dates on "repeat dates"
  for (const date of repeatDates) {
    await fulfilTransportForRepeatDate({
      date,
      transportPricingContext,
      futureTransport: futureTransportForRepeat,
      recomputeStatus: updatedRecomputeStatus,
      breakdownsManualData,
      identificationData,
      intermPOIList,
      appFlowData,
      rentPlanData,
      getPriceByTariffInfoPopulated,
    });
  }
};

type BreakdownsManualData = {
  tax: BreakdownObjectType;
  commission: BreakdownObjectType;
};

export const fulfilTransportForRepeatDate = async ({
  date,
  transportPricingContext,
  futureTransport,
  intermPOIList,
  breakdownsManualData,
  recomputeStatus,
  identificationData,
  appFlowData,
  rentPlanData,
  getPriceByTariffInfoPopulated,
}: {
  date: string;
  transportPricingContext: TransportPricingContextType;
  futureTransport: TransportType;
  intermPOIList: POI[];
  breakdownsManualData: BreakdownsManualData;
  recomputeStatus: RecomputeStatus;
  identificationData: IdentificationData;
  appFlowData: AppFlowData;
  rentPlanData: RentPlanDataType;
  getPriceByTariffInfoPopulated: getPriceByTariffInfoPopulatedType;
}) => {
  const repeatPricingContext = {
    ...transportPricingContext,
    action: transferActionEnum.REPEAT_ON_DATES,
  };
  const { recomputeTaxBreakdown, recomputeCommissionBreakdown } = recomputeStatus;
  const futureRepeatTransport: TransportForRequestType = {
    ...futureTransport,
    ...{
      effectiveDate: moment(date).toDate(),
      effectiveDateString: date,
      idTransportRequest: null,
      id: null,
      number: null,
      hash: null,
      completeEstimateDate: null,
      voucher: null,
      histories: null,
      paymentStatus: defaultPaymentStatus,
    },
  };

  if (recomputeTaxBreakdown || recomputeCommissionBreakdown) {
    const newTariffInfo = await getTariffInfo({
      transport: futureRepeatTransport,
      identificationData,
      intermPOIList,
    });

    if (recomputeTaxBreakdown) {
      const futureTaxBreakdown = {
        ...breakdownsManualData?.tax,
        ...(newTariffInfo?.taxBreakdown || {}),
      };
      const tariffObject = getPriceByTariffInfoPopulated({
        transport: futureRepeatTransport,
        tariffInfo: newTariffInfo,
      });
      futureRepeatTransport.tariff = tariffObject.price || 0;
      futureRepeatTransport.taxBreakdown = futureTaxBreakdown;
    }

    if (recomputeCommissionBreakdown) {
      const futureCommissionBreakdown = {
        ...breakdownsManualData?.commission,
        ...(newTariffInfo?.commissionBreakdown || {}),
      };
      const commissionObject = getPriceByTariffInfoPopulated({
        transport: futureRepeatTransport,
        tariffInfo: newTariffInfo,
        companyType: TypeOfCompany.Supplier,
      });
      futureRepeatTransport.commission = commissionObject.price || 0;
      futureRepeatTransport.commissionBreakdown = futureCommissionBreakdown;
    }
  }

  fulfilTransportRequest({
    transportParam: futureRepeatTransport,
    appFlowData,
    rentPlanData,
    transportPricingContext: repeatPricingContext,
  });
};

export const propertyInsideTransportObject = (
  transport: TransportType,
  fieldName: string | string[]
): boolean => {
  // Check if fieldName is an array
  if (Array.isArray(fieldName)) {
    const parent = fieldName[0];
    const child = fieldName[1];

    return Boolean(transport[parent]) && Boolean(transport[parent][child]);
  }

  // Allow numbers like 0;
  return (
    transport[fieldName] !== undefined &&
    transport[fieldName] !== null &&
    transport[fieldName] !== ''
  );
};

export const isFormValid = (
  transport,
  customFields,
  passengers,
  fields
): FormValidatorResponseType => {
  let returnObject = {
    error: false,
    errorMessage: '',
  };

  for (let i = 0; i < fields.length; i++) {
    const currentField = fields[i];
    const fieldName: string = convertFieldName[currentField.name] || currentField.name || '';
    const transportHasFieldValue = propertyInsideTransportObject(transport, fieldName);

    if (typeof fieldName === 'string' && fieldName.startsWith('companyCustomField')) {
      const customFieldInput = customFields[fieldName];
      // the custom fields have a weird system to hold information as a string. So we might have ':' followed by options in the label
      const customLabel = (customFieldInput?.label || '').split(':')[0].trim();
      currentField.label = customLabel;
      currentField.required = Boolean(customFieldInput.mandatory);
    }
    if (currentField.required === true && !transportHasFieldValue) {
      if (fieldName === 'passengerName' && Boolean(passengers.mainPassenger)) {
        continue; // the passengerName is handled outside the main flow (the value is saved at the end on the object)
      }
      toast.error(`${currentField.label} is required`);

      scrollIntoView(currentField.name);
      returnObject = {
        error: true,
        errorMessage: `${currentField.label} is required`,
      };
      break;
    }
  }

  return returnObject;
};

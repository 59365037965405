import React, { useState } from 'react'
import { FlatSubscriptionType } from '../../../../../types/subscription.types'
import { sendMultipleContractCopies } from '../../../add-edit-subscription/utils'

const SendMultipleEmailsModal = ({
  subscriptions,
  setModalVisible,
}: {
  subscriptions: FlatSubscriptionType[],
  setModalVisible: (_: boolean) => void
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <div className='py-5 px-8' style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ textAlign: 'center', fontSize: '16px' }}>
        Trimitere email-uri pentru următoarele subscripții {`(${subscriptions.length})`}:
      </span>
      <div className='mt-8' style={{
        display: 'flex',
        maxHeight: '200px',
        width: '450px',
        overflow: 'auto',
        flexFlow: 'column',
        gap: '10px',
        background: '#f6f6f6'
      }}>
        {subscriptions.map(s => (<div key={s.id} style={{ padding: '10px' }}>
          <span><b>To: {s.client_email}</b></span>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span><b>Id:</b> {s.id}</span>
            <span className="px-2"><b>Company:</b> {s.main_company_name || s.company_name}</span>
            <span><b>Client:</b> {s.client_name}</span>
          </div>
        </div>))}
      </div>
      <div className='mt-8' style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          disabled={isLoading}
          className='btn btn-secondary me-5'
          onClick={() => setModalVisible(false)}
        >
          Anuleaza
        </button>
        <button
          disabled={isLoading}
          className='btn btn-primary'
          onClick={async () => {
            setIsLoading(true)

            await sendMultipleContractCopies({ subscriptionIds: subscriptions.map(s => s.id) })

            setIsLoading(false)
            setModalVisible(false)
          }}
        >
          Trimite
        </button>
      </div>
    </div>
  )
}


export default SendMultipleEmailsModal
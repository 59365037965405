import React from 'react'
import { TooltipLink } from '../../../../components/table/icon.actions'

const Actions = ({
  templateName,
  transportId
}: {
  templateName: string,
  transportId: number | string
}) => {
  return (
    <>
      <TooltipLink
        title='Repeat'
        iconPath={'/Media/Repeat.svg'}
        to={`../add-edit-transport/${templateName}?transportIdForAction=${transportId}&action=repeat`}
      />

      <TooltipLink
        title='Return'
        iconPath={'/Media/Back.svg'}
        iconClassName={'rotate-180'}
        to={`../add-edit-transport/${templateName}?transportIdForAction=${transportId}&action=return`}
      />

      <TooltipLink
        title='View'
        iconPath={'/General/Search.svg'}
        iconClassName={'svg-icon-2'}
        to={`../view-transport/${templateName}/${transportId}`}
      />

      <TooltipLink
        title='Edit'
        iconPath={'/Communication/Edit.svg'}
        iconClassName={'svg-icon-2'}
        to={`../add-edit-transport/${templateName}/${transportId}`}
      />
    </>
  )
}

export default Actions
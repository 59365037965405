import { FC, useEffect, useState } from 'react'
import { updateField } from '../../../utils/state.utils'
import Table from './Table'
import { getStringOfEntity } from '../../../utils/string.utils'
import { createRentIncludedKm, deleteRentIncludedKm, getRentPlanVehicleClass, putRentIncludedKm } from '../../../setup/axios/rent.request'
import { RentPlanVehicleClassModel, emptyRentPlanVehicleClass } from '../../../app/modules/rent-plans/models/RentPlanVehicleClassModel'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { usePlanContext } from '../../../context/plan.context'

const getIncludedKmLabel = () => "Included km";

const compoundUniqueKeyKm = ['name']

const rentIncludedKmFields = [
  'name',
  'included_km_per_day',
  'extra_cost_per_km'
]

const RentIncludedKMFieldToProperty = (
  field: any,
  rentIncludedKm: RentPlanVehicleClassModel,
  setrentIncludedKm: Function
) => {
  let fieldName = field.name
  const updateExtrasListElemEvent = (e: any) =>
    updateField({ value: e, fieldName: fieldName, setObject: setrentIncludedKm })

  const fieldsToPanelPropertiesMapping = {
    name: {value: getStringOfEntity({ entity: rentIncludedKm.name }) },
    included_km_per_day: { value: getStringOfEntity({ entity: rentIncludedKm.included_km_per_day }) },
    extra_cost_per_km: { value: getStringOfEntity({ entity: rentIncludedKm.extra_cost_per_km }) }
  }

  return (fieldsToPanelPropertiesMapping as any)[fieldName]
}

const IncludedKmTable: FC<{}> = ({ }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [rentPlanVehicleClass, setRentPlanVehicleClass] = useState<RentPlanVehicleClassModel[]>([])
  const { id, companyId } = useParams<any>()
  const { rentCompanyPricingPlan } = usePlanContext()
  
  useEffect(() => {
    getRentPlanVehicleClass(id).then((futureRentPlanVehicleClass: RentPlanVehicleClassModel[] | null) => {
      if (!futureRentPlanVehicleClass) {
        return toast.error('The Rent Plan Vehicle Class failed to load!')
      }
      setRentPlanVehicleClass(futureRentPlanVehicleClass)
      setIsLoading(false)
    })
  }, [])

  const rentPlanVehicleClassProperties = [
    {
      name: 'name',
      getDisplayLabel: (name: string, row: any) => {
        return row.image_url ? <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom:10}}> <img src={row.image_url} style={{ marginRight: 14, width: 40, height: 40, marginBottom: 20 }} />{row.name}</div> : <div>{row.name}</div>
      },
      label: 'Class name',
      type: 'select',
      editable: false
    },
    {
      name: 'included_km_per_day',
      getDisplayLabel: (included_km_per_day: string) => included_km_per_day,
      label: 'Included KM/day',
      type: 'input',
    },
    {
      name: 'extra_cost_per_km',
      getDisplayLabel: (extra_cost_per_km: string) => extra_cost_per_km,
      label: 'Extra cost/KM',
      type: 'input',
      addOns: { append: rentCompanyPricingPlan.currency }
    }
  ]

  return (
    <>
      <Table
        tableLabel={'Included KM/Extra'}
        properties={rentPlanVehicleClassProperties}
        fieldToProperty={RentIncludedKMFieldToProperty}
        entries={rentPlanVehicleClass}
        setEntries={setRentPlanVehicleClass}
        emptyEntry={emptyRentPlanVehicleClass}
        getEntryLabel={getIncludedKmLabel}
        requiredFields={rentIncludedKmFields}
        compoundUniqueKey={compoundUniqueKeyKm}
        deleteEndpoint={deleteRentIncludedKm}
        editEndpoint={putRentIncludedKm}
        createEndpoint={(value) => createRentIncludedKm(value, id)}
        isPlanCreated= {!!id}
        addEntryButtonVisible = {false}
      />
    </>
  )
}

export default IncludedKmTable

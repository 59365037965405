/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useContext, useEffect} from 'react'
import {TemplatesContext} from '../../../../../../context/templates.context'
import Select from '../../../../../../_metronic/layout/components/select/Select'
import {toast} from 'react-toastify'

const AppSettings: React.FC = () => {
  const {getTemplates, defaultTemplate, setDefaultTemplate} = useContext<any>(TemplatesContext)

  const [templates, setTemplates] = useState<any>([])

  useEffect(() => {
    const fetchData = async () => {
      const result = await getTemplates()
      if (result.error) {
        return null
      }

      setTemplates(result.data)
    }
    fetchData()
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_app_settings'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>App settings</h3>
        </div>
      </div>

      <div id='kt_account_app_settings' className='collapse show'>
        <div className='card-body border-top p-9'>
          <label className='col-lg-4 col-form-label required fw-bold fs-6'>Default template:</label>

          <div className='col-lg-3 fv-row'>
            <Select
              getOptionLabel={(e) => e.name}
              options={templates}
              getOptionValue={(e) => e.id}
              value={defaultTemplate}
              onChange={(e) => {
                setDefaultTemplate(e)
                toast.success('Default template changed successfully')
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export {AppSettings}

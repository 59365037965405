import { useContext, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { PackageContext } from '../../context/packages.context';
import { API_URL } from '../../constants/api.constants';
import './html-editor.scss';
import { PackageContextType } from '../../types/package.types';

const HtmlEditor = ({
  value,
  setValue,
  company_id,
}: {
  value: string;
  setValue: (value: string) => void;
  company_id?: number;
}) => {
  const quillRef = useRef(null);
  const { postImageInContractCompanyDetails } = useContext<PackageContextType>(PackageContext);
  const ColorClass = ReactQuill.Quill.import('attributors/class/color');
  const SizeStyle = ReactQuill.Quill.import('attributors/style/size');
  const AlignStyle = ReactQuill.Quill.import('attributors/style/align');
  const Block = ReactQuill.Quill.import('blots/block');
  const FontClass = ReactQuill.Quill.import('attributors/class/font');
  ReactQuill.Quill.register(ColorClass, true);
  ReactQuill.Quill.register(SizeStyle, true);
  ReactQuill.Quill.register(AlignStyle, true);
  ReactQuill.Quill.register(Block, true);
  ReactQuill.Quill.register(FontClass, true);
  class DivBlock extends Block {}
  DivBlock.tagName = 'DIV';
  ReactQuill.Quill.register('blots/block', DivBlock, true);
  const Image = ReactQuill.Quill.import('formats/image');
  ReactQuill.Quill.register(Image, true);

  class ImageBlot extends Image {
    static create(value) {
      const node = super.create(value);
      if (typeof value === 'string') {
        node.setAttribute('width', 650);
        node.setAttribute('height', 260);
      }
      return node;
    }
  }

  ReactQuill.Quill.register(ImageBlot, true);

  const modules = {
    clipboard: {
      matchVisual: false,
    },
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline'],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['image'],
    ],
  };
  const saveImageForPdf = async () => {
    quillRef.current
      .getEditor()
      .getModule('toolbar')
      .addHandler('image', () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = async () => {
          if (!input.files || !input?.files?.length || !input?.files?.[0]) return;
          const editor = quillRef?.current?.getEditor();
          const file = input.files[0];
          const newImage = await postImageInContractCompanyDetails({
            payload: { company_id: company_id, image: file },
          });
          const range = editor.getSelection(true);
          editor.insertEmbed(
            range.index,
            'image',
            `${API_URL.split('/')
              .filter((item) => item !== 'api')
              .join('/')}${newImage.data}`,
            ''
          );
        };
      });
  };
  useEffect(() => {
    saveImageForPdf();
  }, [quillRef.current]);

  useEffect(() => {
    const saveImageForPdf = async () => {
      quillRef.current
        .getEditor()
        .getModule('toolbar')
        .addHandler('image', () => {
          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
          input.click();
          input.onchange = async () => {
            if (!input.files || !input?.files?.length || !input?.files?.[0]) return;
            const editor = quillRef?.current?.getEditor();
            const file = input.files[0];
            const newImage = await postImageInContractCompanyDetails({
              payload: { company_id: company_id, image: file },
            });
            const range = editor.getSelection(true);
            editor.insertEmbed(
              range.index,
              'image',
              `${API_URL.split('/')
                .filter((item) => item !== 'api')
                .join('/')}${newImage.data}`,
              ''
            );
          };
        });
    };

    saveImageForPdf();
  }, [quillRef.current]);

  return (
    <div style={{ position: 'relative', marginTop: '5em', border: 'solid 1px transparent' }}>
      <div style={{ height: 400, marginTop: '3em' }}>
        <ReactQuill
          ref={quillRef}
          className='ql-editor'
          modules={modules}
          theme='snow'
          value={value}
          onChange={setValue}
          placeholder='Content goes here...'
        />
      </div>
    </div>
  );
};

export default HtmlEditor;

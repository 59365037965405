import moment from 'moment'
import { FC } from 'react'
import { getDifferenceInDays, getFormatedDurationString } from '../../../../utils/date.utils'
import {
  extractTariffInfo,
  getExchangeRate,
  getPriceFormatted,
  hasIncludedVATByPaymentType,
  TypeOfCompany,
} from '../../../../utils/pricing.utils'
import { ViewGoogleMapsButton } from '../../add-edit-transport/components/CourseMap'
import StopsList from '../../add-edit-transport/components/StopsList'
import { extractBaseCurrency } from '../../add-edit-transport/utils'
import { StopsComponent } from '../../transport-assignments/CardHoverMenu'
import BreakdownCardWrapper from './BreakdownCard'
import GreeterSignList from './GreeterSignList'
import { defaultPaymentStatus, defaultTransportType, extractPassengerNames, getDriverKm } from '../../add-edit-transport/scripts/transportDataUtils'
import { OptionsListViewMode } from '../../add-edit-transport/components/rent/OptionsSelect'
import { TransportType } from '../../add-edit-transport/scripts/transportTypes'
import { FieldType } from '../../../../utils/field.utils'

const formatCoordComponent = (coords: string) => {
  let coordElements = coords.split(',')
  let formattedCoords = (
    <>
      {parseFloat(coordElements[0]).toFixed(6)}
      <br />
      {parseFloat(coordElements[1]).toFixed(5)}
    </>
  )
  return formattedCoords
}

const Cell: FC<{
  label: string
  value: any
  children: any
  isBinary?: boolean
  isSecondary?: boolean
}> = ({ label, value, children, isBinary = false, isSecondary = false }) => {
  let content = children || (isBinary ? (value ? 'YES' : 'No') : value || '-')
  let labelStyle = {
    display: 'inline-block',
    ...(!isSecondary
      ? { width: '40%', maxWidth: '80%', padding: '0.75rem' }
      : { flex: '1 1 auto', paddingRight: '0.75rem' }),
  }
  let contentStyle = {
    display: 'inline-block',
    ...(!isSecondary
      ? { width: 'auto', padding: '0.75rem' }
      : { flex: '1 1 auto', paddingRight: '0.75rem' }),
  }
  return (
    <>
      <div style={labelStyle}>
        <span className='fw-bold'>{label}</span>
      </div>

      <div style={contentStyle}>
        <span>{content}</span>
      </div>
    </>
  )
}

export const PanelFields = ({
  panelName,
  panelFields,
  transport,
}: {
  panelName: string
  panelFields: any[]
  transport: TransportType
}) => {
  const visibleFields = panelFields.filter((panelField: any) => {
    if (panelField.hidden) return false
    return fields.find((field: any) => field.name === panelField.name)
  })
  const visibleFieldsLength = visibleFields.length
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {visibleFields.map((field: any, index: number) => (
        <Field
          key={panelName + '-' + field.name}
          isHidden={field.hidden}
          fieldName={field.name}
          lastRow={index === visibleFieldsLength - 1}
          transport={transport}
        />
      ))}
    </div>
  )
}

const renderBreakdownCard = ({ transport, field }: { transport: any, field: any }) => {
  const isTransportRent = transport?.type === 'RENT'
  const rentCurrency = isTransportRent ? transport?.rentData?.currency : null
  const companyType = field.name === 'taxBreakdown' ? TypeOfCompany.Client : TypeOfCompany.Supplier
  // exchangeRate from getExchangeRate is the default value. If we couldn't extract it from the transport object, it will be replaced in the breakdown component
  const exchangeRate = getExchangeRate({ priceDetails: transport.priceDetails, exchangeRate: 0, companyType, isTransportRent })
  return <BreakdownCardWrapper
    label={field.label + ':'}
    key={field.label}
    breakdownIdentifier={field.name}
    canEdit={false}
    tariffInfo={extractTariffInfo({ transport, taxCurrency: rentCurrency })}
    setTariffInfo={() => { }}
    initialExchangeRate={exchangeRate}
    chosenCurrency={(field.name === 'taxBreakdown') ? transport?.currency : transport?.supplier?.currency || 'RON'}
    baseCurrency={(field.name === 'taxBreakdown') ? (rentCurrency || extractBaseCurrency({ transport, companyType: field?.companyType })) : transport?.supplier?.currency || 'RON'}
    paymentType={(field.name === 'taxBreakdown') ? transport?.paymentType : 'CONTRACT'}
    gpsMonitored={transport?.gpsMonitored}
  />
}

export const Field = ({
  fieldName,
  isHidden,
  transport,
  lastRow = false,
}: {
  fieldName: string
  isHidden: boolean
  transport: TransportType
  lastRow?: boolean
}) => {
  let field = fields.find((field: any) => field.name == fieldName)
  if (!field) return <></>
  return (
    <div
      key={'row-' + field.name}
      style={{
        display: isHidden ? 'none' : 'flex',
        borderBottom: lastRow ? '' : '1px solid #f5f8fa',
      }}
    >
      {(field.name === 'taxBreakdown' || field.name === 'commissionBreakdown') && transport.id ? (
        renderBreakdownCard({ transport, field })
      ) : (
        <RowCell key={field.name} field={field} transport={transport} />
      )}
    </div>
  )
}

const RowCell = ({
  field,
  transport,
  isSecondary = false,
}: {
  field: any
  transport: TransportType
  isSecondary?: boolean
}) => {
  let content = null
  if (field.children) {
    content = field.children.map((subField: any) => {
      const subFieldKey = subField.name || subField.label
      return (
        <div key={'subField-container-' + subFieldKey} style={{ display: 'flex' }}>
          <RowCell
            key={'subField-' + subFieldKey}
            field={subField}
            transport={transport}
            isSecondary={true}
          />
        </div>
      )
    })
  }
  let fieldValue = field.getValue ? field.getValue(transport) : null
  return (
    <Cell
      label={field.label}
      value={fieldValue}
      isBinary={field.isBinary || false}
      isSecondary={isSecondary}
    >
      {content}
    </Cell>
  )
}

export const formatCurrency = ({ value, currency }: { value: number | string; currency?: string }) => {
  let parsedValue = parseFloat('' + value)
  if (isNaN(parsedValue)) {
    parsedValue = 0
  }
  const currencyString = Boolean(currency) ? ' ' + currency : ''
  return Number(value).toFixed(2).toString() + currencyString
}

const fields = [
  {
    name: 'idCompanyClient',
    label: 'Company',
    getValue: (transport: TransportType) => transport.companyName,
  },
  {
    name: 'requestorName',
    label: 'Requestor',
    getValue: (transport: TransportType) => transport.requestorName,
  },
  {
    name: 'requestorPhone',
    label: 'Requestor Phone',
    getValue: (transport: TransportType) => transport.requestorPhone,
  },
  {
    name: 'requestorEmail',
    label: 'Requestor Email',
    getValue: (transport: TransportType) => transport.requestorEmail,
  },
  {
    name: 'companyCustomField1Value',
    label: 'Requestor Reference',
    getValue: (transport: TransportType) => transport.companyCustomField1Value,
  },
  {
    name: 'companyCustomField2Value',
    label: 'Requestor Division',
    getValue: (transport: TransportType) => transport.companyCustomField2Value,
  },
  {
    name: 'companyCustomField3Value',
    label: 'Requestor Department',
    getValue: (transport: TransportType) => transport.companyCustomField3Value,
  },
  {
    name: 'companyCustomField4Value',
    label: 'Requestor Service',
    getValue: (transport: TransportType) => transport.companyCustomField4Value,
  },
  {
    name: 'companyCustomField5Value',
    label: 'Requestor Cost Center',
    getValue: (transport: TransportType) => transport.companyCustomField5Value,
  },
  {
    name: 'promoCode',
    label: 'Promo Code',
    getValue: (transport: TransportType) => transport.promoCode,
  },
  {
    name: 'courseMap',
    label: 'Course Map',
    getValue: (transport: TransportType) => (
      <ViewGoogleMapsButton
        startLocationCoords={transport.originCoords}
        endLocationCoords={transport.destinationCoords}
        waypointsCoordsArray={
          transport.intermediaryPoints
            ? transport.intermediaryPoints.map((poi: any) => poi.gps)
            : []
        }
      />
    )
  },
  {
    name: 'effectiveDateString',
    label: 'Effective date',
    getValue: (transport: TransportType) => {
      return transport.effectiveDate && moment(transport.effectiveDate).format('YYYY-MM-DD HH:mm')
    },
  },
  {
    name: 'originCoords',
    label: 'Origin GPS',
    getValue: (transport: TransportType) =>
      transport.originCoords && formatCoordComponent(transport.originCoords),
  },
  {
    name: 'origin',
    label: 'Pickup address',
    getValue: (transport: TransportType) => transport.origin,
  },
  {
    name: 'originAdditionalInfo',
    label: 'Additional info',
    getValue: (transport: TransportType) => transport.originAdditionalInfo,
  },
  {
    name: 'originGeoFence',
    label: 'Zone',
    getValue: (transport: TransportType) => transport.originGeoFence,
  },
  {
    name: 'destinationCoords',
    label: 'Destination GPS',
    getValue: (transport: TransportType) =>
      transport.destinationCoords && formatCoordComponent(transport.destinationCoords),
  },
  {
    name: 'destination',
    label: 'Destination address',
    getValue: (transport: TransportType) => transport.destination,
  },
  {
    name: 'destinationAdditionalInfo',
    label: 'Additional info',
    getValue: (transport: TransportType) => transport.destinationAdditionalInfo,
  },
  {
    name: 'destinationGeoFence',
    label: 'Zone',
    getValue: (transport: TransportType) => transport.destinationGeoFence,
  },
  {
    name: 'intermediaryPoints',
    label: 'Stops',
    getValue: (transport: TransportType) => (
      <StopsComponent
        poiList={transport?.intermediaryPoints}
        showWaitingTime={false}
        inline={true}
      />
    ),
  },
  {
    name: 'idTariffService',
    label: 'Service',
    getValue: (transport: TransportType) => transport.tariffServiceName,
  },
  {
    name: 'paymentTypeString',
    label: 'Payment',
    getValue: (transport: TransportType) => transport.paymentType,
  },
  {
    name: 'tariffString',
    label: 'Tariff',
    getValue: (transport: TransportType) =>
      `${getPriceFormatted(transport?.tariff).toString()} ${transport?.currency} ${hasIncludedVATByPaymentType(transport?.paymentType) ? 'VAT included' : 'Without VAT'
      }`,
  },
  {
    name: 'blockTariff',
    label: 'Block tarriff',
    getValue: (transport: TransportType) => transport.blockTariff,
    isBinary: true,
  },
  {
    name: 'currencyString',
    label: 'Currency',
    getValue: (transport: TransportType) => transport.currency,
  },
  {
    name: 'paymentStatusString',
    label: 'Payment status',
    getValue: (transport: TransportType) => transport.paymentStatus || defaultPaymentStatus,
  },
  {
    name: 'type',
    label: 'Transport Type',
    getValue: (transport: TransportType) => transport.type || defaultTransportType,
  },
  {
    name: 'idUser',
    label: 'Answered by',
    getValue: (transport: TransportType) => transport.user && transport.user.username,
  },
  {
    name: 'requestDateString',
    label: 'Requested on',
    getValue: (transport: TransportType) =>
      transport.requestDate && moment(transport.requestDate).format('DD-MM-YYYY HH:mm'),
  },
  {
    name: 'initialComment',
    label: 'Initial comment',
    getValue: (transport: TransportType) => {
      return transport?.assignedVehicle && transport?.histories?.length > 0
        ? transport.histories.at(-1).dispatchComment
        : ''
    },
  },
  {
    name: 'taxBreakdownTransferType',
    label: 'Pricing transfer type',
    getValue: (transport: TransportType) => transport?.taxBreakdownTransferType || 'Unknown',
  },
  {
    name: 'taxBreakdownOriginAgency',
    label: 'Origin Agency',
    getValue: (transport: TransportType) => transport?.taxBreakdownOriginAgency || 'Unknown',
  },
  {
    name: 'billedCustomer',
    label: 'Billed customer',
    getValue: (transport: TransportType) => transport.billedCustomer,
  },
  {
    name: 'customerAddress',
    label: 'Customer address',
    getValue: (transport: TransportType) => transport.customerAddress,
  },
  {
    name: 'paymentNote',
    label: 'Payment note',
    getValue: (transport: TransportType) => transport.paymentNote,
  },
  {
    name: 'invoiceNumber',
    label: 'Invoice number',
    getValue: (transport: TransportType) => transport.invoiceNumber,
  },
  {
    name: 'commission',
    label: 'Commission',
    getValue: (transport: TransportType) => {
      const currentValue = getPriceFormatted(transport?.commission).toString()
      const commissionCurrency = transport?.supplier?.currency || 'RON'
      return `${currentValue} ${commissionCurrency} Without VAT`
    },
  },
  {
    name: 'blockCommission',
    label: 'Block commission',
    getValue: (transport: TransportType) => transport.blockCommission,
    isBinary: true,
  },
  {
    name: 'taxBreakdown',
    label: 'Tariff Breakdown',
    companyType: TypeOfCompany.Client,
  },
  {
    name: 'commissionBreakdown',
    label: 'Commission Breakdown',
    companyType: TypeOfCompany.Supplier,
  },
  {
    name: 'supplier',
    label: 'Supplier',
    getValue: (transport: TransportType) => transport?.supplier?.name || 'No Supplier',
  },
  {
    name: 'revenueCenter',
    label: 'Revenue Center',
    getValue: (transport: TransportType) => transport?.revenueCenter || 'No Revenue Center',
  },
  {
    name: 'freeCancellation',
    label: 'Free cancellation (hours)',
    getValue: (transport: TransportType) => transport.freeCancellation,
  },
  {
    name: 'passengerName',
    label: 'Name',
    getValue: (transport: TransportType) => extractPassengerNames(transport.passengerName).mainPassenger,
  },
  {
    name: 'language',
    label: 'Language',
    getValue: (transport: TransportType) => transport.language,
  },
  {
    name: 'passengerPhone',
    label: 'Passenger phone',
    getValue: (transport: TransportType) => transport.passengerPhone,
  },
  {
    name: 'passengerEmail',
    label: 'Passenger email',
    getValue: (transport: TransportType) => transport.passengerEmail,
  },
  {
    name: 'passengerReference',
    label: 'Passenger/Flight Reference',
    getValue: (transport: TransportType) => transport.passengerReference,
  },
  {
    name: 'passengers',
    label: 'Number of passengers',
    getValue: (transport: TransportType) => transport.passengers,
  },
  {
    name: 'otherPassengers',
    label: 'Other Passengers',
    getValue: (transport: TransportType) => extractPassengerNames(transport.passengerName).otherPassengers.join(', '),
  },
  {
    name: 'vip',
    label: 'VIP',
    getValue: (transport: TransportType) => transport.vip,
    isBinary: true,
  },
  {
    name: 'assignmentBlocked',
    label: 'Assignment blocked',
    getValue: (transport: TransportType) => transport.assignmentBlocked,
    isBinary: true,
  },
  {
    name: 'driverNotification',
    label: 'Driver notification',
    getValue: (transport: TransportType) => transport.driverNotification,
  },
  {
    name: 'greeter',
    label: 'Greeter',
    children: [
      {
        label: 'Logo:',
        getValue: (transport: TransportType) => transport.greeterSignLogo,
        isBinary: true,
      },
      {
        label: 'Name:',
        getValue: (transport: TransportType) => transport.greeterSignName,
        isBinary: true,
      },
      {
        label: 'Other:',
        getValue: (transport: TransportType) => transport.greeterSignOther,
      },
    ],
  },
  {
    name: 'greeterSignActivations',
    label: 'Greeter Sign Activations',
    getValue: (transport: TransportType) => (
      <GreeterSignList
        hideLabel={true}
        greeterSignActivations={transport?.greeterSignActivations || []}
        field={{ name: 'driverTransportStops', label: 'Driver Stops' }}
      />
    ),
  },
  {
    name: 'hasUnsolvedIssues',
    label: 'Has unsolved issues',
    getValue: (transport: TransportType) => transport.hasUnsolvedIssues,
    isBinary: true,
  },
  {
    name: 'requestStatusString',
    label: 'Status',
    getValue: (transport: TransportType) => transport.requestStatus,
  },
  {
    name: 'idTariffType',
    label: 'Service type',
    getValue: (transport: TransportType) => transport.tariffTypeId,
  },
  {
    name: 'duration',
    label: 'Duration',
    getValue: (transport: TransportType) => getFormatedDurationString(transport.duration),
  },
  {
    name: 'systemEstimatedDuration',
    label: 'System estimation duration',
    getValue: (transport: TransportType) => transport.systemEstimatedDuration,
  },
  {
    name: 'systemEstimatedDurationBreakdown',
    label: 'Breakdown',
    getValue: (transport: TransportType) => transport.systemEstimatedDurationBreakdown,
  },
  {
    name: 'completeEstimateDateString',
    label: 'Complete estimate date',
    getValue: (transport: TransportType) => transport.completeEstimateDate
      && moment(transport.completeEstimateDate).format('YYYY-MM-DD HH:mm'),
  },
  {
    name: 'distance',
    label: 'Distance',
    getValue: (transport: TransportType) => transport.distance,
  },
  {
    name: 'stops',
    label: 'Stops',
    getValue: (transport: TransportType) => transport.stops,
  },
  {
    name: 'stopsCost',
    label: 'Stops Cost',
    getValue: (transport: TransportType) =>
      formatCurrency({ value: transport.stopsCost, currency: transport.currency }),
  },
  {
    name: 'wait',
    label: 'Wait',
    getValue: (transport: TransportType) => transport.wait,
  },
  {
    name: 'waitCost',
    label: 'Wait Cost',
    getValue: (transport: TransportType) =>
      formatCurrency({ value: transport.waitCost, currency: transport.currency }),
  },
  {
    name: 'estimatedWait',
    label: 'Estimated wait',
    getValue: (transport: TransportType) => transport.estimatedWait,
  },
  {
    name: 'idVehicleAssigned',
    label: 'Assigned vehicle',
    getValue: (transport: TransportType) =>
      transport.assignedVehicle && transport.assignedVehicle.businessName,
  },
  {
    name: 'idDriverAssigned',
    label: 'Assigned driver',
    getValue: (transport: TransportType) => transport.assignedDriver && transport.assignedDriver.fullName,
  },
  {
    name: 'transferTypeString',
    label: 'Transfer type',
    getValue: (transport: TransportType) => transport.transferType,
  },
  {
    name: 'comment',
    label: 'Comment',
    getValue: (transport: TransportType) => transport.comment,
  },
  {
    name: 'requestRemarkString',
    label: 'Request remark',
    getValue: (transport: TransportType) => transport.requestRemark,
  },
  {
    name: 'dispatchComment',
    label: 'Dispatch comments',
    getValue: (transport: TransportType) => transport.dispatchComment,
  },
  {
    name: 'delayAnnounced',
    label: 'Delay Announced',
    children: [
      {
        label: 'Has announced delay: ',
        getValue: (transport: TransportType) => transport.delayAnnounced,
        isBinary: true,
      },
      {
        label: 'Delay announced timestamp: ',
        getValue: (transport: TransportType) => transport.delayAnnounced && transport.delayAnnouncedTimestamp,
      },
    ],
  },
  {
    name: 'announcedDelay',
    label: 'Announced Delay',
    children: [
      { label: 'Announced delay: ', getValue: (transport: TransportType) => transport.announcedDelay },
      { label: 'Real delay (min): ', getValue: (transport: TransportType) => transport.realDelay },
    ],
  },
  {
    name: 'responsible',
    label: 'Assign responsible',
    getValue: (transport: TransportType) => transport.user && transport.user.username,
  },
  {
    name: 'assistanceVehiclePlateNumber',
    label: 'Plate number',
    getValue: (transport: TransportType) => transport.assistanceVehiclePlateNumber,
  },
  {
    name: 'assistanceVehicleVin',
    label: 'VIN',
    getValue: (transport: TransportType) => transport.assistanceVehicleVin,
  },
  {
    name: 'assistanceVehicleBrand',
    label: 'Brand',
    getValue: (transport: TransportType) => transport.assistanceVehicleBrand,
  },
  {
    name: 'assistanceVehicleModel',
    label: 'Model',
    getValue: (transport: TransportType) => transport.assistanceVehicleModel,
  },
  {
    name: 'assistanceVehicleType',
    label: 'Type',
    getValue: (transport: TransportType) => transport.assistanceVehicleType,
  },
  {
    name: 'assistanceVehicleManufactureYear',
    label: 'Manufacture year',
    getValue: (transport: TransportType) => transport.assistanceVehicleManufactureYear,
  },
  {
    name: 'assistanceVehicleDepartment',
    label: 'Department',
    getValue: (transport: TransportType) => transport.assistanceVehicleDepartment,
  },
  {
    name: 'assistanceVehicleLocation',
    label: 'Location',
    getValue: (transport: TransportType) => transport.assistanceVehicleLocation,
  },
  {
    name: 'assistanceVehicleReplacementClass',
    label: 'Replacement class',
    getValue: (transport: TransportType) => transport.assistanceVehicleReplacementClass,
  },
  {
    name: 'assistanceVehicleReplacementDays',
    label: 'Replacement days',
    getValue: (transport: TransportType) => transport.assistanceVehicleReplacementDays,
  },
  {
    name: 'assistanceVehicleRoadAssistDescription',
    label: 'Road assist description',
    getValue: (transport: TransportType) => transport.assistanceVehicleRoadAssistDescription,
  },
  {
    name: 'assistanceVehicleDtdDescription',
    label: 'DTD Description',
    getValue: (transport: TransportType) => transport.assistanceVehicleDtdDescription,
  },
  {
    name: 'replacementVehiclePlateNumber',
    label: 'Replacement plate',
    getValue: (transport: TransportType) => transport.replacementVehiclePlateNumber,
  },
  {
    name: 'replacementVehicleBrand',
    label: 'Brand',
    getValue: (transport: TransportType) => transport.replacementVehicleBrand,
  },
  {
    name: 'replacementVehicleModel',
    label: 'Model',
    getValue: (transport: TransportType) => transport.replacementVehicleModel,
  },
  {
    name: 'replacementVehicleLocation',
    label: 'Location',
    getValue: (transport: TransportType) => transport.replacementVehicleLocation,
  },
  {
    name: 'replacementVehicleReturnEstimateDateString',
    label: 'Return estimate date',
    getValue: (transport: TransportType) => transport.replacementVehicleReturnEstimateDate,
  },
  {
    name: 'emissions',
    label: 'Emissions',
    getValue: (transport: TransportType) => transport.emissions,
  },
  {
    name: 'applicantId',
    label: 'Applicants',
    getValue: (_: any) => '-',
  },
  {
    name: 'denierId',
    label: 'Deniers',
    getValue: (_: any) => '-',
  },
  {
    name: 'delayerDriver',
    label: 'Status',
    getValue: (_: any) => 'Delayed by Driver',
  },
  {
    name: 'alert',
    label: 'Alert by driver',
    getValue: (_: any) => '',
  },
  {
    name: 'startOdometer',
    label: 'Start Odometer',
    getValue: (transport: TransportType) => transport?.startOdometer || '',
  },
  {
    name: 'endOdometer',
    label: 'End Odometer',
    getValue: (transport: TransportType) => transport?.endOdometer || '',
  },
  {
    name: 'driverKm',
    label: 'Driver KM',
    getValue: (transport: TransportType) =>
      getDriverKm({
        startOdometer: transport?.startOdometer,
        endOdometer: transport?.endOdometer,
      }),
  },
  {
    name: 'gpsKm',
    label: 'GPS KM',
    getValue: (transport: TransportType) => transport?.gpsKm,
  },
  {
    name: 'googleReadyDistance',
    label: 'Google Ready distance',
    getValue: (transport: TransportType) => transport?.googleReadyDistance,
  },
  {
    name: 'gpsReadyDistance',
    label: 'GPS Ready distance',
    getValue: (transport: TransportType) => transport?.gpsReadyDistance,
  },
  {
    name: 'driverTransportStops',
    label: 'Driver Stops',
    getValue: (transport: TransportType) => (
      <StopsList
        hideLabel={true}
        title='Driver Stops'
        stopsList={transport?.driverTransportStops || []}
        field={{ name: 'driverTransportStops', label: 'Driver Stops' }}
      />
    ),
  },
  {
    name: 'systemTransportStops',
    label: 'System Stops',
    getValue: (transport: TransportType) => (
      <StopsList
        hideLabel={true}
        title='System Stops'
        stopsList={transport?.systemTransportStops || []}
        field={{ name: 'systemTransportStops', label: 'System Stops' }}
      />
    ),
  },
  {
    name: 'gpsMonitored',
    label: 'GPS Monitored',
    getValue: (transport: TransportType) => transport.gpsMonitored,
    isBinary: true,
  },
  // rent fields
  {
    name: 'rentalPeriod',
    label: 'Rental Period',
    getValue: (transport: TransportType) => {
      const duration = Math.ceil(getDifferenceInDays(transport.effectiveDate, transport.completeEstimateDate))
      return `${duration} days`
    }
  },
  {
    name: 'vehicleClass',
    label: 'Vehicle Class',
    getValue: (transport: TransportType) => transport?.rentData?.vehicle_class?.name || ''
  },
  {
    name: 'extraOptions',
    label: 'Extras',
    getValue: (transport: TransportType) => <OptionsListViewMode options={transport?.rentData?.extra_costs || []} currency={transport?.rentData?.currency} />
  },
  {
    name: 'dislocationType',
    label: 'Dislocation Type',
    getValue: (transport: TransportType) => transport?.rentData?.dislocation_type || 'Unknown'
  },
  {
    name: 'insurance',
    label: 'Insurance',
    getValue: (transport: TransportType) => transport?.rentData?.insurance?.insurance_name || ''
  },
  {
    name: 'insuranceType',
    label: 'Insurance Type',
    getValue: (transport: TransportType) => (transport?.rentData?.liability_type || []).join('; ')
  },
  {
    name: 'discount',
    label: 'Discount',
    getValue: (transport: TransportType) => `${(transport?.rentData?.discount || 0) * 100}%`,
  },
]

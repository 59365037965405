import { FC, useEffect, useState } from 'react'
import Table from './Table'
import { createRentPlanIntervals, deleteRentPlanDayCost, getIntervalByStartDay, getRentPlanInterval, putRentTariffs } from '../../../setup/axios/rent.request'
import { RentPlanTariffsModel, emptyRentPlanTariffs } from '../../../app/modules/rent-plans/models/RentPlanTariffsModel'
import { updateField } from '../../../utils/state.utils'
import { getStringOfEntity } from '../../../utils/string.utils'
import { RentPlanIntervalModel } from '../../../app/modules/rent-plans/models/RentPlanIntervalModel'
import { useParams } from 'react-router-dom'
import { usePlanContext } from '../../../context/plan.context'

const compoundUniqueKeyTariffs = ['name']

const tariffsRequiredFields = [
    'start_day'
]

const getTariffsLabel = () => "Tariffs";

const RentPlanTariffsFieldToProperty = (
    field: any,
    rentPlanTariffs: RentPlanTariffsModel,
    setrentPlanTariffs: Function,
    setDisableEndDay: Function
) => {
    let fieldName = field.name
    const updateExtrasListElemEvent = (e: any) =>
        updateField({ value: e, fieldName: fieldName, setObject: setrentPlanTariffs })

    const startsWithNumberExpression: RegExp = /^\d/;
    const intervalsArray = Object.keys(rentPlanTariffs).filter(key => startsWithNumberExpression.test(key))
   
    const intervalCosts = intervalsArray
        .reduce((acc, key) => {
            return ({
                ...acc, [key]: {
                    value: rentPlanTariffs[key],
                    inputAdditionalValue: rentPlanTariffs[key] ?? "0"
                }
            })
        }, {});


    const fieldsToPanelPropertiesMapping = {
        start_day: {value: getStringOfEntity({ entity: rentPlanTariffs.start_day })},
        disable_end_day: {
            value: getStringOfEntity({ entity: rentPlanTariffs.disable_end_day }),
            effectOverValueOnChange: (value) => {
                setDisableEndDay(value);
                return value;
            }
        },
        end_day: {value: getStringOfEntity({ entity: rentPlanTariffs.end_day })},
        ...{ ...intervalCosts }
    }
    return (fieldsToPanelPropertiesMapping as any)[fieldName]
}

const RentTariffsTable: FC<{}> = ({ }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { id, companyId } = useParams<any>()
    const [toBeEdited, setToBeEdited] = useState<any>();
    const [disableEndDay, setDisableEndDay] = useState(false);
    const {
        rentCompanyPricingPlan,
        setRentCompanyPricingPlan,
        setInitialTariffs,
        intervalsData,
        setIntervalsData
      } = usePlanContext()

    const fetchIntervals = async () => {
        const startsWithNumberExpression: RegExp = /^\d/;
        return getRentPlanInterval(id).then((futureRentsetRentPlanTariffs: RentPlanIntervalModel[] | null) => {
            updateTariffs(futureRentsetRentPlanTariffs);
            setInitialTariffs(futureRentsetRentPlanTariffs);
            if (!futureRentsetRentPlanTariffs.length) {
                setIsLoading(false);
                return;
            }
            const intervalsArray = Object.keys(futureRentsetRentPlanTariffs[0]).filter(key => startsWithNumberExpression.test(key));
            let intervalPromises = [];
            for (const intervalDays of intervalsArray) {
                const days = intervalDays.match(/\d+/);
                const start_day = days[0];
                intervalPromises = intervalPromises.concat(getIntervalByStartDay(start_day, id));
            }
            Promise.all(intervalPromises).then(intervals => setIntervalsData(intervals.map((interval, idx) =>
            ({
                id: interval.id, intervalDays: intervalsArray[idx]
            }))))
            setIsLoading(false)
        })
        .catch(e => {
            updateTariffs([]);
        })
    }
    const updateTariffs = (value) => setRentCompanyPricingPlan({...rentCompanyPricingPlan, rentPlanIntervals: value ?? []})
    useEffect(() => {
        fetchIntervals();
    }, [])

    const startsWithNumberExpression: RegExp = /^\d/;
    let tariffsCostProps = [];
    if (rentCompanyPricingPlan?.rentPlanIntervals?.length) {
        const namesArray = Object.keys(rentCompanyPricingPlan.rentPlanIntervals.find(rpt => true)).filter(key => startsWithNumberExpression.test(key));
        tariffsCostProps = namesArray.map((key, idx) => ({
            name: key,
            getDisplayLabel: (cost: string) => cost,
            label: key,
            type: 'currencyInput',
            customValue: intervalsData[idx],
            editOnColumn: true,
            canEditInView: true,
            addOns: { append: rentCompanyPricingPlan.currency },
            minWidth: '135px',
            addable: false
        }))

    }

    const tariffsProps = [
        {
            name: 'name',
            getDisplayLabel: (name: string, row: any) => {
                return row.image_url ? <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom:10}}> <img src={row.image_url} style={{ marginRight: 14, width: 40, height: 40, marginBottom: 20 }} />{row.name}</div> : <div>{row.name}</div>
            },
            label: 'Class name',
            editable: false
        },
        {
            name: 'start_day',
            type: 'input',
            label: "Start day",
            hideInTable: true,
            editable: true
        },
        {
            name: 'disable_end_day',
            label: "Don't want to have an end day to the interval?",
            type: 'checkbox',
            isCheckboxValueProp: true,
            hideColon: true,
            style: {marginTopCheckbox: 10, marginTopLabel: 12},
            hideInTable: true,
            hidden: false,
            required: false,
            editable: true,
            addable: true
        },
        {
            name: 'end_day',
            type: 'input',
            label: "End day",
            hideInTable: true,
            editable: true,
            disabled: disableEndDay
        },
        ...[...tariffsCostProps]
    ]

    return (
        <>
            <Table
                tableLabel={'Tariffs'}
                properties={tariffsProps}
                fieldToProperty={(field, object, setObjectFunc) => RentPlanTariffsFieldToProperty(field, object, setObjectFunc, setDisableEndDay)}
                entries={rentCompanyPricingPlan.rentPlanIntervals}
                setEntries={updateTariffs}
                emptyEntry={emptyRentPlanTariffs}
                getEntryLabel={getTariffsLabel}
                requiredFields={tariffsRequiredFields}
                compoundUniqueKey={compoundUniqueKeyTariffs}
                deleteEndpoint={() => deleteRentPlanDayCost(toBeEdited.id).then(async () => await fetchIntervals())}
                editEndpoint={putRentTariffs}
                createEndpoint={async (value) => {
                   const intervalResponse = await createRentPlanIntervals(value, id, companyId);
                   await fetchIntervals();
                   return intervalResponse;
                }}
                isPlanCreated= {!!id}
                isTariffs={true}
                addEntryLabel= "Add Intervals"
                addEditOnColumn
                onEditCustom={setToBeEdited}
                resetStateValue={false}
                onSaveResetState={setDisableEndDay}
            />
        </>
    )

}

export default RentTariffsTable


export const fields = [
  {
    name: 'idPoiIntermediary',
    label: 'Intermediary point of interest',
    type: 'inputCompleteFields',
    propertyField: 'additionalInfo',
    inputType: 'text',
  },
  {
    name: 'locationField',
    label: 'Intermediary Address',
    type: 'geolocation',
    required: true,
    coordsKey: 'intermediaryCoords',
    additionalInfoKey: 'intermediaryAddressDetails',
  },
  {
    name: 'intermediaryPoiName',
    label: 'POI Name',
    type: 'input',
    inputType: 'text',
  },
  {
    name: 'intermediaryEstimatedWait',
    label: 'Estimated Wait',
    type: 'input',
    inputType: 'number',
    min: 0,
  },
  {
    name: 'intermediaryPriority',
    label: 'Order',
    type: 'input',
    inputType: 'number',
    min: 1,
  },
]

/*
 *  Get the counterpart of the object properties from companyPOI to transport POI
 */
export const getComplementaryKeyName = (propName: string) : string => {
  switch(propName) {
    case "addressDetails": return "additionalInfo";
    case "additionalInfo": return "addressDetails";
    case           "name": return "poiName";
    case        "poiName": return "name";
    default              : return propName;
  }
}

export interface POI {
  id: number,
  companyId: number,
  address: string
  addressDetails: string
  gps: string
  poiName: string
  estimatedWait: number
  priority: number
  geoFence?: any
  uniqueId: string
}

export const emptyPOI: POI = {
  id: -1,
  companyId: -1,
  address: '',
  addressDetails: '',
  gps: '',
  poiName: '',
  estimatedWait: 0,
  priority: 0,
  geoFence: null,
  uniqueId: ''
}

export interface companyPOI {
  id: number,
  companyId: number,
  address: string
  additionalInfo: string
  gps: string
  name: string
}

export const emptyCompanyPOI: any = {
  id: -1,
  companyId: -1,
  address: '',
  additionalInfo: '',
  gps: '',
  name: ''
}

export const convertPOItoCompanyPOI = (poi: POI) : companyPOI => {
  let newCompanyPOI = Object({...emptyCompanyPOI});
  let objPOI = Object({...poi});
  let keys = Object.keys(emptyCompanyPOI);
  keys.forEach(key => {
    let poiKeyName = getComplementaryKeyName(key);
    newCompanyPOI[key] = objPOI[poiKeyName];
  })
  return newCompanyPOI;
}

export const getInjectedCompanyPOItoPOI = (companyPOI: companyPOI, poi: POI) : POI => {
  let newPOI = Object({...poi});
  let objCompanyPOI = Object({...companyPOI});
  let keys = Object.keys(emptyCompanyPOI);
  keys.forEach(key => {
    let poiKeyName = getComplementaryKeyName(key);
    newPOI[poiKeyName] = objCompanyPOI[key];
  })
  return newPOI;
}

export const areEqualPOIS = (point1: POI, point2: POI) => {
  let keys = Object.keys(emptyPOI)
  let point1Obj = Object(point1)
  let point2Obj = Object(point2)
  for (let i = 0; i < keys.length; i++) {
    let key: string = keys[i]
    if (point1Obj[key] !== point2Obj[key]) {
      return false
    }
  }
  return true
}

export const convertFieldName = {
  idPoiIntermediary: 'id',
  locationField: 'address',
  intermediaryPoiName: 'poiName',
  intermediaryAddressDetails: 'addressDetails',
  intermediaryCoords: 'gps',
  intermediaryEstimatedWait: 'estimatedWait',
  intermediaryPriority: 'priority',
}

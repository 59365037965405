import axios from 'axios'
import { PUBLIC_URL } from '../constants/api.constants';
// const axios = require('axios');

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 60s. If the request takes longer than
// that then the request will be aborted.
const customAxios = axios.create({
  timeout: 60000,
})

// Step-2: Create request, response & error handlers
const requestHandler = (request) => {
  // Token will be dynamic so we can use any app-specific way to always
  // fetch the new token before making the call
  const token = window.localStorage['jwt'] ? JSON.parse(window.localStorage['jwt']) : null;
  request.headers.Authorization = `Bearer ${token}`

  return request
}

const responseHandler = (response) => {

  if (response.status === 401) {
    window.localStorage.clear()
    window.location = PUBLIC_URL
  }

  return response
}

const errorHandler = (error) => {
  const errorStatus = error.response?.status
  // 401 - unauthorized; 403 - unauthenticated
  if (errorStatus === 401 || errorStatus === 403) {
    window.localStorage.clear()
    window.location = PUBLIC_URL
    return
  }
  return Promise.reject(error)
}

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
)

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
)

// Step-4: Export the newly created Axios instance to be used in different locations.
export default customAxios

import moment from 'moment'
import { DateObject } from 'react-multi-date-picker'

export const getDateObj = (date: string | Date) => {
  if (date === null || date === undefined) {
    date = new Date()
  }
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return {
    seconds: date.getSeconds(),
    minutes: date.getMinutes(),
    hour: date.getHours(),
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
    yearShort: date.getFullYear().toString().slice(-2),
  }
}

export const getNextDay = (date) => {
  const newDate = new DateObject()
  const { year, month, day } = new DateObject(date)
  newDate.set({ year, month: month.number, day })
  newDate.add(1, 'day')
  return newDate
}

export const getAdjustedDateByDays = (date: string | number | Date, days: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

export const formatDate = ({
  date,
  format = 'YYYY-MM-DD HH:mm',
}: {
  date: string | Date
  format?: string
}) => {
  return moment(date).format(format)
}

export const formatTimeStringFromMinutes = (minutes: number) => {
  let hours = Math.floor(minutes / 60)
  minutes -= hours * 60
  if (hours === 0) return `${minutes} min`

  let formattedMinutes = minutes.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })
  return `${hours} h ${formattedMinutes} min`
}

export const getFormatedDurationString = (duration: number) => {
  if (!duration) {
    return ''
  }
  let minutes = duration
  let hours = Math.floor(minutes / 60)
  let days = Math.floor(hours / 24)

  minutes %= 60
  hours %= 24

  if (days) {
    return `${days} d ${hours} h ${minutes} min`
  } else if (hours) {
    return `${hours} h ${minutes} min`
  }
  return `${minutes} min`
}

export const isWeekDay = (day: number, currentDate: moment.Moment) => {
  if (!currentDate.format('DD-MM-YYYY').localeCompare(moment().format('DD-MM-YYYY'))) {
    if (day === 7) {
      currentDate = moment(currentDate).subtract(2, 'day')
    }
    if (day === 1) {
      currentDate = moment(currentDate).subtract(3, 'day')
    } else {
      currentDate = moment(currentDate).subtract(1, 'day')
    }
  }
  return currentDate
}

export const getDifferenceInDays = (startDateString: string, endDateString: string, inclusive?: boolean): number => {
  const startDate = new Date(startDateString)
  const endDate = new Date(endDateString)

  let diff = endDate.getTime() - startDate.getTime()
  if(Boolean(inclusive)) {
    const epsilon = 60 * 1000 // 1 minute
    diff += epsilon
  }
  const numOfDays = diff / (1000 * 3600 * 24)

  return numOfDays
}

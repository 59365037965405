import * as Sentry from '@sentry/react'
import {severityFromString} from '@sentry/utils'

export const logError = ({name = 'Eroare fără nume', error={}, type = 'error'}) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  } else {
    Sentry.captureMessage(name, {
      contexts: error,
      level: severityFromString(type),
    })
  }
}

export const REGEX_URL: any =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/

export const capitalize = (str: string): string =>
  str?.split('')
    .map((e, i) => (i === 0 ? e.toUpperCase() : e))
    .join('')

export const titleCase = (str: string): string =>
  str?.toLowerCase()
    .replace(/(?:^|\s|-)\w/g, (match) => match.toUpperCase());


export const formatCurrency = (paramValue: string): string => {
  const value = parseFloat(paramValue)

  if (isNaN(value)) {
    return '0.00'
  }

  return Number(value).toFixed(2).toString()
}

export const formatNumber = ({ paramValue, minValue, maxValue }: { paramValue: string, minValue?: number, maxValue?: number }): string => {
  let value = parseInt(paramValue)

  if (isNaN(value)) {
    value = 0
  }

  if (minValue == null) {
    minValue = value
  }

  if (maxValue == null) {
    maxValue = value
  }

  const clampedValue = Math.min(Math.max(value, minValue), maxValue)

  return clampedValue.toString()
}

export const safeJSONParseToObj = (givenJson: string) => {
  try {
    const parsedJson = JSON.parse(givenJson)
    if (Boolean(parsedJson) && typeof parsedJson === 'object') {
      return parsedJson
    }
  } catch {
    return {}
  }
}

export const numberToStr = (value: number | string): string => '' + (value || '0')
export const strToBool = (value: string | number | boolean): boolean =>
  typeof (value) === "boolean"
    ? value
    : Boolean(parseInt(numberToStr(value)))

export const formatStatusName = (status: string): string => {
  if (status === 'ACKNOWLEDGED') {
    return 'ACK'
  }

  return status
}

export const getURLFormattedTemplateName = (templateName: string): string => {
  // Trim the string
  let convertedTemplateName = templateName.trim();

  // Convert spaces into "-"
  convertedTemplateName = convertedTemplateName.replace(/\s+/g, '-');

  // Convert all diacritics into their normal counterparts
  convertedTemplateName = convertedTemplateName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

  // Replace non-alphanumeric or non-special characters with "$"
  convertedTemplateName = convertedTemplateName.replace(/[^0-9a-zA-Z$\-_.+!*'()]/g, '$');

  // Convert to lowercase
  convertedTemplateName = convertedTemplateName.toLowerCase();

  return convertedTemplateName;
}

export const getArrayOfItemsString = ({
  list,
  maxLength = -1,
  crop = false,
}: {
  list: any[]
  maxLength?: number
  crop?: boolean
}) => {
  if (!list || !Array.isArray(list) || list.length === 0) {
    return ''
  }
  let futureString = list[0] + ''
  for (let i = 1; i < list.length; i++) {
    if (maxLength > 0 && futureString.length + 4 > maxLength) {
      break
    }
    futureString += ', ' + list[i]
  }
  return crop && maxLength > 0 ? futureString.substring(0, maxLength) + '...' : futureString
}

export const getStringOfEntity = ({
  entity,
  identifier = null,
  emptyStringReplacement = '',
}: {
  entity: any
  identifier?: string
  emptyStringReplacement?: string
}) => {
  let toBeProcessed = identifier ? entity?.[identifier] : entity
  return toBeProcessed === null || toBeProcessed === undefined || toBeProcessed === ''
    ? emptyStringReplacement
    : toBeProcessed + ''
}

export const getArrayOfObjectsString = ({
  list,
  identifier = null,
  maxLength = -1,
  emptyStringReplacement = 'ALL',
  crop = false,
}: {
  list: any[]
  identifier?: string
  emptyStringReplacement?: string
  maxLength?: number
  crop?: boolean
}) => {
  if (!list || !Array.isArray(list) || list.length === 0) {
    return ''
  }
  let futureString = getStringOfEntity({ entity: list[0], identifier, emptyStringReplacement })
  for (let i = 1; i < list.length; i++) {
    if (maxLength > 0 && futureString.length + 4 > maxLength) {
      break
    }
    let elemToString = getStringOfEntity({ entity: list[i], identifier, emptyStringReplacement })
    futureString += ', ' + elemToString
  }
  return crop && maxLength > 0 ? futureString.substring(0, maxLength) + '...' : futureString
}

export const anyToBoolean = (checkValue: string | boolean | number) => {
  if (checkValue === "0" || checkValue === "false") {
    return false
  }
  return Boolean(checkValue)
}

export const sanitizeAndFormatString = (stringInput: string, formatType: 'upper' | 'lower' = 'upper'): string => {
  // Use a regular expression to match and replace special characters with spaces
  const sanitizedString = stringInput.replace(/[^a-zA-Z0-9]/g, ' ');

  // Remove multiple spaces and trim the string
  const trimmedString = sanitizedString.replace(/\s+/g, ' ').trim();

  // Replace spaces with underscores
  let formattedString = trimmedString.replace(/\s/g, '_')

  if (formatType === 'upper') {
    formattedString = formattedString.toUpperCase();
  } else if (formatType === 'lower') {
    formattedString = formattedString.toLowerCase();
  }

  return formattedString;
}

export const toTitleCase = (inputString: string) => {
  return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
}
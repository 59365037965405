import React, { useCallback, useContext, useEffect, useState } from 'react'
import { DateObject } from 'react-multi-date-picker'
import { Card } from '../../add-edit-transport/scripts/structuralFields'
import { SubscriptionType, statusList, emptySubscription, UserCompanyType, PaymentType, isOfStripeStatusRefunded, isOfStripeStatusPaid, stripeStatusRefund } from '../../../../types/subscription.types'
import { UserRole } from '../../../../context/user.context'
import { SubscriptionsContext } from '../../../../context/subscriptions.context'
import { Field } from '../utils'
import { CompanyType, PackageType, PeriodUnit } from '../../../../types/company.types'
import { toast } from 'react-toastify'
import { FieldType } from '../../../../utils/field.utils'
import RefundModal from './RefundModal'

const DEFAULT_VALABILITY_PERIOD = 12;
const DEFAULT_VALABILITY_UNIT = PeriodUnit.Months;

export enum CompanyName {
  AutonomAssistance = 'Autonom Assistance',
  TransilvaniaBroker = 'Transilvania Broker',
  NouaSaseNouaCinci = '9695',
}

const getModifiedDatesFromActivationDate = (activatedDate: DateObject, packageValability: number, packagePeriodUnit: PeriodUnit) => {
  const formattedActivatedDate = activatedDate.format('YYYY-MM-DD HH:mm:00.000');

  let expirationDate = activatedDate.add(packageValability, packagePeriodUnit);
  if (packagePeriodUnit !== PeriodUnit.Days) {
    expirationDate = expirationDate.add(-1, 'days');
  }
  const formattedExpirationDate = expirationDate.format('YYYY-MM-DD HH:mm:00.000');

  return {
    formattedActivatedDate,
    formattedExpirationDate,
  }
}

// the two onChange Functions can be reduced to follow a single flow (now we have duplicated code - can be refactorized further)
const onChangeActivationDate = ({
  newActivatedDate,
  setSubscription,
  packageValability,
  packagePeriodUnit,
}: {
  newActivatedDate: DateObject
  setSubscription: Function,
  packageValability: number,
  packagePeriodUnit: PeriodUnit
}) => {
  const { formattedActivatedDate, formattedExpirationDate } =
    getModifiedDatesFromActivationDate(newActivatedDate, packageValability, packagePeriodUnit)

  setSubscription((oldSubscription: SubscriptionType) => ({
    ...oldSubscription,
    activated_at: formattedActivatedDate,
    expiration_date: formattedExpirationDate,
  }))
}

const updateMinDateForSubscriptionBoughtDate = (
  boughtAtDate: DateObject | Date | string | number,
  setSubscriptionFields: Function
) => {
  const today = new DateObject().add(1, 'days').toDate()
  const requestedDate = new DateObject(boughtAtDate).add(1, 'days').toDate()
  const minDate: Date = today > requestedDate ? today : requestedDate
  setSubscriptionFields((oldSubscriptionFields: FieldType[]) => oldSubscriptionFields.map((field) => {
    if (field.name === 'activated_at' || field.name === 'expiration_date') {
      return {
        ...field,
        minDate: minDate,
      }
    }
    return field
  }))
}

const getDatesAfterBoughtDateChange = ({
  newBoughtAtDate,
  packageValability,
  packagePeriodUnit,
  setSubscriptionFields
}: {
  newBoughtAtDate: DateObject
  packageValability: number
  packagePeriodUnit: PeriodUnit
  setSubscriptionFields: Function
}) => {
  const boughtAtDate = newBoughtAtDate
  const formattedBoughtAtDate = boughtAtDate.format('YYYY-MM-DD HH:mm:00.000')

  const activatedDate = boughtAtDate.add(1, 'day')

  const today = new DateObject().add(1, 'days').toDate()
  const requestedDate = activatedDate.toDate()
  const minDate: Date = today > requestedDate ? today : requestedDate

  const { formattedActivatedDate, formattedExpirationDate } = getModifiedDatesFromActivationDate(
    new DateObject(minDate),
    packageValability,
    packagePeriodUnit
  )

  updateMinDateForSubscriptionBoughtDate(formattedBoughtAtDate, setSubscriptionFields)

  return {
    formattedBoughtAtDate,
    formattedActivatedDate,
    formattedExpirationDate
  }
}

const onChangeBoughtDate = ({
  newBoughtAtDate,
  setSubscription,
  setSubscriptionFields,
  packageValability,
  packagePeriodUnit,
}: {
  newBoughtAtDate: DateObject
  setSubscription: Function
  setSubscriptionFields: Function
  packageValability: number
  packagePeriodUnit: PeriodUnit
}) => {
  const { formattedBoughtAtDate, formattedActivatedDate, formattedExpirationDate }
    = getDatesAfterBoughtDateChange({ newBoughtAtDate, packageValability, packagePeriodUnit, setSubscriptionFields })
  setSubscription((oldSubscription: SubscriptionType) => ({
    ...oldSubscription,
    bought_at: formattedBoughtAtDate,
    activated_at: formattedActivatedDate,
    expiration_date: formattedExpirationDate,
    status: 'pending',
  }))
}

const changeEventSubscriptionFieldsByPaymentType = ({
  userRole,
  paymentType,
  setSubscriptionFields,
  isEditMode
}: {
  userRole: UserRole,
  paymentType: PaymentType,
  setSubscriptionFields: Function
  isEditMode: boolean
}) => {
  setSubscriptionFields((oldSusbscriptionFields: FieldType[]) => {
    const datesFieldNames = ['bought_at', 'activated_at', 'expiration_date']
    let futureFields = [...oldSusbscriptionFields]
    const isOfTypePaymentStripe = paymentType === PaymentType.STRIPE
    futureFields = futureFields.map((field: FieldType) => {
      if (field?.name === 'stripe_status') {
        if (isEditMode && paymentType === PaymentType.STRIPE) {
          return { ...field, hidden: false }
        }
        return { ...field, hidden: true }
      }

      if (userRole === UserRole.Admin || userRole === UserRole.AdminSubscriptions) {
        return field; // we don't need to update anything if the user is of type admin - no matter the case it can edit
      }

      if (datesFieldNames.includes(field.name)) {
        const futureField = { ...field }
        if (!isOfTypePaymentStripe) { // if it's contract - the default case
          futureField.required = true
          futureField.disabled = false
        } else {
          futureField.required = false
          futureField.disabled = true
        }
        return futureField
      }

      return field
    })
    return futureFields
  })
}

const onChangePaymentType = ({
  paymentType,
  setSubscription,
  setSubscriptionFields,
  userRole,
  isEditMode
}: {
  paymentType: PaymentType
  setSubscription: Function
  setSubscriptionFields: Function
  userRole: UserRole
  isEditMode: boolean
}) => {
  setSubscription((oldSubscription: SubscriptionType) => ({
    ...oldSubscription,
    package_id: 0,
    bought_at: null,
    activated_at: null,
    expiration_date: null,
    payment_type: paymentType
  }))

  changeEventSubscriptionFieldsByPaymentType({ userRole, paymentType, setSubscriptionFields, isEditMode })
}

const getUpdatedFieldsByPaymentType = ({
  subscription,
  userCompany,
  paymentTypeField,
  stripeStatusField,
  stripeRefundField,
}: {
  subscription: SubscriptionType
  userCompany: UserCompanyType
  paymentTypeField: FieldType | null
  stripeStatusField: FieldType | null
  stripeRefundField: FieldType | null
}) => {
  let futurePaymentTypeField = { ...paymentTypeField }
  let futureStripeStatusField = { ...stripeStatusField }
  let futureStripeRefundField = { ...stripeRefundField }
  if (!subscription) {
    return { futurePaymentTypeField, futureStripeStatusField, futureStripeRefundField }
  }
  const isOfTypePaymentStripe = subscription?.payment_type === PaymentType.STRIPE
  if (!userCompany.mainCompanyStripePayable) {
    futurePaymentTypeField.required = false;
    futurePaymentTypeField.hidden = true;
    futureStripeStatusField.hidden = true;
    futureStripeRefundField.hidden = true;
  } else if (subscription.id) {
    futurePaymentTypeField.required = false;
    futurePaymentTypeField.hidden = false;
    futureStripeStatusField.hidden = !isOfTypePaymentStripe;
    futureStripeRefundField.hidden = !isOfTypePaymentStripe || !isOfStripeStatusPaid(subscription?.stripe_status)
  } else {
    futurePaymentTypeField.required = true;
    futurePaymentTypeField.hidden = false;
    futureStripeStatusField.hidden = !isOfTypePaymentStripe;
    futureStripeRefundField.hidden = true;
  }
  return { futurePaymentTypeField, futureStripeStatusField, futureStripeRefundField }
}
const getDatesByPackageChange = ({
  boughtAtString,
  activatedAtString,
  packageValability,
  packagePeriodUnit,
  setSubscriptionFields
}: {
  boughtAtString: string | null,
  activatedAtString: string | null,
  packageValability: number
  packagePeriodUnit: PeriodUnit
  setSubscriptionFields: Function
}) => {
  const boughtDate = boughtAtString ? new DateObject(boughtAtString) : new DateObject();
  let boughtDateString = '', activatedDateString = '', expirationDateString = '';
  if (!boughtAtString) {
    // if there is no boughtDate we generate all three of them
    let { formattedBoughtAtDate, formattedActivatedDate, formattedExpirationDate }
      = getDatesAfterBoughtDateChange({ newBoughtAtDate: boughtDate, packageValability, packagePeriodUnit, setSubscriptionFields })

    boughtDateString = formattedBoughtAtDate;
    activatedDateString = formattedActivatedDate;
    expirationDateString = formattedExpirationDate;
  } else {
    // if there is a boughtDate, generate only the last two
    // if activationDate is also null, we set it in regards to boughtDate
    const activatedDate = activatedAtString ? new DateObject(activatedAtString) : boughtDate.add(1, 'day')

    let { formattedActivatedDate, formattedExpirationDate }
      = getModifiedDatesFromActivationDate(activatedDate, packageValability, packagePeriodUnit)

    boughtDateString = boughtAtString;
    activatedDateString = formattedActivatedDate;
    expirationDateString = formattedExpirationDate;
  }

  return {
    formattedBoughtAtDate: boughtDateString,
    formattedActivatedDate: activatedDateString,
    formattedExpirationDate: expirationDateString
  }
}

const onChangePackage = ({
  subscription,
  setSubscription,
  chosenPackage,
  setSubscriptionFields
}: {
  subscription: SubscriptionType,
  setSubscription: Function,
  chosenPackage: PackageType,
  setSubscriptionFields: Function
}) => {
  const packageValability = chosenPackage?.valability_period;
  if (!packageValability) {
    toast.error("Pachetul furnizat nu dispune de o valabilitate! Nu se poate determina perioada de valabilitate în mod automat!")
    return;
  }
  const packagePeriodUnit = chosenPackage?.valability_unit;
  if (!packagePeriodUnit) {
    toast.error("Pachetul furnizat nu dispunde de o unitate de măsură a duratei. Nu se poate determina în mod automat dacă este vorba de zile, săptămâni, luni sau ani!")
  }
  const boughtAt = subscription.bought_at ? subscription.bought_at.toString() : null
  const activatedAt = subscription.activated_at ? subscription.activated_at.toString() : null
  const { formattedBoughtAtDate, formattedActivatedDate, formattedExpirationDate }
    = getDatesByPackageChange({ boughtAtString: boughtAt, activatedAtString: activatedAt, packageValability, packagePeriodUnit, setSubscriptionFields })
  setSubscription((oldSubscription: SubscriptionType) => ({
    ...oldSubscription,
    package_id: chosenPackage?.id || 0,
    bought_at: formattedBoughtAtDate,
    activated_at: formattedActivatedDate,
    expiration_date: formattedExpirationDate,
  }))
}

const SubscriptionCard = ({
  userRole,
  subscription,
  setSubscription,
  userCompany,
  packages,
  companies,
  setShowCancelledModal
}: {
  userRole: UserRole
  subscription: SubscriptionType
  setSubscription: Function
  userCompany: UserCompanyType
  companies: CompanyType[]
  packages: PackageType[]
  setShowCancelledModal: (showCancelledModal: boolean) => void
}) => {
  const { subscriptionFields, setSubscriptionFields } = useContext<any>(SubscriptionsContext)
  const [refundModalVisible, setRefundModalVisible] = useState<boolean>(false)
  const selectedCompany = companies.find((company: CompanyType) => company.id === subscription?.company_id)

  const allowStatusChangeCompanies = ['KIA', 'VOLVO', 'MG', 'ISUZU'];
  const onRefundSuccess = useCallback(() => {
    setSubscription({ ...subscription, stripe_status: stripeStatusRefund })
  }, [])

  useEffect(() => {
    if (Boolean(subscription?.id)) {
      updateMinDateForSubscriptionBoughtDate(subscription?.bought_at || 0, setSubscriptionFields)
      changeEventSubscriptionFieldsByPaymentType({
        userRole,
        setSubscriptionFields,
        paymentType: subscription?.payment_type || PaymentType.CONTRACT,
        isEditMode: Boolean(subscription?.id)
      })
    }
  }, [subscription?.id])

  useEffect(() => {
    if (userRole !== UserRole.Admin && !allowStatusChangeCompanies.includes(userCompany?.companyName) && !allowStatusChangeCompanies.includes(userCompany?.mainCompanyName)) {
      const statusField = subscriptionFields.find((field: FieldType) => field?.name === 'status');
      statusField.disabled = true;
      setSubscriptionFields([...subscriptionFields]);
    }
  }, [userRole, userCompany?.companyName])

  useEffect(() => {
    const paymentTypeFieldIndex = subscriptionFields?.findIndex((field: FieldType) => field?.name === 'payment_type')
    const stripeStatusFieldIndex = subscriptionFields?.findIndex((field: FieldType) => field?.name === 'stripe_status')
    const stripeRefundFieldIndex = subscriptionFields?.findIndex((field: FieldType) => field?.name === 'stripe_refund')

    const { futurePaymentTypeField, futureStripeStatusField, futureStripeRefundField } = getUpdatedFieldsByPaymentType({
      subscription,
      userCompany,
      paymentTypeField: paymentTypeFieldIndex === -1 ? null : subscriptionFields[paymentTypeFieldIndex],
      stripeStatusField: stripeStatusFieldIndex === -1 ? null : subscriptionFields[stripeStatusFieldIndex],
      stripeRefundField: stripeRefundFieldIndex === -1 ? null : subscriptionFields[stripeRefundFieldIndex],
    })

    const futureSubscriptionFields = [...subscriptionFields] // shallow copy, beware of mutations
    if (futurePaymentTypeField) {
      futureSubscriptionFields[paymentTypeFieldIndex] = futurePaymentTypeField
    }
    if (futureStripeStatusField) {
      futureSubscriptionFields[stripeStatusFieldIndex] = futureStripeStatusField
    }
    if (futureStripeRefundField) {
      futureSubscriptionFields[stripeRefundFieldIndex] = futureStripeRefundField
    }

    setSubscriptionFields(futureSubscriptionFields)
  }, [userCompany?.mainCompanyStripePayable])

  const getFieldPropertiesFromFieldName = (fieldName: string) => {
    if (!Boolean(fieldName)) {
      return null
    }
    switch (fieldName) {
      case 'package_id':
        return {
          simpleValue: subscription?.package_id || '',
          options: packages || [],
          getOptionLabel: (e: PackageType) => e?.name || '',
          getOptionValue: (e: PackageType) => e?.id || null,
          onChange: (packageId: number) => {
            const chosenPackage = (packages || []).find((packageObj: PackageType) => packageObj.id === packageId)
            if (!chosenPackage) {
              toast.error("Nu s-a putut găsi pachetul furnizat!")
              return; // invalid package id
            }
            onChangePackage({ subscription, setSubscription, chosenPackage, setSubscriptionFields })
          }
        }
      case 'payment_type':
        return {
          simpleValue: subscription?.payment_type,
          options: [{ type: PaymentType.CONTRACT }, { type: PaymentType.STRIPE }],
          getOptionValue: (e: { type: PaymentType }) => e.type,
          getOptionLabel: (e: { type: PaymentType }) => e.type,
          onChange: (type: PaymentType) => {
            if (type === subscription.payment_type) {
              return;
            }
            onChangePaymentType({ paymentType: type, setSubscription, setSubscriptionFields, userRole, isEditMode: Boolean(subscription?.id) })
          },
        }
      case 'status':
        return {
          value: {name: subscription?.status || ''} ,
          options: statusList,
          getOptionLabel: (e: any) => e?.name || '',
          getOptionValue: (e: any) => e?.name || '',
          onChange: (value: string) => {
            if (value === 'cancelled') {
              setShowCancelledModal(true);
            } else {
              return setSubscription({ ...subscription, status: value });
            }
          },
        }
      case 'activated_at': {
        const foundPackage = packages?.find(p => p.id === subscription?.package_id)
        const packageValability = foundPackage?.valability_period ?? DEFAULT_VALABILITY_PERIOD;
        const packagePeriodUnit = foundPackage?.valability_unit ?? DEFAULT_VALABILITY_UNIT;
        return {
          value: subscription?.activated_at || '',
          onChange: (value: DateObject) => {
            if (subscription?.bought_at?.toString() < value.format('YYYY-MM-DD HH:mm:00.000')) {
              setSubscription({ ...subscription, status: 'pending' })
            } else {
              setSubscription({ ...subscription, status: '' })
            }

            onChangeActivationDate({ newActivatedDate: value, setSubscription, packageValability, packagePeriodUnit })
          },
        }
      }
      case 'bought_at': {
        const foundPackage = packages?.find(p => p.id === subscription?.package_id)
        const packageValability = foundPackage?.valability_period ?? DEFAULT_VALABILITY_PERIOD;
        const packagePeriodUnit = foundPackage?.valability_unit ?? DEFAULT_VALABILITY_UNIT;
        return {
          value: subscription?.bought_at || '',
          onChange: (value: DateObject) =>
            onChangeBoughtDate({ newBoughtAtDate: value, setSubscription, packageValability, packagePeriodUnit, setSubscriptionFields }),
        }
      }
      case 'subscription_type':   
      return {
        value: {type: subscription?.subscription_type || subscription?.payment_type} ,
        options: [{ type: PaymentType.CONTRACT }, { type: PaymentType.STRIPE }],
        getOptionValue: (e: { type: PaymentType }) => e.type,
        getOptionLabel: (e: { type: PaymentType }) => e.type,
        onChange: (type: PaymentType) => {
          setSubscription({ ...subscription, subscription_type: type })
        },
      }
      case 'stripe_refund': {
        return {
          isStripeRefunded: isOfStripeStatusRefunded(subscription?.stripe_status),
          onClick: () => setRefundModalVisible(true)
        }
      }
      default:
        const value = subscription[fieldName] || emptySubscription[fieldName]
        if (value === undefined) {
          return null
        }
        return value
    }
  }

  return (
    <div key={'subscription-container'} className='col-12 pb-4'>
      <Card name={'Subscription'}>
        {subscriptionFields
          .filter((field: any) => (field.name !=='subscription_type' && field.name !== 'price_paid' && field.name !== 'discount') || 
            (field.name === 'subscription_type' && ((userRole !== UserRole.Admin && userRole !== UserRole.AdminSubscriptions && (userCompany.mainCompanyName === CompanyName.AutonomAssistance || userCompany.mainCompanyName === CompanyName.TransilvaniaBroker || userCompany.mainCompanyName === CompanyName.NouaSaseNouaCinci)) || (selectedCompany?.name === CompanyName.AutonomAssistance || selectedCompany?.name === CompanyName.TransilvaniaBroker || selectedCompany?.name === CompanyName.NouaSaseNouaCinci))) || 
            ((field.name === 'discount' || field.name === 'price_paid') && ((userRole !== UserRole.Admin && userRole !== UserRole.AdminSubscriptions && userCompany.mainCompanyName === CompanyName.AutonomAssistance) || (selectedCompany?.name === CompanyName.AutonomAssistance))))
          .map((field: any, index: number) => {
          const fieldName = field?.name
          const fieldProperty = getFieldPropertiesFromFieldName(fieldName)
          return (
            <Field
              key={`field-subscription-${fieldName}`}
              field={field}
              fieldName={fieldName}
              fieldProperty={fieldProperty}
              setObject={setSubscription}
            />
          )
        })}
      </Card>
      {Boolean(refundModalVisible)
        ? <RefundModal
          subscriptionId={subscription?.id}
          onRefundSuccess={onRefundSuccess}
          visible={refundModalVisible}
          setVisible={setRefundModalVisible}
        />
        : null}
    </div>
  )
}

export default SubscriptionCard



export const enum TypeOfCompany {
  Company = 'company',
  Branch = 'branch',
}

export type CompanyType = {
  id: number
  main_company_id: number | null
  main_company_name?: string | null
  main_company_stripe_payable?: boolean | null
  stripe_payable: boolean
  type: TypeOfCompany
  address: string
  name: string
  phone: string
  contact: string
  email: string
  business_profile?: string | boolean,
  business_profile_id?: string,
  is_vin_required?: boolean,
  company_conglomerate_id?: number,
}
export const emptyCompany: CompanyType = {
  id: 0,
  main_company_id: null,
  main_company_name: null,
  main_company_stripe_payable: null,
  stripe_payable: false,
  type: TypeOfCompany.Company,
  address: '',
  name: '',
  phone: '',
  contact: '',
  email: '',
}
export const emptyBranch: CompanyType = {
  ...emptyCompany,
  type: TypeOfCompany.Branch,
}

// the enum case for the values matches the character case from backend (lowercase). Otherwise it would have been UPPERCASE
export enum PeriodUnit {
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
  Years = 'years'
}
export const periodUnitList = Object.values(PeriodUnit).map((unit: string) => ({ unit }))

export const enum ClientFlag {
  ALL = 'ALL',
  SOME = 'SOME',
}

export type PackageType = {
  id: number
  client_flag: ClientFlag
  company?: CompanyType
  company_id?: number
  created_at?: string
  created_by_user?: string
  description: string
  name: string
  price: number
  currency?: string
  updated_at?: string
  valability_period: number
  valability_unit: PeriodUnit
  package_summary: string
  package_details: string
}

export const emptyPackage: PackageType = {
  id: 0,
  name: '',
  company_id: 0,
  price: 0,
  valability_period: 0,
  valability_unit: PeriodUnit.Months,
  description: '',
  client_flag: ClientFlag.ALL,
  package_summary: '',
  package_details: '',
}

export type ServiceType = {
  id?: number
  name: string,
  has_offer_mark: boolean,
  is_offer_present: boolean,
  additional_info: string,
  package_id?: string,
  created_ad?: string
}

export const emptyService: ServiceType = {
  name: '',
  additional_info: '',
  has_offer_mark: false,
  is_offer_present: false
}

export type CompanyPackageGeneralInfo = {
  id?: number
  photo?: any,
  company_id?: number,
  uploaded_document?: any,
  uploaded_document_path?: string
  email_title: string | null,
  email_template: string | null,
  general_conditions_text?: string
  pdf_title: string
  photo_path?: string
}

export const emptyCompanyPackageGeneralInfo: CompanyPackageGeneralInfo = {
  email_title: '',
  email_template: '',
  uploaded_document_path: '',
  general_conditions_text: '',
  pdf_title: '',
  photo_path: '',
  uploaded_document: null,
  photo: null
}

export type CompanyPaginationType = {
  page: number;
  per_page: number;
  total: number;
  totalPages: number;
};

export type ConglomerateCompaniesType = {
  id: number;
  name: string;
};

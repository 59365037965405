/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
// import '../../app/modules/autonom.scss'
import Loading from '../../../_metronic/layout/components/loading/Loading'
import { generateField } from '../../../components/field/field-generator'
import PlanProvider, { usePlanContext } from '../../../context/plan.context'
import { updateField } from '../../../utils/state.utils'
import { disableMaxWidth, enableMaxWidth } from '../../../utils/style.utils'
import RentExtrasTable from '../../../pages/add-edit-plan/modules/RentExtrasTable'
import IncludedKmTable from '../../../pages/add-edit-plan/modules/IncludedKmTable'
import RentVehicleClassTable from '../../../pages/add-edit-plan/modules/RentVehicleClassTable'
import DislocationTable from '../../../pages/add-edit-plan/modules/DislocationTable'
import GuaranteeTable from '../../../pages/add-edit-plan/modules/GuaranteeTable'
import ResponseTable from '../../../pages/add-edit-plan/modules/ResponseTable'
import RentTariffsTable from '../../../pages/add-edit-plan/modules/RentTariffsTable'
import { createRentCompanyPricePlan, getRentCompanyPricePlan, updatePlanAndTariffsTable } from '../../../setup/axios/rent.request'
import { toast } from 'react-toastify'
import { RentCompanyPricingPlanModel, emptyRentCompanyPricingPlan } from '../rent-plans/models/RentCompanyPricingPlanModel'
import ModalPortal from '../../../_metronic/layout/components/modal/modal-portal'
import { Card } from '../add-edit-transport/scripts/structuralFields'
import { CompanyContext } from '../../../context/company.context'
import easytrackAxios from '../../../setup/easytrack.axios'
import { EASYTRACK_API_URL } from '../../../constants/api.constants'
import { UserContext, UserRole } from '../../../context/user.context'

const Field: FC<{
  name: string
  style?: any
  generatePlanField: any
  field: any
  plan: RentCompanyPricingPlanModel
}> = memo(
  ({ name, style, generatePlanField, field }) => {
    return (
      <div key={name} className={`${name}-card-field pb-7 card-field`} style={style}>
        {field && generatePlanField(field)}
      </div>
    )
  },
  (prev, next) => prev.plan[next.name] === next.plan[next.name]
)

enum tabs {
  Auto = "Auto Classes",  // tab baza
  Response = "Liability", //tab raspundere
  Tariffs = "Tariffs", // tarife
  Included = "Included KM/Extra", //tab km inclusi
  Extras = "Extras", // tab extra
  Dislocation = "Dislocation", //tab dislocare
  // Guarantee = "Guarantee", //garantie
}

const fields = [
  { name: 'name', label: 'Name', type: 'input', inputType: 'text', isEditable: true, required: true },
  { name: 'description', label: 'Description', type: 'input', inputType: 'text', isEditable: true, required: true },
  { name: 'currency', label: 'Currency', type: 'select', isEditable: true, required: true },
  { name: 'clients', label: 'Clients', type: 'select', isEditable: true, required: false, isMulti: true },
  { name: 'branches', label: 'Branches', type: 'select', isEditable: true, required: false, isMulti: true }

]

const RedirectBtnToCalculatePricing = ({ planId, companyId }: { planId: number, companyId: number }) => {
  return (
    <Link
      className='btn btn-light-primary btn-text-primary mt-2'
      style={{
        position: 'absolute',
        right: '3.5rem',
        height: '40px'
      }}
      to={`/rent-pricing/${companyId}/${planId}`}
    >
      Calculate
    </Link>
  )
}

const AddEditPlanPage: FC = () => {
  const { id, companyId } = useParams<any>()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const {
    pricingPlanTemplate,
    rentCompanyPricingPlan,
    setRentCompanyPricingPlan,
    initialTariffs,
    setInitialTariffs,
    intervalsData,
    setCompanies
  } = usePlanContext()

  const planContext = usePlanContext();
  const planCompanies = useMemo(() => (planContext.companies || []).map(company => ({ id: company?.id, name: company?.name })), [planContext.companies]);
  const [hadLoaded, setHadLoaded] = useState<boolean>(false)
  const [chosenTab, setChosenTab] = useState<string>(tabs.Auto)
  const [modalVisible, setModalVisible] = useState(false);
  const [desiredTab, setDesiredTab] = useState("");
  const updatePlanField = (value: any, fieldName: string) => {
    updateField({ value: value, fieldName: fieldName, setObject: setRentCompanyPricingPlan })
  }
  const { getCompanies, companies, getCompany } = useContext<any>(CompanyContext);
  const { businessProfileId, user: { role: { slug: userRole } } } = useContext<any>(UserContext)
  useEffect(() => {
    const payload = { mainCompanyId: companyId, businessProfileId: null }
    if (userRole === UserRole.AdminFleet) {
      payload.businessProfileId = businessProfileId
    }
    getCompanies(payload)
  }, [])

  const fetchRentPlans = () => {
    ; (async () => {
      if (id != null) {
        getRentCompanyPricePlan(id).then((futureRentCompanyPricePlans: RentCompanyPricingPlanModel | null) => {
          if (!futureRentCompanyPricePlans) {
            return toast.error('The Rent Company Price Plan failed to load!')
          }
          setRentCompanyPricingPlan({ ...rentCompanyPricingPlan, ...futureRentCompanyPricePlans })
          setIsLoading(false)
        })
      } else {
        setRentCompanyPricingPlan(emptyRentCompanyPricingPlan)
      }
    })()
  }
  useEffect(() => {
    fetchRentPlans();
  }, [pricingPlanTemplate])

  useEffect(() => {
    Promise.allSettled([
      (async () => {
        const response = await getCompany({ id: companyId, businessProfileId })
        if (!response?.error && response?.company && response.company.business_profile_id) {
          const result = await easytrackAxios
            .get(`${EASYTRACK_API_URL}/companies?businessProfileId=${response.company.business_profile_id}`)
            .catch((err) => err)
          if (result.data) {
            setCompanies(result.data)
          }
        }
      })(),
      (async () => {
        if (pricingPlanTemplate) {
          return
        }
      })(),
    ]).then(() => setHadLoaded(true))
  }, [])


  const SaveModal = () => {
    return (
      <div className='py-5 px-8' style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ textAlign: 'center', fontSize: '16px' }}>
          Do you want to save your changes?
        </span>
        <div className='mt-8' style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            className='btn btn-secondary me-5'
            onClick={() => {
              setModalVisible(false);
              setChosenTab(desiredTab);
            }}
          >
            Cancel
          </div>
          <div
            className='btn btn-danger'
            onClick={() => {
              saveEditPlanAndTariff();
              setModalVisible(false);
              setChosenTab(desiredTab);
            }}
          >
            Save
          </div>
        </div>
      </div>
    )
  }

  const getDisabledState = () => {
    return !(rentCompanyPricingPlan.name && rentCompanyPricingPlan.description && rentCompanyPricingPlan.currency);
  }

  const isDisabled = useMemo(() => getDisabledState(), [
    rentCompanyPricingPlan.name, rentCompanyPricingPlan.description, rentCompanyPricingPlan.currency
  ]);
  const fieldToProperty = (field: any) => {
    let fieldName = field.name
    const fieldsToPanelPropertiesMapping = {
      name: rentCompanyPricingPlan.name || "",
      description: rentCompanyPricingPlan.description || "",
      currency: {
        simpleValue: rentCompanyPricingPlan.currency,
        options: [{ name: "EUR", title: "EUR" }, { name: "RON", title: "RON" }],
        getOptionValue: (e: any) => e.name || '',
        getOptionLabel: (e: any) => e.title || '',
        onChange: (e: any) => {
          updatePlanField(e, 'currency')
        },
      },
      clients: {
        value: rentCompanyPricingPlan.clients || [],
        options: planCompanies,
        getOptionValue: (e: any) => e,
        getOptionLabel: (e: any) => e.name || '',
        onChange: (e: any) => {
          updatePlanField(e, 'clients')
        },
      },
      branches: {
        simpleValue: rentCompanyPricingPlan.branches,
        options: companies.map(company => ({ name: company.name, title: company.name })),
        getOptionValue: (e: any) => e.name || '',
        getOptionLabel: (e: any) => e.title || '',
        onChange: (e: any) => {
          updatePlanField(e, 'branches')
        },
      }
    }
    return (fieldsToPanelPropertiesMapping as any)[fieldName]
  }

  const generatePlanField = (field: any) => {
    const fieldName = field?.name
    const fieldProperty = fieldToProperty(field)
    return generateField({ field, fieldName, fieldProperty, setObject: setRentCompanyPricingPlan })
  }

  const saveEditPlanAndTariff = async () => {
    const newPlan = {
      created_at: rentCompanyPricingPlan.created_at,
      updated_at: rentCompanyPricingPlan.updated_at,
      company_id: rentCompanyPricingPlan.company_id,
      currency: rentCompanyPricingPlan.currency,
      description: rentCompanyPricingPlan.description,
      branches: rentCompanyPricingPlan.branches,
      clients: rentCompanyPricingPlan.clients,
      id: rentCompanyPricingPlan.id,
      name: rentCompanyPricingPlan.name,
      status: rentCompanyPricingPlan.status
    };
    return await updatePlanAndTariffsTable(newPlan, rentCompanyPricingPlan.rentPlanIntervals, intervalsData, initialTariffs, setInitialTariffs, id, companyId);
  }

  return (!hadLoaded || !rentCompanyPricingPlan) ? (
    <Card name=''>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loading />
      </div>
    </Card>

  ) : (<>
    <div key='container' className='col-12 pb-4'>
      {/* <div className='page__top-buttons'>
    <Link className='btn btn-primary item-right' to={`../add-edit-plan/`}>
      New Plan
    </Link>
  </div> */}
      {modalVisible ? (
        <ModalPortal setVisible={setModalVisible} visible={modalVisible} hasExitButton={true}>
          {SaveModal()}
        </ModalPortal>
      ) : null}
      <div
        className='page__top-buttons'
        style={{
          position: 'fixed',
          zIndex: 1000,
          right: '90px',
          top: '12px',
        }}
      >
        <button
          className='btn btn-primary item-right'
          type='submit'
          disabled={isDisabled}
          onClick={async () => {
            if (id) {
              const response = await saveEditPlanAndTariff();
              if (response) {
                fetchRentPlans();
              }
              return;
            }
            let saved = await createRentCompanyPricePlan(rentCompanyPricingPlan, companyId)
            if (saved) {
              setRentCompanyPricingPlan(saved);
              history.push(`../add-edit-rent-plan/${companyId}/${saved.id}`);
            }
          }}
        >
          Save
        </button>
      </div>
      <Card name='Rent Pricing Plan'>
        {fields.filter(field => (field.isEditable || id == null)).map((field: any) => (
          <Field
            key={'field-' + field.name}
            name={field.name}
            plan={rentCompanyPricingPlan}
            field={field}
            generatePlanField={generatePlanField}
          />
        ))}
      </Card>
    </div>
    <div
      style={{
        padding: '10px',
        borderRadius: '10px',
        backgroundColor: 'white',
        display: 'flex',
      }}
      className='my-3'
    >
      {(Object.values(tabs) as (keyof typeof tabs)[]).map((tab: string) => {
        return (
          <span
            key={tab}
            onClick={() => {
              if (!id) {
                return;
              }
              if (tab !== tabs.Tariffs && chosenTab !== tab && chosenTab === tabs.Tariffs && JSON.stringify(rentCompanyPricingPlan.rentPlanIntervals) !== JSON.stringify(initialTariffs)) {
                setModalVisible(true)
              } else {
                setChosenTab(tab)
              }
              setDesiredTab(tab);
            }}
            style={{
              color: chosenTab === tab ? '#009ef7' : 'black',
              backgroundColor: chosenTab === tab ? '#f3f6f8' : '',
              opacity: id ? 1 : 0.5,
              padding: '10px',
              margin: '5px',
              borderRadius: '10px',
              fontSize: '15px',
              fontWeight: '500',
              cursor: id ? 'pointer' : 'initial',
            }}
          >
            {tab}
          </span>
        )
      })}
      {Boolean(id) ? <RedirectBtnToCalculatePricing planId={id} companyId = {companyId} /> : null}
    </div>
    {chosenTab === tabs.Auto ? <RentVehicleClassTable /> : null}
    {chosenTab === tabs.Response ? <ResponseTable /> : null}
    {chosenTab === tabs.Tariffs ? <RentTariffsTable /> : null}
    {chosenTab === tabs.Included ? <IncludedKmTable /> : null}
    {chosenTab === tabs.Extras ? <RentExtrasTable /> : null}
    {chosenTab === tabs.Dislocation ? <DislocationTable /> : null}
    {/* {chosenTab === tabs.Guarantee ? <GuaranteeTable /> : null} */}
  </>
  )
}

const RentCompanyPlans: FC = () => {
  useEffect(() => {
    disableMaxWidth()

    return () => {
      enableMaxWidth()
    }
  }, [])

  return (
    <>
      <PlanProvider>
        <AddEditPlanPage />
      </PlanProvider>
    </>
  )
}

export default RentCompanyPlans

import {useState} from 'react'

import {CarClassModel} from '../../../auth/models/CarClassModel'
import CarClassCard from '../car-class-card/CarClassCard'
import {CreateUpdateCarModal} from '../create-update-car-class-modal/CreateUpdateCarClassModal.component'
import TableHeader from '../table-header/TableHeader'

type Props = {
  className: string
  carClasses: CarClassModel[]
}

const CarClassesTable: React.FC<Props> = ({className, carClasses}) => {
  const [carsData, setCarsData] = useState<CarClassModel[]>(carClasses)
  const [showCarModal, setShowCarModal] = useState<boolean>(false)
  const [currentCar, setCurrentCar] = useState<CarClassModel>(null)

  return (
    <div className={`card ${className}`}>
      <TableHeader
        cars={carsData}
        setCurrentCar={setCurrentCar}
        setShowCarModal={setShowCarModal}
      />
      <div className='row row-cols-1 row-cols-sm-2 row-cols-xl-3 row-cols-xxl-4 g-5'>
        {carsData.map((car) => (
          <CarClassCard
            key={(car.id).toString()}
            setShowCarModal={setShowCarModal}
            setCurrentCar={setCurrentCar}
            car={car}
            carsData={carsData}
            setCarsData={setCarsData}
          />
        ))}
      </div>
      <CreateUpdateCarModal
        carClassData={carsData}
        setCarClassData={setCarsData}
        carClass={currentCar}
        showCarClassModal={showCarModal}
        setShowCarClassModal={setShowCarModal}
      />
    </div>
  )
}
export default CarClassesTable

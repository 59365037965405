import { debounce } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import InputField from '../../../../../_metronic/layout/components/fields/input/input-field'
import { getSortedList } from '../../../../../utils/sort.utils';
import { FieldType } from '../../../../../utils/field.utils';
import { getSubscriptionsByPrompt, getSubscriptionsByVIN } from '../../../../../setup/axios/subscription.request';

const statusMessage = {
  default: 'Search after either the VIN or the plate number. Type at least 3 characters!',
  defaultOnlyVIN: 'Search after the VIN. It should have exactly 17 characters!',
  loading: 'Searching for subscriptions, please wait...',
  error: 'An error occured! Check the error message from below:',
  notFound: 'No subscription was found for the given VIN or plate number. You can try to search after the other option!',
  notFoundOnlyVIN: 'No subscription was found for the given VIN!',
  found: 'The field found some subscriptions! Check them inside the "Subscriptions Found" field!'
}

export const SearchSubscriptionsField = ({
  setSubscriptions,
  field,
  onChangeLabel,
  onlyVIN,
}: {
  setSubscriptions: Function,
  field: FieldType,
  onChangeLabel?: (value: string) => void
  onlyVIN?: boolean
}) => {
  const [searchInput, setSearchInput] = useState<string>('')
  const [message, setMessage] = useState<string>(onlyVIN ? statusMessage.defaultOnlyVIN : statusMessage.default)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isTokenSearchable = useCallback((token: string) => {
    if (!token) {
      return false
    }
    if (onlyVIN) {
      // strictly after the VIN
      return token.length === 17
    } else {
      // either by VIN or plate number - at least 3 characters
      return token.length > 3
    }
  }, [])

  const searchSubscriptionsEvent = useCallback(async (searchInput: string) => {
    setIsLoading(true)
    setMessage(statusMessage.loading)

    const findSubscriptions = onlyVIN ? getSubscriptionsByVIN : getSubscriptionsByPrompt
    const result = await findSubscriptions(searchInput)
    setIsLoading(false)

    if (!result || result.error) {
      setMessage(statusMessage.error + '\n' + result.message)
      return
    }

    const futureSubscriptions = getSortedList({ listToSort: result?.subscriptions || [], args: ["created_at"], reversed: true })
    const notFoundMessage = onlyVIN ? statusMessage.notFoundOnlyVIN : statusMessage.notFound
    setMessage(futureSubscriptions.length === 0 ? notFoundMessage : statusMessage.found)
    setSubscriptions(futureSubscriptions)
  }, [])

  const debouncedSearchForSubscriptions = useMemo(() => debounce(searchSubscriptionsEvent, 600), [])

  useEffect(() => {
    return () => {
      debouncedSearchForSubscriptions.cancel()
    }
  }, [debouncedSearchForSubscriptions])

  return (
    <div style={{ display: 'flex', flexFlow: 'column' }}>
      <InputField
        field={{
          label: field?.label || 'Search subscriptions', name: field?.name || 'search_subscriptions', addOns: isLoading ? {
            append: <span
              style={{ width: '1.5rem', height: '1.5rem' }}
              className='spinner-border spinner-border-sm align-middle ms-1 me-2'
            />
          } : null
        }}
        value={searchInput}
        onChange={(e: any) => {
          const val = e?.target?.value || ''
          if (val === searchInput) {
            return
          }
          setMessage(onlyVIN ? statusMessage.defaultOnlyVIN : statusMessage.default)
          setSearchInput(val)
          if (!isTokenSearchable(val)) {
            setSubscriptions([])
            return;
          }
          debouncedSearchForSubscriptions(val)
        }}
        onChangeLabel={onChangeLabel}
        readOnly={isLoading}
      />
      <textarea
        className='form-control form-control-sm mt-2'
        style={{ minHeight: '75px', maxHeight: '225px', backgroundColor: '#f5f8fa' }}
        value={message}
        readOnly={true}
      />
    </div>
  )
}

export default SearchSubscriptionsField
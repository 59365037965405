import { useCallback, useEffect, useRef } from 'react';
import _ from 'lodash';

export default function useThrottle(cb : any, delay : number) {
  const options = { leading: true, trailing: false }; // pass custom lodash options
  const cbRef = useRef(cb);

  useEffect(() => {
    cbRef.current = cb;
  });

  return useCallback(
    _.throttle((...args) => cbRef.current(...args), delay, options),
    [delay],
  );
}

import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { EASYTRACK_API_URL } from '../../../../constants/api.constants'
import easytrackAxios from '../../../../setup/easytrack.axios'
import { DocumentVariantType, TransportPhotoPayloadType, TransportPhotoType } from '../../../../types/document.types'
import { SelectObject } from '../../../../_metronic/layout/components/select/Select'

const AddImageModal = ({
  transportRequestId,
  setModalVisible,
  setProcessingFiles,
  updateListWithNewPhotos,
  photoTypes,
}: {
  transportRequestId: string
  setModalVisible: Function
  setProcessingFiles: Function
  updateListWithNewPhotos: Function
    photoTypes: DocumentVariantType[]
}) => {
  const [photoObjectList, setPhotoObjectList] = useState<File[]>([])
  const [photoType, setPhotoType] = useState<string>(photoTypes[0]?.name)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const payload: TransportPhotoPayloadType = {
    photos: photoObjectList,
    photoType: photoType,
    transportRequestId: transportRequestId,
  }

  const addPhotoRequest = async (payload: TransportPhotoPayloadType) => {
    const formData = new FormData()
    formData.append('type', payload.photoType)
    const photoList = Array.from(payload.photos || [])
    photoList.forEach((photo: File) => {
      formData.append('photos', photo)
    })
    try {
      const photosResult = await easytrackAxios.post(
        `${EASYTRACK_API_URL}/transport-requests/${payload.transportRequestId}/photos/multiple`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
      return photosResult
    } catch {
      // not ideal - will be replaced in the future with a better solution
      return null
    }
  }

  const ActionButtons = () => {
    return (
      <div className='mt-8' style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          className='btn btn-secondary me-5'
          disabled={Boolean(isLoading)}
          onClick={() => {
            setModalVisible(false)
          }}
        >
          Cancel
        </button>
        <button
          className='btn btn-success'
          disabled={Boolean(isLoading)}
          onClick={async () => {
            if (!Boolean(photoObjectList) || photoObjectList.length === 0) {
              toast.warning('There are no images selected for upload!')
              return
            }
            setIsLoading(true)
            toast.info('Saving Images...')
            const result = await addPhotoRequest(payload)
            setIsLoading(false)
            if (!Boolean(result)) {
              toast.error('ERROR: The images did not save.')
              return
            } else {
              toast.success('The images were succesfully saved. Loading new images...')
              const receivedURLs: Array<{id?:number, url: string }> = result.data
              const newPhotos: TransportPhotoType[] = []
              const currentDate = new Date()
              const currentDateString = currentDate.toString()
              receivedURLs.forEach((item) => {
                const newPhoto: TransportPhotoType = {
                  id: item?.id || 0,
                  url: item.url,
                  transportRequestId,
                  type: photoType,
                  userCanDelete: false,
                  createdDate: currentDateString,
                }
                newPhotos.push(newPhoto)
              })
              updateListWithNewPhotos(newPhotos)
              setProcessingFiles(true)
            }
            setModalVisible(false)
          }}
        >
          Save
        </button>
      </div>
    )
  }

  return (
    <div className='py-4 px-6 w-100 h-100'>
      <span style={{ fontSize: '16px', fontWeight: '500' }}>Add an image</span>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        className='mt-4'
      >
        {/* Change Photo Type */}
        <label className='py-1'>Photo Type:</label>
        <SelectObject
          styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
          simpleValue={photoType}
          options={photoTypes}
          onChange={(value: string) => setPhotoType(value)}
          getOptionValue={(photoType: DocumentVariantType) => photoType.name}
          getOptionLabel={(photoType: DocumentVariantType) => photoType.title}
        />
        {/* Load Image Input */}
        <label className='mt-4 py-1'>Photos:</label>
        <input
          type='file'
          accept='image/*'
          multiple
          onChange={(e: any) => {
            setPhotoObjectList(e.target.files || [])
          }}
        />
      </div>
      <ActionButtons />
    </div>
  )
}

export default AddImageModal
import { TransportType } from '../app/modules/add-edit-transport/scripts/transportTypes'
import { BreakdownTypeTemp, getListSum } from '../app/modules/view-transport/modules/BreakdownCard'

export enum TypeOfCompany {
  Client = 'client',
  Supplier = 'supplier',
}

export type TariffInfoType = {
  currency: string
  commissionCurrency: string
  taxBreakdown: BreakdownObjectType
  commissionBreakdown: BreakdownObjectType
  transferType?: string
  originAgency?: string
  [key: string]: any
}
export const emptyTariffInfo = {
  currency: null,
  commissionCurrency: null,
  taxBreakdown: {},
  commissionBreakdown: {},
}
export interface BreakdownObjectType {
  [label: string]: { value: number; description: string; manuallyEdited: boolean; important: boolean }
}

// taxCurrency is a field that forces the currency to take it's value (for when we want to replace it)
export const extractTariffInfo = ({ transport, taxCurrency }: { transport: TransportType, taxCurrency?: string }): TariffInfoType => {
  const currency = taxCurrency || transport?.newCurrency || transport?.company?.currency || 'RON'
  const commissionCurrency = transport?.commissionCurrency || transport?.supplier?.currency || 'RON'
  const taxBreakdown = { ...(transport?.taxBreakdown || {}) }
  const commissionBreakdown = { ...(transport?.commissionBreakdown || {}) }
  return {
    currency,
    commissionCurrency,
    taxBreakdown,
    commissionBreakdown,
  }
}

export enum FormattingType {
  CEIL,
  FLOOR,
  ROUND,
}

export const getPriceFormatted = (
  price: number | null,
  formatMode: FormattingType = FormattingType.ROUND
) => {
  if (!price) { // if the price is null or undefined or 0, return '0.00'
    return '0.00'
  }
  let formattingFunction: Function
  switch (formatMode) {
    case FormattingType.CEIL:
      formattingFunction = Math.ceil
      break
    case FormattingType.FLOOR:
      formattingFunction = Math.floor
      break
    default:
      formattingFunction = Math.round
      break
  }
  const roundedPrice = formattingFunction(100 * price) / 100
  const formattedPrice = roundedPrice.toFixed(2).toString()
  return formattedPrice
}

export const getExchangeRate = ({
  priceDetails,
  exchangeRate,
  companyType = TypeOfCompany.Client,
  isTransportRent,
}: {
  priceDetails: { pricedAt: string, exchangeRate: number, commissionExchangeRate: number, rentExchangeRate?: number },
  exchangeRate: number,
  companyType?: TypeOfCompany
  isTransportRent?: boolean
}) => {
  const isTariffExchangeRate = companyType === "client"
  let receivedExchangeRate = isTariffExchangeRate ? priceDetails?.exchangeRate : priceDetails?.commissionExchangeRate
  if (isTransportRent && isTariffExchangeRate) {
    receivedExchangeRate = priceDetails?.rentExchangeRate || receivedExchangeRate
  }
  if (!receivedExchangeRate || receivedExchangeRate === 0 || receivedExchangeRate === 1) {
    return exchangeRate
  }
  return receivedExchangeRate
}

export const roundToDecimal = (value: number, decimals: number): number => {
  // toFixes accepts values in the range 0-20 inclusive
  decimals = Math.max(0, Math.min(decimals, 20))
  return parseFloat(value.toFixed(decimals))
}

export const eurToRon = ({ price, exchangeRate }: { price: number; exchangeRate: number }): number => {
  return price * exchangeRate
}

export const ronToEur = ({ price, exchangeRate }: { price: number; exchangeRate: number }): number => {
  return price / exchangeRate
}

export const getPriceWithVAT = ({ price, VAT }: { price: number; VAT: number }): number => {
  return price * VAT
}

export const hasIncludedVATByPaymentType = (paymentType: string) => {
  if (!paymentType) {
    return false;
  }
  return paymentType === 'CONTRACT' || paymentType === 'SUBSCRIPTION' ? false : true
}

export const getCurrencyOfTotal = ({
  paymentType,
  currency,
}: {
  paymentType: string
  currency: string
}) => {
  switch (paymentType) {
    case 'CONTRACT':
    case 'SUBSCRIPTION':
      return { currency, includeVAT: false }
    case 'POS':
    case 'ONLINE':
      return { currency: 'RON', includeVAT: true }
    default:
      return { currency, includeVAT: false }
  }
}

const getTariffSumFromBreakdownObject = ({
  tariffBreakdownObject,
}: {
  tariffBreakdownObject: BreakdownObjectType
}) => {
  let values = Object.keys(tariffBreakdownObject || {}).map((key: string) =>
    parseFloat('' + (tariffBreakdownObject[key].value || '0'))
  )
  let sum = values.reduce((sum: number, val: number) => sum + val, 0)
  return sum
}

export const getPriceFromTariffInfoObject = ({
  breakdownObject,
  baseCurrency,
  chosenCurrency,
  paymentType,
  exchangeRate,
  VAT,
}: {
  breakdownObject: BreakdownObjectType
  baseCurrency: string
  chosenCurrency: string
  paymentType: string
  exchangeRate: number
  VAT: number
}) => {
  exchangeRate = exchangeRate || 1
  VAT = VAT || 1
  const totalBreakdownSum = getTariffSumFromBreakdownObject({
    tariffBreakdownObject: breakdownObject || {},
  })
  return getPriceFullyConverted({
    price: totalBreakdownSum || 0,
    baseCurrency,
    chosenCurrency,
    paymentType,
    exchangeRate,
    VAT,
  })
}

export const getTariffFromTariffBreakdownTypeList = ({
  taxBreakdownList,
  baseCurrency,
  chosenCurrency,
  paymentType,
  exchangeRate,
  VAT,
}: {
  taxBreakdownList: BreakdownTypeTemp[]
  baseCurrency: string
  chosenCurrency: string
  paymentType: string
  exchangeRate: number
  VAT: number
}) => {
  exchangeRate = exchangeRate || 1
  VAT = VAT || 1
  const totalBreakdownSum = getListSum({ breakdownList: taxBreakdownList, currency: baseCurrency })

  return getPriceFullyConverted({
    price: totalBreakdownSum || 0,
    baseCurrency,
    chosenCurrency,
    paymentType,
    exchangeRate,
    VAT,
  })
}

export const getPriceFullyConverted = ({
  price,
  baseCurrency,
  chosenCurrency,
  exchangeRate,
  paymentType,
  VAT,
}: {
  price: number
  baseCurrency: string
  chosenCurrency: string
  exchangeRate: number
  paymentType?: string
  VAT?: number
}) => {
  let futurePrice = price
  if (baseCurrency === 'EUR' && chosenCurrency === 'RON') {
    futurePrice = futurePrice * exchangeRate
  } else if (baseCurrency === 'RON' && chosenCurrency === 'EUR') {
    futurePrice = futurePrice / exchangeRate
  }

  if (paymentType && VAT) {
    const includeVAT = hasIncludedVATByPaymentType(paymentType)
    if (includeVAT) {
      futurePrice *= VAT
    }
  }

  return futurePrice
}
import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { saveCarClass } from '../../../../../setup/axios/rent.request';
import { FlexibleObject, StateSetter, findFalseEntry } from '../../../../../utils/state.utils';
import { KTSVG } from '../../../../../_metronic/helpers';
import { SelectObject } from '../../../../../_metronic/layout/components/select/Select';
import {
  CarClassModel,
  emptyCarClass,
  FuelType,
  fuelTypeList,
  TransmissionType,
  transmissionTypeList,
} from '../../../auth/models/CarClassModel';

type CarClassModalProps = {
  carClassData: CarClassModel[];
  setCarClassData: StateSetter<CarClassModel[]>;
  carClass: CarClassModel;
  showCarClassModal: boolean;
  setShowCarClassModal: StateSetter<boolean>;
};

export const CreateUpdateCarModal: React.FC<CarClassModalProps> = ({
  carClassData,
  setCarClassData,
  carClass,
  showCarClassModal,
  setShowCarClassModal,
}) => {
  // Initialize the form state with the carClass's current details
  const [formState, setFormState] = useState(emptyCarClass);
  const [previewImageURL, setPreviewImageURL] = useState(null);

  const resetState = () => {
    setFormState(emptyCarClass);
    if (previewImageURL) {
      URL.revokeObjectURL(previewImageURL);
    }
    setPreviewImageURL(null);
  };

  // Handle changes to the form inputs
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];

      if (!img) {
        toast.warning('Please provide a valid image!');
        return;
      }

      const imageUrl = URL.createObjectURL(img);
      setPreviewImageURL(imageUrl);

      setFormState({ ...formState, image_url: img });
    }
  };
  // Handle new carClass class creation
  const createNewCar = async () => {
    const newCar: CarClassModel = {
      id: 0,
      ...formState,
    };

    const result = await saveCarClass(newCar);
    if (result?.error) return;

    const futureCarsData = [...carClassData, result.payload];

    setCarClassData(futureCarsData);
    toast.success('Car Class was created!');

    resetState();
    setShowCarClassModal(false);
  };

  // Handle carClass class update
  const updateCarClass = async () => {
    // Update the carClass with the new form values
    const updatedCar = { ...carClass, ...formState };

    const result = await saveCarClass(updatedCar);
    if (!result || result.error) {
      return;
    }
    const receivedCarClass = result.payload;
    // Update cars array with the new carClass value
    const futureCarsData = carClassData.map((e) => {
      if (e.id === receivedCarClass.id) {
        e = receivedCarClass;
      }

      return e;
    });

    setCarClassData(futureCarsData);
    toast.success('Car Class was updated!');

    resetState();
    setShowCarClassModal(false);
  };

  useEffect(() => {
    if (carClass && showCarClassModal) {
      setPreviewImageURL(carClass.image_url);
      setFormState({
        name: carClass.name,
        vehicle_type: carClass.vehicle_type,
        description: carClass.description,
        image_url: carClass.image_url,
        number_of_doors: carClass.number_of_doors,
        luggage_capacity: carClass.luggage_capacity,
        fuel_type: carClass.fuel_type,
        transmission_type: carClass.transmission_type,
      });
    } else {
      resetState();
    }
  }, [carClass, showCarClassModal]);

  return (
    <Modal
      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
      id='kt_inbox_compose'
      role='dialog'
      data-backdrop='false'
      aria-hidden='true'
      tabIndex='-1'
      show={showCarClassModal}
      animation={false}
    >
      <div className='modal-content'>
        {/* begin::Form */}
        {/*begin::Header*/}
        <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
          <div>
            <h5 className='fw-bold m-0'>
              {carClass ? `Edit carClass class:` : 'Create a new carClass class'}
            </h5>
            <h5 className='fw-bold m-0 mt-2'>
              {carClass?.description && (
                <span className='carClass-badge'>{`${carClass?.description}`}</span>
              )}
            </h5>
          </div>

          <div className='d-flex ms-2'>
            {/*begin::Close*/}
            <div
              className='btn btn-icon btn-sm btn-light-primary ms-2'
              data-bs-dismiss='modal'
              onClick={() => {
                resetState();
                setShowCarClassModal(false);
              }}
            >
              <KTSVG className='svg-icon-1' path='/media/icons/duotone/Navigation/Close.svg' />
            </div>
            {/*end::Close*/}
          </div>
        </div>
        {/*end::Header*/}

        {/*begin::Body*/}
        <div className='d-block py-5 ps-8 pe-5'>
          {/* Clasa */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Name</label>
            <input
              name='name'
              value={formState.name}
              onChange={handleChange}
              placeholder='Clasa X'
              type='text'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>

          {/* Type */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Type</label>
            <input
              name='vehicle_type'
              value={formState.vehicle_type}
              onChange={handleChange}
              placeholder='SMALL'
              type='text'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>

          {/* Description */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Description</label>
            <input
              name='description'
              value={formState.description}
              onChange={handleChange}
              placeholder='Toyota Aygo sau similar'
              type='text'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>

          {/* Image */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Image</label>
            <div style={{ marginTop: 20 }}>
              <label
                htmlFor='files'
                className='btn btn-primary form-label fw-bolder text-light fs-6 mt-1'
              >
                Select image
              </label>
              {previewImageURL ? (
                <div style={{ display: 'inline', position: 'relative', paddingLeft: '50px' }}>
                  <div
                    style={{
                      display: 'inline',
                      position: 'absolute',
                      width: '240px',
                      height: '120px',
                    }}
                  >
                    <img
                      style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                      src={previewImageURL}
                    />
                  </div>
                </div>
              ) : (
                <span style={{ marginLeft: 30 }} className='form-label fw-bolder text-dark fs-6'>
                  No image selected
                </span>
              )}
              <input
                name='image_url'
                onChange={onImageChange}
                id='files'
                accept='image/*'
                style={{ visibility: 'hidden' }}
                type='file'
              />
            </div>
          </div>

          {/* Doors */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Number of Doors</label>
            <input
              name='number_of_doors'
              value={formState.number_of_doors}
              onChange={handleChange}
              placeholder='4'
              type='number'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>

          {/* Luggage */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Luggage Capacity</label>
            <input
              name='luggage_capacity'
              value={formState.luggage_capacity}
              onChange={handleChange}
              placeholder='3'
              type='number'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>
          {/* Fuel type */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Fuel type</label>
            <SelectObject
              isMulti={true}
              styles={{
                menuPortal: (base: FlexibleObject) => ({ ...base, zIndex: 9999 }),
              }}
              key='fuel type'
              name='Fuel Type'
              className='col-12'
              onChange={(fuelType: FuelType[]) => {
                setFormState({ ...formState, fuel_type: fuelType });
              }}
              simpleValue={formState?.fuel_type || []}
              options={fuelTypeList}
              menuPortalTarget={document.body}
              getOptionValue={(e: { name: string }) => e.name}
              getOptionLabel={(e: { name: string }) => e.name}
            />
          </div>
          {/* <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Fuel type</label>
            <input
              name='fuel_type'
              value={formState.fuel_type}
              onChange={handleChange}
              placeholder='Benzina'
              type='text'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div> */}

          {/* Transmission */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Transmission</label>
            <SelectObject
              isMulti={true}
              styles={{
                menuPortal: (base: FlexibleObject) => ({ ...base, zIndex: 9999 }),
              }}
              key='transmission'
              name='Transmission'
              className='col-12'
              onChange={(transmissionType: TransmissionType[]) =>
                setFormState({ ...formState, transmission_type: transmissionType })
              }
              simpleValue={formState?.transmission_type || []}
              options={transmissionTypeList}
              menuPortalTarget={document.body}
              getOptionValue={(e: { name: string }) => e.name}
              getOptionLabel={(e: { name: string }) => e.name}
            />
          </div>
          {/* <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Transmission</label>
            <input
              name='transmission_type'
              value={formState.transmission_type}
              onChange={handleChange}
              placeholder='Manual'
              type='text'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div> */}

          {/* end::Form group */}
        </div>
        {/*end::Body*/}

        {/*begin::Footer*/}
        <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-top'>
          {/*begin::Actions*/}
          <div className='d-flex align-items-center me-3'></div>
          {/*end::Actions*/}

          {/*begin::Toolbar*/}
          <div className='d-flex align-items-center'>
            <button
              onClick={() => {
                const emptyEntry = findFalseEntry(formState);
                if (emptyEntry) {
                  const [key] = emptyEntry;
                  return toast.error(`${key} field is required!`);
                }
                if (carClass) {
                  return updateCarClass();
                } else {
                  return createNewCar();
                }
              }}
              className='btn btn-primary px-6'
            >
              {carClass ? 'Update' : 'Create'}
            </button>
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Footer*/}
        {/*end::Form*/}
      </div>
    </Modal>
  );
};

export enum StatusType {
  NOT_STARTED = 'Not started',
  ON_GOING = 'On going',
  FINISHED = 'Finished',
}
export const statusTypes = Object.values(StatusType);

export enum Type {
  EMAIL = 'Email',
  SMS = 'SMS',
  SMS_AND_EMAIL = 'sms_and_email',
}
export const campaignTypes = Object.values(Type);

export enum LanguageType {
  RO = 'ro',
  EN = 'en',
}
export const languageTypes = Object.values(LanguageType);

export enum FormStatusType {
  UPDATED = 'updated',
  NOT_UPDATED = 'not updated',
}
export const formStatusTypes = Object.values(FormStatusType);

export type CampaignType = {
  id: number;
  name: string;
  type: string;
  content_type: string;
  send_after: Date;
  expiration_date: Date;
  status: StatusType;
  number_of_clients: number;
};

export const emptyCampaign: CampaignType = {
  id: 0,
  name: '',
  type: '',
  content_type: '',
  send_after: null,
  expiration_date: null,
  status: null,
  number_of_clients: 0,
};

export type ClientCampaignType = {
  id: number;
  campaign_id: number;
  business_id: number;
  short_code: string;
  status: string;
  updated_at: string;
  is_owner: number;
  car_id: number;
  car_VIN: string;
  car_brand: string;
  car_license_number: string;
  car_model: string;
  car_number_of_km: number;
  car_preferred: string;
  company_address: string;
  company_bank: string;
  company_cui: string;
  company_iban: string;
  company_id: number;
  company_name: string;
  user_id: number;
  user_first_name: string;
  user_last_name: string;
  user_email: string;
  user_county: string;
  user_feedback: string;
  user_location: string;
  user_phone: string;
  user_preferred_language: string;
  user_is_data_processing_approved: number;
  user_is_promotion_approved: number;
  is_client_details_correct: number;
};

export const emptyClientCampaign: ClientCampaignType = {
  id: 0,
  campaign_id: 0,
  business_id: 0, // place_of_business
  short_code: null,
  updated_at: '',
  status: '',
  car_VIN: '',
  is_owner: 0,
  car_brand: '',
  car_id: 0,
  car_license_number: '',
  car_model: '',
  car_number_of_km: 0,
  car_preferred: '',
  company_address: '',
  company_bank: '',
  company_cui: '',
  company_iban: '',
  company_id: 0,
  company_name: '',
  user_county: '',
  user_email: '',
  user_feedback: '',
  user_first_name: '',
  user_id: 0,
  user_last_name: '',
  user_location: '',
  user_phone: '',
  user_preferred_language: '',
  user_is_data_processing_approved: 0,
  user_is_promotion_approved: 0,
  is_client_details_correct: 0,
};

export type SmsFormFieldsType = {
  id: number;
  campaign_id: number;
  verification_short_code: string;
  text_message: string;
  company: string;
  location: string;
  phone: string;
  brand: string;
  model: string;
  VIN: string;
  subsidiary: string;
  branch: string;
  license_number: string;
  county: string;
  email: string;
  full_name: string;
  no_of_km: number;
  sms_status: number;
  is_sent: number;
  is_data_processing_approved: number;
  is_client_details_correct: number;
  is_promotion_approved: number;
  is_car_estranged: number;
};

export const emptySmsFormFieldsType: SmsFormFieldsType = {
  id: 0,
  campaign_id: 0,
  verification_short_code: '',
  text_message: '',
  company: '',
  location: '',
  phone: '',
  brand: '',
  model: '',
  VIN: '',
  subsidiary: '',
  branch: '',
  license_number: '',
  county: '',
  email: '',
  full_name: '',
  no_of_km: 0,
  sms_status: 0,
  is_sent: 0,
  is_data_processing_approved: 0,
  is_client_details_correct: 0,
  is_promotion_approved: 0,
  is_car_estranged: 0,
};

export type ClientGeneralFieldsType = {
  user_first_name: string;
  user_last_name: string;
  user_email: string;
  user_phone: string;
};

export const emptyClientGeneralFieldsType: ClientGeneralFieldsType = {
  user_first_name: '',
  user_last_name: '',
  user_email: '',
  user_phone: ''
};

export type PlaceOfBusinessType = {
  id: number;
  name: string;
};

export type LocationType = {
  id: number;
  location: string;
};

export type CountyType = {
  id: number;
  county: string;
};

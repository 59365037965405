import React, {useMemo} from 'react'
import {EditableLabel} from '../../../../../app/modules/add-edit-template/components/fields-generator'
import Select from '../../select/Select'
import InputField from '../input/input-field'

const defaultCustomFieldName = '(no label)'

// This component is anti-pattern, we need to refactor it.
const CustomInputField = ({
  field,
  customFields,
  value,
  onChangeInput,
  onChangeSelect,
  onChangeLabel,
}: {
  field: any
  customFields: any
  value: any
  onChangeInput: (e: any) => void
  onChangeSelect: (e: any) => void
  onChangeLabel?: (value: string) => void
}) => {
  const customFieldInput = useMemo(() => {
    return customFields[field?.name]
  }, [field, customFields])

  const label = useMemo(() => {
    return customFieldInput?.label || defaultCustomFieldName
  }, [customFieldInput, field])

  const args = useMemo(() => {
    if (!label) {
      return []
    }

    return label.split(':')
  }, [label])

  const options = useMemo(() => {
    if (!args || args?.length <= 1) {
      return []
    }

    let futureOptions = (args[1] || '').split(';')
    futureOptions = futureOptions.map((option: string) => option.trim())
    futureOptions = futureOptions.map((option: string) => ({value: option, text: option}))
    return futureOptions
  }, [args])

  // Is type input
  if (args.length === 1) {
    let futureFieldInfo = {...field}
    if (customFieldInput?.label) {
      futureFieldInfo.label = customFieldInput.label
    }
    if (customFieldInput?.mandatory) {
      futureFieldInfo.required = true
    }
    return (
      <InputField
        field={futureFieldInfo}
        value={value}
        onChange={onChangeInput}
        onChangeLabel={onChangeLabel}
      />
    )
  }
  let simpleValue = value
  let selectOptionValue = {text: simpleValue, value: simpleValue}
  const onChange = (option: {text: string; value: string}) => onChangeSelect(option.value)
  return (
    <>
      <EditableLabel
        label={args[0] || ''}
        onChange={onChangeLabel}
        isRequired={customFieldInput.mandatory}
      />

      <Select
        name={field?.name}
        className='col-12'
        id={field?.name}
        required={customFieldInput.mandatory}
        onChange={onChange}
        value={selectOptionValue}
        options={options}
        getOptionValue={(option: any) => option.value}
        getOptionLabel={(option: any) => option.text}
      />
    </>
  )
}

export default CustomInputField

import React, { useState } from 'react'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';

const UserAvatar: React.FC<{src : any, alt : string, className? : string}> = ({src, alt, className = ''}) => {
  const [URL, setURL] = useState(src);
  return (
    <img className={className} src={toAbsoluteUrl(URL)} alt={"" + alt} onError={() => { setURL('/media/avatars/blank.jpg'); }}/>
  )
}

export default UserAvatar
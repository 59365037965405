import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ClientCampaignType,
  StatusType,
  Type,
  emptyClientCampaign,
  emptySmsFormFieldsType,
} from '../../../../types/campaign.types';
import FormFields from './FormFields.components';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import FinishedCampaign from './FinishedCampaign.components';
import {
  getCampaignStatus,
  getCampaignType,
  getClientsByEmailRequest,
  getClientsByShortCodeRequest,
} from '../../../../setup/axios/campaign.request';
import KmAndTiresForm from '../km-and-tires-campaign/KmAndTiresForm.component';
import { LoadingCard } from '../../../../_metronic/layout/components/loading/Loading';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { APIResponseType } from '../../../../types/general.types';

interface Params {
  campaignId: string;
  clientCode: string;
  type?: string;
}
// removed code blocks that aren't necessary anymore (they weren't used) in order to see which endpoints were needed
// to be available without authentication

const Form = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [clientsByCampaign, setClientsByCampaign] = useState<ClientCampaignType[]>([]);
  const [isCurrentCampaignExpired, setIsCurrentCampaignExpired] = useState<boolean>(false);
  const [campaignType, setCampaignType] = useState<string>('');
  const { campaignId, clientCode, type } = useParams<Params>();
  const [phoneNumberOrEmail, setPhoneNumberOrEmail] = useState<string>('');

  const fetchData = async () => {
    setIsLoading(true);
    let clientsResponse: APIResponseType;

    if (clientCode) {
      clientsResponse = await getClientsByShortCodeRequest(
        clientCode,
        Number(campaignId),
        phoneNumberOrEmail
      );
    } else {
      clientsResponse = await getClientsByEmailRequest(Number(campaignId), phoneNumberOrEmail);
    }

    if (!clientsResponse || clientsResponse.error) {
      toast.error(`Error: ${clientsResponse?.message || ''}`);
      setIsLoading(false);
      return;
    }

    const statusResponse = await getCampaignStatus(Number(campaignId));
    if (!statusResponse || statusResponse.error) {
      toast.error(statusResponse?.message || 'Something went wrong');
      setIsLoading(false);
      return;
    }

    const typeResponse = await getCampaignType(Number(campaignId));
    if (!typeResponse || typeResponse.error) {
      toast.error(typeResponse?.message || 'Something went wrong');
      setIsLoading(false);
      return;
    }
    setCampaignType(typeResponse.campaign_type);

    const currentCampaignStatus = statusResponse.campaign_status;
    const hasCampaignExpired = currentCampaignStatus === StatusType.FINISHED;
    setIsCurrentCampaignExpired(hasCampaignExpired);

    if (!hasCampaignExpired) {
      if (!clientsResponse || clientsResponse.error) {
        toast.error(`This client has no cars! ${clientsResponse?.message || ''}`);
        setIsLoading(false);
        return;
      }

      // if campaing lcient is empty, set empty array
      if (clientsResponse.campaign_clients.length === 0) {
        toast.error(clientCode ? 'Număr de telefon invalid!' : 'Email invalid!');
      }

      setClientsByCampaign(clientsResponse.campaign_clients);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (campaignId !== '0' && phoneNumberOrEmail) {
      fetchData();
    }
  }, []);

  const canContinueFromPhoneStep = useMemo(() => {
    if (!phoneNumberOrEmail) {
      return false;
    }

    if (clientCode) {
      // regex /^(?:(?:(?:00\s?|\+)40\s?|0)(?:7\d{2}\s?\d{3}\s?\d{3}|(21|31)\d{1}\s?\d{3}\s?\d{3}|((2|3)[3-7]\d{1})\s?\d{3}\s?\d{3}|(8|9)0\d{1}\s?\d{3}\s?\d{3}))$/
      // test regex
      if (
        !/^(?:(?:(?:00\s?|\+)40\s?|0)(?:7\d{2}\s?\d{3}\s?\d{3}|(21|31)\d{1}\s?\d{3}\s?\d{3}|((2|3)[3-7]\d{1})\s?\d{3}\s?\d{3}|(8|9)0\d{1}\s?\d{3}\s?\d{3}))$/.test(
          phoneNumberOrEmail
        )
      ) {
        return false;
      }
    }
    else {
      // regex for email
      if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(phoneNumberOrEmail)) {
        return false;
      }
    }

    return true;
  }, [phoneNumberOrEmail]);

  if (!phoneNumberOrEmail || clientsByCampaign.length === 0) {
    return (
      <div className='container-form-sms-campaign' style={{ backgroundColor: 'white' }}>
        <div className='contact content-area ' id='post-8'>
          <div className='container'>
            <div className='wrapper-form'>
              <div className='logo-custom'>
                <img
                  alt='Form Logo'
                  className='logo-custom mt-10'
                  src={toAbsoluteUrl('/media/logos/logo-leasing-operational.png')}
                />
              </div>

              <p style={{ textAlign: 'center' }}>
                { clientCode ? 'Te rugăm să confirmi numărul de telefon pentru a putea continua.' : 'Te rugăm să confirmi email-ul pentru a putea continua.' }
              </p>
              <div
                className='form-group'
                style={{
                  marginTop: 16,
                }}
              >
                <input
                  type='text'
                  className='form-control'
                  // validate
                  value={phoneNumberOrEmail}
                  placeholder={ clientCode ? 'Număr de telefon' : 'Email'}
                  onChange={(e) => {
                    // if e is not a number, return
                    if (clientCode && isNaN(Number(e.target.value))) {
                      return;
                    }
                    setPhoneNumberOrEmail(e.target.value);
                  }}
                />
                <p
                  style={{
                    paddingLeft: 4,
                    opacity: 0.5,
                    fontSize: 14,
                    marginTop: 5,
                  }}
                >
                  { clientCode ? 'Format acceptat: 0712345678' : '' } 
                </p>

                <button
                  disabled={!canContinueFromPhoneStep}
                  style={{
                    marginTop: 15,
                    width: '100%',
                    opacity: canContinueFromPhoneStep ? 1 : 0.5,
                  }}
                  className='btn btn-primary btn-block'
                  onClick={() => {
                    fetchData();
                  }}
                >
                  Continuă
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return <LoadingCard />;
  }

  return (
    <div className='container-form-sms-campaign' style={{ backgroundColor: 'white' }}>
      <div className='contact content-area ' id='post-8'>
        <div className='container'>
          <div className='wrapper-form'>
            <div className='logo-custom'>
              <img
                alt='Form Logo'
                className='logo-custom mt-10'
                src={toAbsoluteUrl('/media/logos/logo-leasing-operational.png')}
              />
            </div>

            {campaignId === '0' ? (
              type === Type.SMS.toLocaleLowerCase() ? (
                <>
                  <p style={{ textAlign: 'center' }}>
                    Te rugăm să verifici datele urmatoarelor mașini.
                  </p>
                  <FormFields client={emptySmsFormFieldsType} formType='edit' />
                  <p style={{ textAlign: 'center', paddingTop: 15 }}>
                    În cazul înregistrării unei mașini noi, completează următorul formular.
                  </p>
                  <FormFields client={emptySmsFormFieldsType} formType='add' />
                </>
              ) : type === Type.SMS_AND_EMAIL.replace(/ /g, '_').toLocaleLowerCase() ? (
                <KmAndTiresForm clients={[emptyClientCampaign]} fetchData={async () => { }} />
              ) : null
            ) : !isCurrentCampaignExpired && !isLoading ? (
              campaignType === Type.SMS.toLocaleLowerCase() ? (
                <>
                  {clientsByCampaign?.map((item, index) => (
                    <FormFields client={item} formType='edit' key={index} />
                  ))}
                  <FormFields client={emptySmsFormFieldsType} formType='add' />
                </>
              ) : campaignType === Type.SMS_AND_EMAIL.replace(/ /g, '_').toLocaleLowerCase() ? (
                <KmAndTiresForm clients={clientsByCampaign} fetchData={fetchData} />
              ) : null
            ) : (
              <FinishedCampaign />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;

import {FC, useEffect, useState} from 'react'
import {BookingTaxesType} from './PlanTypes'
import {updateField} from '../../../utils/state.utils'
import Table from './Table'
import {usePlanContext} from '../../../context/plan.context'
import {getStringOfEntity} from '../../../utils/string.utils'

const bookingTaxesTypeReq = ['title', 'value']
const bookingTaxesTypeUnique = ['title']
const emptyBookingTax: BookingTaxesType = {
  name: '',
  title: '',
  value: 0,
}
const getBookingTaxLabel = (bookingTax: BookingTaxesType) =>
  'booking tax: ' +
  (bookingTax.name ? bookingTax.name || '' + ' - ' : '') +
  (bookingTax.title || '')

const BookingTaxesTable: FC<{}> = ({}) => {
  const {plan, setPlan, dictionaries} = usePlanContext()

  const [bookingTaxes, setBookingTaxes] = useState<BookingTaxesType[]>(
    plan?.bookingManagementTaxes || []
  )

  useEffect(() => {
    setPlan({...plan, bookingManagementTaxes: bookingTaxes})
  }, [bookingTaxes])

  const bookingProps = [
    {name: 'name', getDisplayLabel: (e: any) => e, label: 'Name', type: 'input'},
    {name: 'title', getDisplayLabel: (e: any) => e, label: 'Title', type: 'select'},
    {
      name: 'value',
      getDisplayLabel: (e: any) => e,
      label: 'Value',
      type: 'currencyInput',
      addOns: {append: (plan?.currency || '').toLowerCase()},
      canEditInView: true,
      minWidth: '135px',
    },
  ]

  const BookingFieldToProperty = (
    field: any,
    bookingTax: BookingTaxesType,
    setBookingTax: Function
  ) => {
    let fieldName = field.name
    const updateBookingTaxListElemEvent = (e: any) =>
      updateField({value: e, fieldName: fieldName, setObject: setBookingTax})

    const fieldsToPanelPropertiesMapping = {
      title: {
        simpleValue: bookingTax.title || '',
        options: dictionaries.BOOKING_MANAGEMENT_TAXES || [],
        getOptionValue: (e: any) => e.title || '',
        getOptionLabel: (e: any) => e.title || '',
        onChange: updateBookingTaxListElemEvent,
      },
      name: {value: getStringOfEntity({entity: bookingTax.name})},
      // the fieldName is value, the object contained has info about the field itself (value, onChange, other effects, etc)
      value: {
        value: bookingTax.value + '',
      },
    }

    return (fieldsToPanelPropertiesMapping as any)[fieldName]
  }

  return (
    <>
      <Table
        tableLabel={'Booking Management Taxes Table'}
        properties={bookingProps}
        fieldToProperty={BookingFieldToProperty}
        entries={bookingTaxes}
        setEntries={setBookingTaxes}
        emptyEntry={emptyBookingTax}
        getEntryLabel={getBookingTaxLabel}
        requiredFields={bookingTaxesTypeReq}
        compoundUniqueKey={bookingTaxesTypeUnique}
      />
    </>
  )
}

export default BookingTaxesTable

import React, { Suspense, useContext, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { Routes } from './routing/Routes'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { UserContext } from '../context/user.context'
import { AppContext } from '../context/app.context'
type Props = {
  basename: string
}

Sentry.init({
  dsn: 'https://bf03f670e13b4124a6d2b56f02d155b0@o1128191.ingest.sentry.io/6389583',
  integrations: [new BrowserTracing()],
  enabled: process.env.NODE_ENV !== 'development',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
})

function App({ basename }: Props) {
  const { businessProfileId } = useContext<any>(UserContext)
  const { initializeData } = useContext<any>(AppContext)

  useEffect(() => {
    if (businessProfileId) {
      initializeData(businessProfileId)
    }
  }, [businessProfileId])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <Routes />
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export { App }

export type RentPlanVehicleClassModel = {
    id: number
    name: string
    vehicle_type: string
    description: string | null
    image_url: string | null
    number_of_doors: string | null
    luggage_capacity: string
    fuel_type: string[]
    transmission_type: string[]
    included_km_per_day: string
    extra_cost_per_km: string
    created_at: string | null
    updated_at: string | null
  }

  export const emptyRentPlanVehicleClass = {
  name: "",
  vehicle_type: "",
  description: null,
  image_url: null,
  number_of_doors: null,
  luggage_capacity: "",
  fuel_type: [],
  transmission_type: [],
  included_km_per_day: "",
  extra_cost_per_km: "",
  created_at: null,
  updated_at: null
}

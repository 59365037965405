import { Modal } from "react-bootstrap"
import { KTSVG } from "../../_metronic/helpers";

type DeleteModalProps = {
  deleteMethod: any
  deleteModalVisible: boolean
  setDeleteModalVisible: Function,
  textModal: string;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  deleteModalVisible,
  setDeleteModalVisible,
  deleteMethod,
  textModal
}) => {

  return (
    <>
      {deleteModalVisible && (
        <Modal
          className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
          id='kt_inbox_compose'
          role='dialog'
          data-backdrop='static'
          aria-hidden='true'
          tabIndex='-1'
          show={deleteModalVisible}
          animation={true}
        >
          <div className='modal-content'>
            {/* begin::Form */}
            {/*begin::Header*/}
            <div className='d-flex align-items-center justify-content-between py-3 ps-4 pe-3'>
              <h2 className='fw-bold m-0'> </h2>
              <div className='d-flex ms-2'>
                {/*begin::Close*/}
                <div
                  className='btn btn-icon btn-sm btn-secondary ms-2'
                  data-bs-dismiss='modal'
                  onClick={() => setDeleteModalVisible(false)}
                >
                  <KTSVG className='svg-icon-1' path='/media/icons/duotone/Navigation/Close.svg' />
                </div>
                {/*end::Close*/}
              </div>
            </div>
            {/*end::Header*/}

            {/*begin::Body*/}
            <div className='d-block ps-8 pe-5 text-center'>
              {/* end::Form group */}

              <h2 className='d-block py-5 pe-5'>Are you sure?</h2>
              <p className='d-block py-5'>
                {textModal}
              </p>
            </div>
            {/*end::Body*/}

            {/*begin::Footer*/}
            <div className='d-flex align-items-center justify-content-center py-5 ps-8 pe-5 border-top'>
              {/*begin::Actions*/}
              <div className='d-flex align-items-center me-3'></div>
              {/*end::Actions*/}

              {/*begin::Toolbar*/}
              <div className='mt-2' style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  className='btn btn-secondary me-10'
                  data-bs-dismiss='modal'
                  onClick={() => setDeleteModalVisible(false)}
                >
                  Cancel
                </div>
                <div>
                  <button
                    onClick={deleteMethod}
                    className='btn btn-danger me-5'
                  >
                    Delete
                  </button>
                </div>
              </div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Footer*/}
            {/*end::Form*/}
          </div>
        </Modal>
      )}
    </>
  )
}
import React, { useContext, useEffect } from 'react'
import { Card } from '../../add-edit-transport/scripts/structuralFields';
import { Field } from '../utils'
import { ClientType, emptyClient, TypeOfClient } from '../../../../types/client.types';
import { SubscriptionsContext } from '../../../../context/subscriptions.context';

const ClientCard = ({ client, setClient }: { client: ClientType; setClient: Function }) => {
  const { clientFields, setClientFields } = useContext<any>(SubscriptionsContext)

  const getFieldPropertiesFromFieldName = (fieldName: string) => {
    if (!Boolean(fieldName)) {
      return null
    }
    switch (fieldName) {
      case 'type':
        return {
          simpleValue: client?.type || emptyClient?.type,
          options: [{ type: TypeOfClient.PF }, { type: TypeOfClient.PJ }],
          getOptionValue: (e: { type: TypeOfClient }) => e.type,
          getOptionLabel: (e: { type: TypeOfClient }) => e.type,
          onChange: (type: TypeOfClient) => {
            setClient({ ...client, type: type })
            updateClientFields(type)
          },
        }
      case 'insurance_for':
        return {
          value: client?.insurance_for || '',
          onChange: (value: string) => {
            if (client?.type === 'PF') {
              setClient({ ...client, insurance_for: value, name: value })
            } else {
              setClient({ ...client, insurance_for: value })
            }
          },
        }
      default:
        const value = client[fieldName] || emptyClient[fieldName]
        if (value === undefined) {
          return null
        }
        return value
    }
  }

  const updateClientFields = (e) => {
    setClientFields(oldClientFields => oldClientFields.map((field) => {
      if (e === 'PF') {
        if (field.name === 'insurance_for') field.label = 'Proprietar'
        if (field.name === 'name') field.label = 'Utilizator'
        if (field.name === 'identity_code') {
          field.label = 'CNP'
          field.hidden = false
          field.required = false
        }
      } else if (e === 'PJ') {
        if (field.name === 'insurance_for') field.label = 'Nume companie'
        if (field.name === 'name') field.label = 'Nume si Prenume'
        if (field.name === 'identity_code') {
          field.label = 'CUI'
          field.hidden = false
          field.required = true
        }
      }
      return field
    }))
  }

  useEffect(() => {
    updateClientFields(client?.type)
  }, [client?.id])

  return (
    <div key={'client-container'} className='col-12 pb-4'>
      <Card name={'Client'}>
        {clientFields.map((field: any, index: number) => {
          const fieldName = field?.name
          const fieldProperty = getFieldPropertiesFromFieldName(fieldName)
          return (
            <Field
              key={`field-client-${fieldName}`}
              field={field}
              fieldName={fieldName}
              style={field?.style}
              isHidden={field?.hidden}
              fieldProperty={fieldProperty}
              setObject={setClient}
            />
          )
        })}
      </Card>
    </div>
  )
}

export default ClientCard

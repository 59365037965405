import {FC, useState} from 'react'
import {toast} from 'react-toastify'
import { Card } from '../../../app/modules/add-edit-transport/scripts/structuralFields'
import {generateField} from '../../../components/field/field-generator'
import {areObjectsSimilar, deleteListElem, replaceListElem} from '../../../utils/state.utils'
import {KTSVG} from '../../../_metronic/helpers'
import ModalPortal from '../../../_metronic/layout/components/modal/modal-portal'
import moment from 'moment'

const getUniqueKey = (entry: any, keyList: string[]) => {
  return keyList.map((key: string) => JSON.stringify(entry[key]) || '').join(',')
}

const Table: FC<{
  tableLabel: string
  properties: any[]
  fieldToProperty: Function
  entries: any[]
  setEntries: Function
  emptyEntry: any
  getEntryLabel: Function
  requiredFields: string[]
  compoundUniqueKey?: string[]
  extraExceptions?: {message: string; isEntryValid: Function}[],
  deleteEndpoint?: Function,
  editEndpoint?: Function,
  createEndpoint?: Function,
  isPlanCreated?: boolean,
  isTariffs? : boolean
  hasChildProperties?: boolean,
  addEditOnColumn?: boolean,
  onEditCustom?: Function,
  addEntryLabel?: string,
  addEntryButtonVisible?: boolean
  customEditModal?: (p: {setModalVisible, setToBeEditedTable}) => JSX.Element,
  isVehicleClass?: boolean,
  isResponse?: boolean,
  resetStateValue?: any,
  onSaveResetState?: Function,
  onCancelCustom?: Function,
}> = ({
  tableLabel,
  properties,
  fieldToProperty,
  entries,
  setEntries,
  emptyEntry,
  getEntryLabel,
  requiredFields,
  compoundUniqueKey,
  extraExceptions,
  deleteEndpoint,
  editEndpoint,
  createEndpoint,
  isPlanCreated,
  isTariffs,
  hasChildProperties,
  addEditOnColumn,
  onEditCustom,
  customEditModal,
  addEntryLabel = "Add Entry",
  addEntryButtonVisible = true,
  isVehicleClass,
  isResponse,
  resetStateValue,
  onSaveResetState,
  onCancelCustom,
}) => {
  const [originalEntry, setOriginalEntry] = useState<any>(emptyEntry)
  const [toBeEdited, setToBeEdited] = useState<any>()
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [toBeDeleted, setToBeDeleted] = useState<any>()
  const [pickUpTimeTable, setPickupTimeTable] = useState(moment());
  const [deliveryTimeTable, setDeliveryTimeTable] = useState(moment());
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getMissingFields = (entry: any) =>
    requiredFields.filter(
      (reqField: string) =>
        entry[reqField] == null ||
        entry[reqField] === '' ||
        (Array.isArray(entry[reqField]) && entry[reqField].length === 0)
    )

  const addEvent = ({elem}: {elem: any}) => setEntries([...entries, elem])

  const editEvent = ({oldElem, newElem}: {oldElem: any; newElem: any}) => {
    const newList = replaceListElem({
      listElem: oldElem,
      newListElem: newElem,
      oldList: entries,
    })
    setEntries(newList)
  }

  const deleteEvent = ({elem}: {elem: any}) => {
    const newList = deleteListElem({
      listElem: elem,
      oldList: entries,
    })
    setEntries(newList)
  }

  const DeleteModal = () => {
    return (
      <div className='py-5 px-8' style={{display: 'flex', flexDirection: 'column'}}>
        <span style={{textAlign: 'center', fontSize: '16px'}}>
          Are you sure you want to delete entity: {getEntryLabel(toBeDeleted)}?
        </span>
        <div className='mt-8' style={{display: 'flex', justifyContent: 'center'}}>
          <div
            className='btn btn-secondary me-5'
            onClick={() => {
              setToBeDeleted(null)
              setModalVisible(false)
            }}
          >
            Cancel
          </div>
          <div
            className='btn btn-danger'
            onClick={() => {
              deleteEvent({elem: toBeDeleted})
              deleteEndpoint(toBeDeleted.id)
              toast.success('Entity sucessfully deleted!')
              setToBeDeleted(null)
              setModalVisible(false)
            }}
          >
            Delete
          </div>
        </div>
      </div>
    )
  }

  const Actions = (p: { entry: any, customValue?: any }) => <div style = {(isResponse || isTariffs) ? {display: "flex", justifyContent: "center"} : {}}>
    {
      !isVehicleClass ?
        <button
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
          hidden={isTariffs}
          onClick={() => {
            setModalVisible(true)
            setToBeEdited(p.entry)
            setOriginalEntry(p.entry)
            if (typeof onEditCustom === "function") {
              if (p.customValue) {
                onEditCustom({ ...p.customValue, isEditMode: true })
              } else {
                onEditCustom({ ...p.entry, isEditMode: true })
              }
            }
          }}
        >
          <KTSVG
            path='/media/icons/duotone/Communication/Write.svg'
            className='svg-icon-3'
          />
        </button> : <></>
    } 
    <button
      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
      onClick={() => {
        setToBeDeleted(p.entry)
        if(typeof onEditCustom === "function") {
          if (p.customValue) {
            onEditCustom(p.customValue)
          } else {
            onEditCustom(p.entry)
          }
        }
        setModalVisible(true)
      }}
    >
      <KTSVG
        path='/media/icons/duotone/General/Trash.svg'
        className='svg-icon-3'
      />
    </button>
  </div>

  const ViewTable = () => {
    const shownProperties = properties.filter(p => !p.hideInTable);
    return (
      <div className='p-0 w-100'>
        <div className='table-responsive'>
          <table className={(isResponse || isTariffs) ? 
            'grid-table table table-hover table-striped table-row-gray-100 align-middle' : 
            'table table-hover table-striped table-row-gray-100 align-middle'}>
            <thead>
              <tr style={isPlanCreated ? {opacity: 1} : {opacity: 0.5}} className='fw-bolder'>
                {shownProperties.filter(p => !p.syncWithNextProp).map((prop: any, idx) => (
                  <th
                    className='max-w-140px pb-0 ps-5'
                    style={{minWidth: Boolean(prop.minWidth) ? prop.minWidth : '100px', width: prop.width ?? "auto"}}
                    key={'header-' + prop.label}
                  >
                    <div style={{display: "flex", flexDirection: "row", alignItems:"center", justifyContent: (isResponse || isTariffs) ? "center" : "initial"}}>
                      <span style={hasChildProperties ? {display: "flex", justifyContent: "center"} : {whiteSpace: 'nowrap'}}>{typeof prop.label === "function" ? prop.label(prop.name) : prop.label}</span>
                      {(addEditOnColumn && prop.editOnColumn) ? <div style={{ marginLeft: 12 }}><Actions entry={entries} customValue={prop.customValue} /></div> :
                        <div style={{ margin: "16px 0px" }}></div>}
                    </div>
                  </th>
                ))}
                {!addEditOnColumn && <th style = {(isResponse || isTariffs) ? {display: "flex", justifyContent: "center"} : {}} className='min-w-100px text-end pb-0 pe-4'>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {hasChildProperties && <tr className='fw-bolder' style= {{}}>
                {properties.filter(p => !p.hideInTable).map((prop: any) => (
                  <td
                    className='ps-5'
                    style={{ height: 50 }}
                    key={'header2-' + prop.label}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {prop.children && prop.children.map((child, index) => <span key={'header2-child-' + child.label} style={{ whiteSpace: 'nowrap', width: "100%", textAlign: "center", borderRight: prop.children.length -1 !== index ? "1px solid #e9e9e9" : "none", paddingTop: 15, margin: "-25px 0" }}>
                        {typeof child.label === "function" ? child.label(child.name) : child.label}
                      </span>)}
                    </div>
                  </td>
                ))}
              </tr>}
              {entries?.map((entry: any) => (
                <tr key={'row-' + getUniqueKey(entry, compoundUniqueKey)}>
                  {properties.filter(p => !p.hideInTable).map((prop: any, idx) => (
                    <td className='ps-5' style={{textAlign: (isResponse || isTariffs) ? "center" : "left"}} key={prop.name}>
                      {Boolean(prop.canEditInView)
                        ? <div style = {(isTariffs && properties.filter(p => !p.hideInTable).length - 1 === idx) ? {marginRight: 12} : {}}>
                            {generateFormField({
                              field: prop,
                              entry: entry,
                              setObject: (getModifiedObject: Function) => {
                                let editedEntry = {...entry}
                                editedEntry = getModifiedObject(entry)
                                editEvent({oldElem: entry, newElem: editedEntry})
                              },
                              hideLabel: true,
                            })}
                        </div>
                        : <span>
                            {prop.getDisplayLabel(entry[prop.name], entry)}
                            {(prop.addOns && prop.addOns.append) && <span style = {{fontWeight: "600", position: "relative", left: 6}}>
                              {prop.addOns.append}
                            </span>}
                          </span>
                      }
                      {prop.children && <div style={{ display: "flex", flexDirection: "row" }}>
                        {prop.children.map((child, idx) => <div key={'child - ' + child.label} style = {{width: `${100 / (prop.children.length)}%`, borderRight: prop.children.length -1 !== idx ? "1px solid #e9e9e9" : "none", paddingTop: 22, margin: "-30px 0"}}>
                          {Boolean(child.canEditInView)
                            ? generateFormField({
                              field: child,
                              entry: entry,
                              setObject: (getModifiedObject: Function) => {
                                let editedEntry = { ...entry }
                                editedEntry = getModifiedObject(entry)
                                editEvent({ oldElem: entry, newElem: editedEntry })
                              },
                              hideLabel: true,
                            })
                            : <span key={child.label}>
                                {child.getDisplayLabel(entry[child.name], entry)}
                                <span style = {{fontWeight: "600", position: "relative", left: 6}}>
                                  {(child.addOns && child.addOns.append) ?? ""}
                                </span>
                              </span>
                            }
                        </div>)}
                      </div>}
                    </td>
                  ))}
                  {!addEditOnColumn && <td className='text-end pe-2'>
                    <Actions entry={entry} />
                  </td>}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {addEntryButtonVisible ? <button
          type='button'
          className='btn btn-light-primary btn-text-primary mt-2'
          style={{float: 'right'}}
          disabled= {isPlanCreated === false}
          onClick={() => {
              setToBeEdited(emptyEntry)
              setOriginalEntry(emptyEntry)
              setModalVisible(true)
              if (typeof onEditCustom === "function") {
                onEditCustom({...emptyEntry, isEditMode: false})
              }
          }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <KTSVG path='/media/icons/duotone/Navigation/Plus.svg' className='svg-icon-2' />
              {addEntryLabel}
            </div>
        </button> : <></>}
      </div>
    )
  }

  const generateFormField = ({
    field,
    entry,
    hideLabel,
    setObject,
  }: {
    field: any
    setObject?: Function
    entry?: any
    hideLabel?: boolean
  }) => {
    if (!Boolean(field?.type)) return
    const fieldName = field?.name
    let setObjectFunc = Boolean(setObject) ? setObject : setToBeEdited
    let object = Boolean(entry) ? entry : toBeEdited
    let addOns = field.addOns || {}
    if (field.dependentOfEntry && field.getAddOns) {
      addOns = field.getAddOns(object)
    }
    const fieldProperty = fieldToProperty(field, object, setObjectFunc)
    return generateField({
      field: {...field, addOns: addOns},
      fieldName,
      fieldProperty,
      setObject: setObjectFunc,
      hideLabel,
    })
  }

  const EditModal = () => {
    const isEditMode = !areObjectsSimilar({objectA: originalEntry, objectB: emptyEntry})
    return (
      <div className='py-4 px-6 w-100 h-100'>
        <span style={{fontSize: '16px', fontWeight: '500'}}>
          {isEditMode ? 'Edit' : 'Add'} Table Entity
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          className='mt-4'
        >
          {properties
            .filter((prop) => (isEditMode ? prop.editable !== false : prop.addable !== false))
            .map((field: any) =>
              !field.editModalChildren ? (
                <div className='py-1' key={'container-' + field.name}>
                  {generateFormField({field})}
                </div>
              ) : Array.isArray(field.editModalChildren) ? (
                <div key={'container-' + field.name}>
                  <div
                    className='py-1 mt-4'
                    style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
                    key={'container-' + field.name}
                  >
                    {field.editModalChildren.map((child) => generateFormField({field: child}))}
                  </div>
                </div>
              ) : (
                <></>
              )
            )}
        </div>
        <div className='mt-8' style={{display: 'flex', justifyContent: 'center'}}>
          <div
            className='btn btn-secondary me-5'
            onClick={() => {
              if (typeof onCancelCustom === 'function') {
                onCancelCustom()
              }
              setToBeEdited(null)
              setModalVisible(false)
              if (typeof onEditCustom === 'function') {
                onEditCustom(null)
              }
              if (typeof onSaveResetState === 'function') {
                onSaveResetState(resetStateValue)
              }
            }}
          >
            Cancel
          </div>
          <button
            className='btn btn-success'
            onClick={async () => {
              setIsLoading(true)
              const missingFields = getMissingFields(toBeEdited)
              const isEntryComplete = missingFields.length === 0
              if (!isEntryComplete) {
                toast.warning(
                  'Please provide a valid entry: MISSING VALUES - ' + missingFields.join(', ')
                )
                setIsLoading(false)
                return
              }
              if (Boolean(compoundUniqueKey)) {
                let otherEntries = !areObjectsSimilar({objectA: originalEntry, objectB: emptyEntry})
                  ? entries.filter(
                      (entry: any) => !areObjectsSimilar({objectA: entry, objectB: originalEntry})
                    )
                  : entries
                if (
                  otherEntries.some((entry: any) => {
                    return areObjectsSimilar({
                      objectA: entry,
                      objectB: toBeEdited,
                      fields: compoundUniqueKey,
                    })
                  })
                ) {
                  toast.warning(
                    'The Provided entry already exists: UNIQUE CONSTRAINT ON COMPOUND KEY - ' +
                      compoundUniqueKey.join(', ')
                  )
                  setIsLoading(false)
                  return
                }
              }
              if (Boolean(extraExceptions)) {
                let isValid = true
                extraExceptions.forEach((exception) => {
                  if (!exception.isEntryValid(toBeEdited)) {
                    toast.warning('EXCEPTION: ' + exception.message)
                    isValid = false
                  }
                })
                if (!isValid) {
                  setIsLoading(false)
                  return
                }
              }
              let response: boolean

              if (isEditMode) {
                editEvent({oldElem: originalEntry, newElem: toBeEdited})
                response = await editEndpoint(toBeEdited)
              } else {
                if (!isTariffs) {
                  addEvent({elem: toBeEdited})
                }
                response = await createEndpoint(toBeEdited)
              }
              if (response) {
                setToBeEdited(null)
                setModalVisible(false)
                if (typeof onSaveResetState === 'function') {
                  onSaveResetState(resetStateValue)
                }
                if (typeof onEditCustom === 'function') {
                  onEditCustom(null)
                }
              }
              else {
                editEvent({oldElem: toBeEdited, newElem: originalEntry})
              }
              setIsLoading(false)
            }}
            disabled={isLoading}
          >
            Save
          </button>
        </div>
      </div>
    )
  }

  return (
    <Card key={tableLabel} name={tableLabel} isPlanCreated={isPlanCreated}>
      {modalVisible ? (
        <ModalPortal setVisible={setModalVisible} visible={modalVisible} hasExitButton={false}>
          {Boolean(toBeEdited) ? (!customEditModal ? EditModal() : customEditModal({setModalVisible, setToBeEditedTable: setToBeEdited})) : Boolean(toBeDeleted) ? DeleteModal() : null}
        </ModalPortal>
      ) : null}
      {ViewTable()}
    </Card>
  )
}

export default Table

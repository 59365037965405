import { EASYTRACK_API_URL } from '../../constants/api.constants'
import {logError} from '../../utils/sentry.utils'
import easytrackAxios from '../easytrack.axios'

export const getVehiclesRequest = ({}) => {
  return easytrackAxios.get(`${EASYTRACK_API_URL}/vehicles`).catch((err) => err)
}

/**
 * Get all vehicles for autocomplete
 * Need at least 3 characters for plateNumber param
 * @param code
 * @returns
 */
export const getAllVehiclesRequest = async ({
  plateNumber,
  businessProfileId,
  active = true,
  usedForTransport = null,
}) => {
  if (!businessProfileId) {
    logError({name: 'Missing businessProfileId', error: {}, type: 'error'})
    return null
  }
  if (!plateNumber || plateNumber.length < 3) {
    return []
  }

  const result = await easytrackAxios.get(
    `${EASYTRACK_API_URL}/vehicles?businessProfileId=${businessProfileId}&active=${active}&usedForTransport=${usedForTransport}&search=${plateNumber}`
  )
  if (result.status <= 299) {
    return result.data
  }

  return []
}

export const getVehicleBrandsRequest = ({withModels = true}: {withModels?: boolean}) => {
  return easytrackAxios
    .get(`${EASYTRACK_API_URL}/vehicle-brands?withModels=${withModels}`)
    .catch((err) => err)
}

export const getVehicleTypesRequest = ({}) => {
  return easytrackAxios.get(`${EASYTRACK_API_URL}/vehicle-types`).catch((err) => err)
}

export const updateVehicleForDriver = async ({
  businessProfileId,
  driverUserId,
  vehicleId,
}: {
  businessProfileId: string | number
  driverUserId: string | number
  vehicleId: string | number
}) => {
  var path = `/business-profiles/${businessProfileId}/user-profiles/${driverUserId}/vehicles/${vehicleId}`
  return easytrackAxios.put(`${EASYTRACK_API_URL}${path}`).catch((err) => err)
}

export const deleteVehicleForDriver = async ({
  businessProfileId,
  driverUserId,
}: {
  businessProfileId: string | number
  driverUserId: string | number
}) => {
  var path = `/business-profiles/${businessProfileId}/user-profiles/${driverUserId}/vehicles`
  return easytrackAxios.delete(`${EASYTRACK_API_URL}${path}`).catch((err) => err)
}
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {UsersContext} from '../../../../../context/users.context'
import {anyToBoolean, capitalize} from '../../../../../utils/string.utils'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {getSortedList} from '../../../../../utils/sort.utils'
import {UserModel} from '../../../auth/models/UserModel'
import {CreateUpdateUserModal} from '../create-update-user-modal/CreateUpdateUserModal.component'
import Pagination from '../../../transport-requests/Pagination'

type UserRowProps = {
  user: UserModel
  setCurrentUser: any
  setShowCreateUserModal: any
  deleteUser: any
  addToSelected: any
  removeFromSelected: any
  checked: any
}

type Props = {
  className: string
  pageCount: number
  usersCount: number
  currentPage: number
  resultsPerPage: number
  setCurrentPage: (_: number) => void
  setResultsPerPage: (_: number) => void
  companyId:number
}

const UserRow: React.FC<UserRowProps> = ({
  user,
  setCurrentUser,
  setShowCreateUserModal,
  deleteUser,
  addToSelected,
  removeFromSelected,
  checked
}) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
  return (
    <>
      {deleteModalVisible && (
        <Modal
          className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
          id='kt_inbox_compose'
          role='dialog'
          data-backdrop='static'
          aria-hidden='true'
          tabIndex='-1'
          show={deleteModalVisible}
          animation={true}
        >
          <div className='modal-content'>
            {/* begin::Form */}
            {/*begin::Header*/}
            <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5'>
              <h2 className='fw-bold m-0'> </h2>
              <div className='d-flex ms-2'>
                {/*begin::Close*/}
                <div
                  className='btn btn-icon btn-sm btn-light-primary ms-2'
                  data-bs-dismiss='modal'
                  onClick={() => setDeleteModalVisible(false)}
                >
                  <KTSVG className='svg-icon-1' path='/media/icons/duotone/Navigation/Close.svg' />
                </div>
                {/*end::Close*/}
              </div>
            </div>
            {/*end::Header*/}

            {/*begin::Body*/}
            <div className='d-block py-5 ps-8 pe-5'>
              {/* end::Form group */}

              <h2>Are you sure?</h2>
              <p>
                The user{' '}
                <strong>
                  {user.firstName} {user.lastName}
                </strong>{' '}
                with registration id: <strong>{user.id}</strong>, will be deleted forever
              </p>
            </div>
            {/*end::Body*/}

            {/*begin::Footer*/}
            <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-top'>
              {/*begin::Actions*/}
              <div className='d-flex align-items-center me-3'></div>
              {/*end::Actions*/}

              {/*begin::Toolbar*/}
              <div className='d-flex align-items-center'>
                <button
                  onClick={async () => {
                    const response = await deleteUser(user.id)

                    if (response.error) {
                      toast.error(response.message)
                      return ''
                    }
                  }}
                  className='btn btn-primary px-6'
                >
                  Delete
                </button>
              </div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Footer*/}
            {/*end::Form*/}
          </div>
        </Modal>
      )}

      <tr key={user.id}>
        <td>
          <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input widget-9-check'
              checked={checked()}
              onChange={(e) => {
                if (e.currentTarget.checked) {
                  addToSelected(user)
                } else {
                  removeFromSelected(user)
                }
              }}
              type='checkbox'
              value='1'
            />
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-35px p-2 me-5'>
              <img src={toAbsoluteUrl('/media/avatars/blank.jpg')} alt='logo user' />
            </div>

            <div className='d-flex justify-content-start flex-column'>
              <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                {user.firstName}
              </a>
              <span className='text-muted fw-bold text-muted d-block fs-7'>{user.lastName}</span>
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <div className='d-flex flex-stack mb-2'>
              <span className='text-muted me-2 fs-7 fw-bold'>{capitalize(user.role)}</span>
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <div className='d-flex flex-stack mb-2'>
              <span className='text-muted me-2 fs-7 fw-bold'>
                {anyToBoolean(user.from_easytrack) ? 'Yes' : 'No'}
              </span>
            </div>
          </div>
        </td>
        <td className='text-end'>
          <div className='d-flex flex-column w-100 me-2'>
            <div className='d-flex flex-stack mb-2'>
              <span className='text-muted me-2 fs-7 fw-bold'>{user.email}</span>
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <a
              onClick={() => {
                setCurrentUser(user)
                setShowCreateUserModal(true)
              }}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <KTSVG path='/media/icons/duotone/Communication/Write.svg' className='svg-icon-3' />
            </a>
            {user.role !== 'admin' && (
              <a
                onClick={async () => {
                  setDeleteModalVisible(true)
                }}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              >
                <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
              </a>
            )}
          </div>
        </td>
      </tr>
    </>
  )
}

const UsersTableComponent: React.FC<Props> = ({
  className,
  usersCount,
  pageCount,
  currentPage,
  resultsPerPage,
  setCurrentPage,
  setResultsPerPage,
  companyId
}) => {
  const {
    usersData,
    getCompanies,
    deleteUser,
    setCurrentUser,
    setSelectedUsers,
    selectedUsers,
    showCreateUserModal,
    setShowCreateUserModal,
    currentUser,
  } = useContext<any>(UsersContext)

  const removeFromSelected = (user: UserModel) => {
    const futureSelected = selectedUsers.filter((e: UserModel) => e.id !== user.id)
    setSelectedUsers(futureSelected)
  }

  const addToSelected = (user: UserModel) => {
    const futureSelected = [...selectedUsers, user]
    setSelectedUsers(futureSelected)
  }

  useEffect(() => {
    getCompanies()
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>All users</span>
          <span className='text-muted mt-1 fw-bold fs-7'>A total of {usersCount} users</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            onClick={() => {
              setCurrentUser(undefined)
              setShowCreateUserModal(true)
            }}
            className='btn btn-sm btn-light-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
          >
            <KTSVG path='media/icons/duotone/Navigation/Plus.svg' className='svg-icon-3' />
            New user
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          setSelectedUsers(usersData)
                        } else {
                          setSelectedUsers([])
                        }
                      }}
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>Full name</th>
                <th className='min-w-140px'>Role</th>
                <th className='min-w-120px'>From easytrack</th>
                <th className='min-w-120px'>Email</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {usersData.map((user: UserModel) => {
                return (
                  <UserRow
                    key={user.id}
                    checked={() => {
                      if (selectedUsers.some((e: UserModel) => e.id === user.id)) {
                        return true
                      }
                      return false
                    }}
                    user={user}
                    setCurrentUser={setCurrentUser}
                    addToSelected={addToSelected}
                    removeFromSelected={removeFromSelected}
                    setShowCreateUserModal={setShowCreateUserModal}
                    deleteUser={deleteUser}
                  />
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {
        <CreateUpdateUserModal
          mainCompanyId={companyId}
          user={currentUser}
          visible={showCreateUserModal}
          setVisible={setShowCreateUserModal}
        />
      }
      {
        <Pagination
          pageCount={pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          resultsPerPage={resultsPerPage}
          setResultsPerPage={setResultsPerPage}
        />
      }
    </div>
  )
}

export {UsersTableComponent}

import React, { memo, useCallback, useContext, useMemo, useRef, useState } from 'react'
// @ts-ignore
import { ControlledMenu, MenuItem, useMenuState } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import { KTSVG } from '../../../_metronic/helpers'
import { TransportsContext } from '../../../context/transports.context'
import CardHoverMenu from './CardHoverMenu'
import { toast } from 'react-toastify'
import AssignDriverModal from './components/modals/AssignDriverModal'
import { TemplatesContext } from '../../../context/templates.context'
import TransportCell from './TransportCell'
import { CellModeType } from '../../../types/general.types'
import easytrackAxios from '../../../setup/easytrack.axios'
import { EASYTRACK_API_URL, PUBLIC_EASYTRACK_URL } from '../../../constants/api.constants'
import { sendEmailRequestFromTransport } from '../../../setup/axios/transports.request'
import { getURLFormattedTemplateName } from '../../../utils/string.utils'
import { UserContext } from '../../../context/user.context'
import { statusListOfFinal } from '../../../utils/style.utils'
import CompleteTransportModal from './components/modals/CompleteTransportModal'

function openInNewTab(url: string) {
  // @ts-ignore
  window.open(url, '_blank').focus()
}

const updateTransportRequest = async ({
  transportId,
  businessProfileId,
  comment = '',
  driverId,
  requestStatus,
  vehicleId,
}: {
  transportId: number | string
  businessProfileId: number | string
  comment?: string
  driverId: number | string
  requestStatus: string
  vehicleId: number | string
}) => {
  const payload = { businessProfileId, comment, driverId, requestStatus, vehicleId }
  const result = await easytrackAxios
    .put(`${EASYTRACK_API_URL}/transport-requests/${transportId}`, { ...payload })
    .catch((err) => err)
  return result
}

const getGoogleMapsLinkForTransport = async (transportId: number, businessProfileId: number) => {
  const result = await easytrackAxios
    .get(
      `${EASYTRACK_API_URL}/business-profiles/${businessProfileId}/transport-requests/${transportId}/route/v2`
    )
    .catch((err) => err)
  return result
}

const extractServiceObject = (transport: any) => {
  const tariffServiceId = transport?.tariffServiceId || transport?.tariffService?.id
  const tariffServiceName = transport?.tariffServiceName || transport?.tariffService?.name
  return { id: tariffServiceId, name: tariffServiceName }
}

const buildAssignmentObject = ({ assignment, transport }: any) => {
  const tariffService = extractServiceObject(assignment)
  const fromDate = assignment.fromDate
  const toDate = assignment.toDate
  return { ...transport, tariffService, fromDate, toDate }
}

enum MODAL_TYPE {
  ASSIGN = 'assign', COMPLETE = 'complete', NONE = 'none'
}

const DisplayedModal: React.FC<{
  whichModalVisible: MODAL_TYPE
  setWhichModalVisible: Function
  transport: any 
}> = ({
  whichModalVisible,
  setWhichModalVisible, 
  transport
}) => {
  const closeModal = () => setWhichModalVisible(MODAL_TYPE.NONE)
  switch (whichModalVisible) {
    case MODAL_TYPE.ASSIGN:
      return (
        <AssignDriverModal
          modalVisible={true} //  the modal is only displayed when it needs to be visible, so this modal will not render for "modalVisible - false"
          setModalVisible={closeModal}
          transport={transport}
        />
      )
    case MODAL_TYPE.COMPLETE:
      return (
        <CompleteTransportModal
          modalVisible={true}
          setModalVisible={closeModal}
          transportId={transport?.id}
        />
      )
    case MODAL_TYPE.NONE:
      return null
  }
}

function TransportCellWrapper({
  transport,
  mode,
  driver,
  index,
}: {
  index: number
  transport: any
  mode: CellModeType
  driver: any
}) {
  const [menuProps, toggleMenu] = useMenuState({ transition: true })
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })
  const { defaultTemplate } = useContext<any>(TemplatesContext)
  const { businessProfileId } = useContext<any>(UserContext)
  const {
    checkTransport,
    dictionaries,
    transportToBeAssigned,
    setTransportToBeAssigned,
    setShiftsMap,
    getShiftsMap,
  } = useContext<any>(TransportsContext)

  const ref = useRef(null)
  const [cardProps, toggleCard] = useMenuState({ transition: true })
  const [whichModalVisible, setWhichModalVisible] = useState<MODAL_TYPE>(MODAL_TYPE.NONE)
  const [localTransport, setLocalTransport] = useState(transport)
  const [isLoading, setIsLoading] = useState(false)


  const handleCheck = async () => {
    const result = await checkTransport({ id: localTransport.id, checkType: 'CHECK' })
    if (!result.data) {
      toast.error('Error checking transport')
      return
    }
    const futureTransport = buildAssignmentObject({
      assignment: localTransport,
      transport: result.data,
    })

    setLocalTransport(futureTransport)
  }

  const handleComplete = () => {
    setWhichModalVisible(MODAL_TYPE.COMPLETE)
  }

  const handleAssign = () => {
    setWhichModalVisible(MODAL_TYPE.ASSIGN)
  }

  const handleUnassign = async () => {
    setIsLoading(true)
    const result = await updateTransportRequest({
      transportId: transport.id,
      businessProfileId,
      requestStatus: 'NEW',
      driverId: null,
      vehicleId: null,
    })

    setIsLoading(false)
    if (result?.response?.data?.error || result?.response?.status === 500) {
      // Error
      toast.error(result?.response?.data?.message || 'We have some problem with your last request!')
      return
    }

    const futureShiftsMap = await getShiftsMap()
    // set the transports with the fetched data
    setShiftsMap(futureShiftsMap)

    setIsLoading(false)

    // Success
    toast.success('Transport request was unassigned!')
  }

  /**
   * Redirect user to google maps
   */
  const handleMap = () => {
    let intermediaryPointsGps = ''

    localTransport.intermediaryPoints.forEach((point: any) => {
      intermediaryPointsGps += '/' + point.gps
    })

    openInNewTab(
      `https://www.google.com/maps/dir/${localTransport.originCoords}${intermediaryPointsGps}/${localTransport.destinationCoords}`
    )
  }

  const setTransportStatusForConfirm = async () => {
    const hasDriverAssigned = Boolean(transport.assignedDriver?.id)
    const statusForConfirm = hasDriverAssigned ? 'PENDING' : 'NEW'

    const result = await updateTransportRequest({
      transportId: transport.id,
      businessProfileId,
      requestStatus: statusForConfirm,
      driverId: transport.assignedDriver?.id,
      vehicleId: transport.assignedVehicle?.id,
    })

    if (result?.response?.data?.error || result?.response?.status === 500) {
      // Error
      toast.error(result?.response?.data?.message || 'We have some problem with your last request!')
      return
    }
    const futureTransport = buildAssignmentObject({ assignment: transport, transport: result.data })
    setLocalTransport(futureTransport)
  }

  const handleDelay = async () => {
    const result = await checkTransport({ id: localTransport.id, checkType: 'DELAY' })

    if (!result.data) {
      toast.error('Error delaying transport')
      return
    }

    const futureTransport = buildAssignmentObject({
      assignment: localTransport,
      transport: result.data,
    })
    setLocalTransport(futureTransport)
  }
  /**
   * Redirect user to local link
   */
  const handleView = () => {
    window.open(
      `/view-transport/${getURLFormattedTemplateName(defaultTemplate.name)}/${localTransport.id}`,
      '_blank'
    )
  }

  const handleTrackEvent = async () => {
    if (statusListOfFinal.includes(localTransport?.requestStatus)) {
      // Open in new tab easytrack on show history
      window.open(
        `${PUBLIC_EASYTRACK_URL}/maps/history.do?transportRequestId=${localTransport.id}`,
        '_blank'
      )
    } else {
      const linkRequestResult = await getGoogleMapsLinkForTransport(localTransport.id, businessProfileId)
      if (linkRequestResult?.status === 200) {
        window.open(linkRequestResult.data, '_blank')
      } else {
        toast.error(`Could not retrieve link! ${linkRequestResult?.response?.data?.message || ''}`)
      }
    }
  }

  const handleConfirmationEvent = async () => {
    sendEmailRequestFromTransport({
      transport: localTransport,
      additionalEffectForSuccess: setTransportStatusForConfirm,
    })
  }

  const toggleEventCard = (toggled: boolean) => {
    toggleCard(toggled)
  }

  const toggleEventMenu = (toggled: boolean) => {
    toggleMenu(toggled)
  }

  const onTransportCellClick = useCallback(
    () => setTransportToBeAssigned({ transport: localTransport, index }),
    [localTransport, index]
  )
  const onTransportCellContextMenu = useCallback((e: any) => {
    e.preventDefault()
    setAnchorPoint({ x: e.clientX, y: e.clientY })
    toggleEventMenu(true)
  }, [])
  const transportCellStyle = useMemo(
    () => ({
      border:
        localTransport.id === transportToBeAssigned?.transport?.id ? '2px solid yellow' : 'none',
      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
    }),
    [localTransport, transportToBeAssigned]
  )

  const enableEventCard = useCallback(() => toggleEventCard(true), [])
  const disableEventCard = useCallback(() => toggleEventCard(false), [])
  const disableEventMenu = useCallback(() => toggleEventMenu(false), [])

  return (
    <>
      <DisplayedModal 
        whichModalVisible={whichModalVisible}
        setWhichModalVisible={setWhichModalVisible}
        transport={localTransport}
      />
      <div
        ref={ref}
        onMouseEnter={enableEventCard}
        onMouseLeave={disableEventCard}
        onMouseMove={enableEventCard}
      >
        <TransportCell
          driver={driver}
          mode={mode}
          transport={localTransport}
          transferTypeList={dictionaries?.TRANSFER_TYPES || []}
          onClick={onTransportCellClick}
          onContextMenu={onTransportCellContextMenu}
          style={transportCellStyle}
          className='transport-item-content pt-1 pb-1 clickable'
        />
      </div>

      {/* Hover */}
      <CardHoverMenu
        transport={localTransport}
        offsetX={-16}
        portal={true}
        direction={'right'}
        {...cardProps}
        // viewScroll='auto'
        align='start'
        // overflow='auto'
        arrow={true}
        anchorRef={ref}
        onMouseLeave={disableEventCard}
        onClose={disableEventCard}
      />

      <ControlledMenu
        {...menuProps}
        portal={mode === 'unassigned'}
        anchorPoint={anchorPoint}
        onClose={disableEventMenu}
      >
        <MenuItem onClick={handleConfirmationEvent}>
          <KTSVG
            path='/media/icons/duotone/Communication/Urgent-mail.svg'
            className='svg-icon-2 svg-icon-gray-500'
          />
          Confirmation
        </MenuItem>
        <MenuItem onClick={handleCheck}>
          <KTSVG
            path='/media/icons/duotone/Navigation/Check.svg'
            className='svg-icon-2 svg-icon-gray-500'
          />
          Check
        </MenuItem>
        <MenuItem onClick={handleDelay}>
          <KTSVG
            path='/media/icons/duotone/Home/Clock.svg'
            className='svg-icon-2 svg-icon-gray-500'
          />
          Delay
        </MenuItem>
        <MenuItem onClick={handleComplete}>
          <KTSVG
            path='/media/icons/duotone/Home/Book-open.svg'
            className='svg-icon-2 svg-icon-gray-500'
          />
          Complete
        </MenuItem>
        <MenuItem disabled={!Boolean(localTransport?.assignedDriver)} onClick={handleTrackEvent}>
          <KTSVG
            path='/media/icons/duotone/Map/Compass.svg'
            className='svg-icon-2 svg-icon-gray-500'
          />
          Track
        </MenuItem>
        <MenuItem onClick={handleMap}>
          <KTSVG
            path='/media/icons/duotone/Map/Compass.svg'
            className='svg-icon-2 svg-icon-gray-500'
          />
          Map
        </MenuItem>
        <MenuItem onClick={handleView}>
          <KTSVG
            path='/media/icons/duotone/Interface/Edit.svg'
            className='svg-icon-2 svg-icon-gray-500'
          />
          View
        </MenuItem>
        <MenuItem onClick={handleAssign}>
          <KTSVG
            path='/media/icons/duotone/Communication/Add-User.svg'
            className='svg-icon-2 svg-icon-gray-500'
          />
          Assign
        </MenuItem>

        {mode === 'drivers' ? (
          <MenuItem onClick={handleUnassign}>
            <KTSVG
              path={
                isLoading
                  ? '/media/icons/duotone/Code/Loading.svg'
                  : '/media/icons/duotone/Communication/Delete-User.svg'
              }
              className={'svg-icon-2 svg-icon-gray-500' + (isLoading ? ' spin' : '')}
            />
            Unassign
          </MenuItem>
        ) : null}
      </ControlledMenu>
    </>
  )
}

export default memo(TransportCellWrapper)

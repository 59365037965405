export default class Semaphore {
  private requests: Array<() => void> = [];
  private activeCount: number = 0;
  private readonly maxConcurrent: number;

  constructor(maxConcurrent: number) {
    this.maxConcurrent = maxConcurrent;
  }

  private tryNext() {
    if (this.requests.length > 0 && this.activeCount < this.maxConcurrent) {
      const nextRequest = this.requests.shift();
      if (nextRequest) {
        this.activeCount++;
        nextRequest();
      }
    }
  }

  public async handleRequest<T>(request: () => Promise<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const preparedRequest = () => {
        request()
          .then(resolve)
          .catch(reject)
          .finally(() => {
            this.activeCount--;
            this.tryNext();
          });
      };

      this.requests.push(preparedRequest);
      this.tryNext();
    });
  }
}

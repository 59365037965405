import React, { FC, useContext, useEffect, useState } from 'react'
import {  useHistory, useParams } from 'react-router-dom'
import Loading from '../../../_metronic/layout/components/loading/Loading'
import { PageTitle } from '../../../_metronic/layout/core'
import PackageData, { isPackageValidForValabbility } from './components/PackageData.component'
import { PackageContext } from '../../../context/packages.context'
import { PackageType, emptyPackage } from '../../../types/company.types'
import HtmlEditor from '../../../components/html-editor/HtmlEditor.component'


const AddEditPackage: FC = () => {
  const history = useHistory()
  const { id, companyId } = useParams<any>()

  const [packageInfo, setPackageInfo] = useState<PackageType>()
  const [packageName, setPackageName] = useState<string>('');
  const { getPackage, isLoading, setIsLoading, createAndUpdatePackage } = useContext<any>(PackageContext)

  const canSave = packageInfo?.name !== '' &&
    packageInfo?.price &&
    packageInfo?.valability_period &&
    packageInfo?.valability_unit &&
    isPackageValidForValabbility(packageInfo)

  const getAndSetPackageInfo = async () => {
    setIsLoading(true)
    if (id) {
      const response = await getPackage({ id: id })
      if (!response?.error && response?.package) {
        setPackageInfo(response?.package)
        setPackageName(response?.package.name)
      }
    }
    else {
      setPackageInfo({...emptyPackage, company_id:companyId})
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getAndSetPackageInfo()
  }, [])

  const renderPackagePageContent = () =>{
    return <>
      <PackageData companyPackage={packageInfo} setCompanyPackage={setPackageInfo} />
      <div
        // @ts-ignore
        className={`card draggable has-card-options p-10 align-center`}
        style={{ marginTop: 16, marginBottom: 16 }}
      >
        <h1 className='py-5  d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
          Descrierea serviciilor aferente pachetului:
        </h1>
        <HtmlEditor company_id={packageInfo.company_id} value={packageInfo.package_details} setValue={(value)=>{
          setPackageInfo(prevState => ({
            ...prevState,
            package_details: value.replaceAll("<br>", "")
          }))
        }}/>
      </div>
    </> 
  }

  return (
    <div>
      {/* Header */}
      <PageTitle>
        {id ? `Editare pachet ${packageName}` : 'Adauga pachet'}
      </PageTitle>
      <div
        className='page__top-buttons'
        style={{
          position: 'fixed',
          display: 'flex',
          justifyContent: 'end',
          zIndex: 1000,
          right: '90px',
          top: '12px',
        }}
      >
        <button
          className='btn btn-light w-90px'
          style={{ background: 'none' }}
          onClick={(e) => {
            e.preventDefault()

            history.goBack()
          }}
          disabled={isLoading}
          type='button'
        >
          Cancel
        </button>
        <button
          className='btn btn-primary w-90px'
          onClick={async (e) => {
            setIsLoading(true)
            e.preventDefault()
            let payload = { ...packageInfo };
            if (id) {
              payload.id = id
            }
            const response = await createAndUpdatePackage({ payload: payload })
            if (!response?.error) {
              history.goBack()
            }
            setIsLoading(false)
          }}
          disabled={isLoading || !canSave}
          type='submit'
        >
          {isLoading ? (
            <span
              style={{ width: '1.3rem', height: '1.3rem' }}
              className='spinner-border spinner-border-sm align-middle'
            />
          ) : (
            <span>Save</span>
          )}
        </button>
      </div>

      {/* Finish Header */}
      {packageInfo ? renderPackagePageContent() : <Loading />}

    </div>
  )
}

export default AddEditPackage

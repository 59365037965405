/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from "react";
import { DeleteModal } from "../../../../components/delete-modal/DeleteModal.component";
import { useHistory } from "react-router-dom";
import { KTSVG } from "../../../../_metronic/helpers";
import { PackageContext } from "../../../../context/packages.context";
import { PackageType, PeriodUnit } from "../../../../types/company.types";
import { processUnitLabel, valabilitySelect } from "../../add-edit-package/components/PackageData.component";

const getValabilityText = (period: number, unit: PeriodUnit) => {
  const valability = (valabilitySelect.options || []).find((option) => option.unit === unit && option.value === period)
  if (!valability) {
    return `${period} ${processUnitLabel(unit)}`
  }
  return valability.label
}

const PackageRow = ({
  companyPackage,
  deletePackage,
}: {
  companyPackage: PackageType,
  deletePackage: any
}) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
  const { previewContract } = useContext<any>(PackageContext)
  const history = useHistory();

  return (
    <>
      <DeleteModal
        deleteModalVisible={deleteModalVisible}
        setDeleteModalVisible={setDeleteModalVisible}
        deleteMethod={() => { deletePackage({ id: companyPackage.id, companyId: companyPackage.company_id }); setDeleteModalVisible(false); }}
        textModal={`The package ${companyPackage.name}, will be deleted forever!`}
      />
      <tr key={companyPackage.id}>
        <td>
          <div className='d-flex align-items-center'>
            {/* <div className='symbol symbol-35px p-2 me-5'>
              <img src={toAbsoluteUrl('/media/avatars/blank.jpg')} alt='logo user' />
            </div> */}

            <div className='d-flex align-items-center'>
              <span className='text-dark fs-6'>{companyPackage?.name}</span>
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <span className='text-dark fs-6'>{companyPackage.description}</span>
          </div>
        </td>
        <td className='text-end'>
          <div className='d-flex align-items-center'>
            <span className='text-dark fs-6'>{companyPackage.price}</span>
          </div>
        </td>
        <td className='text-end'>
          <div className='d-flex align-items-center'>
            <span className='text-dark fs-6'>{companyPackage.currency || 'RON'}</span>
          </div>
        </td>
        <td className='text-end'>
          <div className='d-flex align-items-center'>
            <span className='text-dark fs-6'>{companyPackage.company?.name}</span>
          </div>
        </td>
        <td className='text-end'>
          <div className='d-flex align-items-center'>
            <span className='text-dark fs-6'>{getValabilityText(companyPackage.valability_period, companyPackage.valability_unit)}</span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <button
              data-bs-toggle='tooltip'
              data-bs-boundary='window'
              data-bs-placement='top'
              title='Preview'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                previewContract({
                  payload: {
                    company_id: companyPackage.company_id, package_id: companyPackage.id, subscription_description: companyPackage.package_details
                  },
                  fileNameData: {
                    packageName: companyPackage.name,
                    type: "package"
                  }
                })
              }}
            >
              <KTSVG
                path={'/media/icons/duotone/Files/Download.svg'}
                className={'svg-icon-1'}
              />
            </button>
            <a
              onClick={() => {
                history.push(`/add-edit-package/${companyPackage.company_id}/${companyPackage.id}`)
              }}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <KTSVG path='/media/icons/duotone/Communication/Write.svg' className='svg-icon-3' />
            </a>
            <a
              onClick={async () => {
                setDeleteModalVisible(true)
              }}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            >
              <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
            </a>
          </div>
        </td>
      </tr>
    </>
  )
}


export default PackageRow;
export enum FuelType {
  Diesel = 'Diesel',
  Hybrid = 'Hybrid',
  Gasoline = 'Gasoline',
  Electric = 'Electric'
}

export const fuelTypeList = Object.values(FuelType).map((fuelType: string) => ({
  name: fuelType,
}))

export enum TransmissionType {
  Automatic = 'Automatic',
  Manual = 'Manual',
}

export const transmissionTypeList = Object.values(TransmissionType).map(
  (transmissionType: string) => ({
    name: transmissionType,
  })
)

export type CarClassModel = {
  id: number
  name: string
  vehicle_type: string
  description: string
  image_url?: string
  number_of_doors: number
  luggage_capacity: number
  fuel_type: string[] | null
  transmission_type: string[] | null
}

export const emptyCarClass = {
  name: '',
  vehicle_type: '',
  description: '',
  image_url: '',
  number_of_doors: 0,
  luggage_capacity: 0,
  fuel_type: null,
  transmission_type: null,
}

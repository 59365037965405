import { useState } from 'react';
import { TransportPhotoTempType, TransportPvTempType } from '../../../../types/document.types'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import Loading from '../../../../_metronic/layout/components/loading/Loading'


const PhotoFrame = ({ style = {}, children }: { style?: any; children: any }) => {
  return (
    <div
      className='col-4 m-1'
      style={{
        width: '100px',
        height: '120px',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '10px',
        border: '1px solid #E4E6EF',
        ...style,
      }}
    >
      {children}
    </div>
  )
}

const PhotoCard = ({ photo, handleShowViewImgModal }: { photo: TransportPhotoTempType, handleShowViewImgModal: Function }) => {
  return (
    <PhotoFrame key={`img-${photo.type}-${photo.id || photo.tempKey}`}>
      <img
        alt={`${photo.type}`}
        style={{ objectFit: 'cover' }}
        className='w-100 h-100 clickable-grow-fade-out'
        src={`data:image/jpeg;base64,${photo.content}`}
        onClick={() => handleShowViewImgModal(photo)}
      />
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          opacity: '0.9',
          bottom: 0,
        }}
        className='bg-primary text-white'
      >
        <span>{photo.type}</span>
      </div>
    </PhotoFrame>
  )
}

const PvCard = ({ pv, index, handleShowViewPvModal }: { pv: TransportPvTempType, index: number, handleShowViewPvModal: Function }) => {

  return (
    <PhotoFrame key={`img-${pv.pvType}-${pv.id}`}>
      <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
        <div className='pdfContainer clickable-grow-fade-out' onClick={() => handleShowViewPvModal(pv)}>
          <p style={{ pointerEvents: 'none', userSelect: 'none' }}>{`pv ${index + 1}`}</p>
          <img alt={`pv ${index + 1}`} src={toAbsoluteUrl('/media/svg/files/pdf.svg')} />
        </div>
      </div>

      <div
        style={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          opacity: '0.9',
          bottom: 0,
        }}
        className='bg-primary text-white'
      >
        <span>{pv.pvType}</span>
      </div>
    </PhotoFrame>
  )
}

const TransportDocument = ({
  photosTempObjectList,
  setModalVisible,
  photoTypesLoaded,
  processingFiles,
  handleShowViewImgModal,
  handleShowViewPvModal,
  type,
  pvsTempObjectList,
}: {
  photosTempObjectList?: TransportPhotoTempType[]
  setModalVisible: Function
  photoTypesLoaded: boolean
  processingFiles: boolean
  handleShowViewImgModal: Function
  handleShowViewPvModal: Function
  type: string,
  pvsTempObjectList?: TransportPvTempType[]
}) => {

  const renderDocument = () => {
    if (type === 'photos') {
      return (photosTempObjectList || []).map((photo: TransportPhotoTempType) => (
        <PhotoCard key={`img-${photo.type}-${photo.id || photo.tempKey}`} photo={photo} handleShowViewImgModal={handleShowViewImgModal} />
      ))
    }
    return (pvsTempObjectList || []).map((pv: TransportPvTempType, index: number) => (
      <PvCard index={index} key={`img-${pv.pvType}-${pv.id || pv.tempKey}`} pv={pv} handleShowViewPvModal={handleShowViewPvModal} />
    ))
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <PhotoFrame>
        <button
          type='button'
          className='btn btn-light-primary btn-text-primary w-100 h-100 p-0'
          disabled={!photoTypesLoaded || processingFiles}
          onClick={() => setModalVisible(true)}
        >
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <KTSVG path='/media/icons/duotone/Navigation/Plus.svg' className='svg-icon-1 plusIcon' />
            {type === 'pvs' ? 'Add Pv' : 'Add Photo'}
          </div>
        </button>
      </PhotoFrame>

      {processingFiles ? (
        <PhotoFrame style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Loading size={100} />
        </PhotoFrame>
      ) : renderDocument()}
    </div>
  )
}
export default TransportDocument;
import chroma from 'chroma-js'
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import '../../app/modules/autonom.scss'
import { PageTitle } from '../../_metronic/layout/core'
import { getDefaultBillingDate, getPlan, savePlan, updatePlan } from '../tariff-grid/modules/PlanRequests'
import { UserContext } from '../../context/user.context'
import moment from 'moment'
import { updateField } from '../../utils/state.utils'
import { generateField } from '../../components/field/field-generator'
import { LoadingCard } from '../../_metronic/layout/components/loading/Loading'
import TariffsTable from './modules/TariffsTable'
import { GeofenceType } from '../../types/general.types'
import { getGeofenceListRequest } from '../../setup/axios/geofence.request'
import BookingTaxesTable from './modules/BookingTaxesTable'
import PickupTimeTable from './modules/PickupTimeTable'
import WaitingTimeTable from './modules/WaitingTimeTable'
import PickupLocationTable from './modules/PickupLocationTable'
import StopExtrasTable from './modules/StopExtrasTable'
import IntercityCostsTable from './modules/IntercityCostsTable'
import PlanProvider, { usePlanContext } from '../../context/plan.context'
import {
  TariffPlanType,
  TariffServiceType,
  pickupTimeBasedExtrasType,
  TariffType,
  waitingTimeExtrasType,
  pickupLocationBasedTaxesType,
  intermediaryStopExtrasType,
  intercityKmCostsType,
  currencySourceList,
} from './modules/PlanTypes'
import { disableMaxWidth, enableMaxWidth } from '../../utils/style.utils'
import { colourStyles } from '../../utils/select.styles.utils'
import { AppContext } from '../../context/app.context'
import { Card } from '../../app/modules/add-edit-transport/scripts/structuralFields'
import { toast } from 'react-toastify'
import { formatNumber } from '../../utils/string.utils'
import { validateFields } from '../../utils/file.utils'

const Field: FC<{
  name: string
  style?: any
  generatePlanField: any
  field: any
  plan: any
}> = memo(
  ({ name, style, generatePlanField, field }) => {
    return (
      <div key={name} className={`${name}-card-field pb-7 card-field`} style={style}>
        {field && generatePlanField(field)}
      </div>
    )
  },
  (prev, next) => prev.plan[next.name] === next.plan[next.name]
)

const tabs = [
  'Tariffs',
  'Booking Taxes',
  'Pickup Time',
  'Waiting Time',
  'Pickup Location',
  'Stop Extras',
  'Intercity Costs',
]

const fields = [
  { name: 'name', label: 'Name', type: 'input', inputType: 'text', required: true },
  { name: 'customer', label: 'Customer', type: 'select', styles: colourStyles, required: true },
  { name: 'from', label: 'From', type: 'date', required: true },
  { name: 'currency', label: 'Currency', type: 'select', required: true },
  { name: 'autoBillingEnabled', label: 'Auto Billing', type: 'checkbox', checkboxType: 'column' },
  { name: 'autoBillingDate', label: 'Billing Date', type: 'input', inputType: 'number' },
  { name: 'currencySource', label: 'Currency Source', type: 'select', required: true, hidden: false },
]

const emptyPricingPlan = {
  id: null,
  name: '',
  customer: null,
  from: moment.utc().format('YYYY-MM-DD').toString(),
  autoBillingEnabled: false,
  autoBillingDate: '',
  currency: null,
  tariffs: [],
  bookingManagementTaxes: [],
  pickupTimeBasedExtras: [],
  waitingTimeExtras: [],
  pickupLocationBasedTaxes: [],
  intermediaryStopExtras: [],
  intercityKmCosts: [],
  createdAt: null,
  createdBy: null,
  updatedAt: null,
  updatedBy: null,
}

export const displayGeofence = (geofence: GeofenceType, dictionaries: any) => {
  if (!Boolean(geofence) || !Boolean(geofence.id)) return 'ALL'
  let geofenceType = dictionaries.PRICING_GEOFENCE_TYPES.find(
    (item: any) => item.name === geofence.type
  )?.title
  return geofence.name + ' (' + geofenceType + ')'
}

const AddEditPlanPage: FC = () => {
  const { id, companyId } = useParams<any>()
  const { businessProfileId } = useContext<any>(UserContext)
  const { getPromiseByKey } = useContext<any>(AppContext)
  const history = useHistory()

  const {
    plan,
    setPlan,
    companies,
    setCompanies,
    geofences,
    setGeofences,
    dictionaries,
    setDictionaries,
    tariffServices,
    setTariffServices,
    pricingPlanTemplate,
  } = usePlanContext()

  const [hadLoaded, setHadLoaded] = useState<boolean>(false)

  const updatePlanField = (value: any, fieldName: string) => {
    updateField({ value: value, fieldName: fieldName, setObject: setPlan })
  }
  useEffect(() => {
    ; (async () => {
      if (Boolean(plan)) {
        return
      }

      const defaultBillingDatePayload = await getDefaultBillingDate({ businessProfileId })
      const defaultBillingDate = defaultBillingDatePayload.billingDate || ''
      if (id != null) {
        const foundPlan = await getPlan({ businessProfileId, companyId, planId: id })
        if (!foundPlan.autoBillingDate || isNaN(Number(foundPlan.autoBillingDate))) {
          foundPlan.autoBillingDate = defaultBillingDate
        }
        setPlan(foundPlan)
      } else {
        setPlan({ ...emptyPricingPlan, autoBillingDate: defaultBillingDate })
      }
    })()
  }, [pricingPlanTemplate])

  const getPreparedTariffs = ({ tariffs }: { tariffs: TariffType[] }): TariffType[] => {
    return (tariffs || []).map((tariff: TariffType) => {
      let startGeofence = null
      if (tariff.startGeofence) {
        // might be NULL/ALL
        startGeofence = geofences.find(
          (geofence: GeofenceType) => geofence.id == tariff?.startGeofence?.id
        )
        if (startGeofence) {
          startGeofence.polygon = null
        }
      }
      let endGeofence = null
      if (tariff.endGeofence) {
        // might be NULL/ALL
        endGeofence = geofences.find(
          (geofence: GeofenceType) => geofence.id == tariff?.endGeofence?.id
        )
        if (endGeofence) {
          endGeofence.polygon = null
        }
      }

      let service = tariffServices.find(
        (tariffService: TariffServiceType) => tariffService.id == tariff?.service?.id
      )

      return { ...tariff, startGeofence: startGeofence, endGeofence: endGeofence, service: service }
    })
  }

  const getPreparedPickupTimeExtras = ({
    pickupTimeExtras,
  }: {
    pickupTimeExtras: pickupTimeBasedExtrasType[]
  }): pickupTimeBasedExtrasType[] => {
    return (pickupTimeExtras || []).map((timeExtra: pickupTimeBasedExtrasType) => {
      let services = (timeExtra?.tariffServices || []).map((service: TariffServiceType) => {
        return tariffServices.find((tariffService: TariffServiceType) => {
          return tariffService.id == service.id
        })
      })

      return { ...timeExtra, tariffServices: services }
    })
  }

  const getPreparedWaitingTimeExtras = ({
    waitingTimeExtras,
  }: {
    waitingTimeExtras: waitingTimeExtrasType[]
  }): waitingTimeExtrasType[] => {
    return (waitingTimeExtras || []).map((timeExtra: waitingTimeExtrasType) => {
      let services = (timeExtra?.tariffServices || []).map((service: TariffServiceType) => {
        return tariffServices.find(
          (tariffService: TariffServiceType) => tariffService.id == service.id
        )
      })

      return { ...timeExtra, tariffServices: services }
    })
  }

  const getPreparedPickupLocationTaxes = ({
    pickupLocationTaxes,
  }: {
    pickupLocationTaxes: pickupLocationBasedTaxesType[]
  }): pickupLocationBasedTaxesType[] => {
    return (pickupLocationTaxes || []).map((pickupTax: pickupLocationBasedTaxesType) => {
      let startGeofences = (pickupTax?.startGeofences || []).map((startGeofence: GeofenceType) => {
        return geofences.find((geofence: GeofenceType) => startGeofence.id == geofence.id)
      })
      let services = (pickupTax?.tariffServices || []).map((service: TariffServiceType) => {
        return tariffServices.find(
          (tariffService: TariffServiceType) => tariffService.id == service.id
        )
      })
      return { ...pickupTax, startGeofences: startGeofences, tariffServices: services }
    })
  }

  const getPreparedStopExtras = ({
    stopExtras,
  }: {
    stopExtras: intermediaryStopExtrasType[]
  }): intermediaryStopExtrasType[] => {
    return (stopExtras || []).map((stopExtra: intermediaryStopExtrasType) => {
      let services = (stopExtra?.tariffServices || []).map((service: TariffServiceType) => {
        return tariffServices.find((tariffService: TariffServiceType) => {
          return tariffService.id == service.id
        })
      })

      return { ...stopExtra, tariffServices: services }
    })
  }

  const getPreparedintercityKmCosts = ({
    intercityKmCosts,
  }: {
    intercityKmCosts: intercityKmCostsType[]
  }): intercityKmCostsType[] => {
    return (intercityKmCosts || []).map((intercityKmCost: intercityKmCostsType) => {
      let services = (intercityKmCost?.tariffServices || []).map((service: TariffServiceType) => {
        return tariffServices.find(
          (tariffService: TariffServiceType) => tariffService.id == service.id
        )
      })
      return { ...intercityKmCost, tariffServices: services }
    })
  }

  /**
   * Converts a pricing plan object received from a request into an object that can be used
   * for further operations inside this component
   * @param pricingPlan the pricing plan to be converted
   * @returns valid pricing plan to be edited in this component
   */
  const getPreparedPricingPlan = ({ pricingPlan }: { pricingPlan: TariffPlanType }) => {
    // tariff
    let futurePricingPlan = { ...pricingPlan }
    futurePricingPlan.tariffs = getPreparedTariffs({ tariffs: futurePricingPlan?.tariffs || [] })
    futurePricingPlan.pickupTimeBasedExtras = getPreparedPickupTimeExtras({
      pickupTimeExtras: futurePricingPlan?.pickupTimeBasedExtras || [],
    })
    futurePricingPlan.waitingTimeExtras = getPreparedWaitingTimeExtras({
      waitingTimeExtras: futurePricingPlan?.waitingTimeExtras || [],
    })
    futurePricingPlan.pickupLocationBasedTaxes = getPreparedPickupLocationTaxes({
      pickupLocationTaxes: futurePricingPlan?.pickupLocationBasedTaxes || [],
    })
    futurePricingPlan.intermediaryStopExtras = getPreparedStopExtras({
      stopExtras: futurePricingPlan?.intermediaryStopExtras || [],
    })
    futurePricingPlan.intercityKmCosts = getPreparedintercityKmCosts({
      intercityKmCosts: futurePricingPlan?.intercityKmCosts || [],
    })
    futurePricingPlan.name = ''
    return futurePricingPlan
  }

  useEffect(() => {
    Promise.allSettled([
      (async () => {
        const result = await getPromiseByKey('companies', { businessProfileId })
        if (result?.data) {
          setCompanies(result.data)
        }
      })(),
      (async () => {
        const result = await getPromiseByKey('dictionaries', { businessProfileId })
        if (result?.data) {
          setDictionaries(result.data)
        }
      })(),
      (async () => {
        const result = await getPromiseByKey('tariffServices', { businessProfileId })
        if (Boolean(result?.data)) {
          setTariffServices(result.data)
        }
      })(),
      (async () => {
        const result = await getGeofenceListRequest({
          businessProfile: businessProfileId,
        })
        if (result.data) {
          setGeofences(result.data)
        }
      })(),
      (async () => {
        if (pricingPlanTemplate) {
          return
        }
      })(),
    ]).then(() => setHadLoaded(true))
  }, [])

  const fieldToProperty = (field: any) => {
    let fieldName = field.name

    const fieldsToPanelPropertiesMapping = {
      name: plan?.name || '',
      customer: {
        simpleValue: plan?.customer || '',
        options: companies || [],
        getOptionValue: (e: any) => ({ id: e.id, name: e.name, client: e.client } || ''),
        getOptionLabel: (e: any) => e.name || '',
        onChange: (e: any) => updatePlanField(e, 'customer'),
      },
      from: plan?.from || '',
      currency: {
        simpleValue: plan?.currency,
        options: dictionaries.CURRENCIES || [],
        getOptionValue: (e: any) => e.name || '',
        getOptionLabel: (e: any) => e.title || '',
        onChange: (e: any) => {
          updatePlanField(e, 'currency')
        },
      },
      autoBillingEnabled: plan?.autoBillingEnabled || false,
      autoBillingDate: {
        value: plan?.autoBillingDate + '' || '',
        onBlur: (value: string) => formatNumber({ paramValue: value, minValue: 0, maxValue: 31 })
      },
      currencySource: {
        simpleValue: plan?.currencySource || '',
        options: currencySourceList,
        getOptionLabel: (e: { name: string }) => e?.name || '',
        getOptionValue: (e: { name: string }) => e?.name || '',
        onChange: (value: string) => {
          updatePlanField(value, 'currencySource')
        },
      }
    }
    return (fieldsToPanelPropertiesMapping as any)[fieldName]
  }

  const generatePlanField = (field: any) => {
    const fieldName = field?.name
    const fieldProperty = fieldToProperty(field)
    return generateField({ field, fieldName, fieldProperty, setObject: setPlan })
  }

  const [chosenTab, setChosenTab] = useState<string>(tabs[0])

  return hadLoaded && Boolean(plan) ? (
    <>
      <div key='container' className='col-12 pb-4'>
        <div
          className='page__top-buttons'
          style={{
            position: 'fixed',
            zIndex: 1000,
            right: '90px',
            top: '12px',
          }}
        >
          <button
            className='btn btn-primary item-right'
            type='submit'
            onClick={async () => {
              let newPlan = { ...plan }
              newPlan.from = moment(plan.from).toDate()
              const autoBillingDate = newPlan.autoBillingDate ? Number(newPlan.autoBillingDate) : 0
              const autoBillingNotValid = !autoBillingDate || isNaN(autoBillingDate)
              if (newPlan.autoBillingEnabled && autoBillingNotValid) {
                toast.error("Please provide a valid auto billing date!")
                return;
              }
              const isValid = validateFields(fields, newPlan)
              if (!isValid) {
                return;
              }
              let payload = {
                businessProfileId: businessProfileId,
                companyId: Boolean(companyId) ? companyId : plan.customer?.id,
                plan: newPlan,
              }
              let action = Boolean(plan.id) ? updatePlan : savePlan
              let saved = await action(payload)
              if (saved) history.push('../../tariff-grid/')
            }}
          >
            Save
          </button>
        </div>
        <Card name='Pricing Plan'>
          {fields.map((field: any) => (
            <Field
              key={'field-' + field.name}
              name={field.name}
              plan={plan}
              field={field}
              generatePlanField={generatePlanField}
            />
          ))}
        </Card>
      </div>
      <div
        style={{
          padding: '10px',
          borderRadius: '10px',
          backgroundColor: 'white',
          display: 'flex',
        }}
        className='my-3'
      >
        {tabs.map((tab: string) => {
          return (
            <span
              key={tab}
              onClick={() => {
                setChosenTab(tab)
              }}
              style={{
                color: chosenTab === tab ? '#009ef7' : 'black',
                backgroundColor: chosenTab === tab ? '#f3f6f8' : '',
                padding: '10px',
                margin: '5px',
                borderRadius: '10px',
                fontSize: '15px',
                fontWeight: '500',
                cursor: 'pointer',
              }}
            >
              {tab}
            </span>
          )
        })}
      </div>
      {chosenTab === tabs[0] ? <TariffsTable /> : null}
      {chosenTab === tabs[1] ? <BookingTaxesTable /> : null}
      {chosenTab === tabs[2] ? <PickupTimeTable /> : null}
      {chosenTab === tabs[3] ? <WaitingTimeTable /> : null}
      {chosenTab === tabs[4] ? <PickupLocationTable /> : null}
      {chosenTab === tabs[5] ? <StopExtrasTable /> : null}
      {chosenTab === tabs[6] ? <IntercityCostsTable /> : null}
    </>
  ) : (
    <LoadingCard />
  )
}

const AddEditPlanWrapper: FC = () => {
  useEffect(() => {
    disableMaxWidth()

    return () => {
      enableMaxWidth()
    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>Add/Edit Tariff</PageTitle>
      <PlanProvider>
        <AddEditPlanPage />
      </PlanProvider>
    </>
  )
}

export default AddEditPlanWrapper

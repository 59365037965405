import React from 'react'
import { generateField } from '../../../components/field/field-generator'
import customAxios from '../../../setup/custom.axios'
import { API_URL } from '../../../constants/api.constants'
import { getLatitudeAndLongitudeFromPlaceId } from '../../../utils/geolocation.utils'
import { updateField } from '../../../utils/state.utils'
import GeolocationSelect from '../../../_metronic/layout/components/geolocation-select/GeolocationSelect'
import { toast } from 'react-toastify'
import { orbitRequest, RequestMethod } from '../../../utils/api.utils'
import moment from 'moment'
import { FieldType } from '../../../utils/field.utils'

export const checkSubscriptionsVin = async (vin: string, activatedAd: Date, expirationDate: Date, id: number, package_id: number, registration_nr: string) => {
  if (activatedAd && expirationDate) {
    const response = await orbitRequest({
      route: `/subscription/getActiveSubscription?vin=${vin}&activated_at=${moment(activatedAd).format('DD-MM-YYYY')}&expiration_date=${moment(expirationDate).format('DD-MM-YYYY')}&company_id=${id}&package_id=${package_id}&registration_nr=${registration_nr}`,
      method: RequestMethod.GET
    })
    if (response?.found) {
      let message = `Pentru vehiculul cu seria vin ${vin} și numărul de înmatriculare ${registration_nr} ${response.subscriptions?.length > 1 ? 'au fost identificate ca active următoarele polițe' : 'a fost identificată ca activă următoarea poliță'}`
      response.subscriptions?.forEach((subs) => {
        message = `${message}\n${subs.package_name}: ${moment(subs.activation_date).format('DD.MM.YYYY')} - ${moment(subs.expiration_date).format('DD.MM.YYYY')}`
      })
      return {
        error: true,
        message: message
      }
    }
  }
  return { error: false }
}

type SubscriptionTemplateType = {
  name: string,
  fields: {
    client: FieldType[],
    vehicle: FieldType[],
    subscription: FieldType[]
  }
}

export const defaultSubscriptionTemplate: SubscriptionTemplateType = {
  name: 'Default Subscription Template',
  fields: {
    client: [
      { name: 'type', label: 'Tip persoană', type: 'select', required: true, hidden: false },
      {
        name: 'insurance_for',
        label: 'Nume companie',
        type: 'input',
        inputType: 'text',
        required: true,
        hidden: false,
      },
      {
        name: 'name',
        label: 'Nume și prenume',
        type: 'input',
        required: true,
        inputType: 'text',
        hidden: false,
      },
      {
        name: 'email',
        label: 'Email',
        type: 'input',
        inputType: 'text',
        hidden: false,
        required: true,
      },
      {
        name: 'phone',
        label: 'Telefon',
        type: 'input',
        inputType: 'text',
        hidden: false,
        required: true,
      },
      {
        name: 'address',
        label: 'Adresa',
        type: 'geolocation',
        hidden: false,
        required: true,
        coordsKey: 'locationGPS',
        style: {
          flex: 1,
          minWidth: '50%',
        },
      },
      {
        name: 'identity_code',
        label: 'CUI',
        type: 'input',
        inputType: 'text',
        required: false,
        hidden: true,
      },
      {
        name: 'locationGPS',
        label: 'A valid address',
        type: 'hiddenInput',
        required: true,
        hidden: true,
      },
    ],
    vehicle: [
      {
        name: 'red_number',
        label: 'Număr rosu',
        hidden: false,
        type: 'checkbox',
        required: false,
        checkboxType: 'column',
      },
      {
        name: 'registration_nr',
        label: 'Număr de înmatriculare',
        hidden: false,
        type: 'input',
        required: true,
        inputType: 'text',
        regex: /^[a-zA-Z]{1,2}([0-9]{2,3})([a-zA-Z]{3})$/,
        regexConditions: [{
          section: 'vehicle',
          field: 'red_number',
          value: false
        }],
        errorMessage: 'Numărul de înmatriculare nu este valid',
      },
      {
        name: 'vin',
        label: 'VIN',
        hidden: false,
        type: 'input',
        required: false,
        inputType: 'text',
        regex: /^[a-zA-Z0-9]{17}$/,
        errorMessage: 'Seria VIN trebuie să aibă 17 caractere.',
      },
      {
        name: 'brand',
        label: 'Marca',
        hidden: false,
        type: 'input',
        required: true,
        inputType: 'text',
      },
      {
        name: 'model',
        label: 'Model',
        hidden: false,
        type: 'select',
        required: true,
      },
      {
        name: 'category',
        label: 'Categorie auto',
        hidden: false,
        type: 'select',
        required: true,
      },
      {
        name: 'registration_date',
        label: 'Data primei înmatriculări',
        required: true,
        hidden: false,
        type: 'date',
        noDatePlugins: true,
        dateFormat: 'YYYY-MM-DD',
      },
      {
        name: 'weight',
        label: 'Masa proprie (kg)',
        required: false,
        hidden: false,
        type: 'integerInput',
        min: 0,
      },
      { name: 'vehicle_age', type: 'hiddenInput', required: false, hidden: true },
      { name: 'company_id', type: 'hiddenInput', required: true, hidden: true },
    ],
    subscription: [
      { name: 'payment_type', label: 'Metodă de plată', type: 'select', required: true, hidden: false },
      { name: 'package_id', label: 'Tip poliță', type: 'select', required: true, hidden: false },
      { name: 'interface_type', label: 'Interface type', type: 'hiddenInput', required: false, hidden: true },      {
        name: 'bought_at',
        label: 'Data achiziției',
        type: 'date',
        dateFormat: 'YYYY-MM-DD',
        noDatePlugins: true,
        showOtherDays: true,
        required: true,
        hidden: false,
      },
      {
        name: 'activated_at',
        label: 'Data activării',
        type: 'date',
        dateFormat: 'YYYY-MM-DD',
        noDatePlugins: true,
        showOtherDays: true,
        required: true,
        hidden: false,
      },
      {
        name: 'expiration_date',
        label: 'Data expirării',
        type: 'date',
        dateFormat: 'YYYY-MM-DD',
        noDatePlugins: true,
        showOtherDays: true,
        required: true,
        hidden: false,
      },

      { name: 'status', label: 'Status', type: 'select', required: true, hidden: false },
      { name: 'discount', label: 'Discount', type: 'input', inputType: 'number', required: false, hidden: false },
      { name: 'price_paid', label: 'Price paid', type: 'input', inputType: 'number', required: false, hidden: false },

      { name: 'company_id', label: 'Company id', type: 'hiddenInput', required: true, hidden: true },
      { name: 'stripe_status', label: 'Stripe Status', type: 'input', inputType: 'text', required: false, hidden: false, disabled: true },
      { name: 'stripe_refund', label: 'Rambursare Stripe', type: 'refundButton', required: false, hidden: false },
      { name: 'subscription_type', label: 'Tip subscripție', type: 'select', required: false, hidden: false },
      { name: 'newsletter', label: 'Newsletter', type: 'checkbox', checkboxType: 'column', required: false, hidden: false },
    ],
  },
}

export const Field = ({
  field,
  fieldName,
  fieldProperty,
  style = {},
  isHidden,
  setObject,
}: {
  field: FieldType
  fieldName: string
  fieldProperty: any
  style?: any
  isHidden?: boolean
  setObject: Function
}) => {
  if (field?.hidden) {
    return null;
  }
  const updateObjectField = (value: any, fieldName: string) =>
    updateField({ value: value, fieldName: fieldName, setObject: setObject })

  const updateFields = (fieldValuePairs: any) => {
    setObject((oldObject: any) => {
      return { ...oldObject, ...fieldValuePairs }
    })
  }
  if (field?.type === 'geolocation') {
    return (
      <div
        key={`generatedField-${fieldName}`}
        className={`${fieldName}-card-field pb-7 card-field`}
        style={style}
      >
        <label key={`label${field?.name}`} htmlFor={field?.name} className='form-label'>
          {field?.label}
          {': '}
          {field?.required ? <span className='text-danger'>*</span> : null}
        </label>
        <GeolocationSelect
          simpleValue={fieldProperty || ''}
          onChange={async (params: {place_id?: number, description?: string}) => {
            const { place_id, description } = params

            // Update coords
            if (!field?.coordsKey) {
              return null
            }

            const coords: { lat?: number; lng?: number } = await getLatitudeAndLongitudeFromPlaceId({
              placeId: place_id,
            })

            let fieldsValuePairs: any = {}
            fieldsValuePairs[(fieldName as any)[field?.name] || field?.name] = description
            fieldsValuePairs[
              (fieldName as any)[field?.coordsKey] || field?.coordsKey
            ] = `${coords.lat},${coords.lng}`

            updateFields(fieldsValuePairs)
          }}
          onInputChange={(value: string) => {
            updateObjectField(value, fieldName)
          }}
        />
      </div>
    )
  }
  if (field?.type === 'refundButton') {
    const isStripeRefunded = Boolean(fieldProperty?.isStripeRefunded)
    const fieldLabel = `${field?.label}:`
    const buttonLabel = isStripeRefunded ? 'Rambursată' : 'Rambursează'
    const buttonStyle = isStripeRefunded ? 'btn-secondary' : 'btn-warning'

    return (
      <div
        key={`generatedField-${fieldName}`}
        className={`${fieldName}-card-field pb-7 card-field`}
        style={{ display: isHidden ? 'none' : '', ...style }}
      >
        <label key={`label${field?.name}`} htmlFor={field?.name} className='form-label'>
          {fieldLabel}
        </label>
        <div>
          <button className={`btn ${buttonStyle} w-100`} name={field?.name} disabled={isStripeRefunded} onClick={fieldProperty?.onClick}>
            {buttonLabel}
          </button>
        </div>
      </div>
    )
  }
  return (
    <div
      key={`generatedField-${fieldName}`}
      className={`${fieldName}-card-field pb-7 card-field`}
      style={{ display: isHidden ? 'none' : '', ...style }}
    >
      {generateField({
        field,
        fieldName,
        fieldProperty,
        setObject,
      })}
    </div>
  )
}

export const isResponseValid = (result: any) =>
  !(result instanceof Error || !Boolean(result?.data) || Boolean(result.error))

export const isFormValid = async ({
  fields,
  object,
  getField
}: {
  fields: any[]
  object: any,
  getField: (section: string, fileName: string, loadedData?: boolean) => string;
}): Promise<{ error: boolean; errorMessage: string; fieldName: string }> => {
  let returnObject = {
    error: false,
    errorMessage: '',
    fieldName: '',
  }

  for (let i = 0; i < fields.length; i++) {
    let currentField = fields[i]
    const fieldName: string = currentField.name || ''
    const objectHasFieldValue = Boolean(object[fieldName])
    if (currentField.required === true && !objectHasFieldValue) {
      returnObject = {
        error: true,
        errorMessage: `${currentField.label} is required`,
        fieldName: fieldName,
      }
      break
    }

    const canCheckRegex = currentField.regexConditions?.length > 0 ? currentField.regexConditions.every((cond) => {
      const field = getField(cond.section, cond.field);
      return field === cond.value || (Boolean(field) && cond.isComplete)
    }) : true;

    if (canCheckRegex && currentField.regex && objectHasFieldValue && !currentField.regex.test(object[fieldName])) {
      returnObject = {
        error: true,
        errorMessage: currentField.errorMessage,
        fieldName: fieldName,
      }
      break
    }

    const responseRequest = currentField.makeRequest ? await currentField.makeRequest(object[fieldName], getField) : null;
    if (responseRequest?.error) {
      returnObject = responseRequest;
      break
    }
  }

  return returnObject
}

export const sendContractCopy = async ({
  subscriptionId,
  sendToAgent,
}: {
  subscriptionId: number
  sendToAgent: boolean
}) => {
  const response = await orbitRequest({
    route: `/email/sendSubscriptionEmail?subscriptionId=${subscriptionId}&sendToAgent=${sendToAgent ? 1 : 0
      }`,
    method: RequestMethod.POST,
  })
  if (!response || response.error) {
    toast.error(
      response.message
    )
  } else {
    toast.success('Email succesfully sent!')
  }
}

export const sendMultipleContractCopies = async ({
  subscriptionIds
}: {
  subscriptionIds: number[]
}) => {
  const response = await orbitRequest({
    route: `/email/sendMultipleSubscriptionEmails`,
    method: RequestMethod.POST,
    payload: { subscriptionIds }
  })
  if (!response || response.error) {
    toast.error(
      response.message
    )
  } else {
    toast.success('Emails succesfully sent!')
  }
}

export const downloadContractCopy = async (subscriptionId: number, clientName: string) => {
  const today = new Date()
  const formattedDate = today
    .toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })
    .replace(/\//g, '-')
  const fileName = `${formattedDate}_${clientName}_KAR_CONTRACT.pdf`

  const result = await customAxios
    .get(`${API_URL}/pdf/create-invoice?id=${subscriptionId}`, { responseType: 'blob' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      return response
    })
    .catch((err) => err)
  if (!isResponseValid(result)) {
    toast.error(
      'Contract Downloading Failed: The Contract Copy could not be downloaded due to a technical issue.'
    )
  }
}

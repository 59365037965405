import {FC, useEffect, useState} from 'react'
import {emptyTariffService, pickupTimeBasedExtrasType, TariffServiceType} from './PlanTypes'
import {
  getInitializedObjectListWithEmpty,
  getObjectListWithEmptyStripped,
  onChangeListHasEmpty,
  updateField,
} from '../../../utils/state.utils'
import Table from './Table'
import {usePlanContext} from '../../../context/plan.context'
import {getArrayOfObjectsString} from '../../../utils/string.utils'

const pickupTimeTypeReq = ['name', 'fixedTax', 'kmTax', 'hourTax']
const pickupTimeTypeUnique = ['name', 'transferTypes', 'tariffServices']
const emptyPickupTime: pickupTimeBasedExtrasType = {
  id: null,
  name: '',
  transferTypes: [],
  tariffServices: [],
  fixedTax: 0,
  kmTax: 0,
  hourTax: 0,
}
const getPickupTimeExtraLabel = (pickupTimeExtra: pickupTimeBasedExtrasType) =>
  'pickup time extra (name: ' +
  (pickupTimeExtra.name || '') +
  '; transferTypes: ' +
  getArrayOfObjectsString({list: pickupTimeExtra.transferTypes || [], maxLength: 25}) +
  '; tariffServices: ' +
  getArrayOfObjectsString({
    list: pickupTimeExtra.tariffServices || [],
    identifier: 'name',
    maxLength: 25,
  }) +
  ')'

const PickupTimeTable: FC<{}> = ({}) => {
  const {plan, setPlan, dictionaries, tariffServices} = usePlanContext()
  const pricingTransferTypes = [{name: '', title: ''}, ...dictionaries.PRICING_TRANSFER_TYPES]

  const [pickupTimeExtras, setPickupTimeExtras] = useState<pickupTimeBasedExtrasType[]>(
    plan?.pickupTimeBasedExtras || []
  )

  useEffect(() => {
    let futurePickupTimeExtras = getInitializedObjectListWithEmpty({
      initialObjectList: plan?.pickupTimeBasedExtras || [],
      propsWithEmpty: [
        {property: 'tariffServices', emptyValue: emptyTariffService},
        {property: 'transferTypes', emptyValue: ''},
      ],
    })
    setPickupTimeExtras(futurePickupTimeExtras)
  }, [])

  useEffect(() => {
    let futurePickupTimeExtras = getObjectListWithEmptyStripped({
      objectList: pickupTimeExtras || [],
      propsWithEmpty: [
        {property: 'tariffServices', emptyValue: emptyTariffService},
        {property: 'transferTypes', emptyValue: ''},
      ],
    })
    setPlan({...plan, pickupTimeBasedExtras: futurePickupTimeExtras})
  }, [pickupTimeExtras])

  const PickupTimeProps = [
    {
      name: 'name',
      getDisplayLabel: (name: string) =>
        dictionaries.PRICING_EXTRAS.find((item: any) => item.name === name)?.title,
      label: 'Name',
      type: 'select',
    },
    {
      name: 'transferTypes',
      getDisplayLabel: (transferTypes: string[]) =>
        (transferTypes || [])
          .map((type: string) => {
            return pricingTransferTypes.find((item: any) => item.name === type).title || 'ALL'
          })
          .join(', '),
      label: 'Transfer Types',
      type: 'select',
      isMulti: true,
    },
    {
      name: 'tariffServices',
      getDisplayLabel: (tariffServices: TariffServiceType[]) =>
        (tariffServices || []).map((service: any) => service.name || 'ALL').join(', '),
      label: 'Tariff Services',
      type: 'select',
      isMulti: true,
    },
    {
      name: 'fixedTax',
      getDisplayLabel: (e: any) => e,
      label: 'Fixed Tax',
      type: 'currencyInput',
      canEditInView: true,
      addOns: {append: (plan?.currency || '').toLowerCase()},
      minWidth: '135px',
    },
    {
      name: 'kmTax',
      getDisplayLabel: (e: any) => e,
      label: 'km Tax',
      type: 'currencyInput',
      canEditInView: true,
      addOns: {append: (plan?.currency || '').toLowerCase()},
      minWidth: '135px',
    },
    {
      name: 'hourTax',
      getDisplayLabel: (e: any) => e,
      label: 'Hour Tax',
      type: 'currencyInput',
      canEditInView: true,
      addOns: {append: (plan?.currency || '').toLowerCase()},
      minWidth: '135px',
    },
  ]

  const pickupTimeFieldToProperty = (
    field: any,
    pickupTimeExtra: pickupTimeBasedExtrasType,
    setPickupTimeExtra: Function
  ) => {
    let fieldName = field.name
    const updatePickupTimeListElemEvent = (e: any) =>
      updateField({value: e, fieldName: fieldName, setObject: setPickupTimeExtra})

    const fieldsToPanelPropertiesMapping = {
      name: {
        simpleValue: pickupTimeExtra.name || '',
        options: dictionaries.PRICING_EXTRAS || [],
        getOptionValue: (e: any) => e.name || '',
        getOptionLabel: (e: any) => e.title || '',
        onChange: updatePickupTimeListElemEvent,
      },
      transferTypes: {
        simpleValue: pickupTimeExtra.transferTypes || [],
        options: pricingTransferTypes || [],
        getOptionValue: (e: any) => e.name || '',
        getOptionLabel: (e: any) => e.title || 'ALL',
        onChange: (transferTypes: string[]) =>
          onChangeListHasEmpty({
            entity: pickupTimeExtra,
            setEntity: setPickupTimeExtra,
            newValues: transferTypes,
            emptyValue: '',
            property: 'transferTypes',
          }),
        sorted: true,
      },
      tariffServices: {
        simpleValue: pickupTimeExtra.tariffServices || [],
        options: [emptyTariffService, ...(tariffServices || [])],
        getOptionValue: (e: any) => e || '',
        getOptionLabel: (e: any) => (Boolean(e.name) ? e.name : 'ALL'),
        onChange: (tariffServices: TariffServiceType[]) =>
          onChangeListHasEmpty({
            entity: pickupTimeExtra,
            setEntity: setPickupTimeExtra,
            newValues: tariffServices,
            emptyValue: emptyTariffService,
            property: 'tariffServices',
          }),
        sorted: true,
      },
      fixedTax: pickupTimeExtra.fixedTax,
      kmTax: pickupTimeExtra.kmTax,
      hourTax: pickupTimeExtra.hourTax,
    }

    return (fieldsToPanelPropertiesMapping as any)[fieldName]
  }

  return (
    <>
      <Table
        tableLabel={'Pickup Time Based Extras Table'}
        properties={PickupTimeProps}
        fieldToProperty={pickupTimeFieldToProperty}
        entries={pickupTimeExtras}
        setEntries={setPickupTimeExtras}
        emptyEntry={emptyPickupTime}
        getEntryLabel={getPickupTimeExtraLabel}
        requiredFields={pickupTimeTypeReq}
        compoundUniqueKey={pickupTimeTypeUnique}
      />
    </>
  )
}

export default PickupTimeTable

import React, {createContext, useMemo, useState} from 'react'
import {UserModel} from '../app/modules/auth/models/UserModel'
import {
  createUserRequest,
  deleteUserRequest,
  getUsersRequest,
  getSubscriptionCompaniesRequest,
  updateUserRequest,
} from '../setup/axios/users.request'
import {toast} from 'react-toastify'
import {defaultPaginationSize} from '../app/modules/transport-requests/Pagination'
import { CompanyType } from '../types/company.types'
const UsersContext = createContext({})

function UsersProvider(props: any) {
  const [usersData, setUsersData] = useState<Array<UserModel>>([])
  const [companies, setCompanies] = useState<Array<CompanyType>>([])
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>(undefined)
  const [selectedUsers, setSelectedUsers] = useState<Array<UserModel>>([])
  const [showCreateUserModal, setShowCreateUserModal] = useState<boolean>(false)

  // Get Companies
  const getCompanies = async () => {
    const response = await getSubscriptionCompaniesRequest()

    if (!Boolean(response) || response.error) {
      let errorMessage = response?.message || ''
      toast.error(`We have some problem with your last request! ${errorMessage}`)
      return {message: errorMessage, error: true}
    }

    setCompanies(response.companies)

    return {error: false, data: response.companies}
  }

  // Get Users
  const getUsers = async ({
    id,
    currentPage = 1,
    resultsPerPage = defaultPaginationSize,
    shouldUpdate = true,
    company_id
  }: {
    id?: string
    currentPage?: number
    resultsPerPage?: number
    shouldUpdate?: boolean
    company_id?:number
  }) => {
    const response = await getUsersRequest({id, currentPage, resultsPerPage, company_id})

    if (!response || response.error) {
      return toast.error(`We have some problems with your last request! ${response.message}`)
    }
    if (shouldUpdate) {
      setUsersData(response.data)
    }
    return {error: false, data: response}
  }

  // Create User
  const createUser = async (user: UserModel) => {
    const response = await createUserRequest({user: user})

    if (!response || response.error) {
      toast.error(`The user could not be created! ${response.message}`)
      return {message: response.message, error: true}
    }
    let futureUser = {...response.user}
    if (futureUser?.roles) {
      futureUser.role = futureUser.roles[0]?.name || ''
    }

    const futureUsersData = [...usersData, futureUser]
    setUsersData(futureUsersData)

    toast.success(`User ${user.email} created!`)
    return {error: false}
  }

  // Update User
  const updateUser = async (user: UserModel) => {
    const response = await updateUserRequest({user: user})

    if (!response || response.error) {
      toast.error(`The user with id ${user.id} could not be updated! ${response.message}`)
      return {message: response.message, error: true}
    }

    let futureUser = {...response.user}
    if (futureUser?.roles) {
      futureUser.role = futureUser.roles[0]?.name || ''
    }

    const futureUsersData = usersData.map((e) => {
      if (e.id === user.id) {
        e = futureUser
      }

      return e
    })
    setUsersData(futureUsersData)

    toast.success(`User ${user.email} was updated!`)
    return {error: false, data: response.user}
  }

  // Delete User
  const deleteUser = async (id: number) => {
    const response = await deleteUserRequest({id: id})

    if (!response || response.error) {
      toast.error(`The user with id ${id} could not be deleted! ${response.message}`)
      return {message: response.message, error: true}
    }

    const futureUsersData = usersData.filter((e) => e.id !== id)
    setUsersData(futureUsersData)

    toast.success(`The user with id ${id} was deleted!`)
    return {error: false}
  }

  const isSelectedUser = (User: UserModel): boolean => {
    if (selectedUsers.some((e) => e.id === User.id)) {
      return true
    }

    return false
  }
  const store = {
    usersData,
    getUsers,
    createUser,
    deleteUser,
    updateUser,
    isSelectedUser,

    companies,
    getCompanies,

    // Current User
    currentUser,
    setCurrentUser,

    // Selected Users
    selectedUsers,
    setSelectedUsers,

    // Create modal
    showCreateUserModal,
    setShowCreateUserModal,
  }

  const storeForProvider = useMemo(() => store, [store])
  return <UsersContext.Provider value={storeForProvider}>{props.children}</UsersContext.Provider>
}

export {UsersContext}
export default UsersProvider

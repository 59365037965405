import {FC, useEffect, useState} from 'react'
import {emptyTariffService, intercityKmCostsType, TariffServiceType} from './PlanTypes'
import {
  epsilon,
  getInitializedObjectListWithEmpty,
  getObjectListWithEmptyStripped,
  onChangeListHasEmpty,
  updateField,
} from '../../../utils/state.utils'
import Table from './Table'
import {usePlanContext} from '../../../context/plan.context'
import _ from 'lodash'
import {getArrayOfObjectsString} from '../../../utils/string.utils'

const intercityKmCostsTypeReq = ['transferType', 'tariffServices', 'kmPricePercentage']
const intercityKmCostsTypeUnique = ['transferType', 'tariffServices']
const emptyIntercityCost: intercityKmCostsType = {
  id: null,
  transferType: '',
  tariffServices: [],
  kmPricePercentage: 0,
}
const getIntercityKmCostLabel = (intercityKmCost: intercityKmCostsType) =>
  'intercity cost (transferType: ' +
  (intercityKmCost?.transferType || '') +
  '; tariffServices: ' +
  getArrayOfObjectsString({
    list: intercityKmCost?.tariffServices || [],
    identifier: 'name',
    maxLength: 25,
  }) +
  ')'

const IntercityCostsTable: FC<{}> = ({}) => {
  const {plan, setPlan, dictionaries, tariffServices} = usePlanContext()
  const [intercityCosts, setIntercityCosts] = useState<intercityKmCostsType[]>(
    plan?.intercityKmCosts || []
  )

  useEffect(() => {
    let futureIntercityCosts = getInitializedObjectListWithEmpty({
      initialObjectList: plan?.intercityKmCosts || [],
      propsWithEmpty: [{property: 'tariffServices', emptyValue: emptyTariffService}],
    })
    setIntercityCosts(futureIntercityCosts)
  }, [])

  useEffect(() => {
    let futureIntercityCosts = getObjectListWithEmptyStripped({
      objectList: intercityCosts || [],
      propsWithEmpty: [{property: 'tariffServices', emptyValue: emptyTariffService}],
    })
    setPlan({...plan, intercityKmCosts: futureIntercityCosts})
  }, [intercityCosts])

  const intercityCostsProps = [
    {
      name: 'transferType',
      getDisplayLabel: (name: string) =>
        dictionaries.INTERCITY_KM_COST_TYPES.find((item: any) => item.name === name)?.title,
      label: 'Transfer Type',
      type: 'select',
    },
    {
      name: 'tariffServices',
      getDisplayLabel: (tariffServices: TariffServiceType[]) =>
        (tariffServices || []).map((service: any) => service.name || 'ALL').join(', '),
      label: 'Tariff Services',
      type: 'select',
      isMulti: true,
    },
    {
      name: 'kmPricePercentage',
      getDisplayLabel: (e: any) => e,
      label: 'km Price Percentage',
      type: 'input',
      inputType: 'number',
      addOns: {prepend: '%'},
      canEditInView: true,
      minWidth: '135px',
    },
  ]

  const intercityCostsFieldToProperty = (
    field: any,
    intercityCost: intercityKmCostsType,
    setIntercityCost: Function
  ) => {
    let fieldName = field.name
    const updateIntercityCostListElemEvent = (e: any) =>
      updateField({value: e, fieldName: fieldName, setObject: setIntercityCost})

    const fieldsToPanelPropertiesMapping = {
      transferType: {
        simpleValue: intercityCost.transferType || '',
        options: dictionaries.INTERCITY_KM_COST_TYPES || [],
        getOptionValue: (e: any) => e.name || '',
        getOptionLabel: (e: any) => e.title || '',
        onChange: updateIntercityCostListElemEvent,
      },
      tariffServices: {
        simpleValue: intercityCost.tariffServices || [],
        options: [emptyTariffService, ...(tariffServices || [])],
        getOptionValue: (e: any) => e || '',
        getOptionLabel: (e: any) => (Boolean(e.name) ? e.name : 'ALL'),
        onChange: (tariffServices: TariffServiceType[]) =>
          onChangeListHasEmpty({
            entity: intercityCost,
            setEntity: setIntercityCost,
            newValues: tariffServices,
            emptyValue: emptyTariffService,
            property: 'tariffServices',
          }),
        sorted: true,
      },
      kmPricePercentage: {
        value: intercityCost.kmPricePercentage,
        effectOverValueOnChange: (val: number | string) => {
          if (val != 0 && !Boolean(val)) return '0'
          let parsedValue = parseFloat(val + '')
          const maxVal = 100 - epsilon
          return parsedValue < epsilon ? '0' : parsedValue > maxVal ? '100' : val + ''
        },
      },
    }
    return (fieldsToPanelPropertiesMapping as any)[fieldName]
  }

  return (
    <>
      <Table
        tableLabel={'Intercity Km Costs Table'}
        properties={intercityCostsProps}
        fieldToProperty={intercityCostsFieldToProperty}
        entries={intercityCosts}
        setEntries={setIntercityCosts}
        emptyEntry={emptyIntercityCost}
        getEntryLabel={getIntercityKmCostLabel}
        requiredFields={intercityKmCostsTypeReq}
        compoundUniqueKey={intercityKmCostsTypeUnique}
      />
    </>
  )
}

export default IntercityCostsTable

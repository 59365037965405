import React, {createContext, useMemo, useState} from 'react'
import {GeofenceType} from '../types/general.types'

const GeofenceContext = createContext({})

function GeofenceProvider(props: any) {
  const [selectedGeofence, setSelectedGeofence] = useState<any>(null)
  const [geofences, setGeofences] = useState<GeofenceType[]>([])

  const store = {
    selectedGeofence,
    setSelectedGeofence,
    geofences,
    setGeofences,
  }

  const storeForProvider = useMemo(() => store, [store])
  return (
    <GeofenceContext.Provider value={storeForProvider}>{props.children}</GeofenceContext.Provider>
  )
}

const useGeofence = () => {
  const context = React.useContext<any>(GeofenceContext)
  if (context === undefined) {
    throw new Error('useGeofence must be used within a GeofenceProvider')
  }
  return context
}

export {GeofenceContext, useGeofence}
export default GeofenceProvider

import { Field, Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import { StatusType, Type, campaignTypes, statusTypes } from '../../../../types/campaign.types';
import { campaignValidationSchema } from '../utils';
import { APIResponseType } from '../../../../types/general.types';
import { RequestMethod, orbitRequest } from '../../../../utils/api.utils';
import { toast } from 'react-toastify';
import { CAMPAIGN_CSV_PASSWORD } from '../../../../constants/api.constants';
import { ChangeEvent, useState } from 'react';

export const saveCampaignRequest = async (bodyFormData: FormData): Promise<APIResponseType> =>
  await orbitRequest({
    route: `/campaigns/create`,
    payload: bodyFormData,
    method: RequestMethod.POST,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });

const AddEditCampaignForm = () => {
  const [clientsListFile, setClientsListFile] = useState<File | null>(null);

  const saveCampaign = async (values) => {
    const bodyFormData = await createFormDataObject(values);
    console.log(values, clientsListFile);
    //return
    const response = await saveCampaignRequest(bodyFormData);
    if (!response || response.error) {
      toast.error(`Unable to save campaign! ${response?.message || ''}`);
      return;
    }

    toast.success(`Campaign has been added`);
    return;
  };

  const createFormDataObject = async (values) => {
    const bodyFormData = new FormData();

    bodyFormData.append('name', values.name);
    bodyFormData.append('type', values.type);
    bodyFormData.append('content_type', values.content_type);
    if (values.send_after) {
      bodyFormData.append('send_after', values.send_after);
    }
    if (values.expiration_date) {
      bodyFormData.append('expiration_date', values.expiration_date);
    }
    bodyFormData.append('status', values.status);
    bodyFormData.append('number_of_clients', values.number_of_clients);
    bodyFormData.append('password', values.password);
    if (clientsListFile) {
      bodyFormData.append('file', clientsListFile);
    }

    return bodyFormData;
  };

  return (
    <div className='card-header border-0 pt-5'>
      <Formik
        validationSchema={campaignValidationSchema}
        initialValues={{
          name: '',
          type: Type.EMAIL.toLowerCase(),
          content_type: '',
          send_after: null,
          expiration_date: null,
          status: StatusType.NOT_STARTED,
          number_of_clients: 0,
          password: CAMPAIGN_CSV_PASSWORD,
        }}
        onSubmit={(values) => saveCampaign(values)}
      >
        {({ values, handleChange }) => (
          <Form encType='multipart/form-data' className='form w-100'>
            <div>
              <div style={{ display: 'inline-block', width: 365, marginRight: '20px' }}>
                {/* begin::Name */}
                <ul className='autonom-dynamic-card'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    Campaign Name
                  </label>
                  <Field
                    className='form-control'
                    name='name'
                    variant='standard'
                    onChange={handleChange}
                  />
                </ul>
                {/* end::Name */}
              </div>

              {/* begin::Type */}
              <div style={{ display: 'inline-block', width: 365, marginRight: '20px' }}>
                <ul className='autonom-dynamic-card'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>Type</label>
                  <Field
                    className='form-control'
                    name='type'
                    variant='standard'
                    as='select'
                    onChange={handleChange}
                  >
                    {campaignTypes?.map((item, id) => (
                      <option key={id} value={item} label={item.replaceAll('_', ' ').toUpperCase()}>
                        {item}
                      </option>
                    ))}
                  </Field>
                </ul>
                {/* end::Type */}
              </div>

              <div style={{ display: 'inline-block', width: 365, marginRight: '20px' }}>
                {/* begin::ContentType */}
                <ul className='autonom-dynamic-card'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    Content Type
                  </label>
                  <Field
                    className='form-control'
                    name='content_type'
                    variant='standard'
                    onChange={handleChange}
                  />
                </ul>
                {/* end::ContentType */}
              </div>
            </div>

            <div style={{ display: 'inline-block', width: 365, marginRight: '20px' }}>
              {/* begin::SendAfter */}
              <ul className='autonom-dynamic-card'>
                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>Send After</label>
                <Field
                  className='form-control'
                  name='send_after'
                  variant='standard'
                  type='datetime-local'
                  onChange={handleChange}
                />
              </ul>
              {/* end::SendAfter */}
            </div>

            <div style={{ display: 'inline-block', width: 365, marginRight: '20px' }}>
              {/* begin::ExpirationDate */}
              <ul className='autonom-dynamic-card'>
                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                  Expiration Date
                </label>
                <Field
                  className='form-control'
                  name='expiration_date'
                  variant='standard'
                  type='datetime-local'
                  onChange={handleChange}
                />
              </ul>
              {/* end::ExpirationDate */}
            </div>

            <div style={{ display: 'inline-block', width: 365, marginRight: '20px' }}>
              {/* begin::Status */}
              <ul className='autonom-dynamic-card'>
                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>Status</label>
                <Field
                  className='form-control'
                  name='status'
                  variant='standard'
                  as='select'
                  onChange={handleChange}
                >
                  {statusTypes?.map((item, id) => (
                    <option key={id} value={item}>
                      {item}
                    </option>
                  ))}
                </Field>
              </ul>
              {/* end::Status */}
            </div>

            <div style={{ display: 'inline-block', width: 365 }}>
              {/* begin::NumberOfClients */}
              <ul className='autonom-dynamic-card'>
                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                  Number of Clients
                </label>
                <Field
                  className='form-control'
                  name='number_of_clients'
                  type='number'
                  variant='standard'
                  onChange={handleChange}
                />
              </ul>
              {/* end::NumberOfClients */}
            </div>

            <div
              className='d-flex justify-content-between'
              style={{ margin: '0 28px', marginBottom: 10 }}
            >
              {/* begin::ImportCSV */}
              <div>
                <label className='mt-4 py-1 fw-bolder'>CSV File:</label>
                <input
                  name='file'
                  className='btn btn-info me-4'
                  type='file'
                  accept='.csv'
                  style={{ width: '100%' }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const files = e.target.files || [];
                    const futureFile = files[0];
                    setClientsListFile(futureFile);
                  }}
                />
              </div>
              {/* end::ImportCSV */}

              <div style={{ display: 'inline-block', marginTop: 50 }}>
                {/* begin::AddButton */}
                <Button
                  className='btn btn-success mb-5 me-4'
                  type='submit'
                  onClick={() => saveCampaign(values)}
                >
                  Add
                </Button>
                {/* end::AddButton */}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddEditCampaignForm;

// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState, useContext} from 'react'
import {Redirect, useHistory} from 'react-router-dom'

import '../../app/modules/autonom.scss'
import moment from 'moment'
import {PageTitle} from '../../_metronic/layout/core'
import {
  disableMaxWidth,
  enableMaxWidth,
  getServiceImage,
  getStyleForStatus,
} from '../../utils/style.utils'
import Input from '../../components/input/input.component'
import {toast} from 'react-toastify'
import {EASYTRACK_API_URL} from '../../constants/api.constants'
import easytrackAxios from '../../setup/easytrack.axios'
import { TransportsContext } from '../../context/transports.context'
import { TransportType } from '../../app/modules/add-edit-transport/scripts/transportTypes'

const ConfirmationPage: FC = () => {
  const {selectedTransportsForConfirmation, setSelectedTransportsForConfirmation} = useContext(TransportsContext)
  const [emailTitle, setEmailTitle] = useState<string>('Will be autocompleted')

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [confimationTypes, setConfimationTypes] = useState<any>([])
  const history = useHistory()
  const [typeSelected, setTypeSelected] = useState<any>(null)

  useEffect(() => {
    ;(async () => {
      const result = await easytrackAxios
        .get(`${EASYTRACK_API_URL}/utils/dictionaries`)
        .catch((err) => err)
      if (result.data) {
        setConfimationTypes(result.data.EMAIL_CONFIRMATION_TYPES)
      }
    })()
  }, [])

  if (!selectedTransportsForConfirmation) {
    return <Redirect to='/' />
  }

  const handleSubmit = async (e: any) => {
    const transportRequestNumbers = []
    toast.info('Sending Confirmation Emails...')
    selectedTransportsForConfirmation.forEach((transport: TransportType) => {
      transportRequestNumbers.push(transport.number)
    })

    const result = await easytrackAxios
      .post(`${EASYTRACK_API_URL}/transport-requests/multiple-emails`, {
        title: emailTitle,
        confirmationText: typeSelected.name,
        numbers: transportRequestNumbers,
      })
      .catch((err) => {
        toast.error(`The request for sending the emails failed: ${err}`)
        return err
      })
    if (result.data) {
      toast.success('Emails sent')
      setSelectedTransportsForConfirmation([])
      return history.goBack()
    }
  }

  return (
    <div>
      <div
        className='col-xxl-12'
        style={{
          backgroundColor: '#fff',
        }}
      >
        <div className='table-responsive transport-table'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bolder'>
                <th
                  style={{
                    paddingLeft: '16px',
                  }}
                >
                  No.
                </th>
                <th>Status</th>
                <th>Effective date</th>
                <th>Client</th>
                <th>Requestor</th>
                <th>Pickup address</th>
                <th>Destination</th>
                <th>Passenger</th>
                <th>P.#</th>
                <th>Service</th>
                <th>Veh.</th>
                <th>Driver</th>
              </tr>
            </thead>

            <tbody>
              {selectedTransportsForConfirmation.map((e) => {
                return (
                  <tr>
                    {/* Number */}
                    <td>
                      <div
                        className='clickable'
                        onClick={() => {
                          // append to cuurent url the id of the transport
                          history.push(`/add-edit-transport/DTD/${e.id}`)

                          // window.location.href = window.location.href + `/${transport.id}`
                        }}
                        style={{marginBottom: 8, textDecoration: 'underline'}}
                      >
                        {e.number}
                      </div>
                    </td>
                    {/* Status */}
                    <td>
                      <span style={getStyleForStatus(e.requestStatus)}>{e.requestStatus}</span>
                    </td>
                    {/* Effective date */}
                    <td>
                      <span style={{whiteSpace: 'nowrap'}}>
                        {moment(e.effectiveDate).format('YYYY-MM-DD HH:mm')}
                      </span>
                    </td>
                    {/* Client */}
                    <td>{e.company.name || ''}</td>
                    {/* Requestor */}
                    <td>{e.requestorName}</td>
                    {/* Pickup address */}
                    <td>
                      {e.origin} {e.originAdditionalInfo}
                    </td>
                    {/* Destination */}
                    <td>{e.destination}</td>
                    {/* Passenger */}
                    <td>{e.passengerName}</td>
                    {/* p nr */}
                    <td>{e.passengers}</td>
                    {/* service */}
                    <td style={{textAlign: 'center'}}>
                      {/* Show service image */}
                      {getServiceImage(e.tariffServiceName) ? (
                        <img
                          style={{width: '70px', marginBottom: '4px'}}
                          src={`${getServiceImage(e.tariffServiceName)}`}
                        />
                      ) : null}

                      {e.tariffServiceName}
                    </td>

                    {/* vehicle */}
                    <td>{e.assignedVehicle?.unitNumber || ''}</td>
                    {/* driver */}
                    <td
                      style={{
                        paddingRight: '16px',
                      }}
                    >
                      {e.assignedDriver?.fullName || ''}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Bottom Form */}
      <div
        className='col-xxl-12'
        style={{
          marginTop: 16,
          padding: '32px 16px',
          backgroundColor: '#fff',
        }}
      >
        <div className='col-xxl-9'>
          <Input
            label='Email title'
            value={emailTitle}
            onChange={(event) => {
              setEmailTitle(event.target.value)
            }}
          />

          {/* testg */}
          <div
            key={JSON.stringify(typeSelected)}
            data-kt-control='toggle-buttons'
            style={{
              display: 'flex',
              marginTop: 16,
            }}
          >
            {confimationTypes.map((e) => {
              const isActive = typeSelected?.title === e.title
              return (
                <div key={e.title}>
                  <input
                    type='radio'
                    className='btn-check'
                    name='form-options'
                    checked={isActive}
                    id={'kt' + e.title}
                    onChange={() => {
                      setTypeSelected(e)
                    }}
                  />
                  <label
                    style={{
                      marginRight: '16px',
                    }}
                    className={`col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active py-2 px-4 min-w-125px ${
                      isActive ? 'active' : ''
                    }`}
                    htmlFor={'kt' + e.title}
                  >
                    <span className='text-gray-800 fw-bold'>{e.title}</span>
                  </label>
                </div>
              )
            })}
          </div>

          {/* Button */}
          <button
            disabled={!typeSelected || isLoading}
            onClick={async () => {
              if (typeSelected) {
                setIsLoading(true)
                await handleSubmit()
                setIsLoading(false)
              }
            }}
            style={{
              cursor: typeSelected && !isLoading ? 'pointer' : 'not-allowed',
              marginTop: '42px',
              padding: '8px 16px',
              backgroundColor: typeSelected && !isLoading ? '#00a65a' : '#eee',
              color: typeSelected && !isLoading ? '#fff' : '#333',
              border: 'none',
              borderRadius: '4px',
              fontSize: '14px',
            }}
          >
            Send confirmation email
            {isLoading ? <i className='fa fa-circle-o-notch fa-spin ms-2' /> : null}
          </button>
        </div>
      </div>
    </div>
  )
}

const ConfirmationPageWrapper: FC = () => {
  useEffect(() => {
    disableMaxWidth()

    return () => {
      enableMaxWidth()
    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>Pagina de confirmare</PageTitle>
      <ConfirmationPage />
    </>
  )
}

export default ConfirmationPageWrapper

import React, { useState } from 'react'
import { sendContractCopy } from '../../../add-edit-subscription/utils'
import { FlatSubscriptionType } from '../../../../../types/subscription.types'

const SendEmailModal = ({
  subscription,
  setModalVisible,
}: {
  subscription: FlatSubscriptionType
  setModalVisible: (modalVisible: boolean) => void
}) => {
  const [sendToAgent, setSendToAgent] = useState(false)
  const {
    id: subscriptionId,
    client_email: email,
    user_email: agentEmail,
  } = subscription
  return (
    <div className='d-flex flex-column'>
      <div className='modal-header'>
        <p className='fs-2 text-center modal-title'>
          Trimite email clientului cu adresa:
          <span className='badge bg-light fs-2 text-dark fw-normal p-2'>{email}</span>
        </p>
      </div>

      <div className='d-flex modal-body'>
        <input
          className='clickable form-check-input w-30px h-30px me-2 flex-shrink-0 mb-3'
          type='checkbox'
          checked={sendToAgent}
          onChange={(e) => {
            let value = e.target.checked
            setSendToAgent(value)
          }}
        />
        <p className='fs-4'>
          Trimite si la adresa agentului:
          <span className='badge bg-light fs-4 text-dark fw-normal p-2'>{agentEmail}</span>
        </p>
      </div>

      <div className='modal-footer d-flex justify-content-center'>
        <div className='btn btn-secondary me-5' onClick={() => setModalVisible(false)}>
          Anuleaza
        </div>
        <div
          className='btn btn-primary'
          onClick={() => {
            sendContractCopy({ subscriptionId, sendToAgent })
            setModalVisible(false)
          }}
        >
          Trimite
        </div>
      </div>
    </div>
  )
}

export default SendEmailModal

import {uuid4} from '@sentry/utils'
import React, {FC, useState} from 'react'
import {toast} from 'react-toastify'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import ModalPortal from '../../../../_metronic/layout/components/modal/modal-portal'
import {EditableLabel} from '../../add-edit-template/components/fields-generator'

type localPassengerType = {
  name: string
  tempKey: string
}

const ActionButtons: FC<{
  isLoading: boolean
  setIsLoading: Function
  setModalVisible: Function
  otherPassengers: localPassengerType[]
  setPassengers: Function
}> = ({isLoading, setIsLoading, setModalVisible, otherPassengers, setPassengers}) => {
  return (
    <div className='mt-8'>
      <button
        className='btn btn-secondary me-5'
        disabled={isLoading}
        onClick={() => {
          setModalVisible(false)
        }}
      >
        Cancel
      </button>
      <button
        className='btn btn-success'
        disabled={isLoading}
        onClick={async () => {
          setIsLoading(true)
          const isValidList = otherPassengers.every((passenger) => Boolean(passenger.name))
          if (!isValidList) {
            toast.error('Missing or invalid name on one of the passengers!')
            setIsLoading(false)
            return
          }

          setPassengers([...otherPassengers.map((passenger) => passenger.name.trim())])
          setModalVisible(false)
        }}
      >
        {isLoading ? <i className='fa fa-circle-o-notch fa-spin' /> : null}
        Save
      </button>
    </div>
  )
}

const getEditableFields = ({
  otherPassengers,
  setOtherPassengers,
  isLoading,
}: {
  otherPassengers: localPassengerType[]
  setOtherPassengers: Function
  isLoading: boolean
}) => {
  return (
    <div className='w-100'>
      <table className='table table-hover table-row-gray-100 align-middle w-100'>
        <tr className='fw-bolder'>
          <th>No.</th>
          <th>Passenger Name</th>
          <th style={{width: '50px', maxWidth: '60px', paddingLeft: '0.75rem'}}>Actions</th>
        </tr>
        {otherPassengers.map((passenger, index) => {
          return (
            <tr key={`row-${passenger.tempKey}`}>
              <td>
                <span>{index + 1}</span>
              </td>
              <td>
                <div>
                  <input
                    placeholder='name...'
                    type={'text'}
                    className='form-control form-control-sm'
                    value={passenger.name}
                    onChange={(e: any) => {
                      setOtherPassengers(
                        otherPassengers.map((elem, elemIndex) => {
                          if (elemIndex !== index) {
                            return elem
                          }

                          return {...elem, name: e.target.value}
                        })
                      )
                    }}
                  />
                </div>
              </td>
              <td>
                <div>
                  <button
                    disabled={isLoading}
                    className='btn btn-icon btn-light-danger h-30px'
                    onClick={() => {
                      setOtherPassengers(
                        otherPassengers.slice(0, index).concat(otherPassengers.slice(index + 1))
                      )
                      toast.info('The passenger was removed!')
                    }}
                  >
                    <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-4' />
                  </button>
                </div>
              </td>
            </tr>
          )
        })}
      </table>
      <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
        <button
          type='button'
          className='btn btn-light-primary btn-text-primary'
          disabled={isLoading}
          onClick={() => {
            setOtherPassengers([
              ...otherPassengers,
              {
                name: '',
                tempKey: uuid4(),
              },
            ])
          }}
        >
          <div style={{display: 'flex', alignItems: 'center'}}>
            <KTSVG path='/media/icons/duotone/Navigation/Plus.svg' className='svg-icon-2' />
            Add Entry
          </div>
        </button>
      </div>
    </div>
  )
}

const PassengersListModal = ({setModalVisible, modalVisible, passengerTempList, setPassengers}) => {
  const [otherPassengers, setOtherPassengers] = useState(passengerTempList || [])
  const [isLoading, setIsLoading] = useState(false)

  return (
    <ModalPortal setVisible={setModalVisible} visible={modalVisible} hasExitButton={false}>
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          marginTop: '10px',
          alignItems: 'center',
          width: '92.5%',
        }}
      >
        <span className='mb-8' style={{fontSize: '16px', fontWeight: '500'}}>
          Other Passengers
        </span>
        {getEditableFields({otherPassengers, setOtherPassengers, isLoading})}
        <ActionButtons
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setModalVisible={setModalVisible}
          otherPassengers={otherPassengers}
          setPassengers={setPassengers}
        />
      </div>
    </ModalPortal>
  )
}

const getDisplayText = (passengerList: string[]) => {
  if (passengerList.length === 0) {
    return 'No other passengers'
  } else if (passengerList.length === 1) {
    return '1 other passenger'
  } else {
    return `${passengerList.length} other passengers`
  }
}

const PassengersComponent = ({
  passengerNames = [],
  field,
  onChangeLabel,
  onChange = () => {},
  hideLabel = false,
}: {
  passengerNames?: string[]
  field: any
  onChangeLabel?: any
  onChange?: any
  hideLabel?: boolean
}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const passengerTempList: localPassengerType[] = (passengerNames || []).map((passengerName) => ({
    name: passengerName,
    tempKey: uuid4(),
  }))

  return (
    <div key={`list-${field?.name}`}>
      {!hideLabel ? (
        <EditableLabel
          label={field?.label}
          onChange={onChangeLabel}
          isRequired={field?.required}
          style={{display: 'block'}}
        />
      ) : null}
      <button
        type='button'
        onClick={() => {
          setModalVisible(true)
        }}
        style={{border: 'none', backgroundColor: 'white', color: '#009ef7', paddingLeft: 0}}
      >
        {getDisplayText(passengerNames)}
      </button>
      {modalVisible ? (
        <PassengersListModal
          passengerTempList={passengerTempList}
          setPassengers={onChange}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      ) : null}
    </div>
  )
}

export default PassengersComponent

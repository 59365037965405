import React from 'react'
import { EditableLabel } from '../../../../../app/modules/add-edit-template/components/fields-generator'
import { InputComponentGroup } from '../../../../../components/field/field-generator'

const InputField = ({
  field,
  value,
  hideLabel,
  readOnly,
  style,
  containerStyle,
  placeholder,
  onChange,
  onChangeLabel,
  onBlurEffect,
}: {
  field: any
  value: any
  hideLabel?: boolean
  readOnly?: boolean
  style?: React.CSSProperties
  containerStyle?: React.CSSProperties
  placeholder?: string
  onChange: (e: any) => void
  onChangeLabel?: (value: string) => void
  onBlurEffect?: (value: string | number) => void
}) => {
  const inputComponent = (
    <input
      name={field?.name}
      type={field?.inputType}
      style={{
        borderRadius: Boolean(!field?.addOns)
          ? '0.475rem'
          : Boolean(field?.addOns.prepend)
            ? '0rem 0.475rem 0.475rem 0rem'
            : '0.475rem 0rem 0rem 0.475rem',
        ...(style || {}),
      }}
      required={field?.required}
      className={field?.inputType !== "checkbox" ? `form-control form-control-sm` : "input-checkbox"}
      id={field?.name}
      value={value || ''}
      placeholder={placeholder}
      defaultChecked={value == 1}
      onChange={onChange}
      onBlur={(ev: any) => {
        var futureValue = ev.target.value
        if (Boolean(onBlurEffect)) {
          ev.target.value = onBlurEffect(futureValue)
        }
        onChange(ev)
      }}
      readOnly={readOnly}
      disabled={field?.disabled}
    />
  )

  return (
    <div style={{ ...(containerStyle || {}) }}>
      {!Boolean(hideLabel) ? (
        <EditableLabel label={field?.label} onChange={onChangeLabel} isRequired={field.required} />
      ) : null}
      {Boolean(field?.addOns) ? (
        <InputComponentGroup addOns={field?.addOns}>{inputComponent}</InputComponentGroup>
      ) : (
        inputComponent
      )}
    </div>
  )
}

export default InputField

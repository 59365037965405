import moment from 'moment'
import Loading from '../../../_metronic/layout/components/loading/Loading'

import {components} from 'react-select'
import {memo, useEffect, useMemo, useState} from 'react'
import {getDistanceRequest} from '../../../setup/axios/transports.request'
import {toast} from 'react-toastify'
const {Option} = components

const getStyleForTransportSmallInfo = (transport, selectedTransport) => {
  let style = {}

  if (!transport || !selectedTransport) {
    return {}
  }
  // Set next style
  if (
    moment(transport.effectiveDate).isBetween(
      selectedTransport.effectiveDate,
      selectedTransport.completeEstimateDate
    ) ||
    moment(transport.completeEstimateDate).isBetween(
      selectedTransport.effectiveDate,
      selectedTransport.completeEstimateDate
    )
  ) {
    style = {
      background: 'red',
      color: '#fff',
    }
  }

  return style
}

const getClosestDateInArray = (array, selectedTransport) => {
  let minDiff = null
  let mostAccurateDate = array[0]
  let mostAccurateDateIndex = 0
  array.map((item, index) => {
    const diff = Math.abs(
      moment(selectedTransport.effectiveDate).diff(item.effectiveDate, 'minutes', true)
    )
    if (!minDiff || diff < minDiff) {
      minDiff = diff
      mostAccurateDate = item
      mostAccurateDateIndex = index
    }
  })

  if (!mostAccurateDate) {
    return {
      last: null,
      next: null,
    }
  }

  if (moment(mostAccurateDate.effectiveDate).isAfter(selectedTransport.effectiveDate)) {
    return {
      last: array[mostAccurateDateIndex - 1],
      next: mostAccurateDate,
      nextStyle: getStyleForTransportSmallInfo(mostAccurateDate, selectedTransport),
      lastStyle: getStyleForTransportSmallInfo(array[mostAccurateDateIndex - 1], selectedTransport),
    }
  } else {
    return {
      last: mostAccurateDate,
      next: array[mostAccurateDateIndex + 1],
      nextStyle: getStyleForTransportSmallInfo(array[mostAccurateDateIndex + 1], selectedTransport),
      lastStyle: getStyleForTransportSmallInfo(array[mostAccurateDate + 1], selectedTransport),
    }
  }
}

const CustomDriverOption = ({
  data,
  transportsPerDriver,
  selectedTransport,
  ...props
}: any) => {
  const [driver] = useState(data)
  const [closestTransport, setClosestTransport] = useState(null)

  // Between closest last and next transport
  const [infoBetweenTransportsLoading, setInfoBetweenTransportsLoading] = useState(false)
  const [infoBetweenTransports, setInfoBetweenTransports] = useState<{
    time: number
    distance: number
  } | null>(null)
  useEffect(() => {
    const currentDriverTransports = transportsPerDriver[driver.idUserProfile]
      ? transportsPerDriver[driver.idUserProfile].sort((a, b) => {
          return moment(a.effectiveDate).isBefore(moment(b.effectiveDate)) ? -1 : 1
        })
      : []

    const x = getClosestDateInArray(currentDriverTransports, selectedTransport)
    setClosestTransport(x)
  }, [])

  const isOutsideDriverShift = useMemo(() => {
    if (!driver || !driver.currentShift) {
      return false
    }

    if (
      moment(selectedTransport.effectiveDate).isBefore(driver.currentShift.startDate) ||
      moment(selectedTransport.completeEstimateDate).isAfter(
        driver.currentShift.endDateAccordingToNormAndPauseTime
      )
    ) {
      return true
    }

    return false
  }, [driver, selectedTransport])

  const canRenderCalculationButton = useMemo(() => {
    return closestTransport?.last && closestTransport?.next
  }, [closestTransport])

  const getInfoBetweenTransports = async () => {
    setInfoBetweenTransportsLoading(true)
    // setInfoBetweenTransports(null)

    if (infoBetweenTransports) {
      return setInfoBetweenTransportsLoading(false)
    }

    const result = await getDistanceRequest({
      originCoordinates: closestTransport.last.destinationCoords,
      destinationCoordinates: closestTransport.next.originCoords,
      departureDate: closestTransport.last.completeEstimateDate,
      intermediaryPoints: [],
    })
    setInfoBetweenTransportsLoading(false)

    if (result.data.empty) {
      toast.warning('No data yet...')
    }

    if (result.data) {
      setInfoBetweenTransports({
        time: result.data.durationInMinutes.toFixed(2),
        distance: result.data.distanceInKilometers.toFixed(2),
      })
    }
  }

  return (
    <Option
      {...props}
      style={{
        borderTop: '1px solid #eee',
      }}
    >
      <div style={{padding: 8, border: '1px solid #eee', background: 'white', color: 'black'}}>
        <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
          {props.label.trim()}{' '}
          <div>
            <span className='badge bg-success'>
              {onlyFirstLetterUpperCase(driver.englishLevel.trim())} English
            </span>
            {driver.employeeBadge ? (
              <span className='badge bg-dark' style={{marginLeft: 8}}>
                {driver.employeeBadge.trim()}
              </span>
            ) : null}
          </div>
        </div>

        {driver.currentShift ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: isOutsideDriverShift ? 'underline' : 'none',
              textDecorationColor: 'red',
              color: isOutsideDriverShift ? 'red' : 'black',
              opacity: isOutsideDriverShift ? 1 : 0.7,
            }}
          >
            Shift: {moment(driver.currentShift.startDate).format('HH:mm')} -{' '}
            {moment(driver.currentShift.endDateAccordingToNormAndPauseTime).format('HH:mm')}
          </div>
        ) : null}

        {canRenderCalculationButton ? (
          <div>
            <p
              style={{
                marginTop: 4,
                marginBottom: 4,
                width: 200,
                textDecoration: 'underline',
              }}
              className='clickable'
              onMouseEnter={() => {
                getInfoBetweenTransports()
              }}
            >
              Get info between transports
            </p>
            {infoBetweenTransportsLoading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <Loading size={35} />
                Loading...
              </div>
            ) : null}
            {infoBetweenTransports?.distance ? (
              <div
                className='transport-item-date'
                style={{fontSize: 12, marginBottom: 0, opacity: 1}}
              >
                Distance: {infoBetweenTransports?.distance} km
              </div>
            ) : null}
            {infoBetweenTransports?.time ? (
              <div
                className='transport-item-date'
                style={{fontSize: 12, marginBottom: 4, opacity: 1}}
              >
                Time: {infoBetweenTransports?.time} min
              </div>
            ) : null}
          </div>
        ) : null}

        <div>
          <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
            <TransportSmallInfo
              style={{marginRight: 5, ...(closestTransport?.lastStyle || {})}}
              label='Last'
              transport={closestTransport?.last}
            />
            <TransportSmallInfo
              style={{marginLeft: 5, ...(closestTransport?.nextStyle || {})}}
              label='Next'
              transport={closestTransport?.next}
            />
          </div>
        </div>
      </div>
    </Option>
  )
}

const TransportSmallInfo = memo(({transport, style, label}: any) => {
  const address = useMemo(() => {
    if (!transport) {
      return ''
    }

    if (label === 'Last') {
      return transport.destinationAdditionalInfo + ' - ' + transport.destination
    }

    if (label === 'Next') {
      return transport.originAdditionalInfo + ' - ' + transport.origin
    }

    return ''
  }, [transport])

  return (
    <div
      style={{
        flex: 1,
        marginTop: 8,
        borderRadius: 4,
        padding: 8,
        background: '#f8f8f8',
        color: 'black',
        ...style,
      }}
    >
      {label === 'Last' ? 'Previous destination:' : 'Next pick-up location:'}
      {address ? (
        <div className='transport-item-date' style={{fontSize: 12, marginBottom: 4, opacity: 1}}>
          <p>{address}</p>
        </div>
      ) : null}

      {transport?.effectiveDate ? (
        <div className='transport-item-date' style={{fontSize: 12, marginTop: -4}}>
          Start: {moment(transport.effectiveDate).format('HH:mm (DD/MM/YYYY)')}
        </div>
      ) : null}
      {transport?.completeEstimateDate ? (
        <div className='transport-item-date' style={{fontSize: 12, marginTop: -4}}>
          Stop: {moment(transport.completeEstimateDate).format('HH:mm (DD/MM/YYYY)')}
        </div>
      ) : null}
    </div>
  )
})

const onlyFirstLetterUpperCase = (value: string) => {
  value = value.toLowerCase()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export default CustomDriverOption

import moment from 'moment'
import { toast } from 'react-toastify'
import { EASYTRACK_API_URL } from '../../constants/api.constants'
import { TariffServiceType } from '../../pages/add-edit-plan/modules/PlanTypes'
import { logError } from '../../utils/sentry.utils'
import {
  getClientVoucherFromTransport,
  getTransportForDetails,
  TransportForDetails,
} from '../../utils/transport.utils'
import { isValidEmail } from '../../_metronic/layout/components/fields/chipsTextbox/chips-textbox'
import { getUniqueEmailListWithNewEmails } from '../../_metronic/layout/components/fields/confirmationContainer/confirmation-container'
import easytrackAxios from '../easytrack.axios'
import { TransportType } from '../../app/modules/add-edit-transport/scripts/transportTypes'
import { ImportTransportEntry } from '../../app/modules/transport-requests/components/utils/importTransportsUtils'
import { POI } from '../../app/modules/poi-component/poiConstants'
import { easytrackRequest, RequestMethod } from '../../utils/api.utils'

export const getTransportsRequest = ({
  countMode,
  id,
  ids,
  businessProfileId,
  page,
  resultsPerPage,
  requestStatuses,
  vehicleIds,
  driverIds,
  clientIds,
  supplierIds,
  tariffServiceIds,
  startDate,
  stopDate,
  orderBy,
  orderDir,
  number,
  passengerName,
  requesterName,
  paymentTypes,
  pricingTransferTypes,
  invoiceNumber,
  companyCustomField1,
  companyCustomField2,
  companyCustomField3,
  companyCustomField4,
  companyCustomField5,
  revenueCenters,
  searchText,
  simplifyForAssignments = false,
}: {
  countMode?: boolean
  id?: number
  ids?: Array<Number>
  businessProfileId: string
  page?: number
  resultsPerPage?: number
  requestStatuses?: Array<any>
  vehicleIds?: Array<Number>
  driverIds?: Array<Number>
  clientIds?: Array<Number>
  startDate?: Date
  stopDate?: Date
  paymentTypes?: Array<String>
  revenueCenters?: Array<String>
  pricingTransferTypes?: Array<String>
  supplierIds?: Array<Number>
  tariffServiceIds?: Array<Number>
  invoiceNumber?: string,
  companyCustomField1?: string,
  companyCustomField2?: string,
  companyCustomField3?: string,
  companyCustomField4?: string,
  companyCustomField5?: string,
  searchText?: string
  number?: number
  orderBy?: string
  orderDir?: string
  passengerName?: string
  requesterName?: string
  simplifyForAssignments?: boolean
}) => {
  let argString = ''
  if (id) {
    argString = '/' + id
  } else if (ids) {
    argString = '?ids=' + ids
  } else {
    argString = `?businessProfileId=${businessProfileId}&page=${page || 1}${resultsPerPage !== undefined ? `&maxResults=${resultsPerPage}` : ''
      }`
    if (requestStatuses) {
      argString += `&requestStatuses=${requestStatuses.toString()}`
    }

    if (vehicleIds) {
      argString += `&vehicleIds=${vehicleIds.toString()}`
    }

    if (driverIds) {
      argString += `&driverIds=${driverIds.toString()}`
    }

    if (clientIds) {
      argString += `&clientIds=${clientIds.toString()}`
    }

    if (supplierIds) {
      argString += `&supplierIds=${supplierIds.toString()}`
    }

    if (tariffServiceIds) {
      argString += `&tariffServiceIds=${tariffServiceIds.toString()}`
    }

    if (startDate) {
      let formattedDate = moment.utc(startDate).utcOffset(120).format('yyyy-MM-DD')
      argString += `&startDate=${formattedDate}`
    }

    if (stopDate) {
      let formattedDate = moment.utc(stopDate).utcOffset(120).format('yyyy-MM-DD')
      argString += `&stopDate=${formattedDate}`
    }

    if (paymentTypes) {
      argString += `&paymentTypes=${paymentTypes.toString()}`
    }

    if (revenueCenters) {
      argString += `&revenueCenters=${revenueCenters.toString()}`
    }

    if (searchText) {
      argString += `&searchText=${searchText}`
    }

    if (pricingTransferTypes) {
      argString += `&pricingTransferTypes=${pricingTransferTypes.toString()}`
    }

    if (invoiceNumber) {
      argString += `&invoiceNumber=${invoiceNumber}`
    }

    if (companyCustomField1) {
      argString += `&companyCustomField1=${companyCustomField1}`
    }

    if (companyCustomField2) {
      argString += `&companyCustomField2=${companyCustomField2}`
    }

    if (companyCustomField3) {
      argString += `&companyCustomField3=${companyCustomField3}`
    }

    if (companyCustomField4) {
      argString += `&companyCustomField4=${companyCustomField4}`
    }

    if (companyCustomField5) {
      argString += `&companyCustomField5=${companyCustomField5}`
    }

    if (!!number) {
      argString += `&number=${number}`
    }

    if (!!passengerName) {
      argString += `&passengerName=${passengerName}`
    }

    if (!!requesterName) {
      argString += `&requesterName=${requesterName}`
    }

    argString += `&orderBy=${orderBy || 'id'}`
    argString += `&orderDir=${orderDir || 'desc'}`
  }

  if (simplifyForAssignments) {
    argString = '/assignments/v2' + argString + '&detailed=false'
  }

  if (countMode) {
    argString = '/count' + argString
  }

  return easytrackAxios
    .get(`${EASYTRACK_API_URL}/transport-requests${argString}`)
    .catch((err) => err)
}

// Get distance between two points
export const getDistanceRequest = ({
  originCoordinates,
  destinationCoordinates,
  intermediaryPoints,
  departureDate,
}: {
  originCoordinates: string
  destinationCoordinates: string
  intermediaryPoints: Array<{
    gps: string
  }>
  departureDate: Date
}) => {
  const payload = {
    originCoordinates,
    destinationCoordinates,
    intermediaryPoints,
    departureDate,
    eventSource: 'DRIVER_READY_DISTANCE_CHECK',
    searchMethod: 'WAZE_SEARCH',
  }

  return easytrackAxios.post(`${EASYTRACK_API_URL}/distances`, payload).catch((err) => err)
}

export const getTariffBreakdownForTransport = async ({
  transport,
  businessProfileId,
}: {
  transport: TransportType
  businessProfileId: string | number
}) => {
  if (!transport || !transport.transports) {
    return null
  }
  try {
    if (!businessProfileId) {
      throw { status: 404, message: 'business profile id not found' }
    }
    const voucher = getClientVoucherFromTransport({ transport })
    const tariffBreakdownRequest = await getTariffBreakdown({
      payload: getTransportForDetails({ transport, voucher }),
      businessProfileId,
    })
    const futureTariffBreakdown = tariffBreakdownRequest?.data
    if (!futureTariffBreakdown) {
      throw { status: 404, message: 'Tariff Breakdown not found' }
    }
    return futureTariffBreakdown
  } catch (err: any) {
    console.error(err)
    return null
  }
}

export const getTariffBreakdown = ({
  payload,
  businessProfileId,
}: {
  payload: TransportForDetails
  businessProfileId: string | number
}) => {
  if (!payload || !businessProfileId) {
    logError({ name: 'Missing arguments for transport details request', error: {}, type: 'error' })
    return null
  }

  return easytrackAxios
    .post(`${EASYTRACK_API_URL}/business-profiles/${businessProfileId}/pricing`, {
      ...payload,
    })
    .catch((err) => err)
}

type TariffRentPayloadType = {
  supplier_id: number,
  plan_id: number,
  vehicle_class_id: number,
  start_date: string,
  end_date: string,
  insurance_id: number,
  extra_costs: { id: number, quantity: number },
  discount?: number
  dislocation: string
}

export const sendEmailRequestFromTransport = async ({
  transport,
  additionalEffectForSuccess = () => { },
}: {
  transport: TransportType
  additionalEffectForSuccess?: Function
}) => {
  const transportRequestId = transport?.idTransportRequest || transport?.id
  if (!transportRequestId) {
    return toast.error('Invalid transport request!')
  }
  const emailsForTO = [
    ...getUniqueEmailListWithNewEmails({
      emailList: [],
      newEmails: transport.requestorEmail || transport.requesterEmail,
    }),
  ].filter((email: string) => isValidEmail(email))
  if (emailsForTO.length === 0) {
    return toast.error("This transport request doesn't have valid requestor emails!")
  }
  const emailsForCC = [
    ...getUniqueEmailListWithNewEmails({
      emailList: [],
      newEmails: transport.passengerEmail,
    }),
  ].filter((email: string) => isValidEmail(email))

  toast.info('Sending email...')
  const result = await sendConfirmationEmailRequest({
    emailsForTO,
    emailsForCC,
    transportRequestId,
  })

  if (result?.data?.success) {
    additionalEffectForSuccess()
    toast.success('Email sent successfully')
  } else {
    toast.error('Error sending email')
  }
}

// Send confirmation email
export const sendConfirmationEmailRequest = ({
  transportRequestId,
  emailsForTO,
  emailsForCC,
}: {
  transportRequestId: number
  emailsForTO: string[]
  emailsForCC: string[]
}) => {
  const payload = {
    to: emailsForTO,
    cc: emailsForCC,
  }

  return easytrackAxios
    .post(`${EASYTRACK_API_URL}/transport-requests/${transportRequestId}/emails/v2`, payload)
    .catch((err) => err)
}

export const addInvoiceNumberForTransports = ({
  invoiceNumber,
  transportIds,
}: {
  invoiceNumber: string
  transportIds: number[]
}) => {
  const payload = {
    ids: transportIds,
    invoiceNumber,
  }
  return easytrackAxios
    .put(`${EASYTRACK_API_URL}/transport-requests/batch`, payload)
    .catch((err) => err)
}

export const getTariffServices = async ({
  businessProfileId,
  onlyEnabled = false,
}: {
  businessProfileId: string | number
  onlyEnabled?: boolean
}) => {
  const result = await easytrackAxios
    .get(`${EASYTRACK_API_URL}/tariff-services?businessProfileId=${businessProfileId}`)
    .catch((err) => err)
  if (Boolean(result?.data) && Array.isArray(result?.data)) {
    const futureTariffServices = (result.data || []).filter(
      (tariffService: TariffServiceType) => !onlyEnabled || tariffService.enabled
    )
    return futureTariffServices
  } else {
    return []
  }
}

export const importTransportsRequest = async (businessProfileId: number, entries: ImportTransportEntry[]) => {
  const payload = { businessProfileId, entries }
  const config = { headers: { 'ACCEPT': 'application/json' } }
  const result = await easytrackRequest({ method: RequestMethod.POST, route: '/transport-requests/batch', payload, config })
  if (!result || result.error) {
    return { error: true, message: result?.message, row: result?.data?.row }
  }
  return { error: false }
}

export type CompleteRequestPayloadType = {
  businessProfileId: number
  processingDate: Date
  comment?: string,
  passengers: number
  destination: string
  paymentStatus: string
  paymentNote: string
  transferType: string
  startOdometer?: number
  odometer: number,
  numberOfStops: number,
  intermediaryPoints: POI[]
  estimatedWait?: number
}

export type CompleteFormType = {
  businessProfileId: number
  estimatedWait: number
  startOdometer?: number
  endOdometer?: number
}

export const convertTransportFormDataToCompleteRequest = ({
  formData,
  transport
}: {
  formData: CompleteFormType
  transport: any
}): CompleteRequestPayloadType => ({
  businessProfileId: formData?.businessProfileId,
  processingDate: new Date(),
  passengers: transport.passengers,
  destination: transport.destination,
  paymentStatus: transport.paymentStatus,
  paymentNote: transport.paymentNote,
  transferType: transport.transferType,
  startOdometer: formData.startOdometer,
  odometer: formData.endOdometer,
  numberOfStops: (transport.intermediaryPoints || []).length,
  intermediaryPoints: transport.intermediaryPoints || [],
  estimatedWait: formData.estimatedWait

})

export const completeTransportRequest = async ({
  transportRequestId,
  payload
}: {
  transportRequestId: number
  payload: CompleteRequestPayloadType
}) => {
  const result = await easytrackAxios
    .post(`${EASYTRACK_API_URL}/transport-requests/${transportRequestId}/complete`, payload)
    .catch((err) => err)
  return result
}

export const getTransportById = async (transportId: number) => {
  const result = await easytrackAxios
    .get(`${EASYTRACK_API_URL}/transport-requests/${transportId}`)
    .catch((err) => err)
  if (!result?.data) {
    return { error: true, transport: null }
  }
  const futureTransport = result.data
  return { error: false, transport: futureTransport }
}

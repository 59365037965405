import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import easytrackAxios from '../../../../setup/easytrack.axios';
import { TransportPvTempType } from '../../../../types/document.types';
import { KTSVG } from '../../../../_metronic/helpers';
import { logError } from '../../../../utils/sentry.utils';
import { createURLForPDFFromBase64, downloadFile } from '../utils';
import ConfirmModal from './ConfirmModal';
import { EASYTRACK_API_URL } from '../../../../constants/api.constants';
import PdfViewer from './PdfViewer';

const PvPreview = ({
  handleCloseViewPVModal,
  viewPVModal,
  setProcessingFiles,
  pv,
  pvsTempObjectList,
  updateListWithNewPVs,
}: {
  handleCloseViewPVModal: () => void;
  viewPVModal: boolean;
  setProcessingFiles: (loadingFlag: boolean) => void;
  pv: TransportPvTempType;
  pvsTempObjectList: TransportPvTempType[];
  updateListWithNewPVs: (newEntries: TransportPvTempType[]) => void;
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const pvURL = useMemo(() => createURLForPDFFromBase64(pv.content), [pv.content]);

  const handleDelete = async () => {
    const deletePvUrl = `${EASYTRACK_API_URL}/transport-requests/${pv?.transportRequestId}/pvs/${pv?.id}`;
    await easytrackAxios
      .delete(deletePvUrl)
      .then((res) => {
        if (res.status !== 200) {
          toast.error('ERROR: The pv did not delete.');
        } else {
          toast.success('The pv were succesfully deleted. Reloading pvs...');
          const unprocessedPVs: TransportPvTempType[] = pvsTempObjectList.filter(
            (el) => el.id !== pv?.id
          );
          updateListWithNewPVs(unprocessedPVs);
          setProcessingFiles(true);
        }
        handleCloseViewPVModal();
        setShowConfirmModal(false);
      })
      .catch((err) => {
        toast.error('ERROR: An unexpected error has occurred. Please try again later.');

        logError({ name: 'Error delete pv', error: { ...err }, type: 'error' });

        handleCloseViewPVModal();
        setShowConfirmModal(false);
      });
  };

  return (
    <>
      <Modal
        size='lg'
        className='photoModal'
        onHide={handleCloseViewPVModal}
        style={{ width: '100%' }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={viewPVModal}
        animation={true}
      >
        <div
          style={{
            position: 'absolute',
            top: 15,
            right: -10,
            flex: 1,
            flexDirection: 'column',
            display: 'flex',
          }}
        >
          <button
            onClick={handleCloseViewPVModal}
            className='btn btn-icon  btn-active-color-danger btn-sm me-5 mb-5'
          >
            <KTSVG
              path='/media/icons/duotone/Interface/Close-Square.svg'
              className='svg-icon-3tx svg-icon-primary clickable'
            />
          </button>
          <button
            onClick={() => window.open(pvURL, '_blank')}
            className='btn btn-icon btn-active-color-danger btn-sm me-10 mb-5'
          >
            <KTSVG
              path='/media/icons/duotone/Communication/Share.svg'
              className='svg-icon-2tx svg-icon-primary clickable'
            />
          </button>
          <button
            onClick={() => {
              downloadFile(pv.content, pv.createdDate, 'pdf');
              handleCloseViewPVModal();
            }}
            className='btn btn-icon btn-active-color-danger btn-sm me-10 mb-10'
          >
            <KTSVG
              path={'/media/icons/duotone/Files/Download.svg'}
              className='svg-icon-2tx svg-icon-primary clickable'
            />
          </button>
          <button
            style={{ marginTop: -15 }}
            onClick={() => setShowConfirmModal(true)}
            className='btn btn-icon btn-active-color-danger btn-sm me-10 mb-10'
          >
            <KTSVG
              path='/media/icons/duotone/General/Trash.svg'
              className='svg-icon-3tx svg-icon-primary clickable'
            />
          </button>
        </div>
        <PdfViewer pdfUrl={pvURL} />
      </Modal>
      <ConfirmModal
        confirmAction={handleDelete}
        title='Confirm Delete'
        subtitle='Are you sure you want to delete this image?'
        visible={showConfirmModal}
        setVisible={setShowConfirmModal}
      />
    </>
  );
};

export default PvPreview;

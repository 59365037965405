import { FC, useEffect, useState } from 'react'
import Table from './Table'
import { RentPlanVehicleClassModel, emptyRentPlanVehicleClass } from '../../../app/modules/rent-plans/models/RentPlanVehicleClassModel'
import { createRentAutoClasses, deleteRentVehicleClass, getCarClasses, getRentPlanVehicleClass, putRentVehicleClass } from '../../../setup/axios/rent.request'
import { toast } from 'react-toastify'
import { updateField } from '../../../utils/state.utils'
import { getStringOfEntity } from '../../../utils/string.utils'
import { useHistory, useParams } from 'react-router-dom'
import { CarClassModel, fuelTypeList, transmissionTypeList } from '../../../app/modules/auth/models/CarClassModel'

const rentVehicleClassFields = [
    'name',
]

const getExtrasLabel = () => "Vehicle";
const compoundUniqueKeyVehicleClass = ['name']

const RentVehicleClassTable: FC<{}> = ({ }) => {

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [rentPlanVehicleClass, setRentPlanVehicleClass] = useState<RentPlanVehicleClassModel[]>([])
  const [cs, setcs] = useState<CarClassModel[]>([])

  const { id, companyId } = useParams<any>()
  const history = useHistory();
  useEffect(() => {
    getCarClasses().then(s => setcs(s))
  }, [])
  const rentPlanVehicleClassProperties = [
    {
        name: 'name',
        getDisplayLabel: (name: string, row: any) => {
          return row.image_url ? <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom:10}}> <img src={row.image_url} style={{ marginRight: 14, width: 40, height: 40, marginBottom: 20 }} />{row.name}</div> : <div>{row.name}</div>
        },
        label: 'Class name',
        type: 'select',
        dynamicOptions: cs
      },
      {
        name: 'description',
        getDisplayLabel: (description: string) => description,
        label: 'Description'
      },
      {
        name: 'vehicle_type',
        getDisplayLabel: (vehicle_type: string) => vehicle_type,
        label: 'Vehicle type'
      },
      {
        name: 'number_of_doors',
        getDisplayLabel: (number_of_doors: string) => number_of_doors,
        label: 'No of doors'
      },
      {
        name: 'luggage_capacity',
        getDisplayLabel: (luggage_capacity: string) => luggage_capacity,
        label: 'Luggage capacity'
      },
      {
        name: 'fuel_type',
        getDisplayLabel: (fuel_type: string[]) => fuel_type.toString().split(',').join('-'),
        label: 'Fuel type',
        type: 'select',
        isMulti: true,
        editable: false,
        addable: false
      },
      {
        name: 'transmission_type',
        getDisplayLabel: (transmission_type: string[]) => transmission_type.toString().split(',').join('-'),
        label: 'Transmission type',
        type: 'select',
        isMulti: true,
        editable: false,
        addable: false
      }
]

const RentPlanVehicleClassFieldToProperty = (
  field: any,
  rentPlanVehicleClasses: RentPlanVehicleClassModel,
  setRentPlanVehicleClasses: Function
) => {
  let fieldName = field.name
  const updateExtrasListElemEvent = (e: any) =>
    updateField({ value: e, fieldName: fieldName, setObject: setRentPlanVehicleClasses })
  const rentPlanVehicleClassNamesArray = rentPlanVehicleClass.map(r => r.name);
  const fieldsToPanelPropertiesMapping = {
    name: {
      simpleValue: rentPlanVehicleClasses.name || '',
      options: (field.dynamicOptions ?? []).filter(option => !rentPlanVehicleClassNamesArray.includes(option.name)).map(vehicle_class => ({name: vehicle_class.name, title: vehicle_class.name})),
      getOptionValue: (e: any) => e.name || '',
      getOptionLabel: (e: any) => e.title || '',
      onChange: updateExtrasListElemEvent,
    },
    description: { value: getStringOfEntity({ entity: rentPlanVehicleClasses.description }) },
    vehicle_type: { value: getStringOfEntity({ entity: rentPlanVehicleClasses.vehicle_type }) },
    number_of_doors: { value: getStringOfEntity({ entity: rentPlanVehicleClasses.number_of_doors }) },
    transmission_type: {
      simpleValue: rentPlanVehicleClasses.transmission_type || [],
      options: transmissionTypeList || [],
      getOptionValue: (e: any) => e.name || '',
      getOptionLabel: (e: any) => e.name || '',
      onChange: updateExtrasListElemEvent,
    },
    fuel_type: {
      simpleValue: rentPlanVehicleClasses.fuel_type || [],
      options: fuelTypeList || [],
      getOptionValue: (e: any) => e.name || '',
      getOptionLabel: (e: any) => e.name || '',
      onChange: updateExtrasListElemEvent,
    },
    luggage_capacity: {
      simpleValue: rentPlanVehicleClasses.luggage_capacity || '',
      options: [{name: "4", title: "4"}, {name: "5", title: "5"}, {name: "6", title: "6"},  {name: "7", title: "7"}] || [],
      getOptionValue: (e: any) => e.name || '',
      getOptionLabel: (e: any) => e.title || '',
      onChange: updateExtrasListElemEvent,
    },
  }

  return (fieldsToPanelPropertiesMapping as any)[fieldName]
}

const fetchVehicleClasses = (loading: boolean) => {
  if (loading) {
    setIsLoading(true);
  }
  getRentPlanVehicleClass(id).then((futureRentPlanVehicleClass: RentPlanVehicleClassModel[] | null) => {
    if (!futureRentPlanVehicleClass) {
      return toast.error('The Rent Plan Vehicle Class failed to load!')
    }
    setRentPlanVehicleClass(futureRentPlanVehicleClass)
    setIsLoading(false)
  })
}

  useEffect(() => {
    fetchVehicleClasses(false);
  }, [])

    return (
      <>
        <Table
          tableLabel={'Auto Classes'}
          properties={rentPlanVehicleClassProperties}
          fieldToProperty={RentPlanVehicleClassFieldToProperty}
          entries={rentPlanVehicleClass}
          setEntries={setRentPlanVehicleClass}
          emptyEntry={emptyRentPlanVehicleClass}
          getEntryLabel={getExtrasLabel}
          requiredFields={rentVehicleClassFields}
          compoundUniqueKey={compoundUniqueKeyVehicleClass}
          deleteEndpoint={deleteRentVehicleClass}
          editEndpoint={putRentVehicleClass}
          isVehicleClass={true}
          createEndpoint={async (value) => {
            const response = await createRentAutoClasses(value, id, companyId)
              if (response) {
                fetchVehicleClasses(true)
              }
              return response;
            }
          }
          isPlanCreated= {!!id}
          addEntryLabel= "Add Class"
        />
      </>
    )

 }

export default RentVehicleClassTable

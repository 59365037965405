/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from "react";
import { DeleteModal } from "../../../../components/delete-modal/DeleteModal.component";
import { KTSVG } from "../../../../_metronic/helpers";
import { PackageContext } from "../../../../context/packages.context";
import { ServiceType } from "../../../../types/company.types";
import AddEditModal from "../../../../components/add-edit-modal/AddEditModal.component";
import { defaultServiceFields } from "../../../../utils/companies.utils";

const ServiceRow = ({
  service,
  deleteService,
  number
}: {
  service: ServiceType,
  deleteService: any,
  number:number
}) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const { createAndUpdateService } = useContext<any>(PackageContext);

  return (
    <>
      <DeleteModal
        deleteModalVisible={deleteModalVisible}
        setDeleteModalVisible={setDeleteModalVisible}
        deleteMethod={() => { deleteService({ id: service.id}); setDeleteModalVisible(false); }}
        textModal={`The service ${service.name}, will be deleted forever!`}
      />
      <AddEditModal 
        data={service}
        addEditModalVisible={editModalVisible}
        setAddEditModalVisible={setEditModalVisible}
        type='service'
        fields={defaultServiceFields}
        saveMethod={createAndUpdateService}
      />
      {/* <AddEditModal service={service} addEditModalVisible={editModalVisible} setAddEditModalVisible={setEditModalVisible} /> */}
      <tr key={service.id}>
        <td>
          <div className='d-flex align-items-center'>

            <div className='d-flex align-items-center'>
              <span className='text-dark fs-6'>{number + 1 }</span>
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>

            <div className='d-flex align-items-center'>
              <span className='text-dark fs-6'>{service?.name}</span>
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <span className='text-dark fs-6'>{service.additional_info}</span>
          </div>
        </td>
        {/* <td className='text-end'>
          <div className='d-flex align-items-center'>
            <span className='text-dark fs-6'>{service.has_offer_mark == '1' ? 'Check' : 'Text'}</span>
          </div>
        </td> */}
        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <a
              onClick={() => {
                setEditModalVisible(true);
              }}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <KTSVG path='/media/icons/duotone/Communication/Write.svg' className='svg-icon-3' />
            </a>
            <a
              onClick={async () => {
                setDeleteModalVisible(true)
              }}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            >
              <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
            </a>
          </div>
        </td>
      </tr>
    </>
  )
}


export default ServiceRow;
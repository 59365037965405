import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import * as XLS from 'xlsx'
import ModalPortal from '../../../../_metronic/layout/components/modal/modal-portal'
import Pagination from '../../transport-requests/Pagination'
import SendEmailModal from './modals/SendEmailModal'
import { getSubscriptionsRequest } from '../Subscriptions'
import { strToBool } from '../../../../utils/string.utils'
import { UserContext, UserRole } from '../../../../context/user.context'
import { FlatSubscriptionType } from '../../../../types/subscription.types'
import SendStripePaymentEmailModal from './modals/SendStripePaymentEmailModal'
import { FlexibleObject } from '../../../../utils/state.utils'
import DeleteModal from './modals/DeleteModal'
import DeleteAllModal from './modals/DeleteAllModal'
import SendMultipleEmailsModal from './modals/SendMultipleEmailsModal'
import { toast } from 'react-toastify'
import Actions from './Actions'
import { baseTableButtonStyle } from '../../../../utils/style.utils'
import ViewSubscriptionModal from './modals/ViewSubscriptionModal'
import { LoadingCard } from '../../../../_metronic/layout/components/loading/Loading'

export enum ModalType {
  Delete = "delete", SendEmail = "sendEmail", SendStripePaymentEmail = "sendStripePaymentEmail",
  DeleteAll = "deleteAll", SendEmailMultiple = "sendEmailMultiple", View = "view"
}

const getModalByModalType = ({
  modalType,
  subscription,
  selectedSubscriptions,
  setModalVisibleWithEffect,
  additionalEffects,
}: {
  subscription: FlatSubscriptionType,
  selectedSubscriptions?: FlatSubscriptionType[],
  setModalVisibleWithEffect: (_: boolean) => void,
  modalType: ModalType
  additionalEffects?: FlexibleObject
}) => {
  switch (modalType) {
    case ModalType.Delete: {
      return <DeleteModal
        subscription={subscription}
        setModalVisible={setModalVisibleWithEffect}
        triggerAdditionalEffect={additionalEffects.deleteSubscriptionEffect}
      />
    }
    case ModalType.DeleteAll: {
      return <DeleteAllModal
        subscriptions={selectedSubscriptions}
        setModalVisible={setModalVisibleWithEffect}
        triggerAdditionalEffect={additionalEffects.deleteSubscriptionEffect}
      />
    }
    case ModalType.SendEmail: {
      return <SendEmailModal subscription={subscription} setModalVisible={setModalVisibleWithEffect} />
    }
    case ModalType.SendEmailMultiple: {
      return <SendMultipleEmailsModal subscriptions={selectedSubscriptions} setModalVisible={setModalVisibleWithEffect} />
    }
    case ModalType.SendStripePaymentEmail: {
      return <SendStripePaymentEmailModal subscription={subscription} setModalVisible={setModalVisibleWithEffect} />
    }
    case ModalType.View: {
      return <ViewSubscriptionModal subscription={subscription} modalVisible={true} setModalVisible={setModalVisibleWithEffect} />
    }
    default:
      return null;
  }
}

type SubscriptionsTableProps = {
  subscriptions: FlatSubscriptionType[]
  pageCount: number
  currentPage: number
  resultsPerPage: number
  setCurrentPage: (currenPage: number) => void
  setResultsPerPage: (resultsPerPage: number) => void,
  searchInput: string
  startDate: string
  endDate: string
  companyIds: string
  setTriggerSubscriptionUpdate: Function
}

const SubscriptionsTable: React.FC<SubscriptionsTableProps> = ({
  subscriptions,
  pageCount,
  currentPage,
  resultsPerPage,
  setCurrentPage,
  setResultsPerPage,
  searchInput,
  startDate,
  endDate,
  companyIds,
  setTriggerSubscriptionUpdate
}) => {
  // modalVisible can be deduced from modalType - if it's not null, then modalVisible is true
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [modalType, setModalType] = useState<ModalType | null>(null)
  const [subscription, setSubscription] = useState<FlatSubscriptionType | null>(null)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [selectedSubscriptions, setSelectedSubscriptions] = useState<any[]>([])
  const [allSubscriptionsSelected, setAllSubscriptionsSelected] = useState<boolean>(false)
  const exportTableData = useMemo(() => ({ data: [], filename: '' }), [])
  const { user: { username, firstName, lastName, role: { slug: userRole } } } = useContext<any>(UserContext)
  const [isXLSDataLoading, setIsXLSDataLoading] = useState(false);
  const hasAdminAccess = useMemo(() => userRole === UserRole.AdminSubscriptions || userRole === UserRole.Admin, [userRole])
  const areActionButtonsVisible = Boolean(selectedSubscriptions)
    && Array.isArray(selectedSubscriptions)
    && !modalVisible
    && !isLoading
    && selectedSubscriptions.length > 0

  const onPressActionButton = useCallback(({
    subscription,
    modalType
  }: {
    subscription: FlatSubscriptionType,
    modalType: ModalType
  }) => {
    setModalType(modalType)
    setSubscription(subscription)
    setModalVisible(true)
  }, [])

  const prepareAllDataForAction = async () => {
    if (allSubscriptionsSelected) {
      setIsLoading(true)
      toast.info("Fetching all data for action! Please wait!")
      const res = await getSubscriptionsRequest({ searchInput: searchInput, resultsPerPage: pageCount * resultsPerPage, startDate, endDate, companyIds });
      if (!res || res.error) {
        return
      }
      setSelectedSubscriptions(res?.data || selectedSubscriptions)
      setIsLoading(false)
    }
  }

  const setModalVisibleWithEffect = useCallback((visible: boolean) => {
    setModalVisible(visible)
    setModalType(null)
    setSubscription(null)
  }, [])

  useEffect(() => {
    setSelectedSubscriptions([])
  }, [])

  // Table Data for XLS
   const convertCsvToExcelBuffer = (csvString: string) => {
    const arrayOfArrayCsv = csvString.split("\n").map((row: string) => {
      return row.replaceAll('\"', '').split(",")
    });
    const xls = generateXLS(arrayOfArrayCsv)
    return xls
  }

  const createExportTableData = async () => {
    setIsXLSDataLoading(true);

    if (allSubscriptionsSelected) {
      const res: unknown = await getSubscriptionsRequest({ searchInput: searchInput, resultsPerPage: pageCount * resultsPerPage, startDate, endDate, companyIds, exportExcel: true });
      setIsXLSDataLoading(false)
      return convertCsvToExcelBuffer(res as string);
    } else {
      const data = []
      const headerRow = [
        'ID',
        'Package',
        'Dealer',
        'User',
        'Beneficiary',
        'Company',
        'Client Email',
        'Nr telefon',
        'CUI/CNP',
        'Nr inmatriculare',
        'VIN',
        'Brand',
        'Model',
        'Vechime masina',
        'Data creare ',
        'Data activare',
        'Data expirare',
        'Status',
        'Tip plata',
        'Status Stripe',
        'Interfata folosita pentru creare',
        'Abonat la newsletter',
      ]
  
      data.push(headerRow)
      console.log(selectedSubscriptions)
      selectedSubscriptions.forEach((sub: FlatSubscriptionType) => {
        const subscriptionsData = [
          sub?.id || '', 
          sub?.package_name || '',
          sub?.company_name || '',
          `${sub?.user_first_name} ${sub.user_last_name}`,
          sub?.client_name || '',
          sub?.main_company_name || sub?.company_name || '',
          sub?.client_email || '',
          sub?.client_phone || '',
          sub?.client_identity_code || '',
          getNumber(sub),
          sub?.vin || '',
          sub?.brand || '',
          sub?.model || '',
          sub?.vehicle_age === 'under_10_years' ? 'Pana la 10 ani' : (sub?.vehicle_age === 'under_16_years' ? 'Intre 11 - 15 ani' : ''),
          moment(sub?.created_at).utc().format('YYYY-MM-DD HH:mm:ss') || '',
          sub?.activated_at || 'missing',
          sub?.expiration_date || 'missing',
          sub?.status || '',
          sub?.payment_type || '',
          sub?.stripe_status || '',
          sub?.campaign_name || '',
          sub?.interface_type || '',
          sub?.newsletter === true ? 'Da' : 'Nu'
        ];
        data.push(subscriptionsData)
      })
      setIsXLSDataLoading(false)
      return generateXLS(data)
    }
  }

  const generateXLS = (data) => {
    const wb = XLS.utils.book_new()
    const ws = XLS.utils.aoa_to_sheet(data)

    XLS.utils.book_append_sheet(wb, ws, 'Subscriptions')
    const xls = XLS.write(wb, { type: 'buffer', bookType: 'xls' })
    return xls
  }

  const exportXLS = async () => {
    if (Boolean(selectedSubscriptions) &&
      Array.isArray(selectedSubscriptions) &&
      selectedSubscriptions.length > 0) {
        const todayDate = new Date()
          .toLocaleDateString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          .replace(/\//g, '-')
        const xls = await createExportTableData();
        const blob = new Blob([xls], { type: 'application/vnd.ms-excel' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `${todayDate}_${username || firstName + '-' + lastName}.xls`
        link.click()
    }
  }

  const areSubscriptionsSelected = useMemo(() => {
    return subscriptions?.length > 0 &&
      subscriptions.every((subscription) => {
        return selectedSubscriptions.some((subs) => subs.id === subscription.id)
      })
  }, [selectedSubscriptions, subscriptions])

  const getNumber = (sub: FlatSubscriptionType) => {
    if (strToBool(sub?.red_number)) {
      return 'Numar rosu'
    }
    return sub?.registration_nr || ''
  }

  const deleteSubscriptionEffect = useCallback(() => setTriggerSubscriptionUpdate(true), [])

  if (isXLSDataLoading) {
    return <LoadingCard />;
  }

  return (
    <>
      {modalVisible ? (
        <ModalPortal setVisible={setModalVisibleWithEffect} visible={modalVisible} hasExitButton={false}>
          {getModalByModalType({ modalType, subscription, selectedSubscriptions, setModalVisibleWithEffect, additionalEffects: { deleteSubscriptionEffect } })}
        </ModalPortal>
      ) : null}
      {areActionButtonsVisible ? (
        <div style={{ position: 'fixed', right: '20px', bottom: '40px', display: 'flex', flexDirection: 'column', rowGap: '4px', zIndex: '1' }}>
          <button
            style={{ ...baseTableButtonStyle, background: '#6C757D' }}
            onClick={() => {
              setAllSubscriptionsSelected(false);
              setSelectedSubscriptions([])
            }}
          >
            Deselect all
          </button>
          <button
            style={{ ...baseTableButtonStyle, background: '#009EF7' }}
            onClick={async () => {
              setModalType(ModalType.SendEmailMultiple)
              await prepareAllDataForAction()
              setModalVisible(true)
            }}
          >
            Send Emails
          </button>
          <button
            style={{ ...baseTableButtonStyle, background: '#1D6F42' }}
            onClick={exportXLS}
          >
            Export as XLS
          </button>
          {hasAdminAccess ?
            <button
              style={{ ...baseTableButtonStyle, background: '#f40f02' }}
              onClick={async () => {
                setModalType(ModalType.DeleteAll)
                await prepareAllDataForAction()
                setModalVisible(true)
              }}
            >
              Delete all
            </button> : null
          }
        </div>
      ) : null}
      <div className={`card mb-7`}>
        <div className='card-header pt-5 border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Subscriptions</span>
          </h3>
        </div>
        {areSubscriptionsSelected && (
          <div className='fs-6 h-35px d-flex align-items-center justify-content-center bg-secondary'>
            {allSubscriptionsSelected ? (
              <span>
                All {pageCount} pages are selected.
                <button
                  onClick={() => {
                    setAllSubscriptionsSelected(false)
                    setSelectedSubscriptions([])
                  }}
                  className='ms-5 btn text-primary fw-bold'
                >
                  Clear selection
                </button>
              </span>
            ) : (
              <span>
                All <span>{subscriptions.length}</span> subscriptions on this page are selected.

                <button
                  onClick={() => setAllSubscriptionsSelected(true)}
                  className='ms-5 btn text-primary fw-bold'
                >
                  Select all {pageCount} pages
                </button>

              </span>
            )}
          </div>
        )}
        <div className='card-body py-3'>
          <div className='table-responsive transport-table'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-4 gy-3'>
              <thead>
                <tr className='fw-bolder'>
                  <th style={{ maxWidth: '125px', minWidth: '115px' }}>Actions</th>
                  <th className='w-25px ps-5'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        name='select'
                        className='clickable form-check-input'
                        type='checkbox'
                        checked={selectedSubscriptions === subscriptions}
                        onChange={(e) => {
                          const isChecked = e.target.checked
                          if (isChecked) {
                            setSelectedSubscriptions(subscriptions)
                          } else {
                            setSelectedSubscriptions([])
                          }
                        }}
                      />
                    </div>
                  </th>
                  <th>Id</th>
                  <th>Package</th>
                  <th>Beneficiary</th>
                  <th>Registration Number</th>
                  <th>Activation</th>
                  <th>Expiration</th>
                  <th>Status</th>
                  <th>Payment Type</th>
                  <th>Stripe Status</th>
                  <th>Discount</th>
                  <th>Price paid</th>
                  <th>Dealer</th>
                  <th>User</th>
                  <th>VIN</th>
                  <th>Company</th>
                  <th>Client Email</th>
                  <th>Brand</th>
                  <th>Model</th>
                  <th>Newsletter</th>
                  <th>Campaign</th>
                  <th>Interface type</th>
                </tr>
              </thead>
              <tbody
                style={{
                  display: 'table-row-group',
                }}
              >
                {subscriptions.map((sub) => (
                  <tr key={sub.id}>
                    <td>
                      <Actions
                        hasAdminAccess={hasAdminAccess}
                        subscription={sub}
                        onPressActionButton={onPressActionButton}
                      />
                    </td>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          name='Select'
                          className='clickable form-check-input'
                          type='checkbox'
                          checked={
                            selectedSubscriptions.find((s: FlatSubscriptionType) => s.id === sub.id)
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            const isChecked = e.target.checked
                            if (isChecked) {
                              setSelectedSubscriptions([...selectedSubscriptions, sub])
                            } else {
                              setSelectedSubscriptions(
                                selectedSubscriptions.filter(
                                  (s: FlatSubscriptionType) => s.id !== sub.id
                                )
                              )
                            }
                          }}
                        />
                      </div>
                    </td>
                    <td>{sub?.id || ''}</td>
                    <td>{sub?.package_name || ''}</td>
                    <td>{sub?.client_name || ''}</td>
                    <td>{getNumber(sub)}</td>
                    <td>
                      {sub?.activated_at
                        ? new Date(sub?.activated_at)
                          .toLocaleDateString('en-GB')
                          .replace(/\//g, '-')
                        : 'missing'}
                    </td>
                    <td>
                      {sub?.expiration_date
                        ? new Date(sub?.expiration_date)
                          .toLocaleDateString('en-GB')
                          .replace(/\//g, '-')
                        : 'missing'}
                    </td>
                    <td>{sub?.status || ''}</td>
                    <td>{sub?.payment_type || ''}</td>
                    <td>{sub?.stripe_status || ''}</td>
                    <td>{sub?.discount || ''}</td>
                    <td>{sub?.price_paid || ''}</td>
                    <td>{sub?.company_name || ''}</td>
                    <td>{`${sub?.user_first_name} ${sub.user_last_name}`}</td>
                    <th>{sub?.vin || ''}</th>
                    <th>{sub?.main_company_name || sub?.company_name || ''}</th>
                    <th>{sub?.client_email || ''}</th>
                    <th>{sub?.brand || ''}</th>
                    <th>{sub?.model || ''}</th>
                    <th>{sub?.newsletter ? 'Yes' : 'No'}</th>
                    <th>{sub?.campaign_name}</th>
                    <th>{sub?.interface_type}</th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            pageCount={pageCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            resultsPerPage={resultsPerPage}
            setResultsPerPage={setResultsPerPage}
          />
        </div>
      </div>
    </>
  )
}

export default SubscriptionsTable

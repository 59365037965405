import { EASYTRACK_API_URL } from '../constants/api.constants'
import easytrackAxios from '../setup/easytrack.axios'
import { toast } from 'react-toastify'

export const extractCoordsObjFromGps = ({ gps }: { gps: string }): google.maps.LatLng => {
  if (!Boolean(gps)) {
    return null
  }
  let coords = gps.split(',')
  if (!Boolean(coords[0]) || !Boolean(coords[1])) {
    return null
  }
  let coordsObj = { lat: parseFloat(coords[0].trim()), lng: parseFloat(coords[1].trim()) }
  if (isNaN(coordsObj.lat) || isNaN(coordsObj.lng)) {
    return null
  }
  return new google.maps.LatLng(coordsObj.lat, coordsObj.lng)
}

const BUCHAREST_BOUNDS = {
  north: 44.535427,
  south: 44.346138,
  east: 26.244165,
  west: 25.931580,
}

/**
 * Returns distance in meters and duration in seconds
 * @param startLocation adress
 * @param endLocation adress
 * @param waypointsArray array of adresses
 * @returns { distance, duration }
 */
export const getRouteDetails = async ({
  startLocation,
  endLocation,
  waypointsArray,
  departureDateString,
  giveWholeResult = false,
}: {
  startLocation: any
  endLocation: any
  waypointsArray: any[]
  departureDateString?: string
  giveWholeResult?: boolean
}) => {
  const departureDate = departureDateString ? new Date(departureDateString) : new Date()
  const waypoints = waypointsArray.map((waypoint: any) => ({ location: waypoint }))
  return new Promise((res, rej) => {
    //@ts-ignore
    var service = new google.maps.DirectionsService()
    service.route(
      {
        origin: startLocation,
        destination: endLocation,
        waypoints: waypoints,
        // @ts-ignore
        travelMode: 'DRIVING',
        transitOptions: {
          departureTime: departureDate,
        },
        region: 'ro',
      },
      (result: any) => {
        res(giveWholeResult ? result : getDetailsForWholeRoute(result.routes[0]))
      }
    )
  })
}

const getDetailsForWholeRoute = (route: any) => {
  var totalDist = 0
  var totalDuration = 0
    ; (route?.legs || []).forEach((leg: any) => {
      totalDist += leg?.distance?.value || 0
      totalDuration += leg?.duration?.value || 0
    })
  return { distance: totalDist, duration: totalDuration }
}

export function throttle(callback: Function, delay: any) {
  var timeoutHandler: any = null
  return function () {
    if (timeoutHandler == null) {
      timeoutHandler = setTimeout(function () {
        callback()
        timeoutHandler = null
      }, delay)
    }
  }
}

export const getLatitudeAndLongitudeFromPlaceId = async ({
  placeId,
}: {
  placeId: any
}): Promise<object> => {
  return new Promise((res, rej) => {
    //@ts-ignore
    new google.maps.Geocoder().geocode(
      { placeId: `${placeId}` },
      function (results: any[], status: any) {
        const lat = results[0].geometry.location.lat()
        const lng = results[0].geometry.location.lng()
        res({ lat, lng })
      }
    )
  })
}

export const getEasyTrackZone = async ({ lat, lng }: any) => {
  // to be modified (businessProfileId should be provided as input)
  const result = await easytrackAxios
    .get(`${EASYTRACK_API_URL}/geo-fences?businessProfileId=1&coordinates=${lat},${lng}`)
    .catch((err) => err)

  if (result?.response?.data?.error) {
    toast.error(result?.response?.data?.message || 'We have some problem with your last request!')
    return { message: result?.response?.data?.message, error: true }
  }

  return { error: false, data: result.data }
}

export enum DislocationType {
  SameAutonomAgency = 'Preluare / predare aceeași Agenție Autonom',
  SameAutonomCities = 'Preluare / predare același oraș Autonom',
  DiffAutonomAgency = 'Preluare / predare agenții diferite Autonom',
  DiffAutonomCities = 'Preluare / predare orașe diferite Autonom',
  NoAutonomAgencies = 'Preluare / predare în orașe fără agenții Autonom'
}

export enum GeofenceTypeEnum {
  Custom = 'CUSTOM',
  Airport = 'AIRPORT',
  City = 'CITY',
  County = 'COUNTY'
}

export type GeofenceZoneType = {
  id: number,
  businessProfileId: number,
  name: string,
  type: GeofenceTypeEnum,
  referenceGPSPoint: string,
  connectedGeofence?: {
    id: number,
    name: string,
    type: GeofenceTypeEnum,
    containsReferer?: boolean
  }
}

export const getEasyTrackZoneByCoords = async ({
  coords,
  businessProfileId
}: {
  coords: string,
  businessProfileId: number | string
}) => {
  const result = await easytrackAxios
    .get(`${EASYTRACK_API_URL}/business-profiles/${businessProfileId}/pricing-geofences/${coords}`)
    .catch((err) => err)

  if (result?.response?.data?.error) {
    toast.error(result?.response?.data?.message || 'We have some problem with your last request!')
    return { message: result?.response?.data?.message, error: true }
  }

  const geofenceZone: GeofenceZoneType | null = result.data
  return { error: false, data: geofenceZone }
}

export const getDislocationType = async ({
  originCoords,
  destinationCoords,
  businessProfileId
}: {
  originCoords: string
  destinationCoords: string
  businessProfileId: number
}): Promise<DislocationType | null> => {
  const originGeofenceResponse = await getEasyTrackZoneByCoords({ coords: originCoords, businessProfileId })
  const destinationGeofenceResponse = await getEasyTrackZoneByCoords({ coords: destinationCoords, businessProfileId })

  if (!originGeofenceResponse?.data || !destinationGeofenceResponse?.data) {
    return null
  }

  const originGeofence = originGeofenceResponse.data
  const originIsAgency = originCoords === originGeofence.referenceGPSPoint || originGeofence.type === GeofenceTypeEnum.Airport
  const originIsCity = originGeofence.type === GeofenceTypeEnum.City
  const originIsAirport = originGeofence.type === GeofenceTypeEnum.Airport
  const originConnectedGeofence = originGeofence?.connectedGeofence
  const originInsideCity = originConnectedGeofence?.type === GeofenceTypeEnum.City
    && Boolean(originConnectedGeofence?.containsReferer)

  const destinationGeofence = destinationGeofenceResponse.data
  const destinationIsAgency = destinationCoords === destinationGeofence.referenceGPSPoint || destinationGeofence.type === GeofenceTypeEnum.Airport
  const destinationIsCity = destinationGeofence.type === GeofenceTypeEnum.City
  const destinationIsAirport = destinationGeofence.type === GeofenceTypeEnum.Airport
  const destinationConnectedGeofence = destinationGeofence?.connectedGeofence
  const destinationInsideCity = destinationConnectedGeofence?.type === GeofenceTypeEnum.City
  && Boolean(destinationConnectedGeofence?.containsReferer)
  
  const sameGeofence = originGeofence.id === destinationGeofence.id
  const describeSameGeofence = originGeofence.id === destinationConnectedGeofence?.id || originConnectedGeofence?.id === destinationGeofence.id
  const insideSameGeofence = originConnectedGeofence && destinationConnectedGeofence && originConnectedGeofence.id === destinationConnectedGeofence.id
  const connectedToSameGeofence = Boolean(originConnectedGeofence?.id) && Boolean(destinationConnectedGeofence?.id)
    && originConnectedGeofence?.id === destinationConnectedGeofence?.id
  
  const originDescribeCity = originIsCity || originInsideCity
  const destinationDescribeCity = destinationIsCity || destinationInsideCity

  if (originIsAgency && destinationIsAgency) {
    return sameGeofence ? DislocationType.SameAutonomAgency : DislocationType.DiffAutonomAgency
  }

  const originAirportOfDestination = originIsAirport && destinationDescribeCity && (describeSameGeofence || connectedToSameGeofence)
  const destinationAirportOfOrigin = destinationIsAirport && originDescribeCity && (describeSameGeofence || connectedToSameGeofence)
  if (originAirportOfDestination || destinationAirportOfOrigin) {
    return DislocationType.SameAutonomCities
  }

  if ((originIsCity && destinationInsideCity) || (originInsideCity && destinationIsCity)) {
    return describeSameGeofence ? DislocationType.SameAutonomCities : DislocationType.DiffAutonomCities
  }

  if (originInsideCity && destinationInsideCity) {
    return insideSameGeofence ? DislocationType.SameAutonomCities : DislocationType.DiffAutonomCities
  }

  if (originIsCity && destinationIsCity) {
    return sameGeofence ? DislocationType.SameAutonomCities : DislocationType.DiffAutonomCities
  }

  if ((originDescribeCity && destinationIsAgency) || (originIsAgency && destinationDescribeCity)) {
    return DislocationType.DiffAutonomCities
  }

  return DislocationType.NoAutonomAgencies
}

export const getAutocompleteAsync = async ({ input }: { input: string }): Promise<Array<any>> => {
  return new Promise((res, rej) => {
    //@ts-ignore
    const service = new google.maps.places.AutocompleteService()

    //@ts-ignore
    service.getPlacePredictions(
      {
        input,
        region: 'ro',
        language: 'ro',
        bounds: BUCHAREST_BOUNDS,
        // @ts-ignore
        fields: ['geometry'],
      },
      (
        //@ts-ignore
        predictions: any[],
        //@ts-ignore
        status: google.maps.places.PlacesServiceStatus
      ) => {
        res(predictions)
      }
    )
  })
}

const googleMapElem = document.getElementById('custom_map') as HTMLDivElement

export const getPlaceDetails = async ({ place_id }: { place_id: string }): Promise<any> => {
  return await new Promise((res, rej) => {
    const service = new google.maps.places.PlacesService(googleMapElem)

    service.getDetails(
      {
        placeId: `${place_id}`,
      },
      (result: any, status: any) => {
        if (status === 'OK') {
          res({ ...result, name: result.name || result.formatted_address })
        } else {
          res({})
        }
      }
    )
  })
}

export const isSameGeofence = ({
  geofenceA,
  geofenceB,
}: {
  geofenceA: any
  geofenceB: any
}): boolean => {
  if (!geofenceA || !geofenceB) {
    return false
  }

  if (geofenceA.id && geofenceA.id === geofenceB.id) {
    return true
  }
  if (geofenceA.localUuId && geofenceA.localUuId === geofenceB.localUuId) {
    return true
  }

  return false
}

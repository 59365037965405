import {EditableLabel} from '../../../../../app/modules/add-edit-template/components/fields-generator'
import {InputComponentGroup} from '../../../../../components/field/field-generator'
import {formatCurrency} from '../../../../../utils/string.utils'

type CurrencyInputProps = {
  field: any
  value: string
  onChange: (value: any) => void
  hideLabel?: boolean
  className?: string
  onChangeLabel?: (value: string) => void
  additionalInfo?: string
  [key: string]: any
}

const CurrencyInput = ({
  field,
  value,
  hideLabel,
  onChange,
  onChangeLabel,
  additionalInfo,
  ...inputProps
}: CurrencyInputProps) => {
  const inputComponent = (
    <input
      onBlur={(e: any) => {
        var futureValue = e.target.value
        e.target.value = formatCurrency(futureValue)
        onChange(e)
      }}
      style={{
        borderRadius: Boolean(!field?.addOns)
          ? '0.475rem'
          : Boolean(field?.addOns.prepend)
          ? '0rem 0.475rem 0.475rem 0rem'
          : '0.475rem 0rem 0rem 0.475rem',
      }}
      type='number'
      step='0.1'
      name={field?.name}
      required={field?.required}
      className={`form-control form-control-sm`}
      id={field?.name}
      value={value}
      onChange={(ev) => {
        onChange(ev)
      }}
      disabled={field?.disabled}
      {...inputProps}
    />
  )
  return (
    <div>
      {!Boolean(hideLabel) ? (
        <EditableLabel label={field?.label} onChange={onChangeLabel} isRequired={field.required} />
      ) : null}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {Boolean(field?.addOns) ? (
          <InputComponentGroup addOns={field?.addOns}>{inputComponent}</InputComponentGroup>
        ) : (
          inputComponent
        )}
      </div>
      {Boolean(additionalInfo) ? (
        <div style={{opacity: 0.9, padding: '5px'}}>
          <span style={{fontWeight: '500'}}>info: </span>
          <span>{additionalInfo}</span>
        </div>
      ) : null}
    </div>
  )
}

export default CurrencyInput

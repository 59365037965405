import React, { FC, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Loading from '../../../_metronic/layout/components/loading/Loading'
import { CompanyType, emptyBranch, emptyCompany } from '../../../types/company.types'
import CompanyInfoComponent from './components/CompanyInfo.component'
import { PageTitle } from '../../../_metronic/layout/core'
import { CompanyContext } from '../../../context/company.context'
import CompanyOptionsComponent from './components/CompanyOptions.component'
import { toast } from 'react-toastify'
import { UserContext, UserRole } from '../../../context/user.context'
import { anyToBoolean, numberToStr } from '../../../utils/string.utils'

enum businessProfileIds {
  VMS = "1",
  Proassista = "58"
}

const AddEditCompany: FC = () => {
  const history = useHistory()
  const { id, companyId } = useParams<any>()
  const isEditMode = Boolean(id)
  const isBranch = Boolean(companyId)
  const { businessProfileId, user: { role: { slug: userRole } } } = useContext<any>(UserContext)
  const { isLoading, setIsLoading, getCompany, createOrUpdateCompany } = useContext<any>(CompanyContext)
  const [companyInfo, setCompanyInfo] = useState<CompanyType>()
  const [companyName, setCompanyName] = useState<string>();

  const getAndSetCompanyInfo = async () => {
    setIsLoading(true)
    if (isEditMode) {
      const response = await getCompany({ id, businessProfileId })
      if (!response?.error && response?.company) {
        setCompanyName(response?.company?.name)
        setCompanyInfo(response?.company)
      }
    }
    else {
      let futureCompanyInfo = isBranch ? { ...emptyBranch, main_company_id: companyId } : emptyCompany
      setCompanyInfo(futureCompanyInfo)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getAndSetCompanyInfo()
  }, [history?.location?.key]) // if the page "reloads" we update the company info

  return (
    <div>
      {/* Header */}
      <PageTitle>
        {isEditMode ? `Edit company ${companyName || id}` : 'Add company'}
      </PageTitle>

      <div
        className='page__top-buttons'
        style={{
          position: 'fixed',
          display: 'flex',
          justifyContent: 'end',
          zIndex: 1000,
          right: '90px',
          top: '12px',
        }}
      >
        <button
          className='btn btn-light w-90px'
          style={{ background: 'none' }}
          onClick={(e) => {
            e.preventDefault()

            history.goBack()
          }}
          disabled={isLoading}
          type='button'
        >
          Cancel
        </button>
        <button
          className='btn btn-primary w-90px'
          onClick={async (e) => {
            const hasBusinessProfile = anyToBoolean(companyInfo.business_profile)
            const formattedBusinessProfileId = numberToStr(companyInfo.business_profile_id)
            if (hasBusinessProfile && (formattedBusinessProfileId !== businessProfileIds.VMS) && (formattedBusinessProfileId !== businessProfileIds.Proassista)) {
              toast.error("Company business profile id doesn't exist!");
              return;
            }
            setIsLoading(true)
            e.preventDefault()
            let payload = { ...companyInfo, business_profile: companyInfo.business_profile ?? false, is_vin_required: companyInfo?.is_vin_required ?? false};
            if (isEditMode) {
              payload.id = id
            }

            if (payload.business_profile === false) {
              payload.business_profile_id = null;
            }
            const response = await createOrUpdateCompany({ payload })
            setIsLoading(false)
            if (!response?.error) {
              if (isBranch) { // for branch just go back to the list
                history.goBack()
              }
              if (isEditMode) {
                history.replace(`./${id}`) // redirect to same route - this will trigger a history key update
              } else {
                const newCompanyId = response?.company?.id
                if (!newCompanyId) {
                  history.goBack()
                }
                history.replace(`./${newCompanyId}`) // redirect to newly created company
              }
            }
          }}
          disabled={isLoading}
          type='submit'
        >
          {isLoading ? (
            <span
              style={{ width: '1.3rem', height: '1.3rem' }}
              className='spinner-border spinner-border-sm align-middle'
            />
          ) : (
            <span>Save</span>
          )}
        </button>
      </div>

      {/* Finish Header */}
      {companyInfo ? <>
        <CompanyInfoComponent company={companyInfo} setCompany={setCompanyInfo} userRole={userRole} businessProfileId={businessProfileId} />
        {!isBranch ? <CompanyOptionsComponent id={companyInfo.id} name={companyName} userRole={userRole} /> : null}
      </> :
        <Loading />}

    </div>
  )
}

export default AddEditCompany

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {CarsContext} from '../../../../../context/cars.context'
import {formatCarNumber, getLogoFromBrand} from '../../../../../utils/car.utils'
import {getSortedList} from '../../../../../utils/sort.utils'
import {KTSVG} from '../../../../../_metronic/helpers'
import {CarModel} from '../../../auth/models/CarModel'
import {CarActionsModal} from '../car-actions-modal/CarActionsModal'
import {CreateUpdateCarModal} from '../create-update-car-modal/CreateUpdateCarModal.component'

type CarRowProps = {
  car: CarModel
  setCurrentCar: any
  setShowCreateCarModal: any
  deleteCar: any
  addToSelected: any
  removeFromSelected: any
  setShowCarActionsModal: any
  checked: any
}

type Props = {
  className: string
}

const CarRow: React.FC<CarRowProps> = ({
  car,
  setCurrentCar,
  setShowCreateCarModal,
  setShowCarActionsModal,
  deleteCar,
  addToSelected,
  removeFromSelected,
  checked,
}) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)

  const logo = getLogoFromBrand(car.brand)

  const toggelCarSelected = (car: CarModel) => {
    if (!checked()) {
      addToSelected(car)
      setCurrentCar(car)
    } else {
      removeFromSelected(car)
    }
  }

  return (
    <>
      {deleteModalVisible && (
        <Modal
          className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
          id='kt_inbox_compose'
          role='dialog'
          data-backdrop='static'
          aria-hidden='true'
          tabIndex='-1'
          show={deleteModalVisible}
          animation={true}
        >
          <div className='modal-content'>
            {/* begin::Form */}
            {/*begin::Header*/}
            <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5'>
              <h2 className='fw-bold m-0'> </h2>
              <div className='d-flex ms-2'>
                {/*begin::Close*/}
                <div
                  className='btn btn-icon btn-sm btn-light-primary ms-2'
                  data-bs-dismiss='modal'
                  onClick={() => setDeleteModalVisible(false)}
                >
                  <KTSVG className='svg-icon-1' path='/media/icons/duotone/Navigation/Close.svg' />
                </div>
                {/*end::Close*/}
              </div>
            </div>
            {/*end::Header*/}

            {/*begin::Body*/}
            <div className='d-block py-5 ps-8 pe-5'>
              {/* end::Form group */}

              <h2>Are you sure?</h2>
              <p>
                The car{' '}
                <strong>
                  {car.brand} {car.model}
                </strong>{' '}
                with registration number: <strong>{car.carNumber}</strong>, will be deleted forever
              </p>
            </div>
            {/*end::Body*/}

            {/*begin::Footer*/}
            <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-top'>
              {/*begin::Actions*/}
              <div className='d-flex align-items-center me-3'></div>
              {/*end::Actions*/}

              {/*begin::Toolbar*/}
              <div className='d-flex align-items-center'>
                <button
                  onClick={async () => {
                    const response = await deleteCar(car.id)
                  }}
                  className='btn btn-primary px-6'
                >
                  Delete
                </button>
              </div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Footer*/}
            {/*end::Form*/}
          </div>
        </Modal>
      )}

      <tr key={car.id}>
        <td>
          <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input widget-9-check'
              checked={checked()}
              onChange={() => {
                toggelCarSelected(car)
              }}
              type='checkbox'
              value='1'
            />
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            {logo && (
              <div className='symbol symbol-35px p-2 me-5'>
                <img src={logo} alt='logo car' />
              </div>
            )}

            <div className='d-flex justify-content-start flex-column'>
              <span
                onClick={() => {
                  toggelCarSelected(car)
                }}
                className='car-brand text-dark fw-bolder text-hover-primary fs-6'
              >
                {car.brand}
              </span>
              <span className='text-muted fw-bold text-muted d-block fs-7'>{car.model}</span>
            </div>
          </div>
        </td>
        <td>
          <div>
            <span className='car-number-plate'>{formatCarNumber(car.carNumber)}</span>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-45px me-5'>
              <a
                onClick={() => {
                  setCurrentCar(car)
                  window.scrollTo({top: 0, behavior: 'smooth'})
                }}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG
                  path='/media/icons/duotone/Interface/Map-Marker.svg'
                  className='svg-icon-3'
                />
              </a>
            </div>

            {car.location && (
              <div>
                <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                  {car.trackNumber}
                </a>
                <span className='text-muted fw-bold text-muted d-block fs-7'>
                  {car.location.lat} &amp; {car.location.long}
                </span>
              </div>
            )}
          </div>
        </td>
        <td className='text-end'>
          <div className='d-flex flex-column w-100 me-2'>
            <div className='d-flex flex-stack mb-2'>
              <span className='text-muted me-2 fs-7 fw-bold'>{car.phoneNumber}</span>
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <a
            title='Show actions modal'
              onClick={() => {
                setCurrentCar(car)
                setShowCarActionsModal(true)
              }}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <KTSVG path='/media/icons/duotone/General/Settings-1.svg' className='svg-icon-3' />
            </a>
            <a
            title='Edit'
              onClick={() => {
                setCurrentCar(car)
                setShowCreateCarModal(true)
              }}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <KTSVG path='/media/icons/duotone/Communication/Write.svg' className='svg-icon-3' />
            </a>
            <a
              title='Delete'
              onClick={async () => {
                setDeleteModalVisible(true)
              }}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            >
              <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
            </a>
          </div>
        </td>
      </tr>
    </>
  )
}

const CarsTableComponent: React.FC<Props> = ({className}) => {
  const {
    getAndSetCars,
    carsData,
    deleteCar,
    currentCar,
    setCurrentCar,
    setSelectedCars,
    selectedCars,
    showCreateCarModal,
    setShowCreateCarModal,
    showCarActionsModal,
    setShowCarActionsModal,
  } = useContext<any>(CarsContext)

  const removeFromSelected = (car: CarModel) => {
    const futureSelected = selectedCars.filter((e: CarModel) => e.id !== car.id)
    setSelectedCars(futureSelected)
  }

  const addToSelected = (car: CarModel) => {
    const futureSelected = [...selectedCars, car]
    setSelectedCars(futureSelected)
  }

  useEffect(() => {
    getAndSetCars()
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Car data</span>
          <span className='text-muted mt-1 fw-bold fs-7'>A total of {carsData.length} cars</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            onClick={() => {
              setCurrentCar(undefined)
              setShowCreateCarModal(true)
            }}
            className='btn btn-sm btn-light-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
          >
            <KTSVG path='media/icons/duotone/Navigation/Plus.svg' className='svg-icon-3' />
            New car
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          setSelectedCars(carsData)
                        } else {
                          setSelectedCars([])
                        }
                      }}
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>Brand</th>
                <th className='min-w-150px'>Car number</th>
                <th className='min-w-140px'>Track number</th>
                <th className='min-w-120px'>Phone</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {getSortedList({listToSort: carsData, args: ["carNumber", "model", "brand"]}).map((car: CarModel) => (
                <CarRow
                  key={car.id}
                  checked={() => {
                    if (selectedCars.some((e: CarModel) => e.id === car.id)) {
                      return true
                    }
                    return false
                  }}
                  setShowCarActionsModal={setShowCarActionsModal}
                  car={car}
                  setCurrentCar={setCurrentCar}
                  addToSelected={addToSelected}
                  removeFromSelected={removeFromSelected}
                  setShowCreateCarModal={setShowCreateCarModal}
                  deleteCar={deleteCar}
                />
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {<CreateUpdateCarModal car={currentCar} />}

      {showCarActionsModal && <CarActionsModal car={currentCar} />}
    </div>
  )
}

export {CarsTableComponent}

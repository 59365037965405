import moment from 'moment'
import Loading from '../../../_metronic/layout/components/loading/Loading'

import {components} from 'react-select'
import {memo, useEffect, useMemo, useState} from 'react'
import { CarType } from '../../../types/general.types'
const {Option} = components


const CustomVehicleOption = ({
  ...props
}: any) => {
  const [vehicle] = useState<CarType>(props.data)


  return (
    <Option
      {...props}
      style={{
        borderTop: '1px solid #eee',
      }}
    >
    {vehicle.businessName}
    </Option>
  )
}

export default CustomVehicleOption

export const DEFAULT_STATUSES = [
  'ACKNOWLEDGED',
  'ALERT',
  'ANALYZE',
  'DELAYED',
  'NEW',
  'OPEN',
  'PENDING',
  'QUOTE',
  'READY',
  'STARTED',
  'TO_BE_ACCEPTED',
  'TO_BE_REJECTED',
  'UNSOLVED',
]

export const DEFAULT_STATUSES_AS_OBJECT = [
  {name: 'ANALYZE', title: 'Analyze', oldId: 1, color: '#BD9FE1'},
  {name: 'TO_BE_REJECTED', title: 'To be rejected', oldId: 3, color: '#E19797'},
  {name: 'TO_BE_ACCEPTED', title: 'To be accepted', oldId: 2, color: '#B7FFD9'},
  {name: 'NEW', title: 'New', oldId: 4, color: '#99CBF5'},
  {name: 'PENDING', title: 'Pending', oldId: 5, color: '#58AAEE'},
  {name: 'ACKNOWLEDGED', title: 'Acknowledged', oldId: 6, color: '#147BD1'},
  {name: 'READY', title: 'Ready', oldId: 7, color: '#10069F'},
  {name: 'STARTED', title: 'Started', oldId: 8, color: '#00C65E'},
  {name: 'DELAYED', title: 'Delayed', oldId: 9, color: '#FFC000'},
  {name: 'ALERT', title: 'Alert', oldId: 10, color: '#C13737'},
  {name: 'UNSOLVED', title: 'Unsolved', oldId: 16, color: '#FFE285'},
  {name: 'QUOTE', title: 'Quote', oldId: 18, color: '#A379D5'},
  {name: 'OPEN', title: 'Open', oldId: 17, color: '#00B0F0'},
]

import React, { useState } from 'react';
import { BlockedStatus } from './scripts/tariffUtils';
import { StateSetter } from '../../../utils/state.utils';

export type SubmitFormFunction = ({
  recomputeTaxBreakdown,
  recomputeCommissionBreakdown,
}: {
  recomputeTaxBreakdown: boolean;
  recomputeCommissionBreakdown: boolean;
}) => void;

const getMessageFromBlockedState = (blockedStatus: BlockedStatus) => {
  // neither is blocked - this case shouldn't happen. But in case it happens, we will know based on this title
  if (blockedStatus === BlockedStatus.NotBlocked) {
    return 'Do you want to recalculate?';
  }

  // both are blocked
  if (blockedStatus === BlockedStatus.BothBlocked) {
    return 'It seems like both the tariff and the commission are blocked? How would you like to proceed?';
  }

  // one is blocked
  let componentBlocked: string = 'tariff';
  if (blockedStatus === BlockedStatus.CommissionBlocked) {
    componentBlocked = 'commission';
  }
  return `The ${componentBlocked} seems to be blocked. Do you want to recalculate it anyway?`;
};

const ConfirmSingleModal = ({
  modalTitle,
  setModalVisible,
  confirmOperation,
}: {
  modalTitle: string;
  setModalVisible: StateSetter<boolean>;
  confirmOperation: ({ accepted }: { accepted: boolean }) => void;
}) => {
  return (
    <div className='py-5 px-8' style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ textAlign: 'center', fontSize: '16px' }}>{modalTitle}</span>
      <div className='mt-8' style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          className='btn btn-secondary me-5'
          onClick={() => {
            confirmOperation({ accepted: false });
            setModalVisible(false);
          }}
        >
          No
        </div>
        <div
          className='btn btn-danger'
          onClick={() => {
            confirmOperation({ accepted: true });
            setModalVisible(false);
          }}
        >
          Yes
        </div>
      </div>
    </div>
  );
};

const ConfirmationCheckbox = ({
  checked,
  setChecked,
  message,
  className = '',
}: {
  checked: boolean;
  setChecked: StateSetter<boolean>;
  message: string;
  className?: string;
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }} className={className}>
      <div className='w-300px' style={{ display: 'inline-block' }}>
        {message}
      </div>
      <input
        className='clickable form-check-input w-30px h-30px'
        type={'checkbox'}
        checked={checked}
        onChange={(e) => {
          const value = e.target.checked;
          setChecked(value);
        }}
      />
    </div>
  );
};

const ConfirmMultipleModal = ({
  modalTitle,
  setModalVisible,
  confirmOperations,
}: {
  modalTitle: string;
  setModalVisible: StateSetter<boolean>;
  confirmOperations: SubmitFormFunction;
}) => {
  const [recomputeTariff, setRecomputeTariff] = useState<boolean>(false);
  const [recomputeCommission, setRecomputeCommission] = useState<boolean>(false);

  return (
    <div className='py-5 px-8' style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ textAlign: 'center', fontSize: '16px' }}>{modalTitle}</span>
      <div className='mt-5' style={{ fontSize: '15px' }}>
        <ConfirmationCheckbox
          message={'Recalculate the tariff?'}
          checked={recomputeTariff}
          setChecked={setRecomputeTariff}
        />
        <ConfirmationCheckbox
          message={'Recalculate the commission?'}
          checked={recomputeCommission}
          setChecked={setRecomputeCommission}
          className='mt-2'
        />
      </div>
      <div className='mt-5' style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          className='btn btn-danger me-5'
          onClick={() => {
            confirmOperations({
              recomputeTaxBreakdown: recomputeTariff,
              recomputeCommissionBreakdown: recomputeCommission,
            });
            setModalVisible(false);
          }}
        >
          Confirm
        </div>
      </div>
    </div>
  );
};

const ConfirmModalWrapper = ({
  setModalVisible,
  blockedStatus,
  submitForm,
}: {
  setModalVisible: StateSetter<boolean>;
  blockedStatus: BlockedStatus;
  submitForm: SubmitFormFunction;
}) => {
  const modalTitle = getMessageFromBlockedState(blockedStatus);

  if (blockedStatus === BlockedStatus.BothBlocked) {
    return (
      <ConfirmMultipleModal
        modalTitle={modalTitle}
        setModalVisible={setModalVisible}
        confirmOperations={submitForm}
      />
    );
  }

  const confirmOperation = ({ accepted }: { accepted: boolean }): void => {
    let recomputeTaxBreakdown = false,
      recomputeCommissionBreakdown = false;
    if (blockedStatus === BlockedStatus.TariffBlocked) {
      recomputeTaxBreakdown = accepted;
    }
    if (blockedStatus === BlockedStatus.CommissionBlocked) {
      recomputeCommissionBreakdown = accepted;
    }
    submitForm({ recomputeTaxBreakdown, recomputeCommissionBreakdown });
  };

  return (
    <ConfirmSingleModal
      modalTitle={modalTitle}
      setModalVisible={setModalVisible}
      confirmOperation={confirmOperation}
    />
  );
};

export default ConfirmModalWrapper;

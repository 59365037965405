import { Button, Modal } from 'react-bootstrap';
import { KTSVG } from '../../../../_metronic/helpers';
import { CampaignType, StatusType } from '../../../../types/campaign.types';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  createSMSListRequest,
  updateCampaignRequest,
} from '../../../../setup/axios/campaign.request';
import Loading from '../../../../_metronic/layout/components/loading/Loading';
import { useState } from 'react';

type CampaignInfoProps = {
  campaign: CampaignType;
  showStartCampaignModal: boolean;
  setShowStartCampaignModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCampaign: React.Dispatch<React.SetStateAction<CampaignType>>;
};

export const StartCampaignModal: React.FC<CampaignInfoProps> = ({
  campaign,
  showStartCampaignModal,
  setShowStartCampaignModal,
  setCampaign,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleSMSCreation = async () => {
    setIsLoading(true);
    toast.success('Your request has been registered!');
    const response = await createSMSListRequest(campaign.id);
    if (!response || response.error) {
      setIsLoading(false);
      return toast.error(response?.message || 'Something went wrong');
    }
    setIsLoading(false);
    toast.success('Messages sent!');
    setShowStartCampaignModal(false);

    return;
  };

  const changeCampaignStatus = async (
    id,
    name,
    type,
    send_after,
    content_type,
    expiration_date,
    status,
    number_of_clients
  ) => {
    console.log(
      id,
      name,
      type,
      content_type,
      send_after,
      expiration_date,
      status,
      number_of_clients
    );
    const response = await updateCampaignRequest(
      id,
      name,
      type,
      send_after,
      content_type,
      expiration_date,
      status,
      number_of_clients
    );

    if (!response || response.error) {
      return toast.error(response?.message || 'Something went wrong');
    }
    setShowStartCampaignModal(false);
    setCampaign(response.campaign);
    toast.success('Campaign is now on-going!');
    return;
  };

  return (
    <Modal
      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
      show={showStartCampaignModal}
    >
      {!isLoading ?
      <div className='modal-content'>
        {/* begin::Header */}
        <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
          <h5 className='fw-bold m-0'>Start Campaign</h5>

          <div className='d-flex ms-2'>
            {/* begin::Close */}
            <div
              className='btn btn-icon btn-sm btn-light-primary ms-2'
              data-bs-dismiss='modal'
              onClick={() => {
                setShowStartCampaignModal(false);
              }}
            >
              <KTSVG className='svg-icon-1' path='/media/icons/duotone/Navigation/Close.svg' />
            </div>
            {/* end::Close */}
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='py-5 px-8' style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ textAlign: 'center', fontSize: '16px' }}>
            Are you sure you want to start the {campaign.type} campaign for {campaign.name}?
          </span>

          <div className='mt-8' style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              className='btn btn-danger btn-secondary me-5'
              onClick={() => setShowStartCampaignModal(false)}
            >
              Cancel
            </Button>

            <Button
              className='btn btn-success'
              onClick={async () => {
                handleSMSCreation();
                await changeCampaignStatus(
                  campaign.id,
                  campaign.name,
                  campaign.type,
                  campaign.content_type,
                  moment().format('YYYY-MM-DD HH:mm:ss'),
                  campaign.expiration_date,
                  StatusType.ON_GOING,
                  campaign.number_of_clients
                );
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div> : <Loading />}
    </Modal>
  );
};

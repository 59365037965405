import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserContext, UserRole } from '../../../context/user.context';
import { APIResponseType } from '../../../types/general.types';
import { orbitRequest, RequestMethod } from '../../../utils/api.utils';
import { disableMaxWidth, enableMaxWidth } from '../../../utils/style.utils';
import { PageTitle } from '../../../_metronic/layout/core';
import { LoadingCard } from '../../../_metronic/layout/components/loading/Loading';
import { defaultPaginationSize } from '../transport-requests/Pagination';
import Filter from './components/Filter';
import SubscriptionsTable from './components/Table';
import ModalPortal from '../../../_metronic/layout/components/modal/modal-portal';
import ImportSubscriptionsModal from './components/modals/ImportSubscriptionsModal';
import { FlatSubscriptionType, SubscriptionsContextType } from '../../../types/subscription.types';
import SearchSubscriptionsModal from './components/modals/SearchSubscriptionsModal';
import { FlexibleObject } from '../../../utils/state.utils';
import { SubscriptionsContext } from '../../../context/subscriptions.context';

enum ModalStatus {
  CLOSED,
  SEARCH_SUBSCRIPTION,
  IMPORT_SUBSCRIPTIONS,
}

export const getSubscriptionsRequest = async ({
  searchInput = '',
  currentPage = 1,
  resultsPerPage = defaultPaginationSize,
  startDate = '',
  endDate = '',
  companyIds = '',
  selectedStatus = '',
  exportExcel = false,
  campaignIds = '',
}: {
  searchInput?: string;
  currentPage?: number;
  resultsPerPage?: number;
  startDate?: string;
  endDate?: string;
  companyIds?: string;
  selectedStatus?: string;
  exportExcel?: boolean;
  campaignIds?: string;
}): Promise<APIResponseType> =>
  await orbitRequest({
    route: `/subscription?page=${currentPage}&resultsPerPage=${resultsPerPage}&searchInput=${searchInput}&startDate=${startDate || ''}&endDate=${endDate || ''}&companyIds=${companyIds || ''}&status=${selectedStatus}&exportExcel=${exportExcel}&campaignIds=${campaignIds || ''}`,
    method: RequestMethod.GET,
  });

const SubscriptionsPage: React.FC<Record<string, never>> = () => {
  const { filter, setFilter } = useContext<SubscriptionsContextType>(SubscriptionsContext);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [resultsPerPage, setResultsPerPage] = useState<number>(defaultPaginationSize);
  const [subscriptions, setSubscriptions] = useState<FlatSubscriptionType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [triggerSubscriptionUpdate, setTriggerSubscriptionUpdate] = useState<boolean>(false);
  const {
    user: {
      company_id: userCompanyId,
      role: { slug: userRole },
    },
  } = useContext<FlexibleObject>(UserContext);
  const [modalStatus, setModalStatus] = useState<ModalStatus>(ModalStatus.CLOSED);
  const exitModal = useCallback(() => setModalStatus(ModalStatus.CLOSED), []);

  const { searchInput, selectedTimeInterval, selectedCompanies, selectedStatus, selectedCampaigns } = filter;
  const { setSearchInput, setSelectedTimeInterval, setSelectedCompanies, setSelectedStatus, setSelectedCampaigns } = setFilter;

  const setSearchInputWithEffect = useCallback((searchInput: string) => {
    setCurrentPage(1);
    setSearchInput(searchInput);
  }, []);

  const companyIds =
    Boolean(selectedCompanies) && Array.isArray(selectedCompanies)
      ? selectedCompanies?.map((company) => company.id)?.toString() || ''
      : '';

  const campaignIds =
    Boolean(selectedCampaigns) && Array.isArray(selectedCampaigns)
    ? selectedCampaigns?.toString() || ''
    : '';

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let startDate = '';
      let endDate = '';

      if (Boolean(selectedTimeInterval) && Array.isArray(selectedTimeInterval)) {
        startDate = selectedTimeInterval[0] || '';
        endDate = selectedTimeInterval[1] || '';
      }

      if (triggerSubscriptionUpdate) {
        setTriggerSubscriptionUpdate(false);
      }
      const response = await getSubscriptionsRequest({
        searchInput: searchInput,
        currentPage,
        resultsPerPage,
        startDate,
        endDate,
        companyIds,
        selectedStatus: selectedStatus,
        campaignIds,
      });
      if (!response || response.error) {
        toast.error(`The subscriptions could not be loaded! ${response?.message || ''}`);
      } else {
        const subscriptionList: FlatSubscriptionType[] = response.data;
        setSubscriptions(subscriptionList);
        setPageCount(response.total_pages);
      }
      setIsLoading(false);
    })();
  }, [
    searchInput,
    currentPage,
    resultsPerPage,
    triggerSubscriptionUpdate,
    selectedTimeInterval,
    selectedCompanies,
    selectedStatus,
    selectedCampaigns,
  ]);

  useEffect(() => {
    disableMaxWidth();

    return () => {
      enableMaxWidth();
    };
  }, []);

  return (
    <>
      {modalStatus === ModalStatus.IMPORT_SUBSCRIPTIONS ? (
        <ModalPortal
          setVisible={exitModal}
          visible={modalStatus === ModalStatus.IMPORT_SUBSCRIPTIONS}
          hasExitButton={false}
        >
          <ImportSubscriptionsModal
            setModalVisible={exitModal}
            setTriggerSubscriptionUpdate={setTriggerSubscriptionUpdate}
          />
        </ModalPortal>
      ) : null}
      {modalStatus === ModalStatus.SEARCH_SUBSCRIPTION ? (
        <ModalPortal
          title='Find Subscriptions'
          setVisible={exitModal}
          visible={modalStatus === ModalStatus.SEARCH_SUBSCRIPTION}
          hasExitButton={true}
        >
          <SearchSubscriptionsModal />
        </ModalPortal>
      ) : null}
      <div
        className='page__top-buttons'
        style={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        {userRole === UserRole.Agent && Boolean(userCompanyId) ? (
          <button
            className='btn btn-light me-2'
            onClick={() => setModalStatus(ModalStatus.SEARCH_SUBSCRIPTION)}
          >
            Find Subscriptions
          </button>
        ) : null}
        {userRole === UserRole.Admin || userRole === UserRole.AdminSubscriptions ? (
          <button
            className='btn btn-info me-2'
            onClick={() => setModalStatus(ModalStatus.IMPORT_SUBSCRIPTIONS)}
          >
            Import Subscriptions
          </button>
        ) : null}
        <Link className='btn btn-primary' to={'../add-edit-subscription'}>
          New Subscription
        </Link>
      </div>
      <Filter
        setSearchInput={setSearchInputWithEffect}
        selectedTimeInterval={selectedTimeInterval}
        setSelectedTimeInterval={setSelectedTimeInterval}
        setSelectedCompanies={setSelectedCompanies}
        selectedCompanies={selectedCompanies}
        selectedStatus={selectedStatus}
        selectedCampaigns={selectedCampaigns}
        setSelectedStatus={setSelectedStatus}
        setSelectedCampaigns={setSelectedCampaigns}
        searchInput={searchInput}
      />
      <div className='row gy-5 g-xl-12'>
        <div className='col-xxl-12'>
          {isLoading ? (
            <LoadingCard />
          ) : (
            <SubscriptionsTable
              subscriptions={subscriptions}
              pageCount={pageCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              resultsPerPage={resultsPerPage}
              setResultsPerPage={setResultsPerPage}
              searchInput={searchInput}
              startDate={selectedTimeInterval ? selectedTimeInterval[0] : ''}
              endDate={selectedTimeInterval ? selectedTimeInterval[1] : ''}
              companyIds={companyIds}
              setTriggerSubscriptionUpdate={setTriggerSubscriptionUpdate}
            />
          )}
        </div>
      </div>
    </>
  );
};

const SubscriptionsWrapper: React.FC<Record<string, never>> = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Road Assist'}</PageTitle>
      <SubscriptionsPage />
    </>
  );
};

export default SubscriptionsWrapper;

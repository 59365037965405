import React, { useState, useMemo } from 'react'
import { toast } from 'react-toastify'

import { encode } from 'js-base64';
import { DocumentVariantType, TransportPvType, TransportPvPayloadType } from '../../../../types/document.types'
import { SelectObject } from '../../../../_metronic/layout/components/select/Select'
import { EASYTRACK_API_URL } from '../../../../constants/api.constants'
import Input from '../../../../components/input/input.component'
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Signature from '../../../../components/signature/signature.component'
import easytrackAxios from '../../../../setup/easytrack.axios';
import { TextareaComponent } from '../../../../_metronic/layout/components/fields/textarea/textarea';

const pvRemarksMaxLength = 1024;

const AddPvModal = ({
  transportRequest,
  setModalVisible,
  setProcessingFiles,
  updateListWithNewPhotos,
  pvTypes,
}: {
  transportRequest: any
  setModalVisible: Function
  setProcessingFiles: Function
  updateListWithNewPhotos: Function
  pvTypes: DocumentVariantType[]
}) => {

  const [registrationNumber, setRegistrationNumber] = useState(transportRequest?.assistanceVehiclePlateNumber || '');
  const [brand, setBrand] = useState(transportRequest?.assistanceVehicleBrand || '');
  const [model, setModel] = useState(transportRequest?.assistanceVehicleModel || '');
  const [km, setKm] = useState("");
  const [obs, setObs] = useState("");
  const [nameClient, setNameClient] = useState(transportRequest?.passengerName || "");
  const [email, setEmail] = useState(transportRequest?.passengerEmail || "");
  const [nameProvider, setNameProvider] = useState(transportRequest?.assignedDriver?.fullName || "");
  const [clientSignature, setClientSignature] = useState(null);
  const [providerSignature, setProviderSignature] = useState(null);
  const [showClientSignaturePad, setShowClientSignaturePad] = useState(false);
  const [showProviderSignaturePad, setShowProviderSignaturePad] = useState(false);
  const [pvType, setPvType] = useState<string>(pvTypes[0]?.name)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const payload: TransportPvPayloadType = {
    pvType: pvType.toUpperCase(),
    plateNumber: registrationNumber,
    brand: brand,
    model: model,
    beneficiaryCarKm: parseInt(km),
    observations: obs,
    email: email,
    clientRepresentative: nameClient,
    clientSignature: encode(clientSignature),
    supplierRepresentative: nameProvider,
    driverSignature: encode(providerSignature),
  }

  const addPvRequest = async (payload: TransportPvPayloadType, transportRequestId: string) => {
    const addPvUrl = `${EASYTRACK_API_URL}/transport-requests/${transportRequestId}/pvs`
    return await easytrackAxios.post(addPvUrl, payload)
      .then((res) => { return res.data })
      .catch((err) => { return null })
  }

  const onSave = async () => {
    setIsLoading(true)
    const id = toast.loading('Saving Pv...')
    const result = await addPvRequest(payload, transportRequest.id)
    setIsLoading(false)
    if (!Boolean(result)) {
      toast.dismiss(id);
      return toast.error('ERROR: The pv did not save.');
    } else {
      toast.dismiss(id);
      toast.success("The pv was succesfully saved. Loading new pv...");
      const currentDate = new Date()
      const currentDateString = currentDate.toString()
      const newPV: TransportPvType = {
        pvType: pvType,
        plateNumber: registrationNumber,
        brand: brand,
        model: model,
        beneficiaryCarKm: parseInt(km),
        observations: obs,
        email: email,
        clientRepresentative: nameClient,
        supplierRepresentative: nameProvider,
        createdDate: currentDateString,
        id: result.id || 0,
        transportRequestId: transportRequest.id,
        url: result.url,
        userCanDelete: false,
      }
      updateListWithNewPhotos([newPV])
      setProcessingFiles(true)
    }
    setModalVisible(false)
  }

  const isButtonDisabled = useMemo(() => {
    if (
      isLoading ||
      !registrationNumber ||
      !brand ||
      !model ||
      !obs || ((obs || '').length > pvRemarksMaxLength) ||
      !email ||
      !clientSignature ||
      !providerSignature
    ) {
      return true;
    }

    return false;
  }, [
    registrationNumber,
    brand,
    model,
    obs,
    email,
    clientSignature,
    providerSignature,
    isLoading,
  ]);

  const ActionButtons = () => {
    return (
      <div className='mt-8' style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          className='btn btn-secondary me-5'
          disabled={Boolean(isLoading)}
          onClick={() => {
            setModalVisible(false)
          }}
        >
          Cancel
        </button>
        <button
          className='btn btn-success'
          disabled={isButtonDisabled}
          onClick={onSave}
        >
          Save
        </button>
      </div>
    )
  }

  return (
    <div className='py-4 px-6 w-100 h-100'>
      <span style={{ fontSize: '16px', fontWeight: '500' }}>Add an pv</span>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        className='mt-4'
      >
        {/* Change Photo Type */}
        <Container fluid className='addPvContainer'>
          <Row>
            <Col>
              <label className='form-label'>PV Type</label>
              <SelectObject
                styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
                simpleValue={pvType}
                options={pvTypes}
                onChange={(value: string) => setPvType(value)}
                getOptionValue={(photoType: DocumentVariantType) => photoType.name}
                getOptionLabel={(photoType: DocumentVariantType) => photoType.title}
              />
            </Col>
            <Col>
              <Input
                label='Plate number'
                value={registrationNumber}
                onChange={(event) => {
                  setRegistrationNumber(event.target.value)
                }} />
            </Col>
            <Col>

              <Input
                label='Brand'
                value={brand}
                onChange={(event) => {
                  setBrand(event.target.value)
                }} />
            </Col>
          </Row>

          <Row>
            <Col>
              <Input
                label='Model'
                value={model}
                onChange={(event) => {
                  setModel(event.target.value)
                }} />
            </Col>
            <Col >
              <Input
                label='Km'
                value={km}
                onChange={(event) => {
                  setKm(event.target.value)
                }} />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextareaComponent
                label='Remarks'
                value={obs}
                maxLength={pvRemarksMaxLength}
                additionalInfo={`characters ${(obs || '').length}/${pvRemarksMaxLength}`}
                onChange={(event) => {
                  setObs(event.target.value)
                }} />
            </Col>
          </Row>
          <Row>
            <Col >
              <Input
                label='Client Name'
                value={nameClient}
                onChange={(event) => {
                  setNameClient(event.target.value)
                }} />
            </Col>
            <Col >
              <Input
                label='Email'
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value)
                }} />
            </Col>
            <Col className='justify-content-center'>
              <button disabled={clientSignature} className={`btn ${clientSignature ? 'btn-secondary' : 'btn-primary'} me-1`} onClick={() => setShowClientSignaturePad(true)}>Signature</button>
              <br />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                label='Provider Name'
                value={nameProvider}
                onChange={(event) => {
                  setNameProvider(event.target.value)
                }} />
            </Col>
            <Col>
              <button disabled={providerSignature} className={`btn ${providerSignature ? 'btn-secondary' : 'btn-primary'} me-1`} onClick={() => setShowProviderSignaturePad(true)}>Signature</button>
              <br />
            </Col>
          </Row>
        </Container>
        {showClientSignaturePad ?
          <Signature
            title="Create Client Signature"
            setSignature={setClientSignature}
            openModal={showClientSignaturePad}
            setOpenModal={setShowClientSignaturePad} />
          : null}

        {showProviderSignaturePad ?
          <Signature
            title="Create Provider Signature"
            setSignature={setProviderSignature}
            openModal={showProviderSignaturePad}
            setOpenModal={setShowProviderSignaturePad} />
          : null
        }
      </div>
      <ActionButtons />
    </div>
  )
}

export default AddPvModal
import React, { useState } from 'react'
import { RequestMethod, orbitRequest } from '../../../../../utils/api.utils'
import { APIResponseType } from '../../../../../types/general.types'
import { toast } from 'react-toastify'
import { FlatSubscriptionType } from '../../../../../types/subscription.types'

export const deleteMultipleSubscriptions = async (subscriptionIds: number[]): Promise<APIResponseType> =>
  await orbitRequest({
    route: '/subscription/deleteMultiple',
    payload: { ids: subscriptionIds },
    method: RequestMethod.POST,
  })

const DeleteAllModal = ({
  subscriptions,
  setModalVisible,
  triggerAdditionalEffect,
}: {
  subscriptions: FlatSubscriptionType[],
  setModalVisible: (_: boolean) => void
  triggerAdditionalEffect: any
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <div className='py-5 px-8' style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ textAlign: 'center', fontSize: '16px' }}>
        Sigur dorești să ștergi subscripțiile următoare {`(${subscriptions.length})`}?
      </span>
      <div className='mt-8' style={{
        display: 'flex',
        maxHeight: '200px',
        width: '450px',
        overflow: 'auto',
        flexFlow: 'column',
        gap: '10px',
        background: '#f6f6f6'
      }}>
        {subscriptions.map(s => (<div key={s.id} style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
          <span><b>Id:</b> {s.id}</span>
          <span className="px-2"><b>Company:</b> {s.main_company_name || s.company_name}</span>
          <span><b>Client:</b> {s.client_name}</span>
        </div>))}
      </div>
      <div className='mt-8' style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          disabled={isLoading}
          className='btn btn-secondary me-5'
          onClick={() => setModalVisible(false)}
        >
          Cancel
        </button>
        <button
          disabled={isLoading}
          className='btn btn-danger'
          onClick={async () => {
            setIsLoading(true)

            const result = await deleteMultipleSubscriptions(subscriptions.map(s => s.id))
            if (!result || result.error) {
              toast.error(`Subscripțiile nu au putut fi șterse! ${result?.message}`)
            } else {
              triggerAdditionalEffect()
              toast.success('Subscripțiile au fost ștearse cu succes!')
            }

            setIsLoading(false)
            setModalVisible(false)
          }}
        >
          Delete
        </button>
      </div>
    </div>
  )
}

export default DeleteAllModal
import _ from 'lodash'
import { sanitizeAndFormatString } from './string.utils'
import moment from 'moment'

export interface FlexibleObject {
  [key: string]: any
}

export const findFalseEntry = (obj: FlexibleObject): [string, any] | null => {
  const entries = Object.entries(obj)
  for (const [key, value] of entries) {
    if (!Boolean(value)) {
      return [key, value]
    }
  }
  return null
}

export const updateField = ({
  value,
  fieldName,
  setObject,
}: {
  value: any
  fieldName: string
  setObject: Function
}) => {
  setObject((oldObject: any) => {
    const editedObject = { ...oldObject }
    editedObject[fieldName] = value
    return editedObject
  })
}

export const replaceListElem = ({
  listElem,
  newListElem,
  oldList,
}: {
  listElem: any
  newListElem: any
  oldList: any[]
}) => {
  let elemIndex = oldList.findIndex((elem: any) => _.isEqual(elem, listElem))
  let newList = [...oldList]
  newList[elemIndex] = newListElem
  return newList
}

export const deleteListElem = ({ listElem, oldList }: { listElem: any; oldList: any[] }) => {
  let newList = oldList.filter((elem: any) => !_.isEqual(elem, listElem))
  return newList
}

export const onChangeListHasEmpty = ({
  newValues,
  emptyValue,
  entity,
  setEntity,
  property,
}: {
  newValues: any[]
  emptyValue: any
  entity: any
  setEntity: Function
  property: string
}) => {
  let futureValues = [...(newValues || [])]

  // check if we had selected only the EMPTY option, if yes, we remove and replace it
  let oldValues = entity[property] || []
  if (oldValues.length === 1 && _.isEqual(oldValues[0], emptyValue)) {
    futureValues = futureValues.filter((service: any) => !_.isEqual(service, emptyValue))
  }

  // check if we selected the EMPTY option, if yes, the list will be only the empty option
  futureValues = futureValues.some((value: any) => _.isEqual(value, emptyValue))
    ? [emptyValue]
    : futureValues

  let newEntity = { ...entity }

  newEntity[property] = futureValues

  setEntity(newEntity)
}

// initialize objects from a list with empty for given properties
export const getInitializedObjectListWithEmpty = ({
  initialObjectList,
  propsWithEmpty,
}: {
  initialObjectList: any[]
  propsWithEmpty: { property: string; emptyValue: any }[]
}): any[] => {
  let futureObjectList = [...initialObjectList]
  futureObjectList.forEach((object: any, index: number) => {
    let futureObject = { ...object }
    propsWithEmpty.forEach(({ property, emptyValue }) => {
      if (Array.isArray(object[property]) && object[property].length === 0) {
        futureObject[property] = [emptyValue]
      } else if (!Boolean(object[property])) {
        futureObject[property] = emptyValue
      }
    })
    futureObjectList[index] = futureObject
  })
  return futureObjectList
}

export const getObjectListWithEmptyStripped = ({
  objectList,
  propsWithEmpty,
}: {
  objectList: any[]
  propsWithEmpty: { property: string; emptyValue: any }[]
}) => {
  let futureObjectList = [...objectList]
  futureObjectList.forEach((object: any, index: number) => {
    let futureObject = { ...object }
    propsWithEmpty.forEach(({ property, emptyValue }) => {
      if (
        Array.isArray(object[property]) &&
        object[property].length === 1 &&
        _.isEqual(object[property][0], emptyValue)
      ) {
        futureObject[property] = []
      } else if (_.isEqual(object[property], emptyValue)) {
        futureObject[property] = null
      }
    })
    futureObjectList[index] = futureObject
  })
  return futureObjectList
}

export const areObjectsSimilar = ({
  objectA,
  objectB,
  fields,
}: {
  objectA: any
  objectB: any
  fields?: string[]
}) => {
  if (!fields) return _.isEqual(objectA, objectB)
  return fields.every(
    (field: string) =>
      objectA.hasOwnProperty(field) &&
      objectB.hasOwnProperty(field) &&
      _.isEqual(objectA[field], objectB[field])
  )
}

export const areObjectsEqual = ({
  objectA,
  objectB,
}: {
  objectA: { [label: string]: any }
  objectB: { [label: string]: any }
}) => {
  if (!Boolean(objectA) || !Boolean(objectB)) {
    return false
  }
  let keysOfA = Object.keys(objectA)
  let keysOfB = Object.keys(objectB)
  if (keysOfA.length !== keysOfB.length) {
    return false
  }
  return keysOfA.every((key: string) => _.isEqual(objectA[key], objectB[key]))
}

export const epsilon = 0.0001

export const isNotValid = (val: any) => val == null

export const getDefaultOption = (field, localData) => {
  let defaultOption = {}
  field.options.forEach((option) => {
    if (option.value === localData[field.backend_property]) {
      defaultOption = option;
      return;
    }
  })
  return defaultOption
}

export const createDictFromObjectArray = (array: any[], keyProp: string) => {
  const futureDict = array.reduce((dict, elem) => {
    const key = elem[keyProp] || ''
    if (key) {
      dict[key] = elem
    }
    return dict
  }, {})
  return futureDict
}

export const getArrayOfUniqueElems = (array: any[]) => {
  const uniqueElems = new Set(array)
  return Array.from(uniqueElems)
}

export function getObjectWithSanatizedKeys<ReturnType>(inputObject: Object): ReturnType {
  const sanitizedObject = {};

  for (const key in inputObject) {
    // Sanitize the key (e.g., remove spaces and convert to lowercase)
    const sanitizedKey = sanitizeAndFormatString(key, 'lower');

    // Add the sanitized key and the corresponding value to the new object
    sanitizedObject[sanitizedKey] = inputObject[key];
  }

  return sanitizedObject as ReturnType;
}

export type StateSetter<Type> = React.Dispatch<React.SetStateAction<Type>>

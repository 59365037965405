export const defaultTemplate = {
  name: 'New template',
  panels: [
    {
      name: 'client',
      fields: [
        { name: 'clientSpecs', hidden: false },
        { name: 'code', hidden: false },
        { name: 'idCompanyClient', hidden: false },
        { name: 'requestorName', hidden: false },
        { name: 'requestorPhone', hidden: false },
        { name: 'requestorEmail', hidden: false },
        { name: 'companyCustomField1Value', hidden: false },
        { name: 'companyCustomField2Value', hidden: false },
        { name: 'companyCustomField3Value', hidden: false },
        { name: 'companyCustomField4Value', hidden: false },
        { name: 'companyCustomField5Value', hidden: false },
        { name: 'promoCode', hidden: false },
        { name: 'repeat', hidden: false },
      ],
    },
    {
      name: 'request',
      fields: [
        { name: 'effectiveDateString', hidden: false },
        { name: 'idPoiOrigin', hidden: false },
        { name: 'originCoords', hidden: false },
        { name: 'origin', hidden: false },
        { name: 'originAdditionalInfo', hidden: false },
        { name: 'originGeoFence', hidden: false },
        { name: 'idPoiDestination', hidden: false },
        { name: 'destinationCoords', hidden: false },
        { name: 'destination', hidden: false },
        { name: 'destinationAdditionalInfo', hidden: false },
        { name: 'destinationGeoFence', hidden: false },
        { name: 'intermediaryPoints', hidden: false },
        { name: 'courseMap', hidden: false },
      ],
    },
    {
      name: 'details',
      fields: [
        { name: 'type', hidden: false },
        { name: 'idTariffService', hidden: false },
        { name: 'paymentTypeString', hidden: false },
        { name: 'currencyString', hidden: false },
        { name: 'paymentStatusString', hidden: false },
        { name: 'idUser', hidden: false },
        { name: 'requestDateString', hidden: false },
        { name: 'initialComment', hidden: false },
        { name: 'billedCustomer', hidden: false },
        { name: 'customerAddress', hidden: false },
        { name: 'paymentNote', hidden: false },
        { name: 'invoiceNumber', hidden: false },
        { name: 'freeCancellation', hidden: false }
      ],
    },
    {
      name: 'tariff',
      fields: [
        { name: 'tariffString', hidden: false },
        { name: 'blockTariff', hidden: false },
        { name: 'commission', hidden: false },
        { name: 'blockCommission', hidden: false },
        { name: 'supplier', hidden: false },
        { name: 'revenueCenter', hidden: false },
        { name: 'taxBreakdownTransferType', hidden: false },
        { name: 'taxBreakdownOriginAgency', hidden: false },
        { name: 'taxBreakdown', hidden: false },
        { name: 'commissionBreakdown', hidden: false },
      ]
    },
    {
      name: 'rent',
      fields: [
        { name: 'rentalPeriod', hidden: false },
        { name: 'extraOptions', hidden: false },
        { name: 'vehicleClass', hidden: false },
        { name: 'dislocationType', hidden: false },
        { name: 'insurance', hidden: false },
        { name: 'insuranceType', hidden: false },
        { name: 'discount', hidden: false },
      ]
    },
    {
      name: 'passenger',
      fields: [
        { name: 'passengerName', hidden: false },
        { name: 'language', hidden: false },
        { name: 'passengerPhone', hidden: false },
        { name: 'passengerEmail', hidden: false },
        { name: 'passengerReference', hidden: false },
        { name: 'passengers', hidden: false },
        { name: 'otherPassengers', hidden: false },
        { name: 'vip', hidden: false },
        { name: 'assignmentBlocked', hidden: false },
        { name: 'driverNotification', hidden: false },
        { name: 'greeter', hidden: false },
        { name: 'greeterSignActivations', hidden: false },
        { name: 'hasUnsolvedIssues', hidden: false },
      ],
    },
    {
      name: 'other',
      fields: [
        { name: 'requestStatusString', hidden: false },
        { name: 'idTariffType', hidden: false },
        { name: 'duration', hidden: false },
        { name: 'systemEstimatedDuration', hidden: false },
        { name: 'systemEstimatedDurationBreakdown', hidden: false },
        { name: 'completeEstimateDateString', hidden: false },
        { name: 'distance', hidden: false },
        { name: 'stops', hidden: false },
        { name: 'stopsCost', hidden: false },
        { name: 'wait', hidden: false },
        { name: 'waitCost', hidden: false },
        { name: 'estimatedWait', hidden: false },
        { name: 'idVehicleAssigned', hidden: false },
        { name: 'idDriverAssigned', hidden: false },
        { name: 'transferTypeString', hidden: false },
        { name: 'comment', hidden: false },
      ],
    },
    {
      name: 'sla',
      fields: [
        { name: 'requestRemarkString', hidden: false },
        { name: 'dispatchComment', hidden: false },
        { name: 'delayAnnounced', hidden: false },
        { name: 'announcedDelay', hidden: false },
        { name: 'responsible', hidden: false },
      ],
    },
    {
      name: 'subscriptions',
      fields: [
        { name: 'searchSubscriptions', hidden: false },
        { name: 'displaySubscriptions', hidden: false },
      ]
    },
    {
      name: 'vehicle',
      fields: [
        { name: 'assistanceVehiclePlateNumber', hidden: false },
        { name: 'assistanceVehicleVin', hidden: false },
        { name: 'assistanceVehicleBrand', hidden: false },
        { name: 'assistanceVehicleModel', hidden: false },
        { name: 'assistanceVehicleType', hidden: false },
        { name: 'assistanceVehicleManufactureYear', hidden: false },
        { name: 'assistanceVehicleDepartment', hidden: false },
        { name: 'assistanceVehicleLocation', hidden: false },
        { name: 'assistanceVehicleReplacementClass', hidden: false },
        { name: 'assistanceVehicleReplacementDays', hidden: false },
        { name: 'assistanceVehicleRoadAssistDescription', hidden: false },
        { name: 'assistanceVehicleDtdDescription', hidden: false },
        { name: 'replacementVehiclePlateNumber', hidden: false },
        { name: 'replacementVehicleBrand', hidden: false },
        { name: 'replacementVehicleModel', hidden: false },
        { name: 'replacementVehicleLocation', hidden: false },
        { name: 'replacementVehicleReturnEstimateDateString', hidden: false },
        { name: 'emissions', hidden: false },
        { name: 'photos', hidden: false },
        { name: 'pvs', hidden: false }
      ],
    },
    {
      name: 'availability',
      fields: [
        { name: 'applicantId', hidden: false },
        { name: 'denierId', hidden: false },
        { name: 'delayerDriver', hidden: false },
        { name: 'alert', hidden: false },
      ],
    },
    {
      name: 'ticket',
      fields: [
        { name: 'startOdometer', hidden: false },
        { name: 'endOdometer', hidden: false },
        { name: 'driverKm', hidden: false },
        { name: 'gpsKm', hidden: false },
        { name: 'startTime', hidden: false },
        { name: 'endTime', hidden: false },
        { name: 'googleReadyDistance', hidden: false },
        { name: 'gpsReadyDistance', hidden: false },
        { name: 'driverTransportStops', hidden: false },
        { name: 'systemTransportStops', hidden: false },
        { name: 'gpsMonitored', hidden: false },
      ]
    }
  ],
  columns: []
}
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import debounce from 'lodash.debounce'
import { UserContext } from '../../../context/user.context'
import { KTSVG } from '../../../_metronic/helpers'
import Loading from '../../../_metronic/layout/components/loading/Loading'
import ModalPortal from '../../../_metronic/layout/components/modal/modal-portal'
import { SelectObject } from '../../../_metronic/layout/components/select/Select'
import { TariffPlanType } from '../../add-edit-plan/modules/PlanTypes'
import { clonePlan, deletePlan, getPlans } from './PlanRequests'
import Pagination from '../../../app/modules/transport-requests/Pagination'
import { colourStyles } from '../../../utils/select.styles.utils'
import { AppContext } from '../../../context/app.context'
import { Card } from '../../../app/modules/add-edit-transport/scripts/structuralFields'
import { StateSetter } from '../../../utils/state.utils'
import { displayDate, getNextValidDate, plansWithBillingDateOptions } from '../utils'

const HeaderRow: FC = () => {
  return (
    <tr className='fw-bolder text-muted'>
      {/* id, name, customer id, customer name, from, currency, updatedAt, updatedBy */}
      <th>Id.</th>
      <th className='min-w-140px'>Name</th>
      <th className='min-w-100px' style={{ whiteSpace: 'nowrap' }}>
        Customer Id
      </th>
      <th className='min-w-100px' style={{ whiteSpace: 'nowrap' }}>
        Customer Name
      </th>
      <th className='min-w-100px' style={{ whiteSpace: 'nowrap' }}>
        Customer Type
      </th>
      <th className='min-w-190px' style={{ whiteSpace: 'nowrap' }}>
        From
      </th>
      <th className='min-w-100px'>Currency</th>
      <th className='min-w-100px'>Source</th>
      <th className='min-w-190px' style={{ whiteSpace: 'nowrap' }}>
        Auto Billing
      </th>
      <th className='min-w-190px' style={{ whiteSpace: 'nowrap' }}>
        Updated At
      </th>
      <th className='min-w-100px'>Updated By</th>
      <th className='min-w-100px' style={{ paddingLeft: '44px' }}>
        Actions
      </th>
      <th></th>
    </tr>
  )
}

const PlanRow: FC<{
  plan: TariffPlanType
  setPlanToBeCloned: StateSetter<TariffPlanType>
  setPlanToBeDeleted: StateSetter<TariffPlanType>
}> = ({ plan, setPlanToBeCloned, setPlanToBeDeleted }) => {
  const billingDate = useMemo(() => getNextValidDate(plan?.autoBillingDate ? parseInt(plan.autoBillingDate + '') : 0), [plan?.autoBillingDate])
  if (!Boolean(plan)) {
    return null
  }
  return (
    <tr>
      <td>{plan.id}</td>
      <td>{plan.name}</td>
      <td>{plan.customer?.id}</td>
      <td>{plan.customer?.name}</td>
      <td>
        {plan.customer?.client
          ? <span style={{ minWidth: 66 }} className="badge badge-primary">Client</span>
          : <span style={{ minWidth: 66 }} className="badge badge-info">Supplier</span>
        }
      </td>
      <td>{displayDate(plan.from)}</td>
      <td>{plan.currency}</td>
      <td>{plan.currencySource}</td>
      <td>{!plan.autoBillingEnabled ? 'NO' : billingDate}
      </td>
      <td>{displayDate(plan.updatedAt)}</td>
      <td>{plan.updatedBy}</td>
      <td className='text-end'>
        <div style={{ display: 'flex' }}>
          <button
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={() => {
              setPlanToBeCloned(plan)
            }}
          >
            <KTSVG path='/media/icons/duotone/General/Duplicate.svg' className='svg-icon-3' />
          </button>
          <Link
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm mx-2'
            to={`../add-edit-plan/${plan.customer?.id}/${plan.id}`}
          >
            <KTSVG path='/media/icons/duotone/Communication/Write.svg' className='svg-icon-3' />
          </Link>
          <button
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={() => {
              setPlanToBeDeleted(plan)
            }}
          >
            <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
          </button>
        </div>
      </td>
    </tr>
  )
}

const Filters = ({
  searchToken,
  setSearchToken,
  companies,
  selectedCompany,
  setSelectedCompany,
  billingDateOption,
  setBillingDateOption,
}: {
  searchToken: string
  setSearchToken: Function
  companies: any[]
  selectedCompany: any
  setSelectedCompany: Function
  billingDateOption: boolean | null
  setBillingDateOption: StateSetter<boolean | null>
}) => {
  return (
    <Card name='Filter'>
      {/* Search */}
      <div className='col py-3'>
        <label className='fw-bolder form-label pb-2 pt-1'>Search</label>
        <div className='input-group input-group-sm d-flex justify-content-center justify-content-xl-start'>
          <span className='input-group-text'>
            <KTSVG
              path='/media/icons/duotone/General/Search.svg'
              className='svg-icon-2 svg-icon-gray-500'
            />
          </span>
          <input
            style={{ height: '38px' }}
            placeholder='Name...'
            className='form-control'
            type='text'
            value={searchToken}
            onChange={(e) => {
              setSearchToken(e.target.value)
            }}
          />
        </div>
      </div>
      <div className='col py-3'>
        <SelectObject
          className='min-w-120px'
          styles={colourStyles}
          label='Customers'
          value={selectedCompany}
          options={companies}
          isClearable={true}
          getOptionValue={(e: any) => e}
          getOptionLabel={(e: any) => e.name}
          onChange={(value: any) => {
            setSelectedCompany(value)
          }}
        />
      </div>
      <div className='col py-3'>
        <SelectObject
          className='min-w-120px'
          label='Billing Date Options'
          simpleValue={billingDateOption}
          options={plansWithBillingDateOptions}
          getOptionValue={(e: any) => e.value}
          getOptionLabel={(e: any) => e.name}
          onChange={(value: any) => {
            setBillingDateOption(value)
          }}
        />
      </div>
    </Card>
  )
}

const DeleteModal = ({
  planToBeDeleted,
  setPlanToBeDeleted,
  setModalVisible,
  businessProfileId,
  triggerRecalculation,
}: {
  planToBeDeleted: any
  setPlanToBeDeleted: (plan: any) => void
  setModalVisible: (modalVisible: boolean) => void
  businessProfileId: string
  triggerRecalculation: Function
}) => {
  return (
    <div className='py-5 px-8' style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ textAlign: 'center', fontSize: '16px' }}>
        Are you sure you want to delete plan "{planToBeDeleted.name}" with id {planToBeDeleted.id}?
      </span>
      <div className='mt-8' style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          className='btn btn-secondary me-5'
          onClick={() => {
            setPlanToBeDeleted(null)
            setModalVisible(false)
          }}
        >
          Cancel
        </div>
        <div
          className='btn btn-danger'
          onClick={async () => {
            let deleted = await deletePlan({
              businessProfileId: businessProfileId,
              companyId: planToBeDeleted.customer?.id,
              planId: planToBeDeleted.id,
            })
            if (deleted) {
              triggerRecalculation()
            }
            setPlanToBeDeleted(null)
            setModalVisible(false)
          }}
        >
          Delete
        </div>
      </div>
    </div>
  )
}

const CloneModal = ({
  planToBeCloned,
  setPlanToBeCloned,
  setModalVisible,
  businessProfileId,
}: {
  planToBeCloned: any
  setPlanToBeCloned: (plan: boolean) => void
  setModalVisible: (modalVisible: boolean) => void
  businessProfileId: string
}) => {
  const history = useHistory()

  let name = planToBeCloned.name
  return (
    <div className='py-4 px-6 w-100 h-100'>
      <span style={{ fontSize: '16px', fontWeight: '500' }}>
        Clone pricing plan "{planToBeCloned.name}" with id {planToBeCloned.id}?
      </span>
      <div
        style={{
          fontSize: '16px',
          display: 'flex',
          flexDirection: 'column',
        }}
        className='mt-4'
      >
        <label>Name:</label>
        <input
          className='min-w-300px form-control'
          defaultValue={name}
          onChange={(e) => (name = e.target.value)}
        />
      </div>
      <div className='mt-8' style={{ display: 'flex', justifyContent: 'right' }}>
        <div
          className='btn btn-secondary me-5'
          onClick={() => {
            setPlanToBeCloned(null)
            setModalVisible(false)
          }}
        >
          Cancel
        </div>
        <div
          className='btn btn-info'
          onClick={async () => {
            let clone = await clonePlan({
              businessProfileId: businessProfileId,
              companyId: planToBeCloned.customer?.id,
              planId: planToBeCloned.id,
              extraInfo: { name: name },
            })
            setPlanToBeCloned(null)
            setModalVisible(false)
            if (Boolean(clone)) history.push(`../add-edit-plan/${clone.customer?.id}/${clone.id}`)
          }}
        >
          Clone
        </div>
      </div>
    </div>
  )
}

const TariffGrid: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [plans, setPlans] = useState<TariffPlanType[]>([])
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [planToBeDeleted, setPlanToBeDeleted] = useState<any>(null)
  const [planToBeCloned, setPlanToBeCloned] = useState<any>(null)
  const { businessProfileId } = useContext<any>(UserContext)
  const { getPromiseByKey } = useContext<any>(AppContext)

  const [companies, setCompanies] = useState<any>([])
  const [selectedCompany, setSelectedCompany] = useState<any>()
  const [searchToken, setSearchToken] = useState<string>('')
  const [billingDateOption, setBillingDateOption] = useState<boolean | null>(null)

  const [pageCount, setPageCount] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [resultsPerPage, setResultsPerPage] = useState<number>(25)
  // the value of the boolean is not important, it will be used just to trigger the getAndSetPlans function
  const [triggerGetPlans, setTriggerGetPlans] = useState<boolean>(false)

  useEffect(() => {
    ; (async () => {
      const result = await getPromiseByKey('companies', { businessProfileId })
      if (result?.data) {
        const futureCompanies = result.data.filter((company: any) => {
          if (company.enabled) {
            return true
          }
          return false
        })
        setCompanies(futureCompanies)
      }
    })()
  }, [])

  useEffect(() => {
    if (Boolean(planToBeDeleted)) setModalVisible(true)
  }, [planToBeDeleted])

  useEffect(() => {
    if (Boolean(planToBeCloned)) setModalVisible(true)
  }, [planToBeCloned])

  const getAndSetPlans = useCallback(
    async (
      selectedCompany: any,
      searchToken: string,
      billingDateOption: boolean | null,
      currentPage: number,
      resultsPerPage: number
    ) => {
      setIsLoading(true)
      const response = await getPlans({
        businessProfileId: businessProfileId,
        companyId: selectedCompany?.id,
        searchToken: searchToken,
        page: currentPage,
        size: resultsPerPage,
        matchingDefaultBillingDate: billingDateOption
      })
      setPlans(response.items)
      setPageCount(Math.ceil(response.totalElements / resultsPerPage))
      setIsLoading(false)
    },
    []
  )

  const debouncedChangeHandler = useMemo(() => debounce(getAndSetPlans, 600), [])

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [debouncedChangeHandler])

  useEffect(() => {
    debouncedChangeHandler(selectedCompany, searchToken, billingDateOption, currentPage, resultsPerPage)
  }, [selectedCompany, searchToken, billingDateOption, currentPage, resultsPerPage, triggerGetPlans])

  // useEffect(() => {
  //   getAndSetPlans(selectedCompany, searchToken)
  // }, [])

  return (
    <>
      {modalVisible ? (
        <ModalPortal setVisible={setModalVisible} visible={modalVisible} hasExitButton={false}>
          {planToBeDeleted ? (
            <DeleteModal
              planToBeDeleted={planToBeDeleted}
              setPlanToBeDeleted={setPlanToBeDeleted}
              setModalVisible={setModalVisible}
              businessProfileId={businessProfileId}
              triggerRecalculation={() => setTriggerGetPlans(!triggerGetPlans)}
            />
          ) : planToBeCloned ? (
            <CloneModal
              planToBeCloned={planToBeCloned}
              setPlanToBeCloned={setPlanToBeCloned}
              setModalVisible={setModalVisible}
              businessProfileId={businessProfileId}
            />
          ) : null}
        </ModalPortal>
      ) : null}
      {
        <Filters
          searchToken={searchToken}
          setSearchToken={setSearchToken}
          companies={companies}
          setSelectedCompany={setSelectedCompany}
          selectedCompany={selectedCompany}
          billingDateOption={billingDateOption}
          setBillingDateOption={setBillingDateOption}
        />
      }
      <div className={`card mb-7`}>
        {/* begin::Header */}
        <div className='card-header pt-5 border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Pricing Plans</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-300 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <HeaderRow />
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody
                style={{
                  display: isLoading ? 'table-caption' : 'table-row-group',
                }}
              >
                {isLoading ? (
                  <tr
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      flex: 1,
                    }}
                  >
                    <td>
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  (plans || []).map((plan: TariffPlanType) => (
                    <PlanRow
                      plan={plan}
                      setPlanToBeDeleted={setPlanToBeDeleted}
                      setPlanToBeCloned={setPlanToBeCloned}
                      key={'plan-' + plan.id}
                    />
                  ))
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
          <Pagination
            pageCount={pageCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            resultsPerPage={resultsPerPage}
            setResultsPerPage={setResultsPerPage}
          />
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export default TariffGrid

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState, useContext } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { PageTitle } from '../../../_metronic/layout/core'
import { TransportsContext } from '../../../context/transports.context'
import '../../../_metronic/assets/sass/plugins.scss'
import '../autonom.scss'
import { defaultTemplate } from '../../../data/template.default'
import { capitalize, getURLFormattedTemplateName } from '../../../utils/string.utils'
import ConfirmationContainer from '../../../_metronic/layout/components/fields/confirmationContainer/confirmation-container'
import { PanelFields } from './modules/RequestFields'
import HistoryTable from './modules/HistoryTable'
import { UserContext } from '../../../context/user.context'
import { toast } from 'react-toastify'
import Loading, { LoadingCard } from '../../../_metronic/layout/components/loading/Loading'
import TicketTable from './modules/TicketTable'
import { orbitRequest, RequestMethod } from '../../../utils/api.utils'
import { getCompanyRequest } from '../../../setup/axios/company.request'
import { getTransportRentInfo } from '../../../setup/axios/rent.request'
import { TransportType } from '../add-edit-transport/scripts/transportTypes'
import { getUpdatedTransportWithRentExchangeRate } from '../../../utils/transport.utils'

export const formatEntryDate = (date: string, dateFormat?: string) => {
  return moment(date).format(dateFormat || 'MMM DD HH:mm')
}

export const Card: FC<{ name: string; removePadX?: boolean; children: any }> = ({
  name,
  removePadX = false,
  children,
}) => {
  return (
    <div id={`${name}-card`} className={`${name}-card card h-100`}>
      {/* begin::Header */}
      <div className='card-header pt-5 border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{name}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className={'card-body py-3 sortable-zone' + (removePadX ? ' px-0' : '')}>{children}</div>
      {/* end::Body */}
    </div>
  )
}

const PanelRow: FC<{ panel: any; transport: TransportType }> = ({ panel, transport }) => {
  if ((panel?.name || '').toLowerCase() === "subscriptions") {
    return null
  }
  return (
    <div className='col-xl-6 col-xxl-4 pb-4'>
      <Card name={panel.name}>
        <div className='table-responsive'>
          <PanelFields panelName={panel.name} panelFields={panel.fields} transport={transport} />
        </div>
      </Card>
    </div>
  )
}

const ViewTransportPage: FC<{
  templateName: string
  template: any
  transport: TransportType
}> = ({ templateName, template, transport }) => {
  const history = useHistory()

  return (
    <>
      <ConfirmationContainer transport={transport} />
      <div className='row gy-5 g-xl-12'>
        <div
          className='page__top-buttons'
          style={{
            position: 'fixed',
            display: 'flex',
            justifyContent: 'end',
            zIndex: 1000,
            right: '90px',
            top: '-4px',
            width: 'auto',
          }}
        >
          <Link
            className='btn btn-light w-90px'
            style={{ background: 'none' }}
            to={`/add-edit-transport/${templateName.toLowerCase()}?transportIdForAction=${transport.id
              }&action=return`}
          >
            Return
          </Link>
          <Link
            className='btn btn-light w-90px'
            style={{ background: 'none' }}
            to={`/add-edit-transport/${templateName.toLowerCase()}?transportIdForAction=${transport.id
              }&action=repeat`}
          >
            Repeat
          </Link>
          <Link
            className='btn btn-light w-90px'
            style={{ background: 'none' }}
            to={`/add-edit-transport/${templateName}/${transport.id}`}
          >
            Edit
          </Link>
        </div>
        {template.panels.map((panel) => (
          <PanelRow key={'panelRow-' + panel.name} panel={panel} transport={transport} />
        ))}
      </div>
      {(transport.histories || []).length > 0 && <HistoryTable histories={transport.histories} />}
      {(transport.transports || []).length > 0 && (
        <TicketTable tickets={transport.transports} id={transport.id} />
      )}
    </>
  )
}

const ViewTransportWrapper: FC = () => {
  const { templateName } = useParams<any>()
  const { businessProfileId } = useContext<any>(UserContext)
  const [template, setTemplate] = useState(null)
  const [transport, setTransport] = useState<any>(null)
  const { getTransports } = useContext<any>(TransportsContext)
  const { id } = useParams<any>()

  useEffect(() => {
    ; (async () => {
      if (id) {
        const result = await getTransports({ id })
        if (!Boolean(result.data) || !Array.isArray(result.data) || !Boolean(result.data[0])) {
          return toast.error('The transport was not found!')
        }
        let futureTransport = result.data[0]
        let company = futureTransport.company
        if (company?.id) {
          const companyResponse = await getCompanyRequest({ id: company.id, businessProfileId, simple: true })
          company = companyResponse?.data || company
        }
        let supplier = futureTransport.supplier
        if (supplier?.id) {
          const supplierResponse = await getCompanyRequest({ id: supplier.id, businessProfileId, simple: true })
          supplier = supplierResponse?.data || supplier
        }
        const isTransportRent: boolean = futureTransport.type === "RENT"
        let rentData = {}
        if (isTransportRent) {
          const transportRentData = await getTransportRentInfo(futureTransport.id)
          if (Boolean(transportRentData)) {
            rentData = transportRentData
            // add the rent exchange rate to the tariff details
            const rentExchangeRate = transportRentData.exchange_rate
            if (rentExchangeRate) {
              futureTransport = getUpdatedTransportWithRentExchangeRate({ transport: futureTransport, rentExchangeRate })
            }
          }
        }
        futureTransport = { ...futureTransport, company: { ...company }, supplier: { ...supplier }, rentData: { ...rentData } }
        setTransport(futureTransport)
      }
    })()
      ; (async () => {
        if (!businessProfileId) {
          return // we don't have an easytrack account
        }
        const response = await orbitRequest({
          route: '/template/get',
          method: RequestMethod.POST,
          payload: { businessProfileId },
        })
        if (!Boolean(response) || response.error) {
          return toast.error("The templates could not be retrieved")
        }

        const templates = response?.templates || []
        let template = templates.find(
          (template: any) => {
            const formatedTemplateName = getURLFormattedTemplateName(template.name)
            return formatedTemplateName === templateName
          }
        )
        if (!template) {
          toast.error(
            'The provided template was not found: switched to the default template configuration'
          )
          setTemplate(defaultTemplate)
          return null
        }
        if (typeof template.content === 'string') {
          template.content = JSON.parse(template.content)
        }
        if (!template.content) {
          toast.error(
            'The provided template was not found: switched to the default template configuration'
          )
          setTemplate(defaultTemplate)
          return null
        }
        setTemplate(template.content)
      })()
  }, [])

  return (
    <>
      <PageTitle subtitle={capitalize(templateName).replaceAll('-', ' ')} breadcrumbs={[]}>
        {`View transport ${transport?.voucher || ''}`}
      </PageTitle>
      {!Boolean(transport) || !Boolean(template) ? (
        <LoadingCard />
      ) : (
        <ViewTransportPage templateName={templateName} transport={transport} template={template} />
      )}
    </>
  )
}

export default ViewTransportWrapper

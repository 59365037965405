import {ControlledMenu, useMenuState} from '@szhsin/react-menu'
import moment from 'moment'
import {useMemo, useRef} from 'react'

const SmallDriverInfo = ({driver}: {driver: any}) => {
  const ref = useRef(null)
  const [cardProps, toggleCard] = useMenuState({transition: true})

  return (
    <div style={{flex: 1}}>
      <div
        ref={ref}
        onMouseEnter={() => {
          toggleCard(true)
        }}
        onMouseLeave={() => {
          toggleCard(false)
        }}
        onMouseMove={() => {
          toggleCard(true)
        }}
        style={{
          marginTop: 2,
          padding: '4px 4px',
          borderRadius: '5px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          className='symbol'
          style={{
            width: '24px',
            height: '24px',
          }}
        >
          <img
            src={
              driver.photoUrl || 'https://prepsec.org/wp-content/uploads/2017/09/unknown-person-icon-Image-from.png'
            }
            style={{
              width: '24px',
              height: '24px',
            }}
            alt='driver'
          />
        </div>

        <div
          style={{
            marginLeft: '8px',
            lineHeight: '14px'
          }}
        >
          <span style={{fontSize: '11px', fontWeight: 600}}>{driver.fullName}</span>
          <div style={{fontSize: '11px', opacity: 0.8}}>
            {driver.remarks && ' - ' + driver.remarks}
          </div>
        </div>
      </div>

      <ControlledMenu
        {...cardProps}
        menuStyle={{
          minWidth: '280px',
        }}
        portal={true}
        align='start'
        offsetX={-32}
        // overflow='auto'
        direction={'right'}
        arrow={true}
        anchorRef={ref}
        onMouseEnter={() => toggleCard(true)}
        onMouseLeave={() => toggleCard(false)}
        onClose={() => toggleCard(false)}
      >
        <div
          style={{
            padding: '8px 16px',
          }}
          className="selectable-hover-card"
        >
          {/* Adress */}
          {driver.address ? (
            <div className='hover-card-item' style={{flex: 1}}>
              <label>Driver address:</label>
              <p>{driver.address}</p>
            </div>
          ) : null}

          {/* Phones */}
          <div
            style={{
              display: 'flex',
            }}
          >
            {driver.phone1 ? (
              <div className='hover-card-item' style={{flex: 1}}>
                <label>Phone 1:</label>
                <p>{driver.phone1}</p>
              </div>
            ) : null}
            {driver.phone2 ? (
              <div className='hover-card-item' style={{flex: 1}}>
                <label>Phone 2:</label>
                <p>{driver.phone2}</p>
              </div>
            ) : null}
          </div>

          {/* English */}
          <div className='hover-card-item' style={{flex: 1}}>
            <label>English level:</label>
            <p>{driver.englishLevel}</p>
          </div>

          {/* Next medical check ups */}
          {driver.nextMedicalCheckUp ? (
            <div className='hover-card-item' style={{flex: 1}}>
              <label>Next medical check up:</label>
              <p>{moment(driver.nextMedicalCheckUp).format('YYYY-MM-DD')}</p>
            </div>
          ) : null}

          {/* Contract expiry date */}
          {driver.contractExpiryDate ? (
            <div className='hover-card-item' style={{flex: 1}}>
              <label>Contract expiry date:</label>
              <p>{moment(driver.contractExpiryDate).format('YYYY-MM-DD')}</p>
            </div>
          ) : null}

          {/* Next work safety instructions */}
          {driver.nextWorkSafetyInstructing ? (
            <div className='hover-card-item' style={{flex: 1}}>
              <label>Next work safety instructions:</label>
              <p>{moment(driver.nextWorkSafetyInstructing).format('YYYY-MM-DD')}</p>
            </div>
          ) : null}
        </div>
      </ControlledMenu>
    </div>
  )
}

export default SmallDriverInfo

import { createContext, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import {
  getVehiclesRequest,
  getVehicleBrandsRequest,
  getVehicleTypesRequest,
  getAllVehiclesRequest,
} from '../setup/axios/vehicles.request'
import { orbitRequest, RequestMethod } from '../utils/api.utils'

const VehiclesContext = createContext({})

function VehiclesProvider(props: any) {
  const [vehicles, setVehicles] = useState<Array<any>>([])
  const [vehicleBrands, setVehicleBrands] = useState<any[]>()
  const [vehicleTypes, setVehicleTypes] = useState<any[]>()

  const defaultRequest = async ({
    getRequest,
    setData,
    payload = {},
  }: {
    getRequest: Function
    setData: Function
    payload?: {}
  }) => {
    const result = await getRequest(payload)
    if (result?.response?.data?.error) {
      toast.error(result?.response?.data?.message || 'We have some problem with your last request!')
      return { message: result?.response?.data?.message, error: true }
    }

    if (result.data) {
      setData(result.data)
    }

    return { error: false, data: result.data }
  }

  const getVehicles = async () => {
    return defaultRequest({ getRequest: getVehiclesRequest, setData: setVehicles })
  }

  const getAllVehicles = getAllVehiclesRequest

  const getVehicleBrands = async () => {
    return defaultRequest({ getRequest: getVehicleBrandsRequest, setData: setVehicleBrands })
  }

  const getVehicleTypes = async () => {
    return defaultRequest({ getRequest: getVehicleTypesRequest, setData: setVehicleTypes })
  }

  const store = {
    getVehicles,
    vehicles,
    getAllVehicles,
    getVehicleBrands,
    vehicleBrands,
    getVehicleTypes,
    vehicleTypes,
  }

  const storeForProvider = useMemo(() => store, [store])
  return (
    <VehiclesContext.Provider value={storeForProvider}>{props.children}</VehiclesContext.Provider>
  )
}

export { VehiclesContext }
export default VehiclesProvider

import {FC, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {getCarClasses} from '../../../setup/axios/rent.request'
import {LoadingCard} from '../../../_metronic/layout/components/loading/Loading'
import {PageTitle} from '../../../_metronic/layout/core'
import {CarClassModel} from '../auth/models/CarClassModel'
import CarClassesTable from './components/car-class-table/CarClassTable'

// Declare the type for the props of the 'CarClassesPage' component
type CarClassesPageProps = {}

const CarClassesPage: FC<CarClassesPageProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [carClasses, setCarClasses] = useState<CarClassModel[]>([])

  useEffect(() => {
    getCarClasses().then((futureCarClasses: CarClassModel[] | null) => {
      if (!futureCarClasses) {
        return toast.error('The Car Classes failed to load!')
      }
      setCarClasses(futureCarClasses)
      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return <LoadingCard />
  }

  return (
    <div className='row gy-5 gx-xl-8'>
      <CarClassesTable carClasses={carClasses} className='card-xxl-stretch mb-5 mb-xl-8' />
    </div>
  )
}

// Declare the type for the props of the 'CarClassesWrapper' component
type CarClassesWrapperProps = {}

const CarClassesWrapper: FC<CarClassesWrapperProps> = () => {
  return (
    <>
      <PageTitle>Car Classes</PageTitle>
      <CarClassesPage />
    </>
  )
}

export default CarClassesWrapper

import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { API_URL, EASYTRACK_API_URL } from '../constants/api.constants'
import customAxios from '../setup/custom.axios'
import easytrackAxios from '../setup/easytrack.axios'
import { APIResponseType } from '../types/general.types'

type ApiResponseObjectType = {
  success: (response: AxiosResponse) => APIResponseType,
  error: (error: string | AxiosError) => APIResponseType
}

export const OrbitApiResponse: ApiResponseObjectType = {
  success: (response: any): APIResponseType => {
    return response
  },
  error: (error: any | string): APIResponseType => {
    // error status code
    if (error.response && error.response?.data?.message) {
      // status code
      return {
        error: true,
        message: error.response.data.message,
      }
    }

    if (error.response && error.response.status >= 500) {
      // status code
      return {
        error: true,
        message: 'A apărut o eroare! Vă rugăm reîncercați mai târziu.',
      }
    }

    return {
      error: true,
      message: typeof error === 'string' ? error : error.message,
    }
  },
}

export enum RequestMethod {
  GET,
  HEAD,
  OPTIONS,
  DELETE,
  POST,
  PUT,
  PATCH,
}

const getRequestConfigByMethod = ({
  axiosInstance,
  requestURL,
  method,
  payload = {},
  config
}: {
  axiosInstance: AxiosInstance
  requestURL: string
  method: RequestMethod
  payload?: any
  config?: any
}): Promise<AxiosResponse<any>> => {
  let request = null
  switch (method) {
    // GET/HEAD/OPTIONS
    case RequestMethod.GET: {
      request = axiosInstance.get(requestURL, config)
      break;
    }
    case RequestMethod.HEAD: {
      request = axiosInstance.head(requestURL, config)
      break;
    }
    case RequestMethod.OPTIONS: {
      request = axiosInstance.options(requestURL, config)
      break;
    }
    // POST/PUT/PATCH
    case RequestMethod.POST: {
      request = axiosInstance.post(requestURL, payload, config)
      break;
    }
    case RequestMethod.PUT: {
      request = axiosInstance.put(requestURL, payload, config)
      break;
    }
    case RequestMethod.PATCH: {
      request = axiosInstance.patch(requestURL, payload, config)
      break;
    }
    // DELETE
    case RequestMethod.DELETE: {
      request = axiosInstance.delete(requestURL, { data: payload })
      break;
    }
  }
  return request
}

const handleError = (error: AxiosError): APIResponseType => {
  let errorMessage = ''
  let errorData = null
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    errorMessage = error.response.data?.message || 'An error occurred during the request.';
    errorData = error.response.data
  } else if (error.request) {
    // The request was made but no response was received
    errorMessage = "The request failed to provide a message!"
  } else {
    // Something happened in setting up the request that triggered an Error
    errorMessage = error.message
  }
  return {
    data: errorData,
    message: errorMessage,
    error: true,
  };
}

export const EasytrackApiResponse: ApiResponseObjectType = {
  success: (response: any): APIResponseType => {
    return ({
      data: response,
      message: 'Success',
      error: false,
    })
  },
  error: handleError
}

const axiosRequest = async ({
  axiosInstance,
  apiResponseObject,
  baseRoute,
  route,
  method,
  payload = {},
  config
}: {
  axiosInstance: AxiosInstance
  apiResponseObject: ApiResponseObjectType
  baseRoute: string
  route: string
  method: RequestMethod
  payload?: any
  config?: any
}): Promise<APIResponseType> => {
  try {
    const requestURL = baseRoute + route
    const request = getRequestConfigByMethod({ axiosInstance, requestURL, method, payload, config })
    const response = await request
    return apiResponseObject.success(response.data)
  } catch (err) {
    return apiResponseObject.error(err)
  }
}

type AxiosRequestPayloadType = {
  route: string
  method: RequestMethod
  payload?: any
  config?: any
}

export const orbitRequest = async (axiosPayload: AxiosRequestPayloadType): Promise<APIResponseType> => axiosRequest({ 
  axiosInstance: customAxios,
  apiResponseObject: OrbitApiResponse,
  baseRoute: API_URL,
  ...axiosPayload
})

export const easytrackRequest = async (axiosPayload: AxiosRequestPayloadType): Promise<APIResponseType> => axiosRequest({ 
  axiosInstance: easytrackAxios,
  apiResponseObject: EasytrackApiResponse,
  baseRoute: EASYTRACK_API_URL,
  ...axiosPayload
})

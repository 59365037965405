import moment from 'moment';
import { toast } from 'react-toastify';
import { changeValueForDocumentElement } from '../../../../../utils/file.utils';
import { StateSetter, getObjectWithSanatizedKeys } from '../../../../../utils/state.utils';
import { sanitizeAndFormatString, toTitleCase } from '../../../../../utils/string.utils';
import * as XLSX from 'xlsx';
import { importTransportsRequest } from '../../../../../setup/axios/transports.request';

export enum IMPORT_SERVICE_NAME {
  TESLA = 'Tesla',
  BLUE = 'Blue',
  COMFORT = 'Comfort',
  PREMIUM = 'Premium',
  LUXURY = 'Luxury',
  VAN = 'Van',
}
const importServiceSet = new Set(Object.values(IMPORT_SERVICE_NAME));

export enum IMPORT_TRANSPORT_STATUS {
  RESERVED = 'RESERVED',
  DISPATCHED = 'DISPATCHED',
  ACCEPTED = 'ACCEPTED',
  CAB_ARRIVED_AT_PICKUP = 'CAB_ARRIVED_AT_PICKUP',
  TRIP_STARTED = 'TRIP_STARTED',
  CAB_ARRIVED_AT_DROPOFF = 'CAB_ARRIVED_AT_DROPOFF',
  TRIP_ENDED = 'TRIP_ENDED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  NO_SHOW = 'NO_SHOW',
}

type BaseTransportInfoDataXLS = {
  booking_status: string;
  pickup_time_eet: string;
  origin: string;
  destination: string;
  passenger: string;
  passenger_contact_number: string;
  account_name: string;
  account_email: string;
  vehicle_type: string;
};

export type TransportInfoDataXLS = BaseTransportInfoDataXLS & {
  no_of_passengers: string;
  price_inc_vat: string;
  markup: string;
  duration_mins: string;
  distance_km: string;
};

export type RawTransportInfoDataXLS = BaseTransportInfoDataXLS & {
  no_of_passengers: number;
  price_inc_vat: number;
  markup: number;
  duration_mins: number;
  distance_km: number;
};

export type ImportTransportEntry = {
  status: IMPORT_TRANSPORT_STATUS;
  effectiveDate: Date;
  origin: string;
  destination: string;
  passenger: string;
  passengerPhone: string;
  numberOfPassengers: number;
  accountName: string;
  accountEmail: string;
  price: number;
  markup: number;
  duration: number;
  distance: number;
  vehicleType: IMPORT_SERVICE_NAME;
};

export const getImportTransportStatusEnumFromString = (
  status: string
): IMPORT_TRANSPORT_STATUS | null => {
  const sanitizedStatus = sanitizeAndFormatString(status);
  switch (sanitizedStatus) {
    case 'RESERVED':
      return IMPORT_TRANSPORT_STATUS.RESERVED;
    case 'DISPATCHED':
      return IMPORT_TRANSPORT_STATUS.DISPATCHED;
    case 'ACCEPTED':
      return IMPORT_TRANSPORT_STATUS.ACCEPTED;
    case 'CAB_ARRIVED_AT_PICKUP':
      return IMPORT_TRANSPORT_STATUS.CAB_ARRIVED_AT_PICKUP;
    case 'TRIP_STARTED':
      return IMPORT_TRANSPORT_STATUS.TRIP_STARTED;
    case 'CAB_ARRIVED_AT_DROPOFF':
      return IMPORT_TRANSPORT_STATUS.CAB_ARRIVED_AT_DROPOFF;
    case 'TRIP_ENDED':
      return IMPORT_TRANSPORT_STATUS.TRIP_ENDED;
    case 'CANCELLED':
      return IMPORT_TRANSPORT_STATUS.CANCELLED;
    case 'COMPLETED':
      return IMPORT_TRANSPORT_STATUS.COMPLETED;
    case 'NO_SHOW':
      return IMPORT_TRANSPORT_STATUS.NO_SHOW;
    default:
      return null;
  }
};

export const statusMessage = {
  initial: 'Load an XLSX file using the button from above!\n',
  fileRemoved:
    'Warning! The last operation removed the XLSX file!\n' +
    'There is no file loaded! Please provide a valid XLSX to import transport requests!\n',
  fileLoaded: 'File successfully loaded! Please continue the import process!\n',
  loading: 'The file is being processed! The operation can take several seconds!\n',
  error:
    "The import couldn't be made! Please check again the provided file.\n" +
    'Check the import status from below for more info!\n\n',
  failedToImport:
    'No transport request was loaded!\n\n' +
    'Check the error message from below for more info!\n\n',
  success:
    'The file was successfully imported! All the transport requests were loaded in the system!\n',
};

export const readFromXLSXFile = (xlsxFile: File): Promise<TransportInfoDataXLS[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      try {
        const data = new Uint8Array(event.target.result as ArrayBufferLike);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false });
        const sanatizedObjects = jsonData.map((transportData) =>
          getObjectWithSanatizedKeys<TransportInfoDataXLS>(transportData)
        );

        const jsonRawData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        const rawSanatizedObjects = jsonRawData.map((transportData) =>
          getObjectWithSanatizedKeys<RawTransportInfoDataXLS>(transportData)
        );

        const sanatizeCompleteObjects: TransportInfoDataXLS[] = sanatizedObjects.map(
          (transportData: TransportInfoDataXLS, index) => {
            const futureTransportData = { ...transportData };
            const rawTransportsData = rawSanatizedObjects[index];
            if (!isNaN(rawTransportsData.no_of_passengers)) {
              futureTransportData.no_of_passengers = rawTransportsData.no_of_passengers + '';
            }
            if (!isNaN(rawTransportsData.price_inc_vat)) {
              futureTransportData.price_inc_vat = rawTransportsData.price_inc_vat + '';
            }
            if (!isNaN(rawTransportsData.markup)) {
              futureTransportData.markup = rawTransportsData.markup + '';
            }
            if (!isNaN(rawTransportsData.duration_mins)) {
              futureTransportData.duration_mins = rawTransportsData.duration_mins + '';
            }
            if (!isNaN(rawTransportsData.distance_km)) {
              futureTransportData.distance_km = rawTransportsData.distance_km + '';
            }

            return futureTransportData;
          }
        );

        resolve(sanatizeCompleteObjects);
      } catch (error) {
        reject(error);
      }
    };

    reader.readAsArrayBuffer(xlsxFile);
  });
};

export const convertTransportInfoToImportEntry = (
  transportInfo: TransportInfoDataXLS
): ImportTransportEntry => {
  return {
    status: getImportTransportStatusEnumFromString(transportInfo.booking_status),
    effectiveDate: moment(transportInfo.pickup_time_eet, 'M/D/YYYY h:mm:ss A').toDate(),
    origin: transportInfo.origin,
    destination: transportInfo.destination,
    passenger: transportInfo.passenger,
    passengerPhone: transportInfo.passenger_contact_number,
    numberOfPassengers: Number(transportInfo.no_of_passengers || '0'),
    accountName: transportInfo.account_name,
    accountEmail: transportInfo.account_email,
    price: Number(transportInfo.price_inc_vat || '0'),
    markup: Number(transportInfo.markup || '0'),
    duration: Math.ceil(Number(transportInfo.duration_mins || '0')),
    distance: Math.ceil(Number(transportInfo.distance_km || '0')),
    vehicleType: toTitleCase(transportInfo.vehicle_type) as IMPORT_SERVICE_NAME,
  };
};

const validateRawTransportInfo = (
  transportInfo: TransportInfoDataXLS,
  companiesSet: Set<string>
) => {
  const convertedTransportInfo = convertTransportInfoToImportEntry(transportInfo);
  if (!convertedTransportInfo?.status) {
    return {
      error: true,
      message: `The Provided BOOKING STATUS (${transportInfo?.booking_status}) is not recognized! Please check the file and update with the correct data!`,
    };
  }
  if (!companiesSet.has(transportInfo.account_name)) {
    return {
      error: true,
      message:
        `The Provided ACCOUNT NAME (${transportInfo?.account_name}) doesn't correspond to any of the companies from easytrack!` +
        `\n\nBeware that the company name is case sensitive! Please check the file and update with the correct data!`,
    };
  }
  if (!importServiceSet.has(convertedTransportInfo.vehicleType)) {
    return {
      error: true,
      message:
        `The provided VEHICLE TYPE (${transportInfo?.vehicle_type}) doesn't correspond to any of the services from easytrack!` +
        `\n\nPlease check the file and update with the correct data!`,
    };
  }
  return { error: false, convertedTransportInfo };
};

const validateDataAndConvertToImportEntryList = (
  transportDataList: TransportInfoDataXLS[],
  companiesSet: Set<string>
) => {
  const convertedTransportInfoList: ImportTransportEntry[] = [];
  let row = 1;
  for (const transportData of transportDataList) {
    const validationResponse = validateRawTransportInfo(transportData, companiesSet);
    if (validationResponse.error) {
      return { ...validationResponse, row };
    }
    convertedTransportInfoList.push(validationResponse.convertedTransportInfo);
    row++;
  }
  return { error: false, message: '', convertedTransportInfoList };
};

export type HandleImportPayload = {
  businessProfileId: number;
  transportsListFile: File;
  setTransportsListFile: StateSetter<File>;
  setIsLoading: StateSetter<boolean>;
  setImportStatusMessage: StateSetter<string>;
  setModalVisible: StateSetter<boolean>;
  setTriggerTransportUpdate: StateSetter<boolean>;
  setSubsHaveBeenImported: StateSetter<boolean>;
  companiesSet: Set<string>;
};

const handleError = ({
  response,
  setImportStatusMessage,
  setTransportsListFile,
  setIsLoading,
}: {
  response: { error: boolean; message?: string; row?: number };
  setImportStatusMessage: StateSetter<string>;
  setTransportsListFile: StateSetter<File>;
  setIsLoading: StateSetter<boolean>;
}) => {
  const rowNumber = response?.row || -1;
  const rowErrorMessage = `The error occured on the row No. ${rowNumber + 1} from the xlsx file! (Assuming the first row is the header row):\n\n`;
  const errorMessage = statusMessage.error + rowErrorMessage + response.message;
  setImportStatusMessage(errorMessage);
  changeValueForDocumentElement({ elementId: 'transportsListFile', value: '' });
  setTransportsListFile(null);
  setIsLoading(false);
};

export const handleImport = async ({
  businessProfileId,
  transportsListFile,
  setTransportsListFile,
  setIsLoading,
  setImportStatusMessage,
  setModalVisible,
  setTriggerTransportUpdate,
  setSubsHaveBeenImported,
  companiesSet,
}: HandleImportPayload) => {
  try {
    setIsLoading(true);
    setImportStatusMessage(statusMessage.loading);
    const rawTransportsData = await readFromXLSXFile(transportsListFile);
    const validationResponse = validateDataAndConvertToImportEntryList(
      rawTransportsData,
      companiesSet
    );
    if (validationResponse.error) {
      handleError({
        response: validationResponse,
        setImportStatusMessage,
        setTransportsListFile,
        setIsLoading,
      });
      return false;
    }
    const transportEntries = validationResponse.convertedTransportInfoList || [];
    const response = await importTransportsRequest(businessProfileId, transportEntries);
    if (!response || response.error) {
      handleError({
        response: response,
        setImportStatusMessage,
        setTransportsListFile,
        setIsLoading,
      });
      return false;
    }
    setSubsHaveBeenImported(true);
    toast.success(statusMessage.success);
    setTriggerTransportUpdate(true);

    setIsLoading(false);
    setModalVisible(false);
    return true;
  } catch (error) {
    setImportStatusMessage(statusMessage.failedToImport + error);
    changeValueForDocumentElement({ elementId: 'transportsListFile', value: '' });
    setTransportsListFile(null);

    setIsLoading(false);
    return false;
  }
};

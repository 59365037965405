/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  FC,
  useEffect,
  useState,
  useRef,
  useCallback,
  Children,
  useContext,
  useLayoutEffect,
} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import '../../../_metronic/assets/sass/plugins.scss'
import '../autonom.scss'
import {Redirect, useParams} from 'react-router-dom'

import moment from 'moment'
import {toast} from 'react-toastify'
import {getPayUSignature} from '../../../utils/payment.utils'
import {TransportsContext} from '../../../context/transports.context'

const userNameDetails = (user) => {
  const response = {
    fullName: user.requestorName,
    firstName: ' ',
    lastName: ' ',
  }

  try {
    response.firstName = user.requestorName.split(' ')[0] || ''
  } catch (e) {
    response.firstName = user.requestorName
  }

  try {
    response.lastName = user.requestorName.split(' ')[1] || ''
  } catch (e) {
    response.lastName = user.requestorName
  }

  return response
}

const PaymentPage = ({transport}: any) => {
  const {getTransports} = useContext<any>(TransportsContext)

  const [isLoading, setIsLoading] = useState(false)
  const {id} = useParams<any>()

  const [paymentInfo, setPaymentInfo] = useState({
    MERCHANT: 'VEHMANSY',
    ORDER_DATE: moment().format(`YYYY-MM-DD HH:mm:ss`),
    ORDER_PINFO: '',
    ORDER_PRICE_TYPE: 'GROSS',
    ORDER_QTY: '1',
    ORDER_VAT: '19',
    ORDER_SHIPPING: '0',
    ORDER_REF: '',
    ORDER_PNAME: '',
    ORDER_PCODE: '',
    ORDER_PRICE: '',
    PRICES_CURRENCY: '',
    DISCOUNT: '0',
    TESTORDER: 'FALSE',
    ORDER_HASH: '',
    PAY_METHOD: 'CCVISAMC',
    LANGUAGE: 'RO',
  })

  const [userInfo, setUserInfo] = useState<any>(null)

  const getMoreDetails = async () => {
    setIsLoading(true)

    const result = await getTransports({id: id})

    setIsLoading(false)

    if (result.error) {
      return toast.error("Error getting booking's details")
    }
    const localTransport = result.data[0]

    if (localTransport) {
      fillPaymentInfo(localTransport)
    } else {
      alert('A aparut problema A502 pentru codul ' + id)
    }
  }

  const fillPaymentInfo = async (bookingInfo) => {
    const {
      MERCHANT,
      ORDER_DATE,
      ORDER_PINFO,
      ORDER_QTY,
      ORDER_VAT,
      ORDER_SHIPPING,
      ORDER_PRICE_TYPE,
      TESTORDER,
      PAY_METHOD,
    } = paymentInfo

    const ORDER_REF = bookingInfo.id.toString()
    const ORDER_PCODE = '57332731'
    const PRICES_CURRENCY = bookingInfo.currency
    const DISCOUNT = '0'
    var ORDER_PNAME = `1 x VMS Romania Private Transfer according to booking no. ${bookingInfo.number}`
    var ORDER_PRICE = `${bookingInfo.tariff}`

    const signature = getPayUSignature({
      MERCHANT,
      ORDER_REF,
      ORDER_DATE,
      ORDER_PNAME,
      ORDER_PCODE,
      ORDER_PINFO,
      ORDER_PRICE,
      ORDER_QTY,
      ORDER_VAT,
      ORDER_SHIPPING,
      PRICES_CURRENCY,
      DISCOUNT,
      PAY_METHOD,
      ORDER_PRICE_TYPE,
      TESTORDER,
    })

    setPaymentInfo({
      ...paymentInfo,
      ORDER_HASH: signature,
      ORDER_REF,
      ORDER_PNAME,
      ORDER_PCODE,
      ORDER_PRICE,
      PRICES_CURRENCY,
      DISCOUNT,
    })

    setUserInfo({
      firstName: userNameDetails(bookingInfo).firstName,
      lastName: userNameDetails(bookingInfo).lastName,
      requestorEmail: bookingInfo.requestorEmail,
      requestorPhone: bookingInfo.requestorPhone,
    })
  }

  useEffect(() => {
    getMoreDetails()
  }, [])

  useLayoutEffect(() => {
    if (userInfo && paymentInfo) {
      // @ts-ignore
      if (document.getElementById('submit')) {
        document.getElementById('submit').click()
      }
    }
  }, [userInfo, paymentInfo])

  if (!userInfo) {
    return <div>Loading...</div>
  }

  return (
    <form id='payuform' method='post' action='https://secure.payu.ro/order/lu.php'>
      <input name='MERCHANT' value='VEHMANSY' type='hidden' />
      <br />
      <input name='ORDER_REF' value={paymentInfo.ORDER_REF} type='hidden' />
      <br />
      <input name='ORDER_DATE' value={paymentInfo.ORDER_DATE} type='hidden' /> <br />
      <input name='ORDER_PNAME[]' value={paymentInfo.ORDER_PNAME} type='hidden' /> <br />
      <input name='ORDER_PCODE[]' value={paymentInfo.ORDER_PCODE} type='hidden' /> <br />
      <input name='ORDER_PINFO[]' value={paymentInfo.ORDER_PINFO} type='hidden' /> <br />
      <input name='ORDER_PRICE[]' value={paymentInfo.ORDER_PRICE} type='hidden' /> <br />
      <input name='ORDER_QTY[]' value={paymentInfo.ORDER_QTY} type='hidden' /> <br />
      <input name='ORDER_VAT[]' value={paymentInfo.ORDER_VAT} type='hidden' /> <br />
      <input name='ORDER_SHIPPING' value={paymentInfo.ORDER_SHIPPING} type='hidden' /> <br />
      <input name='PRICES_CURRENCY' value={paymentInfo.PRICES_CURRENCY} type='hidden' /> <br />
      <input name='DISCOUNT' value={paymentInfo.DISCOUNT} type='hidden' /> <br />
      <input name='ORDER_PRICE_TYPE[]' value={paymentInfo.ORDER_PRICE_TYPE} type='hidden' /> <br />
      <input name='ORDER_HASH' value={paymentInfo.ORDER_HASH} type='hidden' /> <br />
      <input name='BILL_FNAME' value={userInfo.firstName} type='hidden' /> <br />
      <input name='BILL_LNAME' value={userInfo.lastName} type='hidden' /> <br />
      <input name='BILL_EMAIL' value={userInfo.requestorEmail} type='hidden' /> <br />
      <input name='BILL_PHONE' value={userInfo.requestorPhone} type='hidden' /> <br />
      <input name='BILL_COUNTRYCODE' value='RO' type='hidden' /> <br />
      <input name='LANGUAGE' value={paymentInfo.LANGUAGE} type='hidden' /> <br />
      <input name='PAY_METHOD' value={paymentInfo.PAY_METHOD} type='hidden' /> <br />
      <input name='TESTORDER' value={paymentInfo.TESTORDER} type='hidden' /> <br />
      <input style={{opacity: 0}} id='submit' name='submit' value='Send!' type='submit' />
    </form>
  )
}

const AddEditTemplateWrapper: FC = (params: any) => {
  const intl = useIntl()

  const {id} = useParams<any>()

  if (!id) {
    return <Redirect to='/' />
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Payment page'}</PageTitle>
      <PaymentPage />
    </>
  )
}

export default AddEditTemplateWrapper

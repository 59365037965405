/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useContext, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {UsersContext} from '../../../context/users.context'
import {PageTitle} from '../../../_metronic/layout/core'
import {defaultPaginationSize} from '../transport-requests/Pagination'
import {UsersTableComponent} from './components/users-table/UsersTable.component'
import { useLocation, useParams } from 'react-router-dom'

const UsersPage: FC = () => {
  const { id } = useParams<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageCount, setPageCount] = useState<number>(1)
  const [resultsPerPage, setResultsPerPage] = useState<number>(defaultPaginationSize)
  const [usersCount, setUsersCount] = useState<number>(1)
  const {getUsers} = useContext<any>(UsersContext)

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      const response = await getUsers({currentPage, resultsPerPage, company_id:id})
      if (!response || response.error) {
        return toast.error(`The users could not be loaded! ${response?.message}`)
      } else {
        const pagination = response.data
        setUsersCount(pagination.total_elements)
        setPageCount(pagination.total_pages)
      }
      setIsLoading(false)
    })()
  }, [currentPage, resultsPerPage])

  return (
    <div className='row gy-5 gx-xl-8'>
      <UsersTableComponent
        companyId={id}
        className='card-xxl-stretch mb-5 mb-xl-8'
        pageCount={pageCount}
        usersCount={usersCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        resultsPerPage={resultsPerPage}
        setResultsPerPage={setResultsPerPage}
      />
    </div>
  )
}

const UsersWrapper: FC = () => {
  const intl = useIntl()
  const {state} = useLocation<{companyName: string}>();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{`${intl.formatMessage({id: 'MENU.USERS'})} ${state?.companyName || ''}`}</PageTitle>
      <UsersPage />
    </>
  )
}

export default UsersWrapper

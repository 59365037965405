import React, {createContext, ReactNode, useMemo, useState} from 'react'
import { FlexibleObject } from '../utils/state.utils';

interface Field {
  id: number,
  name: string
  hidden: boolean
  label: string
}

export interface SortableListProps {
  id: string;
  items: Field[];
  panelIndex: number;
  panelMode: string,
}

export type TemplateFieldType = FlexibleObject & {
  name: string
  hidden: boolean
  label?:string
}

export type TemplatePanelType = {
  name: string
  fields: TemplateFieldType[]
}

export type TemplateColumnType = {
  label: string
  backend_property: string
  visible: Boolean
  className?: string
}

export type TemplateType = {
  name: string
  panels: TemplatePanelType[]
  columns: TemplateColumnType[]
}

type EditTemplateContextProps = {
  template: TemplateType
  setTemplate: React.Dispatch<TemplateType>
  panels: TemplatePanelType[]
  setPanels: React.Dispatch<TemplatePanelType[]>
  panelMode: 'view' | 'edit'
  setPanelMode: React.Dispatch<React.SetStateAction<'view' | 'edit'>>
  children?: ReactNode
}

const EditTemplateContext = createContext<EditTemplateContextProps>({
  template: null,
  setTemplate: null,
  panels: null,
  setPanels: null,
  panelMode: 'view',
  setPanelMode: null,
})

type EditTemplateProps = {
  children: ReactNode
}

function EditTemplateProvider(props: EditTemplateProps) {
  const [template, setTemplate] = useState<TemplateType>(null)
  const [panels, setPanels] = useState<TemplatePanelType[]>(null)
  const [panelMode, setPanelMode] = useState<'view' | 'edit'>('view')

  const store = {
    template,
    setTemplate,
    panels,
    setPanels,
    panelMode,
    setPanelMode,
  }

  const storeForProvider = useMemo(() => store, [store])
  return (
    <EditTemplateContext.Provider value={storeForProvider}>
      {props.children}
    </EditTemplateContext.Provider>
  )
}

export const useEditTemplate = () => {
  const context = React.useContext(EditTemplateContext)
  if (context === undefined) {
    throw new Error('useEditTemplate must be used within a EditTemplateProvider')
  }
  return context
}

export {EditTemplateContext}
export default EditTemplateProvider

export type TransportType = any

export type CellModeType = 'drivers' | 'unassigned'

export type FormValidatorResponseType = {
  error: boolean
  errorMessage: string
}

export type CarType = {
  acquisitionDate: string
  bodyObs: string
  bodyWear: string
  brakeObs: string
  brakeWear: string
  brand: string
  businessName: string
  idVehicle: number
  location: string
  manufactureYear: string
  model: string
  plateNumber: string
  type: string
  vin: string
  unitNumber: number
  [key: string]: any
}

export type Polygon = {
  x: number
  y: number
}

export type ConnectedGeofence = {
  area: any
  businessProfileId: number | null
  color: string | null
  connectedGeofence: null
  createdAt: string | null
  createdBy: string | null
  id: number
  name: string
  polygon: Polygon[] | null
  type: string | null
  updatedAt: string | null
  updatedBy: string | null
}
export type GeofenceType = {
  area: any
  businessProfileId: number
  color: null | string
  connectedGeofence: ConnectedGeofence | null
  createdAt: string
  createdBy: string
  id: number
  localUuId?: string
  name: string
  polygon: Polygon[]
  type: string
  updatedAt: string
  updatedBy: string
  hidden?: boolean
}

export const emptyGeofence : GeofenceType = {
  area: {},
  businessProfileId: 0,
  color: null,
  connectedGeofence: null,
  createdAt: '',
  createdBy: '',
  id: 0,
  name: '',
  polygon: [],
  type: '',
  updatedAt: '',
  updatedBy: ''
}

export type APIResponseType = {
  error: boolean
  message: string
  [key: string]: any
}
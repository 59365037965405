// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useMemo, useState, useContext, useEffect} from 'react'

import '../../app/modules/autonom.scss'

import {disableMaxWidth, enableMaxWidth} from '../../utils/style.utils'
import {PageTitle} from '../../_metronic/layout/core'
import GeofencesTable from './modules/GeofencesTable'
import GeofencesMap from './modules/GeofencesMap'
import {getGeofenceListRequest} from '../../setup/axios/geofence.request'
import {UserContext} from '../../context/user.context'
import {toast} from 'react-toastify'
import {useGeofence} from '../../context/geofence.context'
import Loading from '../../_metronic/layout/components/loading/Loading'

export const SHAPE_COLORS = [
  '#00BFFF',
  '#FF0000',
  '#FFA500',
  '#FFFF00',
  '#00FF00',
  '#0000FF',
  '#4B0082',
]
export const defaultColor = SHAPE_COLORS[0]

const GeofenceListPage: FC = () => {
  const {businessProfileId} = useContext<any>(UserContext)
  const [hasLoaded, setHasLoaded] = useState<boolean>(false)
  const {setSelectedGeofence, setGeofences} = useGeofence()

  const getGeofence = async () => {
    const result = await getGeofenceListRequest({
      businessProfile: businessProfileId,
    })

    if (result.status !== 200) {
      toast.error(result.data.message || 'Eroare la incarcarea geofencelor')
      return
    }
    setHasLoaded(true)
    setGeofences(result.data)
  }

  useEffect(() => {
    getGeofence()

    return () => {
      setSelectedGeofence(null)
    }
  }, [])

  return (
    <div>
      <div className='row gy-5 g-xl-12'>
        {hasLoaded ? (
          <div className='col-xxl-12'>
            <GeofencesMap />
            <GeofencesTable />
          </div>
        ) : (
          <div className='col-xxl-12'>
            <Loading />
          </div>
        )}
      </div>
    </div>
  )
}

const GeofenceListWrapper: FC = () => {
  useEffect(() => {
    disableMaxWidth()

    return () => {
      enableMaxWidth()
    }
  }, [])

  const isGoogleLoaded = useMemo(() => !!window.google, [!!window.google])
  return (
    <>
      <PageTitle breadcrumbs={[]}>Geofence List</PageTitle>
      {isGoogleLoaded ? <GeofenceListPage /> : null}
    </>
  )
}

export default GeofenceListWrapper

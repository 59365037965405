import {useContext, useMemo, useState} from 'react'
import {toast} from 'react-toastify'
import {TransportsContext} from '../../../context/transports.context'
import {UserContext} from '../../../context/user.context'
import {CellModeType} from '../../../types/general.types'
import Loading from '../../../_metronic/layout/components/loading/Loading'
import { getHighlightColorOddRows } from './utils'

const CellWrapper = ({
  mode,
  index,
  rowIndex,
  currentIntervalIndex,
  children,
  style,
  className,
  driver,
}: {
  mode: CellModeType
  index: number
  rowIndex?: number
  currentIntervalIndex: number
  children: React.ReactNode
  style?: any
  className?: string
  driver?: any
}) => {
  const [insideCurrentTime] = useState<boolean>(currentIntervalIndex === index)
  const [nearCurrentTime] = useState<boolean>(Math.abs(index - currentIntervalIndex) === 1)
  const {
    transportToBeAssigned,
    setTransportToBeAssigned,
    assignTransportToDriver,
    setShiftsMap,
    getShiftsMap,
  } = useContext<any>(TransportsContext)

  const {businessProfileId} = useContext<any>(UserContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isWrapperForTransportToBeAssigned = useMemo(() => {
    return transportToBeAssigned && transportToBeAssigned?.index === index && mode === 'drivers'
  }, [transportToBeAssigned])

  const backgroundColor = useMemo(() => {
    // Assign transport
    if (isWrapperForTransportToBeAssigned) {
      // Check if the transport to be assigned is in the current interval
      return '#90caf9'
    }

    // Near current time
    if (nearCurrentTime) {
      const hasRowIndexAndIsOdd = (rowIndex && rowIndex % 2 === 1)
      const highlightDarkerHue = '#c6d1e5be'
      const highlightNormalHue = '#c6d1e57d'
      return hasRowIndexAndIsOdd ? highlightDarkerHue : highlightNormalHue
    }

    // Inside current time
    if (insideCurrentTime) {
      const hasRowIndexAndIsOdd = (rowIndex && rowIndex % 2 === 1)
      const highlightDarkerHue = '#a3adc3ee'
      const highlightNormalHue = '#a3adc3cc'
      return hasRowIndexAndIsOdd ? highlightDarkerHue : highlightNormalHue
    }

    if (rowIndex != undefined || rowIndex != undefined) {
      return getHighlightColorOddRows(rowIndex)
    }

    return 'white'
  }, [currentIntervalIndex, index, transportToBeAssigned])

  const handleClick = async () => {
    if (!transportToBeAssigned) {
      return
    }
    if (transportToBeAssigned?.index !== index || !driver) {
      setTransportToBeAssigned(null)
      return
    }

    setIsLoading(true)
    const result = await assignTransportToDriver({
      transportId: transportToBeAssigned.transport.id,
      driverId: driver.idUserProfile,
      businessProfileId: driver.businessProfile.id,
      // vehicleId,
      mode: 'assign',
      bypassDriverSchedule: true,
    })

    if (result.status !== 200 || result?.data?.error) {
      setIsLoading(false)
      let message = result?.response?.message || result?.response?.data?.message || result?.message || result?.data?.message
      toast.error(message || 'Something went wrong')
      return
    }

    if (!result.data.acceptedAssign) {
      setIsLoading(false)
      toast.error('Assign was not accepted by the server')
      return
    }

    if (!result.data.requestInValidDriverSchedule) {
      // Show warning
      toast.warning("The assigned transport request is outside the driver's schedule")
    }

    const futureShifts = await getShiftsMap()
    setIsLoading(false)
    if (!futureShifts) {
      return
    }
    // set the transports with the fetched data
    setShiftsMap(futureShifts)

    setTransportToBeAssigned(null)
    setIsLoading(false)
  }

  return (
    <th
      onClick={() => {
        handleClick()
      }}
      style={{
        ...style,
        backgroundColor: backgroundColor,
        transition: '0.2s',
        opacity: transportToBeAssigned && !isWrapperForTransportToBeAssigned ? 0.5 : 1,
      }}
      className={
        `${isWrapperForTransportToBeAssigned ? 'clickable' : ''} transport-item ${
          insideCurrentTime ? 'inside-current-time' : ''
        }` + className
      }
    >
      {isLoading ? <Loading size={120} /> : children}
    </th>
  )
}

export default CellWrapper

import {useContext, useEffect, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {Card} from '../../../../../app/modules/view-transport/ViewTransport'
import {TransportsContext} from '../../../../../context/transports.context'
import { sendConfirmationEmailRequest } from '../../../../../setup/axios/transports.request'
import {ChipsTextbox, isValidEmail} from '../chipsTextbox/chips-textbox'

export const getUniqueEmailListWithNewEmails = ({
  emailList,
  newEmails,
}: {
  emailList: string[]
  newEmails: string
}) => {
  let newEmailList = emailList || []
  if (!newEmails) {
    return newEmailList
  }
  let emails = newEmails.split(';')
  emails.forEach((email: string) => {
    const newEmail = email.trim()
    if (!newEmailList.includes(newEmail)) {
      newEmailList.push(newEmail)
    }
  })
  return newEmailList
}

const ConfirmationContainer = ({transport}) => {
  const {viewInBrowserRequest} = useContext<any>(TransportsContext)
  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState(false)
  const [isLoadingViewInBrowser, setIsLoadingViewInBrowser] = useState(false)
  const [confirmationText, setConfirmationText] = useState<string>('')
  const [chipsForTO, setChipsForTO] = useState<string[]>([])
  const [chipsForCC, setChipsForCC] = useState<string[]>([])

  useEffect(() => {
    const futureEmailsForTO = [
      ...getUniqueEmailListWithNewEmails({
        emailList: [],
        newEmails: transport.requestorEmail,
      }),
    ]
    setChipsForTO(futureEmailsForTO)

    const futureEmailsForCC = [
      ...getUniqueEmailListWithNewEmails({
        emailList: [],
        newEmails: transport.passengerEmail,
      }),
    ]
    setChipsForCC(futureEmailsForCC)
  }, [transport])

  return (
    <div key='container-confirmation' className='col-12 pb-8'>
      <Card name={'Confirmation'}>
        <div className='form-floating mt-2 mb-7'>
          <ChipsTextbox
            chips={chipsForTO}
            setChips={setChipsForTO}
            fieldId={'emailsForTO'}
            fieldLabel={'Main recipient'}
          />
        </div>

        <div className='form-floating mt-2 mb-7'>
          <ChipsTextbox
            chips={chipsForCC}
            setChips={setChipsForCC}
            fieldId={'emailsForCC'}
            fieldLabel={'Emails for CC'}
          />
        </div>

        <div className='form-floating mb-7'>
          <textarea
            className='form-control pt-9 h-200px'
            placeholder='Confirmation text'
            id='confirmationText'
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
          />
          <label htmlFor='Confirmation text'>Confirmation text</label>
        </div>
        <div className='mb-7 text-end'>
          <Button
            className='btn btn-light btn-sm me-3 my-2'
            onClick={async () => {
              setIsLoadingSendEmail(true)
              const validEmailsForTO = chipsForTO.filter((email: string) => isValidEmail(email))
              if(validEmailsForTO.length === 0) {
                setIsLoadingSendEmail(false)
                return toast.warning("Please provide at least one valid email for 'TO'!")
              }
              const validEmailsForCC = chipsForCC.filter((email: string) => isValidEmail(email))
              const result = await sendConfirmationEmailRequest({
                transportRequestId: transport.id,
                emailsForTO: validEmailsForTO,
                emailsForCC: validEmailsForCC,
              })
              setIsLoadingSendEmail(false)

              if (result.data.success) {
                toast.success('Email was sent')
              } else {
                toast.error('Failed to send email: Some addresses are invalid!')
              }
            }}
            variant='light'
            disabled={isLoadingSendEmail}
          >
            {isLoadingSendEmail ? (
              <Spinner
                style={{marginRight: 8}}
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : null}
            <span>Send via email</span>
          </Button>

          <Button
            className='btn btn-light btn-sm me-3 my-2'
            onClick={async () => {
              setIsLoadingViewInBrowser(true)
              const result = await viewInBrowserRequest({id: transport.id})
              setIsLoadingViewInBrowser(false)

              const byteString = window.atob(result.data.content)
              const arrayBuffer = new ArrayBuffer(byteString.length)
              const int8Array = new Uint8Array(arrayBuffer)
              for (let i = 0; i < byteString.length; i++) {
                int8Array[i] = byteString.charCodeAt(i)
              }
              const blob = new Blob([int8Array], {type: 'application/pdf'})

              const url = URL.createObjectURL(blob)

              // to open the PDF in a new window
              window.open(url, '_blank')
            }}
            variant='light'
            disabled={isLoadingViewInBrowser}
          >
            {isLoadingViewInBrowser ? (
              <Spinner
                style={{marginRight: 8}}
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : null}
            <span>View in browser</span>
          </Button>
          {transport.tariff ? (
            <button
              onClick={() => {
                const url = window.location.origin + '/payment/' + transport.id
                window.open(url, '_blank').focus()
              }}
              type='button'
              className='btn btn-primary btn-sm ms-3 my-2'
            >
              Pay using PayU
            </button>
          ) : null}
        </div>
      </Card>
    </div>
  )
}

export default ConfirmationContainer

import React, { CSSProperties } from 'react'
import { StylesConfig } from 'react-select'
import chroma from 'chroma-js'

export const getServiceImage = (type: string): string => {
  //   TODO: Trebuie sa termin categoria caompatct suv4wk
  switch (type) {
    case 'Standard':
      return 'https://i.imgur.com/RwlpkSa.png'

    case 'Premium':
      return 'https://i.imgur.com/HDBuM4x.png'

    case 'MAX':
      return 'https://i.imgur.com/0eQ5tIW.png'

    case 'Bus':
      return 'https://i.imgur.com/38ztL9n.png'

    case 'Premium VAN':
      return 'https://i.imgur.com/PiCGUD4.png'

    case 'DriveU':
      return 'https://i.imgur.com/Vs34cg1.png'

    case 'Compact SUV 4WD':
      return 'https://i.imgur.com/f6rUCwy.png'

    case 'Minibus':
      return 'https://i.imgur.com/LPoIS5Y.png'

    case 'Luxury':
      return 'https://i.imgur.com/5lKLdwn.png'

    case 'Childseat VAN':
      return 'https://i.imgur.com/fxipCbD.png'

    default:
      return ''
  }
}

// a list of statuses on which the restyle for unsolved issues applies
export const statusListOfFinal = [
  'FINISHED',
  'COMPLETED',
  'COMPLETED POS',
  'COMPLETED_POS',
  'UNSOLVED',
]

export const getStyleForStatus = (status: string, forAssignments: boolean = false): React.CSSProperties => {
  var statusMessage = status?.trim().toUpperCase()

  var messageColor = 'white'
  var backgroundColor = 'black'

  if (statusMessage === 'ANALYZE') {
    backgroundColor = '#BD9FE1'
    messageColor = '#212121'
  }
  if (statusMessage === 'QUOTE') {
    backgroundColor = '#A379D5'
    messageColor = 'black'
  }
  if (statusMessage === 'TO BE ACCEPTED' || statusMessage === 'TO_BE_ACCEPTED') {
    backgroundColor = '#B7FFD9'
    messageColor = 'black'
  }
  if (statusMessage === 'TO BE REJECTED' || statusMessage === 'TO_BE_REJECTED') {
    backgroundColor = '#E19797'
    messageColor = '#1F1F1F'
  }
  if (statusMessage === 'NEW') {
    backgroundColor = '#99CBF5'
    messageColor = '#363636'
  }
  if (statusMessage === 'PENDING') {
    backgroundColor = '#A49B56'
    messageColor = '#171717'
  }
  if (statusMessage === 'ACKNOWLEDGED') {
    backgroundColor = '#147BD1'
  }
  if (statusMessage === 'DELAYED') {
    backgroundColor = '#FFC000'
    messageColor = '#363636'
  }
  if (statusMessage === 'READY') {
    backgroundColor = '#10069F'
  }
  if (statusMessage === 'STARTED') {
    backgroundColor = '#00C65E'
    messageColor = '#00240F'
  }
  if (
    statusMessage === 'COMPLETED' ||
    statusMessage === 'COMPLETED POS' ||
    statusMessage === 'COMPLETED_POS' ||
    statusMessage === 'POS'
  ) {
    backgroundColor = '#564AF8'
  }
  if (statusMessage === 'FINISHED') {
    backgroundColor = '#B4AEFC'
    messageColor = '#140391'
  }
  if (statusMessage === 'ALERT') {
    backgroundColor = '#C13737'
  }
  if (statusMessage === 'REJECTED') {
    backgroundColor = '#D87676'
    messageColor = 'black'
  }
  if (statusMessage === 'CANCELLED') {
    backgroundColor = '#9795B1'
    messageColor = 'black'
  }
  if (statusMessage === 'OPEN') {
    backgroundColor = '#00B0F0'
    messageColor = '#001B29'
  }
  if (statusMessage === 'UNSOLVED') {
    backgroundColor = '#FFE285'
    messageColor = '#5C4600'
  }

  // NEW SPECIAL CASE FOR ASSIGNMENTS: FOR ACKNOWLEDGED, FINISHED, COMPLETED, COMPLETED_POS
  if (forAssignments) {
    if (statusMessage === 'ACKNOWLEDGED') {
      backgroundColor = '#F0F2F5'
      messageColor = 'black'
    }
    if (statusMessage === 'FINISHED') {
      backgroundColor = '#EBEEF2'
      messageColor = 'black'
    }
    if (
      statusMessage === 'COMPLETED' ||
      statusMessage === 'COMPLETED POS' ||
      statusMessage === 'COMPLETED_POS'
    ) {
      backgroundColor = '#F5F6F7'
      messageColor = 'black'
    }
  }

  return {
    backgroundColor,
    padding: '2px 4px',
    borderRadius: '4px',
    color: messageColor,
  }
}

export const statusColorStyle: StylesConfig<any, true> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const textColor = getStyleForStatus(data.text || data.name).backgroundColor
    data.color = textColor

    const color = chroma(data.color)

    let backgroundColor: string;
    let colorValue: string;
    let cursor: string;
    let activeBackgroundColor: any;

    if (isDisabled) {
      colorValue = '#ccc';
      cursor = 'not-allowed';
    } else {
      if (isSelected) {
        backgroundColor = data.color;
        colorValue = chroma.contrast(color, 'white') > 2 ? 'white' : 'black';
      } else {
        backgroundColor = isFocused ? color.alpha(0.1).css() : undefined;
        colorValue = data.color;
      }
      cursor = 'default';
      activeBackgroundColor = isSelected ? data.color : color.alpha(0.3).css();
    }

    return {
      ...styles,
      display: 'flex',
      backgroundColor,
      color: colorValue,
      cursor,
      ':active': {
        ...styles[':active'],
        backgroundColor: activeBackgroundColor,
      },
    }
  },
  multiValue: (styles, { data }) => {
    const textColor = getStyleForStatus(data.text || data.name).backgroundColor
    data.color = textColor
    const color = chroma(data.color)
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    }
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
}

export const disablePaddingBottom = () => {
  // @ts-ignore
  const screenContainer = document.getElementById('kt_content')
  // @ts-ignore
  screenContainer.style.paddingBottom = '0px'
}

export const disableMaxWidth = () => {
  // @ts-ignore
  const screenContainer = document.getElementById('kt_content_container')
  // @ts-ignore
  screenContainer.style.maxWidth = 'none'
}

export const enableMaxWidth = () => {
  const screenContainer = document.getElementById('kt_content_container')

  if (screenContainer) {
    screenContainer.style.maxWidth = '1320px'
  }
}

export const disabledStyle: CSSProperties = { opacity: 0.6, pointerEvents: 'none', boxShadow: 'none' }

export const baseTableButtonStyle: React.CSSProperties = {
  height: '36px',
  width: '110px',
  borderRadius: '4px',
  border: 'none',
  color: 'white',
}
import { FC, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { components } from 'react-select'
import moment from 'moment'
import DatePicker, { DateObject } from 'react-multi-date-picker'
import { EASYTRACK_API_URL } from '../../../constants/api.constants'
import { TransportsContext } from '../../../context/transports.context'
import { UserContext } from '../../../context/user.context'
import Select from '../../../_metronic/layout/components/select/Select'
import easytrackAxios from '../../../setup/easytrack.axios'
import { VehiclesContext } from '../../../context/vehicles.context'
import { getSortedList } from '../../../utils/sort.utils'
import { KTSVG } from '../../../_metronic/helpers'
import { statusColorStyle } from '../../../utils/style.utils'
import { useDebounce } from '../../../hooks/useDebounce'
import { DEFAULT_STATUSES } from '../../../data/status.data'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { getNextDay } from '../../../utils/date.utils'
import { AppContext } from '../../../context/app.context'

const { Option } = components

const orderByOptions = [
  { value: 1, orderBy: 'id', orderDir: 'desc', name: 'Created at (newest)' },
  { value: 2, orderBy: 'id', orderDir: 'asc', name: 'Created at (oldest)' },
  { value: 3, orderBy: 'effectiveDate', orderDir: 'desc', name: 'Effective date (newest)' },
  { value: 4, orderBy: 'effectiveDate', orderDir: 'asc', name: 'Effective date (oldest)' },
  { value: 5, orderBy: 'effectiveDate', orderDir: 'asc', name: 'Starting from today (oldest)' },
  { value: 6, orderBy: 'company.name', orderDir: 'asc', name: 'Client Name (A-Z)' },
  { value: 7, orderBy: 'company.name', orderDir: 'desc', name: 'Client Name (Z-A)' },
]
const defaultOrderBy = orderByOptions[2]

const SearchInputComponent: FC<{ title: string; placeholder: string; value: any; setValue: any }> = ({
  title,
  placeholder,
  value,
  setValue,
}) => {
  return (
    <div className='col py-3' style={{ minWidth: 200 }}>
      <label className='fw-bolder form-label pb-2 pt-1'>{title}</label>
      <div className='input-group input-group-sm d-flex justify-content-center justify-content-xl-start'>
        <span className='input-group-text'>
          <KTSVG
            path='/media/icons/duotone/General/Search.svg'
            className='svg-icon-2 svg-icon-gray-500'
          />
        </span>
        <input
          style={{ height: '38px' }}
          placeholder={placeholder}
          className='form-control'
          type='text'
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
          }}
        />
      </div>
    </div>
  )
}

function Filter({ convertTable }: { convertTable: boolean }) {
  const { getVehicles, vehicles } = useContext<any>(VehiclesContext)
  const {
    getTransports,
    currentPage,
    companies,
    selectedCompanies,
    setSelectedCompanies,
    setCompanies,

    suppliers,
    setSuppliers,

    drivers,
    selectedDrivers,
    setSelectedDrivers,
    setDrivers,

    tariffServices,
    selectedTariffServices,
    setSelectedTariffServices,

    selectedPaymentTypes,
    selectedRevenueCenters,
    selectedTimeInterval,

    setSelectedPaymentTypes,
    setSelectedRevenueCenters,
    setSelectedTimeInterval,

    selectedVehicles,
    setSelectedVehicles,
    setTransports,
    setPageCount,
    resultsPerPage,
    setIsLoading,
    setTransportsFilterPayload,
  } = useContext<any>(TransportsContext)

  const [showMoreFilters, setShowMoreFilters] = useState(false)

  const [dictionaries, setDictionaries] = useState<any>([])
  const [selectedStatuses, setSelectedStatuses] = useState<any>(DEFAULT_STATUSES)
  const [userSearchKeyword, setUserSearchKeyword] = useState<string>('')
  const [invoiceNumberSearch, setInvoiceNumberSearch] = useState<string>('')
  const debouncedInvoiceNumberSearch = useDebounce(invoiceNumberSearch, 500)
  const [referenceSearch, setReferenceSearch] = useState<string>('')
  const debouncedReferenceSearch = useDebounce(referenceSearch, 500)
  const [divisionSearch, setDivisionSearch] = useState<string>('')
  const debouncedDivisionSearch = useDebounce(divisionSearch, 500)
  const [departmentSearch, setDepartmentSearch] = useState<string>('')
  const debouncedDepartmentSearch = useDebounce(departmentSearch, 500)
  const [serviceSearch, setServiceSearch] = useState<string>('')
  const debouncedServiceSearch = useDebounce(serviceSearch, 500)
  const [costCenterSearch, setCostCenterSearch] = useState<string>('')
  const debouncedCostCenterSearch = useDebounce(costCenterSearch, 500)
  const [selectedOrderBy, setSelectedOrderBy] = useLocalStorage<any>(
    'selectedOrderBy',
    defaultOrderBy
  )
  const [selectedTransferTypes, setSelectedTransferTypes] = useState<any>([])
  const [selectedSuppliers, setSelectedSuppliers] = useState<any>([])
  const { getPromiseByKey } = useContext<any>(AppContext)

  const timeInterval = useMemo(
    () => selectedTimeInterval.map((date: string) => new DateObject(date)),
    [selectedTimeInterval]
  )
  const setTimeInterval = useCallback((timeInterval: DateObject[]) => {
    setSelectedTimeInterval(
      (timeInterval || []).map((dateObject: DateObject) => dateObject.format())
    )
  }, [])
  const debouncedUserSearchKeyword = useDebounce(userSearchKeyword, 500)
  const { businessProfileId } = useContext<any>(UserContext)
  const getAndSetTransports = async () => {
    setIsLoading(true)

    const payload: any = {
      businessProfileId,
      resultsPerPage,
      page: currentPage,
      requestStatuses: selectedStatuses.map((requestStatus: any) => requestStatus),
      vehicleIds: selectedVehicles.map((vehicle: any) => vehicle.idVehicle),
      driverIds: selectedDrivers.map((driver: any) => driver.idUserProfile),
      clientIds: selectedCompanies.map((company: any) => company.id),
      supplierIds: selectedSuppliers.map((supplier: any) => supplier.id),
      searchText: userSearchKeyword,
      revenueCenters: selectedRevenueCenters.map((revenueCenter: any) => revenueCenter.name),
      paymentTypes: selectedPaymentTypes.map((paymentType: any) => paymentType.name),
      tariffServiceIds: selectedTariffServices.map((tariffService: any) => tariffService.id),
      pricingTransferTypes: selectedTransferTypes.map((transferType: any) => transferType.name),
      invoiceNumber: invoiceNumberSearch,
      companyCustomField1: referenceSearch,
      companyCustomField2: divisionSearch,
      companyCustomField3: departmentSearch,
      companyCustomField4: serviceSearch,
      companyCustomField5: costCenterSearch,
    }

    if (selectedOrderBy.name === 'Starting from today (oldest)') {
      payload.startDate = moment().format('YYYY-MM-DD')
    }

    payload.orderBy = selectedOrderBy.orderBy
    payload.orderDir = selectedOrderBy.orderDir

    if (Boolean(timeInterval) && Array.isArray(timeInterval) && timeInterval.length === 2) {
      payload.startDate = timeInterval[0].format()
      payload.stopDate = timeInterval[1].format()
    }
    // We will use this payload (without page and resultsPerPage) to get all transports with current filters
    setTransportsFilterPayload({ ...payload, page: undefined, resultsPerPage: undefined })

    const result = await getTransports(payload)

    setTransports(result?.data?.transportRequests || [])
    setPageCount(result?.data?.pageCount || 1)

    setIsLoading(false)
  }

  useEffect(() => {
    // Get and set drivers
    ; (async () => {
      const result = await getPromiseByKey('drivers', { businessProfileId })
      if (result.data) {
        setDrivers(
          result.data.sort((fst: any, snd: any) =>
            fst.fullName.toLowerCase().localeCompare(snd.fullName.toLowerCase())
          )
        )
      }
    })()

      // Get and set tariff companies
      ; (async () => {
        const result = await getPromiseByKey('companies', { businessProfileId })
        if (result.data) {
          const companiesList: any[] = result?.data
          const futureCompanies = []
          const futureSuppliers = []
          companiesList.forEach((company: any) => {
            if (company.client) {
              futureCompanies.push(company)
            } else {
              futureSuppliers.push(company)
            }
          })

          setCompanies(futureCompanies)
          setSuppliers(futureSuppliers)
        }
      })()

      // Get vehicles
      ; (async () => await getVehicles())()
  }, [])

  useEffect(() => {
    getAndSetTransports()
  }, [
    resultsPerPage,
    currentPage,
    selectedSuppliers,
    selectedStatuses,
    selectedVehicles,
    selectedDrivers,
    selectedCompanies,
    selectedOrderBy,
    selectedPaymentTypes,
    selectedRevenueCenters,
    selectedTimeInterval,
    selectedTariffServices,
    selectedTransferTypes,
    debouncedUserSearchKeyword,
    debouncedInvoiceNumberSearch,
    debouncedReferenceSearch,
    debouncedDivisionSearch,
    debouncedDepartmentSearch,
    debouncedServiceSearch,
    debouncedCostCenterSearch,
  ])

  useEffect(() => {
    ; (async () => {
      const result = await getPromiseByKey('dictionaries', { businessProfileId })
      if (result?.data) {
        setDictionaries(result.data)
      }
    })()
  }, [])

  const transferTypeString = {
    label: 'Status',
    value: selectedStatuses || [],
    options: (dictionaries.REQUEST_STATUSES || []).map((requestStatus: any) => ({
      value: requestStatus.name,
      text: requestStatus.title,
    })),
  }

  return (
    <div className='col-12 pb-4'>
      <div className='card h-100'>
        {!convertTable ? (
          <div className='card-header pt-5 border-0'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Filter</span>
            </h3>
          </div>
        ) : null}
        <div className='card-body py-3'>
          <div className='row'>
            <div className='col py-3' id='timeInterval' style={{ minWidth: 200 }}>
              {/* Time Interval */}
              <DateRangePicker timeInterval={timeInterval} setTimeInterval={setTimeInterval} />
            </div>

            {/* Companies */}
            <div className='col py-3' id='company'>
              <Select
                label='Company'
                value={selectedCompanies}
                options={getSortedList({ listToSort: companies || [], args: ['name'] })}
                onChange={(e: any[]) => {
                  setSelectedCompanies(e)
                }}
                isMulti={true}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.id}
              />
            </div>

            {/* Driver */}
            {!convertTable ? (
              <div className='col py-3'>
                <Select
                  label='Drivers'
                  value={selectedDrivers}
                  options={drivers}
                  onChange={(e: any[]) => {
                    setSelectedDrivers(e)
                  }}
                  isMulti={true}
                  getOptionLabel={(option: any) => option.fullName}
                  getOptionValue={(option: any) => option.idUserProfile}
                />
              </div>
            ) : null}

            {/* Vehicle */}
            {!convertTable ? (
              <div className='col py-3'>
                <Select
                  label='Vehicle'
                  value={selectedVehicles}
                  options={getSortedList({
                    listToSort: vehicles || [],
                    args: ['plateNumber', 'unitNumber'],
                  })}
                  onChange={(e: any[]) => {
                    setSelectedVehicles(e)
                  }}
                  isMulti={true}
                  getOptionLabel={(option: any) => option.businessName}
                  getOptionValue={(option: any) => option.idVehicle}
                />
              </div>
            ) : null}

            {/* Payment */}
            <div className='col py-3'>
              <Select
                label='Payment'
                value={selectedPaymentTypes}
                options={dictionaries.PAYMENT_TYPES || []}
                onChange={(e: any[]) => {
                  setSelectedPaymentTypes(e)
                }}
                isMulti={true}
                getOptionLabel={(option: any) => option.title}
                getOptionValue={(option: any) => option.name}
              />
            </div>

            {/* Tariff Service */}
            <div className='col py-3'>
              <Select
                label='Tariff Service'
                value={selectedTariffServices}
                options={tariffServices || []}
                onChange={(e: any[]) => {
                  setSelectedTariffServices(e)
                }}
                isMulti={true}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.id}
              />
            </div>

            {/* Revenue Centers */}
            {!convertTable ? (
              <div className='col py-3'>
                <Select
                  label='Revenue Center'
                  value={selectedRevenueCenters}
                  options={dictionaries.REVENUE_CENTERS || []}
                  onChange={(e: any[]) => {
                    setSelectedRevenueCenters(e)
                  }}
                  isMulti={true}
                  getOptionLabel={(option: any) => option.title}
                  getOptionValue={(option: any) => option.name}
                />
              </div>
            ) : null}

            {/* Search */}
            {!convertTable ? (
              <SearchInputComponent
                title={'Search'}
                placeholder={'Address, phone, name, email...'}
                value={userSearchKeyword}
                setValue={setUserSearchKeyword}
              />
            ) : null}

            {/* Order by */}
            {!convertTable ? (
              <div className='col py-3'>
                <Select
                  label='Order by'
                  value={selectedOrderBy}
                  options={orderByOptions}
                  onChange={(e: any[]) => {
                    setSelectedOrderBy(e)
                  }}
                  isMulti={false}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.value}
                />
              </div>
            ) : null}
          </div>

          {/* Status */}
          {!convertTable ? (
            <div className='col py-3'>
              {dictionaries && (
                <WTFMultiSelect
                  label='Status'
                  placeholder='Select status'
                  styles={statusColorStyle}
                  className='min-w-200p'
                  setFilterValues={(e) => {
                    setSelectedStatuses(e)
                  }}
                  filterName={'transferTypeString'}
                  selectObj={transferTypeString}
                />
              )}
            </div>
          ) : null}

          {!convertTable ? (
            <>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                <div
                  className='btn btn-primary px-4 my-2'
                  style={{ width: '13rem' }}
                  onClick={() => setShowMoreFilters(!showMoreFilters)}
                >
                  {showMoreFilters ? 'Hide filters' : 'Show more filters'}
                </div>
              </div>

              {showMoreFilters ? (
                <div className='row'>
                  {/* Suppliers */}
                  <div className='col py-3' id='supplier'>
                    <Select
                      label='Supplier'
                      value={selectedSuppliers}
                      options={getSortedList({ listToSort: suppliers || [], args: ['name'] })}
                      onChange={(e: any[]) => {
                        setSelectedSuppliers(e)
                      }}
                      isMulti={true}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.id}
                    />
                  </div>

                  {/* Transfer Type */}
                  <div className='col py-3'>
                    <Select
                      label='Transfer Type'
                      value={selectedTransferTypes}
                      options={dictionaries.PRICING_TRANSFER_TYPES || []}
                      onChange={(e: any[]) => {
                        setSelectedTransferTypes(e)
                      }}
                      isMulti={true}
                      getOptionLabel={(option: any) => option.title}
                      getOptionValue={(option: any) => option.name}
                    />
                  </div>

                  {/* Invoice Number */}
                  <SearchInputComponent
                    title={'Invoice Number'}
                    placeholder={'Invoice Number...'}
                    value={invoiceNumberSearch}
                    setValue={setInvoiceNumberSearch}
                  />

                  {/* Requestor Reference */}
                  <SearchInputComponent
                    title={'Requestor Reference'}
                    placeholder={'Reference...'}
                    value={referenceSearch}
                    setValue={setReferenceSearch}
                  />

                  {/* Requestor Division */}
                  <SearchInputComponent
                    title={'Requestor Division'}
                    placeholder={'Division...'}
                    value={divisionSearch}
                    setValue={setDivisionSearch}
                  />

                  {/* Requestor Department */}
                  <SearchInputComponent
                    title={'Requestor Department'}
                    placeholder={'Department...'}
                    value={departmentSearch}
                    setValue={setDepartmentSearch}
                  />

                  {/* Requestor Service */}
                  <SearchInputComponent
                    title={'Requestor Service'}
                    placeholder={'Service...'}
                    value={serviceSearch}
                    setValue={setServiceSearch}
                  />

                  {/* Requestor Cost Center */}
                  <SearchInputComponent
                    title={'Requestor Cost Center'}
                    placeholder={'Cost Center...'}
                    value={costCenterSearch}
                    setValue={setCostCenterSearch}
                  />
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const WTFMultiSelect: FC<{
  setFilterValues: any
  filterName: string
  className?: string
  style?: {}
  placeholder: string
  styles?: any
  label?: string
  selectObj: { value: any[]; options: any[] }
}> = ({
  filterName,
  selectObj,
  className = '',
  style = {},
  placeholder,
  setFilterValues,
  styles,
  label,
}) => {
    // Asta trebuie refacuta sau documentata
    const updateFilter = (selected: any) => {
      let editedFilters = selected.map((elem: any) => elem.value)
      setFilterValues(editedFilters)
    }

    // Asta trebuie refacuta sau documentata
    let options = selectObj.options.sort((fst: any, snd: any) =>
      fst.text.toLowerCase().localeCompare(snd.text.toLowerCase())
    )
    return (
      <Select
        label={label}
        placeholder={placeholder ? placeholder : 'Select'}
        components={{ Option: CustomOption }}
        key={filterName}
        id={filterName}
        className={className}
        value={options.filter((e) => {
          return selectObj.value.includes(e.value)
        })}
        styles={styles}
        name={filterName}
        isMulti={true}
        onChange={(selected: Object) => {
          updateFilter(selected)
        }}
        options={options}
        getOptionLabel={(option: any) => option.text}
      />
    )
  }

const CustomOption = (props: any) => {
  const { transportsCounter } = useContext<any>(TransportsContext)

  return (
    <Option {...props}>
      {props.data.text}{' '}
      {transportsCounter[props.label]?.value ? (
        <span
          style={{
            marginLeft: 'auto',
            backgroundColor: '#f7f7f7',
            color: '#5a5a5a',
            fontSize: '12px',
            padding: '0px 16px',
            borderRadius: 4,
            lineHeight: '20px',
            textAlign: 'center',
          }}
        >
          {transportsCounter[props.label]?.value}
        </span>
      ) : null}
    </Option>
  )
}

const convertDateObjectsToDateInput = (dates: DateObject[]): string => {
  if (!dates || dates.length < 2) {
    return ''
  }

  return (dates.map((dateObject: DateObject) => dateObject.format())).join(' ~ ')
}

export const DateRangePicker: FC<{
  timeInterval: DateObject[]
  setTimeInterval: Function
  rightInclusiveInterval?: boolean // if it is exclusive, we need to add a day to end_date to include the whole interval
  additionalOnChange?: Function
}> = ({ timeInterval, setTimeInterval, rightInclusiveInterval = false, additionalOnChange }) => {
  const datePickerRef = useRef<any>()
  const [shouldCloseCalendar, setShouldCloseCalendar] = useState<boolean>(false)
  const [datePickerValue, setDatePickerValue] = useState<any>(timeInterval)
  const [inputDateString, setInputDateString] = useState(convertDateObjectsToDateInput(timeInterval))

  const handleCloseDatePicker = () => {
    setShouldCloseCalendar(true)

    // Use setTimeout to wait 20 milliseconds before closing the calendar,
    // that it gives time to the state above to update itself
    setTimeout(() => {
      datePickerRef.current.closeCalendar()

      setShouldCloseCalendar(false)
    }, 20)

    if (timeInterval?.length < 1) {
      setDatePickerValue([])
    } else {
      setDatePickerValue(timeInterval)
    }
  }

  const handleSetTimeInterval = () => {
    if (
      !Boolean(datePickerValue) ||
      !Array.isArray(datePickerValue) ||
      datePickerValue.length === 0
    ) {
      return
    }
    // hanndle the case when it is selected only one day or the same day 2 times
    if (
      datePickerValue.length === 1 ||
      datePickerValue[0].format() === datePickerValue[1].format()
    ) {
      // increases the second day of the interval by 1
      const newDate = rightInclusiveInterval ? datePickerValue[0] : getNextDay(datePickerValue[0])
      setDatePickerValue([datePickerValue[0], newDate])
      setTimeInterval([datePickerValue[0], newDate])
    } else {
      const newDate = rightInclusiveInterval ? datePickerValue[1] : getNextDay(datePickerValue[1])
      setTimeInterval([datePickerValue[0], newDate])
    }

    setShouldCloseCalendar(true)
    setTimeout(() => {
      datePickerRef.current.closeCalendar()
      setShouldCloseCalendar(false)
    }, 20)
  }

  const onInvalidDate = () => {
    setDatePickerValue([])
    setTimeInterval([])
  }

  const onDateInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    setInputDateString(val)
    const datesSplitted = val.replaceAll(' ', '').split('~').filter(d => d.length).map(d => new DateObject(d))
    if (datesSplitted.length === 2) {
      const firstDate = datesSplitted[0]
      const secondDate = datesSplitted[1]
      const isFirstDateValid = firstDate.isValid
      const isSecondDateValid = secondDate.isValid
      const isSecondDateGreaterThanFirstDate = new Date(secondDate.format()) >= new Date(firstDate.format())
      if (isFirstDateValid && isSecondDateValid) {
        if (isSecondDateGreaterThanFirstDate) {
          const secondDateFormatted = rightInclusiveInterval ? secondDate : getNextDay(secondDate)
          setDatePickerValue(datesSplitted)
          setTimeInterval([firstDate, secondDateFormatted])
        } else {
          onInvalidDate()
        }
      } else {
        onInvalidDate()
      }
    } else {
      onInvalidDate()
    }
  }

  return (
    <>
      <p className='fw-bolder form-label pb-2 pt-1'>Time Interval</p>
      <DatePicker
        containerStyle={{ width: '100%' }}
        ref={datePickerRef}
        render={(value, openCalendar) => (
          <div className='input-group input-group-sm'>
            <input
              style={{ height: '38px' }}
              placeholder='Click to select a date...'
              className='form-control'
              defaultValue={value}
              onFocus={openCalendar}
              value={inputDateString}
              onChange={onDateInputChange}
            />
            <span className='input-group-text'>
              <button
                className={`btn-close  ${timeInterval?.length < 1 ? 'disabled' : ''}`}
                aria-label='Clear interval'
                type='button'
                onClick={() => {
                  onInvalidDate()
                  setInputDateString('')
                }}
              ></button>
            </span>
          </div>
        )}
        onClose={() => shouldCloseCalendar}
        value={datePickerValue}
        onChange={(e) => {
          setDatePickerValue(e)
          setInputDateString(e.toString().replaceAll(',', ' ~ '))
          if (typeof additionalOnChange === 'function') {
            additionalOnChange(e)
          }
        }}
        range
      >
        <button
          className='btn btn-lg fw-bolder btn-outline-danger me-2 mb-1'
          onClick={handleCloseDatePicker}
        >
          CANCEL
        </button>
        <button
          className='btn btn-lg fw-bolder btn-outline-primary ms-2 mb-1'
          onClick={handleSetTimeInterval}
        >
          OK
        </button>
      </DatePicker>
    </>
  )
}

export default memo(Filter)

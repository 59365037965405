import React from 'react';
import Lottie from 'lottie-react';
import { Card } from '../../../../app/modules/add-edit-transport/scripts/structuralFields';

export default function Loading({ text, size = 400 }: { text?: string; size?: number }) {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'column'
      }}
    >
      {text ? <p className='loadingText' style={{ marginBottom: '-50px' }}>{text}</p> : null}
      <Lottie
        animationData={require('../../../assets/lotties/loader.json')}
        style={{ width: size, height: size }}
      />
    </div>
  );
}

export const LoadingCard = ({ name = '' }: { name?: string }) => (
  <Card name={name}>
    <Loading />
  </Card>
);

import React, {createContext, useMemo} from 'react'
import {EASYTRACK_API_URL} from '../constants/api.constants'
import customAxios from '../setup/easytrack.axios'
import {logError} from '../utils/sentry.utils'

const ClientContext = createContext({})

function ClientProvider(props: any) {
  /**
   * Get client codes for autocomplete
   * Need at least 3 characters for code param
   * @param code
   * @returns
   */
  const getTransportCodes = async ({
    code,
    businessProfileId,
  }: {
    code: string
    businessProfileId?: string
  }): Promise<[]> => {
    if (!businessProfileId) {
      logError({name: 'Missing businessProfileId', error: {}, type: 'error'})
      return []
    }
    if (!code || code.length < 3) {
      return []
    }

    const result = await customAxios.get(
      `${EASYTRACK_API_URL}/transport-codes?businessProfile=${businessProfileId}&code=${code}`
    )
    if (result.status <= 299) {
      return result.data
    }

    return []
  }

  const store = {
    getTransportCodes,
  }

  const storeForProvider = useMemo(() => store, [store])
  return <ClientContext.Provider value={storeForProvider}>{props.children}</ClientContext.Provider>
}

export {ClientContext}
export default ClientProvider

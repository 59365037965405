import { Link, useHistory } from 'react-router-dom'
import '../index.css'
import { UserRole } from '../../../../context/user.context';
import { useMemo } from 'react';

const CompanyOptionsComponent = ({ id, name, userRole }: { id: number, name: string, userRole: UserRole }) => {
  const history = useHistory();
  const optionsWithPermissions = useMemo(() => {
    const newOptions = options.map((option) => {
      const newOption = {...option}
      if(Boolean(option?.userHasAccess)) {
        newOption.active = option.userHasAccess(userRole)
      }
      return newOption
    })
    return newOptions
  }, [userRole])

  return (
    <>
      <div
        // @ts-ignore
        className={`card${false ? ' card-options-active' : ''} draggable has-card-options pb-10`}
        style={{
          marginTop: 16,
        }}
      >
        <h1 className={`py-10 px-10 d-flex align-items-center text-dark fw-bolder my-1 fs-3 ${!id && 'company-card-inactive'}`}>
          Company Options
        </h1>
        <div className='px-10 company-container'>
        {optionsWithPermissions.map((option)=>{
          return <div onClick={() => { option.active && id && history.push(`${option.to}/${id}`, {companyName: name})}}  key={option.pageName} className={`company-card ${option.active && id? 'company-card-active' :'company-card-inactive'}`}>
            <div className='font-bold option'>{option.pageName}</div>
          </div>
        })}
        </div>
      </div>
    </>
  )
}

export default CompanyOptionsComponent

export const options = [
  {
    pageName: 'Pachete asistenta rutiera',
    active: true,
    to: '/packages',
    userHasAccess: (userRole: UserRole) => userRole === UserRole.Admin || userRole === UserRole.AdminSubscriptions
  },
  {
    pageName: 'Utilizatorii ai companiei',
    active: true,
    to:'/users',
    userHasAccess: (userRole: UserRole) => userRole === UserRole.Admin || userRole === UserRole.AdminSubscriptions
  },
  {
    pageName: 'Sucursale companie',
    active: true,
    to:`/branches`
  },
  {
    pageName: 'Rent',
    backend_property: 'address',
    active: true,
    to: `/rent-plans`
  }, 
]

import { createContext, useMemo, useState } from 'react';
import { getCompanyRequest } from '../setup/axios/company.request'
import { toast } from 'react-toastify';
import { RequestMethod, orbitRequest } from '../utils/api.utils';
import { CompanyPaginationType, CompanyType, ConglomerateCompaniesType } from '../types/company.types';

const CompanyContext = createContext({})

function CompanyProvider(props: any) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [companyInfo, setCompanyInfo] = useState<Array<any>>([]);
  const [companies, setCompanies] = useState<Array<CompanyType>>([]);
  const [companyPagination, setCompanyPagination] = useState<CompanyPaginationType>({
    page: 1,
    per_page: 25,
    total: 0,
    totalPages: 0,
  });
  const [conglomerateCompanies, setConglomerateCompanies] = useState<ConglomerateCompaniesType[]>([]);


  const getCompanyInfo = async (data: { id: number, businessProfileId: string }) => {
    const result = await getCompanyRequest(data);

    if (result?.response?.data?.error) {
      toast.error(result?.response?.data?.message || "We have some problem with your last request!")
      return { message: result?.response?.data?.message, error: true }
    }

    if (result.data) {
      setCompanyInfo(result.data);
    }

    return { error: false, data: result.data }
  }

  const getConglomerateCompanies = async () => {
    const response = await orbitRequest({
      route: '/conglomerate-companies',
      method: RequestMethod.GET
    })

    if (!response || response.error) {
      toast.error(response?.message || 'Something went wrong')
      return { message: response?.message, error: true }
    }

    if (response?.conglomerate_companies) {
      setConglomerateCompanies(response.conglomerate_companies)
      return response
    }

    return { error: false, data: response?.response?.data }
  }

  const getCompanies = async ({
    mainCompanyId,
    onlyMainCompany,
    businessProfileId,
    companyConglomerateId,
    search_token = '',
    page = 1,
    per_page = 15,
  }: {
    mainCompanyId: number,
    onlyMainCompany: number,
    businessProfileId: number,
    companyConglomerateId?: number,
    search_token?: string,
    page: number,
    per_page: number
  }) => {
    setIsLoading(true);
    // these two are complementary, they can't be both on this request
    const mainCompanyIdArg = mainCompanyId ? `?main_company_id=${mainCompanyId}&page=${page}&per_page=${per_page}` : ''
    const onlyMainCompanyArg = onlyMainCompany ? `?only_main_companies=${onlyMainCompany}&page=${page}&per_page=${per_page}` : ''

    // const isFirstArg = !Boolean(mainCompanyIdArg) && !Boolean(onlyMainCompanyArg)
    const businessProfileIdArg = businessProfileId ? `&business_profile_id=${businessProfileId}` : ''
    const searchTokenArg = search_token ? `&search_token=${search_token}` : ''
    const conglomerateCompaniesArg = companyConglomerateId ? `&company_conglomerate_id=${companyConglomerateId}` : ''
    const route = '/company' + mainCompanyIdArg + onlyMainCompanyArg + businessProfileIdArg + searchTokenArg + conglomerateCompaniesArg
    const response = await orbitRequest({
      route: route,
      method: RequestMethod.GET
    })

    if (!response || response.error) {
      toast.error(response?.message || 'Something went wrong')
      setIsLoading(false);
      return { message: response?.message, error: true }
    }

    if (response?.companies) {
      setIsLoading(false);
      setCompanies(response.companies.data);
      setCompanyPagination(oldCompanyPagination => ({
        ...oldCompanyPagination,
        total: response.companies.total,
        totalPages: response.companies.last_page,
      }));
      return response
    }

    setIsLoading(false);
    return { error: false, data: response?.response?.data }

  }

  const getCompany = async ({ id, businessProfileId }: { id: number, businessProfileId?: number }) => {
    setIsLoading(true);
    const response = await orbitRequest({
      route: `/company?id=${id}&business_profile_id=${businessProfileId}`, method: RequestMethod.GET
    })

    if (!response || response.error) {
      toast.error(response?.message || 'Something went wrong')
      setIsLoading(false);
      return { message: response?.message, error: true }
    }

    if (response?.company) {
      return response
    }

    setIsLoading(false);
    return { error: true, data: response?.response?.data }
  }

  const createOrUpdateCompany = async ({ payload }: { payload: any }) => {
    setIsLoading(true)
    const response = await orbitRequest({
      route: `/company/${payload.id ? 'update' : 'create'}`, payload: payload, method: RequestMethod.POST
    })

    if (!response || response.error) {
      toast.error(response?.message || 'Something went wrong')
      return { error: true }
    }

    toast.success(`${payload.id ? 'Company was updated!' : 'Company was created!'}`)
    return { error: false }
  }

  const deleteCompany = async ({ id }: { id: number }) => {
    const response = await orbitRequest({
      route: `/company/delete`, payload: { id: id }, method: RequestMethod.POST
    })

    if (!response || response.error) {
      return toast.error(response?.message || 'Something went wrong')
    }

    toast.success('Comapany was deleted!')
    const futureCompanies = companies.filter((companyPackage) => companyPackage.id !== id);
    setCompanies(futureCompanies);
  }

  const store = {
    getCompanyInfo,
    companyInfo,
    getCompanies,
    companies,
    createOrUpdateCompany,
    isLoading,
    setIsLoading,
    deleteCompany,
    getCompany,
    companyPagination,
    setCompanyPagination,
    conglomerateCompanies,
    getConglomerateCompanies,
  }

  const storeForProvider = useMemo(() => store, [store]);
  return <CompanyContext.Provider value={storeForProvider}>{props.children}</CompanyContext.Provider>
}

export { CompanyContext }
export default CompanyProvider

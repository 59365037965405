import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { APIResponseType } from '../../../../../types/general.types'
import { downloadImportFileMock, getSubscriptionsImportRules, importSubscriptionsRequest } from '../../../../../setup/axios/subscription.request'
import { changeValueForDocumentElement } from '../../../../../utils/file.utils'
import { SubscriptionsContext } from '../../../../../context/subscriptions.context'
import PopoverComponent from '../../../../../_metronic/layout/components/popover/popover'

const generateAndDownloadCSV = async () => {
  const response: any = await downloadImportFileMock();

  if (!response || response.error) {
    toast.error('Nu s-a putut descărca modelul de fișier de import!')
    return;
  }

  const csvContent = response;
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', 'example.csv');
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const statusMessage = {
  initial: 'Încărcați un fișier folosind câmpul de mai sus!\n'
    + 'Apăsați pe butonul "Descarcă model" pentru a vedea un exemplu!\n',
  fileRemoved: 'Atenție, ultima operație a eliminat fișierul CSV!\n'
    + 'Nu s-a încărcat un fișier! Pentru a se putea importa subscripții trebuie să se încarce un fișier CSV valid!\n',
  fileLoaded: 'Fișier încărcat cu succes! Puteți continua procesul de import!\n',
  loading: 'Fișierul se procesează! Operația poate dura căteva secunde!\n',
  error: 'Încărcarea nu s-a putut realiza! Vă rog verificați fișierul furnizat.\n'
    + 'Consultați statusul de încărcare de mai jos pentru a remedia problema.\n\n',
  failedToImport: 'Nicio subscripție nu a fost importată!\n\n'
    + 'Consultați mesajul de eroare de mai jos pentru a remedia problema.\n\n',
  success: 'Fișierul a fost importat cu succes! Subscripțiile au fost încărcate în sistem!\n'
}

const getErrorMessageFromResponse = (response: APIResponseType) => {
  const wrongEntity = response.subscription;
  const wrongEntityMessage =
    `Eroare întâmpinată la rândul ${wrongEntity.row_number + 1} din fișier!\n\n`
    + `Subscripția companiei ${wrongEntity.company_name}, cu pachetul ${wrongEntity.package}, `
    + `având data de activare ${wrongEntity.activation_date} `
    + `pentru clientul ${wrongEntity.client_name}, având vehiculul cu VIN ${wrongEntity.vin} `
    + `conține anumite informații într-un format greșit și nu se poate importa!\n\n`
  const reason = `Cauza problemei de încărcare: ${response.message}\n`
  return wrongEntityMessage + reason;
}

const handleImport = async ({
  subscriptionsListFile,
  setSubscriptionsListFile,
  setIsLoading,
  setImportStatusMessage,
  setModalVisible,
  setTriggerSubscriptionUpdate,
  setSubsHaveBeenImported
}: {
  subscriptionsListFile: File,
  setSubscriptionsListFile: Function,
  setIsLoading: Function,
  setImportStatusMessage: Function,
  setModalVisible: Function
  setTriggerSubscriptionUpdate: Function
  setSubsHaveBeenImported: Function
}) => {
  setIsLoading(true)
  setImportStatusMessage(statusMessage.loading)
  const response = await importSubscriptionsRequest(subscriptionsListFile)
  if (!response || response.error) {
    const hasSubscription = Boolean(response?.subscription);
    const errorMessage = hasSubscription
      ? statusMessage.failedToImport + getErrorMessageFromResponse(response)
      : statusMessage.error + response.message;
    setImportStatusMessage(errorMessage);

    if (hasSubscription) {
      let subscriptionInfo = response.subscription
      let rowNumberOfWrongSub = subscriptionInfo.row_number || 0
      setSubsHaveBeenImported(Boolean(rowNumberOfWrongSub > 1));
    }
    changeValueForDocumentElement({ elementId: 'subscriptionsListFile', value: '' });
    setSubscriptionsListFile(null);

    setIsLoading(false);
    return false;
  }

  setSubsHaveBeenImported(true)
  toast.success(statusMessage.success)
  setTriggerSubscriptionUpdate(true)

  setIsLoading(false)
  setModalVisible(false)
  return true;
}

const getAndSetImportRules = async ({ importRules, setImportRules }: { importRules: string, setImportRules: Function }) => {
  if (!importRules) {
    const importRulesRequest = await getSubscriptionsImportRules()
    if (!importRulesRequest || importRulesRequest.error) {
      return;
    }
    setImportRules(importRulesRequest.import_rules || '')
  }
}

const ImportSubscriptionsModal = ({
  setModalVisible,
  setTriggerSubscriptionUpdate
}: {
  setModalVisible: (modalVisible: boolean) => void
  setTriggerSubscriptionUpdate: Function
}) => {
  const [subscriptionsListFile, setSubscriptionsListFile] = useState<File | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [importStatusMessage, setImportStatusMessage] = useState<string>(statusMessage.initial)
  const [subsHaveBeenImported, setSubsHaveBeenImported] = useState<boolean>(false)
  const { importRules, setImportRules } = useContext<any>(SubscriptionsContext)

  useEffect(() => {
    getAndSetImportRules({ importRules, setImportRules })
  }, [])

  return (
    <div className='d-flex flex-column' style={{ width: '90%' }}>
      <div className='modal-header' style={{ justifyContent: 'center' }}>
        <p className='fs-2 modal-title'>
          Încarcă subscripții prin fișier
        </p>
      </div>

      <div className='d-flex flex-column modal-body'>
        {/* Load Image Input */}
        <button className='btn btn-info' onClick={generateAndDownloadCSV}>Descarcă model</button>
        <PopoverComponent trigger={
          <button
            className='btn btn-secondary mt-2'
            style={{ marginTop: '10px', width: '100%' }}
            disabled={!Boolean(importRules)}
          >Reguli de încărcare</button>
        }>
          <textarea
            className='form-control form-control-sm'
            style={{ minHeight: '300px', height: '350px', maxHeight: '400px', width: '500px', backgroundColor: '#f5f8fa' }}
            value={importRules}
            readOnly={true}
          />
        </PopoverComponent>
        <div>
          <label className='mt-4 py-1 fw-bolder'>Fișier CSV:</label>
          <input
            id='subscriptionsListFile'
            type='file'
            accept='.csv'
            style={{ width: '100%' }}
            disabled={isLoading}
            onChange={(e: any) => {
              const files = e.target.files || [];
              const futureFile = files[0];
              setSubscriptionsListFile(futureFile);
              if (!futureFile) {
                setImportStatusMessage(statusMessage.fileRemoved);
              } else {
                setImportStatusMessage(statusMessage.fileLoaded);
              }
            }}
          />
        </div>
        <div>
          <label className='mt-4 py-1 fw-bolder'>Status încărcare:</label>
          <textarea
            className='form-control form-control-sm'
            style={{ height: 250, backgroundColor: '#f5f8fa' }}
            value={importStatusMessage}
            readOnly={true}
          />
        </div>
      </div>

      <div className='modal-footer d-flex justify-content-center'>
        <button
          disabled={isLoading}
          style={{ width: '140px' }}
          className='btn btn-danger me-5'
          onClick={() => {
            if (subsHaveBeenImported) {
              setTriggerSubscriptionUpdate(true)
            }
            setModalVisible(false)
          }}
        >
          Anulează
        </button>
        <button
          disabled={!Boolean(subscriptionsListFile) || isLoading}
          style={{ width: '140px' }}
          className='btn btn-primary'
          onClick={() => handleImport({
            subscriptionsListFile,
            setSubscriptionsListFile,
            setIsLoading,
            setImportStatusMessage,
            setModalVisible,
            setTriggerSubscriptionUpdate,
            setSubsHaveBeenImported,
          })}
        >
          {isLoading
            ? <span>
              Procesare{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
            : <span>Trimite</span>}
        </button>
      </div>
    </div>
  )
}

export default ImportSubscriptionsModal

import {orbitRequest, RequestMethod} from '../../utils/api.utils'

// Logs
export const getLogsRequest = ({car_id, user_id}: {car_id?: number; user_id?: number}) => {
  var path = '/car/log'

  if (car_id) {
    path += `?car_id=${car_id}`
  }

  if (user_id) {
    path += `${car_id ? '&' : '?'}user_id=${user_id}`
  }

  return orbitRequest({route: path, method: RequestMethod.GET})
}

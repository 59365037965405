import React from 'react'
import { useEditTemplate } from '../../../../context/edit-template-context'
import { generateField } from './fields-generator'

import { CSS } from "@dnd-kit/utilities";
import { useSortable, } from '@dnd-kit/sortable'

const DragHandle = (props) => (
  <div {...props} className='btn btn-icon btn-active-color-primary w-30px'>

    <i className='fas fa-arrows-alt'></i>
  </div>
)

export const EyeIcon = () => (
  <div className='btn btn-icon btn-active-color-primary w-30px'>
    <i className='fas fa-eye'></i>
  </div>
)
export const EyeIconSlash = () => (
  <div className='btn btn-icon btn-active-color-primary w-30px'>
    <i className='fas fa-eye-slash'></i>
  </div>
)

const FieldComponent = ({
  field,
  fieldIndex,
  panelIndex,
  panelMode,
  id
}: {
  field: any
  fieldIndex: number
  panelIndex: number
  panelMode: 'view' | 'edit'
  id: string
}) => {
  const { panels, setPanels } = useEditTemplate()
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: id,
    data: {
      id,
      fieldIndex,
      panelIndex,
    }
  });

  const styleItem = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  };

  const onChangeLabel = (value: string) => {
    const futurePanels = JSON.parse(JSON.stringify(panels))
    futurePanels[panelIndex].fields[fieldIndex].label = value
    setPanels(futurePanels)
  }

  return (
    <div
      ref={setNodeRef}
      className='pb-7 card-field sortable-item dragging-dbd-kit'
      data-index={fieldIndex}
      // @ts-ignore
      style={{
        ...styleItem,
        ...(field?.style && field.style),
        opacity: isDragging ? 0 : field?.hidden ? 0.5 : 1,
        display: panelMode === 'view' && field?.hidden ? 'none' : 'block',
      }}
    >
      {panelMode === 'edit' ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            height: '32px',
          }}
        >
          <div
            onClick={() => {
              const futurePanels = JSON.parse(JSON.stringify(panels))

              futurePanels[panelIndex].fields[fieldIndex].hidden =
                !futurePanels[panelIndex].fields[fieldIndex].hidden

              setPanels(futurePanels)
            }}
          >
            {field.hidden ? <EyeIconSlash /> : <EyeIcon />}
          </div>
          <DragHandle {...listeners} {...attributes} />
        </div>
      ) : null}
      <div>
        {generateField(field, panelMode === 'edit' ? onChangeLabel : undefined)}
      </div>
    </div>
  )
}

export default FieldComponent

import React, { useEffect, useState } from 'react';
import { ClientCampaignType } from '../../../../types/campaign.types';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  downloadExcel,
  getClientsByCampaignRequest
} from '../../../../setup/axios/campaign.request';
import { tableHeaders } from '../../../../utils/campaign.utils';
import Pagination from '../../transport-requests/Pagination';

type ClientsTableProps = {
  clients: ClientCampaignType[];
};

type PaginationType = {
  page: number;
  per_page: number;
  total: number;
  totalPages: number;
};



const ClientsTable: React.FC<ClientsTableProps> = ({ clients }) => {
  const [clientsList, setClientsList] = useState<ClientCampaignType[]>([]);
  const [sortOrder, setSortOrder] = useState({ key: null, direction: 'asc' });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { campaignId } = useParams<any>();
  const [pagination, setPagination] = useState<PaginationType>({
    page: 1,
    per_page: 25,
    total: 0,
    totalPages: 0,
  });

  const handleColumnOrder = (column) => {
    if (!sortOrder.key) {
      setSortOrder({ key: column, direction: 'asc' });
    }
    let futureSortOrder = { key: column, direction: 'asc' };

    if (sortOrder.key === column) {
      if (sortOrder.direction === 'asc') {
        futureSortOrder = { key: column, direction: 'desc' };
      } else {
        futureSortOrder = { key: null, direction: 'asc' };
      }
    } else {
      futureSortOrder = { key: column, direction: 'asc' };
    }
    setSortOrder(futureSortOrder);
  };


  const fetchData = async () => {
    setIsLoading(true);
    setClientsList([]);
    
    const clientsRequest = await getClientsByCampaignRequest(campaignId,
      pagination.page,
      pagination.per_page,
      sortOrder.key,
      sortOrder.direction
    );

    if (!clientsRequest || clientsRequest.error) {
      toast.error(clientsRequest?.message || 'Something went wrong');
      setIsLoading(false);
      return;
    }

    const { campaign_clients } = clientsRequest;
    setClientsList(campaign_clients.data);
    setPagination({
      ...pagination,
      total: campaign_clients.total,
      totalPages: campaign_clients.last_page,
    });

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [clients, pagination.page, pagination.per_page, sortOrder]);

  const exportSMSCampaignXLS = async () => {
    const response: unknown = await downloadExcel(campaignId);
    const csvContent = response as BlobPart;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'SMS_Campaign_Clients.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className={'card mb-7 mt-7'}>
        {/* begin::Header */}
        <div className='card-header pt-5 border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Clients</span>
          </h3>
          <div className='page__top-buttons'>
            <Button className='btn btn-info item-right mt-5 me-4' onClick={exportSMSCampaignXLS}>
              Export as XLS
            </Button>
          </div>
        </div>
        {/* end::Header */}
        <div className='card-body py-3'>
          <div className='table-responsive transport-table'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-4 gy-3'>
              {/* begin::Table Head */}
              <thead>
                <tr className='fw-bolder'>
                  {tableHeaders.map((column, id) => (
                    <th
                      key={id}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleColumnOrder(column.keyName)}
                    >
                      {column.label}
                      {sortOrder.key === column.keyName && (
                        <span>{sortOrder.direction === 'asc' ? ' ↑' : ' ↓'}</span>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              {/* end::Table Head */}
              {/* begin::Table Body */}
              <tbody style={{ display: 'table-row-group' }}>
                {clientsList?.map((client) => (
                  <tr key={client?.id}>
                    <td>{client?.id || ''}</td>
                    <td>{client?.company_name || ''}</td>
                    <td>{client?.user_phone || ''}</td>
                    <td>{client?.user_location || ''}</td>
                    <td>{client?.car_brand || ''}</td>
                    <td>{client?.car_model || ''}</td>
                    <td>{client?.car_license_number || ''}</td>
                    <td>{client?.car_VIN || ''}</td>
                    <td>{client?.car_number_of_km || 0} </td>
                    <td>{client?.is_owner == 0 ? 'No' : 'Yes'} </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table Body */}
            </table>
            <div>
              <Pagination
                pageCount={pagination.totalPages}
                currentPage={pagination.page}
                setCurrentPage={(nextPage) => setPagination({ ...pagination, page: nextPage })}
                resultsPerPage={pagination.per_page}
                setResultsPerPage={(resultsPerPage) =>
                  setPagination({ ...pagination, per_page: resultsPerPage })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientsTable;

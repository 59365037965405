
export const defaultServiceFields = [
  {
    label: 'Name',
    backend_property: 'name',
    required: true,
    type: 'text'
  },
  {
    label: 'Additional Info',
    backend_property: 'additional_info',
    required: false,
    type: 'text'
  },
  {
    label: 'Service type',
    backend_property: 'has_offer_mark',
    required: true,
    type: 'radioSelect',
    options: [{
      value: "Text",
      id: 'text',
      name: 0
    },
    {
      value: "Check",
      id: 'check',
      name: 1
    }
    ]
  },
  {
    label: 'Service availability',
    backend_property: 'is_offer_present',
    isVisible: {
      field: 'has_offer_mark',
      value: 1
    },
    required: true,
    type: 'radioSelect',
    options: [{
      value: "Enabled",
      id: 'enabled',
      name: 1
    },
    {
      value: "Disabled",
      id: 'disabled',
      name: 0
    }
    ]
  }
]
import {EditableLabel} from '../../../../../app/modules/add-edit-template/components/fields-generator'
import {formatNumber} from '../../../../../utils/string.utils'

type IntegerInputProps = {
  field: any
  value: string
  onChange: (value: any) => void
  hideLabel?: boolean
  className?: string
  onChangeLabel?: (value: string) => void
  additionalInfo?: string
  [key: string]: any
}

const IntegerInput = ({
  field,
  value,
  hideLabel,
  onChange,
  onChangeLabel,
  additionalInfo,
  ...inputProps
}: IntegerInputProps) => {
  if (Object(field).hasOwnProperty('min')) {
    inputProps = {...inputProps, min: field?.min}
  }
  if (Object(field).hasOwnProperty('max')) {
    inputProps = {...inputProps, max: field?.max}
  }
  const inputComponent = (
    <input
      onBlur={(e: any) => {
        var futureValue = e.target.value
        e.target.value = formatNumber({
          paramValue: futureValue,
          minValue: field?.min,
          maxValue: field?.max,
        })
        onChange(e)
      }}
      type='number'
      name={field?.name}
      required={field?.required}
      className={`form-control form-control-sm`}
      id={field?.name}
      value={value}
      onChange={(ev) => {
        onChange(ev)
      }}
      disabled={field?.disabled}
      {...inputProps}
    />
  )
  return (
    <div>
      {!Boolean(hideLabel) ? (
        <EditableLabel label={field?.label} onChange={onChangeLabel} isRequired={field.required} />
      ) : null}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {inputComponent}
      </div>
    </div>
  )
}

export default IntegerInput

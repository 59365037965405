import {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import CampaignsTable from './Table'
import {CampaignType} from '../../../types/campaign.types'
import {toast} from 'react-toastify'
import {LoadingCard} from '../../../_metronic/layout/components/loading/Loading'
import { getCampaignsRequest } from '../../../setup/axios/campaign.request'

const FormularPage: React.FC<{}> = () => {
  const [campaigns, setCampaigns] = useState<CampaignType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)

      const response = await getCampaignsRequest()
      if (!response || response.error) {
        toast.error(`Campaigns could not be loaded! ${response?.message || ''}`)
        setIsLoading(false)
      } else {
        const campaignList: CampaignType[] = response.campaigns
        setCampaigns(campaignList)
      }
      setIsLoading(false)
    })()
  }, [])

  return (
    <>
      <div className='row gy-5 g-xl-12'>
        <div className='col-xxl-12'>
          {isLoading ? <LoadingCard /> : <CampaignsTable campaigns={campaigns} />}
        </div>
      </div>
    </>
  )
}

type FormularWrapperProps = {}

const FormularWrapper: React.FC<FormularWrapperProps> = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'SMS Campaigns'}</PageTitle>
      <FormularPage />
    </>
  )
}

export default FormularWrapper

import { FC, useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { v4 as uuiv4 } from 'uuid'
import {
  getEasyTrackZone,
  getPlaceDetails,
} from '../../../utils/geolocation.utils'
import { areObjectsEqual } from '../../../utils/state.utils'
import GeolocationSelect from '../../../_metronic/layout/components/geolocation-select/GeolocationSelect'
import ModalPortal from '../../../_metronic/layout/components/modal/modal-portal'
import Select from '../../../_metronic/layout/components/select/Select'
import { EditableLabel } from '../add-edit-template/components/fields-generator'
import { convertFieldName, emptyPOI, fields, getComplementaryKeyName, POI } from './poiConstants'
import PoiList from './PoiList'

const PoiFormWrapper: FC<{
  intermPOIList: any
  style?: any
  setIntermPOIList: (param: any) => void
  pois: any[]
  field?: any
  onChangeLabel?: (value: string) => void
  disabled?: boolean
}> = ({ intermPOIList, setIntermPOIList, pois, style, field, onChangeLabel, disabled }) => {
  // TO DO: use a reducer hook to better define the link between modalVisible and POI
  const [pointToEdit, setPointToEdit] = useState<any>(null)
  // the modal should be visible only if the pointToEdit has a value: new or edit
  // pointToEdit: (null -> no action); (empty -> add new point); (not empty -> edit existing point)
  const modalVisible = Boolean(pointToEdit)
  const setModalVisible = useCallback((visible: boolean) => {
    // if we want to hide the modal, we need to set the point to null
    if (!visible) {
      setPointToEdit(null)
    }
  }, [])

  return (
    <div>
      <EditableLabel label={field?.label} onChange={onChangeLabel} isRequired={field?.required} />
      <div style={{ ...style, border: '1px solid #eee' }}>
        {modalVisible ? (
          <ModalPortal setVisible={setModalVisible} visible={modalVisible}>
            <PoiForm
              onConfirm={() => setModalVisible(false)}
              intermPOIList={intermPOIList}
              setIntermPOIList={setIntermPOIList}
              pois={pois}
              intermPoint={pointToEdit}
            />
          </ModalPortal>
        ) : null}

        <PoiList
          intermPOIList={intermPOIList}
          setIntermPOIList={setIntermPOIList}
          setPointToEdit={setPointToEdit}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 6,
            paddingBottom: 16,
          }}
        >
          <button
            style={{
              minWidth: 150,
              backgroundColor: '#009EF7',
              borderRadius: 33,
              border: 'none',
              padding: 6,
              color: '#fff',
            }}
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault()
              setPointToEdit(emptyPOI)
            }}
          >
            Add Stops
          </button>
        </div>
      </div>
    </div>
  )
}

const generateLabel = (fieldLabel: string, fieldRequired: boolean) => {
  return (
    <>
      {fieldLabel}:
      {fieldRequired && (
        <span className='ps-2' style={{ color: 'red' }}>
          *
        </span>
      )}
    </>
  )
}

const PoiForm: FC<{
  intermPOIList: any
  setIntermPOIList: Function
  pois: any[]
  intermPoint: POI
  onConfirm: any
}> = ({ intermPOIList, setIntermPOIList, pois, intermPoint, onConfirm }) => {
  const [selectedPoi, setSelectedPoi] = useState<POI>(intermPoint)
  const edit = useMemo(() => !areObjectsEqual({ objectA: intermPoint, objectB: emptyPOI }), [])

  const fieldToFormObject = (fieldName: string): any => {
    switch (fieldName) {
      case 'idPoiIntermediary':
        return {
          value: selectedPoi.id,
          options: (pois || []).filter((poi: any) => poi.additionalInfo),
          optionValue: (e: any) => e.id,
          optionLabel: (e: any) => e.additionalInfo ?? '',
        }
      case 'intermediaryCoords':
        return selectedPoi.gps
      case 'locationField':
        return selectedPoi.address
      case 'intermediaryAddressDetails':
        return selectedPoi.addressDetails
      case 'intermediaryPoiName':
        return selectedPoi.poiName
      case 'intermediaryEstimatedWait':
        return selectedPoi.estimatedWait
      case 'intermediaryPriority':
        return selectedPoi.priority
    }
  }

  const updateField = (e: any, field: any) => {
    // @ts-ignore
    setSelectedPoi((oldPoi: any) => {
      let editedPoi = { ...oldPoi }

      if ((e.target || {}).type === 'checkbox') {
        editedPoi[field] = e.target.checked
      } else {
        if (Array.isArray(field)) {
          // if the field is an array, that means that we have an object with an id
          let objectField = field[0],
            objectIdField = field[1]
          editedPoi[objectField] = {}
          editedPoi[objectField][objectIdField] = e.target.value === '' ? null : e.target.value
        } else {
          editedPoi[field] = e.target.value === '' ? null : e.target.value
        }
      }

      return editedPoi
    })
  }

  const updateFields = (fieldValuePairs: any) => {
    const futureSelectedPoi = { ...selectedPoi, ...fieldValuePairs }
    setSelectedPoi(futureSelectedPoi)
  }

  const generateField = (field: any): any => {
    const fieldLabel = generateLabel(field?.label, field?.required)
    let fieldProperty = fieldToFormObject(field?.name)
    // if the priority is equal to 0, then we assign the lesser priority to our new entry
    if (field?.name === 'intermediaryPriority' && fieldProperty === 0) {
      fieldProperty = intermPOIList.length + 1
    }

    switch (field?.type) {
      case 'input':
        return (
          <>
            <label key={`label${field?.name}`} htmlFor={field?.name} className='form-label'>
              {fieldLabel}
            </label>
            <input
              key={field?.name}
              name={field?.name}
              type={field?.inputType}
              required={field?.required}
              min={field?.min}
              className={`form-control form-control-sm mb-4`}
              id={field?.name}
              value={fieldProperty ?? ''}
              onChange={(ev) => {
                const convertedFieldName: any = (convertFieldName as any)[field?.name]
                updateField(ev, convertedFieldName || field?.name)
              }}
              disabled={field?.disabled}
            />
          </>
        )
      case 'geolocation':
        return (
          <>
            <label key={`label${field?.name}`} htmlFor={field?.name} className='form-label'>
              {fieldLabel}
              <i
                onClick={async () => {
                  // Add text to clipboard
                  navigator.clipboard.writeText(fieldToFormObject(field?.coordsKey) || '')
                  toast.success('Coords copied to clipboard!')
                }}
                className='fas fa-exclamation-circle ms-2 fs-7 clickable'
                data-bs-toggle='tooltip'
                title={fieldToFormObject(field?.coordsKey) || ''}
              ></i>
            </label>

            <GeolocationSelect
              simpleValue={fieldProperty}
              onInputChange={(value: string) => {
                const fieldName = (convertFieldName as any)[field?.name] || field?.name
                const futureSelectedPoi = { ...selectedPoi, ...{ [fieldName]: value } }
                setSelectedPoi(futureSelectedPoi)
              }}
              onChange={async (params: any) => {
                let fieldsValuePairs: any = {}
                const { place_id, description } = params

                fieldsValuePairs[(convertFieldName as any)[field?.name] || field?.name] =
                  description

                // Update additional info
                const placeDetails = await getPlaceDetails({ place_id: place_id })

                const coords: { lat?: number; lng?: number } = {
                  lat: placeDetails.geometry.location.lat(),
                  lng: placeDetails.geometry.location.lng(),
                }

                fieldsValuePairs[
                  (convertFieldName as any)[field?.additionalInfoKey] || field?.additionalInfoKey
                ] = placeDetails.name

                // Update coords
                if (field?.coordsKey) {
                  fieldsValuePairs[
                    (convertFieldName as any)[field?.coordsKey] || field?.coordsKey
                  ] = `${coords.lat},${coords.lng}`
                }

                const zoneResponse = await getEasyTrackZone({ lat: coords.lat, lng: coords.lng })

                // Update zone
                if (field?.zoneKey) {
                  // Not finished, waiting for Valentin Response.
                  let zone: { name?: string } = zoneResponse.data || { name: '' }
                  fieldsValuePairs[(convertFieldName as any)[field?.zoneKey] || field?.zoneKey] =
                    zone.name
                }
                updateFields(fieldsValuePairs)
              }}
            />
            {field?.additionalInfoKey ? (
              <input
                key={field?.name}
                name={field?.name}
                type='text'
                placeholder='Additional info'
                className='form-control form-control-sm mt-1'
                id={field?.name}
                onChange={(e) => {
                  updateField(
                    e,
                    (convertFieldName as any)[field?.additionalInfoKey] || field?.additionalInfoKey
                  )
                }}
                value={fieldToFormObject(field?.additionalInfoKey) || ''}
              />
            ) : null}
          </>
        )
      case 'inputCompleteFields':
        const value =
          fieldProperty?.options?.find((e: any) => e.id === fieldProperty?.value || '') || null
        return (
          <>
            <label
              key={`label${field?.name}`}
              htmlFor={field?.name}
              className={'form-label ' + (field?.labelType === 'danger' ? 'text-danger' : '')}
            >
              {fieldLabel}
            </label>

            <Select
              styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
              key={JSON.stringify(fieldProperty)}
              onChange={(optionsInstance: any) => {
                let companyPOI = Object({ ...emptyPOI })
                Object.keys(optionsInstance).map((key) => {
                  let keyName = getComplementaryKeyName(key)
                  companyPOI[keyName] = optionsInstance[key]
                })
                const futurePOI = { ...emptyPOI, ...companyPOI }
                setSelectedPoi(futurePOI)
                // change the value of the current field
                const fakeEvent = {
                  type: 'select',
                  target: { value: fieldProperty?.optionValue(optionsInstance) },
                }
                updateField(fakeEvent, (convertFieldName as any)[field?.name] || field?.name)
              }}
              value={value}
              required={field?.required}
              options={fieldProperty?.options || []}
              getOptionValue={fieldProperty?.optionValue}
              getOptionLabel={fieldProperty?.optionLabel}
            />
          </>
        )
    }
  }

  const handleAddEditPoi = () => {
    let newPoi: POI = { ...selectedPoi }
    // if the priority was not set at input we give it the least priority
    if (newPoi.priority === 0) {
      newPoi.priority = intermPOIList.length + 1
    } else if (newPoi.priority < 0) {
      newPoi.priority = 0
    }

    if (newPoi.uniqueId === '') {
      newPoi.uniqueId = uuiv4()
    }

    let oldIntermPoiList = intermPOIList

    if (edit) {
      oldIntermPoiList = oldIntermPoiList.filter(
        (poi: POI) => JSON.stringify(poi) !== JSON.stringify(intermPoint)
      )
    }
    oldIntermPoiList.splice(newPoi.priority - 1, 0, newPoi)
    // the priority corresponds to the position in the list
    for (let index = 0; index < oldIntermPoiList.length; index++) {
      oldIntermPoiList[index].priority = index + 1
    }

    const futureIntermPOIList = [...oldIntermPoiList]
    setIntermPOIList(futureIntermPOIList)
  }

  return (
    <form style={{ width: '100%', padding: 16 }}>
      {fields.map((field) => {
        return (
          <Field
            key={'field' + field.name}
            style={{ marginBottom: '16px' }}
            name={field.name}
            children={generateField(field)}
          ></Field>
        )
      })}
      <button
        className='btn btn-primary item-right'
        type='submit'
        onClick={(ev) => {
          ev.preventDefault()
          if (!selectedPoi?.gps) {
            toast.warning('Invalid Address: Missing GPS info!')
            return
          }
          handleAddEditPoi()
          onConfirm()
        }}
      >
        {edit ? 'Save' : 'Add'}
      </button>
    </form>
  )
}

const Field: FC<{ name: string; children: any; style: any }> = ({ name, children, style }) => {
  return (
    <div style={style} key={'div' + name}>
      {children}
    </div>
  )
}

export default PoiFormWrapper

import * as Yup from 'yup'

export const campaignValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Name must be at least 3 characters')
    .max(20, 'Name must be at most 20 characters')
    .matches(/^[A-Za-z0-9\- ]+$/, 'Only letters, numbers, spaces, and hyphens are allowed')
    .required('Name is required'),

  type: Yup.string()
    .min(3, 'Type must be at least 3 characters')
    .max(20, 'Type must be at most 20 characters')
    .matches(/^[A-Za-z\- ]+$/, 'Only letters, spaces, and hyphens are allowed')
    .required('Type is required'),

  content_type: Yup.string()
    .min(3, 'Type must be at least 3 characters')
    .max(20, 'Type must be at most 20 characters')
    .matches(/^[A-Za-z\- ]+$/, 'Only letters, spaces, and hyphens are allowed')
    .required('Content Type is required'),

  send_after: Yup.date()
    .test(
      'is-valid-date',
      'Send After must be a valid date and should be less than Expiration Date',
      function (value, context) {
        const expirationDate = context.parent.expiration_date
        const sendAfterDate = new Date(value)
        const currentDate = new Date()

        return sendAfterDate >= currentDate && sendAfterDate < expirationDate
      }
    )
    .required('Send After is required'),

    // expiration_date: Yup.date()
    // .when(not null, {
    //   is: (value) => value && value !== '',
    //   then: Yup.date().test('is-valid-date', 'Expiration date must be valid', function (value) {
    //     const expirationDate = new Date(value);
    //     const currentDate = new Date();
  
    //     return expirationDate > currentDate;
    //   })
    // }),

  number_of_client: Yup.number()
    .typeError('Number of clients must be a valid number')
    .test('is-numeric', 'Number of clients must be a valid number', function (value) {
      return !isNaN(value)
    })
    .required('Number of clients is required'),
})

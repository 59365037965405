import { Button, Modal } from 'react-bootstrap'
import { refundStripeSubscription } from '../../../../setup/axios/subscription.request'
import { toast } from 'react-toastify'
import { useState } from 'react'

const RefundModal = ({
  subscriptionId,
  onRefundSuccess,
  visible,
  setVisible
}: {
  subscriptionId: number
  onRefundSuccess: Function
  visible: boolean
  setVisible: Function
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const confirmAction = async () => {
    setIsLoading(true)
    const response = await refundStripeSubscription(subscriptionId)
    setIsLoading(false)
    setVisible(false)
    if (!response || response?.error) {
      toast.error(`Rambursarea a eșuat! ${response.message}`)
      return;
    }
    toast.success('Rambursarea a fost realizată cu succes!')
    onRefundSuccess()
  }

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={true}
      show={visible}
      onHide={() => { setVisible(false) }}
      >
      <Modal.Header closeButton>
        <Modal.Title><span>Rambursare Stripe Subscripție</span></Modal.Title>
      </Modal.Header>
      <Modal.Body><span>{`Doriți să rambursați, prin intermediul Stripe, suma aferentă subscripției cu id ${subscriptionId}?`}</span></Modal.Body>
      <Modal.Footer>
        <Button disabled={isLoading} variant='secondary' onClick={() => { setVisible(false) }}>
          Anulează
        </Button>
        <Button disabled={isLoading} variant='warning' onClick={confirmAction}>
          Rambursează
        </Button>
      </Modal.Footer>
    </Modal>
  )
}


export default RefundModal

import { useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Image, Modal } from 'react-bootstrap'
import easytrackAxios from '../../../../setup/easytrack.axios'
import { TransportPhotoTempType } from '../../../../types/document.types'
import { KTSVG } from '../../../../_metronic/helpers'
import { logError } from '../../../../utils/sentry.utils'
import { downloadFile } from '../utils'
import ConfirmModal from './ConfirmModal'
import { EASYTRACK_API_URL } from '../../../../constants/api.constants'

const ImagePreview = ({
  handleCloseViewImgModal,
  viewImageModal,
  setProcessingFiles,
  photo,
  photosTempObjectList,
  updateListWithNewPhotos,
}: {
  handleCloseViewImgModal: () => void
  viewImageModal: boolean
  setProcessingFiles: Function
  photo: TransportPhotoTempType
  photosTempObjectList: TransportPhotoTempType[]
  updateListWithNewPhotos: Function
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  
  const handleDelete = async () => {
    const deletePhotoUrl = `${EASYTRACK_API_URL}/transport-requests/${photo?.transportRequestId}/photos/${photo?.id}`
    await easytrackAxios.delete(deletePhotoUrl)
      .then((res) => {
        if (res.status !== 200) {
          toast.error('ERROR: The images did not delete.')
          } else {
            toast.success('The images were succesfully deleted. Reloading images...')
            const unprocessedPhotos: TransportPhotoTempType[] = photosTempObjectList.filter(
              (el) => el.id !== photo?.id
            )
            updateListWithNewPhotos(unprocessedPhotos)
            setProcessingFiles(true)
          }
        handleCloseViewImgModal()
        setShowConfirmModal(false)
      })
      .catch((err) => {
        toast.error('ERROR: An unexpected error has occurred. Please try again later.')
        logError({ name: 'Error delete photo', error: {...err}, type: 'error' })
        handleCloseViewImgModal()
        setShowConfirmModal(false)
      })
  }

  return (
    <>

      <Modal
        size='lg'
        className='photoModal'
        onHide={handleCloseViewImgModal}
        style={{width:'100%'}}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={viewImageModal}
        animation={true}
      >
        <div style={{ position: 'absolute', top: 15, right: -10, flex:1, flexDirection:'column', display:'flex' }}>
          <button
            onClick={handleCloseViewImgModal}
            className='btn btn-icon  btn-active-color-danger btn-sm me-5 mb-5'
          >
            <KTSVG
              path='/media/icons/duotone/Interface/Close-Square.svg'
              className='svg-icon-3tx svg-icon-primary clickable'
            />
          </button>
          <button
            onClick={() => { downloadFile(photo.content, photo.createdDate, 'img'); handleCloseViewImgModal ()}}
            className='btn btn-icon btn-active-color-danger btn-sm me-10 mb-10'
          >
            <KTSVG
              path={'/media/icons/duotone/Files/Download.svg'}
              className='svg-icon-2tx svg-icon-primary clickable'
            />
          </button>
          <button
            style={{marginTop:-15}}
            onClick={() => setShowConfirmModal(true)}
            className='btn btn-icon btn-active-color-danger btn-sm me-10 mb-10'
          >
            <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3tx svg-icon-primary clickable' />
          </button>
        </div>
        <Image style={{ width: '70%', height: 'auto', objectFit: 'scale-down' }} src={`data:image/jpeg;base64,${photo?.content}`} alt='image' fluid rounded />
      </Modal>
      <ConfirmModal 
        confirmAction={handleDelete}
        title="Confirm Delete"
        subtitle='Are you sure you want to delete this image?'
        visible={showConfirmModal}
        setVisible={setShowConfirmModal}
      />
    </>
  )
}


export default ImagePreview

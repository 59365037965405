import React, { useState } from 'react'
import { RequestMethod, orbitRequest } from '../../../../../utils/api.utils'
import { APIResponseType } from '../../../../../types/general.types'
import { toast } from 'react-toastify'
import { FlatSubscriptionType } from '../../../../../types/subscription.types'

export const deleteSubscription = async (subscriptionId: number): Promise<APIResponseType> =>
  await orbitRequest({
    route: '/subscription/delete',
    payload: { id: subscriptionId },
    method: RequestMethod.POST,
  })

const DeleteModal = ({
  subscription,
  setModalVisible,
  triggerAdditionalEffect,
}: {
  subscription: FlatSubscriptionType,
  setModalVisible: (_: boolean) => void
  triggerAdditionalEffect: any
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <div className='py-5 px-8' style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ textAlign: 'center', fontSize: '16px' }}>
        Sigur dorești să ștergi subscripția cu id <b>{subscription.id}</b>?
      </span>
      <div className='mt-8' style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          disabled={isLoading}
          className='btn btn-secondary me-5'
          onClick={() => setModalVisible(false)}
        >
          Cancel
        </button>
        <button
          disabled={isLoading}
          className='btn btn-danger'
          onClick={async () => {
            setIsLoading(true)

            const result = await deleteSubscription(subscription?.id)
            if (!result || result.error) {
              toast.error('Subscripția nu a putut fi ștearsă!')
            } else {
              triggerAdditionalEffect()
              toast.success('Subscripția a fost ștearsă cu succes!')
            }

            setIsLoading(false)
            setModalVisible(false)
          }}
        >
          Delete
        </button>
      </div>
    </div>
  )
}

export default DeleteModal
import { FC, useEffect, useContext, useState, useMemo, useCallback, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'
import * as XLS from 'xlsx'
import Loading from '../../../_metronic/layout/components/loading/Loading'
import { TransportsContext } from '../../../context/transports.context'
import { baseTableButtonStyle, getServiceImage, getStyleForStatus } from '../../../utils/style.utils'
import { TemplatesContext } from '../../../context/templates.context'
import { ColumnType, defaultColumns } from '../add-edit-template/components/TableColumns'
import { KTSVG } from '../../../_metronic/helpers'
import {
  addInvoiceNumberForTransports,
  getTariffServices,
  sendEmailRequestFromTransport,
} from '../../../setup/axios/transports.request'
import easytrackAxios from '../../../setup/easytrack.axios'
import { API_URL, EASYTRACK_API_URL } from '../../../constants/api.constants'
import { defaultTemplate } from '../../../data/template.default'
import Pagination from './Pagination'
import { getURLFormattedTemplateName, safeJSONParseToObj } from '../../../utils/string.utils'
import { UserContext } from '../../../context/user.context'
import ModalPortal from '../../../_metronic/layout/components/modal/modal-portal'
import ExportFileModal from './components/modals/ExportFileModal'
import { TypeOfCompany, getPriceFormatted } from '../../../utils/pricing.utils'
import { formatCurrency } from '../view-transport/modules/RequestFields'
import customAxios from '../../../setup/custom.axios'
import { getSelectedTransportsStatistics, getSelectedTransportsTariffServicesAndTarrifs, getUpdatedTransportWithRentExchangeRate } from '../../../utils/transport.utils'
import InvoiceModal from './components/modals/InvoiceModal'
import { getAdjustedDateByDays, getDifferenceInDays } from '../../../utils/date.utils'
import { AppContext } from '../../../context/app.context'
import { ProcessStatusObjectType, getTransportsBatch, changeInvoiceBatch } from './components/utils/batchRequests'
import SendInvoiceModal from './components/modals/SendInvoiceModal'
import { getTransportRentInfoMultiple } from '../../../setup/axios/rent.request'
import Actions from './components/Actions'

// too limited, needs to be polished if the cases become more complex - only one split should be allowed per call
const getPropertyValue = ({ property, transport }: { property: string; transport: any }) => {
  if (property.includes('(list)')) {
    const properties = property.split('(list)')
    const listProperty = properties[1].trim()
    const listValues = getPropertyValue({ property: listProperty, transport })
    const processedListValues = listValues || []
    return processedListValues.join('; ')
  }
  // '>' is used for lists, to extract the property mentioned after the sign from the elements of the array before the sign
  if (property.includes('>')) {
    const properties = property.split('>')
    const mainObjectProperty = properties[0].trim()
    const array = getPropertyValue({ property: mainObjectProperty, transport })
    if (!array) {
      return null
    }
    const entryProperty = properties[1].trim()
    const values = array
      .map((entry: any) => getPropertyValue({ property: entryProperty, transport: entry }))
      .filter((value: string) => Boolean(value))
    return values.join('; ')
  }

  if (property.includes('*')) {
    // special '&&' case: it concatenates the result in reverse, and puts the first argument inside paranthesis
    const properties = property.split('*')
    const propertyA = properties[0].trim()
    const propertyB = properties[1].trim()

    const valueA = transport[propertyA] || ''
    const valueB = getPropertyValue({ property: propertyB, transport }) || ''
    if (valueB.includes(valueA)) {
      return valueB
    }
    if (valueA.includes(valueB)) {
      return valueA
    }
    return valueB + ' (' + valueA + ')'
  }

  if (property.includes('.')) {
    const properties = property.split('.')
    let value = transport[properties[0]]

    if (!value) {
      return null
    }

    for (let i = 1; i < properties.length; i++) {
      value = value[properties[i]]
    }
    return value
  }

  if (property.includes('&&')) {
    const properties = property.split('&&')
    let value = ''
    for (let i = 0; i < properties.length; i++) {
      value = value + ' ' + getPropertyValue({ property: properties[i].trim(), transport })
    }
    return value
  }

  if (property.includes('||')) {
    const properties = property.split('||')
    const propertyA = properties[0].trim()
    const propertyB = properties[1].trim()
    return transport[propertyA] || getPropertyValue({ property: propertyB, transport })
  }

  // positive values only (min0)
  if (property.includes('(min0)')) {
    const properties = property.split('(min0)')
    const propertyB = properties[1].trim()
    return Math.max(0, getPropertyValue({ property: propertyB, transport }))
  }

  // difference between two dates
  if (property.includes('(dateDiff)')) {
    const properties = property.split('(dateDiff)')
    const dateProperties = properties[1].trim().split(',')

    const dateAProperty = dateProperties[0].trim()
    const dateBProperty = dateProperties[1].trim()

    const dateA = getPropertyValue({ property: dateAProperty, transport })
    const dateB = getPropertyValue({ property: dateBProperty, transport })

    const diffInDays = Math.ceil(getDifferenceInDays(dateA, dateB))

    return Math.max(0, diffInDays)
  }

  // substraction
  if (property.includes('-')) {
    const properties = property.split('-')
    const propertyA = properties[0].trim()
    const propertyB = properties[1].trim()

    const fstNumber = transport[propertyA] || 0
    const sndNumber = transport[propertyB] || 0
    return fstNumber - sndNumber
  }

  return transport[property]
}

const setTransportStatusToNew = async (transport: any) => {
  let transportPayload = { ...transport }
  transportPayload.requestStatus = 'NEW'

  const result = await easytrackAxios
    .put(`${EASYTRACK_API_URL}/transport-requests`, { transportRequest: transportPayload })
    .catch((err) => err)

  if (result?.response?.data?.error || result?.response?.status === 500) {
    // Error
    toast.error(result?.response?.data?.message || 'We have some problem with your last request!')
    return
  }
}

const hiddenColumnsForExport = new Set(['custom_confirmation', 'actions', 'select'])

const getExtraColumnsForExportPDF = (columns: ColumnType[]) => {
  const columnsToExclude = new Set([
    'Effective Date',
    'Number',
    'Invoice number',
    'Service',
    'Tariff',
    'Payment',
    'Requestor',
    'Requestor Reference',
    'Requestor Division',
    'Requestor Department',
    'Requestor Service',
    'Requestor Cost Center',
    'Currency',
    'Client',
  ])
  // get a dictionary with the key as backend_property, and value as the label
  // why? Label is not constant, so the only constant is backend_property
  const templateColumnDict: { [key: string]: string } = columns.reduce(
    (acc, { label, backend_property, visible }) => {
      if (visible) {
        acc[backend_property] = label
      }
      return acc
    },
    {}
  )
  // we want the remaining columns
  const remainingColumns = defaultColumns.filter(({ label, backend_property }) => {
    return (
      templateColumnDict[backend_property] &&
      !columnsToExclude.has(label) &&
      !hiddenColumnsForExport.has(backend_property)
    )
  })
  // prepare the columns
  return remainingColumns.map((column) => {
    const property = column.backend_property
    return { label: templateColumnDict[property], backend_property: property }
  })
}

const TransportsTable = ({
  convertTable,
  setConvertTable,
}: {
  convertTable: boolean
  setConvertTable: any
}) => {
  const {
    setTransports,
    transports,
    pageCount,
    currentPage,
    setCurrentPage,
    getTransportsCount,
    setTransportsCounter,
    resultsPerPage,
    setResultsPerPage,
    isLoading,
    selectedTransportsForConfirmation,
    setSelectedTransportsForConfirmation,
    setTariffServices,
    tariffServicePhotoDict,
    getTransports,
    transportsFilterPayload,
  } = useContext<any>(TransportsContext)
  const { templateName } = useParams<any>()
  const { templates } = useContext<any>(TemplatesContext)
  const { businessProfileId } = useContext<any>(UserContext)
  const { getPromiseByKey } = useContext<any>(AppContext)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [isXLS, setIsXLS] = useState<boolean>(false)
  const [isInvoice, setIsInvoice] = useState<boolean>(false)
  const [isSendInvoiceActive, setIsSendInvoiceActive] = useState<boolean>(false)
  const [allTransportsSelected, setAllTransportsSelected] = useState<boolean>(false)
  const [areAllTransportsFulfilled, setAreAllTransportsFulfilled] = useState(false);

  const [companyThatCanBeSelected, setCompanyThatCanBeSelected] = useState<any>(null)
  const [selectedTransports, setSelectedTransports] = useState<any[]>([])

  const bulkToastId = useRef(null)
  const [totalRequests, setTotalRequests] = useState<number>(0)
  const [completedRequests, setCompletedRequests] = useState<number>(0)
  const processStatusObject: ProcessStatusObjectType = {
    bulkToastId, totalRequests, completedRequests, setTotalRequests, setCompletedRequests
  }
  const areTableButtonsVisible = Boolean(selectedTransports)
    && Array.isArray(selectedTransports)
    && !modalVisible
    && !isLoading
    && selectedTransports.length > 0

  const currentTemplate = useMemo(() => {
    if (!templates || templates.length === 0 || !templateName) {
      return null
    }
    let template = templates.find(
      (template: any) => {
        const formatedTemplateName = getURLFormattedTemplateName(template.name)
        return formatedTemplateName === templateName
      }
    )

    if (!template) {
      toast.error(
        'The provided template was not found: switched to the default template configuration'
      )
      return { ...defaultTemplate, columns: defaultColumns }
    }

    let templateContent =
      typeof template.content === 'string'
        ? safeJSONParseToObj(template?.content)
        : template.content
    if (!templateContent) {
      toast.error(
        'The provided template was not found: switched to the default template configuration'
      )
      return { ...defaultTemplate, columns: defaultColumns }
    }

    if (!templateContent.columns) {
      templateContent.columns = defaultColumns
    }

    return templateContent
  }, [templates, templateName])

  const columnsForExportPDF = useMemo(
    () => getExtraColumnsForExportPDF(currentTemplate?.columns || []),
    [currentTemplate]
  )

  const hasVisibileRentColumns = useMemo(() => {
    const columns: ColumnType[] = currentTemplate?.columns || []
    return columns.some((column: ColumnType) => column.backend_property.includes('rentPlanData'))
  }, [currentTemplate])

  useEffect(() => {
    if (totalRequests) {
      toast.update(bulkToastId.current, {
        render: `Making requests! ${completedRequests}/${totalRequests} done`,
        progress: completedRequests / totalRequests,
        autoClose: false,
      })
    }
  }, [totalRequests, completedRequests])

  // Create an empty export table data object
  const emptyExportTableData = { data: [], filename: '', startDate: '', endDate: '' }
  // Memoize the empty export table data object
  const exportTableData = useMemo(() => emptyExportTableData, [])

  const areAllTransportsSelected =
    transports?.length > 0 &&
    transports.every((transport) => {
      return selectedTransports.some((id) => id === transport.id)
    })

  // Reset the selected transports when the page changes or the filter changes
  // useState is used for tracking the changes of the currentPage and transportsFilterPayload
  const [prevPage, setPrevPage] = useState(currentPage)
  if (prevPage !== currentPage) {
    setPrevPage(currentPage)
    if (allTransportsSelected) {
      setAllTransportsSelected(false)
      setSelectedTransports([])
    }
  }
  const [prevTransportsFilterPayload, setPrevTransportsFilterPayload] =
    useState(transportsFilterPayload)
  if (JSON.stringify(prevTransportsFilterPayload) !== JSON.stringify(transportsFilterPayload)) {
    setPrevTransportsFilterPayload(transportsFilterPayload)
    setAllTransportsSelected(false)
    setSelectedTransports([])
  }

  const getCompleteTransports = useCallback(async (payload) => {
    const result = await getTransports(payload)
    const hasReturnedTransports = Boolean(result?.data?.transportRequests)
    if (hasReturnedTransports && hasVisibileRentColumns) {
      const transports = result.data.transportRequests
      const transportIds = transports.map(t => t.idTransportRequest)
      // supply rent data for the given transports to prepare them for export
      const transportRentInfoDict = await getTransportRentInfoMultiple(transportIds);
      if (!transportRentInfoDict || Object.keys(transportRentInfoDict).length === 0) {
        // we don't have anything to add to the transports, so we return the data as we received it
        return result;
      }
      // prepare the new transports with the rentPlanData field
      const newTransports = transports.map(oldTransport => {
        let newTransport = { ...oldTransport }
        newTransport['rentPlanData'] = transportRentInfoDict[newTransport.idTransportRequest] || {}
        // add the rent exchange rate to the tariff details
        const rentExchangeRate = newTransport['rentPlanData'].exchange_rate
        if (rentExchangeRate) {
          newTransport = getUpdatedTransportWithRentExchangeRate({ transport: newTransport, rentExchangeRate })
        }
        return newTransport
      })
      // replace the old transports with the new ones
      result.data.transportRequests = newTransports
    }
    return result
  }, [hasVisibileRentColumns, getTransports])

  const getTransportsForExport = async () => {
    const requestPayload = {
      transportFilters: transportsFilterPayload,
      processStatusObject,
      getTransports: getCompleteTransports,
      setAreAllTransportsFulfilled
    }
    if (allTransportsSelected) {
      const transports = await getTransportsBatch(requestPayload)
      return transports
    }
    requestPayload.transportFilters = {
      ...transportsFilterPayload,
      ids: selectedTransports,
    }
    const transports = await getTransportsBatch(requestPayload)
    return transports
  }

  useEffect(() => {
    if (selectedTransportsForConfirmation.length > 0) {
      setCompanyThatCanBeSelected(selectedTransportsForConfirmation[0].company || null)
    } else {
      setCompanyThatCanBeSelected(null)
    }
  }, [selectedTransportsForConfirmation])

  const loadTariffServices = useCallback(async () => {
    const result = await getPromiseByKey('tariffServices', { businessProfileId })
    if (Boolean(result?.data)) {
      setTariffServices(result.data)
    }
  }, [])

  useEffect(() => {
    // clear on page load
    setCompanyThatCanBeSelected(null)
    setSelectedTransportsForConfirmation([])
    setSelectedTransports([])
    setAllTransportsSelected(false)
    // load the tariff services
    loadTariffServices()
  }, [])

  useEffect(() => {
    setSelectedTransports([])
    setAllTransportsSelected(false)
  }, [resultsPerPage])

  const ServiceImage = ({
    tariffServiceName,
    convertTable = false,
  }: {
    tariffServiceName: string
    convertTable?: boolean
  }) => {
    const photoUrl = getPhotoLinkForService(tariffServiceName)
    return Boolean(photoUrl) ? (
      <img style={{ width: convertTable ? '50px' : '70px', marginBottom: '4px' }} src={photoUrl} />
    ) : null
  }

  const getPhotoLinkForService = (tariffServiceName: string) => {
    let photoUrl = ''
    if (Boolean(tariffServicePhotoDict[tariffServiceName])) {
      photoUrl = tariffServicePhotoDict[tariffServiceName]
    } else {
      photoUrl = getServiceImage(tariffServiceName)
    }
    return photoUrl
  }

  const transportIds = transports.map(t => t.idTransportRequest)
  const transportIdsString = transportIds.join(',')

  const updateTransportsWithRentData = async (transportIds: (number | string)[]) => {
    const transportRentInfoDict = await getTransportRentInfoMultiple(transportIds);
    if (!transportRentInfoDict || Object.keys(transportRentInfoDict).length === 0) {
      return;
    }
    setTransports((oldTransports) => {
      const newTransports = oldTransports.map(oldTransport => {
        let newTransport = { ...oldTransport }
        newTransport['rentPlanData'] = transportRentInfoDict[newTransport.idTransportRequest] || {}
        // add the rent exchange rate to the tariff details
        const rentExchangeRate = newTransport['rentPlanData'].exchange_rate
        if (rentExchangeRate) {
          newTransport = getUpdatedTransportWithRentExchangeRate({ transport: newTransport, rentExchangeRate })
        }

        return newTransport
      })
      return newTransports
    })
  }

  useEffect(() => {
    if (!currentTemplate || !transports) {
      return;
    }
    if (hasVisibileRentColumns) {
      updateTransportsWithRentData(transportIds)
    }
  }, [currentTemplate, transportIdsString])

  const sendConfirmationEmailAndSetNewForTransport = useCallback((transport: any) => {
    sendEmailRequestFromTransport({
      transport,
      additionalEffectForSuccess: () => setTransportStatusToNew(transport),
    })
  }, [])

  const renderCell = useCallback(
    (column, transport, convertTable) => {
      if (!column.visible) {
        return <></>
      }
      if (convertTable && hiddenColumnsForExport.has(column.backend_property)) {
        return <></>
      }
      const backendPropertyValue = getPropertyValue({
        property: column['backend_property'],
        transport,
      })

      switch (column.backend_property) {
        case 'select':
          return (
            <td key={column.label}>
              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                <input
                  name={column.label}
                  className='clickable form-check-input'
                  type='checkbox'
                  checked={selectedTransports.find((id) => id === transport.id)}
                  onChange={(e) => {
                    const isChecked = e.target.checked

                    if (isChecked) {
                      setSelectedTransports((prevSelectedTransports) => [
                        ...prevSelectedTransports,
                        transport.id,
                      ])
                      setSelectedTransportsForConfirmation([])
                    } else {
                      setAllTransportsSelected(false)
                      setSelectedTransports(selectedTransports.filter((id) => id !== transport.id))
                    }
                  }}
                />
              </div>
            </td>
          )
        case 'number':
          return (
            <td key={column.label}>
              {' '}
              <Link
                className={`${convertTable ? 'resizeTable' : ''}`}
                style={{ marginBottom: 8, textDecoration: 'underline', color: '#181C32' }}
                to={`/add-edit-transport/${templateName}/${transport.id}`}
              >
                {backendPropertyValue}
              </Link>
            </td>
          )
        case 'commission':
        case 'tariff': {
          let value = backendPropertyValue
          const futureValue = getPriceFormatted(value)
          return (
            <td key={column.label} className={`${convertTable ? 'resizeTable' : ''}`}>
              {futureValue}
            </td>
          )
        }
        case 'custom_confirmation':
          const isDisabled = companyThatCanBeSelected
            ? companyThatCanBeSelected.id !== transport?.company?.id
            : false
          return (
            <td key={column.label}>
              <div
                className='form-check form-check-sm form-check-custom form-check-solid'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                {!companyThatCanBeSelected ? (
                  <button
                    data-bs-toggle='tooltip'
                    data-bs-boundary='window'
                    data-bs-placement='top'
                    title='Send'
                    onClick={() => sendConfirmationEmailAndSetNewForTransport(transport)}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 my-1 me-4'
                  >
                    <KTSVG
                      path={'/media/icons/duotone/Communication/Send.svg'}
                      className={'svg-icon-1 rotate-180'}
                    />
                  </button>
                ) : null}
                {!isDisabled ? (
                  <input
                    style={{
                      border: '1px solid #b1b1b1',
                      width: '2rem',
                      height: '2rem',
                    }}
                    disabled={isDisabled}
                    name={column.label}
                    className='clickable form-check-input'
                    type='checkbox'
                    checked={selectedTransportsForConfirmation.find(
                      (e: any) => e.id === transport.id
                    )}
                    onChange={(e) => {
                      const isChecked = e.target.checked

                      if (isChecked) {
                        setSelectedTransportsForConfirmation([
                          ...selectedTransportsForConfirmation,
                          transport,
                        ])
                        setAllTransportsSelected(false)
                        setSelectedTransports([])
                      } else {
                        setSelectedTransportsForConfirmation(
                          selectedTransportsForConfirmation.filter(
                            (t: any) => t.id !== transport.id
                          )
                        )
                      }
                    }}
                  />
                ) : null}
              </div>
            </td>
          )
        case 'startTime':
        case 'endTime':
        case 'effectiveDate':
        case 'completeEstimateDate':
          return (
            <td className={`${convertTable ? 'resizeTable' : ''}`} key={column.label}>
              <span style={{ whiteSpace: 'nowrap' }}>
                {!Boolean(backendPropertyValue)
                  ? ''
                  : moment(backendPropertyValue).format('YYYY-MM-DD HH:mm')}
              </span>
            </td>
          )
        case 'tariffServiceName':
          return (
            <td style={{ textAlign: 'center' }} className={`${convertTable ? 'resizeTable' : ''}`}>
              {/* Show service image */}
              <ServiceImage
                tariffServiceName={transport.tariffServiceName}
                convertTable={convertTable}
              />
              {transport.tariffServiceName}
            </td>
          )
        case 'requestStatus':
          return (
            <td className={`${convertTable ? 'resizeTable' : ''}`}>
              <span style={getStyleForStatus(transport.requestStatus)}>
                {transport.requestStatus}
              </span>
            </td>
          )
        case 'actions':
          return (
            <td
              className='text-end'
              style={{
                minWidth: '120px',
              }}
            >
              <Actions templateName={templateName.toLowerCase()} transportId={transport?.id} />
            </td>
          )
        default:
          return (
            <td key={column.label} className={`${convertTable ? 'resizeTable' : ''}`}>
              {backendPropertyValue}
            </td>
          )
      }
    },
    [
      selectedTransportsForConfirmation.length,
      companyThatCanBeSelected,
      tariffServicePhotoDict,
      selectedTransports.length,
    ]
  )

  const renderHeader = useCallback(
    (columns, transports, convertTable) => {
      return columns.map((column) => {
        if (!column.visible) {
          return <></>
        }
        if (convertTable && hiddenColumnsForExport.has(column.backend_property)) {
          return <></>
        }
        if (column.backend_property === 'select' && !isLoading) {
          return (
            <th className='w-25px ps-5'>
              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                <input
                  name={column.label}
                  className='clickable form-check-input'
                  type='checkbox'
                  checked={transports.every((transport) => {
                    return selectedTransports.some((id) => id === transport.id)
                  })}
                  onChange={(e) => {
                    const isChecked = e.target.checked

                    if (isChecked) {
                      setAllTransportsSelected(false)

                      setSelectedTransports((prevSelectedTransports) => [
                        ...prevSelectedTransports,
                        ...transports
                          .filter((t) => !prevSelectedTransports.some((pid) => pid === t.id))
                          .map((t) => t.id),
                      ])
                      setSelectedTransportsForConfirmation([])
                    } else {
                      setAllTransportsSelected(false)
                      setSelectedTransports((prevSelectedTransports) => {
                        return prevSelectedTransports.filter((id) => {
                          return !transports.some((t) => t.id === id)
                        })
                      })
                    }
                  }}
                />
              </div>
            </th>
          )
        }
        return (
          <th
            className={`${column.className ? column.className : ''} ${convertTable ? 'resizeTable' : ''
              }`}
            key={column.label}
          >
            {column.label}
          </th>
        )
      })
    },
    [selectedTransports.length, isLoading]
  )

  const renderRow = useCallback(
    (columns, transport, convertTable) => {
      return (
        <tr>
          {columns.map((column) => {
            return renderCell(column, transport, convertTable)
          })}
        </tr>
      )
    },
    [
      selectedTransportsForConfirmation.length,
      companyThatCanBeSelected,
      tariffServicePhotoDict,
      selectedTransports.length,
    ]
  )

  const getAndSetTransportsCount = async () => {
    const result = await getTransportsCount({ partial: false })
    const statusCountObject = result?.data || {} // {STATUS_NAME: {name: string, value: number}}
    setTransportsCounter(statusCountObject)
  }

  useEffect(() => {
    getAndSetTransportsCount()
  }, [])

  if (!currentTemplate || !currentTemplate?.columns) {
    return <></>
  }

  const extractInfoFromInterval = (dateInterval: string[]) => {
    // Get the earliest and most recent date from the date interval array
    let earliest = new Date(transportsFilterPayload?.startDate ||
      Math.min.apply(
        null,
        dateInterval.map((date) => new Date(date))
      )
    )
    let earliestFormated = earliest.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })

    let mostRecent = transportsFilterPayload?.stopDate
      ? getAdjustedDateByDays(transportsFilterPayload?.stopDate, -1)
      : new Date(
        Math.max.apply(
          null,
          dateInterval.map((date) => new Date(date))
        )
      )
    let mostRecentFormated = mostRecent.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })

    return {
      name: `transport-report-${earliestFormated.replaceAll('/', '-')}-to-${mostRecentFormated.replaceAll('/', '-')}`,
      startDate: earliestFormated.replaceAll('/', '.'),
      stopDate: mostRecentFormated.replaceAll('/', '.'),
    }
  }

  // Table Data for XLS and PDF
  const createExportTableData = (selectedTransportsData) => {
    const newData = []
    const dateInterval = []

    for (let i = 0; i < selectedTransportsData?.length; i++) {
      let transportData = []
      let headerRow = []

        // Loop through the columns defined in the current template
        ; (currentTemplate?.columns || []).forEach((column) => {
          if (column.visible && !hiddenColumnsForExport.has(column.backend_property)) {
            if (i === 0) {
              headerRow.push(column.label)
            }
            const backendPropertyValue = getPropertyValue({
              property: column['backend_property'],
              transport: selectedTransportsData[i],
            })
            let futureValue = null

            // Logic for getting the dates
            switch (column.backend_property) {
              case 'completeEstimateDate':
              case 'effectiveDate': {
                dateInterval.push(backendPropertyValue)
                const date = new Date(backendPropertyValue)
                  .toLocaleDateString('en-gb', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                  .replace(/\//g, '-')
                futureValue = date
                break
              }
              case 'commission':
              case 'tariff': {
                let value = backendPropertyValue
                futureValue = parseFloat(getPriceFormatted(value))
                break
              }
              default: {
                futureValue = backendPropertyValue
                break
              }
            }
            transportData.push(futureValue)
          }
        })

      if (i === 0) {
        newData.push(headerRow)
      }
      newData.push(transportData)
    }

    const info = extractInfoFromInterval(dateInterval)
    exportTableData.filename = info.name
    exportTableData.data = newData
  }

  const createBreakdownSheet = (breakdownType: TypeOfCompany, selectedTransportsData: any[], extraColumns?: { header: string, getValue: Function }[]) => {
    const dataRows = []
    const extraHeaders = (extraColumns || []).map((info) => info.header)
    const breakdownInfoHeaders = ['Tarif', 'Categ', 'Explicatie']
    const headerRow = ['Req. No.', 'Client', 'Date', 'Trf. Srv.', ...extraHeaders, ...breakdownInfoHeaders]

    dataRows.push(headerRow)

    selectedTransportsData.forEach((transport) => {
      const breakdown = (breakdownType === TypeOfCompany.Client) ? transport.taxBreakdown : transport.commissionBreakdown
      if (breakdown && Object.keys(breakdown).length > 0) {
        const formattedDate = new Date(transport.effectiveDate)
          .toLocaleDateString('en-gb', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          })
          .replace(/\//g, '-')

        const extraTransportInfo = (extraColumns || []).map((info) => info.getValue(transport))
        const transportInfo = [
          transport.number,
          transport.companyName,
          formattedDate,
          transport.tariffServiceName,
          ...extraTransportInfo
        ]

        let totalTarrif = 0
        const entries = Object.entries(breakdown)
        entries.forEach(([key, info]: [string, any]) => {
          totalTarrif += info.value
          const dataRow = [...transportInfo, info.value, key, info.description]
          dataRows.push(dataRow)
        })
        dataRows.push([...transportInfo, totalTarrif, 'TOTAL'])
        dataRows.push([]) // leave an empty row for better readability
      }
    })
    return dataRows
  }

  const createClientBreakdownSheet = (selectedTransportsData) => createBreakdownSheet(TypeOfCompany.Client, selectedTransportsData)

  const createSupplierBreakdownSheet = (selectedTransportsData) => {
    const extraColumns = [{
      header: 'Supplier', getValue: (transport: any) => {
        let formattedSupplier = ''
        if (!transport.supplier || transport.supplier?.name === 'error') {
          formattedSupplier = 'Not Set'
        } else {
          formattedSupplier = transport.supplier.name
        }
        return formattedSupplier
      }
    }]

    return createBreakdownSheet(TypeOfCompany.Supplier, selectedTransportsData, extraColumns)
  }

  const prepareTableData = async (invoiceNumber: string = null) => {
    const selectedTransportsData = await getTransportsForExport()
    if (!selectedTransportsData) {
      toast.error('Failed to fetch transports for export!')
      return null
    }
    createExportTableData(selectedTransportsData)
    if (Boolean(invoiceNumber)) {
      await changeInvoiceBatch({ transports: selectedTransportsData, invoiceNumber, processStatusObject, addInvoiceNumberForTransports })
    }
    return selectedTransportsData
  }

  const exportXLS = async (clientBreakdown: boolean, supplierBreakdown: boolean) => {
    const selectedTransportsData = await prepareTableData()
    if (!selectedTransportsData) {
      return
    }
    const wb = XLS.utils.book_new()

    const ws = XLS.utils.aoa_to_sheet(exportTableData.data)
    XLS.utils.book_append_sheet(wb, ws, 'Tickets')

    if (clientBreakdown) {
      const ws2 = XLS.utils.aoa_to_sheet(createClientBreakdownSheet(selectedTransportsData))
      XLS.utils.book_append_sheet(wb, ws2, 'Client Breakdown')
    }

    if (supplierBreakdown) {
      const ws3 = XLS.utils.aoa_to_sheet(createSupplierBreakdownSheet(selectedTransportsData))
      XLS.utils.book_append_sheet(wb, ws3, 'Supplier Breakdown')
    }

    if (clientBreakdown || supplierBreakdown) {
      XLS.writeFile(wb, `${exportTableData.filename}_with_breakdown.xls`)
    } else {
      XLS.writeFile(wb, `${exportTableData.filename}.xls`)
    }
  }

  const exportPDF = async (useInSendInvoice?: boolean) => {
    const valueLabels = {
      extra: columnsForExportPDF.map((column) => column.label),
      requestor: ['Requestor', 'Reference', 'Division', 'Department', 'Service', 'Cost Center'],
    }
    const selectedTransportsData = await getTransportsForExport()
    if (!selectedTransportsData) {
      toast.error('Failed to fetch the transports for export!')
      return
    }
    const finishedTrasports = selectedTransportsData.filter(
      (transport) => transport.requestStatus === 'FINISHED'
    )
    if ((finishedTrasports || []).length === 0) {
      toast.error('Please provide at least one transport request with status FINISHED!')
      return
    }
    const totalPriceStatistics = getSelectedTransportsStatistics(finishedTrasports, true)
    const dataForExport = []
    finishedTrasports.forEach((transport: any) => {
      let dataRow = { requestInfo: [], extra: [], tariff: [], requestor: [] }
      // DATE & SERVICE
      dataRow.requestInfo = [
        '#' + transport.number,
        moment(transport.effectiveDate).format('DD-MM-YYYY HH:mm'),
        transport.tariffServiceName,
        getPhotoLinkForService(transport.tariffServiceName),
      ]

      // SERVICE DETAILS
      dataRow.extra = columnsForExportPDF.map((column) => {
        let propertyValue = getPropertyValue({ property: column.backend_property, transport })
        const hasPropertyValue = Boolean(propertyValue)
        const isOfDateType = column.backend_property === 'completeEstimateDate' ||
          column.backend_property === 'startTime' ||
          column.backend_property === 'endTime'
        if (hasPropertyValue && isOfDateType) {
          propertyValue = moment(propertyValue).format('DD-MM-YYYY HH:mm')
        }
        return propertyValue || ''
      })

      let formattedTariff = formatCurrency({
        value: transport?.tariff || 0,
        currency: transport?.currency || 'RON',
      })

      // AMOUNT
      dataRow.tariff = [formattedTariff, transport.paymentType]

      // CENTERS
      dataRow.requestor = [
        transport.requestorName,
        transport.companyCustomField1Value,
        transport.companyCustomField2Value,
        transport.companyCustomField3Value,
        transport.companyCustomField4Value,
        transport.companyCustomField5Value,
      ]

      dataForExport.push(dataRow)
    })

    const info = extractInfoFromInterval(finishedTrasports.map((transport: any) => transport.effectiveDate))
    try {
      const response = await customAxios
        .post(
          `${API_URL}/pdf/create-transports-pdf`,
          {
            data: dataForExport,
            labels: valueLabels,
            total: totalPriceStatistics,
            interval: { startDate: info.startDate, stopDate: info.stopDate },
            client: finishedTrasports[0].companyName,
            paymentType: useInSendInvoice ? 'CONTRACT' : finishedTrasports[0].paymentType,
          },
          { responseType: 'blob' }
        )
      if (useInSendInvoice) {
        const transportStatistics = getSelectedTransportsTariffServicesAndTarrifs(finishedTrasports);
        return {
          file: new File([new Blob([response.data], { type: 'application/pdf' })], `${info.name}.pdf`),
          transportStatistics: transportStatistics
        }
      }
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', info.name + '.pdf')
      document.body.appendChild(link)
      link.click()
      return response
    } catch (e) {
      toast.error('There was a problem when building the pdf!')
    }
    // referesh state
    setAllTransportsSelected(false)
    setSelectedTransports([])
  }

  const onCancelModal = () => {
    setAllTransportsSelected(false)
    setSelectedTransports([])
    setModalVisible(false)
    setIsInvoice(false)
    setIsSendInvoiceActive(false)
  }

  const onCloseModal = () => {
    setModalVisible(false)
    setIsInvoice(false)
    setIsSendInvoiceActive(false)
  }

  return (
    <div>
      {modalVisible ? (
        <ModalPortal setVisible={setModalVisible} visible={modalVisible} hasExitButton={false}>
          {isInvoice ? (
            <InvoiceModal
              markInvoice={prepareTableData}
              transports={selectedTransports}
              setModalVisible={onCloseModal}
              allTransportsSelected={allTransportsSelected}
              onCancelModal={onCancelModal}
            />
          ) : isSendInvoiceActive ?
            <SendInvoiceModal
              markInvoice={prepareTableData}
              transports={transports}
              selectedTransports={selectedTransports}
              setModalVisible={onCloseModal}
              allTransportsSelected={allTransportsSelected}
              onCancelModal={onCancelModal}
              exportPDF={exportPDF}
              areAllTransportsFulfilled={areAllTransportsFulfilled}
              setTransports={setTransports}
            /> : (
              <ExportFileModal
                isXLS={isXLS}
                transports={selectedTransports}
                exportXLS={exportXLS}
                exportPDF={exportPDF}
                setModalVisible={setModalVisible}
                allTransportsSelected={allTransportsSelected}
                onCancelModal={onCancelModal}
              />
            )}
        </ModalPortal>
      ) : null}
      {areTableButtonsVisible ? (
        <div style={{ position: 'fixed', right: '20px', bottom: '40px', display: 'flex', flexDirection: 'column', rowGap: '4px', zIndex: '999' }}>
          <button
            onClick={() => {
              setSelectedTransports([])
              setAllTransportsSelected(false)
            }}
            style={{ ...baseTableButtonStyle, background: '#6C757D' }}
          >
            Deselect all
          </button>
          <button
            onClick={async () => {
              setIsSendInvoiceActive(true)
              setModalVisible(true)
              setAreAllTransportsFulfilled(false)
            }}
            style={{ ...baseTableButtonStyle, background: '#009EF7' }}
          >
            Invoice
          </button>
          <button
            onClick={() => {
              setIsXLS(true)
              setModalVisible(true)
            }}
            style={{ ...baseTableButtonStyle, background: '#1D6F42' }}
          >
            Export as XLS
          </button>
          <button
            onClick={async () => {
              setIsXLS(false)
              setModalVisible(true)
            }}
            style={{ ...baseTableButtonStyle, background: '#f40f02' }}
          >
            Export as PDF
          </button>
        </div>
      ) : null}
      {companyThatCanBeSelected ? (
        <div style={{ position: 'fixed', right: '20px', bottom: '40px', display: 'flex', flexDirection: 'column', rowGap: '4px', zIndex: '999' }}>
          <button
            onClick={() => {
              setSelectedTransportsForConfirmation([])
            }}
            style={{ ...baseTableButtonStyle, background: '#6C757D' }}
          >
            Deselect all
          </button>
          <button
            style={{ ...baseTableButtonStyle, background: 'green' }}
          >
            <Link
              style={{
                color: 'white',
              }}
              to={{
                pathname: '/confirmation',
                state: { selectedTransportsForConfirmation: selectedTransportsForConfirmation },
              }}
            >
              Confirmation for {selectedTransportsForConfirmation.length} transports
            </Link>
          </button>
        </div>
      ) : null}

      <div className={`card mb-7`}>
        {/* begin::Header */}
        <div className={`card-header ${convertTable ? 'pt-20' : 'pt-5'} border-0`}>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Transports</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        {/* Check if one of the transports from page is selected and transports are not loading */}
        {areAllTransportsSelected && !isLoading && (
          <div className='fs-6 h-35px d-flex align-items-center justify-content-center bg-secondary'>
            {allTransportsSelected ? (
              <span>
                All {pageCount} pages are selected.
                <button
                  onClick={() => {
                    setAllTransportsSelected(false)
                    setSelectedTransports([])
                  }}
                  className='ms-5 btn text-primary fw-bold'
                >
                  Clear selection
                </button>
              </span>
            ) : (
              <span>
                All <span>{transports.length}</span> transports on this page are selected.
                {!convertTable ? (
                  <button
                    onClick={() => setAllTransportsSelected(true)}
                    className='ms-5 btn text-primary fw-bold'
                  >
                    Select all {pageCount} pages
                  </button>
                ) : null}
              </span>
            )}
          </div>
        )}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive transport-table'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder'>
                  {renderHeader(currentTemplate.columns, transports, convertTable)}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody
                style={{
                  display: isLoading ? 'table-caption' : 'table-row-group',
                }}
              >
                {/* Loading */}
                {isLoading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      flex: 1,
                    }}
                  >
                    <Loading />
                  </div>
                ) : (
                  transports.map((transport: any) =>
                    renderRow(currentTemplate.columns, transport, convertTable)
                  )
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
          {!convertTable ? (
            <Pagination
              pageCount={pageCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              resultsPerPage={resultsPerPage}
              setResultsPerPage={setResultsPerPage}
            />
          ) : null}
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}

export default TransportsTable

import { MouseEventHandler, memo, useContext, useEffect, useState } from 'react'
import ModalPortal from '../../../../../_metronic/layout/components/modal/modal-portal'

import { UserContext } from '../../../../../context/user.context'
import Loading from '../../../../../_metronic/layout/components/loading/Loading'
import { CompleteFormType, completeTransportRequest, convertTransportFormDataToCompleteRequest, getTransportById } from '../../../../../setup/axios/transports.request'
import { toast } from 'react-toastify'
import InputField from '../../../../../_metronic/layout/components/fields/input/input-field'

type CompleteModalProps = {
  modalVisible: boolean
  setModalVisible: Function
  transportId: number
}

type FormDataObjectType = {
  startOdometer: number, setStartOdometer: Function,
  endOdometer: number, setEndOdometer: Function,
  estimatedWaitingTime: number, setEstimatedWaitingTime: Function
}

const CompleteModalContent = ({
  isLoading,
  transportNumber,
  setModalVisible,
  completeTransportEvent,
  formDataObject,
}: {
  isLoading: boolean
  transportNumber: number
  setModalVisible: Function
  completeTransportEvent: MouseEventHandler<HTMLButtonElement>
  formDataObject: FormDataObjectType
}) => {
  const {
    startOdometer, setStartOdometer,
    endOdometer, setEndOdometer,
    estimatedWaitingTime, setEstimatedWaitingTime
  } = formDataObject

  // transport not loaded, or still waiting for an event
  if (!transportNumber || isLoading) {
    return <Loading />
  }

  return (< div style={{ width: '100%', padding: '16px 32px 16px 32px' }
  }>
    {/* Modal Header */}
    < div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '-3rem', marginBottom: '2rem' }}>
      <span style={{ fontSize: '16px', fontWeight: '500' }}>
        Complete the transport with number {transportNumber}:
      </span>
    </div >

    {/* Modal Body */}
    < div >
      <InputField
        containerStyle={{ paddingTop: '1rem' }}
        field={{ label: 'Start Odometer', name: 'startOdometer', inputType: 'number', addOns: { append: 'km ' } }}
        value={startOdometer + ''}
        onChange={(e) => {
          const futureValue = Number(e?.target?.value || '0')
          setStartOdometer(futureValue)
        }}
      />
      <InputField
        containerStyle={{ paddingTop: '1rem' }}
        field={{ label: 'End Odometer', name: 'endOdometer', inputType: 'number', addOns: { append: 'km ' } }}
        value={endOdometer + ''}
        onChange={(e) => {
          const futureValue = Number(e?.target?.value || '0')
          setEndOdometer(futureValue)
        }}
      />
      <InputField
        containerStyle={{ paddingTop: '1rem' }}
        field={{ label: 'Distance', name: 'distance', inputType: 'number', addOns: { append: 'km ' } }}
        value={Math.max(endOdometer - startOdometer, 0) + ''}
        onChange={(e) => {
          const futureValue = Number(e?.target?.value || '0')
          const futureEndOdometer = startOdometer + futureValue
          setEndOdometer(futureEndOdometer)
        }}
      />
      <InputField
        containerStyle={{ paddingTop: '1rem' }}
        field={{ label: 'Estimated Waiting Time', name: 'estimatedWaitingTime', inputType: 'number', addOns: { append: 'min' } }}
        value={estimatedWaitingTime + ''}
        onChange={(e) => {
          const futureValue = Number(e?.target?.value || '0')
          setEstimatedWaitingTime(futureValue)
        }}
      />
    </div >

    {/* Modal Footer */}
    < div className='mt-8' style={{ display: 'flex', justifyContent: 'center' }}>
      <button
        className='btn btn-secondary me-5'
        disabled={isLoading}
        onClick={() => {
          setModalVisible(false)
        }}
      >
        Cancel
      </button>
      <button
        className='btn btn-info'
        disabled={isLoading}
        onClick={completeTransportEvent}
      >
        {isLoading ? <i className='fa fa-circle-o-notch fa-spin me-2' /> : null}Complete
      </button>
    </div >
  </div >
  )
}

const CompleteTransportModal = memo(
  ({ modalVisible, setModalVisible, transportId }: CompleteModalProps) => {
    const [transport, setTransport] = useState<any>(null)
    const [estimatedWaitingTime, setEstimatedWaitingTime] = useState<number>(0)
    const [startOdometer, setStartOdometer] = useState<number>(0)
    const [endOdometer, setEndOdometer] = useState<number>(0)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { businessProfileId } = useContext<any>(UserContext)

    const getTransportEvent = async () => {
      setIsLoading(true)

      const response = await getTransportById(transportId)
      if (Boolean(response?.error)) {
        toast.error(`Couldn't retreive the transport with id ${transportId}`)
      } else {
        const futureTransport = response.transport
        setTransport(futureTransport)
        setStartOdometer(futureTransport?.startOdometer || 0)
        setEndOdometer(futureTransport?.endOdometer || 0)
        setEstimatedWaitingTime(futureTransport?.estimatedWait || 0)
      }

      setIsLoading(false)
    }

    const completeTransportEvent: MouseEventHandler<HTMLButtonElement> = async () => {
      setIsLoading(true)
      const formData: CompleteFormType = {
        businessProfileId,
        estimatedWait: estimatedWaitingTime,
        startOdometer: startOdometer,
        endOdometer: endOdometer
      }
      const completeRequestPayload = convertTransportFormDataToCompleteRequest({ formData, transport })
      const completeRequestResponse = await completeTransportRequest({ transportRequestId: transport?.id, payload: completeRequestPayload })

      setIsLoading(false)
      if (!completeRequestResponse?.data) {
        const message = completeRequestResponse?.response?.data?.message
        toast.error(`There was a problem with the last request! ${message || ''}`)
      } else {
        toast.success("Transport Request succesfully completed with the new information!")
        setModalVisible(false)
      }
    }

    useEffect(() => {
      getTransportEvent()
    }, [])

    const formDataObject: FormDataObjectType = {
      startOdometer, setStartOdometer,
      endOdometer, setEndOdometer,
      estimatedWaitingTime, setEstimatedWaitingTime
    }

    return (
      <ModalPortal visible={modalVisible} setVisible={setModalVisible}>
        <CompleteModalContent
          isLoading={isLoading}
          transportNumber={transport?.number}
          completeTransportEvent={completeTransportEvent}
          setModalVisible={setModalVisible}
          formDataObject={formDataObject}
        />
      </ModalPortal>
    )
  }
)

export default CompleteTransportModal

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { CompaniesListComponent } from './components/CompaniesList.component'
import { useLocation, useParams } from 'react-router-dom'

const CompaniesPages: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <CompaniesListComponent className='card-xxl-stretch mb-5 mb-xl-8' />
    </div>
    {/* end::Row */}
  </>
)

const CompaniesWrapper: FC = () => {
  const { id } = useParams<any>()
  const intl = useIntl()
  const {state} = useLocation<{companyName: string}>();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{id ? `Branches ${state.companyName}` : intl.formatMessage({ id: 'MENU.COMPANIES' })}</PageTitle>
      <CompaniesPages />
    </>
  )
}

export default CompaniesWrapper

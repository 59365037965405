import {useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {CarsContext} from '../../../../../context/cars.context'
import {LogsContext} from '../../../../../context/logs.context'
import {formatCarNumber} from '../../../../../utils/car.utils'
import {getDateObj} from '../../../../../utils/date.utils'
import {KTSVG} from '../../../../../_metronic/helpers'
import {CarModel} from '../../../auth/models/CarModel'

export const CarActionsModal = ({car}: {car: CarModel}) => {
  const {showCarActionsModal, setShowCarActionsModal, setShowCreateCarModal, sendCarAction} =
    useContext<any>(CarsContext)

  const {getLogs} = useContext<any>(LogsContext)
  const [localLogs, setLocalLogs] = useState<any>([])

  const _getCarLogs = async () => {
    const result = await getLogs({
      car_id: car ? car.id : null,
      updateStore: false,
    })

    if (result.error) {
      return ''
    }

    setLocalLogs(result.data)
  }

  useEffect(() => {
    _getCarLogs()

    const interval = setInterval(() => {
      _getCarLogs()
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const renderCarLog = (log: any) => {
    if (!log) {
      return null
    }

    const car = log.car[0]
    const user = log.user[0]
    const date = getDateObj(log.created_at)
    const messageWithoutUserID = log.message.split(' ').slice(1).join(' ')
    var icon = ''

    if (log.status === 'SENT') {
      icon = '/media/icons/duotone/Communication/Urgent-mail.svg'
    }
    if (log.status === 'DELIVRD') {
      icon = '/media/icons/duotone/Communication/Urgent-mail.svg'
    }
    if (log.status === 'REJECTD') {
      icon = '/media/icons/duotone/Communication/Mail-error.svg'
    }
    if (log.status === 'TIMEOUT') {
      icon = '/media/icons/duotone/Code/Warning-2.svg'
    }
    if (log.status === 'EXECUTED') {
      icon = '/media/icons/duotone/Code/Done-circle.svg'
    }
    if (log.status === 'UNEXECUTED') {
      icon = '/media/icons/duotone/Code/Warning-2.svg'
    }

    return (
      <div
        style={{
          backgroundColor: '#f8f8f8',
          padding: 8,
          alignItems: 'center',
          display: 'flex',
          marginBottom: 8,
          borderRadius: 4,
          position: 'relative',
        }}
      >
        {/* Header */}
        <div>
          <div style={{fontSize: 14}} className='text-gray-800 fw-bolder'>
            {date.hour}:{date.minutes}
          </div>
          <div style={{fontSize: 12}} className='text-gray-800 '>
            {date.day}/{date.month}/{date.yearShort}
          </div>
        </div>

        {/* Message */}
        <div style={{marginLeft: 16}}>
          {user.firstName} {user.lastName} {messageWithoutUserID}
        </div>

        <div
          title={log.status}
          style={{
            position: 'absolute',
            right: 8,
            borderRadius: 50,
            padding: 2,
          }}
        >
          <KTSVG path={icon} className='svg-icon-1' />
        </div>
      </div>
    )
  }

  return (
    <Modal
      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
      id='kt_inbox_compose'
      role='dialog'
      data-backdrop='false'
      aria-hidden='true'
      tabIndex='-1'
      show={showCarActionsModal}
      animation={false}
    >
      <div className='modal-content'>
        {/* begin::Form */}
        {/*begin::Header*/}
        <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
          <div>
            <h5 className='fw-bold m-0'>{`Actions for car:`}</h5>
            <h5 className='fw-bold m-0 mt-2'>
              <span className='car-badge'>{`${car?.brand} ${car?.model}`}</span>
              <span className='car-number-plate'>{`${formatCarNumber(car?.carNumber)}`}</span>
            </h5>
          </div>
          <div className='d-flex ms-2'>
            {/*begin::Close*/}
            <div
              className='btn btn-icon btn-sm btn-light-primary ms-2'
              data-bs-dismiss='modal'
              onClick={() => setShowCarActionsModal(false)}
            >
              <KTSVG className='svg-icon-1' path='/media/icons/duotone/Navigation/Close.svg' />
            </div>
            {/*end::Close*/}
          </div>
        </div>
        {/*end::Header*/}

        {/*begin::Body*/}
        <div className='d-block py-5 ps-8 pe-5'>
          {/* Track number */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Doors settings:</label>

            <div className='flex'>
              <button
                onClick={() => {
                  sendCarAction('close_doors')
                }}
                type='button'
                className='btn btn-danger btn-sm'
              >
                Close doors
              </button>
              <button
                style={{
                  marginLeft: 15,
                }}
                onClick={() => {
                  sendCarAction('open_doors')
                }}
                type='button'
                className='btn small btn-primary btn-sm'
              >
                Open doors
              </button>
            </div>
          </div>

          {/* Last logs */}
          <label className='form-label mt-16 fw-bolder text-dark fs-6'>Last logs:</label>
          <div className='fv-row mb-7 table-responsive' style={{maxHeight: 350}}>
            {localLogs.map((log: any) => {
              return renderCarLog(log)
            })}
          </div>

          {/* end::Form group */}
        </div>
        {/*end::Body*/}

        {/*begin::Footer*/}
        <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-top'>
          {/*begin::Actions*/}
          <div className='d-flex align-items-center me-3'></div>
          {/*end::Actions*/}

          {/*begin::Toolbar*/}
          <div className='d-flex align-items-center'>
            <button
              onClick={() => {
                setShowCarActionsModal(false)
                setShowCreateCarModal(true)
              }}
              className='btn px-6'
            >
              {'Go to edit page'}
            </button>
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Footer*/}
        {/*end::Form*/}
      </div>
    </Modal>
  )
}

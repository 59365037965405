import { SmsFormFieldsType } from '../../types/campaign.types';
import { APIResponseType } from '../../types/general.types';
import { RequestMethod, orbitRequest } from '../../utils/api.utils';

// Campaigns
export const getCampaignsRequest = async (): Promise<APIResponseType> =>
  await orbitRequest({
    route: `/campaigns`,
    method: RequestMethod.GET,
  });

export const getCampaignStatus = async (campaignId: number): Promise<APIResponseType> =>
  await orbitRequest({
    route: `/campaigns/getCampaignStatus?id=${campaignId}`,
    method: RequestMethod.GET,
  });

export const getCampaignType = async (campaignId: number): Promise<APIResponseType> =>
  await orbitRequest({
    route: `/campaigns/getCampaignType?id=${campaignId}`,
    method: RequestMethod.GET,
  });

export const getCampaignsByLocation = async (location: string): Promise<APIResponseType> =>
  await orbitRequest({
    route: `/tire/getByLocation?location=${location}`,
    method: RequestMethod.GET,
  })

export const clientsAndCarsRequest = async (requestRoute: string, payload: SmsFormFieldsType) =>
  await orbitRequest({
    route: `${requestRoute}`,
    payload: payload,
    method: RequestMethod.POST,
  })

export const updateCampaignRequest = async (
  id: number,
  name: string,
  type: string,
  content_type: string,
  send_after: string,
  expiration_date: string,
  status: string,
  number_of_clients: number
): Promise<APIResponseType> =>
  await orbitRequest({
    route: `/campaigns/update`,
    payload: {
      id: id,
      name: name,
      type: type,
      content_type: content_type,
      send_after: send_after,
      expiration_date: expiration_date,
      status: status,
      number_of_clients: number_of_clients,
    },
    method: RequestMethod.POST,
  });

// Campaigns Clients
export const getClientsByCampaignRequest = async (campaignId: number,
  page: number = 1,
  per_page: number = 15,
  sort_key: string = '',
  sort_direction: string = ''
  ): Promise<APIResponseType> =>
  await orbitRequest({
    route: `/campaigns-clients/getCampaignClientsByCampaignId?campaign_id=${campaignId}&page=${page}&per_page=${per_page}&sort_key=${sort_key}&sort_direction=${sort_direction}`,
    method: RequestMethod.GET,
  });

export const downloadExcel = (campaignId: number) => {
  return orbitRequest({
    route: `/campaigns-clients/exportExcel?campaign_id=${campaignId}`,
    method: RequestMethod.GET,
    config: { responseType: 'blob' }
  })
}

export const getClientsByShortCodeRequest = async (
  short_code: string,
  campaign_id: number,
  phone_number: string
): Promise<APIResponseType> =>
  await orbitRequest({
    route: `/campaign-clients/getCampaignClientsByShortCode?short_code=${short_code}&campaign_id=${campaign_id}&phone_number=${phone_number}`,
    method: RequestMethod.GET,
  });

  export const getClientsByEmailRequest = async (
    campaign_id: number,
    email: string
  ): Promise<APIResponseType> =>
    await orbitRequest({
      route: `/campaign-clients/getCampaignClientsByEmails?campaign_id=${campaign_id}&email=${email}`,
      method: RequestMethod.GET,
    });

// Web2SMS
export const createSMSListRequest = async (campaign_id: number): Promise<APIResponseType> =>
  await orbitRequest({
    route: `/web2sms/createSMSList`,
    payload: {
      campaign_id: campaign_id,
    },
    method: RequestMethod.POST,
  });

// Others
export const getPlacesOfBusinessByLocationRequest = async (
  location: string
): Promise<APIResponseType> =>
  await orbitRequest({
    route: `/places-of-business/getPlacesOfBusinessByLocation?location=${location}`,
    method: RequestMethod.GET,
  });

export const getPlaceOfBusinessByIdRequest = async (businessId: number): Promise<APIResponseType> =>
  await orbitRequest({
    route: `/places-of-business/getPlaceOfBusinessById?id=${businessId}`,
    method: RequestMethod.GET
  })

export const getSubsidiariesRequest = async (): Promise<APIResponseType> =>
  await orbitRequest({
    route: `/tire`,
    method: RequestMethod.GET,
  })

export const getCountiesRequest = async (): Promise<APIResponseType> =>
  await orbitRequest({
    route: `/counties-and-locations/getCounties`,
    method: RequestMethod.GET,
  })

export const getLocationsByCountyRequest = async (county: string): Promise<APIResponseType> =>
  await orbitRequest({
    route: `/counties-and-locations/getLocationsByCounty?county=${county}`,
    method: RequestMethod.GET,
  })

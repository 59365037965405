/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Loading from '../../../_metronic/layout/components/loading/Loading'
import { getSortedList } from '../../../utils/sort.utils'
import PackageRow from './components/PackageRow.component'
import { CompanyPackageGeneralInfo, PackageType, emptyCompanyPackageGeneralInfo } from '../../../types/company.types'
import AddEditGeneralInfo from './components/AddEditGeneralInfo.component'
import { PackageContext } from '../../../context/packages.context'

import './index.css'

const PackagesPage: FC = () => {
  const history = useHistory();
  const { id } = useParams<any>()
  const { isLoading, getPackages, deletePackage, packages, getCompanyPackageDetails } = useContext<any>(PackageContext)
  const [generalInfo, setGeneralInfo] = useState<CompanyPackageGeneralInfo>();
  const {state} = useLocation<{companyName: string}>();

  const getAndSetGeneralInfo = async() =>{
    const response = await getCompanyPackageDetails({id:id});
    if(!response?.error && response?.data){
      setGeneralInfo(response.data)
    }else{
      setGeneralInfo({ ...emptyCompanyPackageGeneralInfo, company_id:id})
    }
  }

  useEffect(() => {
    getPackages({ id: id });
    getAndSetGeneralInfo();
  }, [])

  const renderPackages = () =>{
    if(packages?.length > 0){
      return getSortedList({ listToSort: packages, args: ["name"], reversed: true }).map((companyPackage: PackageType) => (
        <PackageRow
          key={companyPackage.id}
          companyPackage={companyPackage}
          deletePackage={deletePackage}
        />
      ))
    }
    return null;
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{`Pachete asistență rutieră ${state.companyName}`}</PageTitle>
      
        <div className={`card`}>
          {/* begin::Header */}
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Pachete asistență rutieră</span>
            </h3>
            <div className='page__top-buttons'>
            <button className='btn btn-primary item-right' onClick={() => { history.push(`/add-edit-package/${id}`)}}>
                Adaugă pachet
              </button>
            </div>
          </div>
          
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='min-w-150px'>Nume pachet</th>
                    <th className='min-w-140px'>Descriere</th>
                    <th className='min-w-120px'>Preț</th>
                    <th className='min-w-120px'>Monedă</th>
                    <th className='min-w-120px'>Nume companie</th>
                    <th className='min-w-120px'>Valabiliate</th>
                    <th className='min-w-100px text-end'>Acțiuni</th>
                  </tr>
                </thead>
                <tbody style={{
                  display: isLoading ? 'table-caption' : 'table-row-group',
                }}>
                  {
                    isLoading ? (
                      <tr
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          flex: 1,
                        }}
                      >
                        <td>
                          <Loading />
                        </td>
                      </tr>
                    ) : renderPackages()}
                </tbody>
              </table>
            </div>
          </div>

        </div>
        {generalInfo ? <AddEditGeneralInfo generalInfo={generalInfo} setGeneralInfo={setGeneralInfo} /> :  null}
      </>
  )
}

export default PackagesPage;
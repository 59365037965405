/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import Filter from './FilterContainer'
import '../autonom.scss'
import { disableMaxWidth, enableMaxWidth } from '../../../utils/style.utils'
import { capitalize } from '../../../utils/string.utils'
import TransportsTable from './Table'
import ImportTransportsModal from './components/modals/ImportTransportsModal'
import ModalPortal from '../../../_metronic/layout/components/modal/modal-portal'

const TransportPage: FC = () => {
  const { templateName } = useParams<any>()
  const [convertTable, setConvertTable] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  return (
    <div id="transportsTable">
      {/* begin::NewRequestButton */}
      {!convertTable
        ? <div
          className='page__top-buttons'
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}>
          <button
            className='btn btn-info mx-2'
            onClick={() => setModalVisible(true)}
          >
            Import Transports
          </button>
          <Link
            className='btn btn-primary'
            to={`../add-edit-transport/${templateName.toLowerCase()}`}
          >
            New Request
          </Link>
        </div> : null}
      {modalVisible
        ? <ModalPortal setVisible={setModalVisible} visible={modalVisible} hasExitButton={false}>
          <ImportTransportsModal setModalVisible={setModalVisible} setTriggerTransportUpdate={() => { }} />
        </ModalPortal>
        : null
      }
      {/* end::NewRequestButton */}
      {/* begin::Filter Table */}
      <Filter convertTable={convertTable} />
      {/* end::Filter Table */}
      {/* begin::Row */}
      <div className='row gy-5 g-xl-12'>
        {/* begin::Col */}
        <div className='col-xxl-12'>
          <TransportsTable convertTable={convertTable} setConvertTable={setConvertTable} />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
    </div>
  )
}

const TransportRequestsWrapper: FC = () => {
  const { templateName } = useParams<any>()
  const intl = useIntl()

  useEffect(() => {
    disableMaxWidth()

    return () => {
      enableMaxWidth()
    }
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={[]}>{capitalize(templateName.replaceAll('-', ' '))}</PageTitle>
      <TransportPage />
    </>
  )
}

export default TransportRequestsWrapper

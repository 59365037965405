import React, {createContext, useMemo, useState} from 'react'
import {LogModel} from '../app/modules/auth/models/LogModel'
import {getLogsRequest} from '../setup/axios/logs.request'

const LogsContext = createContext({})

function LogsProvider(props: any) {
  const [logsData, setLogsData] = useState<Array<LogModel>>([])

  const getLogs = async (data: any) => {
    var shouldUpdate = false

    const {car_id, user_id, updateStore} = data || {}

    if (updateStore == undefined || updateStore == null) {
      // do something
      shouldUpdate = true
    } else {
      shouldUpdate = updateStore
    }

    const result = await getLogsRequest({car_id, user_id})

    if (!result || result.error) {
      return {message: result?.message || '', error: true}
    }

    if (shouldUpdate) {
      setLogsData(result.response)
    }
    return {error: false, data: result.response}
  }

  const store = {
    logsData,
    setLogsData,
    getLogs,
  }

  const storeForProvider = useMemo(() => store, [store])
  return <LogsContext.Provider value={storeForProvider}>{props.children}</LogsContext.Provider>
}

export {LogsContext}
export default LogsProvider

import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import CampaignInfoComponent from './components/CampaignInfo.components'
import ClientsTable from './components/ClientsTable.components'
import {CampaignType, ClientCampaignType} from '../../../types/campaign.types'
import Loading from '../../../_metronic/layout/components/loading/Loading'
import {APIResponseType} from '../../../types/general.types'
import {RequestMethod, orbitRequest} from '../../../utils/api.utils'
import {toast} from 'react-toastify'
import {useParams} from 'react-router-dom'
import { getCampaignsRequest, getClientsByCampaignRequest } from '../../../setup/axios/campaign.request'

export const getClientsRequest = async (): Promise<APIResponseType> =>
  await orbitRequest({
    route: `/clients-and-cars`,
    method: RequestMethod.GET,
  })

const ViewCampaign: FC = () => {
  const [campaignInfo, setCampaignInfo] = useState<CampaignType>()
  const [campaigns, setCampaigns] = useState<CampaignType[]>([])
  const [clients, setClients] = useState<ClientCampaignType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  let {campaignId} = useParams<any>()

  const fetchData = async () => {
    setIsLoading(true)
    const response = await getClientsByCampaignRequest(campaignId)
    const response_campaigns = await getCampaignsRequest()

    if (!response_campaigns || response_campaigns.error) {
      toast.error(`Campaigns list could not be loaded! ${response_campaigns?.message || ''}`)
      setIsLoading(false)
    } else {
      const campaignsList: CampaignType[] = response_campaigns.campaigns
      setCampaigns(campaignsList)
      const single_campaign = campaignsList.find(
        (campaign: CampaignType) => campaign.id === Number(campaignId)
      )
      setCampaignInfo(single_campaign)
    }
    setIsLoading(false)

    if (!response || response.error) {
      toast.error(`Clients list could not be loaded! ${response?.message || ''}`)
    } else {
      const clientList: ClientCampaignType[] = response.clients_and_cars
      setClients(clientList)
    }
    setIsLoading(false)
  }
  useEffect(() => {
    // This effect runs when the component mounts
    const script = document.createElement('script');
    script.src = '//script.crazyegg.com/pages/scripts/0122/2813.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup function
    return () => {
      document.body.removeChild(script); // Remove the script when the component unmounts
    };
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div>
        {/* Header */}
        <PageTitle>View Campaign</PageTitle>

        {/* Campaign Info */}
        {campaignInfo ? (
          <CampaignInfoComponent campaign={campaignInfo} setCampaign={setCampaignInfo} /> 
        ) : (
          <Loading />
        )}
      </div>

      <div>
        {/* Clients List */}
        <ClientsTable clients={clients} />
      </div>
    </>
  )
}

export default ViewCampaign

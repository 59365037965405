import React, {useContext, useState} from 'react'
import {toast} from 'react-toastify'
import {TransportsContext} from '../../../../../context/transports.context'
import Loading from '../../../../../_metronic/layout/components/loading/Loading'

const InvoiceModal = ({
  transports,
  markInvoice,
  setModalVisible,
  allTransportsSelected,
  onCancelModal,
}: {
  transports: any[]
  markInvoice: Function
  setModalVisible: (modalVisible: boolean) => void
  allTransportsSelected: boolean
  onCancelModal: Function
}) => {
  const {pageCount} = useContext<any>(TransportsContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [invoiceNumber, setInvoiceNumber] = useState<string>('')

  return (
    <div className='d-flex flex-column'>
      {isLoading ? (
        <Loading text={'Setting Invoice...'} />
      ) : (
        <>
          <div className='modal-header'>
            <p className='fs-2 text-center modal-title'>
              Set invoice number for
              <span>
                {allTransportsSelected ? ` ${pageCount} pages of ` : ` ${transports.length} `}
              </span>
              transports?
            </p>
          </div>

          <div className='d-flex modal-body flex-column justify-content-between align-items-between gap-4 fs-4 pb-0'>
            <div className='d-flex justify-content-start align-items-center'>
              <input
                placeholder='Enter invoice number...'
                className='form-control'
                type='text'
                value={invoiceNumber}
                onChange={(e) => {
                  let newInvoiceNumber: string = e.target.value
                  setInvoiceNumber(newInvoiceNumber)
                }}
              />
            </div>
          </div>

          <div className='modal-footer d-flex justify-content-center mt-7'>
            <div className='btn btn-danger me-5' onClick={() => onCancelModal()}>
              Cancel
            </div>
            <div
              className='btn btn-primary'
              onClick={async () => {
                setIsLoading(true)

                if (!invoiceNumber) {
                  toast.error('Please provide an invoice number!')
                  setIsLoading(false)
                  return;
                }
                
                await markInvoice(invoiceNumber)

                setIsLoading(false)
                setModalVisible(false)
              }}
            >
              Mark
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default InvoiceModal

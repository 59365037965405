import { PropsWithChildren, createContext, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { RequestMethod, orbitRequest } from '../utils/api.utils';
import { CompanyPackageGeneralInfo, PackageType, ServiceType } from '../types/company.types';
import axios from 'axios';
import { API_URL } from '../constants/api.constants';
import moment from 'moment';
import customAxios from '../setup/custom.axios';
import {
  PackageContextType,
  PostImageInContractCompanyDetailsPayload,
  PreviewContractPayload,
} from '../types/package.types';

const PackageContext = createContext<PackageContextType>(null);

function PackageProvider(props: PropsWithChildren<unknown>) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [packages, setPackages] = useState<Array<PackageType>>([]);
  const [services, setServices] = useState<Array<ServiceType>>();

  const getPackage = async ({ id }: { id: number }) => {
    setIsLoading(true);
    const response = await orbitRequest({
      route: `/package?id=${id}`,
      method: RequestMethod.GET,
    });

    if (!response || response.error) {
      toast.error(response?.message || 'Something went wrong');
      setIsLoading(false);
      return { message: response?.message, error: true };
    }

    if (response?.package) {
      return response;
    }

    setIsLoading(false);
    return { error: true, data: response?.response?.data };
  };

  const getPackages = async ({ id }: { id: number }) => {
    setIsLoading(true);
    const response = await orbitRequest({
      route: `/package?company_id=${id}`,
      method: RequestMethod.GET,
    });
    if (!response || response.error) {
      toast.error(response?.message || 'Something went wrong');
      setIsLoading(false);
      return { message: response?.message, error: true };
    }

    if (response?.packages) {
      setPackages(response.packages);
      setIsLoading(false);
      return response;
    }

    setIsLoading(false);
    return { error: false, data: response?.response?.data };
  };

  const deletePackage = async ({ id }: { id: number }) => {
    const response = await orbitRequest({
      route: `/package/delete`,
      payload: { id: id },
      method: RequestMethod.POST,
    });
    if (!response || response.error) {
      return toast.error(response?.message || 'Something went wrong');
    }

    toast.success('Package was deleted!');
    const futurePackages = packages.filter((companyPackage) => companyPackage.id !== id);
    setPackages(futurePackages);
  };

  const createAndUpdatePackage = async ({ payload }: { payload: PackageType }) => {
    setIsLoading(true);
    const isEditMode = Boolean(payload.id);
    const response = await orbitRequest({
      route: isEditMode ? `/package/update` : `/stripe/product/createProduct`,
      payload: {
        ...payload,
        currency: payload.currency,
        nickname: payload.name,
        price: payload.price || 0,
      },
      method: RequestMethod.POST,
    });

    if (!response || response.error) {
      setIsLoading(false);
      toast.error(response?.message || 'Something went wrong');
      return { error: true };
    }

    if (response.has_stripe_product) {
      toast.warning(
        'Editarea Prețului sau a Numelui de pachet nu se va aplica și link-ului de plată Stripe.'
      );
    }

    toast.success(
      `${payload.id ? 'Pachetul a fost actualizat!' : 'S-a adăugat noul pachet cu succes!'}`
    );

    const futurePackages = JSON.parse(JSON.stringify(packages));
    if (payload.id) {
      const index = (packages || []).findIndex(
        (companyPackage) => companyPackage.id === payload.id
      );
      futurePackages[index] = response.package;
    } else {
      futurePackages.push(response.package);
    }

    setPackages(futurePackages);
    setIsLoading(false);
    return { error: false };
  };

  const getServices = async ({ id }: { id: number }) => {
    setIsLoading(true);
    const response = await orbitRequest({
      route: `/service/?package_id=${id}`,
      method: RequestMethod.GET,
    });
    if (!response || response.error) {
      toast.error(response?.message || 'Something went wrong');
      setIsLoading(false);
      return { message: response?.message, error: true };
    }
    if (response?.services) {
      setServices(response.services);
      setIsLoading(false);
      return response;
    }

    setIsLoading(false);
    return { error: false, data: response?.response?.data };
  };

  const deleteService = async ({ id }: { id: number }) => {
    const response = await orbitRequest({
      route: `/service/delete`,
      payload: { id: id },
      method: RequestMethod.POST,
    });
    if (!response || response.error) {
      return toast.error(response?.message || 'Something went wrong');
    }

    toast.success('Service was deleted!');
    const futureServices = services.filter((companyPackage) => companyPackage.id !== id);
    setServices(futureServices);
  };

  const createAndUpdateService = async ({ payload }: { payload: ServiceType }) => {
    setIsLoading(true);
    const response = await orbitRequest({
      route: `/service/${payload.id ? 'update' : 'create'}`,
      payload: payload,
      method: RequestMethod.POST,
    });
    if (!response || response.error) {
      setIsLoading(false);
      toast.error(response?.message || 'Something went wrong');
      return { error: true };
    }
    toast.success(`${payload.id ? 'Service was updated!' : 'Service was created!'}`);

    const futureServices = JSON.parse(JSON.stringify(services));
    if (payload.id) {
      const index = services.findIndex((service) => service.id === payload.id);
      futureServices[index] = response.service;
    } else {
      futureServices.push(response.service);
    }

    setServices(futureServices);
    setIsLoading(false);
    return { error: false };
  };

  const getCompanyPackageDetails = async ({ id }: { id: number }) => {
    setIsLoading(true);
    const response = await orbitRequest({
      route: `/company-package-details/get-package-details?id=${id}`,
      method: RequestMethod.GET,
    });

    if (!response || response.error) {
      toast.error(response?.message || 'Something went wrong');
      setIsLoading(false);
      return { message: response?.message, error: true };
    }

    if (response?.company_package_details) {
      setIsLoading(false);
      return { data: response.company_package_details, error: false };
    }

    setIsLoading(false);
    return { error: true, data: response?.data };
  };

  const createAndUpdateCompanyPackageDetails = async ({
    payload,
  }: {
    payload: CompanyPackageGeneralInfo;
  }) => {
    setIsLoading(true);
    const formData = new FormData();
    payload.id && formData.append('id', String(payload.id));
    payload.photo && formData.append('photo', payload.photo);
    payload.uploaded_document && formData.append('uploaded_document', payload.uploaded_document);
    payload.email_template && formData.append('email_template', payload.email_template);
    payload.email_title && formData.append('email_title', payload.email_title);
    formData.append('general_conditions_text', payload.general_conditions_text);
    formData.append('pdf_title', payload.pdf_title);
    formData.append('company_id', String(payload.company_id));
    const token = window.localStorage['jwt'] ? JSON.parse(window.localStorage['jwt']) : null;
    axios({
      method: 'post',
      url: `${API_URL}/company-package-details/${payload.id ? 'update' : 'create'}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => {
        setIsLoading(false);
        if (!res?.data || res?.data?.error) {
          toast.error(res?.data.message || 'Something went wrong');
          return { error: true };
        }

        toast.success(
          `${payload.id ? 'Informațiile generale au fost modificate!' : 'Informațiile generale au fost adăugate!'}`
        );
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data?.message || 'Something went wrong');
      });
  };

  const previewContract = async ({
    payload,
    fileNameData,
  }: {
    payload: PreviewContractPayload;
    fileNameData: {
      packageName: string;
      type: 'subscription' | 'package';
      clientName?: string;
      subscriptionActivationDate?: string;
    };
  }) => {
    let fileName = '';
    const kia = 'KIA';
    const volvo = 'VOLVO';

    if (fileNameData.type === 'subscription') {
      const formattedDate = moment(
        fileNameData.subscriptionActivationDate,
        'YYYY-MM-DD HH_mm_ss'
      ).format('YYYY-MM-DD');
      if (fileNameData.packageName.toLowerCase().includes(kia.toLowerCase())) {
        fileName = `Poliță asistență rutieră KAR-${fileNameData.clientName}-${formattedDate}.pdf`;
      } else if (fileNameData.packageName.toLowerCase().includes(volvo.toLowerCase())) {
        fileName = `${formattedDate}_${fileNameData.clientName}_KAR_CONTRACT.pdf`;
      } else {
        fileName = `${formattedDate}_${fileNameData.clientName}_CONTRACT.pdf`;
      }
    } else {
      fileName = `PREVIEW_${fileNameData.packageName}_CONTRACT.pdf`;
    }

    const response = await customAxios.post(`${API_URL}/pdf/preview-invoice`, payload, {
      responseType: 'blob',
    });

    if (
      response.request.responseType === 'blob' &&
      response.data instanceof Blob &&
      response.data.type &&
      response.data.type.toLowerCase().indexOf('json') != -1
    ) {
      const errorString = JSON.parse(await response.data.text());
      if (errorString.error) {
        toast.error(errorString.message);
        setIsLoading(false);
        return;
      }
    }

    if (response) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    return { error: false, data: response?.data };
  };

  const postImageInContractCompanyDetails = async ({
    payload,
  }: {
    payload: PostImageInContractCompanyDetailsPayload;
  }) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      payload.company_id && formData.append('company_id', String(payload.company_id));
      payload.image && formData.append('image', payload.image);
      const token = window.localStorage['jwt'] ? JSON.parse(window.localStorage['jwt']) : null;
      const res = await axios({
        method: 'post',
        url: `${API_URL}/pdf/upload-company-image`,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setIsLoading(false);
      if (!res?.data || res?.data?.error) {
        toast.error(res?.data.message || 'Something went wrong');
        return { error: true };
      } else {
        return { data: res.data.company_package_general_conditions, error: false };
      }
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.message || 'Something went wrong');
    }
  };

  const store = {
    isLoading,
    setIsLoading,
    packages,
    getPackages,
    deletePackage,
    createAndUpdatePackage,
    services,
    getServices,
    deleteService,
    createAndUpdateService,
    getPackage,
    getCompanyPackageDetails,
    createAndUpdateCompanyPackageDetails,
    previewContract,
    postImageInContractCompanyDetails,
  };

  const storeForProvider = useMemo(() => store, [store]);
  return (
    <PackageContext.Provider value={storeForProvider}>{props.children}</PackageContext.Provider>
  );
}

export { PackageContext };
export default PackageProvider;

import {useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {UserContext, UserRole, rolesList} from '../../../../../context/user.context'
import {UsersContext} from '../../../../../context/users.context'
import {capitalize, titleCase} from '../../../../../utils/string.utils'
import {KTSVG} from '../../../../../_metronic/helpers'
import {UserModel} from '../../../auth/models/UserModel'

const statusData = ['active', 'inactive']
export const CreateUpdateUserModal = ({
  visible,
  setVisible,
  user,
  mainCompanyId
}: {
  visible: boolean
  setVisible: any
  user?: UserModel
  mainCompanyId:number
}) => {
  const {createUser, updateUser, companies} = useContext<any>(UsersContext)
  const {user: authUser} = useContext<any>(UserContext)
  const localRolesList = (mainCompanyId || authUser.role?.slug === UserRole.AdminSubscriptions) ? [UserRole.Company, UserRole.Agent] : rolesList;
  const [email, setEmail] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [localCompanies, setLocalCompanies] = useState<any>();
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  const [role, setRole] = useState<string>(localRolesList[1])
  const [status, setStatus] = useState<any>(statusData[0])
  const [companyId, setCompanyId] = useState<any>(null)
  
  useEffect(()=>{
    if (mainCompanyId){
      const futureCompanies = (companies || []).filter((e) =>
        e.main_company_id?.toString() === mainCompanyId?.toString() || 
        (e.main_company_id === null && e.id?.toString() === mainCompanyId?.toString())
      )
      setLocalCompanies(futureCompanies);
    }else{
      setLocalCompanies(companies)
    }
  },[companies])

  const resetState = () => {
    setEmail('')
    setFirstName('')
    setLastName('')
    setPassword('')
    setPasswordConfirmation('')
    setRole(localRolesList[1])
    setStatus(statusData[0])
    setCompanyId(null)
  }

  useEffect(() => {
    if (!visible) {
      resetState()
    }
  }, [visible])

  const getCompanyId = () =>{
    if(!localCompanies){
      return null;
    }
    if ((role === 'company' || role === 'agent') && localCompanies?.length > 0){
      return localCompanies[0].id
    }
    return null;
  }

  const createNewUser = async () => {
    const result = await createUser({
      email,
      firstName,
      lastName,
      role,
      status,
      company_id: companyId || getCompanyId(),
      password,
      passwordConfirmation,
    })

    if (result.error) {
      return ''
    }

    resetState()
    setVisible(false)
  }

  const _updateUser = async () => {
    const result = await updateUser({
      id: user!.id,
      email,
      firstName,
      lastName,
      role,
      company_id: companyId,
      status,
      password,
      passwordConfirmation,
    })

    if (result.error) {
      toast.error(result.message)
      return ''
    }

    resetState()
    setVisible(false)
  }

  const getCompany = (companyId: number) => {
    const company = (companies || []).filter((e) => e.id === companyId)

    if (company) {
      return company[0]
    }

    return null
  }

  useEffect(() => {
    if (user) {
      setEmail(user.email)
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setRole(user.role)
      setCompanyId(user.company_id)
      setStatus(user.status)
    } else {
      resetState()
    }
  }, [user, visible])

  const isDisabled = () => {
    if (!email || !firstName || !lastName) {
      return true
    }

    if (
      user &&
      password &&
      (!password || !passwordConfirmation || password !== passwordConfirmation)
    ) {
      return true
    }
    if (!user && (!password || !passwordConfirmation || password !== passwordConfirmation)) {
      return true
    }

    return false
  }
  return (
    <Modal
      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
      id='kt_inbox_compose'
      role='dialog'
      data-backdrop='false'
      aria-hidden='true'
      tabIndex='-1'
      show={visible}
      animation={false}
    >
      <div className='modal-content'>
        {/* begin::Form */}
        {/*begin::Header*/}
        <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
          <h5 className='fw-bold m-0'>{user ? `Edit user ${user.id}` : 'Create a new user'}</h5>
          <div className='d-flex ms-2'>
            {/*begin::Close*/}
            <div
              className='btn btn-icon btn-sm btn-light-primary ms-2'
              data-bs-dismiss='modal'
              onClick={() => {
                setVisible(false)
              }}
            >
              <KTSVG className='svg-icon-1' path='/media/icons/duotone/Navigation/Close.svg' />
            </div>
            {/*end::Close*/}
          </div>
        </div>
        {/*end::Header*/}

        {/*begin::Body*/}
        <div className='d-block py-5 ps-8 pe-5'>
          {/* Full name */}
          <div className='row'>
            {/* First name */}
            <div className='col-lg-6 fv-row mb-7'>
              <label className='form-label fw-bolder text-dark fs-6'>First name</label>
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder='Popescu'
                type='text'
                autoComplete='off'
                className={'form-control form-control-lg form-control-solid'}
              />
            </div>

            {/* Last name */}
            <div className='col-lg-6 fv-row mb-7'>
              <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder='Ion'
                type='text'
                autoComplete='off'
                className={'form-control form-control-lg form-control-solid'}
              />
            </div>
          </div>

          {/* Email */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Email</label>
            <input
              disabled={user ? true : false}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='ionpopescu@example.com'
              type='text'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>

          {/* Password */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Password</label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='******'
              type='password'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>

          {/* Password */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Confirm password</label>
            <input
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              placeholder='******'
              type='password'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>

          {/* Status */}
          {/* <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Status</label>

            <div className='fv-row'>
              <select
              key={status}
                className='form-select form-select-solid form-select-lg'
                defaultValue={status}
                onChange={(e) => {
                  setStatus(e.target.value)
                }}
              >
                {statusData.map((e) => {
                  return (
                    <option key={e} value={e}>
                      {capitalize(e)}
                    </option>
                  )
                })}
              </select>
            </div>
          </div> */}

          {/* Role */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Role</label>

            <div className='fv-row'>
              <select
                className='form-select form-select-solid form-select-lg'
                key={role}
                onChange={(e) => {
                  const nextRole = e.target.value
                  if (nextRole !== 'company' && nextRole !== 'agent') {
                    setCompanyId(null)
                  }
                  setRole(nextRole)
                }}
                defaultValue={role}
              >
                {localRolesList.map((role) => {
                  return (
                    <option key={role} value={role}>
                      {titleCase(role.replaceAll('-', ' '))}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>

          {/* Company */}
          {role === 'company' || role === 'agent' ? (
            <div className='fv-row mb-7'>
              <label className='form-label fw-bolder text-dark fs-6'>Company</label>

              <div className='fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg'
                  key={`company-${companyId}`}
                  onChange={(e) => {
                    setCompanyId(e.target.value)
                  }}
                  defaultValue={companyId}
                >
                  {(localCompanies || []).map((company) => {
                    return (
                      <option key={company.id} value={company.id}>
                        {capitalize(company.name)}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          ) : null}
          {/* end::Form group */}
        </div>
        {/*end::Body*/}

        {/*begin::Footer*/}
        <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-top'>
          {/*begin::Actions*/}
          <div className='d-flex align-items-center me-3'></div>
          {/*end::Actions*/}

          {/*begin::Toolbar*/}
          <div className='d-flex align-items-center'>
            <button
              disabled={isDisabled()}
              onClick={() => {
                if (user) {
                  return _updateUser()
                } else {
                  return createNewUser()
                }
              }}
              className='btn btn-primary px-6'
            >
              {user ? 'Update' : 'Create'}
            </button>
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Footer*/}
        {/*end::Form*/}
      </div>
    </Modal>
  )
}

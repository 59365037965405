import {LoginModel} from '../../app/modules/auth/models/AuthModel'
import {APIResponseType} from '../../types/general.types'
import {orbitRequest, RequestMethod} from '../../utils/api.utils'

// Login request
export const loginRequest = async (data: LoginModel): Promise<APIResponseType> => {
  const requestData = {email: data.email, password: data.password}
  return orbitRequest({route: '/user/login', payload: requestData, method: RequestMethod.POST})
}
// forgotPasswordRequest request
export const forgotPasswordRequest = (data: any) => {
  const requestData = {email: data.email}
  return orbitRequest({route: '/forgot', payload: requestData, method: RequestMethod.POST})
}
// resetPasswordRequest request
export const resetPasswordRequest = (data: any) => {
  const requestData = {password: data.password, token: data.token}
  return orbitRequest({route: '/reset', payload: requestData, method: RequestMethod.POST})
}

// User request
export const getCompanyRequest = (id: string | number) => {
  return orbitRequest({route: `/company?id=${id}`, method: RequestMethod.GET})
}

import chroma from "chroma-js";

const CLIENT_COLOR = "#009EF7";
const SUPPLIER_COLOR = "#7239EA";

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 17,
    content: color === CLIENT_COLOR ? '"C"' : '"S"',
    display: 'block',
    marginRight: 6,
    textAlign: 'center',
    color: "white",
    fontWeight: "bold",
    lineHeight: 1.4,
    height: 17,
    width: 17
  },
});

export const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.client ? CLIENT_COLOR : SUPPLIER_COLOR);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.client ? CLIENT_COLOR : SUPPLIER_COLOR
        : isFocused
        ? color.alpha(0.1).css()
        : undefined,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.client ? CLIENT_COLOR : SUPPLIER_COLOR,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.client ? CLIENT_COLOR : SUPPLIER_COLOR
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
  singleValue: (styles, { data }) => ({ ...styles, overflow: "visible", ...dot(data?.client ? CLIENT_COLOR : SUPPLIER_COLOR) }),
};

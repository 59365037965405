import { toast } from "react-toastify";
import { StateSetter } from "../../utils/state.utils";
import { getDefaultBillingDate } from "./modules/PlanRequests";
import moment from "moment";

export const plansWithBillingDateOptions: {name: string, value: null | boolean}[] = [
  { name: 'ALL', value: null },
  { name: 'With Default Date', value: true },
  { name: 'Different Date', value: false }
]

export const displayDate = (date: Date | string) => {
  if (!date) {
    return 'Invalid Date'
  }
  return moment(date).format(`YYYY-MM-DD HH:mm`)
}

export const getNextValidDate = (day: number): string => {
  if (!day || day < 0) {
    return 'Invalid Date'
  }
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  let targetMonth = (day <= today.getDate()) ? currentMonth + 1 : currentMonth;
  let targetYear = currentYear;

  if (targetMonth > 12) {
    targetMonth = 1;
    targetYear++;
  }

  const lastDay = new Date(targetYear, targetMonth, 0).getDate();
  const targetDay = Math.min(day, lastDay);

  const formattedDate = `${targetYear}-${targetMonth.toString().padStart(2, '0')}-${targetDay.toString().padStart(2, '0')}`;

  return formattedDate;
};

export const getAndSetAutoBillingDate = async ({
  setAutoBillingDate,
  businessProfileId,
  setIsLoading
}: {
  setAutoBillingDate: StateSetter<number>,
  businessProfileId: string,
  setIsLoading: StateSetter<boolean>
}) => {
  setIsLoading(true)
  const response = await getDefaultBillingDate({ businessProfileId })
  setIsLoading(false)
  if (response.error) {
    toast.error("couldn't fetch the default billing date!")
    return;
  }
  setAutoBillingDate(response.billingDate)
}
import React, { useState } from 'react'
import { RequestMethod, orbitRequest } from '../../../../../utils/api.utils'
import { toast } from 'react-toastify'
import { FlatSubscriptionType } from '../../../../../types/subscription.types'

const sendStripePaymentEmail = async (sub: FlatSubscriptionType) => {
  const result = await orbitRequest({
    route: '/stripe/product/createSubscriptionLinkAndSendEmail',
    payload: {
      allow_promotion_codes: false,
      currency: "RON",
      subscription_id: sub.id,
      client_mail: sub.client_email
    },
    method: RequestMethod.POST
  })
  if (!result || result.error) {
    toast.error("Emailul de plată nu s-a putut trimite!")
  } else {
    toast.success("Emailul de plată s-a trimis cu succes!")
  }
}

const SendStripePaymentEmailModal = ({
  subscription,
  setModalVisible
}: {
  subscription: FlatSubscriptionType,
  setModalVisible: (_: boolean) => void
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <div className='py-5 px-8' style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ textAlign: 'center', fontSize: '16px' }}>
        Prin această operațiune se va trimite un email de plată către <b>{subscription.client_email}</b> pentru subscripția cu id <b>{subscription.id}</b>. Doriți să continuați?
      </span>
      <div className='mt-8' style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          disabled={isLoading}
          className='btn btn-secondary me-5'
          onClick={() => setModalVisible(false)}
        >
          Anuleaza
        </button>
        <button
          disabled={isLoading}
          className='btn btn-primary'
          onClick={async () => {
            setIsLoading(true)
            await sendStripePaymentEmail(subscription)
            setModalVisible(false)
            setIsLoading(false)
          }}
        >
          Trimite
        </button>
      </div>
    </div>
  )
}

export default SendStripePaymentEmailModal
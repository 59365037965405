import { useEffect, useState } from 'react'
import { extractCoordsObjFromGps, getRouteDetails } from '../../../../utils/geolocation.utils'
import moment from 'moment'
import { Button } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

let courseMap = null,
  directionsRenderer = null

const initMap = () => {
  courseMap = new window.google.maps.Map(document.getElementById('courseMap'), {
    center: {
      lat: 44.4268,
      lng: 26.1025,
    },
    zoom: 12,
  })
  directionsRenderer = new google.maps.DirectionsRenderer()
  directionsRenderer.setMap(courseMap)
}

type CourseMapProps = {
  startLocation: google.maps.LatLng
  endLocation: google.maps.LatLng
  waypointsArray: google.maps.LatLng[]
  departureDateString?: string
}

const CourseMap = ({ startLocation, endLocation, waypointsArray, departureDateString }: CourseMapProps) => {
  useEffect(() => {
    initMap()
  }, [])

  useEffect(() => {
    if (Boolean(courseMap) && Boolean(startLocation) && Boolean(endLocation)) {
      ; (async () => {
        const result: any = await getRouteDetails({
          startLocation: startLocation,
          endLocation: endLocation,
          waypointsArray: waypointsArray || [],
          departureDateString,
          giveWholeResult: true,
        })
        directionsRenderer.setDirections(result)
      })()
    }
  }, ['' + startLocation + endLocation + waypointsArray]) // quick fix for a rerendering problem (NOT IDEAL)

  return (
    <div
      id='courseMap'
      style={{
        flex: 1,
        backgroundColor: 'white',
        width: '100%',
        height: '300px',
      }}
    />
  )
}

interface GoogleRouteProps {
  startLocationCoords: string
  endLocationCoords: string
  waypointsCoordsArray: string[]
}

export const ViewGoogleMapsButton = ({ startLocationCoords, endLocationCoords, waypointsCoordsArray }: GoogleRouteProps) => {
  const origin = startLocationCoords
  const destination = endLocationCoords
  const hasWaypoints = Boolean(waypointsCoordsArray) && Array.isArray(waypointsCoordsArray) && waypointsCoordsArray.length > 0
  const waypoints = hasWaypoints ? waypointsCoordsArray.join('/') : null;

  let googleMapsUrl = 'https://www.google.com/maps/dir/';
  if (origin) {
    googleMapsUrl += `${origin}/`;
  }
  if (waypoints) {
    googleMapsUrl += `${waypoints}/`;
  }
  if (destination) {
    googleMapsUrl += `${destination}/`;
  }

  return (
    <Button
      style={{ width: '150px', border: 'none', padding: '6px' }}
      onClick={() => window.open(googleMapsUrl, '_blank')}
    >
      Show Route
    </Button>
  )
}

interface OptionalCourseMapProps extends GoogleRouteProps {
  showMapInitial?: boolean
  departureDateString?: string
}

export const OptionalCourseMapWrapper = ({ startLocationCoords, endLocationCoords, waypointsCoordsArray, departureDateString, showMapInitial = false }: OptionalCourseMapProps) => {
  const [generateGoogleMap, setGenerateGoogleMap] = useState<boolean>(showMapInitial)

  if (generateGoogleMap) {
    return (
      <CourseMapWrapper
        startLocation={extractCoordsObjFromGps({
          gps: startLocationCoords,
        })}
        endLocation={extractCoordsObjFromGps({
          gps: endLocationCoords,
        })}
        waypointsArray={
          waypointsCoordsArray
            ? waypointsCoordsArray.map((waypointCoords) => extractCoordsObjFromGps({ gps: waypointCoords }))
            : []
        }
        departureDateString={departureDateString}
      />
    )
  }

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      width: '100%',
      height: '300px',
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '250px',
        width: '35%',
        height: '50%',
        backgroundColor: '#F5F8FA',
        zIndex: 1,
      }}>
        <Button
          style={{ width: '150px', border: 'none', padding: '6px' }}
          className="btn-info"
          onClick={() => {
            if (!generateGoogleMap) {
              setGenerateGoogleMap(true)
            }
          }}
        >
          Generate Map
        </Button>
        <ViewGoogleMapsButton startLocationCoords={startLocationCoords} endLocationCoords={endLocationCoords} waypointsCoordsArray={waypointsCoordsArray} />
      </div>
      <img
        src={toAbsoluteUrl('/media/pictures/google-maps-route.jpg')}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          opacity: '0.25',
          pointerEvents: 'none',
          position: 'absolute'
        }}
      />
    </div>

  )
}

export const CourseMapWrapper = ({ startLocation, endLocation, waypointsArray, departureDateString }: CourseMapProps) => {
  const isGoogleLoaded = Boolean(window.google)
  if (!isGoogleLoaded) {
    return null
  }
  return (
    <CourseMap
      startLocation={startLocation}
      endLocation={endLocation}
      waypointsArray={waypointsArray}
      departureDateString={departureDateString}
    />
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {CarsTableComponent} from './components/cars-table/CarsTable.component'
import { LogsTableComponent } from './components/logs-table/LogsTable'
import { CarsMapComponent } from './map/CarsMap'

const CarsPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='gy-5 gx-xl-8 full-map'>

      <CarsMapComponent />
    </div>
    {/* end::Row */}
    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xl-8'>
        <CarsTableComponent className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
      <div className='col-xxl-4'>
        <LogsTableComponent className='card-xxl-stretch' />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const CarsWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CARS'})}</PageTitle>
      <CarsPage />
    </>
  )
}

export default CarsWrapper

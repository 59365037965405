import '../modules/form.scss'
import {useEffect, useMemo, useState} from 'react'
import {Button} from 'react-bootstrap'
import Select, {SelectObject} from '../../../../_metronic/layout/components/select/Select'
import {right} from '@popperjs/core'
import {toast} from 'react-toastify'
import {RequestMethod, orbitRequest} from '../../../../utils/api.utils'
import {APIResponseType} from '../../../../types/general.types'
import {getSortedList} from '../../../../utils/sort.utils'
import {SmsFormFieldsType, emptySmsFormFieldsType} from '../../../../types/campaign.types'
import {useParams} from 'react-router-dom'
import { getCountiesRequest, getLocationsByCountyRequest } from '../../../../setup/axios/campaign.request'

export const getCampaignsByLocation = async (location: string): Promise<APIResponseType> =>
  await orbitRequest({
    route: `/tire/getByLocation?location=${location}`,
    method: RequestMethod.GET,
  })

export const getSubsidiariesRequest = async (): Promise<APIResponseType> =>
  await orbitRequest({
    route: `/tire`,
    method: RequestMethod.GET,
  })

const FormFields = ({client, formType}: {client: any; formType: string}) => {
  const [selectedSubsidiary, setSubsidiary] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState('')
  const [selectedCounty, setSelectedCounty] = useState('')
  const [isFormVisible, setIsFormVisible] = useState([false])
  const [readonly, setReadonly] = useState<boolean>(formType === 'edit')
  const [services, setServices] = useState([])
  const [counties, setCounties] = useState([])
  const [locations, setLocations] = useState([])
  const [selectedService, setService] = useState('')
  const [smsFormData, setSmsFormData] = useState<SmsFormFieldsType>(emptySmsFormFieldsType)
  const [subsidiaries, setSubsidiaries] = useState([])
  const [isConditionChecked, setIsConditionChecked] = useState<boolean>(false)
  const [isCarEstrangedChecked, setIsCarEstrangedChecked] = useState(false)
  const [initialSmsFormData, setInitialSmsFormData] = useState<SmsFormFieldsType>(emptySmsFormFieldsType)
  const {campaignId} = useParams<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const maxMessageCharacters = 300
  const maxVINCharacters = 17
  const generalFields = [
    'is_car_estranged',
    'is_promotion_approved',
    'is_client_details_correct',
    'is_data_processing_approved',
    'text_message',
  ]

  const handleInputChange = (fieldName, value) => {
    if (generalFields.includes(fieldName)) {
      setSmsFormData((oldSmsFormData) => ({
        ...oldSmsFormData,
        [fieldName]: value,
      }))
    } else {
      setSmsFormData((oldSmsFormData) => ({
        ...oldSmsFormData,
        [fieldName]: value,
        is_sent: 1,
      }))
    }
  }

  useEffect(() => {
    if (isFormVisible.some((e) => e)) {
      getAndSetCounties()
      getAndSetSubsidiaries()
      if (client?.subsidiary === 'BUCURESTI') {
        changeServiceDataForBranch()
      }
    }

    setSmsFormData({
      id: client?.id,
      campaign_id: Number(campaignId),
      verification_short_code: client?.verification_short_code,
      license_number: client?.license_number,
      phone: client?.phone,
      text_message: client?.text_message,
      is_client_details_correct: client?.is_client_details_correct,
      is_data_processing_approved: client?.is_data_processing_approved,
      is_promotion_approved: client?.is_promotion_approved,
      company: client?.company,
      location: client?.location,
      brand: client?.brand,
      model: client?.model,
      VIN: client?.VIN,
      subsidiary: client?.subsidiary,
      branch: client?.branch,
      is_car_estranged: client?.is_car_estranged,
      county: client?.county,
      email: client?.email,
      full_name: client?.full_name,
      no_of_km: client?.no_of_km,
      sms_status: client?.sms_status,
      is_sent: client?.is_sent,
    })
    setIsConditionChecked(client?.is_client_details_correct === 1 ? true : false)
    setIsCarEstrangedChecked(client?.is_car_estranged === 1 ? true : false)
  }, [isFormVisible])

  const getAndSetSubsidiaries = async () => {
    const subsidiariesResponse = await getSubsidiariesRequest()
    if (!subsidiariesResponse || subsidiariesResponse.error) {
      toast.error(subsidiariesResponse?.message || 'Something went wrong')
      return
    }

    setSubsidiaries(subsidiariesResponse.tire_shops)
    // find the subsidiary that matches the client's subsidiary
    setSubsidiary(
      subsidiariesResponse.tire_shops.find((tire_shop) => tire_shop.branch === client?.subsidiary)
    )
  }

  const getAndSetCounties = async () => {
    setIsLoading(true)
    const countiesListResponse = await getCountiesRequest()
    if (!countiesListResponse || countiesListResponse.error) {
      toast.error(countiesListResponse?.message || 'Something went wrong')
      setIsLoading(false)
      return
    }

    // find the county that matches the client's county
    const defaultCounty = countiesListResponse.counties.find(
      (county) => county.county === client?.county
    )
    setCounties(countiesListResponse.counties)
    setSelectedCounty(defaultCounty)
    // if a county was found => get the locations for that county
    if (defaultCounty?.county) {
      const countiesResponse = await getLocationsByCountyRequest(defaultCounty.county)
      if (!countiesResponse || countiesResponse.error) {
        toast.error(countiesResponse?.message || 'Something went wrong')
        setIsLoading(false)
        return
      }

      const locations = countiesResponse.locations?.map((location) => ({
        id: location.id,
        location: location.location,
      }))
      setLocations(locations)
      const defaultLocation = locations.find((location) => location.location === client?.location)
      setSelectedLocation(defaultLocation)
    }

    setIsLoading(false)
  }

  const toggleForm = (id) => {
    setSmsFormData(smsFormData)
    setInitialSmsFormData(smsFormData)
    const updatedIsFormVisible = [...isFormVisible]
    updatedIsFormVisible[id] = !updatedIsFormVisible[id]
    setIsFormVisible(updatedIsFormVisible)
  }

  const toggleEditFields = () => {
    setReadonly(!readonly)
  }

  const handleCharactersCount = (e, field, maxCharacters) => {
    const inputText = e.target.value

    if (inputText?.length <= maxCharacters) {
      handleInputChange(field, inputText)
    }
  }

  const validateNumericInput = (e, fieldName) => {
    const re = /^[0-9\b]+$/

    if (e.target.value === '' || re.test(e.target.value)) {
      handleInputChange(fieldName, e.target.value)
    } else {
      toast.error('Only numeric values are allowed')
    }
  }

  const validateAlphaNumericInput = (e, fieldName) => {
    const re = /^[a-zA-Z0-9\b]+$/

    if (e.target.value === '' || re.test(e.target.value)) {
      handleInputChange(fieldName, e.target.value)
    } else {
      toast.error('Only alphanumeric values are allowed')
    }
  }

  const removeSubsidiaryDuplicates = (unfiltered_array) => {
    const filtered_set = new Set(unfiltered_array?.map((elem) => elem.branch))
    const filtered_array = Array.from(filtered_set)?.map((branch) => ({branch}))
    return filtered_array
  }

  const removeServiceDuplicates = (unfiltered_array) => {
    const filtered_set = new Set(unfiltered_array?.map((elem) => elem.service))
    const filtered_array = Array.from(filtered_set)?.map((service) => ({service}))
    return filtered_array
  }

  const changeServiceDataForBranch = async () => {
    const servicesResponse = await getCampaignsByLocation('Bucuresti')
    if (!servicesResponse || servicesResponse.error) {
      toast.error(servicesResponse?.message || 'Something went wrong')
      return
    }
    setServices(
      servicesResponse.tire_shops_by_location?.map((client) => ({
        id: client.id,
        service: client.company,
      }))
    )
    handleInputChange('branch', client?.branch)
    setService(client?.branch)
  }

  const submitChanges = async (e, formType) => {
    let requestRoute
    if (formType === 'edit') {
      requestRoute = `/clients-and-cars/update`
    } else {
      requestRoute = `/clients-and-cars/addCar`
    }

    let response = await orbitRequest({
      route: `${requestRoute}`,
      payload: smsFormData,
      method: RequestMethod.POST,
    })

    if (!response || response.error) {
      return toast.error(response?.message || 'Something went wrong')
    }

    toggleForm(client.id)

    if (formType === 'edit') {
      toast.success('Data was edited')
    } else {
      toast.success('Data was added')
    }
    window.location.reload()

    return
  }

  const formButtonDetails = useMemo(() => {
    let futureDetails = {
      classes: 'btn btn-primary mb-5',
      message: isFormVisible[client.id]
        ? 'Close Form'
        : `Open Form for ${client.license_number || 'ZZ-00-ZZZ'}`,
    }

    if (formType === 'add') {
      futureDetails = {
        classes: 'btn btn-success mb-5',
        message: isFormVisible[client.id] ? 'Close Form' : `Add a new car`,
      }
    }

    return futureDetails
  }, [formType, isFormVisible])

  return (
    <>
      <div>
        <Button
          className={formButtonDetails.classes}
          style={{
            width: 300,
            maxWidth: '100%',
            margin: '0 auto',
            padding: '10px 20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => {
            if (client.id === undefined) {
              client.id = 0
            }
            toggleForm(client.id)
          }}
        >
          {formButtonDetails.message}
        </Button>
      </div>

      {isFormVisible[client.id] && (
        <div className='custom-form form center mb-5'>
          <div className='close-button-wrapper'>
            {!isCarEstrangedChecked ? (
              <div>
                {isFormVisible[client.id] ? (
                  <Button className='edit-fields p-5' onClick={() => toggleEditFields()}>
                    {readonly ? 'Modifică datele' : 'Blocheaza datele'}
                  </Button>
                ) : null}
              </div>
            ) : (
              <div />
            )}

            <div
              className='close-button'
              onClick={() => {
                if (client.id === undefined) {
                  client.id = 0
                }
                toggleForm(client.id)
              }}
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                <path
                  fill='currentColor'
                  fill-rule='evenodd'
                  d='M6.793 6.793a1 1 0 0 1 1.414 0L12 10.586l3.793-3.793a1 1 0 1 1 1.414 1.414L13.414 12l3.793 3.793a1 1 0 0 1-1.414 1.414L12 13.414l-3.793 3.793a1 1 0 0 1-1.414-1.414L10.586 12L6.793 8.207a1 1 0 0 1 0-1.414Z'
                  clip-rule='evenodd'
                />
              </svg>
            </div>
          </div>

          <div className='form-content'>
            <div className='wpcf7 js' id='wpcf7-f7-o1' lang='ro-RO' dir='ltr'>
              <div className='screen-reader-response'></div>
              <form
                method='post'
                className='wpcf7-form init'
                aria-label='Formular de contact'
                data-status='init'
              >
                {formType === 'edit' ? (
                  <h4 className='form-info'>
                    Te rugăm să ne confirmi că datele de mai jos sunt corecte. Dacă necesită
                    actualizări, te rugăm să apeși butonul <b>Modifică Datele</b> din josul paginii
                    pentru a putea să le editezi și să le actualizezi în baza noastră de date.
                  </h4>
                ) : (
                  <h4 className='form-info'>
                    Te rugăm să completezi datele de mai jos și să confirmi la final că acestea sunt
                    corecte.
                  </h4>
                )}

                {/* Car Ownership Checkbox */}
                {formType === 'edit' && (
                  <div className='mb-5'>
                    <div style={{display: 'inline-block', verticalAlign: 'middle'}}>
                      <input
                        type='checkbox'
                        checked={smsFormData?.is_car_estranged ? true : false}
                        onChange={(e) => {
                          handleInputChange('is_car_estranged', e.target.checked)
                          setIsCarEstrangedChecked(!isCarEstrangedChecked)
                          setReadonly(isCarEstrangedChecked ? false : true)
                        }}
                        style={{width: '25px', height: '25px'}}
                      />
                    </div>
                    <label className='ms-5' style={{verticalAlign: 'middle'}}>
                      <span style={{verticalAlign: '-0.2em'}}>
                        Nu mai sunt proprietarul mașinii
                      </span>
                    </label>
                  </div>
                )}

                {/* Nume si Prenume */}
                <div className='mb-5'>
                  <label>Nume și Prenume</label>
                  <input
                    className='wpcf7-form-control wpcf7-text wpcf7-validates-as-required'
                    aria-required='true'
                    aria-invalid='false'
                    type='text'
                    value={smsFormData.full_name}
                    readOnly={readonly}
                    onChange={(e) => handleInputChange('full_name', e.target.value)}
                  ></input>
                </div>

                {/* E-Mail */}
                <div className='mb-5'>
                  <label>E-Mail</label>
                  <input
                    className='wpcf7-form-control wpcf7-text wpcf7-validates-as-required'
                    aria-required='true'
                    aria-invalid='false'
                    type='text'
                    value={smsFormData.email}
                    readOnly={readonly}
                    onChange={(e) => handleInputChange('email', e.target.value)}
                  ></input>
                </div>

                {/* Firma */}
                <div className='mb-5'>
                  <label>Firmă</label>
                  <input
                    className='wpcf7-form-control wpcf7-text wpcf7-validates-as-required'
                    aria-required='true'
                    aria-invalid='false'
                    type='text'
                    value={smsFormData.company}
                    readOnly={readonly}
                    style={{borderColor: smsFormData.company ? '#eee' : 'red'}}
                    onChange={(e) => handleInputChange('company', e.target.value)}
                  ></input>
                </div>

                {/* Judet */}
                <div className='mb-5 input-custom-wrapper'>
                  <Select
                    label='Județ'
                    value={selectedCounty || ''}
                    isDisabled={readonly}
                    options={getSortedList({listToSort: counties || [], args: ['county']})}
                    getOptionLabel={(option: any) => option.county}
                    getOptionValue={(option: any) => option.county}
                    styles={{
                      control: (baseStyles) =>
                        smsFormData.county
                          ? {
                              ...baseStyles,
                              borderColor: '#eee',
                            }
                          : {
                              ...baseStyles,
                              borderColor: 'red',
                            },
                    }}
                    onChange={async (e) => {
                      // reset the location when a new county is selected
                      setSelectedCounty(e)
                      setSelectedLocation(null)
                      const countiesResponse = await getLocationsByCountyRequest(e.county)
                      if (!countiesResponse || countiesResponse.error) {
                        toast.error(countiesResponse?.message || 'Something went wrong')
                        return
                      }

                      setLocations(
                        countiesResponse.locations?.map((location) => ({
                          id: location.id,
                          location: location.location,
                        }))
                      )
                      handleInputChange('county', e.county)
                    }}
                  />
                </div>

                {/* Locatie */}
                <div className='mb-5 input-custom-wrapper'>
                  <Select
                    label='Localitate'
                    value={selectedLocation || ''}
                    isDisabled={readonly}
                    options={getSortedList({listToSort: locations || [], args: ['location']})}
                    getOptionLabel={(option: any) => option.location}
                    getOptionValue={(option: any) => option.location}
                    styles={{
                      control: (baseStyles, state) =>
                        smsFormData.county
                          ? {
                              ...baseStyles,
                              borderColor: '#eee',
                            }
                          : {
                              ...baseStyles,
                              borderColor: 'red',
                            },
                    }}
                    onChange={(e: any) => {
                      setSelectedLocation(e)
                      handleInputChange('location', e.location)
                    }}
                  />
                </div>

                {/* Telefon */}
                <div className='mb-5'>
                  <label>Telefon</label>
                  <div className='tel-field'>
                    <div className='wrapper-tel'>
                      <span
                        className='wpcf7-form-control-wrap'
                        data-name='telefon_prefix'
                        style={{maxWidth: '60px'}}
                      >
                        <div className='intl-tel-input allow-dropdown'>
                          <div className='flag-container'>
                            <div className='selected-flag' title='Romania (România): +40'>
                              <div className='iti-flag ro'>
                                <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Flag_of_Romania.svg/900px-Flag_of_Romania.svg.png?20160520173724' />
                              </div>
                            </div>
                          </div>
                          <input
                            className='wpcf7-form-control wpcf7-phonetext wpcf7-text wpcf7-validates-as-phonetext'
                            id='telefon_prefix'
                            data-numberonly='true'
                            aria-invalid='false'
                            type='text'
                            name='telefon_prefix'
                            readOnly={readonly}
                          ></input>
                          <input type='hidden' name='full_number' readOnly={readonly}></input>
                        </div>
                        <input
                          type='hidden'
                          name='telefon_prefix-country-code'
                          className='wpcf7-phonetext-country-code'
                          value='+40'
                          readOnly={readonly}
                        ></input>
                      </span>
                      <span className='wpcf7-form-control-wrap' data-name='telefon'>
                        <input
                          className='wpcf7-form-control wpcf7-text wpcf7-validates-as-required'
                          id='phone'
                          placeholder='07xxxxxxxx'
                          aria-required='true'
                          aria-invalid='false'
                          value={smsFormData.phone}
                          onChange={(e) => validateNumericInput(e, 'phone')}
                          type='text'
                          name='telefon'
                          readOnly={readonly}
                          style={{borderColor: smsFormData.phone ? '#eee' : 'red'}}
                        ></input>
                      </span>
                    </div>
                  </div>
                </div>

                {/* Marca */}
                <div className='mb-5'>
                  <label>Marcă</label>
                  <span className='wpcf7-form-control-wrap' data-name='marca'>
                    <input
                      className='wpcf7-form-control wpcf7-text wpcf7-validates-as-required'
                      aria-required='true'
                      aria-invalid='false'
                      value={smsFormData.brand}
                      onChange={(e) => handleInputChange('brand', e.target.value)}
                      type='text'
                      name='marca'
                      readOnly={formType === 'edit' ? true : readonly}
                    ></input>
                  </span>
                </div>

                {/* Model */}
                <div className='mb-5'>
                  <label>Model</label>
                  <span className='wpcf7-form-control-wrap' data-name='model'>
                    <input
                      className='wpcf7-form-control wpcf7-text wpcf7-validates-as-required'
                      aria-required='true'
                      aria-invalid='false'
                      value={smsFormData.model}
                      onChange={(e) => handleInputChange('model', e.target.value)}
                      type='text'
                      name='model'
                      readOnly={formType === 'edit' ? true : readonly}
                    ></input>
                  </span>
                </div>

                {/* Numar de Inmatriculare */}
                <div className='mb-5'>
                  <label>Număr înmatriculare</label>
                  <span className='wpcf7-form-control-wrap' data-name='numar-inmatriculare'>
                    <input
                      className='wpcf7-form-control wpcf7-text wpcf7-validates-as-required'
                      aria-required='true'
                      aria-invalid='false'
                      value={smsFormData.license_number}
                      onChange={(e) => validateAlphaNumericInput(e, 'license_number')}
                      type='text'
                      name='numar-inmatriculare'
                      readOnly={readonly}
                      style={{borderColor: smsFormData.license_number ? '#eee' : 'red'}}
                    ></input>
                  </span>
                </div>

                {/* Serie Sasiu */}
                <div className='mb-5'>
                  <label>Serie Șasiu</label>
                  <span className='wpcf7-form-control-wrap' data-name='serie-sasiu'>
                    <input
                      className='wpcf7-form-control wpcf7-text wpcf7-validates-as-required'
                      aria-required='true'
                      aria-invalid='false'
                      value={smsFormData.VIN}
                      type='text'
                      name='serie-sasiu'
                      readOnly={formType === 'edit' ? true : readonly}
                      onChange={(e) => validateAlphaNumericInput(e, 'VIN')}
                      style={{backgroundColor: formType === 'edit' ? 'lightgray' : 'white'}}
                    ></input>
                  </span>
                  {formType === 'add' && (
                    <p style={{textAlign: right, fontSize: 12}}>
                      Info caractere: {smsFormData.VIN?.length ? smsFormData.VIN?.length : 0}/
                      {maxVINCharacters}
                    </p>
                  )}
                </div>

                {/* Filiala */}
                <div className='mb-5 input-custom-wrapper'>
                  <Select
                    label='Filială'
                    value={selectedSubsidiary || ''}
                    options={getSortedList({
                      listToSort: removeSubsidiaryDuplicates(subsidiaries) || [],
                      args: ['branch'],
                    })}
                    isDisabled={readonly}
                    getOptionLabel={(option: any) => option.branch}
                    getOptionValue={(option: any) => option.branch}
                    onChange={async (e: any) => {
                      if (e.branch === 'BUCURESTI') {
                        changeServiceDataForBranch()
                      } else {
                        setService(emptySmsFormFieldsType.branch)
                        handleInputChange('branch', emptySmsFormFieldsType.branch)
                      }
                      setSubsidiary(e)
                      handleInputChange('subsidiary', e.branch)
                    }}
                  />
                </div>

                {/* Service */}
                {/* check branch field in object since 'selectedSubsidiary' is not a string anymore */}
                {selectedSubsidiary?.branch.toLocaleLowerCase() === 'bucuresti' &&
                selectedSubsidiary ? (
                  <p>
                    <SelectObject
                      label='Service'
                      simpleValue={selectedService || ''}
                      isDisabled={readonly}
                      options={getSortedList({
                        listToSort: removeServiceDuplicates(services) || [],
                        args: ['subsidiary'],
                      })}
                      getOptionLabel={(option: any) => option.service}
                      getOptionValue={(option: any) => option.service}
                      onChange={(e: any) => {
                        if (smsFormData.subsidiary === 'BUCURESTI') {
                          setService(e)
                          handleInputChange('branch', e)
                        } else {
                          setService('')
                        }
                      }}
                    />
                  </p>
                ) : null}

                {/* Numar de KM */}
                <div className='mb-5'>
                  <label>Număr de Kilometri</label>
                  <span className='wpcf7-form-control-wrap' data-name='model'>
                    <input
                      className='wpcf7-form-control wpcf7-text wpcf7-validates-as-required'
                      aria-required='true'
                      aria-invalid='false'
                      value={smsFormData.no_of_km}
                      onChange={(e) => validateNumericInput(e, 'no_of_km')}
                      type='text'
                      readOnly={readonly}
                    ></input>
                  </span>
                </div>

                <div>
                  <p style={{marginBottom: 30}}>
                    <label>Mesaj</label>
                    <span className='wpcf7-form-control-wrap' data-name='mesaj'>
                      <textarea
                        className='wpcf7-form-control wpcf7-textarea'
                        aria-invalid='false'
                        name='mesaj'
                        value={smsFormData.text_message}
                        onChange={(e) =>
                          handleCharactersCount(e, 'text_message', maxMessageCharacters)
                        }
                      ></textarea>
                    </span>
                    <p style={{textAlign: right, fontSize: 12}}>
                      Info caractere:{' '}
                      {smsFormData.text_message?.length ? smsFormData.text_message?.length : 0}/
                      {maxMessageCharacters}
                    </p>
                  </p>
                </div>

                <p>
                  <span className='wpcf7-form-control-wrap' data-name='date-corecte'>
                    <span
                      className='wpcf7-form-control wpcf7-checkbox wpcf7-validates-as-required'
                      id='date-corect'
                    >
                      <span className='wpcf7-list-item first last'>
                        <input
                          type='checkbox'
                          placeholder='Toate datele sunt corecte'
                          checked={smsFormData.is_client_details_correct === 1 ? true : false}
                          onChange={(e) => {
                            handleInputChange(
                              'is_client_details_correct',
                              smsFormData.is_client_details_correct === 1 ? 0 : 1
                            )
                            setIsConditionChecked(e.target.checked)
                          }}
                        ></input>
                        <span className='wpcf7-list-item-label'>Toate datele sunt corecte</span>
                      </span>
                    </span>
                  </span>
                  {!isConditionChecked && (
                    <p style={{color: 'red', fontSize: 14}}>*Acest câmp este obligatoriu</p>
                  )}
                </p>

                <p>
                  <span className='wpcf7-form-control-wrap' data-name='acord-date'>
                    <span className='wpcf7-form-control wpcf7-checkbox wpcf7-validates-as-required'>
                      <span className='wpcf7-list-item first last'>
                        <input
                          type='checkbox'
                          placeholder='Sunt de acord cu prelucrarea datelor cu caracter personal'
                          checked={smsFormData.is_data_processing_approved === 1 ? true : false}
                          onChange={(e) =>
                            handleInputChange(
                              'is_data_processing_approved',
                              smsFormData.is_data_processing_approved === 1 ? 0 : 1
                            )
                          }
                        ></input>
                        <span className='wpcf7-list-item-label'>
                          Sunt de acord cu prelucrarea datelor cu caracter personal
                        </span>
                      </span>
                    </span>
                  </span>
                </p>

                <p>
                  <span className='wpcf7-form-control-wrap' data-name='acord-mkt'>
                    <span className='wpcf7-form-control wpcf7-checkbox'>
                      <span className='wpcf7-list-item first last'>
                        <input
                          type='checkbox'
                          placeholder='Sunt de acord să primesc mesaje de informare și promovare din partea Autonom'
                          checked={smsFormData.is_promotion_approved === 1 ? true : false}
                          onChange={(e) =>
                            handleInputChange(
                              'is_promotion_approved',
                              smsFormData.is_promotion_approved === 1 ? 0 : 1
                            )
                          }
                        ></input>
                        <span className='wpcf7-list-item-label'>
                          Sunt de acord să primesc mesaje de informare și promovare din partea
                          Autonom
                        </span>
                      </span>
                    </span>
                  </span>
                </p>

                <Button
                  className='btn btn-primary btn-lg p-5 btn-success'
                  type='submit'
                  style={{width: '100%'}}
                  disabled={!isConditionChecked}
                  onClick={async (e) => {
                    // when using submit type we have to prevent the default behavior of the form in all submit cases not only
                    // if 'isConditionChecked' is true
                    e.preventDefault()
                    if (isConditionChecked) {
                      submitChanges(e, formType)
                    } else {
                      toast.error('Campul de confirmare a datelor este obligatoriu')
                    }
                  }}
                >
                  Trimite
                </Button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default FormFields

import React from 'react';

const PdfViewer = ({ pdfUrl }: { pdfUrl: string }) => {
  return (
    <div style={{ width: '80%', height: '95%' }}>
      <iframe height="100%" width="100%"  src={pdfUrl} />
    </div>
  );
}

export default PdfViewer;
import { CompanyType, emptyCompany } from "./company.types"

// SUBSCRIPTION VEHICLE TYPE

export enum VehicleCategory {
  Car = 'car',
  Suv = 'suv',
  Lcv = 'lcv',
  CargoVan = 'cargo-van',
  PassengerVan = 'passenger-van',
}
export const categoryList = Object.values(VehicleCategory).map((category: string) => ({
  name: category,
}))

export type VehicleType = {
  id: number
  red_number: boolean | number
  registration_nr: string
  vin: string
  brand: string
  model: string
  category: string
  registration_date: Date | string
  weight: number
  vehicle_age?: string
  company: CompanyType
  company_id: number
}

export const emptyVehicle: VehicleType = {
  id: 0,
  red_number: false,
  registration_nr: '',
  vin: '',
  brand: '',
  model: '',
  category: VehicleCategory.Car,
  registration_date: null,
  weight: 0,
  vehicle_age: null,
  company: emptyCompany,
  company_id: 0,
}

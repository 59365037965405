export const getSortedList = ({
  listToSort,
  args,
  caseSensitive = false,
  reversed = false,
  invertArgs = false,
  shallowCopy = false,
}: {
  listToSort: any[]
  args: string[]
  caseSensitive?: boolean
  reversed?: boolean
  invertArgs?: boolean
  shallowCopy?: boolean
}): any[] => {
  const reversedToken = Boolean(reversed) ? -1 : 1 // we need to reverse the compare operations
  if (listToSort.length === 0) {
    return [];
  }
  // revert the args to change the way it is sorted
  if (invertArgs) {
    args.reverse();
  }
  // if we want to keep the reference to the list's objects, we can make a shallow copy
  const futureList = Boolean(shallowCopy) ? [...listToSort] : JSON.parse(JSON.stringify(listToSort))
  args.forEach(arg => {
    let typeOfArg = typeof listToSort[0][arg];
    let sortFunction: Function;
    switch (typeOfArg) {
      case "string":
        let caseObj: any = {};
        if (!caseSensitive) {
          listToSort.forEach(word => caseObj[word[arg]] = word[arg]?.toLowerCase())
        }
        sortFunction = (fst: any, snd: any) => {
          let a = caseSensitive ? fst[arg] : caseObj[fst[arg]];
          let b = caseSensitive ? snd[arg] : caseObj[snd[arg]];
          return ((a > b) ? 1 : (a < b) ? -1 : 0);
        }
        break;
      case "number":
        sortFunction = (fst: any, snd: any) => fst[arg] - snd[arg]
        break;
      case "boolean":
        sortFunction = (fst: any, snd: any) => (fst[arg] === snd[arg]) ? 0 : fst[arg] ? -1 : 1
        break;
      default:
        sortFunction = (fst: any, snd: any) => {
          let a = fst[arg];
          let b = snd[arg];
          return ((a > b) ? 1 : (a < b) ? -1 : 0);
        }
        break;
    }
    const completeSortFunction = (fst: any, snd: any) => reversedToken * sortFunction(fst, snd)
    futureList.sort(completeSortFunction)
  });
  return futureList;
}
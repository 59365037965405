import {EASYTRACK_API_URL} from './../../constants/api.constants'
import easytrackAxios from '../easytrack.axios'
import {GeofenceType} from '../../types/general.types'

// Logs
export const getGeofenceListRequest = ({businessProfile}: {businessProfile?: number}) => {
  if (!businessProfile) {
    alert('businessProfile is required')
    return
  }

  var path = `/business-profiles/${businessProfile}/pricing-geofences`

  return easytrackAxios.get(`${EASYTRACK_API_URL}${path}`).catch((err) => err)
}

/**
 * Update geofence
 */
export const updateGeofenceRequest = ({
  businessProfile,
  geofence,
}: {
  businessProfile?: number
  geofence?: GeofenceType
}) => {
  if (!businessProfile) {
    alert('businessProfile is required')
    return
  }

  if (!geofence) {
    alert('geofence is required')
    return
  }

  var path = `/business-profiles/${businessProfile}/pricing-geofences/${geofence.id}`

  return easytrackAxios.put(`${EASYTRACK_API_URL}${path}`, geofence).catch((err) => err)
}

/**
 * Create geofence
 */
export const createGeofenceRequest = ({
  businessProfile,
  geofence,
}: {
  businessProfile?: number
  geofence?: GeofenceType
}) => {
  if (!businessProfile) {
    alert('businessProfile is required')
    return
  }

  if (!geofence) {
    alert('geofence is required')
    return
  }

  var path = `/business-profiles/${businessProfile}/pricing-geofences`

  return easytrackAxios.post(`${EASYTRACK_API_URL}${path}`, geofence).catch((err) => err)
}

/**
 * Remove the connections with the geofence to be deleted
 */
const removeConnectionsRequest = async ({
  geofences,
  geofenceId,
}: {
  geofences: GeofenceType[],
  geofenceId: number,
}) => {
  if(!geofenceId) return;
  let connectedGeofences = geofences.filter((geofence: GeofenceType) => geofence.connectedGeofence?.id === geofenceId);
  connectedGeofences.forEach(async (geofence: GeofenceType) => {
    geofence.connectedGeofence = null;
    await updateGeofenceRequest({businessProfile: geofence.businessProfileId, geofence: geofence});
  })
}

/**
 * Delete
 */
export const deleteGeofenceRequest = async ({
  businessProfile,
  geofence,
  geofences,
}: {
  businessProfile?: number
  geofence?: GeofenceType
  geofences?: GeofenceType[]
}) => {
  if (!businessProfile) {
    alert('businessProfile is required')
    return
  }

  if (!geofence) {
    alert('geofence is required')
    return
  }

  if (!geofences) {
    alert('the list of all geofences is required')
  }

  await removeConnectionsRequest({geofences: geofences, geofenceId: geofence.id});

  var path = `/business-profiles/${businessProfile}/pricing-geofences/${geofence.id}`

  return easytrackAxios.delete(`${EASYTRACK_API_URL}${path}`).catch((err) => err)
}

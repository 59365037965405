/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useContext, useState, useMemo} from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import '../autonom.scss'
import {TemplatesContext} from '../../../context/templates.context'
import Loading from '../../../_metronic/layout/components/loading/Loading'
import {Modal} from 'react-bootstrap'
import {TemplateType} from '../../../context/edit-template-context'
import {isTemplateUpToDate} from '../add-edit-template/utils'
import { safeJSONParseToObj } from '../../../utils/string.utils'

interface DeleteModalProps {
  deleteModalVisible: boolean
  setDeleteModalVisible: Function
  name: string
  deleteAndSetTemplates: Function
  id: number
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  deleteModalVisible,
  setDeleteModalVisible,
  name,
  deleteAndSetTemplates,
  id,
}) => {
  return (
    <Modal
      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
      id='kt_inbox_compose'
      role='dialog'
      data-backdrop='static'
      aria-hidden='true'
      tabIndex='-1'
      show={deleteModalVisible}
      animation={true}
    >
      <div className='modal-content'>
        {/* begin::Form */}
        {/*begin::Header*/}
        <div className='d-flex align-items-center justify-content-between py-3 ps-4 pe-3'>
          <h2 className='fw-bold m-0'> </h2>
          <div className='d-flex ms-2'>
            {/*begin::Close*/}
            <div
              className='btn btn-icon btn-sm btn-secondary ms-2'
              data-bs-dismiss='modal'
              onClick={() => setDeleteModalVisible(false)}
            >
              <KTSVG className='svg-icon-1' path='/media/icons/duotone/Navigation/Close.svg' />
            </div>
            {/*end::Close*/}
          </div>
        </div>
        {/*end::Header*/}

        {/*begin::Body*/}
        <div className='d-block py-5 ps-8 pe-5 text-center'>
          {/* end::Form group */}

          <h2>Are you sure?</h2>
          <p>
            The template <strong>{name}</strong>, will be deleted forever!
          </p>
        </div>
        {/*end::Body*/}

        {/*begin::Footer*/}
        <div className='d-flex align-items-center justify-content-center py-5 ps-8 pe-5 border-top'>
          {/*begin::Actions*/}
          <div className='d-flex align-items-center me-3'></div>
          {/*end::Actions*/}

          {/*begin::Toolbar*/}
          <div className='mt-2' style={{display: 'flex', justifyContent: 'center'}}>
            <div
              className='btn btn-secondary me-5'
              data-bs-dismiss='modal'
              onClick={() => setDeleteModalVisible(false)}
            >
              Cancel
            </div>
            <div>
              <button
                onClick={() => {
                  deleteAndSetTemplates(id)
                  setDeleteModalVisible(false)
                }}
                className='btn btn-danger me-5'
              >
                Delete
              </button>
            </div>
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Footer*/}
        {/*end::Form*/}
      </div>
    </Modal>
  )
}

const StatusLabel = ({upToDate}: {upToDate: Boolean}) => {
  return (
    <span style={{width: '100px'}} className={`badge ${upToDate ? 'badge-success' : 'badge-warning'}`}>
      {upToDate ? 'UpToDate' : 'Outdated'}
    </span>
  )
}

interface TemplateProps {
  deleteAndSetTemplates: Function
  index: number
  id: number
  templateObject: TemplateType
  name: string,
}

const TemplateRow: React.FC<TemplateProps> = ({
  deleteAndSetTemplates,
  index,
  id,
  templateObject,
  name,
}) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
  const upToDate: Boolean = useMemo(() => isTemplateUpToDate(templateObject), [])

  return (
    <>
      {deleteModalVisible && (
        <DeleteModal
          deleteModalVisible={deleteModalVisible}
          setDeleteModalVisible={setDeleteModalVisible}
          name={name}
          deleteAndSetTemplates={deleteAndSetTemplates}
          id={id}
        />
      )}
      <tr key={id}>
        <td></td>
        <td>{index + 1}</td>
        <td>{name}</td>
        <td>
          <StatusLabel upToDate={upToDate} />
        </td>
        <td className='text-end'>
          <Link
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            to={`add-edit-template/${id}`}
          >
            <KTSVG path='/media/icons/duotone/Communication/Write.svg' className='svg-icon-3' />
          </Link>
          <button
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={() => {
              setDeleteModalVisible(true)
            }}
          >
            <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
          </button>
        </td>
      </tr>
    </>
  )
}

const TemplatesTable: FC = () => {
  const {getTemplates, deleteTemplate, templates, setTemplates} = useContext<any>(TemplatesContext)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const templateObjects: TemplateType[] = useMemo(
    () => templates.map((template: any) => safeJSONParseToObj(template?.content)),
    [templates]
  )

  const getAndSetTemplates = async () => {
    setIsLoading(true)
    const result = await getTemplates()
    setTemplates(result.data)
    setIsLoading(false)
  }

  const deleteAndSetTemplates = async (id: number) => {
    setIsLoading(true)
    await deleteTemplate(id)
    setIsLoading(false)
  }

  useEffect(() => {
    getAndSetTemplates()
  }, [])

  return (
    <>
      <div className='page__top-buttons'>
        <Link className='btn btn-primary item-right' to={'add-edit-template/'}>
          New Template
        </Link>
      </div>
      <div className={`card mb-7`}>
        {/* begin::Header */}
        <div className='card-header pt-5 border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Templates</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-300 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th></th>
                  <th>No.</th>
                  <th className='min-w-140px'>Name</th>
                  <th style={{ width: '110px' }}>Status</th>
                  <th className='min-w-100px text-end'>Actions</th>
                  <th></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody
                style={{
                  display: isLoading ? 'table-caption' : 'table-row-group',
                }}
              >
                {isLoading ? (
                  <tr
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      flex: 1,
                    }}
                  >
                    <td>
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  templates.map((template: any, index: number) => (
                    <TemplateRow
                      deleteAndSetTemplates={deleteAndSetTemplates}
                      key={template.name}
                      index={index}
                      id={template.id}
                      templateObject={templateObjects[index]}
                      name={template.name}
                    />
                  ))
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

const TemplatePage: FC = () => {
  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 g-xl-12'>
        {/* begin::Col */}
        <div className='col-xxl-12'>
          <TemplatesTable />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
    </>
  )
}

const TemplatesWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>Templates</PageTitle>
      <TemplatePage />
    </>
  )
}

export default TemplatesWrapper

import React, {FC, useContext, useEffect, useMemo, useState} from 'react'
import {toast} from 'react-toastify'
import Input from '../../../components/input/input.component'
import {EASYTRACK_API_URL} from '../../../constants/api.constants'
import {useGeofence} from '../../../context/geofence.context'
import {UserContext} from '../../../context/user.context'
import {
  createGeofenceRequest,
  deleteGeofenceRequest,
  updateGeofenceRequest,
} from '../../../setup/axios/geofence.request'
import easytrackAxios from '../../../setup/easytrack.axios'
import {GeofenceType} from '../../../types/general.types'
import {isSameGeofence} from '../../../utils/geolocation.utils'
import {KTSVG} from '../../../_metronic/helpers'
import ModalPortal from '../../../_metronic/layout/components/modal/modal-portal'
import Select from '../../../_metronic/layout/components/select/Select'
import {defaultColor, SHAPE_COLORS} from '../GeofenceList'

const Table: FC<{
  geofences: any[]
  isSelected: Function
  setSelectedGeofence: Function
  switchGeofenceVisibility: Function
  geofenceTypes: any[]
  isLoading: boolean
  setToBeDeleted: Function
}> = ({
  geofences,
  isSelected,
  setSelectedGeofence,
  switchGeofenceVisibility,
  geofenceTypes,
  isLoading,
  setToBeDeleted,
}) => {
  const sortedGeofences = useMemo(
    () =>
      geofences.sort((fst: GeofenceType, snd: GeofenceType) =>
        (fst.name || '').localeCompare(snd.name || '')
      ),
    [geofences]
  )
  return (
    <div
      style={{
        boxShadow: '0 0 70px -40px #0000001f',
        padding: 16,
        borderRadius: 8,
        backgroundColor: 'white',
        maxHeight: '430px',
        overflow: 'auto',
      }}
    >
      <div className='table-responsive'>
        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
          <thead>
            <tr className='fw-bolder'>
              <th className='p-0 w-25px'></th>
              <th className='ps-1 w-25px'>Id</th>
              <th className='ps-1'>Name</th>
              <th>Type</th>
              <th>Connection</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sortedGeofences.map((geofence) => {
              const geofenceId: string = geofence.id ? geofence.id : geofence.localUuId
              return (
                <tr
                  id={'geofence-' + geofenceId}
                  className='m-2'
                  key={geofenceId}
                  style={{
                    backgroundColor: isSelected(geofence) ? '#f5f5f5' : 'white',
                  }}
                >
                  <td>
                    <div
                      className='ms-2'
                      style={{
                        display: 'flex',
                        backgroundColor: geofence.color,
                        width: '20px',
                        height: '20px',
                        borderRadius: '5px',
                      }}
                    />
                  </td>
                  <td className='ps-1'>{geofence.id || '-'}</td>
                  <td
                    className='ps-1 clickable'
                    onClick={() => {
                      setSelectedGeofence(geofence)
                    }}
                  >
                    <span>{geofence.name}</span>
                  </td>
                  <td>{geofenceTypes.find((x: any) => x.name == geofence.type)?.title || ''}</td>
                  <td
                    className={Boolean(geofence.connectedGeofence) ? 'clickable' : ''}
                    onClick={() => {
                      if (!Boolean(geofence.connectedGeofence)) return
                      setSelectedGeofence(geofence.connectedGeofence)
                    }}
                  >
                    {geofence.connectedGeofence ? (
                      <span>
                        <span className='fw-bold'>{geofence.connectedGeofence.name}</span>
                      </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    <button
                      disabled={isLoading}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                      onClick={() => switchGeofenceVisibility(geofence)}
                    >
                      {isLoading ? (
                        <i className='fa fa-circle-o-notch fa-spin' />
                      ) : (
                        <KTSVG
                          path={`/media/icons/duotone/General/${
                            !geofence.hidden ? 'Visible' : 'Hidden'
                          }.svg`}
                          className='svg-icon-3'
                        />
                      )}
                    </button>

                    <button
                      disabled={isLoading}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      onClick={() => setToBeDeleted(geofence)}
                    >
                      {isLoading ? (
                        <i className='fa fa-circle-o-notch fa-spin' />
                      ) : (
                        <KTSVG
                          path='/media/icons/duotone/General/Trash.svg'
                          className='svg-icon-3'
                        />
                      )}
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const GeofencesTable = () => {
  const {businessProfileId} = useContext<any>(UserContext)
  const {selectedGeofence, setSelectedGeofence, geofences, setGeofences} = useGeofence()
  const [originalGeofence, setOriginalGeofence] = useState<GeofenceType>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [selectedColor, setSelectedColor] = React.useState<string>(defaultColor)
  const [geofenceTypes, setGeofenceTypes] = React.useState<any[]>([])
  const [modalVisible, setModalVisible] = React.useState<boolean>(false)
  const [toBeDeleted, setToBeDeleted] = React.useState<GeofenceType>(null)
  const [filterName, setFilterName] = useState<string>('')

  useEffect(() => {
    ;(async () => {
      const result = await easytrackAxios
        .get(`${EASYTRACK_API_URL}/utils/dictionaries`)
        .catch((err) => err)
      if (result.data) {
        setGeofenceTypes(result.data.PRICING_GEOFENCE_TYPES || [])
      }
    })()
  }, [])

  useEffect(() => {
    if (selectedGeofence) {
      setSelectedColor(selectedGeofence.color || defaultColor)
      const geofenceId = selectedGeofence.id ? selectedGeofence.id : selectedGeofence.localUuId
      let selectedElement = document.getElementById('geofence-' + geofenceId)
      if (!selectedElement) {
        return
      }
      selectedElement.scrollIntoView()
    }
  }, [selectedGeofence])

  useEffect(() => {
    setOriginalGeofence(selectedGeofence)
  }, [selectedGeofence?.id, selectedGeofence?.localUuId])

  const updateGeofence = async () => {
    setIsLoading(true)

    const localGeofence = {...selectedGeofence}

    // Close polygon
    if (
      JSON.stringify(localGeofence.polygon.at(-1)) !== JSON.stringify(localGeofence.polygon.at(0))
    ) {
      localGeofence.polygon.push(localGeofence.polygon.at(0))
    }

    const result = await updateGeofenceRequest({
      businessProfile: businessProfileId,
      geofence: localGeofence,
    })

    setIsLoading(false)

    if (result.status !== 200) {
      toast.error(result.data.message || 'We have some error in your last request!')
    }

    toast.success('Geofence updated!')
  }

  const createGeofence = async () => {
    setIsLoading(true)
    const localGeofence = {...selectedGeofence}

    // Close polygon
    if (
      JSON.stringify(localGeofence.polygon.at(-1)) !== JSON.stringify(localGeofence.polygon.at(0))
    ) {
      localGeofence.polygon.push(localGeofence.polygon.at(0))
    }

    const result = await createGeofenceRequest({
      businessProfile: businessProfileId,
      geofence: localGeofence,
    })
    setIsLoading(false)

    if (result.status !== 200) {
      return toast.error(result.data.message || 'We have some error in your last request!')
    }

    let futureGeofences = [...geofences]

    futureGeofences = futureGeofences.map((e: GeofenceType) => {
      if (e.localUuId && e.localUuId === localGeofence.localUuId) {
        e.id = result.data.id
      }
      return e
    })

    setGeofences(futureGeofences)
    setSelectedGeofence(null)
    toast.success('Geofence created!')
  }

  const undoChanges = () => {
    const futureGeofences = JSON.parse(JSON.stringify(geofences))
    const futureGeofence = futureGeofences.map((e) => {
      if (e.id && e.id === selectedGeofence.id) {
        e = {...originalGeofence}
      }

      if (e.localUuId && e.localUuId === selectedGeofence.localUuId) {
        e = {...originalGeofence}
      }

      return e
    })

    setGeofences(futureGeofence)
    setSelectedGeofence({...originalGeofence})
  }

  const deleteGeofence = async (geofence: GeofenceType) => {
    const geofenceToBeDeleted = Boolean(geofence) ? geofence : selectedGeofence
    setIsLoading(true)
    const result = await deleteGeofenceRequest({
      businessProfile: businessProfileId,
      geofence: geofenceToBeDeleted,
      geofences: geofences,
    })
    setIsLoading(false)
    let status = result?.status || result?.response?.status
    if (status !== 200) {
      let message = result?.response?.message || result?.response?.data?.message || result?.message
      if (message.includes('fk_pricing_tariff_start_geofence_id_to_pricing_geofence_id')) {
        return toast.error('This geofence is present in a pricing tariff and cannot be deleted!')
      }
      return toast.error('We have some error in your last request!\n' + message)
    }

    setGeofences(geofences.filter((geofence) => geofence.id !== geofenceToBeDeleted.id))
    setSelectedGeofence(null)
    toast.success('Geofence deleted!')
  }

  const removeGeofence = (geofence: GeofenceType) => {
    const geofenceToBeRemoved = Boolean(geofence) ? geofence : selectedGeofence
    setGeofences(
      geofences.filter((geofence) => geofence.localUuId !== geofenceToBeRemoved.localUuId)
    )
    setSelectedGeofence(null)
    toast.success('Geofence removed!')
  }

  const deleteOrRemoveGeofence = (geofence: GeofenceType) => {
    if (geofence?.id) {
      deleteGeofence(geofence)
    } else {
      removeGeofence(geofence)
    }
  }

  const isSelected = (paramGeofence: any) => {
    if (!selectedGeofence) {
      return false
    }

    if (paramGeofence.localUuId || selectedGeofence.localUuId) {
      return selectedGeofence.localUuId === paramGeofence.localUuId
    }

    return selectedGeofence.id === paramGeofence.id
  }

  const DeleteModal = () => {
    return (
      <div className='py-5 px-8' style={{display: 'flex', flexDirection: 'column'}}>
        <span style={{textAlign: 'center', fontSize: '16px'}}>
          Are you sure you want to delete geofence "{toBeDeleted.name}" with id{' '}
          {toBeDeleted.id || toBeDeleted.localUuId}?
        </span>
        <div className='mt-8' style={{display: 'flex', justifyContent: 'center'}}>
          <div
            className='btn btn-secondary me-5'
            onClick={() => {
              setToBeDeleted(null)
              setModalVisible(false)
            }}
          >
            Cancel
          </div>
          <div
            className='btn btn-danger'
            onClick={async () => {
              deleteOrRemoveGeofence(toBeDeleted)
              setToBeDeleted(null)
              setModalVisible(false)
            }}
          >
            Delete
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    setModalVisible(Boolean(toBeDeleted))
  }, [Boolean(toBeDeleted)])

  const switchGeofenceVisibility = (geofence: GeofenceType) => {
    let index = geofences.findIndex((elem: GeofenceType) =>
      isSameGeofence({geofenceA: geofence, geofenceB: elem})
    )
    if (index === -1) {
      return
    }

    let futureGeofences = [...geofences]
    const elem = geofences[index]
    futureGeofences[index] = {...elem, hidden: !elem.hidden}
    setGeofences(futureGeofences)
  }

  return (
    <div
      style={{
        display: 'flex',
        marginTop: 16,
      }}
    >
      {modalVisible && Boolean(toBeDeleted) ? (
        <ModalPortal setVisible={setModalVisible} visible={modalVisible} hasExitButton={false}>
          {DeleteModal()}
        </ModalPortal>
      ) : null}
      <div
        style={{
          flex: 1,
          marginRight: selectedGeofence ? 16 : 0,
        }}
      >
        <input
          placeholder='Find a geofence...'
          className='form-control mb-1'
          value={filterName}
          onChange={(e: any) => setFilterName(e.target.value)}
        />
        <Table
          geofences={geofences.filter((geofence: GeofenceType) =>
            filterName ? geofence.name.toLowerCase().includes(filterName.toLowerCase()) : true
          )}
          isSelected={isSelected}
          switchGeofenceVisibility={switchGeofenceVisibility}
          setSelectedGeofence={setSelectedGeofence}
          geofenceTypes={geofenceTypes}
          isLoading={isLoading}
          setToBeDeleted={setToBeDeleted}
        />
      </div>

      {/* Right */}
      {selectedGeofence ? (
        <div
          style={{
            backgroundColor: 'white',
            padding: 16,
            boxShadow: '0 0 70px -40px #0000001f',
            minWidth: '500px',
            borderRadius: 8,
          }}
        >
          <div style={{display: 'flex', justifyContent: 'space-between'}} className='mb-2'>
            <span style={{position: 'relative', fontWeight: '600', fontSize: '22px'}}>
              {selectedGeofence.id ? 'Update Geofence' : 'Create Geofence'}
            </span>
            <span
              onClick={() => {
                setSelectedGeofence(null)
              }}
            >
              <KTSVG
                path='/media/icons/duotone/Interface/Close-Square.svg'
                className='svg-icon-2tx svg-icon-danger clickable'
              />
            </span>
          </div>
          <Input
            label='Name'
            value={selectedGeofence.name}
            onChange={(newTextValue) => {
              const futureGeofences = JSON.parse(JSON.stringify(geofences))
              setSelectedGeofence({
                ...selectedGeofence,
                name: newTextValue.target.value,
              })
              const futureGeofence = futureGeofences.map((e) => {
                if (e.id && e.id === selectedGeofence.id) {
                  e.name = newTextValue.target.value
                }

                if (e.localUuId && e.localUuId === selectedGeofence.localUuId) {
                  e.name = newTextValue.target.value
                }

                return e
              })

              setGeofences(futureGeofence)
            }}
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 16,
              marginBottom: 16,
            }}
          >
            {SHAPE_COLORS.map((color) => {
              return (
                <div
                  key={color}
                  className='clickable'
                  onClick={() => {
                    const futureGeofences = JSON.parse(JSON.stringify(geofences))
                    setSelectedGeofence({
                      ...selectedGeofence,
                      color: color,
                    })
                    const futureGeofence = futureGeofences.map((e) => {
                      if (e.id && e.id === selectedGeofence.id) {
                        e.color = color
                      }

                      if (e.localUuId && e.localUuId === selectedGeofence.localUuId) {
                        e.color = color
                      }

                      return e
                    })

                    setGeofences(futureGeofence)
                    setSelectedColor(color)
                  }}
                  style={{
                    flex: 1,
                    height: '32px',
                    margin: '0px 8px',
                    borderRadius: '4px',
                    backgroundColor: color,
                    color: '#fff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {color === selectedColor ? '✓' : ''}
                </div>
              )
            })}
          </div>
          <Select
            label='Geofence Type'
            onChange={(optionsInstance: any) => {
              const futureGeofences = JSON.parse(JSON.stringify(geofences))
              setSelectedGeofence({
                ...selectedGeofence,
                type: optionsInstance.name,
              })
              const futureGeofence = futureGeofences.map((e) => {
                if (e.id && e.id === selectedGeofence.id) {
                  e.type = optionsInstance.name
                }

                if (e.localUuId && e.localUuId === selectedGeofence.localUuId) {
                  e.type = optionsInstance.name
                }

                return e
              })

              setGeofences(futureGeofence)
            }}
            value={geofenceTypes.find((x: any) => x.name == selectedGeofence.type) || ''}
            options={geofenceTypes}
            getOptionLabel={(option: any) => option.title}
            getOptionValue={(option: any) => option.name}
          />

          <Select
            className='pt-2'
            label='Connected Geofence'
            isClearable={true}
            onChange={(optionValue: any) => {
              const futureGeofences = JSON.parse(JSON.stringify(geofences))
              setSelectedGeofence({
                ...selectedGeofence,
                connectedGeofence: optionValue,
              })
              const futureGeofence = futureGeofences.map((e) => {
                if (e.id && e.id === selectedGeofence.id) {
                  e.connectedGeofence = optionValue
                }

                if (e.localUuId && e.localUuId === selectedGeofence.localUuId) {
                  e.connectedGeofence = optionValue
                }

                return e
              })

              setGeofences(futureGeofence)
            }}
            value={selectedGeofence.connectedGeofence}
            options={geofences.filter(
              (geofence: any) =>
                geofence.hasOwnProperty('id') && geofence.id !== selectedGeofence.id
            )}
            getOptionLabel={(geofence: any) => geofence.id + ': ' + geofence.name}
            getOptionValue={(geofence: any) => geofence}
          />
          <div style={{float: 'right'}}>
            <button
              className='btn btn-info m-4'
              disabled={isLoading}
              onClick={() => {
                if (selectedGeofence.id) {
                  updateGeofence()
                } else {
                  createGeofence()
                }
              }}
            >
              {isLoading ? <i className='fa fa-circle-o-notch fa-spin' /> : null}
              {selectedGeofence.id ? 'Save' : 'Create'}
            </button>
            {/* Revert button */}
            <button
              disabled={isLoading}
              className='btn btn-danger'
              onClick={() => {
                // Undo modifications done to selectedGoefence (replace it in the list via id or localUuid)
                undoChanges()
              }}
            >
              {isLoading ? <i className='fa fa-circle-o-notch fa-spin' /> : null}
              Undo
            </button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default GeofencesTable

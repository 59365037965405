import React, { ChangeEvent, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../../context/user.context';
import { HandleImportPayload, handleImport, statusMessage } from '../utils/importTransportsUtils';
import { FlexibleObject, StateSetter } from '../../../../../utils/state.utils';
import { AppContext } from '../../../../../context/app.context';

const ModalContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className='d-flex flex-column' style={{ width: '90%' }}>
      {children}
    </div>
  );
};

const ModalHeader = ({ children }: { children: ReactNode }) => {
  return (
    <div className='modal-header' style={{ justifyContent: 'center' }}>
      {children}
    </div>
  );
};

const Title = () => {
  return <p className='fs-2 modal-title'>Load transports from an excel file</p>;
};

const ModalBody = ({ children }: { children: ReactNode }) => {
  return <div className='d-flex flex-column modal-body'>{children}</div>;
};

const ImportFileField = ({
  isLoading,
  setTransportsListFile,
  setImportStatusMessage,
}: {
  isLoading: boolean;
  setTransportsListFile: StateSetter<File | null>;
  setImportStatusMessage: StateSetter<string>;
}) => {
  return (
    <div>
      <label className='mt-4 py-1 fw-bolder'>Excel File:</label>
      <input
        id='transportsListFile'
        type='file'
        accept='.xlsx'
        style={{ width: '100%' }}
        disabled={isLoading}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const files = e.target.files || [];
          const futureFile: File = files[0];
          setTransportsListFile(futureFile);
          if (!futureFile) {
            setImportStatusMessage(statusMessage.fileRemoved);
          } else {
            setImportStatusMessage(statusMessage.fileLoaded);
          }
        }}
      />
    </div>
  );
};

const DisplayImportStatusField = ({ importStatusMessage }: { importStatusMessage: string }) => {
  return (
    <div>
      <label className='mt-4 py-1 fw-bolder'>Import Status:</label>
      <textarea
        className='form-control form-control-sm'
        style={{ minHeight: '75px', maxHeight: '225px', backgroundColor: '#f5f8fa' }}
        value={importStatusMessage}
        readOnly={true}
      />
    </div>
  );
};

const ModalFooter = ({ children }: { children: ReactNode }) => {
  return <div className='modal-footer d-flex justify-content-center'>{children}</div>;
};

const CancelButton = ({
  isLoading,
  subsHaveBeenImported,
  setTriggerTransportUpdate,
  setModalVisible,
}: {
  isLoading: boolean;
  subsHaveBeenImported: boolean;
  setTriggerTransportUpdate: StateSetter<boolean>;
  setModalVisible: StateSetter<boolean>;
}) => {
  return (
    <button
      disabled={isLoading}
      style={{ width: '140px' }}
      className='btn btn-danger me-5'
      onClick={() => {
        if (subsHaveBeenImported) {
          setTriggerTransportUpdate(true);
        }
        setModalVisible(false);
      }}
    >
      Cancel
    </button>
  );
};

const Spinner = () => <span className='spinner-border spinner-border-sm align-middle ms-2'></span>;

const SendButton = ({
  importPayload,
  isLoading,
}: {
  importPayload: HandleImportPayload;
  isLoading: boolean;
}) => {
  return (
    <button
      disabled={!importPayload?.transportsListFile || isLoading}
      style={{ width: '140px' }}
      className='btn btn-primary'
      onClick={() => handleImport(importPayload)}
    >
      {isLoading ? (
        <span>
          Process <Spinner />
        </span>
      ) : (
        <span>Send</span>
      )}
    </button>
  );
};

const getCompaniesAndUpdateState = async ({
  getCompaniesRequest,
  setCompaniesSet,
  setIsLoading,
}: {
  getCompaniesRequest: () => Promise<FlexibleObject>;
  setCompaniesSet: StateSetter<Set<string>>;
  setIsLoading: StateSetter<boolean>;
}) => {
  setIsLoading(true);
  const result = await getCompaniesRequest();
  if (Boolean(result?.data) && Array.isArray(result?.data)) {
    const companiesList: FlexibleObject[] = result?.data || [];
    const futureCompaniesSet = companiesList.reduce<Set<string>>((companiesSet, company) => {
      companiesSet.add((company?.name as string) || '');
      return companiesSet;
    }, new Set<string>());
    setCompaniesSet(futureCompaniesSet);
  }
  setIsLoading(false);
};

const ImportTransportsModal = ({
  setModalVisible,
  setTriggerTransportUpdate,
}: {
  setModalVisible: StateSetter<boolean>;
  setTriggerTransportUpdate: StateSetter<boolean>;
}) => {
  const [transportsListFile, setTransportsListFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [importStatusMessage, setImportStatusMessage] = useState<string>(statusMessage.initial);
  const [subsHaveBeenImported, setSubsHaveBeenImported] = useState<boolean>(false);
  const [companiesSet, setCompaniesSet] = useState<Set<string>>(null);
  const { businessProfileId } = useContext<FlexibleObject>(UserContext);
  const { getPromiseByKey } = useContext<FlexibleObject>(AppContext);
  const getCompaniesRequest = useCallback(() => {
    return getPromiseByKey('companies', { businessProfileId });
  }, [getPromiseByKey, businessProfileId]);

  useEffect(() => {
    getCompaniesAndUpdateState({ getCompaniesRequest, setCompaniesSet, setIsLoading });
  }, []);

  const importPayload: HandleImportPayload = {
    businessProfileId,
    transportsListFile,
    setTransportsListFile,
    setIsLoading,
    setImportStatusMessage,
    setModalVisible,
    setTriggerTransportUpdate,
    setSubsHaveBeenImported,
    companiesSet,
  };

  return (
    <ModalContainer>
      <ModalHeader>
        <Title />
      </ModalHeader>

      <ModalBody>
        <ImportFileField
          isLoading={isLoading}
          setTransportsListFile={setTransportsListFile}
          setImportStatusMessage={setImportStatusMessage}
        />
        <DisplayImportStatusField importStatusMessage={importStatusMessage} />
      </ModalBody>

      <ModalFooter>
        <CancelButton
          isLoading={isLoading}
          subsHaveBeenImported={subsHaveBeenImported}
          setTriggerTransportUpdate={setTriggerTransportUpdate}
          setModalVisible={setModalVisible}
        />
        <SendButton importPayload={importPayload} isLoading={isLoading} />
      </ModalFooter>
    </ModalContainer>
  );
};

export default ImportTransportsModal;

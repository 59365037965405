import moment from 'moment'
import React, {useState} from 'react'
import {Calendar, DateObject} from 'react-multi-date-picker'
import DatePanel from 'react-multi-date-picker/plugins/date_panel'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'
import { EditableLabel } from '../../../../../app/modules/add-edit-template/components/fields-generator'
import ModalPortal from '../../modal/modal-portal'

const tomorrow = moment().add(1, 'day').toDate()
export default function RepeatRequestField({
  field,
  repeatOnDates = [],
  setRepeatOnDates,
  index,
  length,
  currentDate,
  onChangeLabel
}: any) {
  const [visibleModal, setVisibleModal] = useState<boolean>(false)

  const getRepeatFieldMessage = (): string => {
    let n = repeatOnDates.length
    if (n === 0) {
      return 'Never'
    }
    if (n === 1) {
      return `On ${repeatOnDates[0]}`
    }

    return `On ${n} dates`
  }

  return (
    <div
      className={index !== undefined && length !== undefined && index !== length - 1 ? ' mb-4' : ''}
    >
      <div>

        <EditableLabel label={field?.label} onChange={onChangeLabel} isRequired={field.required} />
      </div>

      <ModalPortal visible={visibleModal} setVisible={setVisibleModal}>
        <Calendar
          shadow={false}
          multiple
          currentDate={currentDate ? new DateObject(currentDate) : null}
          format='DD/MM/YYYY HH:mm'
          plugins={[
            <DatePanel markFocused sort='date' />,
            <TimePicker hideSeconds format='DD/MM/YYYY HH:mm' position='bottom' />,
          ]}
          className='rmdp-mobile'
          value={repeatOnDates}
          minDate={tomorrow}
          onChange={(e: any) => {
            setRepeatOnDates(e)
          }}
        />
      </ModalPortal>
      <button
        type='button'
        onClick={() => {
          setVisibleModal(true)
        }}
        style={{border: 'none', backgroundColor: 'white', color: '#009ef7', paddingLeft: 0}}
      >
        {getRepeatFieldMessage()}
      </button>
    </div>
  )
}

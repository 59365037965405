import React, { useContext, useEffect, useState } from 'react'
import { DateRangePicker } from '../transport-requests/FilterContainer'
import { DateObject } from 'react-multi-date-picker'
import Select from '../../../_metronic/layout/components/select/Select'
import { CompanyType } from '../../../types/company.types'
import { TariffPlanType } from '../../../pages/add-edit-plan/modules/PlanTypes'
import { getSortedList } from '../../../utils/sort.utils'
import { CompanyContext } from '../../../context/company.context'
import { LoadingCard } from '../../../_metronic/layout/components/loading/Loading'
import { getPlans } from '../rent-plans/modules/RentPlansRequests'
import { getRentPlanDislocationCosts, getRentPlanExtraCosts, getRentPlanInsurancesComplete, getRentPlanInterval, getRentPlanVehicleClass } from '../../../setup/axios/rent.request'
import { RentPlanVehicleClassModel } from '../rent-plans/models/RentPlanVehicleClassModel'
import { RentPlanExtraCostsModel } from '../rent-plans/models/RentPlanExtraCostsModel'
import { RentPlanDislocationCostsModel } from '../rent-plans/models/RentPlanDislocationCostsModel'
import { RentPlanCompleteInsuranceModel, RentPlanInsuranceModel } from '../rent-plans/models/RentPlanInsuranceModel'
import { formatNumber } from '../../../utils/string.utils'
import { InputComponentGroup } from '../../../components/field/field-generator'
import { TextareaComponent } from '../../../_metronic/layout/components/fields/textarea/textarea'
import { RequestMethod, orbitRequest } from '../../../utils/api.utils'
import BreakdownCardWrapper from '../view-transport/modules/BreakdownCard'
import { emptyTariffInfo } from '../../../utils/pricing.utils'
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import './rent-pricing.css';
import moment from 'moment'
import { KTSVG } from '../../../_metronic/helpers'
import { useParams } from 'react-router'
import { UserContext, UserRole } from '../../../context/user.context'

//Liability Cost and Liability Type labels become Insurance Cost and Insurance Type
export const insuranceTypeList = [
  {
    value: 'Franchise Fixed Cost',
    label: 'Franchise Fixed Cost',
  },
  {
    value: 'Liability Cost',
    label: 'Liability Cost',
  },
]

const PlanPricingOptions = ({ selectedPlanId, selectedPlanCurrency, timeInterval, daysCount }: { selectedPlanId: number, selectedPlanCurrency: string, timeInterval: DateObject[], daysCount: any }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [vehicleClasses, setVehicleClasses] = useState<RentPlanVehicleClassModel[]>([])
  const [selectedVehicleClassId, setSelectedVehicleClassId] = useState<number>(0)
  const [extraCosts, setExtraCosts] = useState<RentPlanExtraCostsModel[]>([])
  // const [selectedExtraCosts, setSelectedExtraCosts] = useState<{id: number, quantity: number}[]>([])
  const [selectedExtraCosts, setSelectedExtraCosts] = useState<number[]>([])
  const [dislocationCosts, setDislocationCosts] = useState<RentPlanDislocationCostsModel[]>([])
  const [selectedDislocationId, setSelectedDislocationId] = useState<number>(0)
  const [insurances, setInsurances] = useState<RentPlanCompleteInsuranceModel[]>([])
  const [selectedInsuranceId, setSelectedInsuranceId] = useState<number>(0)
  const [discount, setDiscount] = useState<string>('0')
  const [pricingDetails, setPricingDetails] = useState<string>('')
  const [pricingBreakdown, setPricingBreakdown] = useState<any>(null)
  const [selectedLiabilityType, setSelectedLiabilityType] = useState<string[]>([])
  const [pickupTime, setPickupTime] = useState(moment())
  const [deliveryTime, setDeliveryTime] = useState(moment())
  const [openPickupTime, setOpenPickUpTime] = useState(false)
  const [openDeliveryTime, setOpenDeliveryTime] = useState(false)

  const disabledHours = (pickupTime) => {
    const hours: number[] = []
    const pickupDay = timeInterval[0] !== undefined ? timeInterval[0].format('DD.MM.YYYY') : null
    const deliveryDay = timeInterval[1] !== undefined ? timeInterval[1].format('DD.MM.YYYY') : null

    if (pickupDay !== null && deliveryDay !== null && (pickupDay.localeCompare(deliveryDay) === 0)) {
      for (let i = 0; i < 24; i++) {
        if (i < pickupTime.hour()) {
          hours.push(i)
        }
      }
    }
    return hours
  }

  useEffect(() => {
    Promise.allSettled([
      (async () => {
        const futureVehicleClasses = await getRentPlanVehicleClass(selectedPlanId)
        setVehicleClasses(futureVehicleClasses || [])
      })(),
      (async () => {
        const futureExtraCosts = await getRentPlanExtraCosts(selectedPlanId)
        setExtraCosts(futureExtraCosts || [])
      })(),
      (async () => {
        const futureDislocationCosts = await getRentPlanDislocationCosts(selectedPlanId)
        setDislocationCosts(futureDislocationCosts || [])
      })(),
      (async () => {
        const futurePlanInsurance = await getRentPlanInsurancesComplete(selectedPlanId)
        setInsurances(futurePlanInsurance || [])
      })(),
    ]).then(() => {
      setIsLoading(false)
    })
  }, [selectedPlanId])

  //set delivery time to 1 hour after pickup time by default and not allow selecting a delivery time before pickup time 

  useEffect(() => {
    if (pickupTime) {
      setDeliveryTime(moment(pickupTime).add(1, 'hour'))
    }
  }, [pickupTime])

  const calculateRentPricing = async (setPricingDetails, setPricingBreakdown, selectedVehicleClassId, selectedExtraCosts, selectedDislocationId, selectedInsuranceId, pickupTime, deliveryTime, discount, timeInterval: DateObject[]) => {
    const convertedExtraOptions = selectedExtraCosts.map((id: number) => ({ id, quantity: 1 }))
    const convertedFilteredExtraOptions = extraCosts.filter(extra_costs => extra_costs.is_applicable === 'per week day').map((extra_cost: RentPlanExtraCostsModel) => ({ id: extra_cost.id, quantity: 1 }))
    const payload = {
      "plan_id": selectedPlanId,
      "vehicle_class_id": selectedVehicleClassId,
      "start_date": timeInterval[0]?.format('DD.MM.YYYY'),
      "end_date": timeInterval[1]?.format('DD.MM.YYYY'),
      "insurance_id": selectedInsuranceId,
      "extra_costs": convertedExtraOptions,
      "filtered_extra_costs": convertedFilteredExtraOptions,
      "discount": parseInt(discount) / 100,
      "dislocation_id": selectedDislocationId,
      "liability_type": selectedLiabilityType,
      "pickup_time": pickupTime.format('HH:mm'),
      "delivery_time": deliveryTime.format('HH:mm'),
      "days_count": daysCount
    }

    const result = await orbitRequest({ route: '/rent-total-cost/get-initial-cost', method: RequestMethod.POST, payload })
    setPricingDetails(result?.pricing?.tariffDetails?.value
      ? `price: ${result?.pricing?.tariffDetails?.value}`
      : `Something went wrong! ${result?.error ? result?.message : ''}`
    )
    setPricingBreakdown(result?.pricing?.taxBreakdown)
  }

  useEffect(() => {
    if (selectedVehicleClassId && selectedExtraCosts && selectedDislocationId && selectedInsuranceId && selectedLiabilityType.length) {
      calculateRentPricing(setPricingDetails, setPricingBreakdown, selectedVehicleClassId, selectedExtraCosts, selectedDislocationId, selectedInsuranceId, pickupTime, deliveryTime, discount, timeInterval)
    }
  }, [selectedVehicleClassId, selectedExtraCosts, selectedDislocationId, selectedInsuranceId, discount, timeInterval, selectedLiabilityType.length, pickupTime, deliveryTime])

  if (isLoading) {
    return null;
  }

  return (
    <div>
      <div className='row py-3'>
        <div className='col' style={{ marginTop: 10 }}>
          <label
            htmlFor={'Pick up time'}
            className='form-label'
            style={{ marginBottom: 15, fontWeight: '600' }}
          >
            Pick up Time
          </label>
          <div className='col'>
            <TimePicker
              className='rent-pricing-time-picker'
              showSecond={false}
              value={pickupTime}
              onChange={setPickupTime}
              clearIcon={<></>}
              onOpen={() => setOpenPickUpTime(true)}
              onClose={() => setOpenPickUpTime(false)}
              open={openPickupTime}
            />
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
              style={{ position: 'relative', right: 32, top: 1 }}
            >
              {openPickupTime && (
                <KTSVG path='/media/icons/duotone/Navigation/Check.svg' className='svg-icon-1' />
              )}
            </div>
          </div>
        </div>
        <div className='col' style={{ marginTop: 10 }}>
          <label
            htmlFor={'Pick up time'}
            className='form-label'
            style={{ marginBottom: 15, fontWeight: '600' }}
          >
            Delivery Time
          </label>
          <div className='col'>
            <TimePicker
              className='rent-pricing-time-picker'
              disabledHours={() => {
                return disabledHours(pickupTime)
              }}
              showSecond={false}
              value={deliveryTime}
              onChange={setDeliveryTime}
              clearIcon={<></>}
              onOpen={() => setOpenDeliveryTime(true)}
              onClose={() => setOpenDeliveryTime(false)}
              open={openDeliveryTime}
            />
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
              style={{ position: 'relative', right: 32, top: 1 }}
            >
              {openDeliveryTime && (
                <KTSVG path='/media/icons/duotone/Navigation/Check.svg' className='svg-icon-1' />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='row py-3'>
        <div className='col'>
          <Select
            label='Vehicle Class'
            simpleValue={selectedVehicleClassId || ''}
            options={getSortedList({ listToSort: vehicleClasses || [], args: ['name'] })}
            onChange={(e: RentPlanVehicleClassModel) => {
              setSelectedVehicleClassId(e.id)
            }}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
          />
        </div>
      </div>
      <div className='row py-3'>
        <div className='col'>
          <Select
            label='Extras'
            simpleValue={selectedExtraCosts || []}
            options={getSortedList({
              listToSort:
                extraCosts.filter((extra_costs) => extra_costs.is_applicable !== 'per week day') ||
                [],
              args: ['name']
            })}
            onChange={(e: RentPlanExtraCostsModel[]) => {
              setSelectedExtraCosts(e.map((e) => e.id))
            }}
            isMulti={true}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
          />
        </div>
        <div className='col'>
          <Select
            label='Dislocation Type'
            simpleValue={selectedDislocationId || ''}
            options={getSortedList({ listToSort: dislocationCosts || [], args: ['geofence_type'] })}
            onChange={(e: RentPlanDislocationCostsModel) => {
              setSelectedDislocationId(e.id)
            }}
            getOptionLabel={(option: any) => option.geofence_type}
            getOptionValue={(option: any) => option.id}
          />
        </div>
      </div>
      <div className='row py-3'>
        <div className='col'>
          <Select
            label='Insurance'
            simpleValue={selectedInsuranceId || ''}
            options={getSortedList({
              listToSort:
                insurances.filter(
                  (insurance) => insurance.plan_vehicle_class_id == selectedVehicleClassId
                ) || [],
              args: ['name'],
            })}
            isDisabled={!Boolean(selectedVehicleClassId)}
            onChange={(e: RentPlanInsuranceModel) => {
              setSelectedInsuranceId(e.id)
            }}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
          />
        </div>
        <div className='col'>
          <div>
            <label className='fw-bolder form-label pb-2 pt-1'>Discount</label>
            <InputComponentGroup addOns={{ prepend: '%' }}>
              <input
                name={'discount'}
                type={'number'}
                style={{
                  borderRadius: '0rem 0.475rem 0.475rem 0rem',
                  height: '38px',
                  fontSize: '13px',
                }}
                className={`form-control`}
                value={discount || ''}
                min={0}
                max={100}
                onChange={(ev: any) => {
                  var futureValue = formatNumber({
                    paramValue: ev.target.value,
                    minValue: 0,
                    maxValue: 100,
                  })
                  setDiscount(futureValue)
                }}
              // onBlur={(ev: any) => {
              //   var futureValue = formatNumber({ paramValue: ev.target.value, minValue: 0, maxValue: 100 })
              //   setDiscount(futureValue)
              // }}
              />
            </InputComponentGroup>
          </div>
        </div>
      </div>
      <div className='col py-3'>
        <Select
          isMulti={true}
          label='Insurance Type'
          simpleValue={selectedLiabilityType || []}
          options={getSortedList({ listToSort: insuranceTypeList || [], args: ['label'] })}
          isDisabled={!Boolean(selectedInsuranceId)}
          onChange={(e: { value: string; label: string }[]) => {
            setSelectedLiabilityType(e.map((e) => e.value))
          }}
          getOptionLabel={(option: any) => option.label}
          getOptionValue={(option: any) => option.value}
        />
      </div>
      <div className='row py-3'>
        <div className='col'>
          <TextareaComponent label='Pricing Details' value={pricingDetails} readOnly={true} />
        </div>
      </div>
      <div className='row py-3'>
        <div className='col'>
          <BreakdownCardWrapper
            label={'Pricing Details:'}
            key={'pricing_details'}
            breakdownIdentifier={'taxBreakdown'}
            canEdit={false}
            tariffInfo={{ ...emptyTariffInfo, taxBreakdown: pricingBreakdown }}
            initialExchangeRate={4.99}
            chosenCurrency={selectedPlanCurrency}
            baseCurrency={selectedPlanCurrency}
            currencyWillChange={false}
            paymentType={'CONTRACT'}
          />
        </div>
      </div>
    </div>
  )
}

const waitForPlans = async ({
  selectedCompanyId,
  setArePlansLoading,
  setPlans
}: {
  selectedCompanyId: number,
  setArePlansLoading: Function,
  setPlans: Function
}) => {
  setArePlansLoading(true)
  const futurePlans = await getPlans({ companyId: selectedCompanyId })
  setPlans(futurePlans)
  setArePlansLoading(false)
}

const RentPricingCard = ({ companies }: { companies: CompanyType[] }) => {
  const { planId, companyId } = useParams<any>()
  const [timeInterval, setTimeInterval] = useState<DateObject[]>([])
  const [plans, setPlans] = useState<TariffPlanType[]>([])
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(companyId)
  const [selectedPlanId, setSelectedPlanId] = useState<number>(planId)
  const [selectedPlanCurrency, setSelectedPlanCurrency] = useState<string>('RON')
  const [arePlansLoading, setArePlansLoading] = useState<boolean>(true)
  const [daysCount, setDaysCount] = useState<any>()

  const selectedPlanIdFormated: number = (typeof selectedPlanId === 'string' ? parseInt(selectedPlanId) : selectedPlanId)
  const selectedCompanyIdFormated: number = (typeof selectedCompanyId === 'string' ? parseInt(selectedCompanyId) : selectedCompanyId)
  
  useEffect(() => {
    if (selectedCompanyId) {
      waitForPlans({ selectedCompanyId, setArePlansLoading, setPlans })
    }
  }, [selectedCompanyId])

  const calculateDaysCount = (e) => {
    let weekdays = []
    const allWeekDays = [
      'SUN',
      'MON',
      'TUE',
      'WED',
      'THU',
      'FRI',
      'SAT',
    ]
    const startDate = moment(e[0].format('DD.MM.YYYY'), 'DD.MM.YYYY')
    const endDate = e[1]
      ? moment(e[1].format('DD.MM.YYYY'), 'DD.MM.YYYY')
      : moment(e[0].format('DD.MM.YYYY'), 'DD.MM.YYYY')

    weekdays.push(startDate.day())
    weekdays.push(endDate.day())

    const count = weekdays.reduce((acc, curr) => {
      acc[curr] ? acc[curr]++ : (acc[curr] = 1)
      return acc
    }, {})

    const allWeekDaysWithCount = allWeekDays.map((day, index) => {
      return { day, count: count[index] ?? 0 }
    })
    setDaysCount(allWeekDaysWithCount)
  }

  return (
    <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Rent Pricing</span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <div className='row py-3'>
          <div className='col'>
            <Select
              label='Company'
              value={companies.find(c => c.id === selectedCompanyIdFormated) || ''}
              options={getSortedList({ listToSort: companies || [], args: ['name'] })}
              onChange={(e: CompanyType) => {
                setSelectedCompanyId(e.id)
                setSelectedPlanId(0)
                setSelectedPlanCurrency('RON')
              }}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
            />
          </div>
          <div className='col'>
            <Select
              label='Pricing Plan'
              id='selected-pricing-plan'
              key={`selected-pricing-plan-${selectedCompanyId}`}
              value={plans.find(p => p.id === selectedPlanIdFormated) || ''}
              options={getSortedList({ listToSort: plans || [], args: ['name'] })}
              onChange={(e: TariffPlanType) => {
                setSelectedPlanId(e.id)
                setSelectedPlanCurrency(e.currency || 'RON')
              }}
              isDisabled={arePlansLoading}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
            />
          </div>
        </div>
        <div className='row py-3'>
          <DateRangePicker timeInterval={timeInterval} setTimeInterval={setTimeInterval} additionalOnChange={calculateDaysCount} rightInclusiveInterval={true} />
        </div>
        {/* {selectedPlanId && (timeInterval || []).length > 0 ?  */}
        <PlanPricingOptions key={selectedPlanId} selectedPlanId={selectedPlanId} selectedPlanCurrency={selectedPlanCurrency} timeInterval={timeInterval} daysCount={daysCount} />
        {/* : null
        } */}
      </div>
    </div>
  )
}

const waitForCompanies = async ({
  setIsLoading,
  getCompanies,
  businessProfileId,
}: {
  setIsLoading: Function,
  getCompanies: Function
  businessProfileId?: number
}) => {
  setIsLoading(true)
  await getCompanies({ onlyMainCompany: 1, businessProfileId })
  setIsLoading(false)
}

const RentPricingWrapper: React.FC = () => {
  const { companies, getCompanies } = useContext<any>(CompanyContext);
  const { businessProfileId, user: { role: { slug: userRole } } } = useContext<any>(UserContext)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    let payloadBusinessProfileId: number
    if (userRole === UserRole.AdminFleet) {
      payloadBusinessProfileId = businessProfileId
    }
    waitForCompanies({ setIsLoading, getCompanies, businessProfileId: payloadBusinessProfileId })
  }, [])

  if (isLoading) {
    return <LoadingCard name={'Rent Pricing'} />
  }
  return <RentPricingCard companies={companies} />
}

export default RentPricingWrapper
import React, {createContext, useContext, useMemo, useState} from 'react'
import {toast} from 'react-toastify'
import {useLocalStorage} from '../hooks/useLocalStorage'
import {orbitRequest, RequestMethod} from '../utils/api.utils'
import {UserContext} from './user.context'

const TemplatesContext = createContext({})

function TemplatesProvider(props: any) {
  const [templates, setTemplates] = useState<Array<any>>([])
  const {businessProfileId} = useContext<any>(UserContext)
  const [defaultTemplate, setDefaultTemplate] = useLocalStorage<any>('defaultTemplate', null)

  const getTemplates = async () => {
    try {
      if (!businessProfileId) {
        return // we don't have an easytrack account
      }
      const response = await orbitRequest({
        route: `/template/get`,
        payload: {
          businessProfileId: businessProfileId,
        },
        method: RequestMethod.POST,
      })

      if (!response?.templates || response.error) {
        toast.error(response?.message || 'We have some problem with your last request!')
        return {message: 'Failed fetch on templates', error: true}
      }

      const templates = response.templates

      if (!defaultTemplate) {
        setDefaultTemplate(templates[0])
      }

      return {error: false, data: templates}
    } catch (err) {
      console.error(err)
      return {message: 'Failed fetch on templates', error: true, data: []}
    }
  }
  // Delete template
  const deleteTemplate = async (id: number) => {
    const response = await orbitRequest({
      route: '/template/delete',
      payload: {id},
      method: RequestMethod.POST,
    })

    if (!response || response.error) {
      return toast.error(`We have some problems with your last request! ${response?.message || ''}`)
    }

    const futureTemplatesData = templates.filter((e) => e.id !== id)
    setTemplates(futureTemplatesData)
    toast.success('Template was deleted!')
  }

  const store = {
    getTemplates,
    deleteTemplate,
    templates,
    setTemplates,
    defaultTemplate,
    setDefaultTemplate,
  }

  const storeForProvider = useMemo(() => store, [store])
  return (
    <TemplatesContext.Provider value={storeForProvider}>{props.children}</TemplatesContext.Provider>
  )
}

export {TemplatesContext}
export default TemplatesProvider

import {FC, useEffect, useState} from 'react'
import {emptyTariffService, intermediaryStopExtrasType, TariffServiceType} from './PlanTypes'
import {
  getInitializedObjectListWithEmpty,
  getObjectListWithEmptyStripped,
  onChangeListHasEmpty,
  updateField,
} from '../../../utils/state.utils'
import Table from './Table'
import {usePlanContext} from '../../../context/plan.context'
import _ from 'lodash'
import {getArrayOfObjectsString} from '../../../utils/string.utils'

const intermediaryStopExtrasTypeReq = ['transferType', 'fixedTax', 'freeStops']
const intermediaryStopExtrasTypeUnique = ['transferType', 'tariffServices']
const emptyIntermediaryStopExtra: intermediaryStopExtrasType = {
  id: null,
  transferType: '',
  tariffServices: [],
  fixedTax: 0,
  freeStops: 0,
}
const getStopExtraLabel = (stopExtra: intermediaryStopExtrasType) =>
  'intermediary stop extra (transferType: ' +
  (stopExtra.transferType || '') +
  '; tariffServices: ' +
  getArrayOfObjectsString({list: stopExtra.tariffServices || [], identifier: 'name', maxLength: 25}) +
  ')'

const StopExtrasTable: FC<{}> = ({}) => {
  const {plan, setPlan, dictionaries, tariffServices} = usePlanContext()

  const [stopExtras, setStopExtras] = useState<intermediaryStopExtrasType[]>(
    plan?.intermediaryStopExtras || []
  )

  useEffect(() => {
    let futureStopExtras = getInitializedObjectListWithEmpty({
      initialObjectList: plan?.intermediaryStopExtras || [],
      propsWithEmpty: [{property: 'tariffServices', emptyValue: emptyTariffService}],
    })
    setStopExtras(futureStopExtras)
  }, [])

  useEffect(() => {
    let futureStopExtras = getObjectListWithEmptyStripped({
      objectList: stopExtras || [],
      propsWithEmpty: [{property: 'tariffServices', emptyValue: emptyTariffService}],
    })
    setPlan({...plan, intermediaryStopExtras: futureStopExtras})
  }, [stopExtras])

  const stopExtrasProps = [
    {
      name: 'transferType',
      getDisplayLabel: (name: string) =>
        dictionaries.PRICING_TRANSFER_TYPES.find((item: any) => item.name === name)?.title,
      label: 'Transfer Type',
      type: 'select',
    },
    {
      name: 'tariffServices',
      getDisplayLabel: (tariffServices: TariffServiceType[]) =>
        (tariffServices || []).map((service: any) => service.name || 'ALL').join(', '),
      label: 'Tariff Services',
      type: 'select',
      isMulti: true,
    },
    {
      name: 'fixedTax',
      getDisplayLabel: (e: any) => e,
      label: 'Fixed Tax',
      type: 'currencyInput',
      canEditInView: true,
      addOns: {append: (plan?.currency || '').toLowerCase()},
    },
    {
      name: 'freeStops',
      getDisplayLabel: (e: any) => e,
      label: 'Free Stops',
      type: 'input',
      inputType: 'number',
      canEditInView: true,
      addOns: {append: 'stops'},
      minWidth: '135px',
    },
  ]

  const stopExtrasFieldToProperty = (
    field: any,
    stopExtra: intermediaryStopExtrasType,
    setStopExtra: Function
  ) => {
    let fieldName = field.name
    const updateStopExtraListElemEvent = (e: any) =>
      updateField({value: e, fieldName: fieldName, setObject: setStopExtra})

    const fieldsToPanelPropertiesMapping = {
      transferType: {
        simpleValue: stopExtra.transferType || '',
        options: dictionaries.PRICING_TRANSFER_TYPES || [],
        getOptionValue: (e: any) => e.name || '',
        getOptionLabel: (e: any) => e.title || '',
        onChange: updateStopExtraListElemEvent,
      },
      tariffServices: {
        simpleValue: stopExtra.tariffServices || [],
        options: [emptyTariffService, ...(tariffServices || [])],
        getOptionValue: (e: any) => e || '',
        getOptionLabel: (e: any) => (Boolean(e.name) ? e.name : 'ALL'),
        onChange: (tariffServices: TariffServiceType[]) =>
          onChangeListHasEmpty({
            entity: stopExtra,
            setEntity: setStopExtra,
            newValues: tariffServices,
            emptyValue: emptyTariffService,
            property: 'tariffServices',
          }),
      },
      fixedTax: stopExtra.fixedTax,
      freeStops: stopExtra.freeStops,
    }

    return (fieldsToPanelPropertiesMapping as any)[fieldName]
  }

  return (
    <>
      <Table
        tableLabel={'Intermediary Stop Extras Table'}
        properties={stopExtrasProps}
        fieldToProperty={stopExtrasFieldToProperty}
        entries={stopExtras}
        setEntries={setStopExtras}
        emptyEntry={emptyIntermediaryStopExtra}
        getEntryLabel={getStopExtraLabel}
        requiredFields={intermediaryStopExtrasTypeReq}
        compoundUniqueKey={intermediaryStopExtrasTypeUnique}
      />
    </>
  )
}

export default StopExtrasTable

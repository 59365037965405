import { ControlledMenu, useMenuState } from '@szhsin/react-menu'
import { useMemo, useRef } from 'react'
import { PUBLIC_EASYTRACK_URL } from '../../../../../constants/api.constants'
import { getLogoFromBrand } from '../../../../../utils/car.utils'
import { KTSVG } from '../../../../../_metronic/helpers'

const SmallVehicleInfo = ({ vehicle }: { vehicle: any }) => {
  const ref = useRef(null)
  const [cardProps, toggleCard] = useMenuState({ transition: true })

  const logo = useMemo(() => {
    return getLogoFromBrand(vehicle.brand)
  }, [vehicle])

  return (
    <div style={{ flex: 1 }}>
      <div
        ref={ref}
        onMouseEnter={() => {
          toggleCard(true)
        }}
        onMouseLeave={() => {
          toggleCard(false)
        }}
        onMouseMove={() => {
          toggleCard(true)
        }}
        style={{
          padding: '4px 4px',
          borderRadius: '5px',
          display: 'flex',
          alignItems: 'center',
          background: 'rgba(0,0,0,0.04)',
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
        }}
      >
        <div
          className='symbol'
          style={{
            width: '24px',
            background: 'rgba(0,0,0,0.08)',
            display: 'flex',
            height: '24px',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          {logo && (
            <img
              src={logo}
              style={{
                height: '100%',
                width: 'auto',
              }}
              alt='logo car'
            />
          )}
        </div>
        <div
          style={{
            marginLeft: '8px',
          }}
        >
          <span style={{ fontWeight: 600, fontSize: '10px', display: 'flex', lineHeight: '12px' }}>
            {vehicle.brand} {vehicle.model}
          </span>
          <span style={{ fontSize: '12px', opacity: 0.8 }}>
            {`${vehicle.plateNumber} [${vehicle.unitNumber}]`}
          </span>
        </div>

        <div
          onClick={() => {
            // redirect to easytrack
            window.open(
              `${PUBLIC_EASYTRACK_URL}/maps/view.do?idVehicle=${vehicle.idVehicle}`,
              '_blank'
            )
          }}
          style={{
            marginLeft: 'auto',
          }}
        >
          <KTSVG
            path='/media/icons/duotone/Interface/Map-Marker.svg'
            className='svg-icon-1 svg-icon-info clickable'
          />
        </div>
      </div>

      {/* <ControlledMenu
        {...cardProps}
        portal={true}
        align='start'
        offsetX={-32}
        // overflow='auto'
        direction={'right'}
        arrow={true}
        anchorRef={ref}
        onMouseEnter={() => toggleCard(true)}
        onMouseLeave={() => toggleCard(false)}
        onClose={() => toggleCard(false)}
      >
        <div
          style={{
            padding: '8px 16px',
          }}
        >
          <div className='hover-card-item' style={{flex: 1}}>
            <label>Next medical check up:</label>
          </div>
        </div>
      </ControlledMenu> */}
    </div>
  )
}

export default SmallVehicleInfo

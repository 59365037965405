import {FC, useEffect, useState} from 'react'
import {emptyTariffService, TariffServiceType, waitingTimeExtrasType} from './PlanTypes'
import {
  getInitializedObjectListWithEmpty,
  getObjectListWithEmptyStripped,
  onChangeListHasEmpty,
  updateField,
} from '../../../utils/state.utils'
import Table from './Table'
import {usePlanContext} from '../../../context/plan.context'
import {getArrayOfObjectsString} from '../../../utils/string.utils'

const waitingTimeTypeReq = ['name', 'costPerMinute', 'freeMinutes']
const waitingTimeTypeUnique = ['name', 'transferTypes', 'tariffServices']
const emptyWaitingTimeExtra: waitingTimeExtrasType = {
  id: null,
  name: '',
  transferTypes: [],
  tariffServices: [],
  costPerMinute: 0,
  freeMinutes: 0,
}
const getWaitingTimeExtraLabel = (waitingTimeExtra: waitingTimeExtrasType) =>
  'waiting time extra (name: ' +
  (waitingTimeExtra.name || '') +
  '; transferTypes: ' +
  getArrayOfObjectsString({list: waitingTimeExtra.transferTypes || [], maxLength: 25}) +
  '; tariffServices: ' +
  getArrayOfObjectsString({
    list: waitingTimeExtra.tariffServices || [],
    identifier: 'name',
    maxLength: 25,
  }) +
  ')'

const WaitingTimeTable: FC<{}> = ({}) => {
  const {plan, setPlan, dictionaries, tariffServices} = usePlanContext()
  const pricingTransferTypes = [{name: '', title: ''}, ...dictionaries.PRICING_TRANSFER_TYPES]

  const [waitingTimeExtras, setWaitingTimeExtras] = useState<waitingTimeExtrasType[]>(
    plan?.waitingTimeExtras || []
  )

  useEffect(() => {
    let futureWaitingTimeExtras = getInitializedObjectListWithEmpty({
      initialObjectList: plan?.waitingTimeExtras || [],
      propsWithEmpty: [
        {property: 'tariffServices', emptyValue: emptyTariffService},
        {property: 'transferTypes', emptyValue: ''},
      ],
    })
    setWaitingTimeExtras(futureWaitingTimeExtras)
  }, [])

  useEffect(() => {
    let futureWaitingTimeExtras = getObjectListWithEmptyStripped({
      objectList: waitingTimeExtras || [],
      propsWithEmpty: [
        {property: 'tariffServices', emptyValue: emptyTariffService},
        {property: 'transferTypes', emptyValue: ''},
      ],
    })
    setPlan({...plan, waitingTimeExtras: futureWaitingTimeExtras})
  }, [waitingTimeExtras])

  const WaitingTimeProps = [
    {
      name: 'name',
      getDisplayLabel: (name: string) =>
        dictionaries.WAITING_TIME_TYPE.find((item: any) => item.name === name)?.title,
      label: 'Name',
      type: 'select',
    },
    {
      name: 'transferTypes',
      getDisplayLabel: (transferTypes: string[]) =>
        (transferTypes || [])
          .map((type: string) => {
            return pricingTransferTypes.find((item: any) => item.name === type).title || 'ALL'
          })
          .join(', '),
      label: 'Transfer Types',
      type: 'select',
      isMulti: true,
    },
    {
      name: 'tariffServices',
      getDisplayLabel: (tariffServices: TariffServiceType[]) =>
        (tariffServices || []).map((service: any) => service.name || 'ALL').join(', '),
      label: 'Tariff Service',
      type: 'select',
      isMulti: true,
    },
    {
      name: 'costPerMinute',
      getDisplayLabel: (e: any) => e,
      label: 'Cost/Min',
      type: 'currencyInput',
      canEditInView: true,
      addOns: {append: (plan?.currency || '').toLowerCase()},
    },
    {
      name: 'freeMinutes',
      getDisplayLabel: (e: any) => e,
      label: 'Free Minutes',
      type: 'input',
      inputType: 'number',
      canEditInView: true,
      addOns: {append: 'min'},
    },
  ]

  const waitingTimeFieldToProperty = (
    field: any,
    waitingTimeExtra: waitingTimeExtrasType,
    setWaitingTimeExtra: Function
  ) => {
    let fieldName = field.name
    const updateWaitingTimeListElemEvent = (e: any) =>
      updateField({value: e, fieldName: fieldName, setObject: setWaitingTimeExtra})

    const fieldsToPanelPropertiesMapping = {
      name: {
        simpleValue: waitingTimeExtra.name || '',
        options: dictionaries.WAITING_TIME_TYPE || [],
        getOptionValue: (e: any) => e.name || '',
        getOptionLabel: (e: any) => e.title || '',
        onChange: updateWaitingTimeListElemEvent,
      },
      transferTypes: {
        simpleValue: waitingTimeExtra.transferTypes || [],
        options: pricingTransferTypes || [],
        getOptionValue: (e: any) => e.name || '',
        getOptionLabel: (e: any) => e.title || 'ALL',
        onChange: (transferTypes: string[]) =>
          onChangeListHasEmpty({
            entity: waitingTimeExtra,
            setEntity: setWaitingTimeExtra,
            newValues: transferTypes,
            emptyValue: '',
            property: 'transferTypes',
          }),
        sorted: true,
      },
      tariffServices: {
        simpleValue: waitingTimeExtra.tariffServices || [],
        options: [emptyTariffService, ...(tariffServices || [])],
        getOptionValue: (e: any) => e || '',
        getOptionLabel: (e: any) => (Boolean(e.name) ? e.name : 'ALL'),
        onChange: (tariffServices: TariffServiceType[]) =>
          onChangeListHasEmpty({
            entity: waitingTimeExtra,
            setEntity: setWaitingTimeExtra,
            newValues: tariffServices,
            emptyValue: emptyTariffService,
            property: 'tariffServices',
          }),
        sorted: true,
      },
      costPerMinute: waitingTimeExtra.costPerMinute,
      freeMinutes: waitingTimeExtra.freeMinutes,
    }

    return (fieldsToPanelPropertiesMapping as any)[fieldName]
  }

  return (
    <>
      <Table
        tableLabel={'Waiting Time Based Extras Table'}
        properties={WaitingTimeProps}
        fieldToProperty={waitingTimeFieldToProperty}
        entries={waitingTimeExtras}
        setEntries={setWaitingTimeExtras}
        emptyEntry={emptyWaitingTimeExtra}
        getEntryLabel={getWaitingTimeExtraLabel}
        requiredFields={waitingTimeTypeReq}
        compoundUniqueKey={waitingTimeTypeUnique}
      />
    </>
  )
}

export default WaitingTimeTable

import {uuid4} from '@sentry/utils'
import {FC, useEffect, useMemo, useState} from 'react'
import {Button, Collapse} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {EASYTRACK_API_URL} from '../../../../constants/api.constants'
import easytrackAxios from '../../../../setup/easytrack.axios'
import {
  BreakdownObjectType,
  eurToRon,
  getCurrencyOfTotal,
  getPriceWithVAT,
  ronToEur,
  roundToDecimal,
  TariffInfoType,
} from '../../../../utils/pricing.utils'
import {KTSVG} from '../../../../_metronic/helpers'
import CurrencyInput from '../../../../_metronic/layout/components/fields/currencyInput/CurrencyInput'
import ModalPortal from '../../../../_metronic/layout/components/modal/modal-portal'
import {formatCurrency} from './RequestFields'

export type BreakdownTypeTemp = {
  label: string
  valueObj: {RON: number; EUR: number}
  description: string
  manuallyEdited: boolean
  important: boolean
  tempKey: string
}

const getEntriesArrayFromObject = ({
  object,
  exchangeRate,
  currency,
}: {
  object: BreakdownObjectType
  exchangeRate: number
  currency: string
}): BreakdownTypeTemp[] => {
  let isCurrencyRON = currency === 'RON'
  return Object.entries(object || {}).map(([key, info]) => {
    let valueObj = {RON: 0, EUR: 0}
    if (isCurrencyRON) {
      valueObj.RON = info.value
      valueObj.EUR = roundToDecimal(ronToEur({price: info.value, exchangeRate}), 2)
    } else {
      valueObj.RON = roundToDecimal(eurToRon({price: info.value, exchangeRate}), 2)
      valueObj.EUR = info.value
    }
    return {
      label: key,
      description: info.description,
      manuallyEdited: info.manuallyEdited,
      important: info.important,
      valueObj,
      tempKey: uuid4(),
    }
  })
}

export const getObjectFromEntriesArray = ({
  objectList,
  currency,
}: {
  objectList: BreakdownTypeTemp[]
  currency: string
}): BreakdownObjectType => {
  let newObject: BreakdownObjectType = {}
  objectList.forEach((entry: BreakdownTypeTemp) => {
    newObject[entry.label] = {
      description: entry.description,
      value: entry.valueObj[currency],
      important: entry.important,
      manuallyEdited: entry.manuallyEdited,
    }
  })
  return newObject
}

const EditModal = ({
  breakdownList,
  setBreakdownList,
  label,
  modalVisible,
  setModalVisible,
  currency,
  exchangeRate,
}: {
  breakdownList: BreakdownTypeTemp[]
  setBreakdownList: Function
  label: string
  modalVisible: boolean
  setModalVisible: Function
  currency: string
  exchangeRate: number
}) => {
  const [futureBreakdown, setFutureBreakdown] = useState<BreakdownTypeTemp[]>(breakdownList)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const otherCurrency = currency === 'RON' ? 'EUR' : 'RON'

  const convertPrice = (value: number): number => {
    let isCurrencyRON = currency === 'RON'
    let payload = {price: value, exchangeRate}
    let newValue = isCurrencyRON ? ronToEur(payload) : eurToRon(payload)
    return roundToDecimal(newValue, 2)
  }

  const Header: FC = () => (
    <span className='mb-8' style={{fontSize: '16px', fontWeight: '500'}}>
      {label}
    </span>
  )

  const modifyBreakdownList = ({
    index,
    property,
    newValue,
  }: {
    index: number
    property: string
    newValue: any
  }) => {
    let futureList = [...futureBreakdown]
    futureList[index][property] = newValue
    futureList[index].manuallyEdited = true
    setFutureBreakdown(futureList)
  }

  const deleteListElement = ({index}: {index: number}) => {
    let futureList = [...futureBreakdown]
    futureList.splice(index, 1)
    setFutureBreakdown(futureList)
  }

  const getEditableBreakdownFields = () => {
    return (
      <div className='w-100'>
        <table className='table table-hover table-row-gray-100 align-middle w-100'>
          <tr className='fw-bolder'>
            <th
              style={{minWidth: '150px', width: '180px', maxWidth: '180px', paddingLeft: '0.75rem'}}
            >
              Label
            </th>
            <th style={{minWidth: '220px', paddingLeft: '0.75rem'}}>Description</th>
            <th
              style={{minWidth: '70px', width: '140px', maxWidth: '140px', paddingLeft: '0.75rem'}}
            >
              Value
            </th>
            <th style={{width: '50px', maxWidth: '60px', paddingLeft: '0.75rem'}}>Actions</th>
          </tr>
          {futureBreakdown.map((elem: BreakdownTypeTemp, index: number) => {
            return (
              <tr key={`row-${elem.tempKey}`}>
                <td style={{verticalAlign: 'top'}}>
                  <div>
                    <input
                      placeholder='label'
                      className='form-control form-control-sm'
                      value={elem.label}
                      onChange={(e: any) => {
                        modifyBreakdownList({index, property: 'label', newValue: e.target.value})
                      }}
                    />
                  </div>
                </td>
                <td style={{verticalAlign: 'top'}}>
                  <div>
                    <textarea
                      placeholder='description'
                      rows={1}
                      className='form-control form-control-sm'
                      value={elem.description}
                      onChange={(e: any) => {
                        modifyBreakdownList({
                          index,
                          property: 'description',
                          newValue: e.target.value,
                        })
                      }}
                    />
                  </div>
                </td>
                <td style={{verticalAlign: 'top'}}>
                  <CurrencyInput
                    hideLabel={true}
                    field={{name: elem.label, addOns: {append: currency}}}
                    value={elem.valueObj[currency] + ''}
                    onChange={(e: any) => {
                      const newValue = e.target.value
                      let futureList = [...futureBreakdown]
                      futureList[index].valueObj[currency] = newValue
                      futureList[index].valueObj[otherCurrency] = convertPrice(newValue)
                      futureList[index].manuallyEdited = true
                      setFutureBreakdown(futureList)
                    }}
                  />
                </td>
                <td style={{verticalAlign: 'top'}}>
                  <div>
                    <button
                      disabled={isLoading}
                      className='btn btn-icon btn-light-danger h-30px'
                      onClick={() => {
                        deleteListElement({index})
                        toast.info('Entry was deleted!')
                      }}
                    >
                      <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-4' />
                    </button>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <button
            type='button'
            className='btn btn-light-primary btn-text-primary'
            disabled={isLoading}
            onClick={() => {
              setFutureBreakdown([
                ...futureBreakdown,
                {
                  label: '',
                  description: '',
                  valueObj: {EUR: 0, RON: 0},
                  important: false,
                  tempKey: uuid4(),
                  manuallyEdited: true,
                },
              ])
            }}
          >
            <div style={{display: 'flex', alignItems: 'center'}}>
              <KTSVG path='/media/icons/duotone/Navigation/Plus.svg' className='svg-icon-2' />
              Add Entry
            </div>
          </button>
        </div>
      </div>
    )
  }

  const isEntryListValid = (entryList: BreakdownTypeTemp[]): boolean => {
    let keys = entryList.map((entry: BreakdownTypeTemp) => entry.label).sort()
    return keys.every((key: string, index: number) => {
      if (!key) {
        toast.warning('Invalid key: all entries should have a label')
        return false
      }
      if (index > 0 && key === keys[index - 1]) {
        toast.warning('Duplicate key found: ' + key)
        return false
      }
      return true
    })
  }

  const ActionButtons: FC = () => {
    return (
      <div className='mt-8'>
        <button
          className='btn btn-secondary me-5'
          disabled={isLoading}
          onClick={() => {
            setModalVisible(false)
          }}
        >
          Cancel
        </button>
        <button
          className='btn btn-success'
          disabled={isLoading}
          onClick={async () => {
            setIsLoading(true)
            if (!isEntryListValid(futureBreakdown)) {
              setIsLoading(false)
              return
            }
            setBreakdownList([...futureBreakdown])
            setModalVisible(false)
          }}
        >
          {isLoading ? <i className='fa fa-circle-o-notch fa-spin' /> : null}
          Save
        </button>
      </div>
    )
  }

  return (
    <ModalPortal
      setVisible={setModalVisible}
      visible={modalVisible}
      dialogClassName='modal-xxl'
      hasExitButton={false}
    >
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          marginTop: '10px',
          alignItems: 'center',
          width: '92.5%',
        }}
      >
        <Header />
        {getEditableBreakdownFields()}
        <ActionButtons />
      </div>
    </ModalPortal>
  )
}

const BreakdownCardWrapper = ({
  label,
  breakdownIdentifier,
  tariffInfo,
  setTariffInfo = () => {},
  canEdit = false,
  initialVAT = 0,
  initialExchangeRate = 0,
  chosenCurrency = 'RON',
  baseCurrency = 'RON',
  paymentType = '',
  gpsMonitored = false,
  currencyWillChange = true,
}: {
  label: string
  breakdownIdentifier: string
  tariffInfo: TariffInfoType
  setTariffInfo?: Function
  canEdit?: boolean
  initialVAT?: number
  initialExchangeRate?: number
  chosenCurrency?: string
  baseCurrency?: string
  paymentType?: string
  gpsMonitored?: boolean
  currencyWillChange?: boolean
}) => {
  const breakdown = useMemo(
    () => (tariffInfo?.[breakdownIdentifier] ? tariffInfo[breakdownIdentifier] : {}),
    [tariffInfo?.[breakdownIdentifier]]
  )

  const [VAT, setVAT] = useState<number>(initialVAT)
  const [exchangeRate, setExchangeRate] = useState<number>(initialExchangeRate)
  const [hasLoaded, setHasLoaded] = useState<boolean>(false)
  const [currencyBreakdownList, setCurrencyBreakdownList] = useState<BreakdownTypeTemp[]>([])
  
  useEffect(() => {
    if (!hasLoaded) {
      return null
    }
    let futureCurrencyBreakdownList = getEntriesArrayFromObject({
      object: breakdown,
      currency: baseCurrency,
      exchangeRate,
    })
    const otherCurrency: string = baseCurrency === 'RON' ? 'EUR' : 'RON'
    futureCurrencyBreakdownList.forEach((entry: BreakdownTypeTemp, index: number) => {
      let entryIndex = currencyBreakdownList.findIndex((e) => e.label === entry.label)
      if (
        entryIndex !== -1 &&
        currencyBreakdownList[entryIndex].valueObj[baseCurrency] === entry.valueObj[baseCurrency]
      ) {
        futureCurrencyBreakdownList[index].valueObj[otherCurrency] =
          currencyBreakdownList[entryIndex].valueObj[otherCurrency]
      }
    })
    setCurrencyBreakdownList(futureCurrencyBreakdownList)
  }, [breakdown, hasLoaded])

  useEffect(() => {
    Promise.allSettled([
      (async () => {
        let futureExchangeRate = exchangeRate
        // set exchangeRate if we don't have it or if the baseCurrency is RON (because it would be then equal to 1)
        if (currencyWillChange && (!exchangeRate || (baseCurrency === 'RON' && exchangeRate === 1))) {
          const result = await easytrackAxios
            .get(`${EASYTRACK_API_URL}/utils/exchange-rates/EUR`)
            .catch((err) => err)
          if (result.data) {
            futureExchangeRate = result.data
            setExchangeRate(result.data)
          }
        }

        setCurrencyBreakdownList(
          getEntriesArrayFromObject({
            object: breakdown,
            exchangeRate: futureExchangeRate,
            currency: baseCurrency,
          })
        )
      })(),
      (async () => {
        // set VAT
        const result = await easytrackAxios
          .get(`${EASYTRACK_API_URL}/utils/constants`)
          .catch((err) => err)
        if (result.data) {
          setVAT(result.data?.VAT)
        }
      })(),
    ]).then((e) => {
      // is done loading
      setHasLoaded(true)
    })
  }, [])

  if (!hasLoaded) {
    return null
  }

  const setTariffInfoProperty = (breakdownList: BreakdownTypeTemp[]) => {
    setCurrencyBreakdownList(breakdownList)
    let futureBreakdownInfo: BreakdownObjectType = getObjectFromEntriesArray({
      objectList: breakdownList,
      currency: baseCurrency,
    })
    setTariffInfo({...tariffInfo, [breakdownIdentifier]: futureBreakdownInfo})
  }

  return (
    <BreakdownCard
      label={label}
      breakdownList={currencyBreakdownList}
      setBreakdownList={setTariffInfoProperty}
      exchangeRate={exchangeRate}
      VAT={VAT}
      canEdit={canEdit}
      chosenCurrency={chosenCurrency}
      baseCurrency={baseCurrency}
      paymentType={paymentType}
      gpsMonitored={gpsMonitored}
    />
  )
}

const CollapsibleInfo = ({children, buttonLabel}: {children: any; buttonLabel: string}) => {
  const [summaryVisible, setSummaryVisible] = useState<boolean>(false)

  return (
    <div style={{width: '100%', paddingTop: '0.5rem'}}>
      <div style={{width: '100%', display: 'flex', justifyContent: 'end'}}>
        <Button
          style={{width: '150px', borderRadius: '33px', border: 'none', padding: '6px'}}
          onClick={() => setSummaryVisible(!summaryVisible)}
          aria-controls='collapsible-item'
          aria-expanded={summaryVisible}
        >
          {buttonLabel}
        </Button>
      </div>
      <Collapse in={summaryVisible}>
        <div id='collapsible-item'>{children}</div>
      </Collapse>
    </div>
  )
}

const BreakdownEntry = ({
  entry,
  chosenCurrency,
}: {
  entry: BreakdownTypeTemp
  chosenCurrency: string
}) => {
  return (
    <span
      key={entry.label}
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0.375rem 0',
      }}
    >
      <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
        <span className={entry.important ? 'pe-2' : ''}>{entry.label}:</span>
        {entry.important ? (
          <span className='badge badge-warning' style={{color: '#212529', padding: '0.25rem'}}>
            {'Difference between estimated and actual >10%'}
          </span>
        ) : null}
      </div>
      <span style={{flex: 2, opacity: 0.75}} className='pe-5'>
        {entry.description || 'no description'}
      </span>
      <span>
        {formatCurrency({
          value: entry.valueObj[chosenCurrency],
          currency: chosenCurrency,
        })}
      </span>
    </span>
  )
}

const TariffTotal = ({
  totalValueObject,
  paymentType,
  exchangeRate,
  baseCurrency,
}: {
  totalValueObject: any
  paymentType: string
  exchangeRate: number
  baseCurrency: string
}) => {
  const totalValueStylesObject = {
    RON: {
      ...((paymentType === 'CONTRACT' || paymentType === 'SUBSCRIPTION') && baseCurrency === 'RON'
        ? {fontWeight: 700}
        : {}),
    },
    EUR: {
      ...((paymentType === 'CONTRACT' || paymentType === 'SUBSCRIPTION') && baseCurrency === 'EUR'
        ? {fontWeight: 700}
        : {}),
    },
    VatRON: {...(paymentType === 'POS' || paymentType === 'ONLINE' ? {fontWeight: 700} : {})},
    VatEUR: {},
  }

  return (
    <CollapsibleInfo buttonLabel='more details'>
      <span style={{display: 'flex', justifyContent: 'end', paddingTop: '0.75rem'}}>
        <div style={{display: 'inline-flex', flexDirection: 'column', alignItems: 'end'}}>
          <span>
            <span style={{...totalValueStylesObject.RON}}>
              {formatCurrency({
                value: totalValueObject.RON,
                currency: 'RON',
              })}
            </span>
            {' / '}
            <span style={{...totalValueStylesObject.VatRON}}>
              {formatCurrency({
                value: totalValueObject.VatRON,
                currency: 'RON',
              })}{' '}
              <span style={{opacity: 0.75, fontSize: 11}}>VAT included</span>
            </span>
          </span>
          <span>
            <span style={{...totalValueStylesObject.EUR}}>
              {formatCurrency({
                value: totalValueObject.EUR,
                currency: 'EUR',
              })}
            </span>
            {' / '}
            <span style={{...totalValueStylesObject.VatEUR}}>
              {formatCurrency({
                value: totalValueObject.VatEUR,
                currency: 'EUR',
              })}{' '}
              <span style={{opacity: 0.75, fontSize: 11}}>VAT included</span>
            </span>
          </span>
          <span>
            <span style={{opacity: 0.75, fontSize: 12}}>
              {'Exchange rate (RON -> EUR): ' + exchangeRate}
            </span>
          </span>
        </div>
      </span>
    </CollapsibleInfo>
  )
}

export const getListSum = ({
  breakdownList,
  currency,
}: {
  breakdownList: BreakdownTypeTemp[]
  currency: string
}) =>
  (breakdownList || [])
    .map((e: BreakdownTypeTemp) => parseFloat('' + (e.valueObj[currency] || '0'))) // extract the values
    .reduce((sum: number, val: number) => sum + val, 0) // add the values together

const BreakdownCard = ({
  label,
  breakdownList = [],
  setBreakdownList = () => {},
  canEdit,
  exchangeRate,
  VAT,
  chosenCurrency,
  baseCurrency,
  paymentType,
  gpsMonitored,
}: {
  label: string
  breakdownList: BreakdownTypeTemp[]
  setBreakdownList: Function
  canEdit: boolean
  exchangeRate: number
  VAT: number
  chosenCurrency: string
  baseCurrency: string
  paymentType: string
  gpsMonitored?: boolean
}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const getBreakdownTotalValueObject = ({breakdownList}: {breakdownList: BreakdownTypeTemp[]}) => {
    let totalInEUR: number = 0,
      totalInRON: number = 0
    if (baseCurrency === 'EUR') {
      totalInEUR = getListSum({breakdownList, currency: 'EUR'})
      totalInRON = eurToRon({price: totalInEUR, exchangeRate})
    } else {
      totalInRON = getListSum({breakdownList, currency: 'RON'})
      totalInEUR = ronToEur({price: totalInRON, exchangeRate})
    }

    return {
      RON: totalInRON,
      VatRON: getPriceWithVAT({price: totalInRON, VAT}),
      EUR: totalInEUR,
      VatEUR: getPriceWithVAT({price: totalInEUR, VAT}),
    }
  }
  const totalValueObject = useMemo(
    () => getBreakdownTotalValueObject({breakdownList}),
    [breakdownList]
  )
  const currencyOfTotal = getCurrencyOfTotal({paymentType, currency: baseCurrency})
  const currencyKey = (currencyOfTotal.includeVAT ? 'Vat' : '') + currencyOfTotal.currency

  return (
    <div
      style={{width: '100%', padding: '0.75rem', position: 'relative'}}
      className='my-5'
      key={`breakdownInfo-${label}`}
    >
      {modalVisible ? (
        <EditModal
          breakdownList={breakdownList}
          setBreakdownList={setBreakdownList}
          label={label}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          currency={chosenCurrency}
          exchangeRate={exchangeRate}
        />
      ) : null}
      <div
        style={{
          paddingBottom: '0.75rem',
          fontWeight: 700,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{display: 'flex', alignItems: 'center'}}>
          <span className='pe-2'>{label}</span>
          {Boolean(gpsMonitored) ? (
            <span className='badge badge-success'>GPS Monitored</span>
          ) : (
            <span className='badge badge-danger'>NOT GPS Monitored</span>
          )}
        </div>
        {canEdit ? (
          <div
            onClick={() => setModalVisible(true)}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 my-1'
          >
            <KTSVG path='/media/icons/duotone/Communication/Write.svg' className='svg-icon-3' />
          </div>
        ) : null}
      </div>
      {(breakdownList || []).map((entry: BreakdownTypeTemp) => (
        <BreakdownEntry entry={entry} chosenCurrency={chosenCurrency} />
      ))}
      <div
        style={{
          display: 'flex',
          borderTop: '1px solid #DDD',
          padding: '1.5rem 0 0.2rem 0',
          marginTop: '10px',
        }}
      >
        <span style={{flex: 1}}>Total:</span>
        <span>
          <span>
            {formatCurrency({
              value: totalValueObject[currencyKey],
              currency: currencyOfTotal.currency,
            })}{' '}
            <span style={{opacity: 0.75, fontSize: 11}}>
              {currencyOfTotal.includeVAT ? 'VAT included' : 'Without VAT'}
            </span>
          </span>
        </span>
      </div>
      <TariffTotal
        totalValueObject={totalValueObject}
        paymentType={paymentType}
        exchangeRate={exchangeRate}
        baseCurrency={baseCurrency}
      />
    </div>
  )
}

export default BreakdownCardWrapper

import { CompanyType } from "./company.types"

export const enum TypeOfClient {
  PF = 'PF',
  PJ = 'PJ',
}

export type ClientType = {
  id: number
  company: CompanyType
  company_id: number
  type: TypeOfClient
  insurance_for: string
  name: string
  identity_code: string
  address: string
  locationGPS: string
  email: string
  phone: string
}

export const emptyClient: ClientType = {
  id: 0,
  company: null,
  company_id: 0,
  type: TypeOfClient.PF,
  insurance_for: '',
  name: '',
  identity_code: '',
  address: '',
  locationGPS: '',
  email: '',
  phone: '',
}
import React, { useState, useRef } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap'
import SignatureCanvas from "react-signature-canvas";
import { KTSVG } from '../../_metronic/helpers';

const Signature = ({
  title,
  setSignature, 
  openModal,
  setOpenModal
}: {
  title:string
  setSignature:Function
  openModal: boolean
  setOpenModal: Function
}) => {

  const sigCanvas = useRef<SignatureCanvas | null>(null)

  const saveSignature = ()=>{
    if (sigCanvas?.current) { 
      setSignature(sigCanvas.current.getTrimmedCanvas().toDataURL())
      setOpenModal(false);
    } 
  }

  return (
    <Modal
      size='sm'
      className='signatureModal'
      onHide={() => { setOpenModal(false) }}
      style={{ width: '100%' }}
      show={openModal}
      animation={true}
    >
      <ModalHeader>
        <p>{title}</p>
        <button
          onClick={() => { setOpenModal(false) }}
          className='btn btn-icon  btn-active-color-danger btn-sm me-5 mb-5'
        >
          <KTSVG
            path='/media/icons/duotone/Interface/Close-Square.svg'
            className='svg-icon-3tx svg-icon-primary clickable'
          />
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="sigPadContainer">
          <SignatureCanvas
            penColor={'black'}
            canvasProps={{  className: 'sigCanvas' }}
            ref={sigCanvas}
          />
          <hr />
          <button onClick={() => { if (sigCanvas && sigCanvas?.current) { sigCanvas?.current?.clear() } }}>Clear</button>
        </div>

        <div className='mt-8' style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            className='btn btn-secondary me-5'
            onClick={() => {
              setOpenModal(false)
            }}
          >
            Cancel
          </button>
          <button
            className='btn btn-success'
            onClick={saveSignature}
          >
            Save
          </button>
        </div>
      </ModalBody>


    </Modal>
  )
}

export default Signature

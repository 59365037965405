/* eslint-disable react/jsx-no-target-blank */
import React, {useContext, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {UserContext} from '../../../../context/user.context'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {TemplatesContext} from '../../../../context/templates.context'
import {canAccess} from '../../../../utils/permissions.utils'
import {anyToBoolean, getURLFormattedTemplateName} from '../../../../utils/string.utils'

export function AsideMenuMain() {
  const {getTemplates, templates, setTemplates} = useContext<any>(TemplatesContext)
  const intl = useIntl()
  const {user, permissions} = useContext<any>(UserContext)
  const icons = ['Difference', 'Pixels', 'Layers', 'Join-3']

  const [canShowGrileTarife, setCanShowGrileTarife] = React.useState(true)

  const getTemplatesRequest = async () => {
    if (user?.from_easytrack) {
      const result = await getTemplates()
      setTemplates(result.data)
    }
  }

  useEffect(() => {
    getTemplatesRequest()
  }, [])

  const AsideMenuSection: React.FC<{title: string; checkPaths: string[]}> = ({
    title,
    checkPaths,
    children,
  }) => {
    const showTitle = checkPaths.some((path) => canAccess(permissions, path))
    return (
      <>
        {showTitle ? (
          <div className='menu-item'>
            <div className='menu-content pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{title}</span>
            </div>
          </div>
        ) : (
          <></>
        )}
        {children}
      </>
    )
  }

  const ProtectedAsideMenuItem = ({checkPath = null, to, title, ...props}) => {
    return canAccess(permissions, checkPath || to) ? (
      <AsideMenuItem to={to} title={title} {...props} />
    ) : (
      <></>
    )
  }

  return (
    <>
      <AsideMenuSection title='Management' checkPaths={['/users', '/cars']}>
        <ProtectedAsideMenuItem
          to='/companies'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.COMPANIES'})}
          fontIcon='bi-app-indicator'
        />
        <ProtectedAsideMenuItem
          to='/car-classes'
          icon='/media/icons/duotone/Home/Key.svg'
          title='Car Classes'
          fontIcon='bi-app-indicator'
        />
        <ProtectedAsideMenuItem
          to='/users'
          icon='/media/icons/duotone/General/User.svg'
          title={intl.formatMessage({id: 'MENU.USERS'})}
          fontIcon='bi-app-indicator'
        />
        <ProtectedAsideMenuItem
          to='/cars'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.CARS'})}
          fontIcon='bi-app-indicator'
        />
      </AsideMenuSection>

      {/* Grilă tarife */}
      {canShowGrileTarife ? (
        <AsideMenuSection title='Tariff plans' checkPaths={['/geofence-list']}>
          <ProtectedAsideMenuItem
            to='/geofence-list'
            icon='/media/icons/duotone/Interface/Map-Marker.svg'
            title='Geofence list'
            fontIcon='bi-app-indicator'
          />
          <ProtectedAsideMenuItem
            to='/tariff-grid'
            icon='/media/icons/duotone/Layout/Layout-grid.svg'
            title='Tariff plans'
            fontIcon='bi-app-indicator'
          />
        </AsideMenuSection>
      ) : null}

      {anyToBoolean(user.from_easytrack) ? (
        <AsideMenuSection title='Templates' checkPaths={['/templates', '/transport-requests']}>
          {templates &&
            templates.map((template: any, index: number) => (
              <ProtectedAsideMenuItem
                checkPath='/transport-requests'
                key={template.name}
                to={'/transport-requests/' + getURLFormattedTemplateName(template.name)}
                icon={'/media/icons/duotone/Design/' + icons[index % icons.length] + '.svg'}
                title={template.name}
                fontIcon='bi-app-indicator'
              />
            ))}
          <ProtectedAsideMenuItem
            to='/templates'
            icon='/media/icons/duotone/Design/Edit.svg'
            title='Templates'
            fontIcon='bi-app-indicator'
          />
        </AsideMenuSection>
      ) : null}

      <AsideMenuSection title='Transport' checkPaths={['/transport-assignments']}>
        <ProtectedAsideMenuItem
          to='/transport-assignments'
          icon='/media/icons/duotone/Design/Position.svg'
          title='Transport Assignments'
          fontIcon='bi-app-indicator'
        />
      </AsideMenuSection>

      <AsideMenuSection title='Subscription' checkPaths={['/subscriptions']}>
        <ProtectedAsideMenuItem
          to='/subscriptions'
          icon='/media/icons/duotone/Layout/Layout-grid.svg'
          title='Subscriptions'
          fontIcon='bi-app-indicator'
        />
      </AsideMenuSection>

      {/* <AsideMenuSection title='RENT' checkPaths={['/transport-assignments']}>
        <ProtectedAsideMenuItem
          to='/car-classes'
          icon='/media/icons/duotone/Home/Key.svg'
          title='Car Classes'
          fontIcon='bi-app-indicator'
        />
      </AsideMenuSection> */}

      {/* SMS Campaigns */}
      <AsideMenuSection title='Campaigns' checkPaths={['/campaigns']}>
        <ProtectedAsideMenuItem
          to='/campaigns'
          icon='/media/icons/duotone/Layout/Layout-polygon.svg'
          title='SMS'
          fontIcon='bi-app-indicator'
        />
      </AsideMenuSection>
    </>
  )
}
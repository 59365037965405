import _ from 'lodash'
import {useEffect, useState} from 'react'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'

/**
 * Check documentation
 * https://github.com/jedwatson/react-select
 */

export default function Select({styles, className, label, disableBolder, disablePadding, required, ...props}: any) {
  return (
    <div className={className} style={{minWidth: 200}}>
      {label ? (
        <label htmlFor={label} className={`${!disableBolder ? "fw-bolder" : ""} form-label ${disablePadding ? "" : "pb-2 pt-1"}`}>
          {label} {required && <span style ={{color: "red"}}>*</span>}
        </label>
      ) : null}
      <ReactSelect
        closeMenuOnSelect={props.isMulti ? false : true}
        menuPortalTarget={document.body}
        styles={{menuPortal: (base) => ({...base, zIndex: 55}), ...styles}}
        {...props}
      />
    </div>
  )
}

const findValueFromData = (
  value: any,
  simpleValue: any,
  options: any[],
  setValue: Function,
  getOptionValue: Function
) => {
  if (simpleValue !== undefined && options.length) {
    const val = Array.isArray(simpleValue)
      ? options.filter((x: any) =>
          simpleValue.some((value: any) => _.isEqual(value, getOptionValue(x)))
        )
      : options.find((x: any) => _.isEqual(getOptionValue(x), simpleValue))
    setValue(val || '')
  } else if (value !== undefined) {
    setValue(value)
  }
}

export function SelectObject({
  styles,
  className,
  label,
  options,
  simpleValue,
  getOptionValue,
  ...props
}: any) {
  /**
   * Solution proposed in the issue thread: https://github.com/JedWatson/react-select/issues/3063
   */
  const [value, setValue] = useState(props.value)

  useEffect(() => {
    findValueFromData(props.value, simpleValue, options, setValue, getOptionValue)
  }, [props.value, simpleValue, options])

  const onChange = (option: any) => {
    let hasSimpleValue = simpleValue !== undefined
    // the object should have only value or simpleValue
    findValueFromData(
      hasSimpleValue ? null : option,
      hasSimpleValue ? option : null,
      options,
      setValue,
      getOptionValue
    )
    props.onChange && props.onChange(option)
  }

  return (
    <div className={className} style={{minWidth: 200}}>
      {label ? (
        <label htmlFor={label} className='fw-bolder form-label pb-2 pt-1'>
          {label}
        </label>
      ) : null}
      <ReactSelect
        {...props}
        closeMenuOnSelect={props.isMulti ? false : true}
        menuPortalTarget={document.body}
        styles={{menuPortal: (base) => ({...base, zIndex: 55}), ...styles}}
        onChange={(e: any) => {
          onChange(
            Array.isArray(e) ? e.map((elem: any) => getOptionValue(elem)) : getOptionValue(e)
          )
        }}
        options={options}
        value={value}
        getOptionValue={getOptionValue}
      />
    </div>
  )
}

// export function SelectAsync(props: any) {
//   return <AsyncSelect {...props} />
// }

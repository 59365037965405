import {useMemo} from 'react'
import ReactSelect from 'react-select/async'
import {getAutocompleteAsync} from '../../../../utils/geolocation.utils'
import debounce from 'debounce-promise'
import {components} from 'react-select'

/**
 * Check documentation
 * https://github.com/jedwatson/react-select
 */

const Input = (props) => <components.Input {...props} isHidden={false} />

type GeolocationSelectProps = {
  simpleValue: string
  onChange: (value: any) => void
  onInputChange: (value: string) => void
  [key: string]: any
}

const loadOptions = (inputValue: string) => getAutocompleteAsync({input: inputValue})
const debouncedLoadOptions = debounce(loadOptions, 600, {
  leading: true,
})

export default function GeolocationSelect(props: GeolocationSelectProps) {
  const onInputChange = (inputValue, {action}) => {
    // onInputChange => update inputValue
    if (action === 'input-change') {
      props.onInputChange(inputValue)
    }
  }

  const defaultOptions = useMemo(() => {
    if (props?.value) {
      return [
        {
          description: props.simpleValue,
          place_id: props.simpleValue,
        },
      ]
    }

    return null
  }, [])

  const onChange = (value) => {
    if (props.simpleValue === value.place_id) {
      return
    }
    props.onChange(value)
  }

  return (
    <div data-bs-toggle='tooltip' title={props.simpleValue || ''}>
      <ReactSelect
        {...props}
        getOptionLabel={(item: any) => item.description}
        getOptionValue={(item: any) => {
          return item.place_id
        }}
        defaultOptions={defaultOptions}
        loadOptions={(inputValue: string) => debouncedLoadOptions(inputValue)}
        onChange={onChange}
        onInputChange={onInputChange}
        inputValue={props.simpleValue}
        components={{Input}}
      />
    </div>
  )
}

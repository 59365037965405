import {Invoice, InvoiceNecessaries} from '../types/invoice.types'

export const getMappedRates = (arr: number[], items: any[]) => {
  return items.map((item, index) => ({
    itemId: item.item_id,
    rate: arr[index] !== undefined ? arr[index] : 0,
  }))
}

export const getMappedNeededItems = (payload: {
  neededLineItems: any[]
  rates: {itemId: any; rate: number}[]
  invoice: Invoice
}) => {
  const {neededLineItems, rates, invoice} = payload

  return neededLineItems.map((item, index) => ({
    ...item,
    description: invoice.itemDescription[index],
    rate: rates.find((rate) => rate.itemId === item.item_id).rate,
  }))
}

export const getNeededLineItems = (invoice: any) => {
  return invoice?.items?.map((item) => {
    const {product_type, ...neededItem} = item
    return neededItem
  })
}

export const calculateNextInvoiceNumber = (invoiceNumberComparer: {
  lastInvoiceCreated: InvoiceNecessaries
  lastCreditNoteCreated: string
}) => {
  const numericalCharactersFromInvoiceNumber = invoiceNumberComparer.lastInvoiceCreated
    .invoice_number
    ? parseInt(invoiceNumberComparer.lastInvoiceCreated.invoice_number.split('-')[1])
    : 0
  const numericalCharactersFromCreditNoteNumber = parseInt(
    invoiceNumberComparer.lastCreditNoteCreated.split('-')[1]
  )
  const max = Math.max(
    numericalCharactersFromCreditNoteNumber,
    numericalCharactersFromInvoiceNumber
  )
  const newInvoiceNumber = max + 1
  const maxDigits = 5
  const zero = '0'
  const numberOfZerosToBeAppended = maxDigits - String(newInvoiceNumber).length
  const finalInvoiceNumber = `${zero.repeat(numberOfZerosToBeAppended)}${newInvoiceNumber}`
  return finalInvoiceNumber
}

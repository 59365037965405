import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

interface Props {}

const Welcome: React.FC<Props> = () => {
  return (
    <div style={{height: '75vh'}} className='d-flex align-items-center'>
      <Container>
        <Row className='d-flex align-items-center'>
          <Col xs={12} md={12}>
            <div className='jumbotron'>
              <h1 className='display-6 text-center'>Welcome back!</h1>
              <hr className='my-4' />
              <p className='lead text-center'>
                Stay on top of your fleet operations. Let's keep your fleet running smoothly!
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Welcome

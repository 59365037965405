import React, {FC, useContext, useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {defaultTemplate} from '../../../data/template.default'
import Loading from '../../../_metronic/layout/components/loading/Loading'
import PanelComponent from './components/PanelComponent'
import {
  DndContext, DragOverlay, KeyboardSensor,
  PointerSensor,
  closestCorners,
  useSensor,
  useSensors } from "@dnd-kit/core";
import { sortableKeyboardCoordinates, arrayMove } from "@dnd-kit/sortable";
import {DragDropContext, Droppable,} from 'react-beautiful-dnd'
import {
  TemplateColumnType,
  TemplateFieldType,
  TemplatePanelType,
  TemplateType,
  useEditTemplate,
} from '../../../context/edit-template-context'
import TableColumns, {defaultColumns} from './components/TableColumns'
import {downloadObjectAsJson} from '../../../utils/file.utils'
import moment from 'moment'
import {UserContext} from '../../../context/user.context'
import { getUpdatedTemplateWithDefault, getFieldFormatted } from './utils'
import FieldComponent from './components/FieldComponent'
import { orbitRequest, RequestMethod } from '../../../utils/api.utils'

const reorder = ({
  list,
  startIndex,
  endIndex,
}: {
  list: TemplatePanelType[]
  startIndex: number
  endIndex: number
}): TemplatePanelType[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const AddEditTemplate: FC = () => {
  const history = useHistory()
  const {id: paramId} = useParams<any>()

  const {businessProfileId} = useContext<any>(UserContext)
  const [importedTemplate, setImportedTemplate] = useState('')
  const [activeFieldData, setActiveFieldData]  = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const {setTemplate, setPanels, template, panels} = useEditTemplate()
  // Get template
  useEffect(() => {
    ;(async () => {
      if (!paramId) {
        setTemplate(defaultTemplate)
        setPanels(defaultTemplate.panels)
        return
      }

      const response = await orbitRequest({route: `/template/get`, payload: {
        id: paramId,
        businessProfileId: businessProfileId,
      }, method: RequestMethod.POST})

      if (!response || response.error) {
        return toast.error(response?.message || 'Something went wrong')
      }

      const futureTemplate = JSON.parse(response.template?.content)
      setTemplate(futureTemplate)
      setPanels(futureTemplate?.panels)
    })()
  }, [])

  useEffect(() => {
    return () => {
      setTemplate(null)
    }
  }, [])

  // Functions
  const saveChanges = async () => {
    const templatePayload = {
      ...template,
      panels,
    }

    const response = await orbitRequest({route: `/template/update`, payload: {
      id: paramId,
      template: JSON.stringify(templatePayload),
      businessProfileId: businessProfileId,
    }, method: RequestMethod.POST})

    if (!response || response.error) {
      return toast.error(response?.message || 'Something went wrong')
    }

    toast.success('Template was edited!')

    return history.push('../templates')
  }

  const createTemplate = async () => {
    const templatePayload = {
      ...template,
      panels,
    }

    const response = await orbitRequest({route: `/template/create`, payload: {
      template: JSON.stringify(templatePayload),
      businessProfileId: businessProfileId,
    }, method: RequestMethod.POST})

    if (!response || response.error) {
      return toast.error(response?.message || 'Something went wrong')
    }

    toast.success('Template was created!')

    return history.push('../templates')
  }

  // Loading
  if (!template || !panels) {
    return (
      <div
        style={{
          background: '#fff',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Loading />
        </div>
      </div>
    )
  }
  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const futurePanels: TemplatePanelType[] = reorder({
      list: panels,
      startIndex: result?.source?.index,
      endIndex: result?.destination?.index,
    })

    // Change priority
    setPanels(futurePanels)
  }

  const isTemplateValid = (checkTemplate: any) => {
    if (!Boolean(checkTemplate)) {
      return false
    }
    // check if the received template has a valid configuration
    let resultProperties = Object.keys(checkTemplate)
    let templateProperties = Object.keys(template)
    if (resultProperties.sort().join(' ') != templateProperties.sort().join(' ')) {
      return false
    }

    // check if the column's object configuration is valid
    let validColumnConfig = Object.keys(defaultColumns[0])
    let columns = checkTemplate.columns
    if (!Boolean(columns) || !Array.isArray(columns) || columns.length < 1) {
      return false
    }
    let validColumns = columns.every((column: TemplateColumnType) => {
      return validColumnConfig.every((prop) => Object.hasOwn(column, prop))
    })
    if (!validColumns) {
      return false
    }

    // check if the template has the expected panels and fields
    let expectedPanels = defaultTemplate.panels
    let panels = checkTemplate.panels
    if (!Boolean(panels) || !Array.isArray(panels) || panels.length < 1) {
      return false
    }
    let validPanelsAndFields = expectedPanels.every((expectedPanel: TemplatePanelType) => {
      // check if we can find a matching panel
      let matchPanel = panels.find((panel: TemplatePanelType) => panel.name == expectedPanel.name)
      if (!matchPanel) {
        return false
      }
      // check if we can find all the matching fields
      let fields = matchPanel.fields
      if (!Boolean(fields) || !Array.isArray(fields) || fields.length < 1) {
        return false
      }
      return expectedPanel.fields.every((field: TemplateFieldType) =>
        fields.some((matchField: TemplateFieldType) => matchField.name == field.name)
      )
    })
    if (!validPanelsAndFields) {
      return false
    }

    return true
  }

  const handleImport = async (ev: any) => {
    if (!ev.target.files[0]) {
      return
    }
    if (ev.target.files[0].type != 'application/json') {
      toast.error('Please select JSON files only!')
      return
    }

    let result: any = await fileToJSON(ev)
    if (!result) {
      toast.error('File contents invalid!')
      return
    }
    if (isTemplateValid(result)) {
      let newTemplate: TemplateType = {...result}
      newTemplate.panels = newTemplate.panels.filter((panel: any) =>
        defaultTemplate.panels.some((defaultPanel: any) => panel.name == defaultPanel.name)
      )

      defaultTemplate.panels.forEach((defaultPanel: TemplatePanelType) => {
        let index = newTemplate.panels.findIndex(
          (newPanel: any) => defaultPanel.name == newPanel.name
        )
        let importPanel = result.panels.find(
          (importPanel: any) => defaultPanel.name == importPanel.name
        )
        newTemplate.panels[index].fields = importPanel.fields.filter((expectedField: any) =>
          defaultPanel.fields.some((field: any) => expectedField.name == field.name)
        )
      })

      setTemplate(newTemplate)
      setPanels(newTemplate.panels)

      toast.success('Template succesfully loaded!')
    } else {
      toast.error('Invalid template object property configuration!')
    }
  }

  const fileToJSON = async (event: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.onload = (event) =>
        resolve(
          (() => {
            try {
              return JSON.parse(event.target.result as string)
            } catch {
              return null
            }
          })()
        )
      fileReader.onerror = (error) => {
        toast.error(error)
        reject(error)
      }
      fileReader.readAsText(event.target.files[0])
    })
  }


  const handleDragStart = (event) => {
    if(!event?.active?.data?.current){
      return;
    }
    const fieldFormatted = getFieldFormatted(panels[event.active.data.current.panelIndex].fields[event.active.data.current.fieldIndex])
    setActiveFieldData({
      field: fieldFormatted,
      panelIndex: event.active.data.current.panelIndex,
      fieldIndex: event.active.data.current.fieldIndex
    })
  };


  const handleDragEnd = (event) => {
    setActiveFieldData(null);
    if (!event?.active?.data?.current || !event?.over?.data?.current) {
      return;
    }
    const { active, over } = event;
    if (active.id !== over.id && active.data.current.panelIndex !== over.data.current.panelIndex) {
      const oldIndex = active.data.current.fieldIndex;
      const newIndex = over.data.current.fieldIndex;
      const activeContainer = active.data.current.panelIndex
      const overContainer = over.data.current.panelIndex
      const activePanel = panels[activeContainer];
      const futurePanel = panels[overContainer];
      futurePanel.fields.splice(newIndex, 0, activePanel.fields[oldIndex]); 
      activePanel.fields = activePanel.fields.filter((field) => field.name !== active.id)
      const futurePanels = panels;
      futurePanels[activeContainer] = activePanel;
      futurePanels[overContainer] = futurePanel;
      setPanels(futurePanels)
    }

    if (active.id !== over.id && active.data.current.panelIndex === over.data.current.panelIndex) {
      const futurePanels = panels;
      const futurePanel = panels[active.data.current.panelIndex];
      const oldIndex = active.data.current.fieldIndex;
      const newIndex = over.data.current.fieldIndex;
      futurePanel.fields = arrayMove(futurePanel.fields, oldIndex, newIndex);
      futurePanels[active.data.current.panelIndex] = futurePanel
      setPanels(futurePanels);
        
    }
  };

  return (
    <div>
      {/* Header */}
      <div className='page__top-buttons'>
        <div className='template-name-form-group'>
          <label htmlFor='template-name' className='form-label'>
            Template name:
          </label>
          <input
            type='text'
            name='template-name'
            id='template-name'
            className='form-control form-control-sm'
            style={{display: 'inline', width: 'auto', marginLeft: 10}}
            value={template.name}
            onChange={(ev) => {
              setTemplate({...template, name: ev.target.value})
            }}
          />
        </div>
        {/* Update button */}
        <button
          style={{width: '90px'}}
          className='btn btn-info py-0 me-2'
          onClick={() => {
            let futureTemplate = getUpdatedTemplateWithDefault({...template, panels: panels})
            toast.success("Template succesfully updated!")
            setTemplate(futureTemplate)
            setPanels(futureTemplate?.panels)
          }}
        >
          Update
        </button>

        {/* Export button */}
        <button
          className='btn btn-info py-0 me-2'
          style={{width: '90px'}}
          onClick={() => {
            let fileName =
              moment(Date.now()).format('YYYY-MM-DD_HH-mm-ss') + '-template-' + template.name
            let fileContent = {...template, ...{panels: panels}}
            downloadObjectAsJson(fileContent, fileName)
          }}
        >
          Export
        </button>

        {/* Import button */}
        <label
          style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '90px'}}
          className='btn btn-info py-0 me-2'
        >
          Import
          <input
            type='file'
            style={{display: 'none'}}
            accept='application/JSON'
            onChange={(ev) => {
              handleImport(ev)
              setImportedTemplate('')
            }}
            value={importedTemplate}
          />
        </label>

        {/* Save button */}
        <button
          className='btn btn-primary py-0'
          style={{width: '90px'}}
          onClick={async () => {
            if (paramId) {
              return saveChanges()
            }

            createTemplate()
          }}
        >
          Save
        </button>
      </div>
      {/* Finish Header */}

      {/* Body */}
      <TableColumns />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='list'>
          {(provided) => (
            <DndContext
              sensors={sensors}
              onDragEnd={handleDragEnd}
              onDragStart={handleDragStart}
              collisionDetection={closestCorners}
            >
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {panels.map((panel, index) => {
                return (
                  <PanelComponent
                    index={index}
                    panels={panels}
                    key={panel.name + index}
                    panel={panel}
                    setPanels={setPanels}
                  />
                )
              })}
              {provided.placeholder}
            </div>
              <DragOverlay>{activeFieldData && <FieldComponent
                field={activeFieldData.field}
                panelMode={'edit'}
                fieldIndex={activeFieldData.fieldIndex}
                panelIndex={activeFieldData.panelIndex}
                key={activeFieldData.field.name}
                id={activeFieldData.field.name}
               />}</DragOverlay>
            </DndContext>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default AddEditTemplate

import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { getTransportsRequest } from '../setup/axios/transports.request'
import { toast } from 'react-toastify'
import { useLocalStorage } from '../hooks/useLocalStorage'
import easytrackAxios from '../setup/easytrack.axios'
import { EASYTRACK_API_URL } from '../constants/api.constants'
import { UserContext } from './user.context'
import { toAbsoluteUrl } from '../_metronic/helpers'
import { TemplatesContext } from './templates.context'
import { getURLFormattedTemplateName } from '../utils/string.utils'
import moment from 'moment'
import { defaultPaginationSize } from '../app/modules/transport-requests/Pagination'
import { TariffServiceType } from '../pages/add-edit-plan/modules/PlanTypes'
const TransportsContext = createContext({})
export const API_KEY = 'TEST'
export const SECRET_KEY = 'TEST'
function TransportsProvider(props: any) {
  const [selectedTransportsForConfirmation, setSelectedTransportsForConfirmation] = useState<any[]>(
    []
  )
  const { businessProfileId, user } = useContext<any>(UserContext)
  const [showShifts, setShowShifts] = useLocalStorage('showShifts', false)
  const [showSchedules, setShowSchedules] = useLocalStorage('showSchedules', false)
  // Assign
  const [resultsPerPage, setResultsPerPage] = useLocalStorage(
    'resultsPerPage',
    defaultPaginationSize
  )

  const [transportsFilterPayload, setTransportsFilterPayload] = useState(null)
  const [transportToBeAssigned, setTransportToBeAssigned] = useState(null)

  const { defaultTemplate } = useContext<any>(TemplatesContext)
  const [transports, setTransports] = useState<Array<any>>([])
  const [pageCount, setPageCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [transportsCounter, setTransportsCounter] = useLocalStorage<any>('transportsCounter', {})

  // Transport assignments filters
  const [dictionaries, setDictionaries] = useState<any>([])
  const [tariffServices, setTariffServices] = useState<any[]>()
  const [tariffTypes, setTariffTypes] = useState<any[]>()
  const [companies, setCompanies] = useState<any[]>([])
  const [suppliers, setSuppliers] = useState<any[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(true)
  // Filters
  const [drivers, setDrivers] = useState<any>([])
  const [chosenDate, setChosenDate] = useLocalStorage<Date>('filter_date', new Date(Date.now()))
  const [filterInterval, setFilterInterval] = useLocalStorage<number>('filter_interval', 4) // 1h filterInterval
  const [employeeFunction, setEmployeeFunction] = useLocalStorage<string>(
    'filter_employeeFunction',
    'sofer'
  )
  const [selectedStatuses, setSelectedStatuses] = useLocalStorage<any>('selectedStatuses', null)
  const [selectedTariffTypes, setSelectedTariffTypes] = useLocalStorage<any>(
    'selectedTariffTypes',
    null
  )
  const [selectedTariffServices, setSelectedTariffServices] = useLocalStorage<any>(
    'selectedTariffServices',
    []
  )
  const [selectedCompanies, setSelectedCompanies] = useLocalStorage<any>('selectedCompanies', [])
  const [selectedDrivers, setSelectedDrivers] = useLocalStorage<any>('selectedDrivers', [])
  const [selectedShiftType, setSelectedShiftType] = useLocalStorage<any>('selectedShiftType', null)

  const [selectedVehicles, setSelectedVehicles] = useLocalStorage<any>('selectedVehicles', [])

  const [selectedPaymentTypes, setSelectedPaymentTypes] = useLocalStorage<any>(
    'selectedPaymentTypes',
    []
  )
  const [selectedRevenueCenters, setSelectedRevenueCenters] = useLocalStorage<any>(
    'selectedRevenueCenters',
    []
  )
  const [selectedTimeInterval, setSelectedTimeInterval] = useLocalStorage<any>(
    'selectedTimeInterval',
    []
  )

  const [futuresTransport, setFuturesTransport] = useState<any>([])
  const [shiftsMap, setShiftsMap] = useState(null)

  const tariffServicePhotoDict = useMemo(() => {
    let futureDict = {}
      ; (tariffServices || []).forEach((tariffService: TariffServiceType) => {
        if (!Boolean(tariffService?.name) || !Boolean(tariffService?.photoUrl)) {
          return
        }
        futureDict[tariffService?.name] = tariffService?.photoUrl
      })
    return futureDict
  }, [tariffServices])

  const getShiftsMap = async () => {
    const result = await getDriverAssignments({
      driverIds: [],
      startDate: moment.utc(chosenDate).format('YYYY-MM-DD'),
      stopDate: moment.utc(chosenDate).add(24, 'hours').format('YYYY-MM-DD'),
      businessProfileId,
    })

    const futureShifts = {}

    if (!result.data) {
      toast.error('Something went wrong')
      return null
    }

    result.data.assignEntries.map((shift: any) => {
      futureShifts[shift.driver.idUserProfile] = shift.assignmentObjects
    })
    futureShifts['other'] = result.data.externalDriverRequests
    futureShifts['unassigned'] = result.data.unassigned

    return futureShifts
  }

  const getAllCompanies = async () => {
    const result = await easytrackAxios
      .get(`${EASYTRACK_API_URL}/companies?businessProfileId=${businessProfileId}`)
      .catch((err) => err)
    if (Boolean(result?.data) && Array.isArray(result.data)) {
      const companiesList: any[] = result.data
      const futureCompanies = []
      companiesList.forEach((company: any) => {
        if (company.client) {
          futureCompanies.push(company)
        }
      })

      setCompanies(futureCompanies)
    }
  }

  // Check for transport each minute
  const checkForNewTransports = async () => {
    let currentDate = new Date()

    // Add 24 hours
    let dayAfterDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000)

    const countResult = await getTransports({
      businessProfileId,
      startDate: currentDate,
      stopDate: dayAfterDate,
      countMode: true,
    })

    if (!countResult?.data) {
      return null;
    }

    const count = countResult.data
    if (futuresTransport.length >= count) {
      return null;
    }
    const result = await getTransports({
      businessProfileId,
      startDate: currentDate,
      stopDate: dayAfterDate,
      simplifyForAssignments: true,
    })

    // If error
    if (!result?.data || !Array.isArray(result.data)) {
      return null
    }

    const nextFutureTransports = result.data
    setFuturesTransport(nextFutureTransports)

    // If there are new transports and it is the first time;
    if (futuresTransport.length === 0) {
      return
    }

    const setOfIds = new Set(futuresTransport.map((transport: any) => transport.id));
    const diff = nextFutureTransports.filter((transport) => !setOfIds.has(transport.id));

    // For each diff future transport show a toast
    diff.forEach((transport) => {
      toast.success(`New transport request: ${transport.id}!`, {
        onClick: () => {
          // this should be handled by react router
          window.location.href = `/add-edit-transport/${getURLFormattedTemplateName(
            defaultTemplate.name
          )}/${transport.id}`
        },
      })
    })

    // If there are new future transports and it is not the first time;
    new Audio(toAbsoluteUrl('/media/audio/notification.mp3')).play()
  }

  const keyEventCallback = (event: any) => {
    if (event.key === 'Escape') {
      setTransportToBeAssigned(null)
    }
  }

  useEffect(() => {
    // Add event listener for ESC key
    document.addEventListener('keydown', keyEventCallback)

    return () => {
      // Remove event listener for ESC key
      document.removeEventListener('keydown', keyEventCallback)
    }
  }, [transportToBeAssigned])

  useEffect(() => {
    if (!user || !businessProfileId) {
      return () => { }
    }

    checkForNewTransports()

    const interval = setInterval(() => {
      checkForNewTransports()
    }, 60 * 1000)

    return () => {
      clearInterval(interval)
    }
  }, [futuresTransport, user])

  const getUserProfileInfo = async ({ idUserProfile }: { idUserProfile: number | string }) => {
    const result = await easytrackAxios.get(`${EASYTRACK_API_URL}/user-profiles/${idUserProfile}`)
    return result
  }

  const getDriverAssignments = async ({ driverIds, startDate, stopDate, businessProfileId }) => {
    const payload = {
      businessProfileId: businessProfileId,
      requestStatuses: [
        'ANALYZE',
        'TO_BE_ACCEPTED',
        'TO_BE_REJECTED',
        'NEW',
        'PENDING',
        'ACKNOWLEDGED',
        'READY',
        'STARTED',
        'DELAYED',
        'ALERT',
        'COMPLETED',
        'COMPLETED_POS',
        'FINISHED',
        'CANCELLED',
        'REJECTED',
        'UNSOLVED',
        'OPEN',
        'QUOTE',
      ],
      driverIds: driverIds,
      vehicleTypeIds: [],
      tariffTypeIds: [],
      tariffServiceIds: [],
      clientIds: [],
      scheduleTemplates: [],
      employeeFunction: 'sofer',
      startDate: startDate,
      stopDate: stopDate,
    }

    const result = await easytrackAxios.post(
      `${EASYTRACK_API_URL}/transport-requests/assignments/v3`,
      payload
    )

    return result
  }

  const getTransportsCount = ({ partial }: { partial: boolean }) => {
    let status = ''
    if (partial) {
      status = 'ANALYZE,TO_BE_ACCEPTED'
    } else {
      status = `ACKNOWLEDGED,ALERT,ANALYZE,COMPLETED,COMPLETED_POS,DELAYED,NEW,OPEN,PENDING,QUOTE`
        + `,READY,STARTED,TO_BE_ACCEPTED,TO_BE_REJECTED,UNSOLVED`
    }
    const request = easytrackAxios.get(
      `${EASYTRACK_API_URL}/transport-requests/count-map?requestStatuses=${status}&businessProfile=${businessProfileId}`
    )

    return request
  }

  const getVehiclesRequest = async ({ businessProfileId }: { businessProfileId: string }) => {
    const result = await easytrackAxios.get(
      `${EASYTRACK_API_URL}/vehicles?businessProfile=${businessProfileId}`
    )
    return result
  }

  const getTransports = async (data: {
    countMode?: boolean
    id?: number
    ids?: Array<Number>
    businessProfileId: string
    page?: number
    resultsPerPage?: number
    requestStatuses?: Array<any>
    vehicleIds?: Array<Number>
    driverIds?: Array<Number>
    clientIds?: Array<Number>
    supplierIds?: Array<Number>
    startDate?: Date
    stopDate?: Date
    number?: number
    orderBy?: string
    orderDir?: string
    paymentTypes?: Array<String>
    tariffServiceIds?: Array<Number>
    tariffTypeIds?: Array<Number>
    revenueCenters?: Array<String>
    pricingTransferTypes?: Array<String>
    invoiceNumber?: string,
    companyCustomField1?: string,
    companyCustomField2?: string,
    companyCustomField3?: string,
    companyCustomField4?: string,
    companyCustomField5?: string,
    searchText?: string
    passengerName?: string
    requesterName?: string
    simplifyForAssignments?: boolean
  }) => {
    const result = await getTransportsRequest(data)
    if (result?.response?.data?.error) {
      toast.error(result?.response?.data?.message || 'We have some problem with your last request!')
      return { message: result?.response?.data?.message, error: true, data: [] }
    }

    return { error: false, data: data.id ? [result.data] : result.data }
  }

  const checkTransport = async ({ id, checkType }: { id: number; checkType: 'CHECK' | 'DELAY' }) => {
    const result = await easytrackAxios.put(
      `${EASYTRACK_API_URL}/transport-requests/${id}/check?type=${checkType}`,
      null,
      {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Content-Length': 0,
          'Accept-Charset': 'UTF-8',
        },
      }
    )

    return result
  }

  const viewInBrowserRequest = async ({ id }: { id: number }) => {
    const result = await easytrackAxios.get(
      `${EASYTRACK_API_URL}/transport-requests/${id}/confirmations`
    )

    return result
  }

  /**
   * Get transport per driver
   * @param transports
   * @returns object
   */
  const getTransportsPerDriver = useCallback(
    (transports: any[]) => {
      let localTransportsPerDriver: any = { unassigned: [], other: [] }

      for (let i = 0; i < transports.length; i++) {
        const currentTransport = transports[i]
        let driverId = currentTransport.assignedDriver?.idUserProfile
        if (driverId == null) {
          localTransportsPerDriver.unassigned.push(currentTransport)
          continue
        }

        let found = (selectedDrivers.length === 0 ? drivers : selectedDrivers).find(
          (driver: any) => {
            return driver.idUserProfile == driverId
          }
        )

        if (!found) {
          localTransportsPerDriver.other.push(currentTransport)
          continue
        }

        if (!localTransportsPerDriver[driverId]) {
          localTransportsPerDriver[driverId] = []
        }

        localTransportsPerDriver[driverId].push(currentTransport)
      }

      return localTransportsPerDriver
    },
    [drivers, selectedDrivers]
  )

  const assignTransportToDriver = async ({
    transportId,
    driverId,
    businessProfileId,
    vehicleId,
    mode = 'verify',
    bypassDriverSchedule = true,
  }: {
    transportId: number
    driverId: number
    businessProfileId: number
    vehicleId: number
    mode: 'verify' | 'assign'
    bypassDriverSchedule: boolean
  }) => {
    const payload: any = {
      businessProfileId: businessProfileId,
      driverId: driverId,
      assignAccepted: mode === 'assign' ? true : false,
      bypassDriverSchedule: bypassDriverSchedule,
    }

    // Add vehicleId if exists
    vehicleId && (payload.vehicleId = vehicleId)

    const result = await easytrackAxios
      .put(`${EASYTRACK_API_URL}/transport-requests/${transportId}/assign`, payload)
      .catch((err) => err.response)

    return result
  }

  const store = {
    getTransports,
    transports,
    currentPage,
    setCurrentPage,
    shiftsMap,
    setShiftsMap,
    getShiftsMap,
    // Filters
    dictionaries,
    setDictionaries,
    tariffServices,
    setTariffServices,
    tariffTypes,
    setTariffTypes,
    companies,
    setCompanies,
    suppliers,
    setSuppliers,
    isLoading,
    setIsLoading,
    chosenDate,
    setChosenDate,
    filterInterval,
    setFilterInterval,
    employeeFunction,
    setEmployeeFunction,
    selectedStatuses,
    setSelectedStatuses,
    selectedTariffTypes,
    setSelectedTariffTypes,
    selectedTariffServices,
    setSelectedTariffServices,
    selectedCompanies,
    setSelectedCompanies,
    selectedDrivers,
    setSelectedDrivers,
    selectedShiftType,
    setSelectedShiftType,
    selectedVehicles,
    setSelectedVehicles,
    drivers,
    setDrivers,
    selectedPaymentTypes,
    setSelectedPaymentTypes,
    selectedRevenueCenters,
    setSelectedRevenueCenters,
    selectedTimeInterval,
    setSelectedTimeInterval,
    getUserProfileInfo,
    getTransportsCount,
    transportsCounter,
    setTransportsCounter,
    assignTransportToDriver,
    // Menu
    checkTransport,
    getTransportsPerDriver,
    // Transports basic
    setPageCount,
    pageCount,
    setTransports,

    showShifts,
    setShowShifts,
    showSchedules,
    setShowSchedules,
    getVehiclesRequest,
    viewInBrowserRequest,
    resultsPerPage,
    setResultsPerPage,
    transportToBeAssigned,
    setTransportToBeAssigned,
    getDriverAssignments,
    selectedTransportsForConfirmation,
    setSelectedTransportsForConfirmation,
    tariffServicePhotoDict,
    transportsFilterPayload,
    setTransportsFilterPayload,
    getAllCompanies
  }

  const storeForProvider = useMemo(() => store, [store])
  return (
    <TransportsContext.Provider value={storeForProvider}>
      {props.children}
    </TransportsContext.Provider>
  )
}

export { TransportsContext }
export default TransportsProvider

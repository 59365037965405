// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {CarsContext} from '../../../../../context/cars.context'
import {LogsContext} from '../../../../../context/logs.context'
import {UserContext} from '../../../../../context/user.context'
import {UsersContext} from '../../../../../context/users.context'
import { formatCarNumber } from '../../../../../utils/car.utils'
import {getDateObj} from '../../../../../utils/date.utils'
import {capitalize} from '../../../../../utils/string.utils'
import {KTSVG} from '../../../../../_metronic/helpers'
import {CarModel} from '../../../auth/models/CarModel'
import {LogModel} from '../../../auth/models/LogModel'

type Props = {
  className: string
}

const LogsTableComponent: React.FC<Props> = ({className}) => {
  const {user} = useContext<any>(UserContext)
  const {getUsers} = useContext<any>(UsersContext)
  const {selectedCars} = useContext<any>(CarsContext)
  const {logsData, getLogs} = useContext<any>(LogsContext)

  const [localLogs, setLocalLogs] = useState<Array<LogModel>>([])

  const [selectedUserForLogs, setSelectedUserForLogs] = useState(null)
  const [selectedCarForLogs, setSelectedCarForLogs] = useState(null)

  useEffect(() => {
    getLogs()
  }, [])

  useEffect(() => {

    setLocalLogs(logsData)
  }, [logsData])

  const getLogsForSelected = () => {
    if (selectedCars.length <= 0) {
      return setLocalLogs(logsData)
    }

    const futureLogs: Array<any> = []

    selectedCars.filter((car: CarModel) => {
      logsData.map((log: LogModel) => {
        if (log.carId === car.id) {
          futureLogs.push(log)
        }
      })
    })

    setLocalLogs(futureLogs)
  }
  useEffect(() => {
    getLogsForSelected()
  }, [selectedCars])

  const renderRow = (log: LogModel) => {

    if(!log || !log.created_at || !log.message){
      return null;
    }
    const date = getDateObj(log.created_at)
    const messageWithoutUserID = log.message.split(' ').slice(1).join(' ')

    const user = log.user[0];
    const car = log.car[0];

    return (
      <div key={log.id} className='timeline-item'>
        {/* begin::Label */}
        <div className='timeline-label'>
          <div className='text-gray-800 fs-6 fw-bolder'>
            {date.hour}:{date.minutes}
          </div>
          <div className='text-gray-800 fs-8'>
            {date.day}/{date.month}/{date.yearShort}
          </div>
        </div>
        {/* end::Label */}
        {/* begin::Badge */}
        <div className='timeline-badge'>
          <i className='fa fa-genderless text-warning fs-1'></i>
        </div>
        {/* end::Badge */}
        {/* begin::Text */}
        <div className='fw-mormal timeline-content text-muted ps-3'>
          <span
            onClick={async () => {
              const res = await getUsers(log.createdBy, false)
              setSelectedUserForLogs(res.data.user)
            }}
            className='fw-bolder'
            role='button'
          >
            {user.firstName} {" "} {user.lastName}
          </span>{' '}
          {messageWithoutUserID} la masina {' '}
          <span
            onClick={async () => {
              const response = await getCarsRequest({id: log.carId});
              if(Boolean(response) && !response.error){
                setSelectedCarForLogs(response.car)
              }
            }}
            className='car-number-plate'
            role='button'
          >
            {formatCarNumber(car.carNumber)}
          </span>
        </div>
        {/* end::Text */}
      </div>
    )
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder mb-2 text-dark'>
            {selectedCars.length > 0 ? 'Selected logs' : 'All logs'}
          </span>
          <span className='text-muted fw-bold fs-7'>A total of {logsData.length} logs</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div
        className='card-body pt-5'
        style={{
          overflow: 'scroll',
          maxHeight: '550px',
        }}
      >
        {/* begin::Timeline */}
        <div className='timeline-label'>
          {/* begin::Item */}

          {localLogs.map((e: LogModel) => {
            return renderRow(e)
          })}
        </div>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}

      {selectedUserForLogs && (
        <Modal
          className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
          id='kt_inbox_compose'
          role='dialog'
          data-backdrop='false'
          aria-hidden='true'
          tabIndex='-1'
          show={selectedUserForLogs}
          animation={false}
        >
          <div className='modal-content'>
            {/* begin::Form */}
            {/*begin::Header*/}
            <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
              <h5 className='fw-bold m-0'>User info:</h5>
              <div className='d-flex ms-2'>
                {/*begin::Close*/}
                <div
                  className='btn btn-icon btn-sm btn-light-primary ms-2'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    setSelectedUserForLogs(null)
                  }}
                >
                  <KTSVG className='svg-icon-1' path='/media/icons/duotone/Navigation/Close.svg' />
                </div>
                {/*end::Close*/}
              </div>
            </div>
            {/*end::Header*/}

            {/*begin::Body*/}
            <div className='d-block py-5 ps-8 pe-5'>
              {/* Full name */}
              <div className='row'>
                {/* First name */}
                <div className='col-lg-6 fv-row mb-7'>
                  <label className='form-label fw-bolder text-dark fs-6'>First name</label>
                  <input
                    disabled
                    value={selectedUserForLogs.firstName}
                    placeholder='Popescu'
                    type='text'
                    autoComplete='off'
                    className={'form-control form-control-lg form-control-solid'}
                  />
                </div>

                {/* Last name */}
                <div className='col-lg-6 fv-row mb-7'>
                  <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
                  <input
                    disabled
                    value={selectedUserForLogs.lastName}
                    placeholder='Ion'
                    type='text'
                    autoComplete='off'
                    className={'form-control form-control-lg form-control-solid'}
                  />
                </div>
              </div>

              {/* Email */}
              <div className='fv-row mb-7'>
                <label className='form-label fw-bolder text-dark fs-6'>Email</label>
                <input
                  disabled
                  value={selectedUserForLogs.email}
                  placeholder='ionpopescu@example.com'
                  type='text'
                  autoComplete='off'
                  className={'form-control form-control-lg form-control-solid'}
                />
              </div>

              {/* Role */}
              <div className='fv-row mb-7'>
                <label className='form-label fw-bolder text-dark fs-6'>Role</label>

                <div className='fv-row'>
                  <select
                    disabled
                    className='form-select form-select-solid form-select-lg'
                    defaultValue={0}
                  >
                    <option value={0}>{capitalize(selectedUserForLogs.role.name)}</option>
                  </select>
                </div>
              </div>
              {/* end::Form group */}
            </div>
            {/*end::Body*/}

            {/*begin::Footer*/}
            <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-top'>
              {/*begin::Actions*/}
              <div className='d-flex align-items-center me-3'></div>
              {/*end::Actions*/}
            </div>
          </div>
        </Modal>
      )}

      {selectedCarForLogs && <Modal
      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
      id='kt_inbox_compose'
      role='dialog'
      data-backdrop='false'
      aria-hidden='true'
      tabIndex='-1'
      show={selectedCarForLogs}
      animation={false}
    >
      <div className='modal-content'>
        {/* begin::Form */}
        {/*begin::Header*/}
        <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
          <h5 className='fw-bold m-0'>Car info:</h5>
          <div className='d-flex ms-2'>
            {/*begin::Close*/}
            <div
              className='btn btn-icon btn-sm btn-light-primary ms-2'
              data-bs-dismiss='modal'
              onClick={() => setSelectedCarForLogs(null)}
            >
              <KTSVG className='svg-icon-1' path='/media/icons/duotone/Navigation/Close.svg' />
            </div>
            {/*end::Close*/}
          </div>
        </div>
        {/*end::Header*/}

        {/*begin::Body*/}
        <div className='d-block py-5 ps-8 pe-5'>
          {/* Brand */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Brand</label>
            <input
            disabled
              value={selectedCarForLogs.brand}
              placeholder='Opel'
              type='text'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>

          {/* Model */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Model</label>
            <input
            disabled
              value={selectedCarForLogs.model}
              placeholder='Astra'
              type='text'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>

          {/* Car number */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Car number</label>
            <input
            disabled
              value={selectedCarForLogs.carNumber}
              placeholder='B-290-LIU'
              type='text'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>
          {/* VIN */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Vin</label>
            <input
            disabled
              value={selectedCarForLogs.vin}
              placeholder='zy232sa'
              type='text'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>

          {/* Phone number */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Phone number</label>
            <input
            disabled
              value={selectedCarForLogs.phoneNumber}
              placeholder='0735764791'
              type='text'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>

          {/* Track number */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Track number</label>
            <input
            disabled
              value={selectedCarForLogs.trackNumber}
              placeholder='zy232sa'
              type='text'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>

          {/* end::Form group */}
        </div>
        {/*end::Body*/}

        {/*begin::Footer*/}
        <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-top'>
          {/*begin::Actions*/}
          <div className='d-flex align-items-center me-3'></div>
          {/*end::Actions*/}

        </div>
        {/*end::Footer*/}
        {/*end::Form*/}
      </div>
    </Modal>}
    </div>
  )
}

export {LogsTableComponent}

import {useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {CarsContext} from '../../../../../context/cars.context'
import {KTSVG} from '../../../../../_metronic/helpers'
import {CarModel} from '../../../auth/models/CarModel'
import { toast } from 'react-toastify';
import { formatCarNumber } from '../../../../../utils/car.utils'
export const CreateUpdateCarModal = ({car}: {car?: CarModel}) => {
  const {createCar, updateCar, showCreateCarModal, setShowCreateCarModal, setShowCarActionsModal} =
    useContext<any>(CarsContext)
  const [brand, setBrand] = useState<string>('')
  const [model, setModel] = useState<string>('')
  const [carNumber, setCarNumber] = useState<string>('')
  const [vin, setVin] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [trackNumber, setTrackNumber] = useState<string>('')

  const resetState = () => {
    setBrand('')
    setModel('')
    setVin('')
    setCarNumber('')
    setPhoneNumber('')
    setTrackNumber('')
  }

  const createNewCar = async () => {
    const result = await createCar({
      brand,
      model,
      vin,
      phoneNumber,
      carNumber,
      trackNumber,
      location: {
        lat: 0,
        long: 0,
      },
    })

    if (result.error) {
      return ''
    }

    resetState()
    setShowCreateCarModal(false)
  }

  const _updateCar = async () => {
    const result = await updateCar({
      id: car!.id,
      brand,
      model,
      vin,
      phoneNumber,
      carNumber,
      trackNumber,
      location: car!.location,
    })

    if (result.error) {
      return ''
    }

    resetState()
    setShowCreateCarModal(false)
  }

  useEffect(() => {
    if (car) {
      setBrand(car.brand)
      setModel(car.model)
      setCarNumber(car.carNumber)
      setPhoneNumber(car.phoneNumber)
      setTrackNumber(car.trackNumber)
      setVin(car.vin)
    } else {
      resetState()
    }
  }, [car])

  return (
    <Modal
      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
      id='kt_inbox_compose'
      role='dialog'
      data-backdrop='false'
      aria-hidden='true'
      tabIndex='-1'
      show={showCreateCarModal}
      animation={false}
    >
      <div className='modal-content'>
        {/* begin::Form */}
        {/*begin::Header*/}
        <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>

        <div>
            <h5 className='fw-bold m-0'>{car ? `Edit car:` : 'Create a new car'}</h5>
            <h5 className='fw-bold m-0 mt-2'>
              {car?.brand && car?.model && <span className='car-badge'>{`${car?.brand} ${car?.model}`}</span>}
              {car?.carNumber && <span className='car-number-plate'>{`${formatCarNumber(car?.carNumber)}`}</span>}
            </h5>
          </div>

          <h5 className='fw-bold m-0'></h5>
          <div className='d-flex ms-2'>
            {/*begin::Close*/}
            <div
              className='btn btn-icon btn-sm btn-light-primary ms-2'
              data-bs-dismiss='modal'
              onClick={() => setShowCreateCarModal(false)}
            >
              <KTSVG className='svg-icon-1' path='/media/icons/duotone/Navigation/Close.svg' />
            </div>
            {/*end::Close*/}
          </div>
        </div>
        {/*end::Header*/}

        {/*begin::Body*/}
        <div className='d-block py-5 ps-8 pe-5'>
          {/* Brand */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Brand</label>
            <input
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              placeholder='Opel'
              type='text'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>

          {/* Model */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Model</label>
            <input
              value={model}
              onChange={(e) => setModel(e.target.value)}
              placeholder='Astra'
              type='text'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>

          {/* Car number */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Car number</label>
            <input
              value={carNumber}
              onChange={(e) => setCarNumber(e.target.value)}
              placeholder='B-290-LIU'
              type='text'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>
          {/* VIN */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Vin</label>
            <input
              value={vin}
              onChange={(e) => setVin(e.target.value)}
              placeholder='zy232sa'
              type='text'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>

          {/* Phone number */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Phone number</label>
            <input
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder='0735764791'
              type='text'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>

          {/* Track number */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Track number</label>
            <input
              value={trackNumber}
              onChange={(e) => setTrackNumber(e.target.value)}
              placeholder='zy232sa'
              type='text'
              autoComplete='off'
              className={'form-control form-control-lg form-control-solid'}
            />
          </div>

          {/* end::Form group */}
        </div>
        {/*end::Body*/}

        {/*begin::Footer*/}
        <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-top'>
          {/*begin::Actions*/}
          <div className='d-flex align-items-center me-3'></div>
          {/*end::Actions*/}

          {/*begin::Toolbar*/}
          <div className='d-flex align-items-center'>
            {car && (
              <button
                onClick={() => {
                  setShowCreateCarModal(false)
                  setShowCarActionsModal(true)
                }}
                className='btn px-6'
              >
                {'Go to actions page'}
              </button>
            )}

            <button
              onClick={() => {
                if (car) {
                  return _updateCar()
                } else {
                  return createNewCar()
                }
              }}
              className='btn btn-primary px-6'
            >
              {car ? 'Update' : 'Create'}
            </button>
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Footer*/}
        {/*end::Form*/}
      </div>
    </Modal>
  )
}

import React, {useContext, useEffect} from 'react'
import {Redirect, Switch} from 'react-router-dom'
import {UserContext} from '../../../context/user.context'

export function Logout() {
  const {signout} = useContext<any>(UserContext);

  useEffect(() => {
    signout()
    // document.location.reload();
  }, [signout])

  return (
    <Switch>
      <Redirect to='/auth/login' />
    </Switch>
  )
}

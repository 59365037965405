import {FC} from 'react'
import {Card, formatEntryDate} from '../ViewTransport'

const formatRequestStatus = (requestStatus: string) => {
  let formattedRequestStatus =
    requestStatus[0].toUpperCase() + requestStatus.substring(1).toLowerCase()
  formattedRequestStatus = formattedRequestStatus.replaceAll('_', ' ')
  return formattedRequestStatus
}

const getUserName = (entryUser: any) => {
  let username = ''
  if (entryUser.profile) {
    username = entryUser.profile.fullName
  } else if (entryUser.username) {
    username = entryUser.username
  }
  return username
}

const HeaderRow = () => {
  return (
    <tr className='fw-bolder'>
      <th className='w-150px ps-9'>Date</th>
      <th>User</th>
      <th className='w-100px'>Status</th>
      <th style={{minWidth: 160}}>Assignment Blocked</th>
      <th className='w-150px'>Assigned driver</th>
      <th className='w-200px'>Assigned vehicle</th>
      <th className='pe-9'>Comment</th>
    </tr>
  )
}

const HistoryRow: FC<{entry: any}> = ({entry}) => {
  return (
    <tr key={entry.timestamp}>
      <td className='ps-9'>{entry.timestamp ? formatEntryDate(entry.timestamp) : ''}</td>
      <td>{entry.user ? getUserName(entry.user) : ''}</td>
      <td>{entry.requestStatus ? formatRequestStatus(entry.requestStatus) : ''}</td>
      <td>{entry.assignmentBlocked ? 'YES' : 'NO'}</td>
      <td>{entry.assignedDriver ? entry.assignedDriver.fullName : ''}</td>
      <td>{entry.assignedVehicle ? entry.assignedVehicle.businessName : ''}</td>
      <td className='pe-9'>{entry.comment || ''}</td>
    </tr>
  )
}

const HistoryTable: FC<{histories: any}> = ({histories}) => {
  return (
    <div key='container-histories' className='col-12 pt-4 pb-4'>
      <Card name={'History'} removePadX={true}>
        <div className='table-responsive'>
          <table className='table table-hover table-striped table-row-gray-100 align-middle'>
            <thead>{HeaderRow()}</thead>
            <tbody>
              {histories.map((entry: any) => (
                <HistoryRow key={"historyRow-" + entry.timestamp} entry={entry} />
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  )
}

export default HistoryTable
